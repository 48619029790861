import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux'
import * as ib from "./ibdata.js"
const useStyles = makeStyles(theme => ({
  primary: {
    fontSize: '0.5rem',//Insert your required size
    fontFamily: 'Roboto',
    margin: '5px',
    padding: '5px',
    color: "#003D7F"
  },
  sub: {
    zIndex: 102,
    fontSize: '0.5rem',//Insert your required size
    fontFamily: 'Roboto',
    margin: '5px',
    padding: '5px',
    color: "#003D7F"
  }
}))
const animateMenu = ["Animate-Rotate", "Animate-Move", "Animate-Color"]
const subs = { "Animate": animateMenu }
export default function ContextMenu(props) {
  const state = { mouseX: props.x, mouseY: props.y }
  const classes = useStyles();
  const teacherR = useSelector((state) => state.teacher);
  const user = useSelector((state) => state.user);
  const boardConfig = useSelector((state) => state.BoardConfig);

  function handleClose(m, e) {
    if (m in subs) {
      return handleClickA(e, subs[m])
    }
    if (m === "Copy") {
      var j = ib.copyObjtoClipBoard(props.selectedObj)
      var isSafari = window.safari !== undefined;
      if(isSafari) {
        copyToClipboard(j);
      }
      if(navigator.userAgent.indexOf("Chrome") !== -1){
        navigator.clipboard.writeText(j)
      }
    }
    if (m === "Copy SVG") {
      var j = props.selectedObj.data.origSvg
      navigator.clipboard.writeText(j)
    }
    if (props.cb) props.cb(m, props.event)
  };

  function copyToClipboard(textToCopy) {
    var textArea;
  
    function isOS() {
      return navigator.userAgent.match(/ipad|iphone|Mac OS X/i);
    }
  
    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);
    }
  
    function selectText() {
      var range, selection;
  
      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      }
    }
  
    function copyTo() {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  
    createTextArea(textToCopy);
    selectText();
    copyTo();
  }
  const menu = ["Animate", "Delete", "Copy",
    "Move", "ResiZe", "Rotate", "Clone", "Send to back", "Add Link", "Flip Horizontal", "Flip Vertical", "Edit Immersive Reader"]
  var mm = menu
  if (props.otype === "text") {
    if (!boardConfig.DisableImmersive) {
      mm = [...menu, "Edit Text", "Read in Immersive Reader"]
    } else {
      mm = [...menu, "Edit Text"]
    }
  }
  if (props.selectedObj && props.selectedObj.data && props.selectedObj.data.math) {
    mm = [...menu, "Edit Math"]
  }
  if (props.selectedObj && props.selectedObj.data && props.selectedObj.data.pdfText) {
    if (boardConfig.DisableImmersive) {

    } else {
      mm = [...menu, "Read in Immersive Reader"]
    }
  }

  if (props.selectedObj && props.selectedObj.data && props.selectedObj.data.linkData &&
    props.selectedObj.data.linkData.type &&
    (props.selectedObj.data.linkData.type === "textBox" ||
      props.selectedObj.data.linkData.type === "DropZone")) {
    if (props.selectedObj.data.linkData.type === "DropZone") {
      mm.splice(0, 0, "Make Coding Box")
      mm.splice(0, 0, "Make auto counter")
      mm.splice(0, 0, "Make Toss Zone")
    }
    mm.splice(0, 0, "Add Answers and Points")
  }

  if (props.selectedObj && props.selectedObj.data) {
    if (props.selectedObj.data.cannotdelete) {
      var idx = mm.findIndex(v => v === "Delete")
      if (idx !== -1) {
        mm.splice(idx, 1)
      }
    }
    if (props.selectedObj.data.lock) {
      mm = [...mm, "UnLock"]
    } else {
      mm = [...mm, "Lock"]
    }
    if (props.selectedObj.data.lasso) {
      mm = [...mm, "UnGroup"]
    }
    if (props.selectedObj.data.shape) {
      mm = [...mm, "Fill Color"]
    }
    if (teacherR === 1) {

      if (props.selectedObj.data.studentClone) {
        mm = [...mm, "Disable Student Clone"]
      } else {
        mm = [...mm, "Enable Student Clone"]
      }
      if (props.selectedObj.data.studentMove) {
        mm = [...mm, "Disable Student Move"]
      } else {
        mm = [...mm, "Enable Student Move"]
      }
      if (props.selectedObj.data.fixSize) {
        mm = [...mm, "Unfix size"]
      } else {
        mm = [...mm, "Fix size"]
      }

      if (props.creator) {
        mm = [...mm, "Last Upd: " + props.creator,]
      }
    }
    if (user) {
      mm = [...mm, "Add to Palette"]
    }
    if (props.selectedObj.data.studentClone && props.selectedObj.data.notSession) {
      mm = ["Clone"]
      if (props.selectedObj.data.canberead) {
        mm = [...mm, "Read in Immersive Reader"]
      }
    } else
      if (props.selectedObj.data.studentMove && props.selectedObj.data.notSession) {
        mm = ["Move"]
        if (props.selectedObj.data.canberead) {
          mm = [...mm, "Read in Immersive Reader"]
        }
      } else
        if (props.selectedObj.data.notSession && props.selectedObj.data.canberead) {
          mm = ["Read in Immersive Reader"]
        }
    if (props.selectedObj.data.fixSize) {
      mm = mm.filter((el) => { return el !== "ResiZe" })
    }
    if (props.otype === "image" && teacherR === 1) {
      mm = [...mm, "Make Jigsaw"]
    }
    if (props.otype === "image") {
      mm = [...mm, "Set Border"]

    }
    if (props.selectedObj.data.svgImage && props.selectedObj.data.origSvg) {
      mm = [...mm, "Copy SVG"]
    }
  }
  //if (props.otype === "image") mm = [...menu, "Resize"]

  function handleKey(e) {
    if (props.selectedObj.data.notSession) return
    if (e.key === "Backspace") {
      handleClose("Delete")
    }
    if (e.key === "r" || e.key === "R") {
      handleClose("Rotate")
    }
    if (e.key === "m" || e.key === "M") {
      handleClose("Move")
    }
    if (e.key === "Z" || e.key === "z") {
      handleClose("ResiZe")
    }
    if (e.key === "C" || e.key === "c") {
      handleClose("Clone")
    }
    if (e.key === "L" || e.key === "l") {
      handleClose("Lock")
    }
  }
  const [anchorEl, setAnchorEl] = React.useState({ el: null, list: [] });
  const handleClickA = (event, list) => {
    setAnchorEl({ el: event.currentTarget, list: list });
  };
  mm.sort()
  return (
    <div style={{ zIndex: 99, cursor: 'context-menu' }}>
      {/* <ClickAwayListener onClickAway={()=>handleClose("none")}> */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl.el}
        open={Boolean(anchorEl.el)}
        onClose={handleClose}
        autoFocus
        getContentAnchorEl={null}
        className={classes.sub}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        {anchorEl.list.map((m) =>
          <MenuItem classes={{ root: classes.primary }} key={m} onClick={(e) => handleClose(m, e)}>{m}</MenuItem>
        )}
      </Menu>
      <Menu
        keepMounted
        open={props.open}
        onClose={handleClose}
        dense="true"
        autoFocus
        onKeyDown={(e) => handleKey(e)}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        {mm.map((m) =>
          <MenuItem classes={{ root: classes.primary }} key={m} onClick={(e) => handleClose(m, e)}>{m}</MenuItem>
        )}
      </Menu>
      {/* </ClickAwayListener> */}

    </div>
  );
}