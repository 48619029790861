/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const createCommunityBoard = /* GraphQL */ `
  mutation CreateCommunityBoard(
    $input: CreateCommunityBoardInput!
    $condition: ModelCommunityBoardConditionInput
  ) {
    createCommunityBoard(input: $input, condition: $condition) {
      id
      name
      description
      creator
      creatorEmail
      CreatorLocalID
      Country
      Grade
      Subject
      boardLink
      content
      createdAt
      updatedAt
      title
    }
  }
`;
export const updateCommunityBoard = /* GraphQL */ `
  mutation UpdateCommunityBoard(
    $input: UpdateCommunityBoardInput!
    $condition: ModelCommunityBoardConditionInput
  ) {
    updateCommunityBoard(input: $input, condition: $condition) {
      id
      name
      description
      creator
      creatorEmail
      CreatorLocalID
      Country
      Grade
      Subject
      boardLink
      content
      createdAt
      updatedAt
      title
    }
  }
`;
export const deleteCommunityBoard = /* GraphQL */ `
  mutation DeleteCommunityBoard(
    $input: DeleteCommunityBoardInput!
    $condition: ModelCommunityBoardConditionInput
  ) {
    deleteCommunityBoard(input: $input, condition: $condition) {
      id
      name
      description
      creator
      creatorEmail
      CreatorLocalID
      Country
      Grade
      Subject
      boardLink
      content
      createdAt
      updatedAt
      title
    }
  }
`;
export const createClassroom = /* GraphQL */ `
  mutation CreateClassroom(
    $input: CreateClassroomInput!
    $condition: ModelClassroomConditionInput
  ) {
    createClassroom(input: $input, condition: $condition) {
      id
      name
      TeacherID
      content
      Teacher {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      Users {
        items {
          id
          content
          createdAt
          updatedAt
          CurrentSessionId
          CurrentUserId
          ClassroomID
          ttl
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      TeacherList
    }
  }
`;
export const updateClassroom = /* GraphQL */ `
  mutation UpdateClassroom(
    $input: UpdateClassroomInput!
    $condition: ModelClassroomConditionInput
  ) {
    updateClassroom(input: $input, condition: $condition) {
      id
      name
      TeacherID
      content
      Teacher {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      Users {
        items {
          id
          content
          createdAt
          updatedAt
          CurrentSessionId
          CurrentUserId
          ClassroomID
          ttl
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      TeacherList
    }
  }
`;
export const deleteClassroom = /* GraphQL */ `
  mutation DeleteClassroom(
    $input: DeleteClassroomInput!
    $condition: ModelClassroomConditionInput
  ) {
    deleteClassroom(input: $input, condition: $condition) {
      id
      name
      TeacherID
      content
      Teacher {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      Users {
        items {
          id
          content
          createdAt
          updatedAt
          CurrentSessionId
          CurrentUserId
          ClassroomID
          ttl
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      TeacherList
    }
  }
`;
export const createGamePlay = /* GraphQL */ `
  mutation CreateGamePlay(
    $input: CreateGamePlayInput!
    $condition: ModelGamePlayConditionInput
  ) {
    createGamePlay(input: $input, condition: $condition) {
      id
      Session
      type
      content
      createdAt
      updatedAt
      version
      ttl
    }
  }
`;
export const updateGamePlay = /* GraphQL */ `
  mutation UpdateGamePlay(
    $input: UpdateGamePlayInput!
    $condition: ModelGamePlayConditionInput
  ) {
    updateGamePlay(input: $input, condition: $condition) {
      id
      Session
      type
      content
      createdAt
      updatedAt
      version
      ttl
    }
  }
`;
export const deleteGamePlay = /* GraphQL */ `
  mutation DeleteGamePlay(
    $input: DeleteGamePlayInput!
    $condition: ModelGamePlayConditionInput
  ) {
    deleteGamePlay(input: $input, condition: $condition) {
      id
      Session
      type
      content
      createdAt
      updatedAt
      version
      ttl
    }
  }
`;
export const createClassGroup = /* GraphQL */ `
  mutation CreateClassGroup(
    $input: CreateClassGroupInput!
    $condition: ModelClassGroupConditionInput
  ) {
    createClassGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const updateClassGroup = /* GraphQL */ `
  mutation UpdateClassGroup(
    $input: UpdateClassGroupInput!
    $condition: ModelClassGroupConditionInput
  ) {
    updateClassGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const deleteClassGroup = /* GraphQL */ `
  mutation DeleteClassGroup(
    $input: DeleteClassGroupInput!
    $condition: ModelClassGroupConditionInput
  ) {
    deleteClassGroup(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const createClassroomEvent = /* GraphQL */ `
  mutation CreateClassroomEvent(
    $input: CreateClassroomEventInput!
    $condition: ModelClassroomEventConditionInput
  ) {
    createClassroomEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const updateClassroomEvent = /* GraphQL */ `
  mutation UpdateClassroomEvent(
    $input: UpdateClassroomEventInput!
    $condition: ModelClassroomEventConditionInput
  ) {
    updateClassroomEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const deleteClassroomEvent = /* GraphQL */ `
  mutation DeleteClassroomEvent(
    $input: DeleteClassroomEventInput!
    $condition: ModelClassroomEventConditionInput
  ) {
    deleteClassroomEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const createEngagementEvent = /* GraphQL */ `
  mutation CreateEngagementEvent(
    $input: CreateEngagementEventInput!
    $condition: ModelEngagementEventConditionInput
  ) {
    createEngagementEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      luid
      name
      time
      ttl
      Content
      Classroom
    }
  }
`;
export const updateEngagementEvent = /* GraphQL */ `
  mutation UpdateEngagementEvent(
    $input: UpdateEngagementEventInput!
    $condition: ModelEngagementEventConditionInput
  ) {
    updateEngagementEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      luid
      name
      time
      ttl
      Content
      Classroom
    }
  }
`;
export const deleteEngagementEvent = /* GraphQL */ `
  mutation DeleteEngagementEvent(
    $input: DeleteEngagementEventInput!
    $condition: ModelEngagementEventConditionInput
  ) {
    deleteEngagementEvent(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      luid
      name
      time
      ttl
      Content
      Classroom
    }
  }
`;
export const createLocalUsers = /* GraphQL */ `
  mutation CreateLocalUsers(
    $input: CreateLocalUsersInput!
    $condition: ModelLocalUsersConditionInput
  ) {
    createLocalUsers(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const updateLocalUsers = /* GraphQL */ `
  mutation UpdateLocalUsers(
    $input: UpdateLocalUsersInput!
    $condition: ModelLocalUsersConditionInput
  ) {
    updateLocalUsers(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const deleteLocalUsers = /* GraphQL */ `
  mutation DeleteLocalUsers(
    $input: DeleteLocalUsersInput!
    $condition: ModelLocalUsersConditionInput
  ) {
    deleteLocalUsers(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const createMultiBoard = /* GraphQL */ `
  mutation CreateMultiBoard(
    $input: CreateMultiBoardInput!
    $condition: ModelMultiBoardConditionInput
  ) {
    createMultiBoard(input: $input, condition: $condition) {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const updateMultiBoard = /* GraphQL */ `
  mutation UpdateMultiBoard(
    $input: UpdateMultiBoardInput!
    $condition: ModelMultiBoardConditionInput
  ) {
    updateMultiBoard(input: $input, condition: $condition) {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const deleteMultiBoard = /* GraphQL */ `
  mutation DeleteMultiBoard(
    $input: DeleteMultiBoardInput!
    $condition: ModelMultiBoardConditionInput
  ) {
    deleteMultiBoard(input: $input, condition: $condition) {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const createObject = /* GraphQL */ `
  mutation CreateObject(
    $input: CreateObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    createObject(input: $input, condition: $condition) {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const updateObject = /* GraphQL */ `
  mutation UpdateObject(
    $input: UpdateObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    updateObject(input: $input, condition: $condition) {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const deleteObject = /* GraphQL */ `
  mutation DeleteObject(
    $input: DeleteObjectInput!
    $condition: ModelObjectConditionInput
  ) {
    deleteObject(input: $input, condition: $condition) {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      email
      boardConfig
      avatar
      personalConfig
      emailOptIn
      paidPlan
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      email
      boardConfig
      avatar
      personalConfig
      emailOptIn
      paidPlan
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      email
      boardConfig
      avatar
      personalConfig
      emailOptIn
      paidPlan
    }
  }
`;
export const createPersonalPalette = /* GraphQL */ `
  mutation CreatePersonalPalette(
    $input: CreatePersonalPaletteInput!
    $condition: ModelPersonalPaletteConditionInput
  ) {
    createPersonalPalette(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const updatePersonalPalette = /* GraphQL */ `
  mutation UpdatePersonalPalette(
    $input: UpdatePersonalPaletteInput!
    $condition: ModelPersonalPaletteConditionInput
  ) {
    updatePersonalPalette(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const deletePersonalPalette = /* GraphQL */ `
  mutation DeletePersonalPalette(
    $input: DeletePersonalPaletteInput!
    $condition: ModelPersonalPaletteConditionInput
  ) {
    deletePersonalPalette(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const createCommunityPalette = /* GraphQL */ `
  mutation CreateCommunityPalette(
    $input: CreateCommunityPaletteInput!
    $condition: ModelCommunityPaletteConditionInput
  ) {
    createCommunityPalette(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const updateCommunityPalette = /* GraphQL */ `
  mutation UpdateCommunityPalette(
    $input: UpdateCommunityPaletteInput!
    $condition: ModelCommunityPaletteConditionInput
  ) {
    updateCommunityPalette(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const deleteCommunityPalette = /* GraphQL */ `
  mutation DeleteCommunityPalette(
    $input: DeleteCommunityPaletteInput!
    $condition: ModelCommunityPaletteConditionInput
  ) {
    deleteCommunityPalette(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      SessionID
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      content
      UserProfile
      ttl
      avatar
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      SessionID
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      content
      UserProfile
      ttl
      avatar
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      SessionID
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      content
      UserProfile
      ttl
      avatar
    }
  }
`;
export const createErrorLog = /* GraphQL */ `
  mutation CreateErrorLog(
    $input: CreateErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    createErrorLog(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      content
      ttl
    }
  }
`;
export const updateErrorLog = /* GraphQL */ `
  mutation UpdateErrorLog(
    $input: UpdateErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    updateErrorLog(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      content
      ttl
    }
  }
`;
export const deleteErrorLog = /* GraphQL */ `
  mutation DeleteErrorLog(
    $input: DeleteErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    deleteErrorLog(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      content
      ttl
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder(
    $input: CreateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    createFolder(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $input: UpdateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    updateFolder(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: DeleteFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const createServiceNotice = /* GraphQL */ `
  mutation CreateServiceNotice(
    $input: CreateServiceNoticeInput!
    $condition: ModelServiceNoticeConditionInput
  ) {
    createServiceNotice(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      noticeConfig
    }
  }
`;
export const updateServiceNotice = /* GraphQL */ `
  mutation UpdateServiceNotice(
    $input: UpdateServiceNoticeInput!
    $condition: ModelServiceNoticeConditionInput
  ) {
    updateServiceNotice(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      noticeConfig
    }
  }
`;
export const deleteServiceNotice = /* GraphQL */ `
  mutation DeleteServiceNotice(
    $input: DeleteServiceNoticeInput!
    $condition: ModelServiceNoticeConditionInput
  ) {
    deleteServiceNotice(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      noticeConfig
    }
  }
`;
export const createLatencyEndPoints = /* GraphQL */ `
  mutation CreateLatencyEndPoints(
    $input: CreateLatencyEndPointsInput!
    $condition: ModelLatencyEndPointsConditionInput
  ) {
    createLatencyEndPoints(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      region
      content
    }
  }
`;
export const updateLatencyEndPoints = /* GraphQL */ `
  mutation UpdateLatencyEndPoints(
    $input: UpdateLatencyEndPointsInput!
    $condition: ModelLatencyEndPointsConditionInput
  ) {
    updateLatencyEndPoints(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      region
      content
    }
  }
`;
export const deleteLatencyEndPoints = /* GraphQL */ `
  mutation DeleteLatencyEndPoints(
    $input: DeleteLatencyEndPointsInput!
    $condition: ModelLatencyEndPointsConditionInput
  ) {
    deleteLatencyEndPoints(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      region
      content
    }
  }
`;
export const createLatencyCalculation = /* GraphQL */ `
  mutation CreateLatencyCalculation(
    $input: CreateLatencyCalculationInput!
    $condition: ModelLatencyCalculationConditionInput
  ) {
    createLatencyCalculation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      region
      clientRegion
      content
    }
  }
`;
export const updateLatencyCalculation = /* GraphQL */ `
  mutation UpdateLatencyCalculation(
    $input: UpdateLatencyCalculationInput!
    $condition: ModelLatencyCalculationConditionInput
  ) {
    updateLatencyCalculation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      region
      clientRegion
      content
    }
  }
`;
export const deleteLatencyCalculation = /* GraphQL */ `
  mutation DeleteLatencyCalculation(
    $input: DeleteLatencyCalculationInput!
    $condition: ModelLatencyCalculationConditionInput
  ) {
    deleteLatencyCalculation(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      region
      clientRegion
      content
    }
  }
`;
