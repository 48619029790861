import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
    DialogActions, Dialog,
    Checkbox, DialogContent, Button, DialogTitle, InputBase,
} from "@material-ui/core"
import * as ib from "./ibdata"
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    rightSide: {
        marginLeft: 'auto',
    },
    small: {
        width: "0.7rem",
        height: "0.7rem",
    },
    drawer: {
        top: "9vh",
        height: "auto",
        maxHeight: "70vh",
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
    },
    listItemText: {
        fontSize: "0.65rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#ffffff',
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
    },
    iconButton: {
        color: '#ffffff',
    },
    inviteButton: {
        fontSize: "0.5rem",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '999px',
        width: "180px",
        padding: "6px 5px",
        color: "#ffffff",
        "&:hover": {
            color: '#1E2639',
        },
    },
    textFiled: {
        width: 430,
        height: 40,
        display: "flex",
        margin: "10px 0px",
        background: "#FFFFFF",
        border: "1px solid #E0E5E7",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        borderRadius: "999px",
        cursor: "pointer",
        "&:hover": {
            border: "1px solid black"
        },
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        width: "465px",
        justifyContent: "space-evenly",
        alignItems: "center",
        paddingTop: "10px",
    },
}));

const options = [
    { name: 'Manage Boards',checked: false },
    { name: 'Clear',checked: false },
    { name: 'Download',checked: false },
    { name: 'Lasso',checked: false },
    { name: 'Palette',checked: false },
    { name: 'Select',checked: false },
    { name: 'Erase', checked: false },
    { name: 'Move & Resize',checked: false },
    { 
        name:'Drawing Tools',checked: false, sub: [
            { name: 'Click & Draw', checked: false },
            { name: 'Line', checked: false},
            { name: 'Arrow', checked: false },
            { name: 'Shapes', checked: false },
        ]
    },
    { 
        name:'Write',checked: false, sub: [
            { name: 'Click & Type', checked: false },
            { name: 'Text Box', checked: false},
            { name: 'Rich Text', checked: false },
            { name: 'Hand Write', checked: false },
            { name: 'Math Editor',checked: false },
            { name: 'Note', checked: false},
        ]
    },
    { 
        name: 'Upload',checked: false, sub: [
            { name: 'Upload Files From Computer', checked: false },
            { name: 'Upload Files From Google Drive',checked: false },
            { name: 'Upload Bingo', checked: false },
            { name: 'Upload GamePlay',checked: false},
            { name: 'Upload linked PDF', checked: false },
        ]
    },
    {
        name: 'Tools',checked: false, sub: [
            { name: 'Select/Crop',checked: false  },
            { name: 'Infinite Cloner',checked: false },
            { name: 'Drop Zone',checked: false },
            { name: 'Break Down',checked: false },
            { name: 'Add Media',checked: false },
            { name: 'Grids',checked: false },
            { name: 'Time Machine',checked: false},
            { name: 'Pause Animations',checked: false },

        ]
    },
    {
        name: 'Help Student',checked: false, sub: [
            { name: 'Raise Hand',checked: false },
            { name: "I'm finished",checked: false },
            { name: 'Bathroom Break',checked: false },
            { name: 'Please Speed Up',checked: false },
            { name: 'Please Slow Down',checked: false },
        ]
    },
    {
        name: 'widgets',checked: false, sub: [
            { name: "Add Link", checked: false },
            { name: "YouTube", checked: false },
            { name: "Website", checked: false},
            { name: 'Basic Calculator', checked: false },
            { name: 'Graphing Calculator', checked: false},
            { name: "Rekenrek", checked: false},
            { name: "Write Software", checked: false },
            { name: "Tile Factory", checked: false},
            { name: "Form Factory", checked: false},
            { name: "Table Factory",  checked: false},
            { name: "Molecule Editor", checked: false},
        ]
    },
]

var gParts = []
var isDone = 0
export default function FeatureSelector(props) {
    const classes = useStyles();
    function handleClose() {
        if (props.cb) props.cb(false)
    }
    async function handleAgree() {
        var ff = [...gParts]
        var final = {}
        for (var i = 0; i < ff.length; i++) {
            var f = ff[i]

            var sub = {}
            if (f.sub) {
                f.sub.forEach((s) => {
                    if (s.checked) {
                        sub[s.name] = { checked: true }
                    }
                })
            }
            if (Object.keys(sub).length > 0 || f.checked) {
                final[f.name] = { checked: f.checked, sub: sub }
            }
            if (f.value) {
                final[f.name] = f
            }
        }
        await ib.updateClassBoardsTools(props.sess, final)
        if (props.cb) props.cb(true)
    }

    function getListVal(key) {
        var indx = list.findIndex(x => x.name === key)
        if (indx !== -1) return list[indx]
        return null
    }

    function handleType(e) {
        var ff = [...list]
        var key = e.target.id
        var indx = ff.findIndex(x => x.name === key)
        if (indx !== -1) ff.splice(indx, 1)
        if (e.target.value.length > 0) {
            ff.push({
                name: key,
                value: e.target.value, type: "text"
            })
        }
        setList(ff)
        gParts = ff
        // setList({
        //     ...ff,
        //     [e.target.id]: {value: e.target.value, type: "text"}
        // })
    }
    const [list, setList] = React.useState([...options])
    const [stateOfCheckBox, setStateOfCheckBox] = useState(true)

    useEffect(() => {
        if (isDone > 1) return
        //below if else code snippet will work perfectly when setlist is been called atleast once for that reason used this isdone
        var ff = [...list]
        let isChecked = ff.filter(l => l.checked === true)
        if (isChecked.length > 0) {
            setStateOfCheckBox(false)
        } else {
            setStateOfCheckBox(true)
        }
        isDone += 1
    }, [list])

    React.useEffect(() => {
        if (props.sess) {
            if (props.sess.boardConfig) {
                try {
                    var bc = JSON.parse(props.sess.boardConfig)
                } catch { }
                if (bc.boardTools) {
                    var ff = [...list]

                    for (var i = 0; i < ff.length; i++) {
                        var f = ff[i]
                        if (bc.boardTools[f.name] && bc.boardTools[f.name].checked) {
                            f.checked = bc.boardTools[f.name].checked
                        }
                        if (bc.boardTools[f.name] && bc.boardTools[f.name].sub &&
                            Object.keys(bc.boardTools[f.name].sub).length > 0) {
                            f.sub.forEach((s) => {
                                if (bc.boardTools[f.name].sub[s.name]) {
                                    s.checked = bc.boardTools[f.name].sub[s.name].checked
                                }
                            })
                        }
                    }
                    Object.keys(bc.boardTools).forEach((t) => {
                        var v = bc.boardTools[t]
                        if (v.value) {
                            var indx = ff.findIndex(x => x.name === v.name)
                            if (indx !== -1) ff.splice(indx, 1)
                            ff.push(v)
                        }
                    })
                    setList(ff)
                }
            }
        }
    }, [props.sess])

    function handleCheck(a, state) {
        var ff = [...list]
        var found = null
        var indx = ff.findIndex(x => x.name === a.name)
        if (indx !== -1) found = ff[indx]

        if (found) {
            if(state){
                if(state === 'true'){
                    found.checked = true
                    setStateOfCheckBox(false)
                }else{
                    found.checked = false
                    setStateOfCheckBox(true)
                }
            }else{
                if (found.checked) {
                    found.checked = false
                    setStateOfCheckBox(false)
                } else {
                    setStateOfCheckBox(true)
                    found.checked = true
                }
            }
        }
        setList(ff)
        gParts = ff
    }

    function handleCheckB(a, b, state) {
        var ff = [...list]
        var found = null
        var indx = ff.findIndex(x => x.name === a.name)
        if (indx !== -1) found = ff[indx]
        if (found) {
            var i2 = found.sub.findIndex(x => x.name === b.name)
            if (i2 !== -1) {
                if (state) {
                    if (state === 'true') {
                        found.sub[i2].checked = true
                        setStateOfCheckBox(false)
                    } else {
                        found.sub[i2].checked = false
                        setStateOfCheckBox(true)
                    }
                } else {
                    found.sub[i2].checked = !found.sub[i2].checked
                    setStateOfCheckBox(found.sub[i2].checked)
                }
            }
        }
        setList(ff)
        gParts = ff
    }
    function selectAll(e) {
        e.preventDefault()
        let state = 'false'
        if(stateOfCheckBox){
            state = 'true'
        }else{
            state = 'false'
        }
        list.map((a) => {
            handleCheck(a, state)
            if(a.sub){
                a.sub.forEach(element => {
                    handleCheckB(a, element, state)
                });
            }
            return a
        })
    }
    return (
        <Dialog
            className="dialogModal"
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="form-dialog-title">
                <div>
                <span>
                Select Features to Disable for Students
                       </span>
                <CloseIcon className='ndCloseIcon' style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%", position: "absolute", top: "18px" }} onClick={handleClose} />
                </div>
            </DialogTitle>
            <DialogContent>
                <List dense className={classes.list}>
                    {list.map((a) => {
                        return (
                            <>
                                {!a.value && (

                                    <div key={a.name}>
                                        <ListItem key={a.name} button dense onClick={() => handleCheck(a)}>
                                            <Checkbox
                                                checked={a.checked}
                                                value={a.checked}
                                                edge="start"
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': a.name }}
                                            />
                                            <ListItemText
                                                classes={{ primary: classes.listItemText }}
                                                primary={a.name} />
                                        </ListItem>

                                        {a.sub && !a.checked && (
                                            <List dense className={classes.list}>
                                                {a.sub.map((b) => {
                                                    return (
                                                        <ListItem className={classes.nested} key={b.name} button dense onClick={() => handleCheckB(a, b)}>
                                                            <Checkbox
                                                                checked={b.checked}
                                                                value={a.checked}
                                                                edge="start"
                                                                disableRipple
                                                                inputProps={{ 'aria-labelledby': a.name }}
                                                            />
                                                            <ListItemText
                                                                classes={{ primary: classes.listItemText }}
                                                                primary={b.name} />
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        )

                                        }
                                    </div>
                                )
                                }
                            </>

                        )
                    })}
                </List>
                <InputBase
                    className={classes.textFiled}
                    style={{ padding: "0px 25px" }}
                    id="fontSize"
                    name="fontSize"
                    placeholder="Font Size"
                    value={getListVal('fontSize') ? getListVal('fontSize').value : ""}
                    onChange={(e) => handleType(e)}
                />
                <InputBase
                    className={classes.textFiled}
                    style={{ padding: "0px 25px", cursor:"pointer"}}
                    id="brushSize"
                    name="brushSize"
                    placeholder="Brush Size"
                    multiline
                    value={getListVal("brushSize") ? getListVal("brushSize").value : ""}
                    onChange={(e) => handleType(e)}
                />
                <InputBase
                    className={classes.textFiled}
                    style={{ padding: "0px 25px", cursor:"pointer" }}
                    id="brushColor"
                    name="brushColor"
                    placeholder="Brush Color"
                    multiline
                    value={getListVal("brushColor") ? getListVal("brushColor").value : ""}
                    onChange={(e) => handleType(e)}
                />
            </DialogContent>
            <DialogActions>
                <div className={classes.footer}>
                <Button onClick={selectAll} className={clsx(classes.inviteButton, 'mb-10')} style={{ backgroundColor: props.inkColor }}>Toggle All</Button>
                <Button onClick={handleAgree} className={clsx(classes.inviteButton, 'mb-10')} style={{ backgroundColor: props.inkColor }} autoFocus>
                                Save
                            </Button>
                </div>
            </DialogActions>
        </Dialog >
    );
}