/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      nextToken
    }
  }
`;
export const getCommunityBoard = /* GraphQL */ `
  query GetCommunityBoard($id: ID!) {
    getCommunityBoard(id: $id) {
      id
      name
      description
      creator
      creatorEmail
      CreatorLocalID
      Country
      Grade
      Subject
      boardLink
      content
      createdAt
      updatedAt
      title
    }
  }
`;
export const listCommunityBoards = /* GraphQL */ `
  query ListCommunityBoards(
    $filter: ModelCommunityBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        creator
        creatorEmail
        CreatorLocalID
        Country
        Grade
        Subject
        boardLink
        content
        createdAt
        updatedAt
        title
      }
      nextToken
    }
  }
`;
export const getClassroom = /* GraphQL */ `
  query GetClassroom($id: ID!) {
    getClassroom(id: $id) {
      id
      name
      TeacherID
      content
      Teacher {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      Users {
        items {
          id
          content
          createdAt
          updatedAt
          CurrentSessionId
          CurrentUserId
          ClassroomID
          ttl
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      TeacherList
    }
  }
`;
export const listClassrooms = /* GraphQL */ `
  query ListClassrooms(
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassrooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        TeacherID
        content
        Teacher {
          id
          name
          createdAt
          updatedAt
          email
          boardConfig
          avatar
          personalConfig
          emailOptIn
          paidPlan
        }
        Users {
          nextToken
        }
        ttl
        createdAt
        updatedAt
        TeacherList
      }
      nextToken
    }
  }
`;
export const getGamePlay = /* GraphQL */ `
  query GetGamePlay($id: ID!) {
    getGamePlay(id: $id) {
      id
      Session
      type
      content
      createdAt
      updatedAt
      version
      ttl
    }
  }
`;
export const listGamePlays = /* GraphQL */ `
  query ListGamePlays(
    $filter: ModelGamePlayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGamePlays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Session
        type
        content
        createdAt
        updatedAt
        version
        ttl
      }
      nextToken
    }
  }
`;
export const getClassGroup = /* GraphQL */ `
  query GetClassGroup($id: ID!) {
    getClassGroup(id: $id) {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const listClassGroups = /* GraphQL */ `
  query ListClassGroups(
    $filter: ModelClassGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        ttl
        name
        content
        Classroom
        SessionID
      }
      nextToken
    }
  }
`;
export const getClassroomEvent = /* GraphQL */ `
  query GetClassroomEvent($id: ID!) {
    getClassroomEvent(id: $id) {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const listClassroomEvents = /* GraphQL */ `
  query ListClassroomEvents(
    $filter: ModelClassroomEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassroomEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        ttl
        type
        event
        For
        State
        Content
        Classroom
      }
      nextToken
    }
  }
`;
export const getEngagementEvent = /* GraphQL */ `
  query GetEngagementEvent($id: ID!) {
    getEngagementEvent(id: $id) {
      id
      createdAt
      updatedAt
      luid
      name
      time
      ttl
      Content
      Classroom
    }
  }
`;
export const listEngagementEvents = /* GraphQL */ `
  query ListEngagementEvents(
    $filter: ModelEngagementEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEngagementEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        luid
        name
        time
        ttl
        Content
        Classroom
      }
      nextToken
    }
  }
`;
export const getLocalUsers = /* GraphQL */ `
  query GetLocalUsers($id: ID!) {
    getLocalUsers(id: $id) {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const listLocalUserss = /* GraphQL */ `
  query ListLocalUserss(
    $filter: ModelLocalUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocalUserss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        createdAt
        updatedAt
        CurrentSession {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        CurrentSessionId
        CurrentUserId
        CurrentUser {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        ClassroomID
        ttl
      }
      nextToken
    }
  }
`;
export const getMultiBoard = /* GraphQL */ `
  query GetMultiBoard($id: ID!) {
    getMultiBoard(id: $id) {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listMultiBoards = /* GraphQL */ `
  query ListMultiBoards(
    $filter: ModelMultiBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultiBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentBoardID
        parentBoard {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        childBoardID
        childBoard {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getObject = /* GraphQL */ `
  query GetObject($id: ID!) {
    getObject(id: $id) {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const listObjects = /* GraphQL */ `
  query ListObjects(
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        content
        type
        objType
        ended
        createdAt
        updatedAt
        SessionID
        ttl
        animate
        CreatedBy
        DisableSync
        SVGObj
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
      }
      nextToken
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      name
      createdAt
      updatedAt
      email
      boardConfig
      avatar
      personalConfig
      emailOptIn
      paidPlan
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      nextToken
    }
  }
`;
export const getPersonalPalette = /* GraphQL */ `
  query GetPersonalPalette($id: ID!) {
    getPersonalPalette(id: $id) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const listPersonalPalettes = /* GraphQL */ `
  query ListPersonalPalettes(
    $filter: ModelPersonalPaletteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonalPalettes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        path
        owner
        name
        content
      }
      nextToken
    }
  }
`;
export const getCommunityPalette = /* GraphQL */ `
  query GetCommunityPalette($id: ID!) {
    getCommunityPalette(id: $id) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const listCommunityPalettes = /* GraphQL */ `
  query ListCommunityPalettes(
    $filter: ModelCommunityPaletteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityPalettes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        path
        owner
        name
        content
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      createdAt
      updatedAt
      SessionID
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      content
      UserProfile
      ttl
      avatar
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      nextToken
    }
  }
`;
export const getErrorLog = /* GraphQL */ `
  query GetErrorLog($id: ID!) {
    getErrorLog(id: $id) {
      id
      name
      createdAt
      updatedAt
      content
      ttl
    }
  }
`;
export const listErrorLogs = /* GraphQL */ `
  query ListErrorLogs(
    $filter: ModelErrorLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        content
        ttl
      }
      nextToken
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        userProfileId
        user {
          id
          name
          createdAt
          updatedAt
          email
          boardConfig
          avatar
          personalConfig
          emailOptIn
          paidPlan
        }
        parentFolderPath
      }
      nextToken
    }
  }
`;
export const getServiceNotice = /* GraphQL */ `
  query GetServiceNotice($id: ID!) {
    getServiceNotice(id: $id) {
      id
      createdAt
      updatedAt
      noticeConfig
    }
  }
`;
export const listServiceNotices = /* GraphQL */ `
  query ListServiceNotices(
    $filter: ModelServiceNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceNotices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        noticeConfig
      }
      nextToken
    }
  }
`;
export const getLatencyEndPoints = /* GraphQL */ `
  query GetLatencyEndPoints($id: ID!) {
    getLatencyEndPoints(id: $id) {
      id
      createdAt
      updatedAt
      region
      content
    }
  }
`;
export const listLatencyEndPointss = /* GraphQL */ `
  query ListLatencyEndPointss(
    $filter: ModelLatencyEndPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLatencyEndPointss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        region
        content
      }
      nextToken
    }
  }
`;
export const getLatencyCalculation = /* GraphQL */ `
  query GetLatencyCalculation($id: ID!) {
    getLatencyCalculation(id: $id) {
      id
      createdAt
      updatedAt
      region
      clientRegion
      content
    }
  }
`;
export const listLatencyCalculations = /* GraphQL */ `
  query ListLatencyCalculations(
    $filter: ModelLatencyCalculationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLatencyCalculations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        region
        clientRegion
        content
      }
      nextToken
    }
  }
`;
export const queryBySavedOwner = /* GraphQL */ `
  query QueryBySavedOwner(
    $savedOwner: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryBySavedOwner(
      savedOwner: $savedOwner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      nextToken
    }
  }
`;
export const queryByParent = /* GraphQL */ `
  query QueryByParent(
    $parentID: ID
    $pageNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByParent(
      parentID: $parentID
      pageNumber: $pageNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      nextToken
    }
  }
`;
export const queryByparentBoardId = /* GraphQL */ `
  query QueryByparentBoardId(
    $parentBoardID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByparentBoardID(
      parentBoardID: $parentBoardID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      nextToken
    }
  }
`;
export const queryByClassroom = /* GraphQL */ `
  query QueryByClassroom(
    $Classroom: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByClassroom(
      Classroom: $Classroom
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      nextToken
    }
  }
`;
export const queryByCreatorLocalId = /* GraphQL */ `
  query QueryByCreatorLocalId(
    $CreatorLocalID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByCreatorLocalId(
      CreatorLocalID: $CreatorLocalID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      nextToken
    }
  }
`;
export const queryByJoinCode = /* GraphQL */ `
  query QueryByJoinCode(
    $joinCode: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByJoinCode(
      joinCode: $joinCode
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      nextToken
    }
  }
`;
export const queryByFolder = /* GraphQL */ `
  query QueryByFolder(
    $folderID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByFolder(
      folderID: $folderID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      nextToken
    }
  }
`;
export const querybycreator = /* GraphQL */ `
  query Querybycreator(
    $CreatorLocalID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querybycreator(
      CreatorLocalID: $CreatorLocalID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        creator
        creatorEmail
        CreatorLocalID
        Country
        Grade
        Subject
        boardLink
        content
        createdAt
        updatedAt
        title
      }
      nextToken
    }
  }
`;
export const queryByGrade = /* GraphQL */ `
  query QueryByGrade(
    $Grade: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByGrade(
      Grade: $Grade
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        creator
        creatorEmail
        CreatorLocalID
        Country
        Grade
        Subject
        boardLink
        content
        createdAt
        updatedAt
        title
      }
      nextToken
    }
  }
`;
export const queryByTeacher = /* GraphQL */ `
  query QueryByTeacher(
    $TeacherID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByTeacher(
      TeacherID: $TeacherID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        TeacherID
        content
        Teacher {
          id
          name
          createdAt
          updatedAt
          email
          boardConfig
          avatar
          personalConfig
          emailOptIn
          paidPlan
        }
        Users {
          nextToken
        }
        ttl
        createdAt
        updatedAt
        TeacherList
      }
      nextToken
    }
  }
`;
export const queryGamePlayBySession = /* GraphQL */ `
  query QueryGamePlayBySession(
    $Session: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGamePlayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryGamePlayBySession(
      Session: $Session
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Session
        type
        content
        createdAt
        updatedAt
        version
        ttl
      }
      nextToken
    }
  }
`;
export const queryByGroupsClassroom = /* GraphQL */ `
  query QueryByGroupsClassroom(
    $Classroom: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByGroupsClassroom(
      Classroom: $Classroom
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        ttl
        name
        content
        Classroom
        SessionID
      }
      nextToken
    }
  }
`;
export const queryByEventsClassroom = /* GraphQL */ `
  query QueryByEventsClassroom(
    $Classroom: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClassroomEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByEventsClassroom(
      Classroom: $Classroom
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        ttl
        type
        event
        For
        State
        Content
        Classroom
      }
      nextToken
    }
  }
`;
export const querybyEngagementClass = /* GraphQL */ `
  query QuerybyEngagementClass(
    $Classroom: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEngagementEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    querybyEngagementClass(
      Classroom: $Classroom
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        luid
        name
        time
        ttl
        Content
        Classroom
      }
      nextToken
    }
  }
`;
export const queryLocalUserByClass = /* GraphQL */ `
  query QueryLocalUserByClass(
    $ClassroomID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocalUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryLocalUserByClass(
      ClassroomID: $ClassroomID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        createdAt
        updatedAt
        CurrentSession {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        CurrentSessionId
        CurrentUserId
        CurrentUser {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        ClassroomID
        ttl
      }
      nextToken
    }
  }
`;
export const queryByParentId = /* GraphQL */ `
  query QueryByParentId(
    $parentBoardID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMultiBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByParentID(
      parentBoardID: $parentBoardID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentBoardID
        parentBoard {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        childBoardID
        childBoard {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sessionSorted = /* GraphQL */ `
  query SessionSorted(
    $SessionID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sessionSorted(
      SessionID: $SessionID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        type
        objType
        ended
        createdAt
        updatedAt
        SessionID
        ttl
        animate
        CreatedBy
        DisableSync
        SVGObj
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
      }
      nextToken
    }
  }
`;
export const byPOwnerSorted = /* GraphQL */ `
  query ByPOwnerSorted(
    $owner: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPersonalPaletteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPOwnerSorted(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        path
        owner
        name
        content
      }
      nextToken
    }
  }
`;
export const byCOwnerSorted = /* GraphQL */ `
  query ByCOwnerSorted(
    $owner: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityPaletteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCOwnerSorted(
      owner: $owner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        path
        owner
        name
        content
      }
      nextToken
    }
  }
`;
export const byUsersSessionSorted = /* GraphQL */ `
  query ByUsersSessionSorted(
    $SessionID: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUsersSessionSorted(
      SessionID: $SessionID
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      nextToken
    }
  }
`;
export const byProfileSorted = /* GraphQL */ `
  query ByProfileSorted(
    $UserProfile: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byProfileSorted(
      UserProfile: $UserProfile
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      nextToken
    }
  }
`;
export const foldersByOwner = /* GraphQL */ `
  query FoldersByOwner(
    $userProfileId: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    foldersByOwner(
      userProfileId: $userProfileId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        userProfileId
        user {
          id
          name
          createdAt
          updatedAt
          email
          boardConfig
          avatar
          personalConfig
          emailOptIn
          paidPlan
        }
        parentFolderPath
      }
      nextToken
    }
  }
`;
export const lEbyRegion = /* GraphQL */ `
  query LEbyRegion(
    $region: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLatencyEndPointsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    LEbyRegion(
      region: $region
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        region
        content
      }
      nextToken
    }
  }
`;
