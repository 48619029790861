import React, { useEffect } from 'react';
import "../home.css"
import "./homeOver.css"
import "./homeOverNew.css"
import "../styles/sale.scss"
import {
    Hidden,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import ReDirectDialog from "../ReDirectDialog";
import { webinarConfigs } from '../Config';
import Header from './Header.js'
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        // backgroundColor: '#3174F500',
        backgroundImage: "url"
    },
    rightSide: {
        marginLeft: 'auto',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        align: 'center',
    },

    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        color: '#5D5770',
        zIndex: 110,
        marginTop: '20px'
    },
    tooltip: {
        display: 'flex',
        alignItems: 'center'
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#3174F5",
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    },
    customArrow: {
        color: "#3174F5",
    },
}))
let topTestimonials = []
let bottomTestimonials = []
export default function Testimonials(props) {
    const classes = useStyles();

    const [tTestimonials, setTTestimonials] = React.useState(false);
    const [bTestimonials, setBTestimonials] = React.useState(false);
    const testimonialsArr = [
        { name: 'Jen Profaizer Crook', content: `For those of us jumping on the virtual train permanently, I pray you guys will be around for years to come. Many of us have found a new love for teaching and being creative through the difficult times. I'm so grateful to have run on to WBC last August and slowly watch this come to the life it is today. I love working with my students real time, course correcting and fixing misconceptions before they have any kind of chance of solidifying in their brains. I love the instant feedback I receive for every student. I no longer need exit tickets, this has solved so many struggles teachers have had in trying to differentiate and teach to every student. I usually give 4 slides of my regular teaching concept, 3 review questions slides, then I put higher level and extensions on the rest of the slides. I'm meeting every student where they are and my highest kids aren't bored, they are getting challenged while everyone is working at their own pace. It's a beautiful thing. Thank you Pawan and team for helping me love teaching virtually!` },
        { name: 'Tracy Davey-Medina', content: `Thank you for everything you did! This platform sent me in a new direction this year and allowed me an unimaginable opportunity to provide my students with as close to an in-person experience as they were going to get this school year. I have had parents tell me they realize this year was a challenge but they don’t feel like their child lost out on a year. Thank you for all of your support and making my ideas a reality!`, info: ` Elizabeth, New Jersey` },
        { name: 'Lora Rogers Lutz', content: `During an unprecedented time around the world and when teachers were called upon to teach virtually to all ages, students with and without home support, paying for our own tech equipment and internet upgrades in our home- you and your company offered a free, amazing service and just kept on giving to support our profession and our students’ learning. Thank you.` },
        { name: 'Kelly McBride', content: `Thank you for everything you've done for teachers and kids! Teaching maths over the computer was miserable until I found whiteboard.chat! You guys really made a huge difference in this school year for me and my students. I can't even tell you how much I appreciate you both!!! With so much respect and appreciation, THANK YOU!`, info: 'Oakland, California' },
        { name: "Jerrilene Smith", content: `Thank you for helping us reach through time and space to work with our students in real time. There has been nothing as powerful as whiteboard chat in helping our students feel like we are right there with them.  Can't wait to use it again next year with a bit more skill and a bunch more tools than this year` },
        { name: 'Debra Strother', content: `Thank you thank you thank you! I discovered WBC back in September. It has been a game changer this remote year. I use it every day in every subject with my third graders. I so appreciate how you respond to all our questions and everything you have created for us!!!` },
        { name: 'Lindsey Foster Behm', content: `Without your support and work ethic I would not have made it through the school year!!! Thank you! I will continue to be an avid supporter!` },
        { name: 'Kristina Uihlein Holzweiss', content: `Whiteboard.chat scores the win for customer service this year!`, },
        { name: 'Sour Day', content: `Hands down the best tool out there in terms of application and cost.` },
    ]
    // {name:'',content:``, info:''},
    // {name:'',content:``, info:''},
    // {name:'',content:``, info:''},
    // {name:'',content:``, info:''},
    const [startNewBoard, setStartNewBoard] = React.useState(false);

    useEffect(() => {
        let randomItems = testimonialsArr
            .sort(function () { return .5 - Math.random() }) // Shuffle array
            .slice(0, 2);
        testimonialsArr.forEach(element => {
            console.log(element.content.length)
            if (element.content.length < 320) {
                element['len'] = element.content.length
                topTestimonials.push(element)
            } else {
                bottomTestimonials.push(element)
            }
            setTTestimonials(topTestimonials)
            setBTestimonials(bottomTestimonials)
        }, []);

        if (topTestimonials.length > 4) {
            let ftt = topTestimonials.sort((a, b) => b.len - a.len)
            console.log('ftt', ftt)
            topTestimonials = ftt.slice(0, 4)
            if (ftt.length > 4) {
                let leftoutItems = ftt.slice(4, ftt.length)
                bottomTestimonials.push(...leftoutItems)
            }
        }

    }, [])


    if (tTestimonials.length > 0 && bTestimonials.length > 0) {
        return (
            <div className={classes.root}>
                <ReDirectDialog />
                <Header header='posRelative' newBoard={startNewBoard} />
                <img alt="logp" src="tsbg.svg" className="newtsbgOrn" ></img>
                <Grid container className="ontop mainBgTestimonial" spacing={0} alignItems="center" justify="center" >
                    <Grid item xs={12} md={6} sm={6} style={{ textAlign: 'center' }}>
                        <div className='tsPageLeftSide'>
                            <div className="tsHeading">Loved by Teachers All Over The World</div>
                            <div className="tsContent">
                                Teachers love us, because we listen and collaborate with them to continuously
                                improve our digital notebook for better student learning and engagement.
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} style={{ textAlign: 'center' }}>
                        <Grid container className="mt-100" spacing={1} >
                            <Grid item md={1} xs={false}>
                            </Grid>
                            <Grid item md={5} xs={false}>
                                <div className="tsContainer1">
                                    <div className="testimonialContent">
                                        <img src='/pinkQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                        <span className='doublequote'>{testimonialsArr[4].content}</span>
                                    </div>
                                    <div className='tsInfo1 pinkBg p-25'>
                                        <span className='tsName'>{testimonialsArr[4].name}</span>
                                        {testimonialsArr[4].info &&
                                            <span className='tsOccupation'>{testimonialsArr[4].info}</span>
                                        }
                                    </div>
                                </div>
                                <div className="tsContainer1">
                                    <div className="testimonialContent">
                                        <img src='/pinkQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                        <span className='doublequote'>{testimonialsArr[8].content}</span>
                                    </div>
                                    <div className='tsInfo1 pinkBg p-25'>
                                        <span className='tsName'>{testimonialsArr[8].name}</span>
                                        {testimonialsArr[8].info &&
                                            <span className='tsOccupation'>{testimonialsArr[8].info}</span>
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={5} xs={false} className="mt-24">
                                <Hidden smDown>

                                    <div className="tsContainer1">
                                        <div className="testimonialContent">
                                            <img src='/pinkQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                            <span className='doublequote'>{testimonialsArr[5].content}</span>
                                        </div>
                                        <div className='tsInfo1 pinkBg p-25'>
                                            <span className='tsName'>{testimonialsArr[5].name}</span>
                                            {testimonialsArr[5].info &&
                                                <span className='tsOccupation'>{testimonialsArr[5].info}</span>
                                            }
                                        </div>
                                    </div>


                                    <div className="tsContainer1">
                                        <div className="testimonialContent">
                                            <img src='/pinkQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                            <span className='doublequote'>{testimonialsArr[6].content}</span>
                                        </div>
                                        <div className='tsInfo1 pinkBg p-25'>
                                            <span className='tsName'>{testimonialsArr[6].name}</span>
                                            {testimonialsArr[6].info &&
                                                <span className='tsOccupation'>{testimonialsArr[6].info}</span>
                                            }
                                        </div>
                                    </div>

                                </Hidden>
                            </Grid>
                            <Grid item md={1} xs={false}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className='secondSectionTs'>
                    <Grid container className="ontop" spacing={0} alignItems="center" justify="center" >
                        <Grid item md={2} xs={false}>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} xs={false} className="mt-24">
                            <div className="tsContainer1">
                                <div className="testimonialContent">
                                    <img src='/skyBlueQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                    <span className='doublequote'>{testimonialsArr[0].content}</span>
                                </div>
                                <div className='tsInfo1 skybluetsBg p-25'>
                                    <span className='tsName'>{testimonialsArr[0].name}</span>
                                    {testimonialsArr[0].info &&
                                        <span className='tsOccupation'>{testimonialsArr[0].info}</span>
                                    }
                                </div>
                            </div>
                            <div className="tsContainer1">
                                <div className="testimonialContent">
                                    <img src='/purpleBlueQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                    <span className='doublequote'>{testimonialsArr[3].content}</span>
                                </div>
                                <div className='tsInfo1 purplebluetsBg p-25'>
                                    <span className='tsName'>{testimonialsArr[3].name}</span>
                                    {testimonialsArr[3].info &&
                                        <span className='tsOccupation'>{testimonialsArr[3].info}</span>
                                    }
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} xs={false}>

                            <div className="tsContainer1">
                                <div className="testimonialContent">
                                    <img src='/orangeQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                    <span className='doublequote'>{testimonialsArr[2].content}</span>
                                </div>
                                <div className='tsInfo1 orangetsBg p-25'>
                                    <span className='tsName'>{testimonialsArr[2].name}</span>
                                    {testimonialsArr[2].info &&
                                        <span className='tsOccupation'>{testimonialsArr[2].info}</span>
                                    }
                                </div>
                            </div>
                            <div className="tsContainer1">
                                <div className="testimonialContent">
                                    <img src='/purpleQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                    <span className='doublequote'>{testimonialsArr[1].content}</span>
                                </div>
                                <div className='tsInfo1 purpletsBg p-25'>
                                    <span className='tsName'>{testimonialsArr[1].name}</span>
                                    {testimonialsArr[1].info &&
                                        <span className='tsOccupation'>{testimonialsArr[1].info}</span>
                                    }
                                </div>
                            </div>
                            <div className="tsContainer1">
                                <div className="testimonialContent">
                                    <img src='/lightBlueQuote.svg' alt='Quotation Icon' className='quoteIcon' />
                                    <span className='doublequote'>{testimonialsArr[7].content}</span>
                                </div>
                                <div className='tsInfo1 lightBlue p-25'>
                                    <span className='tsName'>{testimonialsArr[7].name}</span>
                                    {testimonialsArr[7].info &&
                                        <span className='tsOccupation'>{testimonialsArr[71].info}</span>
                                    }
                                </div>
                            </div>
                        </Grid>


                        <Grid item md={2} xs={false}>
                        </Grid>
                    </Grid>


                </div>
                <Footer setStartNewBoard={setStartNewBoard}/>
            </div>
        )
    } else {
        return null
    }

}
