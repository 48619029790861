import React from 'react';
// import window.googlePicker from "react-window.google-picker"

import UseScript from "./UseScript.js"
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import "./styles/newDesign.css";

import {
    MenuItem,
    Select,
    Button,
} from '@material-ui/core';
import { svgIcons } from './svgIcons';
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    draggableParent: {
        position: "absolute",
        top: '0',
        left: '0',
        width: "calc(100%)",
        height: "calc(100%)",
        padding: "50px 5px 5px 50px"
    },
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        width: "850px",
        height: "800px",
        zIndex: 105,
        position: "absolute",
        color: "#ffffff",
        backgroundColor: "#ffffff"

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "10px",
        backgroundColor: "#E2E6E9 !important",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        paddingBottom: "5px",
        top: 20,
        left: 0,
        backgroundColor: "#ffffff"
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    graph: {
        padding: '5px',
        margin: '5px'
    },
    whiteColor: {
        color: "white"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",
    },
    selectItem: {
        margin: "2px",
        width: "140px",
        borderRadius: "999px",
    },
    listItemName: {
        color: "#FFFFFF !important"
    },
}))
export default function GraphCalc(props) {
    const classes = useStyles();

    const [draggableParentSize, setDraggableParentSize] = React.useState({ width: '100%', height: '100%' });
    const [windowPosition, setWindowPosition] = React.useState({ x: 100, y: 100 });

    React.useEffect(() => {
        if (props.canvasData && props.canvasData.drawing) {
            setDraggableParentSize({
                width: props.canvasData.drawing.scrollWidth,
                height: props.canvasData.drawing.scrollHeight
            })
        }
    }, [props])

    React.useEffect(() => {
        UseScript("https://www.geogebra.org/apps/deployggb.js", "deployggb", loadPicker)
    }, [])

    function loadPicker(val) {
        if (!val) val = 'classic'
        var params = { "appName": val, "width": 800, "height": 700, "showToolBar": true, "showAlgebraInput": true, "showMenuBar": true };
        var ggbApplet = new window.GGBApplet(params, true);
        ggbApplet.inject('ggb-element');
    }

    function handleDrag(e, ui) {
        if ((ui.y < 0 || ui.y > (window.innerHeight - 60)) || (ui.x < 0 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        if (props.cb) props.cb()
    }
    const menuItems = [
        { name: "Classic", value: "classic" },
        { name: "Graphing", value: "graphing" },
        { name: "Geometry", value: "geometry" },
        { name: "3d", value: "3d" },
        { name: "Suite", value: "suite" },
        { name: "Evaluator", value: "evaluator" },
    ]
    const [action, setAction] = React.useState('classic');

    const handleAction = (event) => {
        loadPicker(event.target.value)
        setAction(event.target.value);
    };

    return (
        <div className={classes.draggableParent}
            style={{
            width: draggableParentSize.width,
            height: draggableParentSize.height
            }}
        >
        <Draggable
            handle="strong"
            bounds="parent"
            onDrag={handleDrag}
            onStop={handleDragEnd}
            position={windowPosition}
            defaultClassName="draggable-grid"
            defaultClassNameDragging="draggable2-grid"
            defaultClassNameDragged="draggable3-grid"
        >
            <div id="smallpalette" className={classes.draggableWindow} style={{
                border: '1px solid #E0E5E7'
            }}>
                <div className={classes.draggableTop} style={{ width: "100%", backgroundColor: "#E2E6E9" }}>
                <strong className={clsx(classes.draggableTopHandle, 'justify-center')}>
                        <svg width="30" height="10" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: "0px", cursor: "move"}}>
                            {svgIcons.dots}
                        </svg>
                    </strong>
                </div>
                <div className= "headerPart">
                <div>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={action}
                            onChange={handleAction}
                            fullWidth
                            variant="outlined"
                            className= {classes.selectItem}
                        >
                            {menuItems.map((e) => (
                                <MenuItem key={e.name} style={{ backgroundColor: props.inkColor, color: "#ffffff" }} className= {classes.listItemName} value={e.value}>{e.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className={classes.draggableTopCloseButton}>
                        <CloseIcon style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%",position : "relative",top: "10px", right: "15px", }} onClick={handleClose} />
                    </div>
                </div>

                <div id="ggb-element" className={classes.graph}>
                </div>

            </div>
        </Draggable >
        </div>
    )
}