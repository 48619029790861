import React from 'react';
import * as ib from "./ibdata.js"
import ReactGA from 'react-ga';
import { Snackbar } from "@material-ui/core"
import * as en from "./encDec";

var pendingList = {}
function CopyBoard(props) {
    const [message, setMessage] = React.useState("")
    function aniMess(mess) {
        setMessage(mess)
    }

    React.useEffect(() => {
        if ('match' in props && 'params' in props.match && 'boardid' in props.match.params) {
            let inp = props.match.params.boardid
            if (inp.indexOf("pgNum") !== -1) {
                continueLoad(inp)
            } else {
                en.decrypt_data(inp, continueLoad)
            }
            function continueLoad(bid) {
                ib.getSession(bid).then((res) => {
                    const sess = res.data.getSession
                    if (!sess) {
                        aniMess("Board link is not valid")
                        return
                    }
                    if (sess.id in pendingList) return
                    pendingList[sess.id] = true
                    aniMess("Loading")
                    ib.saveBoard(sess.name, null, bid, done)
                    function done(id) {
                        ReactGA.event({
                            category: 'Teacher',
                            action: "OpenBoardCopy"
                        });
                        props.history.push("/board/" + id)
                        return
                    }
                })
            }
        }
    }, [props, props.match])

    return (
        <div className="session-table">
            <Snackbar
                open={message !== ""}
                onClose={() => setMessage("")}
                message={message}
                key={"top center"}
            />
        </div>

    )
}



export default CopyBoard