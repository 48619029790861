import {Auth} from 'aws-amplify'
import * as mylocalStorage from "./mylocalStorage";

export function userHasNoBoardExpiry() {
    if (Auth && Auth.user) {
        if (Auth.user.attributes && Auth.user.attributes.email === "feedback@whiteboard.chat") {
            return true;
        }

        var paidUserPlan = mylocalStorage.getItem('paidPlan');

        if (paidUserPlan && paidUserPlan.startsWith('gold')) {
            return true;
        }
    }
    return false;
}

export function getUserEmail() {
    if (Auth && Auth.user) {
        if (Auth.user.attributes && Auth.user.attributes.email) {
            return Auth.user.attributes.email;
        }
    }
    return null;
}

// Takes a UserProfile object from DynamoDB as argument.
export function getChargeBeeId(userProfile) {
    var userId = userProfile.id;

    if (userId.startsWith("Microsoft")) {
        userId = userId.replace("Microsoft", "MS");
    }

    return userId;
}
