import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { Divider, TextField, Box, InputBase } from '@material-ui/core';
import * as ib from "./ibdata"
import GroupIcon from '@material-ui/icons/Group';
import "./styles/avatar.css";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 200,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    text: {
        width: 290,
        height: 40,
        background: "#FFFFFF",
        border: "1px solid #E0E5E7",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        borderRadius: "999px",
    },
    text1: {
        width: 320,
        height: 40,
        background: "#FFFFFF",
        border: "1px solid #E0E5E7",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        borderRadius: "999px",
    },
    forminput: {
        width: "525px",
        display: "flex",
        flexDirection: "row",
        // paddingLeft:"20px",
        justifyContent: "space-between",
        alignItems: "center",
    },
    arrowButton: {
        margin: "10px",
        padding: "8px",
        color: "#ffffff",
        fontSize: "16px",
        fontFamily: 'Lato',
        fontWeight: '600',
        textTransform: "none",
        cursor: "pointer",
        display: 'inline-flex',
        borderRadius: "999px",
        "&:hover": {
            color: '#2F3D5B !important',
        },
    },
    groupbutton: {
        width: "185px",
        height: "40px",
        color: "#FFFFFF",
        background: "#3174F5",
        fontSize: "16px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        border: "1px solid #C8CEDA",
        borderRadius: "999px",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
    button2: {
        margin: "8px 0px 8px 0px",
        width: "212px",
        height: "40px",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: 'Lato',
        fontWeight: '600',
        textTransform: "none",
        display: 'inline-flex',
        border: "1px solid #C8CEDA",
        borderRadius: "999px",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

export default function TransferList(props) {
    const classes = useStyles();
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const [text, setText] = React.useState("");
    const [btext, setBText] = React.useState("Create Group");

    const [alreadyGrouped, setAlreadyGrouped] = React.useState([])
    const [assignment, setAssignment] = React.useState({})

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const [ids, setIds] = React.useState("")
    function pasteText(e) {
        e.preventDefault()
        setIds(e.clipboardData.getData('text/plain'))
    }
    function setID(e) {
        setIds(e.target.value)
    }
    function saveClick(e) {
        e.preventDefault()
        var bb = ids.split(",")
        for (let j = 0; j < bb.length; j++) {
            var f2 = bb[j].trim()
            let ff = [{ id: f2, type: "precog", name: f2 + " preassigned" }]
            ib.createNewGroup(f2, ff, function (done) {})
        }
        if (props.cb) props.cb(true)
    }
    function autoGroupSave(e) {
        e.preventDefault()
        try {
            var r = parseInt(ids2)
        } catch {
            return
        }
        var added = 0
        var arr = []
        var lastGrp = 1
        for (let i = 0; i < props.parts.length; i++) {
            const p = props.parts[i]
            if (p.teacher) {
                continue
            }
            arr.push(p)
            added++
            if (added >= r) {
                var copy = JSON.parse(JSON.stringify(arr))
                ib.createNewGroup("auto-" + lastGrp, copy, function (done) {
                })
                lastGrp++
                arr = []
                added = 0
            }
        }
        if (added) {
            //left over 
            var copy = JSON.parse(JSON.stringify(arr))
            ib.createNewGroup("auto-" + lastGrp, copy, function (done) {
            })
            arr = []
            added = 0
        }
        if (props.cb) props.cb(true)
    }
    const [ids2, setIds2] = React.useState("")
    function pasteText2(e) {
        e.preventDefault()
        setIds2(e.clipboardData.getData('text/plain'))
    }
    function setID2(e) {

        setIds2(e.target.value)
    }
    function getID(m) {
        if (m && m.localID) {
            return m.localID
        }
        return m.id
    }
    React.useEffect(() => {
        const gl = props.groupList;
        var wl = [...props.parts]
        var fl = []
        var rl = []
        if (props.gr && props.gr.gname && gl) {
            setBText("Update Group")
            setText(props.gr.gname.name)
            // move people in this group to the right
            gl.forEach((g) => {
                if (g.content && g.name === props.gr.gname.name) {
                    var mems = JSON.parse(g.content).members;
                    rl = mems
                    setRight(rl)
                }
            })
        } else {
            setBText("Create Group")
        }
        for (let i = 0; i < wl.length; i++) {
            var w = wl[i]
            var found = false
            for (let j = 0; j < rl.length; j++) {
                var r = rl[j]
                if (getID(w) === getID(r)) {
                    //found 
                    found = true
                    break
                }
            }
            if (!found) {
                fl.push(w)
            }
        }
        setLeft(fl)
    }, [props.parts, props.gr, props.groupList])
    React.useEffect(() => {
        const gl = props.groupList;
        var groupedMems = []

        gl.forEach((g) => {
            if (g.content) {
                var mems = JSON.parse(g.content).members;
                if (props.gr && props.gr.gname && props.gr.gname.name === g.name) {
                } else {
                    mems.forEach((m) => {
                        if (!groupedMems.includes(getID(m))) {
                            groupedMems.push(getID(m));
                            setAssignment(p => {
                                p[getID(m)] = g.name;
                                return { ...p }
                            })
                        }
                    })
                }
            }
        });
        if (groupedMems.length) {
            setAlreadyGrouped(p => ([...p, ...groupedMems]))
        }
    }, [props.groupList, props.gr]);

    function Clicked() {
        if (props.gr && props.gr.gname) {
            var ff = JSON.parse(JSON.stringify(props.gr.gname))
            const content = JSON.stringify({ members: right })
            ff.content = content
            ib.updateGroupForClass(ff, right);
        } else {
            ib.createNewGroup(text, right, function (done) {
                if (props.cb) props.cb(done)
            })
        }
    }
    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title, items) => (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value}-label`;

                    return (
                        <>
                            {alreadyGrouped.includes(getID(value)) ?
                                <>
                                    <ListItem key={value} role="listitem">
                                        <ListItemIcon>
                                            <Tooltip title={"Assigned to " + assignment[getID(value)]} arrow placement="right">
                                                <GroupIcon />
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={value.name} />
                                    </ListItem>
                                </> :
                                <>
                                    <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={checked.indexOf(value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={value.name} />
                                    </ListItem>
                                </>
                            }
                        </>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <>
            <div className="formGroup">
                <form onSubmit={saveClick} className={classes.forminput}>
                    <InputBase
                        className={classes.text}
                        style={{ padding: "0px 25px" }}
                        placeholder="Group Names separated by commas"
                        value={ids}
                        onPaste={pasteText}
                        onChange={setID}
                    />
                    <Button
                        size="small"
                        style={{ backgroundColor: props.inkcolor }}
                        className={classes.button2}
                        onClick={saveClick}
                    >
                        PreCreate Groups</Button>
                </form>

                <form onSubmit={autoGroupSave} className={classes.forminput}>
                    <InputBase
                        style={{ padding: "0px 25px" }}
                        className={classes.text}
                        value={ids2}
                        placeholder="Number of students in a group"
                        onPaste={pasteText2}
                        onChange={setID2}
                    />
                    <Button
                        className={classes.button2}
                        style={{ backgroundColor: props.inkcolor }}
                        onClick={autoGroupSave}
                    >Auto Create Groups</Button>
                </form>
            </div>
            <div className="gridview">
            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                <Grid item>{customList('Choices', left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.arrowButton}
                            style={{ backgroundColor: props.inkcolor }}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                                <ArrowForwardIcon
                                    style={{ color: "FFFFFF" }} />
          </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.arrowButton}
                            style={{ backgroundColor: props.inkcolor }}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                                <ArrowBackIcon
                                    style={{ color: "FFFFFF" }} />
          </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList('Chosen', right)}</Grid>
            </Grid>
            </div>

            <div className="styling">
                <InputBase
                    style={{ padding: "0px 25px" }}
                    className={classes.text1}
                    placeholder="Group Name"
                    required={true}
                    onChange={(event) => setText(event.target.value)}
                    value={text}
                />
                <Button
                    disabled={text.length === 0}
                    className={classes.groupbutton}
                    onClick={() => Clicked()}
                    style={{ backgroundColor: props.inkcolor }}
                >
                    {btext}
                </Button>
            </div>

        </>
    );
}