import React, { useState } from 'react';
import "../home.css"
import "./homeOver.css"
import ReactGA from 'react-ga';
import {
    Grid,
    List,
    ListItemText,
    ListItem,
    ListItemIcon,
    Divider,
    Button,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BookIcon from '@material-ui/icons/Book';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import BackspaceIcon from '@material-ui/icons/Backspace';
import * as mylocalStorage from "../mylocalStorage"
import NavigationIcon from '@material-ui/icons/Navigation';
import HelpIcon from '@material-ui/icons/Help';
import YouTubeIcon from '@material-ui/icons/YouTube';
import FunctionsIcon from '@material-ui/icons/Functions';
import DescriptionIcon from '@material-ui/icons/Description';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import GenericDialog from '../GenericDialog'
const currentOptions = [
    {
        primary: "Navigating Whiteboard",
        icon: <NavigationIcon />,
        // secondary: "Detailed documentation of several features and FAQ",
        link: "https://drive.google.com/file/d/1TvcUYWayQKNRK0UhPqSkVx-gnP6oLV7B/view?usp=sharing"
    },
    { primary: "Webinar Training", 
    icon: <HelpOutlineIcon />,
    //  secondary: "Video to show the basic functions of the whiteboard",
      link: "https://forms.gle/2uuHV5ucM987ehKV8" },

    { primary: "What's New", 
    icon: <WhatshotIcon />,
    //  secondary: "Video to show the teaching multiboard & breakout usecase ", 
     link: "https://medium.com/epiphani/whiteboard-chat-latest-news-4ab127795a7b" },

    {
        primary: "Complete Feature List",
        icon: <MenuBookIcon />,
        // secondary: "How whiteboard can be used to assist with distance learning",
        link: "https://drive.google.com/file/d/1BfsvA9-94AdoVLdYzXMVY5XAGZOf5clD/view?usp=sharing"
    },
    {
        primary: "Top 100 Features",
        icon: <BookIcon />,
        // secondary: "Create classes, Create workbook with pdf, breakout room, coach independently",
        link: "https://drive.google.com/file/d/1uT1dgONKcnkklrv0mf1PSXe6cLzEOvXN/view?usp=sharing"
    },
    {
        primary: "Accessing Whiteboard from Microsoft Teams",
        icon: <DescriptionIcon />,
        // secondary: "Use whiteboard.chat to create Polls, Send Links to students",
        link: "https://medium.com/epiphani/whiteboard-chat-in-microsoft-teams-74015c41ca9f"
    },
    {
        primary: "Teacher's Guide",
        icon: <DescriptionIcon />,
        // secondary: "Use whiteboard.chat to create Polls, Send Links to students",
        link: "https://drive.google.com/file/d/1LPvUTVjaRRo2-1_mulaKPvyHi1YOpQPs/view?usp=sharing"
    },
    {
        primary: "Student's Guide",
        icon: <DescriptionIcon />,
        // secondary: "Description of features functions ",
        link: "https://drive.google.com/file/d/1rSYgRKFu1l-Q-gH_JbFY52RvwhaliKyL/view?usp=sharing"
    },
    {
        primary: "Math Input Guide",
        icon: <FunctionsIcon />,
        // secondary: "Select objects with lasso, Draw lines, Break out rooms, Video conf, Draw Dot, Right button to erase, Paste images directly on canvas, Chat with students 1:1, Raise hand",
        link: "https://drive.google.com/file/d/1lJUjhgtgxjwPilO06W2qiwPeAAdcNn4K/view?usp=sharing"
    },
    {
        primary: "FAQs",
        icon: <QuestionAnswerIcon />,
        // secondary: "You can use voice, video and chat for free along with the whiteboard",
        link: "https://medium.com/epiphani/frequently-asked-questions-whiteboard-chat-1ee1e0616182"
    },
    {
        primary: "All Help Articles",
        icon: <HelpIcon />,
        // secondary: "You can use voice, video and chat for free along with the whiteboard",
        link: "https://medium.com/epiphani/whiteboard-chat-video-tutorials-faqs-and-detailed-help-articles-e93e792ea339"
    },
    {
        primary: "All Tutorial Videos",
        icon: <YouTubeIcon />,
        // secondary: "You can use voice, video and chat for free along with the whiteboard",
        link: "https://www.youtube.com/whiteboardchat/videos"
    },
]
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        margin: '0 5px'
    },
    rightSide: {
        marginLeft: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        align: 'center',
    },
    listItemText: {
        fontSize: '1.0em',//Insert your required size
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    }
}))
export default function Reasons(props) {
    const classes = useStyles();
    const [IsStorageReset, setIsStorageReset] = useState(false);
    React.useEffect(() => {
        ReactGA.pageview('/help')
    })
    // function newBoard(e) {
    //     props.history.push("/")
    // }
    function handleClick(e) {
        ReactGA.event({
            category: 'Help',
            action: e.primary
        });
        if (!e.link) return
        window.open(e.link)
    }
    function removeStorage() {
        mylocalStorage.clear();
        setIsStorageReset(true)
    }
    function showReset() {
        if (window.insideMSTeams === true)
            return false
        return true
    }
    function showFeedbackEmail() {
        if (window.insideMSTeams === true)
            return false
        return true
    }
    return (
        <div className={classes.root}>

            <Grid container className="ontopReasons mt-50" spacing={1} >
                <Grid item md={3} xs={false}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.paper} style={{ paddingBottom: 0 }}>
                        <div className="TeachMsg">Help & What is New</div>
                    </div>
                    <List dense m="auto">
                        {currentOptions.map((option) => (
                            <React.Fragment key={option}>
                                <ListItem dense className='Helplist' button key={option} onClick={() => handleClick(option)} >
                                    {option.icon && (
                                        <ListItemIcon className={classes.icon}> {option.icon} </ListItemIcon>
                                    )}
                                    <ListItemText classes={{ primary: 'Helplist' }} primary={option.primary} secondary={option.secondary} />

                                </ListItem>
                                <Divider className={classes.divider} />
                            </React.Fragment>
                        ))}
                    </List>
                </Grid>
                <Grid item md={3} xs={false}>
                </Grid>
            </Grid>
           
            <div className='newheader2'>
                <Button onClick={(event) => { props.history.push("/"); }} className=''>
                    <img src='/logo_title.svg' alt='whiteboard Logo' className='cursor-pointer' />
                </Button>
                {showReset() ?
                    <Button onClick={removeStorage} className='resetButton btnStyle'>
                        <BackspaceIcon />
                        <Typography className="text-16 homebtn">Reset Storage</Typography>
                    </Button>
                    : null}
            </div>
            {/* <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="100vw"
                className="bottomBoxHelp"
                textAlign="center"
            >
                <div style={{ alignItems: "center" }}>
                    <div className="headWBChatLast" >
                        Whiteboard.chat
                    </div>
                    <div
                        className="startDrawingButtonLast"
                        onClick={newBoard}
                    >
                            Start Using
                         </div>
                    <div>
                        <div className="copyright">
                        <a className="link" href="mailto: feedback@whiteboard.chat"> feedback@whiteboard.chat</a>
                        </div>
                    </div>

                </div>
            </Box> */}
            {IsStorageReset && <GenericDialog msg='Storage reset is done!' open={IsStorageReset} setIsStorageReset={setIsStorageReset} />}
        </div>

    )
}