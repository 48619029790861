import React, { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Button,
    IconButton,
    TextField,
    Hidden,
    Paper,
    AppBar,
} from "@material-ui/core";
import * as ib from "./ibdata.js"
import AppsIcon from '@material-ui/icons/Apps';
import ListIcon from '@material-ui/icons/List';
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import './index.css';
import "./SessionTableFileExplorer.css";
// FileBrowser
import CommunityBoard from "./CommunityBoard.js";
import SavedBoards from "./SavedBoards.js";
import { useSelector } from 'react-redux'
import {processBoards} from "./comboards"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as BoardUtil from './BoardUtility'
import * as mylocalStorage from "./mylocalStorage";

var gFoldersRead = false;

// FileBrowser
var gFiles = [];
var gFolders = {};
var gFolderNotEmptyMap = {};
var gSessionIdToKeyMap = {};
var gSessionKeyToIdMap = {}
var gClassSessionMap = {};
var gBoardOwners = {};
var gSessions = [];

export default function SessionTable(props) {
    var [sessions, setSession] = useState([])
    const [tileMode, setTileMode] = useState(props.tileMode);
    const [files, setFiles] = useState([]);
    const [searchVal, setSearchVal] = useState('')
    const [boardSearchVal, setBoardSearchVal] = useState('')
    const authUser = useSelector((state) => state.user);
    const [tabSelected, setTabSelected] = useState(0)

    let curColor = mylocalStorage.getItem("mycolor")

    if (!(curColor)) {
        curColor = "#8f3ee0";
    }


    const [boardScrollHt, setBoardScrollHt] = useState(450)
    useEffect(() => {
        if (window && window.innerHeight) {
            setBoardScrollHt(window.innerHeight - 200)
            //200px is the height of the other above content
        }
    }, [window])


    function localDateFormat(date) {
        const tstamp = new Date(date);
        var options = {
            day: 'numeric',
            month: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        var tstr = tstamp.toLocaleString('en-US', options);
        return tstr
    }
    function getMaxCount(sessArr) {
        let max = 0;
        for (let idx in sessArr) {
            let item = sessArr[idx];
            let us = item.name.match(/^unsaved\(([\d]+)\)$/);

            if (us) {
                let cur = parseInt(us[1]);
                if (cur > max) max = cur;
            }
        }
        return max;
    }



    function renameUnsaved(sessArr, max) {
        let cur = max + 1;

        for (let idx in sessArr) {
            let item = sessArr[idx];

            if (item.name === "unsaved") {
                item.name = `unsaved(${cur})`;
                ib.setName(item, undefined, true);
                cur += 1;
            }
        }

        return max;
    }

    function cleanupBoardName(name, count) {
        return name.replace(/[/\\]/g, "-");
    }
    function getSessObjInternal(tmpSess, count) {
        var a = {
            id: tmpSess.id,
            modified: new Date(tmpSess.updatedAt).getTime(),
            expire: tmpSess.ttl * 1000,
            size: "",
            isDraggable: true,
        }

        if (tmpSess.Classroom === tmpSess.parentID) {
            a.isClassroom = true;
        }

        a.key = cleanupBoardName(tmpSess.name, count);

        if (tmpSess.folderID && tmpSess.folderID !== "") {
            gFolderNotEmptyMap[tmpSess.folderID] = true;
            let paths = getFolderNamePath(gFolders[tmpSess.folderID])
            a.key = `${paths.namePath}/${a.key}`
        }

        return a;
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }


    function getClassSessObjInternal(sessArr, tmpSess, count) {
        let tmpArr = [];
        var b = cleanupBoardName(tmpSess.name, count);
        var className;

        if (tmpSess.folderID && tmpSess.folderID !== "") {
            gFolderNotEmptyMap[tmpSess.folderID] = true;
            let paths = getFolderNamePath(gFolders[tmpSess.folderID])
            className = `${paths.namePath}/${b}`
        } else {
            className = `${b}`
        }

        gSessionIdToKeyMap[tmpSess.id] = className;
        gSessionKeyToIdMap[className] = tmpSess.id;

        for (let idx in sessArr) {
            let item = sessArr[idx];
            var a = {
                id: `${tmpSess.id}|${item.id}`,
                modified: new Date(item.updatedAt).getTime(),
                expire: item.ttl * 1000,
                size: gBoardOwners[item.id] ? gBoardOwners[item.id] : "",
                isClassroom: false,
                classSessId: tmpSess.id,
                studentSessId: item.id,
                isStudent: true,
                isDraggable: false,
                key: `${className}/${cleanupBoardName(item.name)}`,
                classModified: new Date(tmpSess.updatedAt).getTime(),
                classExpire: tmpSess.ttl * 1000,
            }
            tmpArr.push(a);
        }

        return tmpArr;
    }

    function getSessObj(tmpSess, count) {
        var a;
        if (gClassSessionMap[tmpSess.id] && !(isEmpty(gClassSessionMap[tmpSess.id]))) {
            a = getClassSessObjInternal(gClassSessionMap[tmpSess.id], tmpSess, count,)
        } else {
            a = [getSessObjInternal(tmpSess, count)];
        }
        return a;
    }


    function getFolderNamePath(folder) {
        let a = folder.parentFolderPath;
        let r = ""
        let i = "";
        let n = "";

        if (a && a !== "") {
            let s = a.split("/");

            for (let idx in s) {
                let p = gFolders[s[idx]];

                if (!p) {
                    // Move the folder to the the root folder
                    delete folder['user']
                    folder.parentFolderPath = ""
                    ib.updateFolder(folder)
                    return { idPath: folder.id, namePath: folder.name };
                }

                if (r === "") {
                    r = p.name;
                } else {
                    r = r + `/${p.name}`
                }
            }
        }

        if (r !== "") {
            i = `${folder.parentFolderPath}/${folder.id}`;
            n = `${r}/${folder.name}`
        } else {
            i = folder.id;
            n = folder.name;
        }

        return { idPath: i, namePath: n };
    }
    function updateSessions(tSessions, tClassSessions, tBoardOwners) {
        if (gFoldersRead) {
            gFolderNotEmptyMap = {};
            let newFiles = [];
            let maxCount = getMaxCount(gSessions);
            renameUnsaved(gSessions, maxCount);
            for (let idx in gSessions) {
                let tmpSess = gSessions[idx];
                if (tmpSess.folderID && tmpSess.folderID !== "") {
                    if (!(tmpSess.folderID in gFolders)) {
                        tmpSess.folderID = "";
                        ib.setName(tmpSess, null)
                    }
                }
                let a = getSessObj(gSessions[idx], idx, false);
                newFiles = newFiles.concat(a);
            }

            for (let fid in gFolders) {
                if (!gFolderNotEmptyMap[fid]) {
                    let paths = getFolderNamePath(gFolders[fid]);
                    // Folders have to end in a "/"
                    newFiles.splice(0, 0, { key: paths.namePath + "/" });
                }
            }

            if ((gFiles.length !== newFiles.length) || (tBoardOwners)) {
                gFiles = newFiles;
                setFiles(gFiles);
            }
        }
    }


    function rowClick(row) {
        row.edit = true
        gSessions = [...sessions];
        updateSessions(gSessions, null, null);
        setSession(gSessions);
    }
    function setText(name, row) {
        row.name = name
        gSessions = [...sessions];
        updateSessions(gSessions, null, null);
        setSession(gSessions);
    }

    function openRow(row) {
        // Invert the state
        row.open = !row.open;
        gSessions = [...sessions];
        updateSessions(gSessions, null, null);
        setSession(gSessions);
    }

    function handleRefresh(row) {
        ib.refreshBoard(row.id)
    }


    const handleViewMode = () => {
        setTileMode(!tileMode)
        setBoardSearchVal('')
    }

    RegExp.escape = function (s) {
        if (s) {
            return s.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        }
    };

    function OnChangeSearch(evt) {
        setSearchVal(evt.target.value)
        const regEx = new RegExp(RegExp.escape(evt.target.value), 'gi');
        setBoardSearchVal(regEx)
        // setfilteredData1(sessions.filter(d => d.name.match(regEx) || (localDateFormat(d.updatedAt) !== null && localDateFormat(d.updatedAt).match(regEx))));
    }

    useEffect(() => {
        if (tabSelected === 1) {
            setTileMode(true)
            //by default tile mode for the community board
        } else {
            setTileMode(false)
        }
    }, [tabSelected])

    const handleChange = (event, tabValue) => {
        setTabSelected(p => {
            if (p !== tabValue) {
                setSearchVal('')
                setBoardSearchVal('')
            }
            return tabValue
        });
    };

    function getUserDisplayString() {
        let retStr = "";
        if (props.userObj) {
            retStr = ib.getUserString(props.userObj.id, props.userObj.email);
            retStr = ` - ${retStr}`
        }

        return retStr;
    }

    async function processComBoard() {
        var oldBoards = await ib.listCommunityBoardsSync()
        var oldF = {}
        for (let i=0; i< oldBoards.length; i++) {
            const oldb = oldBoards[i]
            oldF[oldb.boardLink] = oldb
            console.log("Found OLD", oldb)
        }
        var readBoards = processBoards()
        for (let i=0; i< readBoards.length; i++) {
            var ff = readBoards[i]
            console.log("processing community boards", ff)
            delete ff['Timestamp']
            var oldL = ff.link.split("/copy/")[1]
            delete ff['link']
            ff.boardLink = oldL
            if (oldL in oldF) {
                console.log("FOUND ALREADY")
            } else {
                ib.createCommunityBoard(ff)
            }
        }
    }

    function importCommunityCSV() {
        processComBoard()
    }

    return (<div>

        <Grid id="runBooks" container direction="column" justify="center" >
            <Grid item className="w-full overflow-y-auto">
            </Grid>
        </Grid>
        <Grid container>
            <Grid item md={1} lg={1} className='relative'>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10} className='relative'>
                <Hidden smUp>
                    <div className='flex justify-between mb-10'>
                        <Typography component={'span'} className='textContent mobBoardTitle mt-16'>{"Saved Boards" + getUserDisplayString()}</Typography>
                    </div>
                    <div className='flex justify-between ml-8 mr-8 '>
                        {tileMode === true && <TextField
                            className="flex"
                            label="Search"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(ev) => OnChangeSearch(ev)}
                        />}

                        {tabSelected === 0 && tileMode === false && <AppsIcon className='text-32 ml-8 togglePos' onClick={handleViewMode} />}
                        {tabSelected === 0 && tileMode === true && <ListIcon className='text-32  ml-8 togglePos' onClick={handleViewMode} />}
                    </div>
                </Hidden>
                <Hidden xsDown>
                    <div className='flex justify-between  mb-10'>
                        <Typography component={'span'} key="1" className='textContent mobBoardTitle mt-16'>{"Saved Boards" + getUserDisplayString()}</Typography>
                        <div key="2" className='cursor-pointer ml-8'>
                            {tileMode === true && <TextField
                                className="flex"
                                label="Search"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchVal}
                                onChange={(ev) => OnChangeSearch(ev)}
                            />}

                            {tabSelected === 0 && tileMode === false && <AppsIcon className='text-32 ml-8 togglePos' onClick={handleViewMode} />}
                            {tabSelected === 0 && tileMode === true && <ListIcon className='text-32  ml-8 togglePos' onClick={handleViewMode} />}

                        </div>

                    </div>
                </Hidden>
                <Paper square>
                    <AppBar position="static" style={{ backgroundColor: curColor, boxShadow: 'none' }} className='mb-10'>
                        <div>

                            <Tabs value={tabSelected} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label="My Saved Boards" style={{ fontSize: '0.5rem' }}/>
                                <Tab label="Community Boards" style={{ fontSize: '0.5rem' }}/>
                            </Tabs>
                            <Button onClick={() => importCommunityCSV()}
                                style={{ display: authUser.attributes.email === "feedback@whiteboard.chat" ? 'inline' : 'none', marginLeft: '100px' }}>
                                    Import Community CSV
                            </Button>
                        </div>
                    </AppBar>
                    {tabSelected === 0 ?

                        <SavedBoards user={props.user} userObj={props.userObj} history={props.history}
                            tileMode={tileMode} boardSearchVal={boardSearchVal} />

                        :

                        < CommunityBoard user={props.user} boardScrollHt={boardScrollHt} localDateFormat={localDateFormat}
                            getIconColor={BoardUtil.getIconColor} handleRefresh={handleRefresh} rowClick={rowClick} openRow={openRow}
                            boardSearchVal={boardSearchVal} history={props.history} />
                    }
                </Paper>

            </Grid>
        </Grid>

    </div>)
}