import React from 'react';
import { SketchPicker } from 'react-color';
import Draggable from 'react-draggable';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button,
    Select,
    MenuItem,
    FormControl
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import * as ib from './ibdata';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const pastels = [
    "#FA28FF", "#16A5A5", "#004899", "#F4B400",
    "#71C001", "#9F8FCE", "#F0728A", "#EF6C35",
    "#ED7D31", "#4A86E3", "#913791", "#0F9D58",
    "#882ff6", "#3174F5", "#FDDFDF", "#FCF7DE",]
const useStyles = makeStyles((theme) => ({

    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        position: "absolute",
        width: "250px",
        height: "418px",
        zIndex: 105,
        color: "#ffffff",
        boxSizing: "border-box",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16)",
        background: "#FFFFFF",
    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "7px",
        backgroundColor: "#E2E6E9",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius:"999px",
        boxShadow: "0px 1px 20px rgba(13, 20, 22, 0.1), 0px 0px 8px 2px rgba(13, 20, 22, 0.02)",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        paddingBottom: "5px",
        top: 20,
        left: 0,
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    picker: {
        width:"200px",
        margin: "8px 14px",
        padding: "0px 10px 0px !important",
        boxSizing: "initial",
        background: "#FFFFFF",
        boxShadow: "none !important",
    },
    select: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "210px",
        marginLeft: "20px",
    },
    formControl: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3174F5"
        }
    },
}))
export default function FillColorPicker(props) {
    const classes = useStyles();

    const xpos = window.innerWidth / 2 - 115
    const [action, setAction] = React.useState('fill');

    const handleAction = (event) => {
        setAction(event.target.value);
    };
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: 140 });

    var deb = null
    function colorChangeComplete(c) {
        ib.debounceClick({ timer: deb, function: colorChange, args: c })
    }

    function colorChange(c) {
        ReactGA.event({
            category: 'User',
            action: "Color Change Fill"
        });
        props.cb({ action: 'colorPick', color: c.hex, where: action })
    }

    function handleDrag(e, ui) {
        if ((ui.y < 120 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        props.cb(null)
    }
    function delBackground() {
        props.cb({ action: "removeFill", where: action })
    }
    return (
        <Draggable
            handle="strong"

            onDrag={handleDrag}
            onStop={handleDragEnd}
            position={windowPosition}
            defaultClassName="draggable344-color"
            defaultClassNameDragging="draggable344-color"
            defaultClassNameDragged="draggable444-color"
        >
            <div id="smallpalette" className={classes.draggableWindow} 
            style={{
                border: '0.5px solid rgba(0, 0, 0, 0.08)'
                // backgroundColor: '#ffffff44'
            }}>
                <div className={classes.draggableTop} style={{ width: "100%"}}>
                    <strong className={classes.draggableTopHandle}>
                    <MoreHorizIcon style={{ color: "#7D8FB6", position: "absolute", top: "-7", left: "115px", cursor: "move" }} />
                    </strong>
                </div>
                <div className={classes.cp}>
                    <div className={classes.select}>
                    <FormControl className={classes.formControl} variant="outlined">
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={action}
                        onChange={handleAction}
                        className="formSelect"
                        style={{width: "160px", borderRadius: "999px"}}
                    >
                        <MenuItem style={{ color: props.inkColor, backgroundColor: "#ffffff" }} value="fill">Fill Color</MenuItem>
                        <MenuItem style={{ color: props.inkColor, backgroundColor: "#ffffff" }} value="stroke">Stroke Color</MenuItem>
                    </Select>
                    </FormControl>
                    
                    <CloseIcon style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%" }} onClick={handleClose} />
                    </div>
                    <SketchPicker
                        disableAlpha
                        className={classes.picker}
                        presetColors={pastels}
                        color={props.color ? props.color : "#ffffff"}
                        onChangeComplete={colorChangeComplete} />
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => delBackground()}
                        style={{ backgroundColor: props.inkColor }}
                        className={classes.button}
                    >
                        Remove
                                        </Button>
                </div>

            </div>

        </Draggable>
    );
}