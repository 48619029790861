import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { InputBase, Typography} from '@material-ui/core';


function SaveDialog(props) {
  const [text, setText] = React.useState("");

  const handleSave = () => {
    if (props.cb) props.cb(text)
  };

  const handleClose = () => {
    if (props.cb) props.cb(null)
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Save a copy of the board</DialogTitle>
        <DialogContent>
          <DialogContentText  style={{fontSize: "14px"}}>
            Please give the board a name to save 
          </DialogContentText>
          <InputBase
            autoFocus
            margin="dense"
            id="name"
            placeholder="Board Name"
            type="text"
            onChange={(event)=> setText(event.target.value)}
            fullWidth
            className= "inputname"
          />
        </DialogContent>
        <DialogActions>
          <div className="positioning">
              <Typography
                size="small"
                style={{ marginRight: "24px", fontSize: "16px", }}
                onClick={handleClose}
                className='formCancel'
              >
                Cancel
              </Typography>
              <Button onClick={handleSave} color="primary" className="createButton" style={{ backgroundColor: props.inkColor }} >
                Save
              </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SaveDialog