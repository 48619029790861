import React from 'react'
import { useSelector } from 'react-redux'
import * as ib from "./ibdata.js"
import ReactGA from 'react-ga';
import { Typography } from '@material-ui/core';
import CreateSession from './CreateSession.js';

function CreateJoinBoard(props) {
    const authUser = useSelector((state) => state.user);
    const [createBoard, setCreateBoard] = React.useState(null);
    const [joinBoard, setJoinBoard] = React.useState(null);
    const [errorState, setErrorState] = React.useState(null);

    React.useEffect(() => {
        if ('match' in props && 'params' in props.match && 'boardid' in props.match.params) {
            var url = window.location.href
            // var coteacher = false
            try {
                console.log("props.match = ", props.match)
                var iduse = props.match.params.boardid
                console.log("iduse = ", iduse)
                if (iduse.indexOf("pgNum") === -1) {
                    iduse += "-pgNum-1"
                }
                ib.getSession(iduse).then((res) => {
                    console.log("res.data = ", res.data)
                    const sess = res.data.getSession
                    if (!sess) {
                        console.log("Creating board = ", { newBid: iduse })
                        setCreateBoard({ newBid: props.match.params.boardid })
                        return
                    } else {
                        setJoinBoard(true)
                        const joinURL = "/join/"+iduse
                        console.log("Joining board joinURL=", joinURL)
                        props.history.push(joinURL);
                        return
                    }
                }) 
            } catch {
                setErrorState("No matching board found. You may have to close this tab and add a new one.")
            }
        }
    }, [props.match])

    return(
        <>
        { createBoard ? <Typography>Launching board...</Typography> : null }
        { createBoard ? <CreateSession {...props} newBid={ createBoard.newBid }/> : null }
        { joinBoard ? <Typography>Joining Board...</Typography> : null }
        { errorState ? <h3>{errorState}</h3> : null }
        </>
    )
}

export default CreateJoinBoard;