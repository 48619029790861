import React from 'react';
import { CompactPicker } from 'react-color';
import { useSelector } from 'react-redux'
import Draggable from 'react-draggable';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button
} from '@material-ui/core';
import { useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as mylocalStorage from "./mylocalStorage";

const useStyles = makeStyles((theme) => ({

    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        width: "255px",
        height: "118px",
        zIndex: 1200,
        position: "absolute",
        color: "#ffffff",

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "row",
        position: "fixed",
        paddingBottom: "5px",
        top: 20,
        left: 0,
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
}))
export default function ColorPicker(props) {
    const classes = useStyles();

    const palletDrawer = useSelector((state) => state.palletDrawer);
    const xpos = window.innerWidth - 550
    const ypos = 50 
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: ypos });
    const dispatch = useDispatch();

    React.useEffect(() => {
        var lu = mylocalStorage.getItem("colorPos")
        if (lu) {
            const pp = JSON.parse(lu)
            let tempXpos = window.innerWidth - 565//this is when stickers dialog is closed i.e. palletDrawer.open=false
            if (pp.x < 20) pp.x = xpos
            if (pp.y < 20) pp.y = ypos
            if (pp.x > xpos) pp.x = palletDrawer.open ? xpos : tempXpos
            setWindowPosition(pp)
            return
        }

    }, [palletDrawer])
    function colorChangeComplete(c) {
        ReactGA.event({
            category: 'User',
            action: "Color Change Simple"
        });
        props.cb['simpleColor'](c.hex)
    }

    function handleDrag(e, ui) {
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
        mylocalStorage.setItem("colorPos", JSON.stringify({ x: ui.lastX, y: ui.lastY }))
    }
    function handleClose(e) {
        e.preventDefault()
        dispatch(Actions.setPalette({
            ...palletDrawer,
            openCol: false
        }))
    }
    return (
        <> {palletDrawer.openCol && (
            <Draggable
                handle="strong"

                onDrag={handleDrag}
                onStop={handleDragEnd}
                position={windowPosition}
                defaultClassName="draggable-color"
                defaultClassNameDragging="draggable2-color"
                defaultClassNameDragged="draggable3-color"
            >
                <div id="smallpalette" className={classes.draggableWindow} style={{
                    border: '5px solid ' + props.inkColor
                    // backgroundColor: '#ffffff44'
                }}>
                    <div className={classes.draggableTop} style={{ width: "100%", backgroundColor: props.inkColor }}>
                        <strong className={classes.draggableTopHandle}>
                            <PaneIcon />
                        </strong>
                        <div className={classes.draggableTopCloseButton}>
                            <Button
                                className={classes.windowButton}
                                onClick={handleClose}>
                                <CloseIcon />
                            </Button>
                        </div>
                    </div>
                    <div className={classes.cp}>
                        <CompactPicker
                            color={props.inkColor}
                            onChangeComplete={colorChangeComplete} />
                    </div>

                </div>

            </Draggable>

        )}
        </>
    );
}