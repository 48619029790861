export const Lang = {
    Afrikaans: "af",
    Albanian: "sq",
    Basque: "eu",
    Belarusian: "be",
    Bulgarian: "bg",
    Catalan: "ca",
    "Chinese(Simplified)": "zh_CN",
    "Chinese(Traditional)": "zh_TW",
    Croatian: "hr",
    Czech: "cs",
    Danish: "da",
    Dutch: "nl",
    English: "en",
    Estonian: "et",
    Filipino: "fil",
    Finnish: "fi",
    French: "fr",
    Galician: "gl",
    German: "de",
    Greek: "el",
    Haitian: "ht",
    Hindi: "hi",
    Hungarian: "hu",
    Icelandic: "is",
    Indonesian: "id",
    Irish: "ga",
    Italian: "it",
    Japanese: "ja",
    Korean: "ko",
    Latin: "la",
    Latvian: "lv",
    Lithuanian: "lt",
    Macedonian: "mk",
    Malay: "ms",
    Norwegian: "no",
    Polish: "pl",
    "Portuguese(Brazil)": "pt_BR",
    "Portuguese(Portugal)": "pt_PT",
    Romanian: "ro",
    Russian: "ru",
    Serbian: "sr",
    Slovak: "sk",
    Slovenian: "sl",
    Spanish: "es",
    Swahili: "sw",
    Swedish: "sv",
    Thai: "th",
    Turkish: "tr",
    Ukranian: "yk",
    Vietnamese: "vi",
    Welsh: "cy"
}
