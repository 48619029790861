import React, { useState, useEffect } from 'react'
import './styles/HighlightElement.css';

export default function HighlightElement(props) {
    let timer = null;
    const [dims, setDims] = useState(null);

    function setDimensions() {
        var div = document.getElementById(props.elId);
        if (!div) {
            setTimeout(setDimensions, 1000);
            // console.log("Could not find element, will retry in a sec.");
            return undefined;
        }
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(setDimensions, 250);

        // console.log("Found div to highlight:", div);
        var rect = div.getBoundingClientRect();
        var margin=12
        var x = rect.left-margin/2;
        var y = rect.top-margin/2;
        var w = rect.width+margin;
        var h = rect.height+margin;
        setDims(p => ({x: x, y: y, w: w, h: h}))
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        }
    }, [])

    useEffect(() => {
        // var div = document.getElementById(props.elId);
        // if (!div) return undefined;
        // // console.log("Found div to highlight:", div);
        // var rect = div.getBoundingClientRect();
        // var margin=24
        // var x = rect.left-margin/2;
        // var y = rect.top-margin/2;
        // var w = rect.width+margin;
        // var h = rect.height+margin;
        // setDims(p => ({x: x, y: y, w: w, h: h}))
        return setDimensions();
    }, [props.elId]);

    function onClick(event) {
        // console.log("On click of highlight called");
        event.stopPropagation();
        if (props.passThruClick) {
            var div = document.getElementById(props.elId);
            if (div) {
                // console.log("Calling on click of div", div)
                div.click(event);
            }
        }
        if (props.onClick) {
            props.onClick(event)
        }
    }

    // var className = "highLightRect animate-flicker" + (props.variant === "orange" ? " highLightRectOrange" : "")
    return (
        <>
        { dims ? <div className={"highLightRect" + (props.variant === "orange" ? " highLightRectOrange" : "")}
                style={{ left: dims.x, top: dims.y, width: dims.w, height: dims.h }} onClick={onClick}/> : null }
        </>
    )
}