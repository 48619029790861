import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import paper from 'paper';
import * as ib from './ibdata'
import * as dp from "./drawPaper.js"
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import YesNoDialog from "./YesNoDialog"
import PageAdvDialog from "./PageAdvDialog"

import InsertLinkIcon from '@material-ui/icons/InsertLink';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useSelector } from 'react-redux'
import LockIcon from '@material-ui/icons/Lock';
import {
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core';
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
var gQuotes = null

const deleteArr = (list, endIndex) => {
    const result = Array.from(list);
    result.splice(endIndex, 1);
    return result;
};
const insertArr = (list, endIndex, item) => {
    const result = Array.from(list);
    result.splice(endIndex, 0, item);
    return result;
};

var gScopes = {}

function Quote({ quote, index, props }) {
    React.useEffect(() => {
        var iPlus = index + 1
        if (!props.sess || !props.sess.id) {
            console.log("NO SESSION!!!")
            return
        }
        var parent = null
        if (props.sess.id !== props.sess.parentBoardID) {
            parent = props.sess.parentBoardID.split("-pgNum-")[0]
        }
        var arr = props.sess.id.split("-pgNum-")
        if (arr.length <= 1) return
        const scopeID = quote.id
        var mypage = scopeID.split("-pgNum-")[1]
        if (iPlus in gScopes) {
            var scope = gScopes[iPlus].scope
        } else {
            scope = new paper.PaperScope()
            scope.setup('sbCanvs#' + index);
        }
        scope.project.activeLayer.removeChildren();
        scope.view.zoom = 0.12;
        scope.view.center = new paper.Point(800, 600)
        // scope.view.center = new paper.Point(2000 / 2, 1280 / 2)
        gScopes[iPlus] = { scope: scope }

        if (scopeID !== gScopes[iPlus].id) {
            async function loadObj() {
                var d = await ib.listObjectSync(scopeID)
                gotData(d)
                function gotData(d) {
                    dp.gotData(scope, d)
                }

                if (parent) {
                    d = await ib.listObjectSync(parent + "-pgNum-" + mypage)
                    gotData(d)
                }
                await new Promise(r => setTimeout(r, 300));

                // var canvas = document.getElementById('sbCanvs#' + index);
                // var img = canvas.toDataURL('image/png');

                gScopes[iPlus].id = scopeID
                // gScopes[iPlus].img = img
            }
            loadObj()

        }
    }, [props.sess, index])
    function Clicked(e, idx) {
        e.preventDefault()
        const scopeID = quote.id
        if (followTeacher.set) {
            // set new page for all kids
            var vv = followTeacher.events[0]
            if (vv) {
                var copy = JSON.parse(JSON.stringify(vv))
                copy.Content.state = idx 
                ib.updateClassEvent(copy)
            }
        }
        props.history.push("/board/" + scopeID)
    }
    const contextMenu = [
        { name: 'Delete Page', cb: deletePage, icon: <DeleteForeverIcon /> },
        { name: 'Duplicate Page', cb: duplicatePage, icon: <FileCopyIcon /> },
        { name: 'Insert Page', cb: InsertPage, icon: <InsertLinkIcon /> },
        { name: 'Toggle hidden', cb: hidePage, icon: <VisibilityOffIcon /> },
        { name: 'Toggle password', cb: addPassword, icon: <LockIcon /> },

    ]
    const [yn, setyn] = React.useState({ open: false, message: "", cb: null })
    const [anchorEl, setAnchorEl] = React.useState({ el: null, list: [] });
    const [pad, setPad] = React.useState({ open: false, message: "", cb: null })
    const followTeacher = useSelector((state) => state.followTeacher);

    async function addPassword(i, q) {
        handleClose()
        if (q.password) {
            q.cb("password", { index: i, password: null })
            return
        }
        setPad({ open: true, cb: doneClick })
        async function doneClick(val) {
            setPad({ open: false, cb: null })
            if (val) {
                q.cb("password", { index: i, password: val })
            }
        }
    }
    async function hidePage(i, q) {
        handleClose()
        q.cb("hide", { index: i })
    }
    async function deletePage(i, q) {
        handleClose()
        if (i === 0) {
            alert("Cannot delete page 1")
            return
        }
        setyn({ open: true, message: "Delete page " + (i + 1) + " are you sure?", cb: doneClick })
        async function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (!val) {
                return
            }
            q.cb("delete", { index: i })
        }
    }
    async function duplicatePage(i, q) {
        handleClose()
        q.cb("duplicate", { index: i })
    }
    async function InsertPage(i, q) {
        handleClose()
        q.cb("insert", { index: i })
    }

    function RClicked(e, idx, quote) {
        handleClose()
        e.preventDefault()
        setAnchorEl({ el: e.currentTarget, list: contextMenu, quote: quote });
        return
    }

    const handleClose = () => {
        setAnchorEl({ el: null, list: [] });
    };
    return (
        <Draggable draggableId={quote.id} index={index}>

            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="sbDiv"
                    onContextMenu={(e) => RClicked(e, index + 1, quote)}
                    onClick={(e) => Clicked(e, index + 1)}
                >
                    Page: {index + 1}
                    {quote.hidden && (
                        <VisibilityOffIcon className="hidden" />
                    )}
                    {quote.password && (
                        <LockIcon className="hidden" />
                    )}
                    {/* {gScopes && gScopes[index + 1] && gScopes[index + 1].img ? (
                        <img src={gScopes[index + 1].img} alt="img" />
                    ) : ( */}
                    <canvas className="sbCanvs" id={"sbCanvs#" + index}>
                    </canvas>
                    {/* )} */}
                    {yn.open && <YesNoDialog {...yn} inkColor={props.inkColor}/>}
                    {pad.open && <PageAdvDialog {...pad} inkColor={props.inkColor}/>}

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl.el}
                        keepMounted
                        open={Boolean(anchorEl.el)}
                        onClose={handleClose}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                        {anchorEl.list.map((e) => (
                            < MenuItem key={e.name} style={{ backgroundColor: props.inkColor, color: "#ffffff" }}
                                onClick={() => e.cb(index, quote)}>
                                { e.icon && <ListItemIcon style={{ color: "#ffffff" }}>{e.icon}</ListItemIcon>}
                                <Typography variant="h6">{e.name}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
        </Draggable>
    );
}
// const QuoteList = React.memo(function QuoteList({ quotes, props}) {
//     return quotes.map((quote, index) => (
//         <Quote quote={quote} index={index} props={props} key={quote.id} />
//     ));
// });
const QuoteList = React.memo(function QuoteList({ quotes, props }) {
    return quotes.map((quote, index) => (
        <Quote quote={quote} index={index} props={props} key={quote.id} />
    ));
});
export default function SideBar(props) {
    const [state, setState] = useState({ quotes: [] });
    const [wait, setWait] = React.useState(false)

    React.useEffect(() => {
        gScopes = {}
    }, [])
    React.useEffect(() => {
        if (!props.sess) return
        if (wait) return
        ib.getboardsbyparent(props.sess.parentID, null, gotPages)
        function gotPages(items) {
            var earlier = []
            var hidden = []
            var password = []

            var it = []
            var max = 0
            if (!items) return

            //read all pages 
            var boardOrder = null
            for (let i = 0; i < items.length; i++) {
                let hid = false
                let pass = null
                let item = items[i]
                if (item.boardConfig) {
                    var bc = JSON.parse(item.boardConfig)
                    if (bc.boardOrder) {
                        boardOrder = bc.boardOrder
                    }
                }
                var f = item.id.split("-pgNum-")
                var pgn = parseInt(f[1])
                var idx = i
                if (pgn > max) max = pgn

                if (boardOrder) {
                    for (let ii in boardOrder.order) {
                        var ffbo = boardOrder.order[ii]
                        if (ffbo === pgn) {
                            idx = ii - 1
                            hid = boardOrder.hidden[ii]
                            pass = boardOrder.password ? boardOrder.password[ii] : false
                        }
                    }
                    // if (max < boardOrder.max)
                    //     max = boardOrder.max
                }
                earlier[idx] = item
                hidden[idx] = hid
                password[idx] = pass
            }
            for (let i = 0; i < max; i++) {
                let item = earlier[i]
                if (!item) continue
                var hiddenV = hidden[i]

                it.push({
                    id: item.id,
                    page: item.pageNumber,
                    item: item,
                    index: i,
                    cb: cbfunc,
                    wait: setWait,
                    hidden: hiddenV,
                    password: password[i]
                })
            }
            setState({ quotes: it })
            gQuotes = it
        }
    }, [props.sess, wait])

    async function updateBoardOrder(quotes) {
        var boardOrder = { order: {}, hidden: {}, password: {} }
        var maxPage = 0
        for (let p in quotes) {
            var i = parseInt(p)
            boardOrder.order[i + 1] = quotes[i].page
            boardOrder.hidden[i + 1] = quotes[i].hidden
            if (quotes[i].password) {
                boardOrder.password[i + 1] = quotes[i].password
            }
            if (quotes[i].page > maxPage) maxPage = quotes[i].page
        }
        boardOrder.max = maxPage
        await ib.updateClassBoardsOrderAll(quotes[0].item, boardOrder)
        await new Promise(r => setTimeout(r, 300));
        window.location.reload()
        setWait(false)
    }
    function getMax(quotes) {
        var max = 0
        var maxIndex = 0
        for (let i in quotes) {
            var q = quotes[i]
            if (max < q.page) max = q.page
            if (maxIndex < q.index) maxIndex = q.index
        }
        return { maxPage: max, maxIndex: maxIndex }
    }
    async function cbfunc(tpe, args) {
        setWait(true)

        if (tpe === "delete") {
            props.history.push("/board/" + props.sess.parentID + "-pgNum-1")

            await ib.deletePageNumber(gQuotes[args.index].id)
            var quotes = deleteArr(
                gQuotes,
                args.index
            )
            //delete board here 
        }
        if (tpe === "hide") {
            quotes = gQuotes
            quotes[args.index].hidden = !quotes[args.index].hidden
        }
        if (tpe === "password") {
            quotes = gQuotes
            quotes[args.index].password = args.password 
        }
        if (tpe === "insert" || tpe === "duplicate") {
            var copy = tpe === "duplicate" ? true : false
            var gm = getMax(gQuotes)
            var newItemC = await ib.duplicatePage(gQuotes[args.index].id, gm.maxPage + 1, copy)
            var newItem = {
                id: newItemC.id,
                page: gm.maxPage + 1,
                item: newItemC,
                index: gm.maxIndex + 1,
                cb: cbfunc,
                wait: setWait,
                hidden: false
            }

            quotes = insertArr(
                gQuotes,
                args.index + 1, //+1 is clicked, insert after 
                newItem
            )
            // for (let i in quotes) {
            //     console.log("i in q", i, quotes[i].id) 
            // }
            //delete board here 
        }
        // setState({ quotes: [...quotes] });
        gQuotes = quotes
        updateBoardOrder(gQuotes)
    }
    async function onDragEnd(result) {
        try{
            // this try catch for if we just drag and drop(not on any other grid but justdrag&drop) then result.source.index gives error
        if (result.destination.index === 0 || result.source.index === 0) {
            alert("Cannot change page 1")
            return
        }
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        var quotes = reorder(
            state.quotes,
            result.source.index,
            result.destination.index
        );

        setState({ quotes: [...quotes] });
        gQuotes = quotes
        updateBoardOrder(gQuotes)
        return
    } catch (e) {
        // console.error('Error while Drag&drop, eMsg:', e)
    }
    }
    return (
        <div className="sidebar" style={{left: props.menuDrawerOpen? '230px': '70px'}}>
            { wait ? <div className="screenCenter"><CircularProgress style={{ color: "#3174F5" }} /></div> : (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="list">
                        {provided => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <QuoteList quotes={state.quotes} props={props} />
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}


        </div>

    );
}
