import React from 'react';
import * as ib from "./ibdata.js"
import ReactGA from 'react-ga';
import { InputBase, Snackbar } from "@material-ui/core"
import { v4 as uuid } from "uuid";
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogContent,
    InputAdornment,
    Button,
    DialogTitle,
    TextField,
    Select,
    MenuItem,
    FormHelperText,
    InputLabel,
} from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AuthDialog from "./AuthDialog"
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as mylocalStorage from "./mylocalStorage";
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    content: {
        alignContent: "center",
        align: "center",
    },
    inviteButton: {
        backgroundColor: "#3174F5",
        fontSize: "18px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "capitalize",
        alignItems: "center",
        marginLeft: "20px",
        border: "1px solid #E0E5E7",
        justifyContent: "center",
        color: "#ffffff",
        borderRadius: "999px",
        padding: "8px 45px",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
    enterName: {
        width: "60%",
        padding: "24px 20px",
        alignSelf: "center",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        background: "#FFFFFF",
        border: "1px solid #E0E5E7",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        borderRadius: "999px",
        fontSize: "14px",
        height: "48px",
    },
    formControl: {
        minWidth: "248px",
        height: "45px",
        "& .MuiOutlinedInput-root": {
            borderRadius: "999Px",
        },
        "& .MuiSelect-select:focus": {
            background: "transparent !important",
        }
      },
    formSelect: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" :{
            borderColor: "#3174F5"
        },
        marginBottom: '16px'
    }
}));

var pendingList = {}
function JoinBoard(props) {
    const [message, setMessage] = React.useState("")
    const [diag, setDiag] = React.useState({ open: false, cb: null, precog: false })
    const classes = useStyles();
    const [authBoardOnly, setAuthBoardOnly] = React.useState(false)
    const authUser = useSelector((state) => state.user);
    const authDialog = useSelector((state) => state.authDialog);
    const [sess, setSess] = React.useState(null)

    const dispatch = useDispatch();

    function aniMess(mess) {
        setMessage(mess)
    }

    function GetName() {
        var ss = mylocalStorage.getItem('myname');
        ss = ss ? ss : ""
        var ss2 = mylocalStorage.getItem('myid');
        ss2 = ss2 ? ss2 : ""
        const [name, setName] = React.useState(ss)
        const [myid, setMyId] = React.useState(ss2)
        const [butt, setButt] = React.useState({ dis: false, text: "Join" })
        const [grps, setGrps] = React.useState([])
        function OnChangeName(evt) {
            evt.preventDefault()
            setName(evt.target.value)
        }
        function onID(evt) {
            evt.preventDefault();
            var ff = evt.target.value
            var x = ff.trim()
            setMyId(x)
        }

        React.useEffect(() => {
            var disabled = false
            var text = "Join"
            if (diag.precog) {
                text = "Join"
                if (name.length === 0 || myid.length === 0) disabled = true
                ib.listClassGroupByClass(sess.Classroom, classB, null)
                function classB(ds) {
                    var ff = [{ val: "", name: "Pick a Group" }]
                    for (let i = 0; i < ds.length; i++) {
                        let d = ds[i]
                        if (d && d.content) {
                            try {
                                var g = JSON.parse(d.content)
                                if (g.members && g.members[0] && g.members[0].type === "precog") {
                                    ff.push({ val: g.members[0].id, name: g.members[0].id })
                                }
                            } catch { }
                        }
                    }
                    // if no groups left then remove precog 
                    if (ff.length === 1) {
                        setDiag({
                            ...diag,
                            precog: false
                        });
                        text = "Join";
                        ff = []
                    }
                    setGrps(ff)
                }
            } else {
                if (name.length === 0) disabled = true
            }
            setButt({ dis: disabled, text: text })
        }, [diag, name, myid, sess])

        function setNameButton(e) {
            if (e) e.preventDefault()
            if (butt.dis) return
            var luname = name.replace(/\s/g, "")
            if (!luname || luname === "") return
            mylocalStorage.setItem('myname', name);
            if (diag.precog) {
                mylocalStorage.setItem('myid', myid + "");
            }
            var dt = Math.floor(Date.now().valueOf() / 1000)
            mylocalStorage.setItem('storeTime', dt)

            if (diag.cb) diag.cb()
        }
        return (
            <Dialog
                open={diag.open}
                disableBackdropClick="true"
                disableEscapeKeyDown="true"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className='nameDialog'
            >
                <div className='flex justify-between headerbg'>

                    <DialogTitle id="alert-dialog-slide-title" className='dialogTitle'>
                        Please enter your name
                        </DialogTitle>
                </div>
                <DialogContent>

                    <div className={classes.formSelect}>
                        <form onSubmit={setNameButton} className='flex inputBaseFiled'>
                            <InputBase
                                className={classes.enterName}
                                placeholder='Please Enter Your Name'
                                onChange={(ev) => OnChangeName(ev)}
                                autoFocus
                                value={name}
                            />
                            {diag.precog && (
                                <>
                                 <FormControl className= {classes.formControl}>
                                  <InputLabel id="demo-mutiple-name-label"
                                  style={{    
                                    position: "absolute",
                                    top: "-7px",
                                    left: "21px",
                                    fontSize: "14px",
                                    textTransform: "capitalize" }}
                                  >pick a group name</InputLabel>
                                  <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={myid}
                                        variant="outlined"
                                        InputLabelProps={{shrink: false}}
                                        onChange={(e) => onID(e)}
                                        label= "Please pick your group"
                                    >
                                        {grps.map((f) => (
                                            <MenuItem key={f.val} value={f.val}>{f.name}</MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                                </>
                                  
                                // <TextField
                                //     className="flex nameEnter"
                                //     placeholder='Please enter your ID'
                                //     onChange={(ev) => onID(ev)}
                                //     value={myid}
                                //     InputProps={{
                                //         startAdornment: (
                                //             <InputAdornment>
                                //                 <AccountBoxIcon />
                                //             </InputAdornment>
                                //         ),
                                //     }}
                                // />
                            )}

                            <Button className={classes.inviteButton}
                                disabled={butt.dis}
                                onClick={() => setNameButton()}>
                                {butt.text}
                            </Button>
                        </form>

                    </div>
                </DialogContent>
            </Dialog>
        )
    }
    React.useEffect(() => {
        if ('match' in props && 'params' in props.match && 'boardid' in props.match.params) {
            var url = window.location.href
            var coteacher = false
            var iduse = props.match.params.boardid
            if (url.indexOf("cojoin") !== -1) {
                coteacher = true
                if (iduse.indexOf("pgNum") === -1) {
                    iduse = window.atob(iduse)
                } else {
                    coteacher = false;//prevent kids from faking
                }
            }
            ib.updateOembedLink();
            ib.getSession(iduse).then((res) => {
                //close sidebar
                mylocalStorage.removeItem('sidebar');
                const sess = res.data.getSession
                if (!sess) {
                    alert("Invite is not valid")
                    return
                }
                setSess(sess)
                var found = false
                if (sess.boardConfig) {
                    const bc = JSON.parse(sess.boardConfig)
                    if (bc.boardAuthUsersOnly) {
                        found = true
                        if (authUser) {
                            var ff = mylocalStorage.getItem('myname');
                            if (!ff) {
                                var myName = authUser.attributes.name;
                                mylocalStorage.setItem('myname', myName);
                            }
                            if (authBoardOnly) setAuthBoardOnly(false)
                            signedIn()
                            return
                        }
                        setAuthBoardOnly(true)
                        function userAuthenticated() {
                            var ff = mylocalStorage.getItem('myname');
                            if (!ff) {
                                var myName = authUser.attributes.name;
                                mylocalStorage.setItem('myname', myName);
                            }
                            setAuthBoardOnly(false)
                            window.location.reload()
                            return
                        }
                        function signUpCancel() {
                            setTimeout(function () {
                                window.location.reload()
                            }, 300);
                            return
                        }
                        if (!authDialog.open && !authUser) {
                            dispatch(Actions.openAuthDialog({
                                open: true,
                                siCB: userAuthenticated, cancelCB: signUpCancel
                            }))
                        }
                    } else {
                        signedIn()
                    }
                    function signedIn() {
                        if (bc.multiBoardOption || sess.Classroom) {
                            found = true
                            ib.findCreateSession(sess.Classroom).then((r) => {
                                if (coteacher) {
                                    var luid = mylocalStorage.getItem('mystoreID');
                                    ib.getClassroom(sess.Classroom, gotClass)
                                    function gotClass(classroom) {
                                        if (!classroom) return
                                        let rr = classroom.TeacherList.indexOf(luid)
                                        if (rr !== -1) {
                                        } else {
                                            classroom.TeacherList.push(luid)
                                            delete classroom['Teacher']
                                            delete classroom['Users']
                                            ib.updateClassroom(classroom)
                                        }
                                    }
                                }
                                if (r) {
                                    ReactGA.event({
                                        category: 'User',
                                        action: "JoinTeacher"
                                    });
                                    props.history.push("/board/" + r.id)
                                    mylocalStorage.setItem('IsStudent', false)
                                    return
                                } else {
                                    const id = uuid()
                                    const pg1 = id + "-pgNum-1"
                                    ReactGA.event({
                                        category: 'User',
                                        action: "JoinStudent"
                                    });
                                    //setting this to display emailOptIn checkbox
                                    mylocalStorage.setItem('IsStudent', true)
                                    var tmpName = mylocalStorage.getItem('myname');
                                    if (!tmpName || bc.hasPreCogs) {
                                        if (!diag.open) setDiag({ open: true, cb: setUpBoard, precog: bc.hasPreCogs });
                                    } else {
                                        setUpBoard()
                                    }
                                    function setUpBoard() {
                                        ib.findCreateSessionByName(sess.Classroom).then((r) => {
                                            if (r) {
                                                ReactGA.event({
                                                    category: 'User',
                                                    action: "JoinStudentByName"
                                                });
                                                props.history.push("/board/" + r.id)
                                                return
                                            }
                                            //parentBoard should ID 1 too incase teacher clicked on new board
                                            const pgSplit = sess.parentBoardID.split("-pgNum-")
                                            const ppid = pgSplit[0] + "-pgNum-1"
                                            if (ppid in pendingList) {
                                                ReactGA.event({
                                                    category: 'User',
                                                    action: "PENDING ALREADY"
                                                });
                                                return //someone is already trying this
                                            }
                                            pendingList[ppid] = true
                                            ib.createMultiBoard(ppid, id, sess.ttl)
                                            var bdname = "unsaved"
                                            let tmpName = mylocalStorage.getItem('myname');
                                            if (tmpName) {
                                                try {
                                                    var fft = sess.name.split(":")
                                                    bdname = fft[0] + ":" + tmpName
                                                } catch {
                                                    bdname = tmpName
                                                }
                                            }
                                            ib.createSession(pg1, bdname, "somecontent",
                                                id, 1, ppid, sess.boardConfig, sess.Classroom, false, sess.ttl).then((res2) => {
                                                    props.history.push("/board/" + pg1)
                                                })
                                        })
                                    }
                                }
                            })
                        } else {
                            found = false
                            ReactGA.event({
                                category: 'User',
                                action: "MatchSingle"
                            });
                            mylocalStorage.setItem('IsStudent', false)

                            props.history.push("/board/" + sess.id)
                        }
                    }
                }
                if (!found) {
                    ReactGA.event({
                        category: 'User',
                        action: "MatchSingle"
                    });
                    mylocalStorage.setItem('IsStudent', false)
                    props.history.push("/board/" + sess.id)
                }
            })
        }
    }, [props, props.match, authUser])
    return (
        <div className="session-table">
            {diag.open && <GetName />}
            {authBoardOnly && (
                <AuthDialog noGear={true} />
            )}
            {message !== "" && (
                <Snackbar
                    open={message !== ""}
                    onClose={() => setMessage("")}
                    message={message}
                    key={"top center"}
                    autoHideDuration={1000}
                />
            )}

        </div>

    )
}



export default JoinBoard