import React, { useState, useEffect } from 'react';
import ProgressComponent from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import * as ib from './ibdata'
import * as mylocalStorage from "./mylocalStorage";
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"

var JitsiMeetExternalAPI = null;
var myId = null;
var userRoles = {};
var tmpVar = false;
var api;
var wbIdTitle;
var glbUser = null;
var glbWbId = "";
var oldApis = {}
var oldIframes = [];
var switchedToGridView = false;
var glbMeeting = {};
var glbMeetingMap = {};
var isFirst = true;
var scriptLoaded = false;
var _isMounted = false;

var DEBUG = false;
var consolelog = console.log;
var consoleerror = console.error;

if (DEBUG !== true) {
    consolelog = function () { };
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function JitsiMeetComponent(props) {
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const dispatch = useDispatch();
    consolelog("GOT PROPS:", props);
    const meetingToJoin = useSelector((state) => state.meetingToJoin);

    const [meeting, setMeeting] = React.useState({ meetingName: "Classroom" });
    const [meetingMap, setMeetingMap] = React.useState({});

    function joinNewMeeting(meetId, meetName) {
        dispatch(Actions.meetingToJoin({ id:meetId, name: meetName}))
    }
    React.useEffect(()=> {
        if (meetingToJoin) {
            joinNewMeetingInt(meetingToJoin.id, meetingToJoin.name)
        }
    },[meetingToJoin])
    function joinNewMeetingInt(meetId, meetName) {
        // Updated meeting ID & title
        glbWbId = meetId;
        wbIdTitle = meetName;
        oldApis[glbWbId] = api;
        if (api) {
            api.addEventListener('videoConferenceLeft', (event) => {
                // Left old conference
                resetOldIframes();
            });
            // Leave old conference
            api.executeCommand('hangup');
        }
        // Reset this to switch to grid view once only.
        switchedToGridView = false;
        // Join new conference
        startConference();
    }

    const gotGroup = React.useCallback((groupList) => {
        consolelog("gotGroup:", groupList)
        var sm = {}
        for (var idx in groupList) {
            var l = groupList[idx].SessionID.split('-pgNum-');
            sm[l[0]] = `group-${groupList[idx].name}`;
            sm[props.session.Classroom] = 'Classroom';
        }
        consolelog("group session map:", sm)

        if (isFirst) {
            var tmpMeet = {
                meetingName: 'Classroom',
                meetingId: props.session.Classroom,
            }
            setMeeting({
                ...meeting,
                ...tmpMeet
            });
            isFirst = false;
        }
        glbMeeting = tmpMeet;
        glbMeetingMap = { ...glbMeetingMap, ...sm };
        setMeetingMap({ ...glbMeetingMap });
    }, []);

    const delGroup = React.useCallback((group) => {
        consolelog("delGroup:", group);
        try {
            var l = group.SessionID.split('-pgNum-');
            delete glbMeetingMap[l[0]]
            setMeetingMap({ ...glbMeetingMap });
            consolelog(glbMeeting);
            if (glbMeeting.meetingId === l[0]) {
                // The group we were joined got delete,
                // so let's switch to the classroom
                var tmpMeet = {
                    meetingName: 'Classroom',
                    meetingId: group.Classroom,
                }
                setMeeting({ ...tmpMeet });
                glbMeeting = tmpMeet;
                consolelog("Switching to default classroom meeting");
                joinNewMeeting(group.Classroom, 'Classroom');
            }
        } catch (err) {
            consolelog("Group meeting deletion got error:", err);
        }
    }, []);

    const handleMeetingSelected = (event) => {
        consolelog("Selected meeting:", event.target.name)
        consolelog("handleMeetingSelected:", event.target)
        consolelog("curMeeting:", JSON.stringify(meeting));
        var tmpMeet = {
            meetingName: meetingMap[event.target.value],
            meetingId: event.target.value,
        }
        setMeeting({
            ...meeting,
            ...tmpMeet
        });
        glbMeeting = tmpMeet;
        consolelog("updatedMeeting:", JSON.stringify(meeting));
        joinNewMeeting(tmpMeet.meetingId, tmpMeet.meetingName);
    };

    function resetOldIframes() {
        var removed = false;
        for (var idx in oldIframes) {
            oldIframes[idx].remove();
            removed = true;
        }
        if (removed) {
            oldIframes = [];
        }
    }

    const containerStyle = {
        width: "100%",
        height: "100%",
        marginRight: "0px",
        position: "fixed",
        left: "80px",
        top: "125px",
    };

    const loadingStyle = {
        top: "50%",
        left: "50%",
        alignItems: "center",
        position: "relative",
        justifyContent: "center"
    }

    const jitsiContainerStyle = {
        display: (loading ? 'none' : 'block'),
        width: '90%',
        height: '70%',
    }

    function setEmail() {
        if (!(glbUser)) {
            consolelog("setEmail: User info still not set...");
            return;
        }

        if (api && glbUser.content && glbUser.content !== "") {
            try {
                var c = JSON.parse(glbUser.content);

                if (c.email) {
                    api.executeCommand('email', c.email);
                }
            } catch (err) {
                consoleerror("Could not parse user content:", err);
            }
        }
    }

    function setDisplayName() {
        if (!(glbUser)) {
            consolelog("setDisplayName: User info still not set...");
            return;
        }

        if (api && glbUser.name && glbUser.name !== "") {
            api.executeCommand('displayName', glbUser.name);
        }
    }

    function setAvatarURL() {
        if (!(glbUser)) {
            consolelog("setAvatarURL: User info still not set...");
            return;
        }

        if (api && glbUser.avatar && glbUser.avatar !== "") {
            api.executeCommand('avatarUrl', glbUser.avatar);
        }
    }

    function setDataToState(user) {
        if (_isMounted) {
            //setUser(user);
            glbUser = user;
            setDisplayName();
            setEmail();
            setAvatarURL();
        } else {
            consolelog("Not setting user data for unmounted Jitsi component");
        }
    }

    function getUserRecord(username, searchDict) {
        if (searchDict.hasOwnProperty(username)) {
            return searchDict[username];
        }

        return null
    }

    function saveUserRecord(username, record, saveDict) {
        saveDict[username] = record;
    }

    function isModerator(userId) {
        if (userRoles.hasOwnProperty(userId)) {
            return userRoles[userId];
        }

        return false;
    }

    function startConference() {
        consolelog("WBID: " + glbWbId);
        consolelog("WBID CLEANED: " + glbWbId.replace(/-/g, ""));
        var tmpElem = null;

        tmpElem = document.getElementById('jitsi-container');

        if (api) {
            // Remove old iframes from div
            var descendents = tmpElem.getElementsByTagName('*');
            // descendents is a live NodeList, so it's length will change
            // as you remove Nodes, so convert it into an array
            //descendents = Array.prototype.slice.call(descendents);
            for (var iii = 0; iii < descendents.length; ++iii) {
                consolelog("descendent:", iii, descendents[iii])
                //descendents[iii].remove();
                oldIframes.push(descendents[iii]);
            }
        }

        consolelog('jitsi container:', tmpElem);

        try {
            var domain = mylocalStorage.getItem("videoserver");
            const options = {
                roomName: glbWbId.replace(/-/g, ""),
                //roomName: "orangedogsmeetgently",
                parentNode: tmpElem,
                subject: "...",
                ConfigOverwrite: {
                    disableDeepLinking: true,
                },
                interfaceConfigOverwrite: {
                    MOBILE_APP_PROMO: false,
                },
                /*interfaceConfigOverwrite: {
                    filmStripOnly: false,
                    //SHOW_JITSI_WATERMARK: false,
                },
                configOverwrite: {
                    disableSimulcast: false,
                },*/
            };

            consolelog("Connecting to jitsi meeting");
            JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;
            api = new JitsiMeetExternalAPI(domain, options);
            api.addEventListener('videoConferenceJoined', (userData) => {
                console.log('Local User Joined');
                consolelog(userData);
                setLoading(false);
                myId = userData.id;
                setEmail();
                setDisplayName();
                setAvatarURL();
                const listener = ({ enabled }) => {
                    consolelog('tile view:', enabled);
                    if (!api) return
                    api.removeEventListener(`tileViewChanged`, listener);

                    if (!enabled) {
                        consolelog('handling tile view disabled');
                        api.executeCommand(`toggleTileView`);
                    }
                };

                api.addEventListener(`tileViewChanged`, listener);
                if (!switchedToGridView) {
                    if (api) {
                        api.executeCommand(`toggleTileView`);
                    }
                    switchedToGridView = true;
                }
                // New conference joined, remove old iframes
                resetOldIframes();
            });
            api.addEventListener('participantRoleChanged', (event) => {
                consolelog("Got a role changed message")
                consolelog("MYID: " + myId);
                consolelog(event);
                if (event.role === "moderator") {
                    userRoles[event.id] = true;

                    if (wbIdTitle && isModerator(myId) && (wbIdTitle !== "...")) {
                        consolelog("ROLE CHANGED, CHANGING SUBJECT: " + wbIdTitle)
                        if (api) api.executeCommand('subject', wbIdTitle);
                    }
                } else {
                    userRoles[event.id] = false;
                }
            });
        } catch (error) {
            consoleerror('Failed to load Jitsi API', error);
        }
    }

    function doneLoading() {
        // Finished loading the external_api.js script.  Now we can start Jitsi
        consolelog("Finished loading external API")
        if (!api) {
            // This is the first time that the API was loaded, start a conference.
            startConference();
        }
        // If a conference has already started, then we do not need to call startConference
        scriptLoaded = true;
    }

    useEffect(() => {
        // verify the JitsiMeetExternalAPI constructor is added to the global..
        if (scriptLoaded === false) {
            var domain = mylocalStorage.getItem("videoserver");

            ib.runScript("https://"+domain+"/external_api.js", doneLoading);
        } else {
            doneLoading()
        }
        _isMounted = true;

        return function cleanup() {
            if (api) api.executeCommand('hangup');
            api = null
            _isMounted = false;
        }
    }, []);

    useEffect(() => {
        consolelog("WBID TITLE: from:", wbIdTitle, " to:", props.wbIdTitle);
        if (glbWbId !== props.wbId) {
            consolelog('Not setting subject as WBID has changed')
        } else {
            if (api && isModerator(myId)) {
                consolelog("CHANGING SUBJECT: " + props.wbIdTitle)
                api.executeCommand('subject', props.wbIdTitle);
            }
        }
        wbIdTitle = props.wbIdTitle;
    }, [props.wbIdTitle]);

    useEffect(() => {
        consolelog("Updated WBID from: ", glbWbId, " to: ", props.wbId);
        if (glbWbId && (glbWbId !== props.wbId)) {
            consolelog("NEED TO JOIN A NEW MEETING!!!")
            joinNewMeeting(props.wbId, wbIdTitle);
            return;
        }
        glbWbId = props.wbId;
    }, [props.wbId]);

    useEffect(() => {
        if (glbUser !== props.user) {
            setDataToState(props.user);
        }
    }, [props.user])

    useEffect(() => {
        var s2 = null, s3 = null;
        function classGroupSubCB(classGroupSubs) {
            [s2, s3] = classGroupSubs;
        }
        if (props.isTeacher && props.session && props.session.Classroom) {
            consolelog("Getting sessions by classroom");
            ib.SubscribeClassGroupByClass({
                "ClassroomID": props.session.Classroom,
                "cb": gotGroup,
                "delCB": delGroup,
                "doList": true,
                "subCB": classGroupSubCB
            });
        }

        return function unsub() {
            if (s2) s2.unsubscribe();
            if (s3) s3.unsubscribe();
        }
    }, [props.session, props.isTeacher])

    return (
        <div
            id="vidCont"
            style={loading ? loadingStyle : containerStyle}
        >
            {loading && <ProgressComponent />}
            {!loading && props.isTeacher && (Object.keys(meetingMap).length > 1) && <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">Select Meeting Room</InputLabel>
                <NativeSelect
                    defaultValue={props.session.Classroom}
                    onChange={handleMeetingSelected}
                    value={meeting.meetingId}
                >

                    {Object.keys(meetingMap).map((tmpMeetingId) => (
                        <option key={tmpMeetingId} value={tmpMeetingId}>{meetingMap[tmpMeetingId]}</option>
                    ))}
                </NativeSelect>
            </FormControl>
            }
            <div
                id="jitsi-container"
                style={jitsiContainerStyle}
            />
        </div>
    );
}


export default JitsiMeetComponent;
