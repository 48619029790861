import React, { useRef } from 'react';
import { Auth } from 'aws-amplify'
import "./home.css"
import "./Home/homeOver.css"
import "./styles/subscribe.css"
import "./styles/sale.scss"
import { useSelector, useDispatch } from 'react-redux'
import * as mylocalStorage from "./mylocalStorage";
import ReactGA, { set } from 'react-ga';
import {
    Hidden,
    AppBar,
    Toolbar,
    Typography,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SocialIcon } from 'react-social-icons';
import * as ib from './ibdata'
import Switch from '@material-ui/core/Switch';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import LockIcon from '@material-ui/icons/Lock';
import PaymentIcon from '@material-ui/icons/Payment';
import Footer from './Home/Footer';
import Header from './Home/Header.js'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        // backgroundColor: '#FFFFFF',
        backgroundColor: '#3174F500',
        backgroundImage: "url"
    },
    rightSide: {
        textAlign: 'right',
        margin: '0 55px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        align: 'center',
    },

    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        color: '#5D5770',
    },
    tooltip: {
        margin: "2px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#3174F5",
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    },
    customArrow: {
        color: "#3174F5",
    },
}))

const planGrid = {
    educator: {
        name: 'Educator Free',
        monthly: 0,
        annual: 0
    },
    basic: {
        name: 'Basic',
        monthly: 7,
        annual: 70
    },
    gold: {
        name: 'Gold',
        monthly: 15,
        annual: 150
    }
}

function Subscribe(props) {
    const classes = useStyles();
    const [payCycle, setPayCycle] = React.useState("monthly");
    const user = useSelector((state) => state.user);
    let curColor = mylocalStorage.getItem("mycolor")
    const oldManageSession = mylocalStorage.getItem('manageBoardsSession');
    const [currPlan, setCurrPlan] = React.useState(props.paidPlan)
    const divRef = useRef(null);
    const [startNewBoard, setStartNewBoard] = React.useState(false);

    React.useEffect(() => {
        setCurrPlan(props.paidPlan)
    }, [props])

    // This is needed because the React fullpage component used for the
    // splash page adds overflow hidden; which makes the lower parts of this
    // page inaccessible.
    document.body.style.overflow = "visible"

    if (!(curColor)) {
        curColor = "#8f3ee0";
    }
    function openInNewTab(url) {
        window.open(url)
    }
    function scrollToHowThisWorks() {
        divRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    function backButtonClicked(event) {
        props.history.push("/board/" + oldManageSession);
    }
    const handleChange = (event) => {
        if (event.target.checked) {
            setPayCycle("annual")
        } else {
            setPayCycle("monthly")
        }
    };

    function SignOut() {
        Auth.signOut();
        window.location.reload();
    }

    return (
        <>
        {/* <div id="bgimg"/> */}
        {/* <AppBar
                position="fixed"
                className={clsx(classes.appBar, {})}
            >
                <Toolbar color="transparent" > */}
                    {/* <Hidden smDown>
                        <div className={classes.rightSide}>
                            <div className='flex'>
                                <div className="hbHow">
                                    { showFlag() ? <Flag /> : null }
                                </div>
                                <div className="hbMU">
                                    <PlayArrowIcon />
                                    <a href="/help">Help & How-To</a>
                                </div>
                                <div className="buyCloudCreditsButton"  style={{ transform: "scale(0.8)", marginLeft: '-20px', minWidth: '220px' }}>
                                <div onClick={(e) => openInNewTab("https://www.buymeacoffee.com/whiteboardchat")}><img src="https://img.buymeacoffee.com/button-api/?text=Buy us cloud credits&emoji=🌥&slug=whiteboardchat&button_colour=3174F5&font_colour=ffffff&font_family=Lato&outline_colour=000000&coffee_colour=FFDD00"/></div>                                </div>
                            </div>
                        </div>
                    </Hidden> */}
                {/* </Toolbar>
        </AppBar> */}
        {/* <div style={{margin: "200px"}}></div> */}

            <Hidden mdUp>
                <div style={{ height: '100px' }}>
                <Header newBoard={startNewBoard}/>
                </div>
                <div className={classes.rightSide} style={{textAlign: 'center'}}>
                    {user ? <div id="username">{ib.getUserString(user.username, user.attributes.email)}</div> : null}
                </div>
            </Hidden>
            <Hidden smDown>
            <div id="whypay">
                <div style={{height: '100px'}}>
                <Header newBoard={startNewBoard}/>
                </div>
                    <div className={classes.rightSide}>
                        { user ? <div id="username">{ib.getUserString(user.username, user.attributes.email)}</div> : null }
                    </div>

                Keep your boards as long as you like<br />
                No manual work of refreshing boards<br />
                Help support Whiteboard.chat for all Educators<br />
                <span onClick={scrollToHowThisWorks} style={{ cursor: 'pointer', color: '#3174F5'}}>How this works ...</span><br />
            </div>
            </Hidden>
            <Hidden smUp><div style={{height: '40px'}}></div></Hidden>
            <div id="paycycle">
            <span onClick={() => setPayCycle("monthly")}>Pay Monthly</span>
            <Switch
                checked={payCycle === "annual"}
                color='primary'
                inputProps={{ 'aria-label': 'checkbox with default color' }}
                onChange={e => handleChange(e)}
            />
            <span onClick={() => setPayCycle("annual")}>Annual
            <span className="sale">16% Off!</span></span>
            </div>
            <div id="price">

                {/* <!--price tab--> */}
                {/* <div class="plan">
                    <div class="plan-inner">
                    <div class="entry-title">
                        <h3>Basic Wash</h3>
                        <div class="price">$25<span>/PER CAR</span>
                        </div>
                    </div>
                    <div class="entry-content">
                        <ul>
                        <li><strong>1x</strong> option 1</li>
                        <li><strong>2x</strong> option 2</li>
                        <li><strong>3x</strong> option 3</li>
                        <li><strong>Free</strong> option 4</li>
                        <li><strong>Unlimited</strong> option 5</li>
                        </ul>
                    </div>
                    <div class="btn">
                        <a href="#">Order Now</a>
                    </div>
                    </div>
                </div> */}
                {/* <!-- end of price tab-->
                <!--price tab--> */}
                <div class="plan educator">
                    <div class="plan-inner">
                    {currPlan === "educator-free" ? <div class="current">Current</div> : null}
                    <div class="entry-title">
                        <h3>{planGrid.educator.name}</h3>
                        <div class="price">${planGrid.educator[payCycle]}<span>{payCycle}</span>
                        </div>
                    </div>
                    <div class="entry-content">
                        <ul>
                        <li><strong>All</strong> Whiteboard Features </li>
                        <li><strong>Unlimited</strong> Boards </li>
                        <li><strong>Manual</strong> Expiry Refresh</li>
                        <li><strong>Free!</strong> </li>
                        </ul>
                    </div>
                    <div class="btn">
                        <a href="#" onClick={() => { props.cb({type: "Select", planId: "educator-free"})} }>Select</a>
                    </div>
                    </div>
                </div>
                {/* <!-- end of price tab-->
                <!--price tab--> */}
                <div class="plan basic">
                    <div class="plan-inner">
                    {currPlan === "basic" ? <div class="current">Current Monthly</div> : null}
                    {currPlan === "basicannual" ? <div class="current">Current Annual</div> : null}
                    {/* <div class="current">Current</div> */}
                    <div class="entry-title">
                        <h3>{planGrid.basic.name}</h3>
                        <div class="price">${planGrid.basic[payCycle]}<span>{payCycle}</span>
                        </div>
                    </div>
                    <div class="entry-content">
                        <ul>
                        <li><strong>All</strong> Whiteboard Features </li>
                        <li><strong>Unlimited</strong> Boards </li>
                        <li><strong>Save</strong> 20 boards</li>
                        <li><strong>Manual</strong> Refresh for {">"} 20 boards</li>
                        </ul>
                    </div>
                    <div class="btn">
                        <a href="#" onClick={() => {
                            props.cb({type: "Select", planId: payCycle === "monthly" ? "basic" : "basicannual"})} }>Select</a>
                    </div>
                    </div>
                </div>
                {/* <!-- end of price tab-->
                <!--price tab--> */}
                <div class="plan gold">
                    <div class="plan-inner">
                    {currPlan === "gold" ? <div class="current">Current Monthly</div> : null}
                    {currPlan === "goldannual" ? <div class="current">Current Annual</div> : null}
                    {/* <div class="current">Current</div> */}
                    <div class="entry-title">
                        <h3>{planGrid.gold.name}</h3>
                        <div class="price">${planGrid.gold[payCycle]}<span>{payCycle}</span>
                        </div>
                    </div>
                    <div class="entry-content">
                        <ul>
                        <li><strong>All</strong> Whiteboard Features </li>
                        <li><strong>Unlimited</strong> Boards </li>
                        <li><strong>Save</strong> all boards</li>
                        <li><strong>No Manual</strong> Refresh needed</li>
                        </ul>
                    </div>
                    <div class="btn">
                        <a href="#" onClick={() => {
                            props.cb({type: "Select", planId: payCycle === "monthly" ? "gold" : "goldannual"})} }>Select</a>
                    </div>
                    </div>
                </div>
                {/* <!-- end of price tab-->
                <!--price tab--> */}
                {/* <div class="plan basic">
                    <div class="plan-inner">
                    <div class="hot">hot</div>
                    <div class="entry-title">
                        <h3>Express Wash</h3>
                        <div class="price">$50<span>/PER CAR</span>
                        </div>
                    </div>
                    <div class="entry-content">
                        <ul>
                        <li><strong>1x</strong> option 1</li>
                        <li><strong>2x</strong> option 2</li>
                        <li><strong>3x</strong> option 3</li>
                        <li><strong>Free</strong> option 4</li>
                        <li><strong>Unlimited</strong> option 5</li>
                        </ul>
                    </div>
                    <div class="btn">
                        <a href="#">Order Now</a>
                    </div>
                    </div>
                </div> */}
                {/* <!-- end of price tab-->
                <!--price tab--> */}
            </div>
            {/* <div class="btn">
                    <a href="#">Update Payment Method</a>
            </div> */}
            <div id="orgContactUs">
                Contact feedback@whiteboard.chat for Schools, Districts and Group plans.
            </div>
            <div id="howthisworks">
                How this works:
            </div>
            <div id="howitworksbullets" ref={divRef}>
                <ul>
                    <li>
                        <b>The Educator Free</b> plan lets you create as many boards as you need. All boards will have an expiry date
                        and will need to be refreshed to extend the expiry date.
                    </li>
                    <li>
                        <b>The Basic plan</b> also allows creation of an unlimited number of boards but allows you to turn off expiry for upto 20 boards.
                        Student boards associated with those boards will also have no expiry.
                    </li>
                    <li>
                        <b>The Gold plan</b> allows creation of an unlimited number of boards and all boards will not have an expiry date.
                    </li>
                    {/* <li>
                        If you bought Whiteboard.chat <b>Cloud Credits</b>, you will receive a credit for your donation towards a purchase of a Storage
                        plan. If you had signed up to be a Whiteboard.chat paying member, you will also receive credits towards the purchase
                        of a Storage plan.
                    </li> */}
                    <li>
                        If you bought Whiteboard.chat <b>Cloud Credits</b> or you had signed up to be a <b>Paying Member</b>, you may request to
                        receive a credit for your donation towards a purchase of a Storage plan. Please write to feedback@whiteboard.chat to
                        request for a credit prior to making your plan purchase.
                    </li>
                    <li>
                        Schools, School Districts, Enterprises, Groups and Other organizations - please reach out to feedback@whiteboard.chat and we can work out a plan for you.
                    </li>
                    <li>
                        Please write to feedback@whiteboard.chat if you have any questions or run into any problems.
                    </li>
                </ul>
            </div>
            <div id="footerButtons">
            <div style={{ height: '20px' }}></div>
            <Button onClick={(event) => { props.history.push("/manage"); }} className='homebtn'>
                <PermMediaIcon className='mr-4' />
                <Typography className="text-16 homebtn">Manage Boards</Typography>
            </Button>
            {oldManageSession && <Button onClick={backButtonClicked} className='homebtn'>
                <ArrowBackIcon />
                <Typography className="text-16 homebtn">Back</Typography>
            </Button>}
            {/* <div class="btn blueButton">
                    <a href="#">Update Payment Method</a>
            </div> */}
            { currPlan !== "educator-free" ?
                <Button onClick={(event) => {
                        props.cb({type: "UpdatePayment"})
                    }} className='homebtn'>
                    <PaymentIcon className='mr-4' />
                    <Typography className="text-16 homebtn">Update Payment Method</Typography>
                </Button> : null
            }
            {user &&
                    (<Button className='homebtn' onClick={() => { SignOut() }}>
                    <LockIcon className='mr-4' />
                    <Typography className="text-16 homebtn">Sign Out</Typography>
                </Button>)
            }
            <div>
            <Footer setStartNewBoard={setStartNewBoard}/>
            </div>
            </div>
        </>
    )
}


export default Subscribe;