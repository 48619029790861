import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
    ListItemAvatar, Avatar, InputLabel, TextField,
    MenuItem, Checkbox, Select, ListItemSecondaryAction, Divider,
    IconButton, Typography
} from "@material-ui/core"
import { useSelector } from 'react-redux'
import { v4 as uuid } from "uuid";
import * as ib from "./ibdata"
import ReactGA from 'react-ga';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    rightSide: {
        marginLeft: 'auto',
    },
    inviteButton: {
        backgroundColor: "#3174F5",
        marginLeft: 'auto',
        height: '2rem',
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '5px',
        marginRight: "5px",
        padding: "5px",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
});

export default function ParticipantDrawer(props) {
    const classes = useStyles();
    const participants = useSelector((state) => state.participants);
    const [parts, setParts] = React.useState([])
    const [checked, setChecked] = React.useState([]);
    const [action, setAction] = React.useState('');
    const [text, setText] = React.useState("");

    const handleAction = (event) => {
        setAction(event.target.value);
    };
    React.useEffect(() => {
        var ff = []
        Object.keys(participants).forEach(item => {
            const p = participants[item]
            var pp 
            try {
                pp = JSON.parse(p.content)
            } catch {
                pp = null 
            }
            const color = pp && pp.color ? pp.color : "green"
            const email = pp && pp.email ? pp.email : ""
            if (pp && p.name) {
                ff.push({
                    name: p.name,
                    email: email, color: color,
                    id: p.id, profile: p.UserProfile, avatar: p.avatar, localID: pp.localID
                })
            }
        })
        ff.sort((a, b) => b.active - a.active)
        setParts(ff)
    }, [participants])
    function handleToggle(value) {   
        setChecked(prev => {
            const newChecked = [...prev];
            const currentIndex = newChecked.indexOf(value);
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
            return newChecked
        });
    }

    function SendEvent(e) {
        var event = uuid()
        var classroom = props.sess.Classroom
        e.preventDefault()
        if (checked.length > 0 && text.length > 0) {
            var forArray = []
            var jj = { action: action, text: text }
            var cmd = { event: event, Classroom: classroom, ttl: props.sess.ttl,
                type: "Poll", State: "Sent", Content: JSON.stringify(jj) }
            checked.map((c) => {
                forArray.push({ name: c.name, localID: c.localID })
                var copy = JSON.parse(JSON.stringify(cmd))
                copy['For'] = c.localID + ":" + c.name
                ib.createClassroomEvent(copy)
            })
        }
        setChecked([])
        setText("")
        setAction('')
        ReactGA.event({
            category: "Send Poll " + checked.length,
            action: action
        });
        toggleDrawer("right", false)
    }
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.handleClose()

        setState({ ...state, [anchor]: open });
    };

    function pasteText(e) {
        e.preventDefault()
        setText(e.clipboardData.getData('text/plain'))
    }
    function selectAll(e) {
        e.preventDefault()
        parts.map((a)=>{
            handleToggle(a)
            return a
        })
    }
    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            Experimental: Send a link to the students OR send questions with buttons as answers.
            The questions needs to be formatted with , and ?:
            <br /> What is your fav color? Blue, Green, Red
            <Divider />
            <List dense className={classes.listroot}>
                {parts.map((a) => {
                    { var inverseColor = ((a.color === "white") || (a.color.toUpperCase() === "#FFFFFF")) ? "black" : "white" }
                    return (
                        <ListItem key={a.id} button dense onClick={()=>handleToggle(a)}>
                            <Checkbox
                                edge="start"
                                tabIndex={-1}
                                checked={checked.indexOf(a) !== -1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': a.id }}
                            />
                            <ListItemText primary={a.name} secondary={a.email} />
                            <ListItemSecondaryAction>
                                <ListItemAvatar>
                                    <Avatar
                                        key={a.id}
                                        src={a.avatar}
                                        style={{ color: inverseColor, backgroundColor: a.color }}
                                    >
                                        {a.name[0].toUpperCase()}
                                    </Avatar>
                                </ListItemAvatar>
                            </ListItemSecondaryAction>

                        </ListItem>
                    )
                })}
            </List>
            <Button fullWidth onClick={selectAll} style={{backgroundColor: props.inkColor}}>Toggle All</Button>
            <InputLabel id="demo-simple-select-label">Action</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={action}
                onChange={handleAction}
                fullWidth
            >
                <MenuItem value={"link"}>Send Link</MenuItem>
                <MenuItem value={"poll"}>Send Poll</MenuItem>
                <MenuItem value={"vote"}>Vote</MenuItem>
            </Select>
            {action !== "" && (
                <>
                    <form onSubmit={SendEvent}>
                        <TextField
                            id="standard-helperText"
                            fullWidth
                            value={text}
                            onPaste={pasteText}
                            onChange={(e) => setText(e.target.value)}
                            label={"Please input " + action}
                            helperText="Link or Poll Q&A [Format: Question? ans1, ans2]"
                            type="text"
                        />
                        <Button className={classes.inviteButton}
                            fullWidth
                            style={{ backgroundColor: props.inkColor }}
                            disabled={text.length === 0 || checked.length === 0}
                            onClick={SendEvent}>
                            Send
                    </Button>
                    </form>

                </>
            )}
        </div>)

    return (
        <div>
            {['right'].map((anchor) => (
                <div key={anchor}>
                    <div >
                        <IconButton id="pollButton" style={{ backgroundColor: props.inkColor }} className={classes.inviteButton} onClick={toggleDrawer(anchor, true)}>
                        {/* <Button style={{ backgroundColor: props.inkColor }} className={classes.inviteButton} onClick={toggleDrawer(anchor, true)}>New Poll</Button> */}
                        <Typography variant="h6">New Poll</Typography> 
                        </IconButton>
                    </div>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                        {list(anchor)}
                    </Drawer>
                </div>
            ))}
        </div>
    );
}