import React from 'react';
import { useDispatch } from 'react-redux';
import { Auth, Hub } from 'aws-amplify'
import * as ib from "./ibdata.js"
import * as Actions from "./store/actions"
import * as mylocalStorage from "./mylocalStorage";

const TIMER_DELAY = 5000;

export default function SocialLogin(props) {
    const dispatch = useDispatch();
    const errorHandler = React.useCallback((...args) => {
        console.error('Social Auth Sign-in Error: ', ...args);
    }, []);

    React.useEffect(() => {
        function redirectPage(u) {
            dispatch(Actions.setUser(u));
            var redirectUrl = mylocalStorage.getItem('redirectUrl');
            if (redirectUrl) {
                //mylocalStorage.removeItem('redirectUrl');
                props.history.push(redirectUrl);
            } else {
                props.history.push("/");
            }
        }

        if (window.location.pathname === '/logout/') {
            mylocalStorage.removeItem('myname');
            var backupId = mylocalStorage.getItem('backupLocalID');

            if (backupId) {
                mylocalStorage.setItem('mystoreID', backupId);
            } else {
                mylocalStorage.removeItem('mystoreID');
            }

            Auth.currentAuthenticatedUser()
            .then((d) => {
            })
            .catch((e) => {
                redirectPage(null);
            })
            return;
        }

        //console.log("social login: adding timeout");
        // This is the relevant part
        const timeoutId = setTimeout(async () => {
            try {
                // console.log("social auth: timeout fired")
                Auth.currentAuthenticatedUser().then((u) => {
                    updateUser(u);
                })
            } catch (error) {
                errorHandler(error);
            }
        }, TIMER_DELAY);

        function updateUser(u) {
            if (u) {
                var userInfo = ib.getUserInfo(u);
                ib.findCreateUserProfile(u.username, u.attributes.email,
                    userInfo.avatar, userInfo.name, mylocalStorage.getItem('redirectUrl'),
                    function (){redirectPage(u)});
            } else {
                redirectPage(u);
            }
        }

        Hub.listen('auth', data => {
            switch (data.payload.event) {
                case 'signIn':
                    //console.log("social login: got hub msg");
                    Auth.currentAuthenticatedUser()
                    .then((d) => {
                        updateUser(d);
                    })
                    return
                case 'signOut':
                    return updateUser(null);
                default:
                    return;
            }
        });

        //console.log("social auth: finished useEffect");
        return () => {
            //console.log("social login: removing timeout");
            clearTimeout(timeoutId);
        };
    }, [])

    return <>.</>;
}