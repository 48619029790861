import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import {
  IconButton,
  Grid,
  CardActions,
  Tooltip,
  TextField,
  Button,
  Box
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import VisibilityIcon from '@material-ui/icons/Visibility';
import "./BoardTile.css"
import RefreshIcon from '@material-ui/icons/Refresh';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import LinkIcon from '@material-ui/icons/Link';
import * as mylocalStorage from "./mylocalStorage"

const useStyles = makeStyles({
  root: {
    transition: '0.3s',
    position: 'relative',
    boxShadow: 'none',
    overflow: 'initial',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 200,
    margin: '8px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  expandBigButton: {
    backgroundColor: "#3174F5",
    fontSize: "0.5rem",
    fontFamily: 'Poppins',
    fontWeight: 'normal',
    textTransform: "none",
    display: 'inline-flex',
    borderRadius: '5px',
    marginRight: "5px",
    padding: "3px",
    color: "#ffffff",
    "&:hover": {
        backgroundColor: '#EBBF23',
        color: '#3174F5',
    },
  },
  typography: {
    padding: 8,
  }
});

function BoardTile(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEditable, setIsEditable] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  function onBlurUpdate(){
    if (props.editable === false) return
    setIsEditable(!isEditable)
    props.rowBlur(props.data)
  }

  function onSubmit(event){
    event.preventDefault();
        props.rowBlur(props.data)
    setIsEditable(!isEditable)
  }

  function handleOpen() {
    props.handleOpen(props.data);
  }

  function copyURLtoClipboard(url) {
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    console.log("Window href ", window.location)
    console.log("Copied url ", url)
  }

  function copyLinkToClipboard(bid) {
    copyURLtoClipboard(window.location.origin + "/copy/" + bid)
  }

  let curColor = mylocalStorage.getItem("mycolor")

  if (!(curColor)) {
      curColor = "#8f3ee0";
  }

  return (
    <div >
      <Card style={{ borderBottom: props.iconProps ? '5px solid '+ props.iconProps.color : '5px solid #5D5770' }} className={clsx('tileBgBox', classes.root)}>
        {props.data === null && (<CardContent className='m-auto'>
          <IconButton className='py-4 px-4 iconBtn' aria-label="Blank">
            <AddIcon className="custom_primary" />
          </IconButton>
        </CardContent>

        )}
        {props.data !== null && (
          <>
            <CardContent className='w-full p-16 pb-0' key={props.data.id}>
              <Grid container  className='tileContainer'>
                <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                  {/* <div className='flex w-full justify-between'> */}
                  <div className='flex justify-between'>
                  <div className='flex ml-8 overflow-hidden'>
                  <div className='overflow-hidden'>
                        {props.parentName ?
                        <div style={{paddingTop: '2px'}}>

                          <Typography component={'span'} className='boardTitle Text-ellipsis' gutterBottom onClick={() => props.rowClick2(props.data)}>
                            {props.parentName}
                            <KeyboardArrowRightIcon className='iconPosition'/>
                            {props.Edit2(props.data)}
                          </Typography>
                          {props.boardOwners ?
                          <Typography>
                            Participants: {props.boardOwners}
                          </Typography> : null}
                        </div>
                          :
                        <div style={{paddingTop: '12px'}} className='BoardTitleContent'>
                          <Typography component={'span'} className='boardTitle Text-ellipsis' gutterBottom onClick={() =>
                            { if (props.editable === false) return; setIsEditable(!isEditable) }}>
                            {isEditable ?
                              <form onBlur={onBlurUpdate} onSubmit={(event) => { onSubmit(event) }}>
                                  <TextField
                                      margin="dense"
                                      id="name"
                                      autoFocus
                                      value={props.data.name}
                                      label="Name"
                                      variant="outlined"
                                      type="text"
                                      onBlur={() => onBlurUpdate}
                                      onChange={(event) => props.setText(event.target.value, props.data)}
                                      />
                              </form>
                            : ( props.community && props.community === true ? props.boardInfo.title : props.data.name )
                            }
                          </Typography>
                        </div>
                        }
                      { props.community && props.community === true && props.boardInfo ?
                        <>
                        <Box component="div" overflow="hidden" fontSize={'0.55rem'} lineHeight={'0.7rem'}
                          style={{ marginBottom: '0.1rem' }}>
                          Grade: {props.boardInfo.Grade}<br/>
                          Subject: {props.boardInfo.Subject}<br/>
                          Contributor: {props.boardInfo.name}<br/>
                        </Box>
                        <Box component="div" overflow="hidden" fontSize={'0.55rem'} overflow='scroll' lineHeight={'0.6rem'}
                          style={{ maxHeight: '2rem' }}>
                          Desc: {props.boardInfo.description}<br />
                        </Box>
                        </>
                        : null }
                  </div>
                  </div>
                    <div className='moreBtn'>

                      {props.showExpand &&
                        (props.rowIsOpen ? (
                          <IconButton className={classes.expandBigButton} onClick={handleOpen}>
                            Hide Class Boards
                          </IconButton>
                        ) : (
                          <IconButton className={classes.expandBigButton} onClick={handleOpen}>
                            Show Class Boards
                          </IconButton>
                          )
                        )
                      }
                      {props.community && props.community === true ?
                        '' :
                        <IconButton onClick={handleClick} className='p-0 my-4'>
                          <MoreVertIcon />
                        </IconButton>
                      }
                    </div>
                    </div>
                </Grid>
              </Grid>

              <CardActions className='pt-0'>

                 <div className='flex justify-between w-full flex-align-center'>
                  <div className='flex flex-column'>
                    {props.community && props.community === true ?
                      <Typography component={'span'} className='boardContent'>
                        Last Updated on {props.localDateFormat(props.data.updatedAt)}
                      </Typography>
                      :
                      <>
                        <Typography component={'span'} className='boardContent'>
                          Expires on {props.localDateFormat(new Date(props.data.ttl * 1000))}
                        </Typography>
                        <Typography component={'span'} className='boardContent'>
                          Last Updated on {props.localDateFormat(props.data.updatedAt)}
                        </Typography>
                      </>
                    }
                </div> 
                <div className='mr-10'>
                    {props.community && props.community === true ?
                      <div>
                        <Tooltip title="Copy link to clipboard">
                        <Button variant="contained" size="small" onClick={() => copyLinkToClipboard(props.boardInfo.boardLink)} style={{ backgroundColor: props.iconProps ? props.iconProps.color : '#5D5770', marginRight: '4px' }}>
                        <LinkIcon fontSize="small" className='cursor-pointer' style={{ color: "#ffffff" }} />
                                    <Typography className="text-16" style={{ color: "#ffffff" }}>Link</Typography>
                        </Button>
                        </Tooltip>
                        <Tooltip title="Copy this board for me">
                        <Button variant="contained" size="small" onClick={() => props.CopyBoard(props.boardInfo.boardLink)} style={{ backgroundColor: props.iconProps ? props.iconProps.color : '#5D5770' }}>
                        <FileCopyOutlinedIcon fontSize="small" className='cursor-pointer' style={{ color: "#ffffff" }} />
                                    <Typography className="text-16" style={{ color: "#ffffff" }}>Copy</Typography>
                        </Button>
                        </Tooltip>
                      </div>
                      :
                      <svg style={{ cursor: 'pointer' }}
                        onClick={(ev) => {
                          props.viewAction(props.data)
                        }}
                        width="50" height="8" viewBox="0 0 50 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M49.3536 4.35355C49.5488 4.15829 49.5488 3.8417 49.3536 3.64644L46.1716 0.464462C45.9763 0.2692 45.6597 0.2692 45.4645 0.464462C45.2692 0.659724 45.2692 0.976307 45.4645 1.17157L48.2929 4L45.4645 6.82842C45.2692 7.02369 45.2692 7.34027 45.4645 7.53553C45.6597 7.73079 45.9763 7.73079 46.1716 7.53553L49.3536 4.35355ZM4.37114e-08 4.5L49 4.5L49 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z" fill={props.iconProps ? props.iconProps.color : "#5D5770"} />
                      </svg>
                    }
                </div> 
                </div> 
                    </CardActions>
                    </CardContent>

          </>)}
      </Card>
      <Popover
        className='pbPopUp'
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >

        <div className='flex justify-between m-12 onHover' onClick={(ev) => {
          props.viewAction(props.data)
        }} style={{ cursor: 'pointer' }}>
          <Typography className={classes.typography}>View</Typography>
          <IconButton className='p-0 m-8'
            onClick={(ev) => {
              props.viewAction(props.data)
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </div>
        {props.data.CreatorLocalID === props.user ?
        <div className='flex justify-between m-12 onHover'
        onClick={(ev) => {
          ev.stopPropagation();
          props.deleteAction(props.data)
          handleClose()
        }} style={{ cursor: 'pointer' }}>
          <Typography className={classes.typography}>Delete</Typography>
          <IconButton className='p-0 m-8'
            onClick={(ev) => {
              ev.stopPropagation();
              props.deleteAction(props.data)
              handleClose()
            }}
            >
            <DeleteForeverIcon />
          </IconButton>
        </div>
            : ''}

        <div className='flex justify-between m-12 onHover'>
        <Typography className={classes.typography}>Expires at</Typography>
        <Tooltip arrow title={'Refresh to Renew Expiration'} placement="left" >
          <IconButton className='p-0 m-8 textContent' onClick={() =>props.handleRefresh(props.data)} >
            <RefreshIcon />
            {props.localDateFormat(new Date(props.data.ttl * 1000))}
          </IconButton>
          </Tooltip>

        </div>
      </Popover>
    </div>
  )
}
export default BoardTile