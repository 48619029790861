import React from 'react';
import { ListItemText, ListItem, List, ListItemIcon, IconButton, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import './styles/style.css'
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import ExploreIcon from '@material-ui/icons/Explore';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DescriptionIcon from '@material-ui/icons/Description';
import FunctionsIcon from '@material-ui/icons/Functions';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ReactGA from 'react-ga';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NavigationIcon from '@material-ui/icons/Navigation';
import HelpIcon from '@material-ui/icons/Help';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ListIcon from '@material-ui/icons/List';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NavigateWhiteBoard = 'https://drive.google.com/file/d/1TvcUYWayQKNRK0UhPqSkVx-gnP6oLV7B/view?usp=sharing'
const whatsNew = 'https://medium.com/epiphani/whiteboard-chat-latest-news-4ab127795a7b'
const CompleteFeatures = 'https://drive.google.com/file/d/1BfsvA9-94AdoVLdYzXMVY5XAGZOf5clD/view?usp=sharing'
const top100 = 'https://drive.google.com/file/d/1uT1dgONKcnkklrv0mf1PSXe6cLzEOvXN/view?usp=sharing'
const TeachersGuid = 'https://drive.google.com/file/d/1LPvUTVjaRRo2-1_mulaKPvyHi1YOpQPs/view?usp=sharing'
const studentsGuid = 'https://drive.google.com/file/d/1rSYgRKFu1l-Q-gH_JbFY52RvwhaliKyL/view?usp=sharing'
const mathGuid = 'https://drive.google.com/file/d/1lJUjhgtgxjwPilO06W2qiwPeAAdcNn4K/view?usp=sharing'
const faqs = 'https://medium.com/epiphani/frequently-asked-questions-whiteboard-chat-1ee1e0616182'
const  allHelpArticles = 'https://medium.com/epiphani/whiteboard-chat-video-tutorials-faqs-and-detailed-help-articles-e93e792ea339'
const allTutVids = 'https://www.youtube.com/whiteboardchat/videos'
const webinarTraining = 'https://forms.gle/2uuHV5ucM987ehKV8'
const wbcInMs = 'https://medium.com/epiphani/whiteboard-chat-in-microsoft-teams-74015c41ca9f'

export default function TipsDialog(props) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(()=>{
    setOpen(props.setopen)
  }, [props])

  const handleClose = () => {
    props.cb()
    setOpen(false);
  };


  function startTour() {
    try {
      ReactGA.event({
          category: 'User',
          action: 'WelcomeTourManualLaunch',
      });
    } catch {  }
    if (props.tourRequestedCB) {
      props.tourRequestedCB()
      handleClose()
    }    
  }

  function openLink(url) {
    window.open(url)
  }

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className='tipsDialog'
      >
        <div className='flex justify-between  py-20'>

        <DialogTitle id="alert-dialog-slide-title" className='dialogTitle'>Help | Whiteboard Tips</DialogTitle>
          <CloseIcon className='ndCloseIcon' style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%",position: "absolute",top: 20 }} onClick={handleClose}  />
        </div>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" component={'span'}>
          <List component={'span'}>
          <ListItem key="tour" button onClick={startTour}>
                <ListItemIcon><ExploreIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Guided Tour"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>
              <ListItem key="nav" button onClick={(e) => { openLink(NavigateWhiteBoard) }}>
                <ListItemIcon><NavigationIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Navigating Whiteboard"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>

              <ListItem key="webinar" button onClick={(e) => { openLink(webinarTraining) }}>
                <ListItemIcon><PlayCircleOutlineIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Webinar Training"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>

          <ListItem key="whatsnew" button onClick={(e)=>{openLink(whatsNew)}}>
                <ListItemIcon><NewReleasesIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="What's New"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>


          <ListItem key="complete" button onClick={(e)=>{openLink(CompleteFeatures)}}>
                <ListItemIcon><MenuBookIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Complete Feature List"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>

          <ListItem key="top" button onClick={(e)=>{openLink(top100)}}>
                <ListItemIcon><ListIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Top 100 Features"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>
          <ListItem key="MS" button onClick={(e)=>{openLink(wbcInMs)}}>
                <ListItemIcon><DescriptionIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Accessing Whiteboard from Microsoft Teams"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>
          <ListItem key="teacher" button onClick={(e)=>{openLink(TeachersGuid)}}>
                <ListItemIcon><DescriptionIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Teacher's Guide"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>
          <ListItem key="student" button onClick={(e)=>{openLink(studentsGuid)}}>
                <ListItemIcon><DescriptionIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Student's Guide"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>

          <ListItem key="math" button onClick={(e)=>{openLink(mathGuid)}}>
                <ListItemIcon><FunctionsIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="Math Input Guide"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>

          <ListItem key="faq" button onClick={(e)=>{openLink(faqs)}}>
                <ListItemIcon><QuestionAnswerIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="FAQs"
                      classes={{primary:'primaryContent'}}/>
              </ListItem>          
              <ListItem key="all" button onClick={(e) => { openLink(allHelpArticles) }}>
                <ListItemIcon><HelpIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="All Help Articles"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>
              <ListItem key="tutorial" button onClick={(e) => { openLink(allTutVids)}}>
                <ListItemIcon><YouTubeIcon style={{ color: '#2F3D5B' }} /></ListItemIcon>
              <ListItemText primary="All Tutorial Videos"
                      classes={{primary:'primaryContent'}}/>
          </ListItem>
        </List>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}