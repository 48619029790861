import React from 'react'
import { useSelector } from 'react-redux'
import * as ib from './ibdata'
import * as iu from "./ImageUtils"
import paper from 'paper';
import ReactGA from 'react-ga';
import pako from 'pako'

function BatchBoardExporter(props) {
    const authUser = useSelector((state) => state.user)

    async function HandleExport(local, sessionID, url, instance) {
        var instanceObj = { local, url, instance }

        // handleEscape()
        var jsonObjt = []
        var boardName = null
        const currPage = sessionID
        const pgSplit = currPage.split("-pgNum-")
        const pages = await ib.getboardsbyparentSync(pgSplit[0])
        if (pages && pages.length > 0) {
            if (pages[0] && pages[0].name === "unsaved") {
                console.log(pages[0].id, "Board needs a name, skipping.")
                return
            } else {
                boardName = pages[0].name
            }
            var sessionObj = { objType: 'session', data: pages }
            jsonObjt.push(sessionObj)
        }
        var allObjects = []
        for (let i = 0; i < pages.length; i++) {

            const page = pages[i]
            var gSessionID = page.id
            var objs = await ib.listObjectSync(page.id)
            allObjects.push(...objs)
            // console.log('objects', objs)
            if (objs && objs.length > 0) {
                // addObjects(jsonObjt,objs)
            }
            // console.log('objects stringify', JSON.stringify(objs))
            if (page.parentBoardID && page.parentBoardID !== page.id) {
                var objs1 = await ib.listObjectSync(page.parentBoardID)
                if (objs1.length > 0) {
                    allObjects.push(...objs1)
                    // console.log('oparentboardid objects', objs)
                }
            }

        }
        if (allObjects && allObjects.length > 0) {
            addObjects(jsonObjt, allObjects, boardName, instanceObj)
        }
    }

    function addObjects(jsonObjt, objs, boardName, instanceObj) {
        var objectsObj = { objType: 'objects', data: objs }
        jsonObjt.push(objectsObj)
        var promiseArr = [];
        var filterobj1 = jsonObjt.filter(j => j.objType === 'objects')
        if (filterobj1 && filterobj1[0].data && filterobj1[0].data.length > 0) {
            filterobj1[0].data.forEach(element => {
                try {

                    if (element.objType === "image") {
                        const ct = JSON.parse(element.content)
                        var url = ct.url
                        var promise1 = new Promise((resolve, reject) => {
                            iu.GetImage(url).then((ff) => {
                                var raster = new paper.Raster({ source: ff.img, crossOrigin: 'anonymous' });
                                raster.onLoad = function () {
                                    var xx = raster._size.width / 2
                                    var yy = raster._size.height / 2
                                    raster.position = new paper.Point(xx + 60, yy + 40)
                                    element.imgB64 = raster.toDataURL('image/jpeg')
                                    resolve(element)

                                }
                                raster.onError = function () {
                                    console.log('onError', url)
                                    reject('Promise is rejected at getImage')
                                }
                            })
                        })
                        promiseArr.push(promise1)
                    } else if (element.objType === "drawPaper") {
                        var content = element.content
                        var eleType = JSON.parse(element.type)
                        if (eleType && eleType.compressed) {
                            content = pako.inflate(element.content, { to: 'string' });
                        }
                        const ct = JSON.parse(content)
                        var pobj = new paper[ct[0]]()
                        if (ct[0] === "Raster") {
                            try {
                                const ff = JSON.parse(content)
                                const rrt = ff[1].source.split("?X-Amz")[0]
                                ff[1].source = rrt
                                content = JSON.stringify(ff)
                            } catch (e) {
                                console.error("ERROR IS ", e)
                            }
                        }
                        pobj.data.id = element.id
                        pobj.importJSON(content)
                        var resolvedProm2 = null
                        if (ct[0] === "Raster") {
                            const promise2 = new Promise((resolve, reject) => {
                                pobj.onLoad = function (f) {
                                    //this is for drawPaper obj but inbuilt stickers/objects
                                    resolve()
                                }
                                resolvedProm2 = resolve
                            });
                            promiseArr.push(promise2)
                            pobj.onError = function (f) {
                                //this is for drawPaper objects i.e. added img is moved or resized
                                // cross domain failures for images?
                                //promise2 resolve it if it is added for the same object
                                promiseArr = promiseArr.filter(p => p !== promise2)
                                resolvedProm2()
                                const ff = JSON.parse(content)
                                var promise3 = new Promise((resolve, reject) => {

                                    iu.GetImage(ff[1].source).then((rr) => {
                                        ff[1].source = rr.img
                                        var raster = new paper.Raster({ crossOrigin: 'anonymous', source: rr.img });
                                        raster.onLoad = function () {
                                            var xx = raster._size.width / 2
                                            var yy = raster._size.height / 2
                                            raster.position = new paper.Point(xx + 60, yy + 40)
                                            element.imgB64 = raster.toDataURL('image/jpeg')
                                            resolve()
                                        }

                                    })
                                })
                                promiseArr.push(promise3)
                            }
                        }
                    }
                } catch (error) {
                    console.log('Error while Export error msg:', error)
                }

            })


            Promise.all(promiseArr).then(values => {
                ExportBoards(jsonObjt, boardName, instanceObj)
            })
        }
    }

    function ExportBoards(jsonObjt, boardName, instanceObj) {

        function callAniMsg(msg) {
            console.log(msg)
            try {
                ReactGA.event({
                    category: 'User',
                    action: 'Board exported to ' + instanceObj.instance
                });
            } catch { }
            if (props.exportDoneCB) {
                props.exportDoneCB()
            }
        }

        function copyErrorCB(msg) {
            console.log(msg)
            try {
                ReactGA.event({
                    category: 'User',
                    action: 'Export to ' + instanceObj.instance + ' failed.'
                });
            } catch { }
        }

        if (!instanceObj.local) {
            console.log("Exporting board, please wait ...")
            ib.copyBoard(jsonObjt, instanceObj, authUser.attributes.email, callAniMsg, copyErrorCB)
            // ib.copyBoard(jsonObjt, instanceObj, "nonexistent@fakeemail.com", callAniMsg, copyErrorCB)
            return
        }


        var data = JSON.stringify(jsonObjt)
        const filename = boardName + '.json';
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        try {
            ReactGA.event({
                category: 'User',
                action: 'BoardExported'
            });
        } catch { }
        console.log("Export Done")
        if (props.exportDoneCB) {
            props.exportDoneCB()
        }
    }

    React.useEffect(() => {
        var canvas = document.getElementById('myCanvas');
		// Create an empty project and a view for the canvas:
		paper.setup(canvas);
    }, [])

    React.useEffect(() => {
        var exportSessionID = null
        if (props && props.exportSessionID) {
            exportSessionID = props.exportSessionID
        }
        // exportSessionID = '05a0622a-4cf4-425b-9209-d819cb302534-pgNum-1'
        if (exportSessionID) {
            var local = false
            var remoteURL = 'https://uswest.whiteboard.chat'
            var instance = 'uswest'
            if (local) {
                HandleExport(local, exportSessionID)
            } else {
                HandleExport(local, exportSessionID, remoteURL, instance)
            }
        }
    },[props]);

    return (
        <>
        <canvas id="myCanvas" resize style={{ display: 'none' }}></canvas>
        </>
    )
}

export default BatchBoardExporter;