import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DialogTitle, FormControlLabel, Switch } from '@material-ui/core';
import { InputBase, Typography} from '@material-ui/core';

export default function AnswerDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const [link, setLink] = React.useState({ points: "", answer: "", ignoreSpecial: false });

    const handleChange = (event) => {
        setLink({ ...link, [event.target.name]: event.target.checked });
    };
    const handleSave = () => {
        setOpen(false);
        var ff = { ...link }
        ff.points = parseInt(ff.points)
        ff.answer = ff.answer.trim()
        ff.children = props.children
        if (props.cb) props.cb(ff)
    };
    React.useEffect(() => {
    }, [])
    React.useEffect(() => {
        setOpen(props.open)
        if (props.linkstate) {
            const ls = props.linkstate
            var ff = ls.points
            var grp = ls.answer
            setLink({ points: ff, answer: grp, ignoreSpecial: ls.ignoreSpecial })
        }
    }, [props])
    const handleClose = () => {
        setOpen(false);
        if (props.cb) props.cb(null)
    };
    function pasteText(e) {
        e.preventDefault()
        const txt = e.clipboardData.getData('text/plain')
        setLink({
            ...link,
            [e.target.id]: txt
        })
    }

    function handleType(e) {
        setLink({
            ...link,
            [e.target.id]: e.target.value
        })
    }
    function handleCheck(e) {
        setLink({
            ...link,
            [e.target.name]: e.target.checked
        })
    }
    // function handleSubmit(e) {
    //     console.log("HIT SUBMIT")
    //     e.preventDefault()
    //     if (link.name.length >= 0 && link.link.length >= 0) handleSave()
    // }
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className= "addAnswer" >Add Answer</DialogTitle>
            <DialogContent style= {{width: "400px"}} >
                <div className= "dialogContentText">
                <DialogContentText style= {{marginBottom: "0px"}}>

                    <InputBase
                        autoFocus
                        margin="dense"
                        id="points"
                        name="points"
                        placeholder="Points"
                        fullWidth
                        value={link.points}
                        onChange={(e) => handleType(e)}
                        className= "inputname"
                    />
                    {!props.children && (
                        <TextField
                            margin="dense"
                            id="answer"
                            name="answer"
                            label="Answer"
                            fullWidth
                            multiline
                            helperText="Separate options with ; like: $25; 25"
                            value={link.answer}
                            variant="outlined"
                            onPaste={pasteText}
                            onChange={(e) => handleType(e)}
                        />
                    )}

                </DialogContentText>
                </div>
                <div className = "formControlAlignment">
                <FormControlLabel
                    className = "formControlLabelPart"
                    labelPlacement="start"
                    control={<Switch checked={link.ignoreSpecial} onChange={handleChange} name="ignoreSpecial" />}
                    label="Ignore special characters ($, %...)"
                />
                </div>
            </DialogContent>
            <DialogActions style={{padding:"0px"}}>
                <div className="positioning">
                    <Typography
                        size="small"
                        style={{ marginRight: "24px", fontSize: "16px", }}
                        onClick={handleClose}
                        className='formCancel'
                    >
                        Cancel
                    </Typography>
                    <Button onClick={handleSave} color="primary" className="createButton" style={{ backgroundColor: props.inkColor }} >
                        Save
                    </Button>
                </div>
            </DialogActions>

        </Dialog>
    );
}
