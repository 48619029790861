import paper, { Path } from 'paper';
import { v4 as uuid } from "uuid";

export function calcClick(text, e) {
    var but = e.data.linkData.button 
    if (but === "C") {
        text.content =  "0"
        return true 
    }
    if (text.content === "0" && but >= "1" && but <= "9") {
        text.content = but
        return true 
    }
    if (text.content === "0" && (but === "(" || but <= ")")) {
        text.content = but
        return 
    }

    if (but === "=") {
        let tt = text.content.replace(/×/g, "*")
        tt = tt.replace(/÷/g, "/")
        text.content = eval(tt)
        return true 
    }
    text.content += e.data.linkData.button 
    return true 
}
export function drawCalc(inkColor) {

    var buttons = [
        ["+", "-", "×", "÷"], 
        ["7", "8", "9", "C"],
        ["4", "5", "6", "("],
        ["1", "2", "3", ")"],
        ["", "0", ".", "="]
    ]
    var obj = []
    var rr = {}
    rr.x = window.innerWidth/2+100
    rr.y = 200
    rr.height = 380
    rr.width = 300
    var rrT = new paper.Rectangle(rr)
    var cornerSize = new paper.Size(10, 10);
    let rectanglePath = new Path.Rectangle(rrT, cornerSize);
    rectanglePath.fillColor = inkColor
    obj.push(rectanglePath)

    var rrT2 = new paper.Rectangle({ x: rr.x + 20, y: rr.y + 20, width: rr.width - 40, height: 50 })
    var cornerSize2 = new paper.Size(10, 10);
    let rectanglePath2 = new Path.Rectangle(rrT2, cornerSize2);
    rectanglePath2.fillColor = "white"
    rectanglePath2.data.screen = true
    obj.push(rectanglePath2)

    const id = uuid()
    var startx = rr.x + 50
    var starty = rr.y + 100
    var butsize = 50

    for (let r = 0; r < buttons.length; r++) {
        var brow = buttons[r];
        for (let c = 0; c < brow.length; c++) {
            let but = brow[c]
            var pp = parseInt(but)
            let rrT2 = new paper.Rectangle({
                x: startx + (c * butsize),
                y: starty + (r * butsize), width: butsize, height: butsize
            })
            let rectanglePath2 = new Path.Rectangle(rrT2);
            rectanglePath2.strokeColor = "grey"
            rectanglePath2.strokeWidth = 2
            if (r > 0 && c < 3) {
                rectanglePath2.fillColor = "white"

            } else {
                rectanglePath2.fillColor = "#ed7d31"
            }

            var text = new paper.PointText(new paper.Point(startx + (c * butsize) + butsize / 2,
                starty + (r * butsize) + butsize / 2 + 5));
            text.content = but
            let col = (r > 0 && c < 3) ?  inkColor : "white"
            text.style = {
                fontFamily: 'roboto',
                fontSize: 20,
                fillColor: col,
                justification: 'center'
            };
            if (but !== "") {
                rectanglePath2.data.linkData = { type: "stoplight", button: but, id: id, subtype: "calc" }
                rectanglePath2.data.linkDataButtonDraw = true
                rectanglePath2.data.linkButton = true
                text.data.linkData = { type: "stoplight", button: but, id: id, subtype: "calc" }
                text.data.linkDataButtonDraw = true
                text.data.linkButton = true
            }
            obj.push(rectanglePath2)
            obj.push(text)


        }
    }

    var group = new paper.Group(obj)
    group.data.stoplightGrp = id
    group.data.linkData = { type: "stoplight", group: true, subtype: "calc" }
    group.data.linkDataButtonDraw = true

    return group

}