
function convertStringToArrayBufferView(str) {
    var bytes = new Uint8Array(str.length);
    for (var iii = 0; iii < str.length; iii++) {
        bytes[iii] = str.charCodeAt(iii);
    }

    return bytes;
}

function convertArrayBufferViewtoString(buffer) {
    var str = "";
    for (var iii = 0; iii < buffer.byteLength; iii++) {
        str += String.fromCharCode(buffer[iii]);
    }

    return str;
}

var password = "whiteboardR0ck$";

var key = null;

function genMyKey(cb) {
    if (window.crypto && window.crypto.subtle) {
        window.crypto.subtle.digest({ name: "SHA-256" }, convertStringToArrayBufferView(password)).then(function (result) {
            window.crypto.subtle.importKey("raw", result, { name: "AES-CBC" }, false, ["encrypt", "decrypt"]).then(function (e) {
                key = e;
                if (cb) cb()
                //encrypt_data();
            }, function (e) {
                alert("Failed to generate key", e.message)
                console.log(e);
            })
        });
    } else {
        if (cb) {
            alert("Crypto engine not found to generate key")
        }
    }
}
genMyKey(null)
function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    let encoded = window.btoa(binary);
    encoded = encoded.replace(/\+/g, '-')
    encoded = encoded.replace(/\//g, '_')
    encoded = encoded.replace(/=+$/, '')

    return encoded
}

function base64ToArrayBuffer(base64) {
    base64 = base64.replace(/-/g, '+')
    base64 = base64.replace(/_/g, '/')

    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

const vector = new Uint8Array([100, 228, 100, 228, 5, 162, 67, 175, 72, 255, 51, 142, 254, 191, 247, 224]);//crypto.getRandomValues(new Uint8Array(16));
// var encrypted_data = null;
export function encrypt_data(data, cb) {
    if (!window.crypto) {
        alert("Crypto engine not found, please try different browser")
        return
    }
    if (!key) {
        genMyKey(done)
    } else {
        done()
    }
    function done() {
        window.crypto.subtle.encrypt({ name: "AES-CBC", iv: vector }, key, convertStringToArrayBufferView(data)).then(
            function (result) {
                let encrypted_data = new Uint8Array(result);
                let abc = arrayBufferToBase64(encrypted_data)
                cb(abc)
            },
            function (e) {
                alert("Failed to encrypt key", e.message)

                console.log(e.message);
            }
        );
    }
}

var decrypted_data = null;

export function decrypt_data(dat, cb) {
    if (!window.crypto) {
        alert("Crypto engine not found, please try different browser")
        return
    }
    let encrypted_data = base64ToArrayBuffer(dat)
    if (!key) {
        genMyKey(done)
    } else {
        done()
    }
    function done() {
        window.crypto.subtle.decrypt({ name: "AES-CBC", iv: vector }, key, encrypted_data).then(
            function (result) {
                decrypted_data = new Uint8Array(result);
                let str = convertArrayBufferViewtoString(decrypted_data)
                if (cb) cb(str)
            },
            function (e) {
                alert("Failed to decrypt key", e.message)

                console.log(e.message);
            }
        );
    }
}