import React from 'react'
import "./styles/kiddieFrame.css"
import { useSelector } from 'react-redux'
import * as mylocalStorage from "./mylocalStorage"

export default function KiddieFrame(props) {
    function mkColor(color, opacity) {
        if (!opacity) return color;
        if (!color.includes("#")) return color;
        const onlyColor = color.substr(0, 7);
        const alpha = (Math.floor(255 * opacity / 100) + 0x100).toString(16).substr(-2).toUpperCase()
        return onlyColor + alpha;
    }

    const boardConfig = useSelector((state) => state.BoardConfig);
    const background = useSelector((state) => state.background);
    var col = background.color ? background.color : "white"
    var trans = null;
    var bimage = null 
    var vv =  mylocalStorage.getItem("backgroundFrame")
    if (vv) {
         trans = mkColor(props.inkColor, 40)
         bimage = "url("+vv +")";
    }
  if (bimage) {
        return (
            <>
            <div className="kfTop" style={{backgroundImage: bimage, backgroundColor: trans}} />
            <div className="kfLeft" style={{backgroundImage: bimage, backgroundColor: trans}} />
            <div className="kfRight" style={{backgroundImage: bimage, backgroundColor: trans}} />
            <div className="kfBottom" style={{backgroundImage: bimage, backgroundColor: trans}} />
            </>

        )
    } else {
        return (
            <div className="kiddieFrameOuter" style={{ border: "3px solid ", zIndex: '1600', borderColor: (props.inkColor ? props.inkColor : "#4373ED") }}>
            </div>
        )
    }
}