export const configs = [
    {
        env: 'development',
        CommunityEmailId: 'feedback@whiteboard.chat'
    },
    {
        env: 'production',
        CommunityEmailId: 'feedback@whiteboard.chat'
    } 
]

export const webinarConfigs = { webinarPacific: "https://forms.gle/UqXJPKRRt9P59Zjo8" }


