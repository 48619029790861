// https://www.cockroachlabs.com/docs/stable/enable-node-map.html
export const cloudLocations = {
    'aws': {
        'us-east-1': [37.478397, -76.453077],
        'us-east-2': [40.417287, -76.453077],
        'us-west-1': [38.837522, -120.895824],
        'us-west-2': [43.804133, -120.554201],
        'ca-central-1': [56.130366, -106.346771],
        'eu-central-1': [50.110922, 8.682127],
        'eu-west-1': [53.142367, -7.692054],
        'eu-west-2': [51.507351, -0.127758],
        'eu-west-3': [48.856614, 2.352222],
        'ap-northeast-1': [35.689487, 139.691706],
        'ap-northeast-2': [37.566535, 126.977969],
        'ap-northeast-3': [34.693738, 135.502165],
        'ap-southeast-1': [1.352083, 103.819836],
        'ap-southeast-2': [-33.86882, 151.209296],
        'ap-south-1': [19.075984, 72.877656],
        'sa-east-1': [-23.55052, -46.633309]
    },
    'azure': {
        'eastasia': [22.267, 114.188],
        'southeastasia': [1.283, 103.833],
        'centralus': [41.5908, -93.6208],
        'eastus': [37.3719, -79.8164],
        'eastus2': [36.6681, -78.3889],
        'westus': [37.783, -122.417],
        'westus2': [47.233, -119.852],
        'westcentralus': [40.890, -110.234],
        'northcentralus': [41.8819, -87.6278],
        'southcentralus': [29.4167, -98.5],
        'northeurope': [53.3478, -6.2597],
        'westeurope': [52.3667, 4.9],
        'japanwest': [34.6939, 135.5022],
        'japaneast': [35.68, 139.77],
        'brazilsouth': [-23.55, -46.633],
        'australiaeast': [-33.86, 151.2094],
        'australiasoutheast': [-37.8136, 144.9631],
        'southindia': [12.9822, 80.1636],
        'centralindia': [18.5822, 73.9197],
        'westindia': [19.088, 72.868],
        'canadacentral': [43.653, -79.383],
        'canadaeast': [46.817, -71.217],
        'uksouth': [50.941, -0.799],
        'ukwest': [53.427, -3.084],
        'koreacentral': [37.5665, 126.9780],
        'koreasouth': [35.1796, 129.0756],
        'francecentral': [46.3772, 2.3730],
        'francesouth': [43.8345, 2.1972],
    },
    'gcp': {
        'us-east1': [33.836082, -81.163727],
        'us-east4': [37.478397, -76.453077],
        'us-central1': [42.032974, -93.581543],
        'us-west1': [43.804133, -120.554201],
        'us-west2': [34.052235, -118.243683],
        'us-west3': [40.758701, -111.876183],
        'us-west4': [36.114647, -115.172813],
        'northamerica-northeast1': [56.130366, -106.346771],
        'europe-west1': [50.44816, 3.81886],
        'europe-west2': [51.507351, -0.127758],
        'europe-west3': [50.110922, 8.682127],
        'europe-west4': [53.4386, 6.8355],
        'europe-west6': [47.3769, 8.5417],
        'asia-east1': [24.0717, 120.5624],
        'asia-northeast1': [35.689487, 139.691706],
        'asia-southeast1': [1.352083, 103.819836],
        'australia-southeast1': [-33.86882, 151.209296],
        'asia-south1': [19.075984, 72.877656],
        'southamerica-east1': [-23.55052, -46.633309],
    }
};