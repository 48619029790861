import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default function WebcamDialog(props) {
    const [pho, setPhoto] = React.useState(null)
    const [streamV, setStreamV] = React.useState({ stream: null, video: null })

    function stopBothVideoAndAudio(stream) {
        if (stream.video) {
            stream.video.pause()
            stream.video.srcObject = null 
            stream.video.src = "" 
        }
        if (stream.stream) {
            stream.stream.getTracks().forEach(function (track) {
                if (track.readyState === 'live') {
                    track.stop();
                }
            });
            //stream.stream.stop()
        }
        if (stream.video) {
            stream.video.srcObject = null 
        }
        setStreamV({ stream: null, video: null })
        //stream.stop()
    }
    function Cancel() {
        stopBothVideoAndAudio(streamV)
        if (props.cb) props.cb(null)
    }
    function HandleSave() {
        stopBothVideoAndAudio(streamV)
        if (props.cb) props.cb(pho)
    }
    const vref = React.useRef(null)
    const cref = React.useRef(null)
    const photoRef = React.useRef(null)
    function useHookWithRefCallback() {
        const setRef = React.useCallback(node => {
            if (vref.current) {
                // Make sure to cleanup any events/references added to the last instance
            }

            if (node) {
                // Check if a node is actually passed. Otherwise node would be null.
                // You can now do what you need to, addEventListeners, measure, etc.
            }

            // Save a reference to the node
            vref.current = node
            startup()
        }, [])

        return [setRef]
    }
    const [ref] = useHookWithRefCallback()

    var width = 620; // We will scale the photo width to this
    var height = 0; // This will be computed based on the input stream

    var streaming = false;

    var video = null;
    var canvas = null;
    var photo = null;
    var startbutton = null;
    function startup() {
        video = document.getElementById('video');
        canvas = document.getElementById('canvasPhoto');
        photo = document.getElementById('photo');
        startbutton = document.getElementById('startbutton');
        if (!video) return
        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false
        })
            .then(function (stream) {
                video.srcObject = stream;
                setStreamV({ stream: stream, video: video })
                video.play();
            })
            .catch(function (err) {
                console.log("An error occurred: " + err);
            });

        video.addEventListener('canplay', function (ev) {
            if (!streaming) {
                height = video.videoHeight / (video.videoWidth / width);

                if (isNaN(height)) {
                    height = width / (4 / 3);
                }

                video.setAttribute('width', width);
                video.setAttribute('height', height);
                canvas.setAttribute('width', width);
                canvas.setAttribute('height', height);
                streaming = true;
            }
        }, false);

        startbutton.addEventListener('click', function (ev) {
            takepicture();
            ev.preventDefault();
        }, false);

        clearphoto();
    }


    function clearphoto() {
        var context = canvas.getContext('2d');
        context.fillStyle = "#AAA";
        context.fillRect(0, 0, canvas.width, canvas.height);

        var data = canvas.toDataURL('image/jpg');
        photo.setAttribute('src', data);
    }

    function takepicture() {
        var context = canvas.getContext('2d');
        if (width && height) {
            canvas.width = width;
            canvas.height = height;
            context.drawImage(video, 0, 0, width, height);

            var data = canvas.toDataURL('image/png');
            photo.setAttribute('src', data);
            var data = canvas.toBlob(cb, 'image/jpeg');
            function cb(d) {
                setPhoto(d)
            }
        } else {
            clearphoto();
        }
    }
    return (
        <Dialog open={props.open} onClose={Cancel} aria-labelledby="form-dialog-title"
            maxWidth="lg" fullWidth>
            <DialogTitle id="form-dialog-title" className= "mainHeader">Take a photo of your work</DialogTitle>
            <DialogContent>
                <div class="contentarea">
                    <Grid container>
                        <Grid item xs={6}>
                            <div class="camera" >
                                <video ref={ref} id="video">Video stream not available.</video>
                            </div>
                            <div><button id="startbutton" className="takePhotoButton" style={{ backgroundColor: props.inkColor }}>Take photo</button></div>
                        </Grid>
                        <Grid item xs={6}>
                            <div class="output">
                                <img id="photo" ref={photoRef} alt="The screen capture will appear in this box." />
                            </div>
                        </Grid>
                    </Grid>
                    <canvas ref={cref} id="canvasPhoto"></canvas>

                </div>
            </DialogContent>
            <DialogActions>
                <div className="positioning">
                    <Typography
                        variant="contained"
                        size="small"
                        style={{ marginRight: "24px", fontSize: "16px", }}
                        onClick={Cancel}
                        className='formCancel'
                    >
                        Cancel
                    </Typography>
                    <Button onClick={HandleSave} className="createButton" style={{ backgroundColor: props.inkColor }} color="primary">
                        Save
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
