import React, { useEffect } from "react"
import "../styles/accessinclusion.scss"
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Hidden, Popover
} from "@material-ui/core"
import { hexToRGB } from "../BoardUtility";
import Header from './Header.js'
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
    },
    rightSide: {
        marginLeft: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        align: 'center',
    },
    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        color: '#5D5770',
        zIndex: 99,
    },
    tooltip: {
        margin: "2px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#3174F5",
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    },
    customArrow: {
        color: "#3174F5",
    },
}));


function AccessInclusion(props) {
    const classes = useStyles();
    const [startNewBoard, setStartNewBoard] = React.useState(false);
    const [url, setUrl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [offY, setOffY] = React.useState(false);
    const [bgColor, setBgColor] = React.useState('#ebfcff');

    useEffect(()=>{
        if (!open){
            document.body.style.overflow = 'unset'
            window.scrollTo(0,offY)
        }else{
            document.body.style.overflow='hidden'
        } 
    },[open])

    function openInNewTab(aUrl) {
         window.open(aUrl)
    }

    function openVideo(vUrl, offSetY, color) {
        setUrl(vUrl)
        setOffY(offSetY)
        setOpen(true)
        setBgColor(hexToRGB(color,0.2))
    }

   
    
    return(
        <div className={classes.root}>
            <Header newBoard={startNewBoard}/>
            {/* <div className="topbg">
                <div className="splashtext">
                    <img src="/a11y_splash_text.svg"></img>
                </div>
                <div className="splashcaption">
                    At whiteboard.chat, we strive to make educational resources accessible to<br/>
                    all learners, regardless of where they are and who the are.
                </div>
            </div> */}
            {/* <img src="/splash.svg" style={{ background: "linear-gradient(255.25deg, #DEF7FE 0%, #EDFCFF 97.86%)", border: "1px solid red", objectFit: 'contain', objectPosition: '0% 0%' }} ></img> */}
            {/* <Hidden only={['sm', 'xs', 'md']}>
            <img src="/splash_text.svg" style={{ height: '100vmax', objectFit: 'contain', objectPosition: '0% 0%' }} ></img>
            </Hidden>
            <Hidden only={['sm', 'xs', 'lg', 'xl']}>
            <img src="/splash_text.svg" style={{ height: '60vmax', objectFit: 'contain', objectPosition: '0% 0%' }} ></img>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
            <img src="/splash_text.svg" style={{ width: '100vw', objectFit: 'contain', objectPosition: '0% 0%' }} ></img>
            </Hidden> */}
            <img src="/splash_text.svg" style={{ width: '100vw', objectFit: 'contain', objectPosition: '0% 0%' }} alt='Accessible & Inclusive Learning at Whiteboard.Chat At whiteboard.chat, we strive to make educational resources accessible to all learners,
regardless of where they are, and who they are. We are an online interactive digital notebook tool that brings together educators and learners from across the globe. Check out resources available at whiteboard.chat for accessible and inclusive learning below.' ></img>
            <div className="subsectiontitle">
                Read aloud and Language Translation
            </div>
            <Grid container direction="row" justify="center">
                <Grid item>
                    <img className="featurecard" src="/immreader_card.svg" style={{ maxWidth: '50vw' }} onClick={(e) => { openVideo("https://www.youtube.com/embed/0XTiqVHRzCE?", window.pageYOffset, '#786cd9') }} alt='Integration with Microsoft’s Immersive Reader. Reading aloud any object placed on the board, including entire PDF documents, with
ability to slow down, speed-up, as well as translation into hundreds of languages.
'></img>
                </Grid>
                <Grid item>
                    <img className="featurecard" src="/speech2text_card.svg" style={{ maxWidth: '50vw' }} onClick={(e) => { openVideo("https://www.youtube.com/embed/mRhrpo3v46k?start=838;", window.pageYOffset, '#f5b453') }} alt='Speech to Text Transcription and Translation. Allow an educator to transcribe speech in a language that the learner understands and
make the transcription part of the digital notebook.'></img>
                </Grid>
                <Grid item>
                    <img className="featurecard" src="/chatxlate_card.svg" style={{ maxWidth: '50vw' }} onClick={(e) => { openVideo("https://www.youtube.com/embed/rO46jWf9mao?start=2234", window.pageYOffset, '#4aced7') }} alt='Chat Language Translation. Converse with multiple learners in languages that they understand simultaneously,
making education inclusive for all.'></img>
                </Grid>
                <Grid item>
                    <img className="featurecard" src="/audio_card.svg" style={{ maxWidth: '50vw'}} onClick={(e) => { openInNewTab("https://medium.com/epiphani/how-to-record-and-place-audio-instructions-on-the-board-9da6cfe30c00")}} alt='Audio Instructions. Record instructions and place audio on the board so learners can understand the assignment work at their own pace.'></img>
                </Grid>
            </Grid>
            <div className="startteachingpanel">
                <div className="startteachingpaneltext">
                Get started with Whiteboard.chat!
                </div>
                {/* <div className="panelbutton" onClick={(e) => { setStartNewBoard(true) }}>Start Teaching</div> */}
            </div>

            <Grid container direction="row" justify="center" className='wpContainer'>
                <Hidden xsDown>
                    <Grid item xs={12} sm={6} md={6} lg={6} style={{ zIndex: '1' }}>
                        <img className="featurecard wpLeft" src="/writingleft.svg" style={{ maxWidth: '50vw' }}></img>
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={6} md={6} lg={6} className='wpSection'>
                    <img className="featurecard" src="/wp1.svg" style={{ maxWidth: '50vw' }} onClick={(e) => { openVideo('https://www.youtube.com/embed/-wvkt_VFbo0?start=2232', window.pageYOffset, '#50cdf7') }} alt='Writing Practice - Grids for Writing. Gives opportunity to a learner to practice writing skills directly on the whiteboard using handwriting, college lined, and primary lined background grids with ability for text alignment.'></img>
                    <img className="featurecard" src="/wp2.svg" style={{ maxWidth: '50vw', margin: '20px auto' }} onClick={(e) => { openVideo('https://www.youtube.com/embed/bGsTsoKlcGU', window.pageYOffset, '#50cdf7') }} alt='Writing Practice - Handwriting Recognition. Allows learners to convert their writing into digitized texts in multiple languages.'></img>
                    <img className="featurecard dmw2" src="/wp3.svg" style={{ maxWidth: '50vw', margin: '20px auto' }} onClick={(e) => { openVideo("https://www.youtube.com/embed/rO46jWf9mao?start=1829", window.pageYOffset, '#50cdf7') }} alt='Writing Practice - Upload Hand-written Work. A learner working with pen and paper can upload their work using a webcam, making iteasy to collaborate with learners who are working online, across different locations.'></img>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center" className='dmwContainer'>
                <Grid item xs={12} sm={6} md={6} lg={6} className='dmw'>
                    <img className="featurecard" src="/dmwtitle.svg" style={{ margin: '89px auto 40px auto' }}></img>
                    <img className="featurecard" src="/dmw1.svg" style={{ maxWidth: '50vw', margin: '0 auto' }} onClick={(e) => { openInNewTab('https://www.youtube.com/playlist?list=PL8ySElh8rLyEsEUPmUrAzbDQBDL4K4wuf') }} alt='Digitized Manipulatives and Widgets - Digitized Manipulatives for Math, Languages and Sciences. Whiteboard.chat has hundreds of digitized visual aids for math, languages, sign language, biology, chemistry, electronics, robots and music symbols, making educational resources truly accessible and inclusive for all.'></img>
                    <img className="featurecard dmw2" src="/dmw2.svg" style={{ maxWidth: '50vw', margin: '50px auto' }} onClick={(e) => { openVideo("https://www.youtube.com/embed/rO46jWf9mao?start=2519", window.pageYOffset, '#f5b453') }} alt='Digitized Manipulatives and Widgets - Configurable Active Widgets. Our many configurable active widgets make learning fun, interactive, collaborative and engaging, irrespective of whether a learner is in a physical or virtual classroom.'></img>
                </Grid>
                <Hidden xsDown>
                    <Grid item xs={12} sm={6} md={6} lg={6} style={{ zIndex: '1' }}>
                        <img className="featurecard" src="/dmw.svg" style={{ maxWidth: '50vw' }}></img>
                    </Grid>
                </Hidden>
            </Grid>
            <img src="/self_paced.svg" className="sectionpanel" style={{ marginTop: '-6vh' }} onClick={(e) => {openInNewTab("https://medium.com/epiphani/live-and-asynchronous-teaching-using-whiteboard-chat-183f244a899f")}} alt='Ability for Self-paced Learning. In order to support diversity of learners, whiteboard.chat provides learners with the ability to let
the teacher know if they are able to keep-up, or should the teacher slow-down. Additionally, a student has the ability to download their work as PDF to work on it with pen and paper, take a pic of finished work and upload it on the whiteboard. We support asynchronous self-paced learning. A student can go back to their digital book, continue to work on their assignments, even when the teacher has ended the class, and can signal the teacher that they are finished.'></img>
            <img src="/ms_partner.svg" className="sectionpanel" style={{ cursor: 'default' }} alt='Integration with Microsoft Education Tools. With our integration with Teams, whiteboard.chat can be directly launched from Team’s Personal, Chat or Channel tabs. An educator can invite students from Teams, giving unparalleled ease of access to features of both Teams and Whiteboard.Chat. Microsoft’s Immersive Reader enables learners at whiteboard.chat to read aloud any object, including entire PDF documents at a pace of their choice. The educators even have the ability to edit the read aloud text associated with objects and PDF documents. OneNote integration allows students to join whiteboard.chat class directly from OneNote, and benefit from superior note taking, drawings, screen clippings, commentaries features of OneNote along with interactive active widgets based
learning tools of whiteboard.chat.'></img>
            <img src="/testimonials.svg" className="sectionpanel" style={{ cursor: 'default', marginTop: '2vh', width: '80vw' }} alt='Testimonial by PhD. ATP - Hillary Goldthwait-Fowles. I have been impressed by Whiteboard Chat’s responsiveness to accessibility needs. Whiteboard.Chat is committed to ensuring that its product is accessible from the start.  They have included individuals in the accessibility field in their testing as well as look for individuals with disabilities who are using assistive technology to test their product. For example, when they integrated Immersive Reader, I was having an issue with a file that wouldn’t convert. Not only did they respond, but they also IMPROVED Immersive Reader by allowing for editable text - which is a feature that is very important to some of my users! While accessibility continues to evolve and grow, I am confident that the folks at Whiteboard.Chat will continue to follow best practices and trends in ensuring that EVERY learner has meaningful access to their product. Testimonial by Occupational Therapist, Assistive Technology Specialist - Elisa Wern. I love the ways and tools available within whiteboard.chat to allow for multiple means of presenting material to students as well as multiple means for students of all abilities to respond. Some of the specialized tools for doing social-emotional check-in or doing intervention for skills are very much flexible and allow for all students to participate.
Testimonial by Orange County District Middle School Teacher of the Year - Lindsey Foster. Whiteboard.chat Is a game changing tool aimed at providing equitable access to content material in all methods of instruction. The ability to see realtime student work and provide immediate feedback all in one place allows teachers to differentiate instruction to meet the needs of all students.'></img>
            <br />
                <Footer setStartNewBoard={setStartNewBoard}/>
            <Popover
                open={open}
                anchorEl={props.fontEl}
                onClose={(e) => {
                    setOpen(false)
                    setUrl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className='vidPopper'
                style={{ backgroundColor: bgColor }}
            >
                <div className='popupHeader'  style={{ width:'50vw',height:'60vh' }}>
                    <iframe
                        className='{classes.windowIframeOutput}'
                        src={url}
                        width='100%'
                        height='100%'
                        allow="camera;microphone"
                    />
                </div>

            </Popover>
        </div>
    )
}

export default AccessInclusion;