import React from 'react';
import "../home.css"
import "./homeOver.css"
import ReactGA from 'react-ga';
import {
    Hidden,
    AppBar,
    Toolbar,
    MenuItem,
    MenuList,
    Popper,
    Grow,
    IconButton,
    Paper,
    ClickAwayListener
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import * as ib from '../ibdata'
import { webinarConfigs } from '../Config';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { SocialIcon } from 'react-social-icons';
import { useSelector, useDispatch } from 'react-redux';
import * as Actions from "../store/actions"
import { v4 as uuid } from "uuid";
import * as mylocalStorage from "../mylocalStorage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        // backgroundColor: '#FFFFFF',
        backgroundColor: '#3174F500',
        backgroundImage: "url"
    },
    rightSide: {
        marginLeft: 'auto',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        align: 'center',
    },

    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        color: '#5D5770',
        zIndex: 110,
        marginTop: '20px'
    },
    tooltip: {
        display: 'flex',
        alignItems: 'center'
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#3174F5",
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    },
    customArrow: {
        color: "#3174F5",
    },
}))

export default function Teach(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const webinarUSDate = "July 15"
    const [localTimePac, setLocalTimePac] = React.useState(null)
    const [localTimeEast, setLocalTimeEast] = React.useState(null)
    const [openMenu, setOpenMenu] = React.useState(false);
    const anchorRef = React.useRef(null);
    const webinarDateStrEast = "July 15 2021 21:00:00 UTC"
    const webinarDateStrPacific = "July 15 2021 23:00:00 UTC"
    const handleToggle = () => {
        setOpenMenu((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        setOpenMenu(false);
    };

    React.useEffect(() => {
        ReactGA.pageview('/teach')
    })

    React.useEffect(() => {
        var webDate;
        webDate = new Date(webinarDateStrPacific)
        var mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(webDate)
        var day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(webDate)
        var hr = new Intl.DateTimeFormat('en', { hour: "numeric", minute: "2-digit", dayPeriod: "short" }).format(webDate)
        setLocalTimePac(mo + " " + day + " " + hr + " " + Intl.DateTimeFormat().resolvedOptions().timeZone.replace("_", " "))

        webDate = new Date(webinarDateStrEast)
        mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(webDate)
        day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(webDate)
        hr = new Intl.DateTimeFormat('en', { hour: "numeric", minute: "2-digit", dayPeriod: "short" }).format(webDate)
        setLocalTimeEast(mo + " " + day + " " + hr + " " + Intl.DateTimeFormat().resolvedOptions().timeZone.replace("_", " "))
    }, [])
    
    React.useEffect(() => {
        if (props && props.newBoard) {
            newBoard()
        }
    }, [props]);

    const redirectTo = (path) => {
        setOpenMenu(false);
        window.location.href = path
    };

    function showStoragePlans() {
        if (window.insideMSTeams === true)
            return false
        return true
    }

    function webinarPacific(e) {
        window.open(webinarConfigs.webinarPacific)
    }

    function openInNewTab(url) {
        window.open(url)
    }

    function newBoard(e) {
        const id = uuid()
        var luid = mylocalStorage.getItem('mystoreID');
    
        const pg1 = id + "-pgNum-1"
        var boardConfig = '{"multiBoardOption": true}'
        var j = { "multiBoardOption": true }
        if (user && user.boardConfig) {
            j = JSON.parse(user.boardConfig)
            j.multiBoardOption = true 
            boardConfig = JSON.stringify(j)
        }
        ib.createClassroom({ id: id, TeacherID: luid, name: "myclass" })
        dispatch(Actions.setBoardConfig(j))
        ib.createSession(pg1, "unsaved", "somecontent", id, 1, null, boardConfig, id).then((res) => {
            const redir = "/board/" + pg1
            ReactGA.pageview('/createboard')
            window.location.href = redir;
        })
    }

    return (
        <div className={classes.root}>
         <AppBar
                className={clsx(classes.appBar, props.header)}
                style={{position: 'absolute', top: '0'}}
            >
                <Toolbar color="transparent" className='headerToolBar'>
                    <div className="headWBChat" >
                        <div className="headWBChat" >
                            <img onClick={() => redirectTo('/')} src='/logo_title.svg' alt='whiteboard Logo' className='cursor-pointer newlogoImg' />
                            <SocialIcon network="facebook" onClick={(e) => { openInNewTab("https://facebook.com/whiteboard.chat") }} style={{ height: 25, width: 25, marginLeft: 10, opacity: 0.8 }} className='cursor-pointer' />
                            <SocialIcon network="instagram" onClick={(e) => { openInNewTab("https://instagram.com/whiteboard.chat") }} style={{ height: 25, width: 25, marginLeft: 10, opacity: 0.8 }} className='cursor-pointer' />
                            <SocialIcon network="twitter" onClick={(e) => { openInNewTab("https://twitter.com/WhiteboardChat") }} style={{ height: 25, width: 25, marginLeft: 10, opacity: 0.8 }} className='cursor-pointer' />
                        </div>
                        <Hidden mdUp>
                        <IconButton
                            ref={anchorRef}
                            aria-controls={openMenu ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                                style={{ position: 'absolute', right: 0 }}
                        >
                            {openMenu ? <MenuOpenIcon fontSize='medium'/> : <MenuIcon />}
                        </IconButton>
                        </Hidden>

                    </div>
                    <div className={classes.rightSide}>
                        <div className='flex rightSideHeader'>
                            {/* <div className="hbHow">
                                    { showFlag() ? <Flag /> : null }
                                </div> */}
                            <Hidden only={['xs', 'sm']}>
                                <a href="/inclusion" className="flex flex-align-center labelsHeader textDecorNone m-10">
                                    Inclusion
                                </a>
                                <a href="/testimonials" className="flex flex-align-center labelsHeader textDecorNone m-10">
                                    Testimonials
                                </a>
                                {showStoragePlans() ? <>
                                    <a href="/subs" className="flex flex-align-center labelsHeader textDecorNone m-10">
                                        Storage Plans
                                    </a>
                                </> : null}
                                <div className="webinarPill m-10">
                                    <Tooltip className={classes.tooltip}
                                        classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }}
                                        title={
                                            <React.Fragment>
                                                {"Your Local Time:"}<br />
                                                {localTimePac}
                                            </React.Fragment>
                                        } arrow placement="bottom">
                                        <div className='liveWebinarTitle' onClick={webinarPacific}
                                        >
                                            <img src='/Live.svg' alt='whiteboard Logo' />
                                            Free Webinar-{webinarUSDate}</div>
                                    </Tooltip>
                                </div>
                            </Hidden>
                            <Hidden only={['xs', 'sm']}>
                                <a href="/help" className="flex flex-align-center labelsHeader textDecorNone m-10">
                                    <PlayArrowIcon />
                                    How it Works
                                </a>
                            </Hidden>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>

            <div>

                <Popper open={openMenu} anchorEl={anchorRef.current} role={undefined} placement='bottom-end' transition disablePortal style={{ zIndex: '999' }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'right-start' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList className='popMenuLi' autoFocusItem={openMenu} id="menu-list-grow">
                                        <MenuItem onClick={(e)=>{redirectTo('/inclusion')}}>Inclusion</MenuItem>
                                        <MenuItem onClick={(e)=>{redirectTo('/testimonials')}}>Testimonials</MenuItem>
                                        {showStoragePlans() && <MenuItem onClick={(e) => { redirectTo('/subs') }}>Storage Plans</MenuItem>}
                                        <MenuItem onClick={(e)=>{webinarPacific()}}>Free Webinar-{webinarUSDate}</MenuItem>
                                        <MenuItem onClick={(e)=>{redirectTo('/help')}}>How it Works</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>

    )
}