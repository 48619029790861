import React from 'react';
import { useSelector } from 'react-redux'
import Draggable from 'react-draggable';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import {
    TextField,
    IconButton,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Switch
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({

    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        width: "200px !important",
        height: "164px",
        zIndex: 105,
        position: "absolute",
        color: "#ffffff",
        backgroundColor: "#ffffff"

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        paddingBottom: "5px",
        top: 30,
        left: 15,
        backgroundColor: "#ffffff"
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1",
        margin: "10px",
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    }, 
    bb:{
        margin: '10px 26px 0px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    sublbl:{
        fontfamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '100%',
        color: '#2F3D5B',
        textAlign: 'left'
    },
    tlml:{
        textAlign: 'left',
        margin: '20px 25px 10px'
    },
}))
const MAXPAGES = 99

export default function GridBrowser(props) {
    const classes = useStyles();

    const gridBrowser = useSelector((state) => state.gridBrowser);
    const xpos = 118
    const ypos = window.innerHeight - 480
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: ypos });
    const dispatch = useDispatch();
    const [pgNum, setpgNum] = React.useState(gridBrowser.pageNum);
    const boardConfig = useSelector((state) => state.BoardConfig);

    React.useEffect(() => {

    }, [gridBrowser])


    const handleChange = (event) => {
        const name = event.target.name

        if (gridBrowser && gridBrowser[name] !== event.target.checked) {
            dispatch(Actions.setGridBrowser({
                ...gridBrowser,
                [name]: event.target.checked,
            }))
        }
    };

    function setPageIfAllowed(pnum) {

        if (pnum >= 1 && pnum <= MAXPAGES) {
            setpgNum(pnum)
            dispatch(Actions.setGridBrowser({
                ...gridBrowser,
                pageNum: pnum,
            }))
        }
    }
    function upPage() {
        setPageIfAllowed(pgNum + 1)
    }
    function downPage() {
        if (pgNum === 1) return
        setPageIfAllowed(pgNum - 1)
    }

    function handleDrag(e, ui) {
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        dispatch(Actions.setGridBrowser({
            open: false, pageNum: 1, realTime: true, groupGridOnly: false 
        }))
    }
        return (
            <> 
                {gridBrowser.open && (
                    <>
                        <Grid container alignItems="center" className='dividerBorder'>
                            <Grid item xs={12} className={classes.tlml}>
                                <span className={classes.sublbl} style={{ fontWeight: 'bold' }}>Boards View</span>
                            </Grid>

                            <Grid item xs={12} className={clsx(classes.bb, 'customSwtBtns')}>
                                <span className={classes.sublbl}>Real time view</span>
                                <FormControlLabel
                                    control={<Switch size="small" checked={gridBrowser.realTime} onChange={handleChange} name="realTime" />}
                                />
                            </Grid>
                            <Grid item xs={12} className={clsx(classes.bb, 'customSwtBtns')}>
                                <span className={classes.sublbl}>Group Boards only</span>
                                <FormControlLabel
                                    control={<Switch size="small" checked={gridBrowser.groupGridOnly} onChange={handleChange} name="groupGridOnly" />}
                                />
                            </Grid>
                        </Grid>
                        {!gridBrowser.realTime && (
                            <Grid container alignItems="center" className='justify-center'>
                                <div className='flex-a-c'>
                                    <IconButton className="" onClick={downPage}>
                                        <NavigateBeforeIcon fontSize='large' style={{ color: props.inkColor }} />
                                    </IconButton>
                                    <TextField
                                        id="name"
                                        type="text"
                                        className="pageNum"
                                        value={pgNum}
                                        onChange={(event) => setPageIfAllowed(event.target.value)}
                                        InputProps={{ disableUnderline: true }}
                                        inputProps={{ size: (pgNum < 10 ? '1' : '2'), style: { textAlign: 'center', fontWeight: 'bold', fontSize: '0.65rem', color: props.inkColor } }}
                                    />
                                    <IconButton className="" onClick={upPage}>
                                        <NavigateNextIcon fontSize='large' style={{ color: props.inkColor }} />
                                    </IconButton>
                                </div>
                            </Grid>
                        )}
                    </>

                )}
            </>
        );
  
}