import React from 'react';
// import "./home.css"
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import * as mylocalStorage from "./mylocalStorage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        backgroundColor: "#3174F5",
        fontSize: "3rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        color: "#ffffff",
        height: "100%",
        justifyContent: "center",
        alignItems: "center", 
    },
}))
export default function ResetStorage(props) {
    const classes = useStyles();

    React.useEffect(() => {
        mylocalStorage.clear();
        ReactGA.event({
            category: 'ResetStorage',
            action: 'ResetStorage'
        });    
    })

    return (
        <div className={classes.root}>
            Reset Done
        </div>
    )
}