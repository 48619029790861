import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
    DialogTitle,
    RadioGroup,
    Radio,
    FormLabel,
    FormControlLabel
} from '@material-ui/core';
import { useSelector } from 'react-redux'
import { InputBase, Typography} from '@material-ui/core';

export default function LoopDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const teacherR = useSelector((state) => state.teacher);

    const [link, setLink] = React.useState({ value: "", type: "count" });
    const handleSave = () => {
        setOpen(false);
        if (props.cb) props.cb(link)
    };
    React.useEffect(() => {
        setOpen(props.open)
    }, [props])
    const handleClose = () => {
        setOpen(false);
        if (props.cb) props.cb(null)
    };
    function pasteText(e) {
        e.preventDefault()
        const txt = e.clipboardData.getData('text/plain')
        setLink({
            ...link,
            [e.target.id]: txt
        })
    }

    function handleType(e) {
        setLink({
            ...link,
            [e.target.id]: e.target.value
        })
    }
    // function handleSubmit(e) {
    //     console.log("HIT SUBMIT")
    //     e.preventDefault()
    //     if (link.name.length >= 0 && link.link.length >= 0) handleSave()
    // }
    const [value, setValue] = React.useState('count');

    const handleChange = (event) => {
        var v = event.target.value
        setValue(v);
        
    };
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className= "mainHeader">Add Link</DialogTitle>
            <DialogContent>
                <DialogContentText className="dialogMessage">
                    Condition for the loop: 
          </DialogContentText>
          <div className= "radioButtonStyle">
          <FormLabel component="legend" className="dialogMessage" style={{ marginBottom: "6px" }}>Loop Type:</FormLabel>
                <RadioGroup aria-label="linkType" name="linkType" value={value} onChange={handleChange}>
                    <FormControlLabel value="count" control={<Radio />} label="Count" />
              </RadioGroup>
          </div>
                    <InputBase
                        autoFocus
                        margin="dense"
                        id="value"
                        placeholder="value"
                        fullWidth
                        value={link.value}
                        onPaste={pasteText}
                        onChange={(e) => handleType(e)}
                        className= "inputname"
                    />
            </DialogContent>
            <DialogActions>
            <div className="positioning">
                    <Typography
                        variant="contained"
                        size="small"
                        style={{marginRight: "24px", fontSize: "16px",}}
                        onClick={handleClose}
                        className='formCancel'
                    >
                        Cancel
                        </Typography>
                    <Button onClick={handleSave} color="primary"
                        style={{ backgroundColor: props.inkColor }}
                        className="createButton"
                        disabled={link.value.length <= 0}>
                        Save
                    </Button>
            </div>
            </DialogActions>

        </Dialog>
    );
}
