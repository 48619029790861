import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
    DialogTitle,

} from '@material-ui/core';
import { InputBase, Typography} from '@material-ui/core';

export default function ReadAloudDialog(props) {
    const [link, setLink] = React.useState("");
    const handleSave = () => {
        if (props.cb) props.cb(link)
    };
    React.useEffect(() => {
        if (props.text) {
            setLink(props.text)
        }
    }, [props])
    const handleClose = () => {
        if (props.cb) props.cb(null)
    };
    function pasteText(e) {
        e.preventDefault()
        const txt = e.clipboardData.getData('text/plain')
        setLink(txt); 
    }

    function handleType(e) {
        var v = e.target.value
        setLink(v); 
    }

    return (
        <Dialog maxWidth="md" fullWidth open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" style={{color: "#1E2639"}}>Edit Immersive Reader Text</DialogTitle>
            <DialogContent>
                <DialogContentText className="dialogMessage">
                    Please edit text for Immersive reader
          </DialogContentText >
          <div>
          <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Text"
                        fullWidth
                        multiline
                        value={link}
                        onPaste={pasteText}
                        onChange={(e) => handleType(e)}
                        variant="outlined"
                    />
          </div>    
            

            </DialogContent>
            <DialogActions>
            <div className="positioning">
                    <Typography
                        size="small"
                        style={{ marginRight: "24px", fontSize: "16px", }}
                        onClick={handleClose}
                        className='formCancel'
                    >
                        Cancel
                    </Typography>
                    <Button onClick={handleSave} color="primary" disabled={link.length <= 0} className="createButton" style={{ backgroundColor: props.inkColor }}>
                        Save
                    </Button>
          </div>
            </DialogActions>

        </Dialog>
    );
}
