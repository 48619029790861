import * as ib from './ibdata'
import React from 'react';
import "./home.css"
import ReactGA from 'react-ga';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {
    Typography,
    Button,
    Hidden,
    Grid
} from '@material-ui/core';
import { v4 as uuid } from "uuid";

export default function Home(props) {
    React.useEffect(() => {
        ReactGA.pageview('/home')
    })
    function newBoard(e) {
        const id = uuid()
        const pg1 = id + "-pgNum-1"
        ib.createSession(pg1, "unsaved", "somecontent", id, 1, null, null).then((res) => {
            const redir = "/board/" + pg1
            props.history.push(redir);
            ReactGA.pageview('/createboard')
        })
    }
    return (
        <div className="scroll">
            <Grid container className='h-full'>
                <Grid item xs={12} sm={1} md={1} lg={1} className='relative'>
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} className='relative mr-top ml-5'>
                    <Typography className="title mb-10">Whiteboard.chat</Typography>
                    <Typography className="drawShareColab">Draw. Share.</Typography>
                    <Typography className="drawShareColab mb-30">Collaborate.</Typography>
                    <Typography className="normalText">Connect multiple people and devices to the same board.</Typography>
                    <Typography className="normalText mb-30">Draw with your finger, mouse, pencil. Click the button below to start.</Typography>

                    <Button
                        onClick={newBoard}
                        className="newEpicBoard colab-btn mb-10"
                    >
                        Start Drawing
                         </Button>
                         <Hidden mdUp>
                    <div className='mt-10 disp-inline-block'>

                        <div className="helpButton btn-content justify-content">
                            <a href="https://youtu.be/BZjgfTuD-I4">Basic Help</a>
                        </div>
                        <div className="helpButton2 btn-content">
                            <NewReleasesIcon style={{ color: "#7f0055", marginRight: '5px' }} />
                            <a href="https://youtu.be/CcDRekFivkY">Multi-User Help</a>
                        </div>
                    </div>
                    <br></br>
                    <div className="flex mt-5perc">
                        <a className="link" href="mailto: feedback@whiteboard.chat"> feedback@whiteboard.chat</a>
                    </div>
                    </Hidden>
                    <Hidden smDown>
                    <br></br>
                    <div className="flex copyrt">
                        <a className="link" href="mailto: feedback@whiteboard.chat"> feedback@whiteboard.chat</a>
                    </div>
                    </Hidden>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} sm={4} md={4} lg={3} className='relative'>

                        <div className='mt-10 disp-inline-block help-btns'>

                            <div className="helpButton btn-content justify-content">
                                <a href="https://youtu.be/BZjgfTuD-I4">Basic Help</a>
                            </div>
                            <div className="helpButton2 btn-content">
                                <NewReleasesIcon style={{ color: "#7f0055", marginRight: '5px' }} />
                                <a href="https://youtu.be/CcDRekFivkY">Multi-User Help</a>
                            </div>
                        </div>
                        <img src="penDraw.svg" alt="epiDraw" className='pendraw' />
                    </Grid>
                </Hidden>

            </Grid>

        </div>

    )
}