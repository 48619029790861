import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import {
    List,
    ListItem,
    ListItemText,
    IconButton,
    ListItemIcon,
    MenuItem,
    Menu,
    Tooltip,
    Typography
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as ib from "./ibdata"
import PanToolIcon from '@material-ui/icons/PanTool';
import ReactGA from 'react-ga';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import HelpIcon from '@material-ui/icons/Help';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as mylocalStorage from "./mylocalStorage"
import WcIcon from '@material-ui/icons/Wc';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';
import { svgIcons } from './svgIcons';
import Draggable from 'react-draggable';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
    },
    iconButtonClear: {
        fontSize: "16px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        color: '#ffffff',
        margin: "10px",
        padding: "8px 10px",
        borderRadius: 999,
        textTransform: "capitalize",
        textAlign: "center",
        width: "160px",
        boxShadow: "0px 1px 20px rgba(13, 20, 22, 0.1), 0px 0px 8px 2px rgba(13, 20, 22, 0.02)",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
    inviteButton: {
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '5px',
        margin: "5px",
        padding: "5px",
        color: "#ffffff",
        "&:hover": {
            color: '#3174F5',
        },
    },
    paper: {
        marginTop: "10px",
        border: "1px solid #E2E6E9",
        backgroundColor: "#ffffff",
        maxHeight: "56vh",
        width: "auto",
        overflow: "auto",
        boxSizing: "border-box",
        boxShadow: "0px 1px 20px rgba(13, 20, 22, 0.1), 0px 0px 8px 2px rgba(13, 20, 22, 0.02)",
        borderRadius: "4px",
    },
    icon: {
        padding: "2px",
        minWidth: "30px",
    },
    typography: {
        padding: theme.spacing(2),
    },
    tooltip: {
        margin: "4px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
    customArrow: {
        color: "#1E2639",
    },
    dots: {
        color: "#7D8FB6",
        position: "relative",
        top: "-5px",
        "&:hover": {
            color: "#3174F5",
            cursor: "move",
        },
    },
    headerDots: {
        width:"100%",
        height:"7px",
        display: "flex",
        justifyContent: "space-around",
        backgroundColor: "#E2E6E9",
    },
}));

function HandRaise(props) {
    const [open3, setOpen] = React.useState(true);
    const classes = useStyles();
    const teacherR = useSelector((state) => state.teacher);
    const hideNotification = useSelector((state) => state.hideNotification);
    const [anchorEl, setAnchorEl] = React.useState({ el: null });
    const boardTools = useSelector((state) => state.boardTools);
    const subMenuDef = [
        { name: "Raise Hand", cb: toggleHand, icon: <HelpIcon /> },
        { name: "I'm finished", cb: toggleFinish, icon: <CheckCircleIcon /> },
        { name: "Bathroom Break", cb: toggleBathroom, icon: <WcIcon /> },
        { name: "Please Speed Up", cb: speedUp, icon: <FastForwardIcon /> },
        { name: "Please Slow Down", cb: slowDown, icon: <FastRewindIcon /> },
    ]
    const [subMenu, setSubMenu] = React.useState([...subMenuDef]);
    const boardConfig = useSelector((state) => state.BoardConfig);

    const assistanceRequest = useSelector((state) => state.assistanceRequest);
    const dispatch = useDispatch();
    const tpopperRef = React.useRef(null)
    const [windowPosition, setWindowPosition] = React.useState({ x: 0, y: 0 });
    // const handleClick = (newPlacement) => (event) => {
    //     setAnchorEl(event.currentTarget);
    //     setOpen((prev) => placement !== newPlacement || !prev);
    //     setPlacement(newPlacement);
    // };
    React.useEffect(() => {
        dispatch(Actions.setRaiseHand(false))
    }, [props.sess])
    React.useEffect(() => {
        if (boardTools && boardTools["Help Student"]) {
            let r = boardTools["Help Student"]
            var old = []

            subMenu.forEach(s => {
                if (r.sub && r.sub[s.name]) {
                    if (!r.sub[s.name].checked) {
                        old.push({...s})
                    }
                } else {
                    if (s) old.push({...s})
                }
            })
            setSubMenu(old)
        } else {
            setSubMenu([...subMenuDef]); 
        }
    }, [boardTools, props.user])

    const icons = {
        'help': <HelpIcon style={{ color: "#EF6C35" }} />,
        'finish': <CheckCircleIcon style={{ color: "#71C001" }} />,
        'bathroom': <WcIcon style={{ color: "#3174F5" }} />,
        'speedup': <FastForwardIcon style={{ color: "#71C001" }} />,
        'slowdown': <FastRewindIcon style={{ color: "#EF6C35" }} />,
    }
    function buttonClick(e) {
        setOpen(!open3)
        if (!teacherR) {
            if (assistanceRequest.studentAsked) {
                const pr = assistanceRequest.pendingList[0]
                if (pr && pr.Content && pr.Content.kind) notificationCommon(pr.Content.kind)
            } else {
                setAnchorEl({ el: e.currentTarget });
            }
        } else {
            var ff = hideNotification && (hideNotification === true)
            mylocalStorage.setItem('setNotif', !ff)
            dispatch(Actions.setNotification(!ff))
        }
    }

    function clearAll() {
        for (let i = 0; i < assistanceRequest.pendingList.length; i++) {
            var sr = assistanceRequest.pendingList[i]
            delete sr['Content']
            sr['State'] = "Done"
            ib.updateClassroomEvent(sr)
        }
    }
    function actionButton(e) {
        if (e.Content && e.Content.action === "RaiseHand") {
            ReactGA.event({
                category: "Assistance",
                action: "TeacherJoined"
            });
            var copy = JSON.parse(JSON.stringify(e))
            delete copy['Content']
            copy['State'] = "Done"
            ib.updateClassroomEvent(copy).then((r) => {
                props.history.push("/board/" + e.Content.session)
                window.location.reload()
            })
        }
    }

    function notificationCommon(type) {
        ReactGA.event({
            category: "Assistance",
            action: "RaiseHand"
        });
        var luid = mylocalStorage.getItem('mystoreID');

        var event = props.sess.Classroom + "-" + luid
        var jj = { action: "RaiseHand", session: props.sess.id, kind: type }
        var cmd = {
            id: event, ttl: props.sess.ttl,
            event: event, Classroom: props.sess.Classroom,
            type: "RaiseHand", State: "Sent", Content: JSON.stringify(jj)
        }
        if (!props.user || !props.user.name) {
            var name = mylocalStorage.getItem('myname');
            if (name) {
                cmd['For'] = luid + ":" + name
            } else {
                cmd['For'] = luid + ": noname"
            }
        } else {
            cmd['For'] = luid + ":" + props.user.name
        }
        if (assistanceRequest.studentAsked) {
            const pr = assistanceRequest.pendingList[0]
            if (pr && pr.Content && pr.Content.kind === type) cmd['State'] = "Done"
        }
        ib.getClassroomEvent(event).then((r) => {
            const f = r.data.getClassroomEvent
            if (f) {
                ib.updateClassroomEvent(cmd)
            } else {
                ib.createClassroomEvent(cmd)
            }
        })
    }
    function toggleHand(e) {
        handleClose()
        setOpen(!open3)
        if (!teacherR) {
            notificationCommon("help")
        }
    }
    function toggleFinish(e) {
        handleClose()
        setOpen(!open3)
        if (!teacherR) {
            notificationCommon("finish")
        }
    }
    function toggleBathroom(e) {
        handleClose()
        setOpen(!open3)
        if (!teacherR) {
            notificationCommon("bathroom")
        }
    }
    function speedUp(e) {
        handleClose()
        setOpen(!open3)
        if (!teacherR) {
            notificationCommon("speedup")
        }
    }
    function slowDown(e) {
        handleClose()
        setOpen(!open3)
        if (!teacherR) {
            notificationCommon("slowdown")
        }
    }
    var open = Boolean(assistanceRequest.studentAsked && teacherR)
    const id = open ? 'transitions-popper' : undefined;
    const handColor = assistanceRequest.studentAsked ? "green" : props.inkColor;
    const handleClose = () => {
        setAnchorEl({ el: null });
    };

    React.useEffect(() => {
        setWindowPosition(prev => {
            return { x: 0, y: 0 }
        });
    }, [props.showTextTools])
    function handleDrag(e, ui) {
        if (ui.y < 0 || ui.y > (window.innerHeight - 160)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    
    return (
        <div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl.el}
                keepMounted
                open={Boolean(anchorEl.el)}
                onClose={handleClose}
                getContentAnchorEl={null}
                className='raiseCls'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} >
                {subMenu.map((e) => (
                    < MenuItem key={e.name} style={{ backgroundColor: props.inkColor, color: "#ffffff" }}
                        onClick={e.cb}>
                        { e.icon && <ListItemIcon style={{ color: "#ffffff" }}>{e.icon}</ListItemIcon>}
                        <Typography variant="h6">{e.name}</Typography>
                    </MenuItem>
                ))}
            </Menu>
            <Tooltip className={classes.tooltip}
                classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow
                }}
                title={teacherR ? "Help Students" : "Raise Hand"} arrow placement="top">
                <IconButton id="raiseHandButton" ref={tpopperRef} className={clsx(classes.iconButtonHelp,'btnSqr p-5')} style={{ color: handColor }}
                    onClick={buttonClick}>
                        <svg width="20" height="20" viewBox="0 0 17 16" fill={handColor} xmlns="http://www.w3.org/2000/svg">
                            {svgIcons.handrise}
                        </svg>
                </IconButton>
            </Tooltip>
            <Popper id={id} open={open && open3} anchorEl={tpopperRef.current} >
            <Draggable
                handle="strong"
                onDrag={handleDrag}
                onStop={handleDragEnd}
                position={windowPosition}
            >
                <div className={classes.paper}>
                    <strong>
                    <div className={classes.headerDots}>
                        <MoreHorizIcon
                            className={classes.dots} />
                    </div>
                    </strong>
                    <List dense >
                        {(assistanceRequest.pendingList).map((e) => (
                            <ListItem dense className= "itemList" key={e.id} button onClick={() => actionButton(e)} onTouchEnd={() => actionButton(e)}>
                                <ListItemIcon className={classes.icon}>
                                    {e.Content && e.Content.kind ? icons[e.Content.kind] : (<LocationOnIcon />)}
                                </ListItemIcon>

                                <ListItemText primary={e.Content && e.Content.kind ? e.name + " [" + e.Content.kind + "]" : e.name}
                                    secondary={new Date(e.createdAt).toLocaleString(undefined,
                                        {
                                            month: "short", day: "numeric",
                                            hour: "numeric", minute: "numeric", second: "numeric"
                                        }
                                    )} />
                            </ListItem>
                        ))}
                    </List>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <IconButton className={classes.iconButtonClear} style={{ backgroundColor: props.inkColor }} onClick={clearAll} onTouchEnd={() => clearAll()}>
                               Clear All
                         </IconButton>
                    </div>
                </div>
            </Draggable>
            </Popper>
        </div >
    )
}

export default React.memo(HandRaise)