import * as ib from './ibdata'
import React from 'react';
import "./home.css"
import ReactGA from 'react-ga';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import {
    Typography,
    Button,
    Hidden,
    Grid,
} from '@material-ui/core';
import { v4 as uuid } from "uuid";
import HomeIcon from '@material-ui/icons/Home';

export default function Demo(props) {
    React.useEffect(() => {
        var from = "nothing";
        if (props.location && props.location.search) {
            const s = props.location.search.split('?')
            if (s && s.length > 0) from = s[1]
        }
        ReactGA.pageview('/demo')
        ReactGA.event({
            category: 'Demo',
            action: from
        });
    })
    function newBoard(e) {
        const id = uuid()
        const pg1 = id + "-pgNum-1"
        ib.createSession(pg1, "unsaved", "somecontent", id, 1, null, null).then((res) => {
            const redir = "/board/" + pg1
            props.history.push(redir);
            ReactGA.pageview('/createboard')
        })
    }
    return (
        <div className="scroll">
            <Grid container className='h-full'>
                <Grid item xs={12} sm={1} md={1} lg={1} className='relative'>
                    <Button onClick={() => { props.history.push("/") }} startIcon={<HomeIcon />} color="secondary" variant="contained">
                        Whiteboard.chat
                    </Button>
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} className='relative mr-top'>
                    <Typography className="drawShareColab">Reimagine Remote Learning</Typography>
                    <Typography className="drawShareColabL2 mb-20">Made for teaching</Typography>
                    <Typography className="normalText">Very easy to use, low bandwidth, takes real world concepts to the digital world.</Typography>
                    <Typography className="normalText ">Synchronus & realtime, individualized for students. Instant feedback.</Typography>
                    <Typography className="normalText">Observe each student and whole class at the same time.</Typography>

                    <video width="80%%"
                        src="https://s3-us-west-2.amazonaws.com/epiphani.ai/whiteboard/DemoTeacher.mp4" playsInline controls={true} loop={true} muted autoPlay="autoplay" />

                    <Button
                        onClick={newBoard}
                        className="newEpicBoard colab-btn mt-10"
                    >
                        Start Drawing
                         </Button>
                    <Hidden mdUp>
                        <div className='mt-10 disp-inline-block'>

                            <div className="helpButton btn-content justify-content">
                                <a href="https://youtu.be/BZjgfTuD-I4">Basic Help</a>
                            </div>
                            <div className="helpButton2 btn-content">
                                <NewReleasesIcon style={{ color: "#7f0055", marginRight: '5px' }} />
                                <a href="https://youtu.be/CcDRekFivkY">Create Class Help</a>
                            </div>
                        </div>
                        <br></br>
                        <div className="flex mt-5perc">
                            <a className="link" href="mailto: feedback@whiteboard.chat"> feedback@whiteboard.chat</a>
                        </div>
                    </Hidden>
                    <Hidden smDown>
                        <br></br>
                        <div className="flex copyrt">
                            <a className="link" href="mailto: feedback@whiteboard.chat"> feedback@whiteboard.chat</a>
                        </div>
                    </Hidden>
                </Grid>
                <Hidden smDown>
                    <Grid item xs={12} sm={4} md={4} lg={3} className='relative'>

                        <div className='mt-10 disp-inline-block help-btns'>

                            <div className="helpButton btn-content justify-content">
                                <a href="https://youtu.be/BZjgfTuD-I4">Basic Help</a>
                            </div>
                            <div className="helpButton2 btn-content">
                                <NewReleasesIcon style={{ color: "#7f0055", marginRight: '5px' }} />
                                <a href="https://youtu.be/CcDRekFivkY">Create Class Help</a>
                            </div>
                        </div>
                        <img src="penDraw.svg" alt="epiDraw" className='pendraw' />
                    </Grid>
                </Hidden>

            </Grid>

        </div>

    )
}