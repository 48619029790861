import * as React from 'react'
import { renderButtons, launchAsync }  from  "@microsoft/immersive-reader-sdk";
import * as ib from './ibdata'

function WBCImmersiveReader(props) {
    const [tokInfo, setTokInfo] = React.useState(null)

    React.useEffect(() => {
      renderButtons();
      ib.getService().then(response => {
        // console.log("response = ", response)
        setTokInfo(response);
      })
    }, [])

    React.useEffect(() => {
      if (!tokInfo) return
      const data = {
        title: props.title,
        chunks: [{
            content: props.text,
            lang:"en-us",
            mimeType: "text/plain"
        }]
      };
      launchAsync(tokInfo.token, tokInfo.subdomain, data, props.options).then(launchres => {
          // console.log("launchres = ", launchres)
          // const irPanelStyle = launchres.container.style
          // irPanelStyle.top="10vh"
          // irPanelStyle.left="10vw"
          // irPanelStyle.width="80vw"
          // irPanelStyle.height="80vh"
          // irPanelStyle.border="2px solid " + props.inkColor
          // irPanelStyle.borderRadius="4px"
        }
      ).catch(function (error) {
              console.log("Error in launching the Immersive Reader.");
              console.log(error);
          });
    }, [tokInfo]);

    return (
      <div className="ImmersiveReaderPanel">
      </div>
    )
}

export default WBCImmersiveReader;