import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { InputBase } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    header:{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    textFiled: {
        width: 370,
        height: 40,
        margin: "10px 0px",
        background: "#FFFFFF",
        border: "1px solid #E0E5E7",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        borderRadius: "999px",
    },
    divForm: {
       width: "375px !important",
    },
    closeIcon: {
        backgroundColor: "#E9EBF0",
        color: "rgb(47, 61, 91)",
        cursor: "pointer",
        borderRadius: "50%",
        position: "absolute",
        right: "30px",
    },
}));
export default function SavePaletteDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const [link, setLink] = React.useState({ name: "", group: "", checked: false, imageOnly: false});
    const classes = useStyles();
    const theme = useTheme();

    const handleSave = () => {
        setOpen(false);
        if (link.group !== "") {
            link.name = link.name + "::" + link.group
        }
        if (props.cb) props.cb(link)
    };
    React.useEffect(() => {
    }, [])
    React.useEffect(() => {
        setOpen(props.open)
        if (props.linkstate) {
            const ls = props.linkstate
            var ff = ls.name
            var grp = ls.group ? ls.group : ""
            setLink({ name: ff, group: grp })
        }
    }, [props])
    const handleClose = () => {
        setOpen(false);
        if (props.cb) props.cb(null)
    };
    function pasteText(e) {
        e.preventDefault()
        const txt = e.clipboardData.getData('text/plain')
        setLink({
            ...link,
            [e.target.id]: txt
        })
    }

    function handleType(e) {
        setLink({
            ...link,
            [e.target.id]: e.target.value
        })
    }
    function handleCheck(e) {
        setLink({
            ...link,
            [e.target.name]: e.target.checked
        })
    }
    // function handleSubmit(e) {
    //     console.log("HIT SUBMIT")
    //     e.preventDefault()
    //     if (link.name.length >= 0 && link.link.length >= 0) handleSave()
    // }
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className={classes.header}>
            <DialogTitle id="form-dialog-title">Add to my Palette</DialogTitle>
            <CloseIcon  className = {classes.closeIcon} onClick={handleClose} />
            </div>
            <DialogContent>
                <div className='flex'>
                <img src={props.obj} />
                </div>
                <DialogContentText>
                <div className={classes.divForm}>
                    <InputBase
                        className={classes.textFiled}
                        style={{ padding: "0px 25px" }}
                        id="group"
                        name="group"
                        placeholder="group name(optional)"
                        value={link.group}
                        onChange={(e) => handleType(e)}
                    />
                    <InputBase
                        className={classes.textFiled}
                        style={{ padding: "0px 25px" }}
                        id="name"
                        name="name"
                        placeholder= "Name"
                        value={link.name}
                        onPaste={pasteText}
                        onChange={(e) => handleType(e)}
                    />
                    </div>
                </DialogContentText>

                <div className='saveDiagChkBxs'>
                <FormGroup aria-label="position" className='frmlblStyle'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={link.imageOnly}
                                onChange={handleCheck}
                                name="imageOnly"
                                color="primary"
                            />
                        }
                        label="Save as image only "
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={link.checked}
                                onChange={handleCheck}
                                name="checked"
                                color="primary"
                            />
                        }
                        label="Share with community "
                    />
                </FormGroup>
                </div>

                <div className='formBtns m-10'>
                    <Typography
                        variant="contained"
                        size="small"
                        onClick={handleClose}
                        className='formCancel'
                        style={{marginRight: "24px", fontSize: "16px",}}
                    >
                        Close
                    </Typography>
                    <Button
                        variant="contained"
                        size="small"
                        style={{ backgroundColor: props.inkColor }}
                    onClick={() => handleSave()}
                        className= "createButton"
                        disabled={link.name.length <= 0}
                        >
                        Create
                    </Button>
                </div>
                </DialogContent>

        </Dialog>
    );
}
