import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useSelector, useDispatch } from 'react-redux'
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemAvatar, ListItemIcon, ListItemSecondaryAction, IconButton, ListSubheader, Tooltip, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import * as Actions from "./store/actions"
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Choices from "./Choices"
import * as ib from "./ibdata"
import ReactGA from 'react-ga';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import CallIcon from '@material-ui/icons/Call';
import * as mylocalStorage from "./mylocalStorage";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { InputBase} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from 'react';

const NOVIDEO = "wb.whiteboard.chat"
var videoEnabled = true
if (window.location.hostname === NOVIDEO) videoEnabled = false
const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        zIndex: 100,
        '& > *': {
            margin: theme.spacing(0),
        },
    },
    listroot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: "8px 0px !important",
    },
    secondaryActions:{
        right: '0'
    },
    button: {
        margin: theme.spacing(1),
        color: "#1E2639",
        // backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        border: "1px solid #C8CEDA",
        borderRadius: "999px",
        "&:hover": {
            backgroundColor: '#FFFFFF',
            color: '#1E2639',
        },
    },
    button1: {
        width: "90px",
        height: "30px",
        padding: "2px 10px",
        margin: "2px",
        color: "#3174F5",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        background: "linear-gradient(180deg, #FFFFFF 0%, #F4F5F6 100%)",
        border: "1px solid #C8CEDA",
        borderRadius: "999px",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        "&:hover": {
            backgroundColor: '#FFFFFF',
            color: '#1E2639',
        },
    },
    chatButton: {
        margin: "8px 8px 8px 0px",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: 'Lato',
        fontWeight: 600,
        letterSpacing: "0.02em",
        textTransform: "none",
        display: 'inline-flex',
        border: "1px solid #C8CEDA",
        borderRadius: "999px",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        "&:hover": {
            color: '#2F3D5B',
            border: "1px solid #E0E5E7 !important",
        },
    },
    wideButton: {
        margin: "8px 8px 8px 0px",
        color: "#FFFFFF",
        fontSize: "16px",
        fontFamily: 'Lato',
        fontWeight: "bold",
        letterSpacing: "0.02em",
        textTransform: "none",
        display: 'inline-flex',
        border: "1px solid #E0E5E7",
        borderRadius: "999px",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        "&:hover": {
            color: '#2F3D5B',
            backgroundColor: "#FFFFFF",
            border: "1px solid #E0E5E7 !important",
        },
    },
    buttonHalf: {
        margin: theme.spacing(1),
        color: "#ffffff",
        width: "220px",
        backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    tooltip: {
        margin: "4px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
    customArrow: {
        color: "#1E2639",
    },
    inputFiled: {
        width: "150px",
        padding: "2px 10px",
        border: "1px solid #2F3D5B",
        borderRadius: "999px",
        fontSize: "14px",
    },
}));

var globalList = []
var gTeacher = false
const teacherStyle = {
    color: "#1E2639",
    letterSpacing: "0.02em",
    padding: "2px 10px",
    margin: "2px",
    fontSize: "14px",
    fontFamily: 'Lato',
    fontWeight: '600',
    textTransform: "capitalize",
    display: 'inline-flex',
    background: "linear-gradient(180deg, #FFFFFF 0%, #F4F5F6 100%)",
    border: "1px solid #C8CEDA",
    borderRadius: "999px",
    boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
};
var gCreator = false
export default function Avatars(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const participants = useSelector((state) => state.participants);
    const activeUsers = useSelector((state) => state.activeUsers);
    const teacherR = useSelector((state) => state.teacher);
    const showCase = useSelector((state) => state.showCase);
    const [classRoom, setClassRoom] = React.useState(null)
    const [parts, setParts] = React.useState([])
    const [teachers, setTeachers] = React.useState([])
    const [students, setStudents] = React.useState([])
    const [diaopen, setdiaopen] = React.useState(false)
    const [groups, setGroups] = React.useState({ open: false, gname: null })
    const hideNotification = useSelector((state) => state.hideNotification);
    const [parentTeacherProfile, setParentTeacherProfile] = React.useState("")
    const [editName, setEditName] = React.useState(false)
    const [bOwnerName, setBOwnerName] = React.useState("")
    const [editStudentName, setEditStudentName] = React.useState(false)
    const [studentName, setStudentName] = React.useState("")
    const boardColor = props.inkColor;
    const lockArr = {
        "lock": { text: "Lock Class", locked: false, icon: <LockIcon /> },
        "unlock": { text: "Unlock Class", locked: true, icon: <LockOpenIcon /> }
    }
    const lockPage = {
        "lockPage": { text: "Lock Page", locked: false, icon: <LockIcon /> },
        "unlockPage": { text: "Unlock Page", locked: true, icon: <LockOpenIcon /> }
    }
    const followArr = {
        "follow": { text: "Follow Teacher", locked: false, icon: <LockIcon /> },
        "unfollow": { text: "UnFollow Teacher", locked: true, icon: <LockOpenIcon /> }
    }
    const [lock, setLock] = React.useState(lockArr["lock"])
    const [lockPage1, setLockPage] = React.useState(lockPage["lockPage"])

    const [follow, setFollow] = React.useState(followArr["follow"])

    const boardLocked = useSelector((state) => state.boardLocked);
    const pageLocked = useSelector((state) => state.pageLocked);

    const followTeacher = useSelector((state) => state.followTeacher);

    const [enableTeacher, setTeacher] = React.useState(false)
    var [groupList, setGroupList] = React.useState([])
    const ownerNameRef = React.useRef(null)
    const studentNameRef = React.useRef(null)
    function deleteGroup(g) {
        ib.deleteClassGroup(g)
    }

    function editGroup(g) {
        setGroups({ open: true, gname: g })
    }
    React.useEffect(() => {
        if (boardLocked.locked) {
            setLock(lockArr['unlock'])
        } else {
            setLock(lockArr['lock'])
        }
    }, [boardLocked])
    React.useEffect(() => {
        if (pageLocked.locked) {
            setLockPage(lockPage['unlockPage'])
        } else {
            setLockPage(lockPage['lockPage'])
        }
    }, [pageLocked])
    React.useEffect(() => {
        if (followTeacher.set) {
            setFollow(followArr['unfollow'])
        } else {
            setFollow(followArr['follow'])
        }
    }, [followTeacher])
    function GotoMyBoard() {
        if (props.sess) {
            ReactGA.event({
                category: 'MultiBoard',
                action: "GoToMyBoard"
            });
            mylocalStorage.setItem('autoJoin', props.sess.Classroom)
            props.history.push("/join/" + props.sess.parentBoardID)
            closeDialog()
        }
    }
    function gotoGroup(g) {
        ReactGA.event({
            category: 'MultiBoard',
            action: "JoinGroup"
        });
        mylocalStorage.setItem('autoJoin', props.sess.Classroom)
        props.history.push("/board/" + g.SessionID)
        closeDialog()
        // window.location.reload()

    }
    React.useEffect(() => {
        var s1, s2, s3;
        function findIdx(list, newO) {
            var ind = -1
            for (var i = 0; i < list.length; i++) {
                if (list[i].id === newO.id) {
                    return i
                }
            }
            return ind
        }
        function newGroup(grps, subs) {
            const newChecked = [...globalList];
            var luid = mylocalStorage.getItem('mystoreID');
            var myid = mylocalStorage.getItem('myid');

            for (var i = 0; i < grps.length; i++) {
                var grp = grps[i]
                const content = JSON.parse(grp.content)
                if (!gTeacher) {
                    var partOfGroup = false
                    for (let j = 0; j < content.members.length; j++) {
                        var m = content.members[j]
                        if (luid === m.localID) {
                            partOfGroup = true
                            break
                        }
                        if (myid && m.type && m.type === "precog") {
                            if (m.id.toString().toLowerCase() === myid.toString().toLowerCase()) {
                                partOfGroup = true
                                break
                            }
                        }
                    }
                    if (!partOfGroup) {
                        //was i part of the group earlier
                        var idx = findIdx(newChecked, grp)
                        if (idx !== -1) {
                            newChecked.splice(idx, 1);
                        }

                        continue //not for me
                    }
                }
                var idx = findIdx(newChecked, grp)

                if (idx === -1) {
                    newChecked.push(grp);
                } else {
                    newChecked.splice(idx, 1);
                    newChecked.push(grp);
                }
            }
            setGroupList(newChecked)
            globalList = newChecked

            var autoJ = mylocalStorage.getItem('autoJoin');
            if (newChecked.length > 0 && !gTeacher && (!autoJ || autoJ !== props.sess.Classroom)) {
                var pp = amIGroupBoard(newChecked[newChecked.length - 1])
                if (!pp) {
                    props.history.push("/board/" + newChecked[newChecked.length - 1].SessionID)
                    closeDialog()
                }
            } else {
                if (newChecked.length === 0 && (!Boolean(teacherR) && !gCreator)) {
                    if (props.sess && props.sess.isGroup) {
                        props.history.push("/join/" + props.sess.parentBoardID)
                    }
                }
            }
        }

        function amIGroupBoard(g) {
            var pp = g.SessionID.split("-pgNum-")
            const JOINURL = window.location.href.split("/board/")
            var sessID = JOINURL[1].split("-pgNum-")
            if (sessID[0] !== pp[0]) {
                return false
            }
            return true
        }
        function delGroup(grp) {
            ReactGA.event({
                category: 'MultiBoard',
                action: "DeleteGroup"
            });
            const newChecked = [...globalList];
            var idx = findIdx(newChecked, grp)
            if (idx !== -1) {
                newChecked.splice(idx, 1);
            }
            setGroupList(newChecked)
            globalList = newChecked
            if (amIGroupBoard(grp)) {
                props.history.push("/join/" + props.sess.parentBoardID)

            }
        }
        function gotClass(cl) {
            var luid = mylocalStorage.getItem('mystoreID');
            if (!cl.TeacherList) {
                cl.TeacherList = [cl.TeacherID]
            }
            if (cl.TeacherList && cl.TeacherList.indexOf(cl.TeacherID) === -1) {
                cl.TeacherList.push(cl.TeacherID)
            }
            if (cl.content) {
                var content = JSON.parse(cl.content)
                if (luid in content) {
                    //save the positions
                    dispatch(Actions.setMatrixGrid(content[luid]))
                }
            }
            setClassRoom(cl)
            if (cl && cl.TeacherList && cl.TeacherList.indexOf(luid) !== -1) {
                // in the teacher list
                if (teacherR === 0) {
                    //was not a teacher 
                    dispatch(Actions.setTeacher(1))
                    setTeacher(true)
                    gTeacher = true
                } else {
                    // already a teacher
                }
            } else {
                if (teacherR === 1) {
                    // was a teacher - no more
                    dispatch(Actions.setTeacher(0))
                    setTeacher(false)
                    gTeacher = false
                }
            }
        }

        function classSubCB(classSub) {
            s3 = classSub;
        }
        function classGroupSubCB(classGroupSubs) {
            [s1, s2] = classGroupSubs;
        }
        if (props.sess) {
            // gTeacher = false
            var luid = mylocalStorage.getItem('mystoreID');
            gCreator = false
            if (luid === props.sess.CreatorLocalID) gCreator = true
            if (props.sess && props.sess.Classroom) {
                if (luid === props.sess.CreatorLocalID && props.sess.id === props.sess.parentBoardID) {
                    setTeacher(true)
                    gTeacher = true
                }
                ib.ClassroomSubscribe({
                    "id": props.sess.Classroom,
                    "cb": gotClass,
                    "doList": true,
                    "subCB": classSubCB
                });
                ib.SubscribeClassGroupByClass({
                    "ClassroomID": props.sess.Classroom,
                    "cb": newGroup,
                    "delCB": delGroup,
                    "doList": true,
                    "subCB": classGroupSubCB
                });

            }
        }
        return () => {
            if (s1) s1.unsubscribe()
            if (s2) s2.unsubscribe()
            if (s3) s3.unsubscribe()
        }
    }, [props.sess, teacherR])

    React.useEffect(() => {
        var ff = []
        let curTime = new Date().getTime();

        function compareParts() {
            if (ff.length !== parts.length) {
                return true;
            }

            for (let idx in ff) {
                if (JSON.stringify(ff[idx]) !== JSON.stringify(parts[idx])) {
                    return true;
                }
            }

            return false;
        }

        Object.keys(participants).forEach(item => {
            const p = participants[item]
            if ((props.sess && props.sess.savedOwner) === (p.UserProfile && p.UserProfile) && p.UserProfile !== null) {
                if(parentTeacherProfile === "")
                {
                    setBOwnerName(p.name)
                    setParentTeacherProfile(p.UserProfile)
                } 
            } else if ((props.sess && props.sess.savedOwner === null)) {
                let parentSess;
                if (props.parentBoardData.length === 0) {
                    ib.getSession(props.sess.parentBoardID).then((res) => {
                        props.setParentBoardData(res.data.getSession)
                        parentSess = res.data.getSession
                    })
                } else {
                    parentSess = props.parentBoardData
                }
                if (parentSess && parentSess.savedOwner === (p.UserProfile && p.UserProfile) && p.UserProfile !== null) {
                    setBOwnerName(p.name)
                    setParentTeacherProfile(p.UserProfile)
                }
            }
            if (props && props.user && props.user.id === p.id) {
                setStudentName(p.name)
            }
            var parse = true
            var pp
            try {
                pp = JSON.parse(p.content)
            } catch {
                parse = false
            }
            if (parse) {
                let color = pp && pp.color ? pp.color : "green";
                let updTime = new Date(p.updatedAt);
                let timedOut = 0;
                const email = pp && pp.email ? " [" + pp.email + "]" : " "
                var active = 0

                /**
                * Because the updates can happen at different times between different
                * participants, we need to check if an update was not received for
                * 2 timeout intervals to truly mark a participant as down or else
                * the state could keep fluctuating between timedOut = 0 and timedOut = 1
                */
                if (((curTime - updTime) / 1000) > (ib.KEEPALIVE_TIMEOUT_SECONDS * 2)) {
                    color = "silver";
                    timedOut = 1;
                }

                if (activeUsers[p.id]) {
                    active = 1
                }
                if (p.name) {
                    var teacher = false
                    if (classRoom && classRoom.TeacherList &&
                        classRoom.TeacherList.indexOf(pp.localID) !== -1) teacher = true
                    let inverseColor = ((color === "white") || (color.toUpperCase() === "#FFFFFF")) ? "black" : "white";
                    // Don't generate a new style object every iteration b/c that will
                    // cause a re-render to trigger
                    let style = { color: inverseColor, backgroundColor: color };
                    var loginTime = ""
                    try {
                        loginTime = ib.dateConvert(updTime)
                    } catch { }
                    var sc = checkShowcase(pp.localID)
                    var nn = teacherR ? email : ""
                    ff.push({
                        name: p.name, eId: nn, active: active, color: color, teacher: teacher, timedOut: timedOut,
                        showcased: sc, peerID: pp.peerID,
                        id: p.id, UserProfile: p.UserProfile, avatar: p.avatar, localID: pp.localID, style: style, loginTime: loginTime
                    })
                }
            }
        })
        function checkShowcase(id) {
            if (!showCase.locked) return null
            for (let i = 0; i < showCase.events.length; i++) {
                var s = showCase.events[i]
                if (s && s.Content && s.Content.student && s.Content.student === id) {
                    return s
                }
            }
            return null
        }
        ff.sort((a, b) => {
            let c = b.active - a.active;

            if ((b.active != 1) && (c <= 0)) {
                // reverse the order b/c we want timed out
                // users to show at the end.
                c = a.timedOut - b.timedOut;
            }

            return c;
        })

        // Only update participants if something changed in ff
        if (compareParts()) {
            setParts(ff);
        } else {
            setParts(parts);
        }
    }, [participants, activeUsers, props.sess, classRoom, showCase])

    React.useEffect(() => {
        let studs = parts.filter(p=>p.teacher === false)
        let teas = parts.filter(p=>p.teacher === true)
        setTeachers(teas)
        setStudents(studs)
        function timerCB() {
            var ff = parts
            ff.forEach((f) => {
                if (f.active)
                    dispatch(Actions.deleteActive(f))
            })
        }
        var timer = window.setInterval(timerCB, 5000)
        return () => { if (timer) window.clearInterval(timer) }
    }, [parts])
    function Clicked(o) {
        ReactGA.event({
            category: 'MultiBoard',
            action: "OpenGroup"
        });
        setGroups({ open: true, gname: null })
    }
    const boardChat = useSelector((state) => state.boardChat);

    function chatClicked(o) {
        if (boardChat.started) {
            closeDialog()
            ib.delChat(props.sess.Classroom)
            return
        }
        var event = props.sess.Classroom + "-" + props.sess.parentID
        var jj = { action: "StartChat", msg: "" }
        var cmd = {
            id: event, ttl: props.sess.ttl,
            event: event, Classroom: props.sess.Classroom,
            type: "Chat", State: "Sent", Content: JSON.stringify(jj)
        }
        cmd['For'] = props.sess.parentID
        ib.getClassroomEvent(event).then((r) => {
            const f = r.data.getClassroomEvent
            if (f) {
                ib.updateClassroomEvent(cmd)
            } else {
                ib.createClassroomEvent(cmd)
            }
        })

        ReactGA.event({
            category: 'MultiBoard',
            action: "chatClicked"
        });
        closeDialog()
    }
    function doneGroup(c) {
        ReactGA.event({
            category: 'MultiBoard',
            action: "GroupCreateDone"
        });
        setGroups({ open: false, gname: null })
    }
    React.useEffect(() => {
        if (diaopen) setGroups({ open: false, gname: null })
        if (window.insideMSTeams === true) videoEnabled = false
    }, [diaopen])

    function showCaseStudent(e) {
        closeDialog()
        ReactGA.event({
            category: 'ShowCase',
            action: lock.name
        });
        if (e.showcased) {
            // remmove the showcase 
            removeShowcaseEvent(e.showcased)
            return
        }
        if (classRoom) {
            async function getUser(u) {
                for (let i = 0; i < u.length; i++) {
                    var ff = u[i]
                    if (ff.CurrentUserId === e.id) {
                        var ff2 = ff.id.split("-CL-")
                        let lu = ff2[0]
                        var userSess = await ib.findSessionbyID(props.sess.Classroom, lu)
                        if (userSess) {
                            let pg = ff.CurrentSessionId.split("-pgNum-")[1]
                            // get the board
                            ib.sendShowCaseEvent(e.localID, userSess.parentID + "-pgNum-" + pg, props.sess)
                        }
                        return
                    }
                }
            }
            ib.listLocalUsersByClass(classRoom.id, getUser, null)
        }
    }
    function makeTeacher(e) {
        closeDialog()

        var update = false
        if (!classRoom || !classRoom.TeacherList) return
        if (e.teacher) {
            var rr = classRoom.TeacherList.indexOf(e.localID)
            if (rr === -1 || classRoom.TeacherList.length <= 1) {
                // not there? error
                return
            }
            classRoom.TeacherList.splice(rr, 1)
            update = true
        } else {
            //not a teacher, make teacher
            let rr = classRoom.TeacherList.indexOf(e.localID)
            if (rr !== -1) return
            classRoom.TeacherList.push(e.localID)
            update = true
        }
        if (update) {
            delete classRoom['Teacher']
            delete classRoom['Users']

            ib.updateClassroom(classRoom)
        }
    }
    function sendEvent(msg, state) {
        var event = props.sess.Classroom + "-" + msg
        var jj = { action: msg, state: state }
        var cmd = {
            id: event, ttl: props.sess.ttl,
            event: event, Classroom: props.sess.Classroom,
            type: msg, State: "Sent", Content: JSON.stringify(jj)
        }
        if (!state) {
            cmd.State = "Done"
        }
        cmd['For'] = props.sess.Classroom
        ib.getClassroomEvent(event).then((r) => {
            const f = r.data.getClassroomEvent
            if (f) {
                ib.updateClassroomEvent(cmd)
            } else {
                ib.createClassroomEvent(cmd)
            }
        })
    }

    function closeDialog() {
        setEditName(false)
        setEditStudentName(false)
        dispatch(Actions.setAvatarDialogOpen(false));
        dispatch(Actions.processPendingParticipants());
        setdiaopen(false);
    }

    function openDialog() {
        dispatch(Actions.setAvatarDialogOpen(true));
        setdiaopen(true);
    }
    function toggleNotif() {
        mylocalStorage.setItem('setNotif', false)
        dispatch(Actions.setNotification(false))
    }
    function lockClick(e) {
        closeDialog()
        toggleNotif()
        ReactGA.event({
            category: 'LockClick',
            action: lock.name
        });
        var lockState
        if (!lock.locked) {
            setLock(lockArr["unlock"])
            lockState = true
        } else {
            lockState = false
            setLock(lockArr["lock"])
        }
        sendEvent("LockClass", lockState)
    }
    function pageLockClick(e) {
        closeDialog()
        toggleNotif()
        ReactGA.event({
            category: 'pageLockClick',
            action: lock.name
        });
        var ff = Actions.getPageLock(pageLocked)
        var lockState
        if (!ff.locked) {
            setLockPage(lockPage['unlockPage'])
            lockState = true
        } else {
            lockState = false
            setLockPage(lockPage['lockPage'])
        }
        sendEvent("LockPage-" + ff.pgNum, lockState)
    }
    function followClick(e) {
        closeDialog()
        toggleNotif()
        ReactGA.event({
            category: 'FollowClick',
            action: "FOLLOW CLICK"
        });
        var followState
        if (!followTeacher.set) {
            setFollow(followArr["unfollow"])
            const JOINURL = window.location.href.split("/board/")
            var sessID = JOINURL[1].split("-pgNum-")
            followState = sessID[1]
        } else {
            followState = null
            setFollow(followArr["follow"])
        }
        sendEvent("FollowTeacher", followState)
    }
    function showcaseRemove() {
        closeDialog()
        if (!teacherR) return
        ReactGA.event({
            category: "ShowCase",
            action: "UnLock"
        });
        mylocalStorage.removeItem("timerLocation")

        showCase.events.forEach((e) => {
            removeShowcaseEvent(e)
        })

    }

    function CallStudent(a) {
        closeDialog()
        props.cb['CallStudent'](a)
    }
    function removeShowcaseEvent(e) {
        var copy = JSON.parse(JSON.stringify(e))
        delete copy['Content']
        copy['State'] = "Done"
        ib.updateClassroomEvent(copy)
    }

    function onSubmitName(e){
        setEditName(false) 
        props.onChangeNameCB(ownerNameRef.current?.value)
        setBOwnerName(ownerNameRef.current?.value)
    }
    function onChangeStudentName(e) {
        setEditStudentName(false)
        props.onChangeNameCB(studentNameRef.current?.value)
        setStudentName(studentNameRef.current?.value)
    }


    const DiaBox = () => {
        return (
            <div>
                <Dialog open={diaopen} className="participantsDialog" onClose={() => closeDialog()} aria-labelledby="form-dialog-title" fullWidth >
                    <div className="participantsHeader">
                        <DialogTitle id="form-dialog-title" className="headerName">Participants</DialogTitle>
                        <div className='dispFlexEnd posClose'>
                        <CloseIcon className='ndCloseIcon' style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%" }} onClick={() => {  closeDialog() }} />
                        </div>
                    </div>
                    <DialogContent>
                        {groups.open ? (
                            <Choices parts={parts} gr={groups} groupList={groupList} cb={doneGroup} inkcolor={boardColor}/>
                        ) : (
                                <>
                                    {showCase.locked && Boolean(teacherR) && (
                                        <Button
                                            // variant="contained"
                                            color="secondary"
                                            fullWidth
                                            onClick={() => showcaseRemove()}
                                            className={classes.button}
                                            startIcon={<CancelIcon />}
                                        >
                                            Remove Showcase
                                        </Button>
                                    )}
                                    {teachers.length > 0 && <List dense className={classes.listroot}>
                                        {teachers.length > 0 ? <span className="listHeading">Teachers</span> : null}
                                       {teachers.map((a) => {
                                        return (
                                            a.teacher ?
                                                <div className="listItem">
                                                    <ListItem key={a.id}>
                                                        <ListItemAvatar>
                                                            {a.active ? (
                                                                <StyledBadge
                                                                    key={a.id}
                                                                    overlap="circle"
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    variant="dot"
                                                                >
                                                                    <Avatar
                                                                        src={a.avatar}
                                                                        style={a.style}
                                                                    >
                                                                        {a.name[0].toUpperCase()}
                                                                    </Avatar>
                                                                </StyledBadge>
                                                            ) : (
                                                                <Avatar
                                                                    key={a.id}
                                                                    src={a.avatar}
                                                                    style={a.style}
                                                                >
                                                                    {a.name[0].toUpperCase()}
                                                                </Avatar>
                                                            )}
                                                        </ListItemAvatar>
                                                        <div>
                                                            {(a.UserProfile === parentTeacherProfile) ?
                                                                <div>
                                                                    {editName ?
                                                                        <>
                                                                         <input 
                                                                         onKeyPress={(e) => e.key === 'Enter' && onSubmitName(e)}
                                                                         className = {classes.inputFiled}
                                                                         ref={ownerNameRef}
                                                                         autoFocus
                                                                         defaultValue={bOwnerName}
                                                                         />
                                                                        </>
                                                                        :
                                                                        <p className= "ownerName">{bOwnerName}</p>}
                                                                      <p className= "ownerId">{a.eId}</p>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <p className= "ownerName" >{a.name}</p>
                                                                    <p className= "ownerId">{a.eId}</p>
                                                                </div>
                                                                }
                                                            <p className= "loginTime" >{a.loginTime}</p>
                                                        </div>
                                                                  {Boolean(teacherR) && (a.UserProfile === parentTeacherProfile) && (
                                                            <ListItemIcon>
                                                                {editName ? (
                                                                    <Button variant="contained" onClick={onSubmitName}  style={teacherStyle}>save</Button>
                                                                ) : (
                                                                    <Button variant="contained" onClick={() => { setEditName(true) }} style={teacherStyle}>Rename</Button>
                                                                )}
                                                            </ListItemIcon>
                                                              )}
                                                        {Boolean(teacherR) && (a.UserProfile !== parentTeacherProfile) && (
                                                            <ListItemIcon onClick={() => makeTeacher(a)}>
                                                                {a.teacher ? (
                                                                    <Button variant="contained" style={teacherStyle}>Undo Co-Teacher</Button>
                                                                ) : (
                                                                    <Button variant="contained" style={teacherStyle}>Make Co-Teacher</Button>
                                                                )}
                                                            </ListItemIcon>
                                                        )}
                                                        {Boolean(teacherR) && (
                                                            <ListItemIcon onClick={() => showCaseStudent(a)}>
                                                                {a.showcased ? (
                                                                    <Button variant="contained" style={teacherStyle}>Undo Showcase</Button>
                                                                ) : (
                                                                    <Button variant="contained" style={teacherStyle}>Showcase</Button>
                                                                )}
                                                            </ListItemIcon>
                                                        )}
                                                        {videoEnabled && Boolean(teacherR) && (a.UserProfile !== parentTeacherProfile) && (
                                                            <CallIcon onClick={() => CallStudent(a)} />
                                                        )}
                                                    </ListItem>
                                                </div>
                                                :
                                                null
                                        )
                                    })}
                                </List>}
                                <List dense className={classes.listroot}>
                                    {students.length > 0 ? <span className="listHeading">{students.length == 1 ? 'student' : 'students'}</span> : null}
                                    {students.map((a) => {
                                            return (
                                            !a.teacher ?
                                                <div className="listItem">
                                                <ListItem key={a.id}>
                                                    <ListItemAvatar>
                                                        {a.active ? (
                                                            <StyledBadge
                                                                key={a.id}
                                                                overlap="circle"
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'right',
                                                                }}
                                                                variant="dot"
                                                            >
                                                                <Avatar
                                                                    src={a.avatar}
                                                                    style={a.style}
                                                                >
                                                                    {a.name[0].toUpperCase()}
                                                                </Avatar>
                                                            </StyledBadge>
                                                        ) : (
                                                                <Avatar
                                                                    key={a.id}
                                                                    src={a.avatar}
                                                                    style={a.style}
                                                                >
                                                                    {a.name[0].toUpperCase()}
                                                                </Avatar>
                                                            )}
                                                    </ListItemAvatar>
                                                    <div>
                                                            {(a.id === props.user.id) ?
                                                                <div>
                                                                    {editStudentName ?
                                                                        <>
                                                                        <input 
                                                                         onKeyPress={(e) => e.key === 'Enter' && onChangeStudentName(e)}
                                                                         className = {classes.inputFiled}
                                                                         ref={studentNameRef}
                                                                         autoFocus
                                                                         defaultValue={studentName}
                                                                         />
                                                                        </>
                                                                        :
                                                                        <p className= "ownerName">{studentName}</p>}
                                                                        <p className= "ownerId">{a.eId}</p>
                                                                </div>
                                                                :
                                                                <div>
                                                                    <p className= "ownerName" >{a.name}</p>
                                                                    <p className= "ownerId">{a.eId}</p>
                                                                </div>
                                                                }
                                                            <p className= "loginTime" >{a.loginTime}</p>
                                                        </div>
                                                            {Boolean(!teacherR) && (a.id === props.user.id) && (
                                                                <ListItemIcon>
                                                                    {editStudentName ? (
                                                                        <Button variant="contained" onClick={onChangeStudentName} style={teacherStyle}>save</Button>
                                                                    ) : (
                                                                        <Button variant="contained" onClick={() => { setEditStudentName(true) }} style={teacherStyle}>Rename</Button>
                                                                    )}
                                                                </ListItemIcon>
                                                            )}
                                                    {Boolean(teacherR) && (
                                                        <ListItemIcon onClick={() => makeTeacher(a)}>
                                                            {a.teacher ? (
                                                                    <Button variant="contained" style={teacherStyle}>Undo Co-Teacher</Button>
                                                            ) : (
                                                                    <Button variant="contained" style={teacherStyle}>Make Co-Teacher</Button>
                                                                )}
                                                        </ListItemIcon>
                                                    )}
                                                    {Boolean(teacherR) && (
                                                        <ListItemIcon onClick={() => showCaseStudent(a)}>
                                                            {a.showcased ? (
                                                                <Button variant="contained" style={teacherStyle}>Undo Showcase</Button>
                                                            ) : (
                                                                    <Button variant="contained" style={teacherStyle}>Showcase</Button>
                                                                )}
                                                        </ListItemIcon>
                                                    )}
                                                    {videoEnabled && Boolean(teacherR) && (
                                                        <CallIcon onClick={() => CallStudent(a)} />
                                                    )}
                                                    </ListItem>
                                                </div>
                                                :
                                                null
                                            )
                                        })}
                                    </List>
                                    {groupList.length > 0 && (
                                        <>
                                        <div className="groupList">
                                            <List dense className={classes.listroot}
                                                subheader={<ListSubheader component="div" id="nested-list-subheader" style={{textTransform: "capitalise"}}>
                                                    Groups
                                          </ListSubheader>}
                                            >
                                                {groupList.map((a) => (
                                                    <ListItem key={a.id} >
                                                        <ListItemText primary={a.name} />
                                                        <ListItemSecondaryAction className={classes.secondaryActions}>
                                                            <IconButton onClick={() => gotoGroup(a)} edge="end" aria-label="delete">
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                            {enableTeacher && (
                                                                <>
                                                                    <IconButton onClick={() => editGroup(a)} edge="end" aria-label="delete">
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton onClick={() => deleteGroup(a)} edge="end" aria-label="delete">
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </List>
                                            {!enableTeacher && (
                                                <Button
                                                    // variant="contained"
                                                    color="secondary"
                                                    fullWidth
                                                    onClick={() => GotoMyBoard()}
                                                    style={{ backgroundColor: props.inkColor }}
                                                    className={classes.wideButton}
                                                    startIcon={<CropOriginalIcon />}
                                                >
                                                    Go To My Board
                                                </Button>
                                            )}
                                        </div>
                                        </>
                                    )}
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {!groups.open ? (
                            <React.Fragment>
                                <div className="avatarFooter">
                                    <div className="participantFooter">
                                        {chatEnable && (
                                            <div>
                                                <Divider
                                                    style={{ margin: "0px -24px" }}
                                                />
                                                <div className="lockSection">
                                                    <div className="classLockSection">
                                                        <LockOutlinedIcon />
                                                        <FormControlLabel
                                                            labelPlacement="start"
                                                            control={<Switch size="small" checked={lock.text === "Lock Class" ? false : true} onChange={() => lockClick()} />}
                                                            label={lock.text}
                                                        />
                                                    </div>
                                                    <div className="pageLockSection">
                                                        <LockOutlinedIcon />
                                                        <FormControlLabel
                                                            labelPlacement="start"
                                                            control={<Switch size="small" checked={lockPage1.text === "Lock Page" ? false : true} onChange={() => pageLockClick()} />}
                                                            label={lockPage1.text}
                                                        />
                                                    </div>
                                                    <div className="pageLockSection">
                                                        <LockOutlinedIcon />
                                                        <FormControlLabel
                                                            labelPlacement="start"
                                                            control={<Switch size="small" checked={follow.text === "Follow Teacher" ? false : true} onChange={() => followClick()} />}
                                                            label={follow.text}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {((videoEnabled && chatEnable) || (window.insideMSTeams === true && chatEnable)) && (
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                fullWidth
                                                style={{ backgroundColor: props.inkColor }}
                                                onClick={() => chatClicked()}
                                                className={classes.chatButton}
                                                startIcon={<GroupAddIcon />}
                                            >
                                                {boardChat.started ? "Delete Chat" : "Create Chat"}
                                            </Button>
                                        )}
                                        {enableTeacher && (
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                fullWidth
                                                onClick={() => Clicked()}
                                                style={{ backgroundColor: props.inkColor }}
                                                className={classes.wideButton}
                                                startIcon={<GroupAddIcon />}
                                            >
                                                Create Group Board
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                            :
                            null
                        }
                    </DialogActions>
                </Dialog >
            </div >
        )
    }
    function GroupClick() {
        ReactGA.event({
            category: 'MultiBoard',
            action: "OpenGroupDialog"
        });
        openDialog();
    }
    const chatEnable = props.sess && props.sess.Classroom && Boolean(teacherR)
    return (
        <div className={classes.root}>
            {diaopen && (<DiaBox parentTeacherProfile={parentTeacherProfile} />)}
            <Tooltip className={classes.tooltip}
                classes={{
                    tooltip: classes.customTooltip,
                }}
                title='Participants Panel' arrow placement="top">
                <AvatarGroup id="avatarPanel" onClick={GroupClick} max={props.tabMob ? 3 : 5}>
                {parts.map((a) => {
                    { var inverseColor = ((a.color === "white") || (a.color.toUpperCase() === "#FFFFFF")) ? "black" : "white" }
                    return (a.active ? (
                        <StyledBadge
                            key={a.id}
                            overlap="circle"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                        >
                            <Avatar
                                src={a.avatar}
                                style={a.style}
                            >
                                {a.name[0].toUpperCase()}
                            </Avatar>
                        </StyledBadge>
                    ) : (
                            <Avatar
                                key={a.id}
                                src={a.avatar}
                                style={a.style}
                            >
                                {a.name[0].toUpperCase()}
                            </Avatar>
                        )
                    )
                })}
            </AvatarGroup>
            </Tooltip>
        </div>
    );
}