import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles({
  root: {
    minWidth: 390,
    // width: '40%',
    // background: '#ffa500'
    border: '4px solid',
    borderRadius: '8px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#FAFAFA',
    },
    barColorPrimary: {
        backgroundColor: '#EAEAEA',
    },
})(LinearProgress);


function calcPosition(elId, pos) {
    if (!pos) {
        pos = 'right';
    }
    var cardPos = { position: 'fixed' };
    if (!elId) return cardPos;
    var div = document.getElementById(elId);
    if (!div) return cardPos;
    var rect = div.getBoundingClientRect();
    var margin = 25;

    switch(pos) {
        case 'right':
            var x = rect.left + rect.width + margin;
            var y = rect.top - margin;
            cardPos = { position: 'fixed', left: x, top: y };
            break;
        case 'right-lower':
            var x = rect.left + rect.width + margin;
            var y = rect.top + margin;
            cardPos = { position: 'fixed', left: x, top: y };
            break;
        case 'right-higher':
            var x = rect.left + rect.width + margin;
            var y = rect.top - 2 * margin;
            cardPos = { position: 'fixed', left: x, top: y };
            break;
        case 'left':
            var x = window.innerWidth - rect.left - margin;
            var y = rect.top - margin;
            cardPos = { position: 'fixed', right: x, top: y };
            break;
        case 'above':
            var x = rect.left - margin - 100;
            var y = window.innerHeight - rect.top + margin;
            cardPos = { position: 'fixed', left: x, bottom: y };
            break;
        case 'below':
            var x = rect.left - margin - 100;
            var y = rect.top + rect.height + margin;
            cardPos = { position: 'fixed', left: x, top: y };
            break;
        case 'above-left':
            var x = window.innerWidth - rect.left - margin;
            var y = window.innerHeight - rect.top - margin;
            cardPos = { position: 'fixed', right: x, bottom: y };
            break;
        case 'above-right':
            var x = rect.left + rect.width + margin;
            var y = window.innerHeight - rect.top - margin;
            cardPos = { position: 'fixed', left: x, bottom: y };
            break;
        case 'below-right':
            var x = rect.left + rect.width + margin;
            var y = rect.top + rect.height + margin;
            cardPos = { position: 'fixed', left: x, top: y };            
            break;
        case 'below-left':
            var x = window.innerWidth - rect.left - margin;
            var y = rect.top + rect.height + margin;
            cardPos = { position: 'fixed', right: x, top: y };
            break;
        case 'over':
            var x = rect.left + margin;
            var y = rect.top + margin;
            cardPos = { position: 'fixed', left: x, top: y };
            break;
        case 'center':
            cardPos = { position: 'fixed' };
            break;
        default:
            var x = rect.left + margin;
            var y = rect.top + margin;
            cardPos = { position: 'fixed', left: x, top: y };
            break;
    }

    return cardPos;
    
}

export default function HelpCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const timeout = props.timeout;
  const increment = 5;
  const [progress, setProgress] = useState(0);
  const [timer, setTimer] = useState(0);
  const [paused, setPaused] = useState(props.paused);
  var isMounted = true;
  const mobile = (typeof window.orientation !== 'undefined')

  function doTimeout() {
     var t = setTimeout(function() {
        if (isMounted)
          setProgress(p => p + increment)
     }, timeout/(100/increment))
     setTimer(t);
  }
  
  useEffect(() => {
    // console.log("cardid = ", props.cardId, "progress = ", progress)
    if (progress >= 100) {
        clearTimeout(timer);
        closeCard()
    } else {
        if (props.timeout && props.timeout != '0' && !paused)
            doTimeout();
    }
  }, [progress]);

  useEffect(() => {
    setProgress(0);
  }, [props.cardId])

  useEffect(() => {
    return () => { clearTimeout(timer); isMounted = false; };
  }, []);

  useEffect(() => {
    if (!paused) {
        setProgress(p => (p === 0 ? p : p - increment))
    } else {
        clearTimeout(timer);
    }
  }, [paused])

  useEffect(() => {
    if (props.title === 'Raise Hands') {
      props.setShowTextTools(false)
    } 
    if(props.title === 'Board Name'){
      props.setShowTextTools(true)
    } 
  }, [props.title])

  function userClosedCard(event) {
    event.stopPropagation();
    setProgress(100);
  }

  function closeCard(forward=true) {
    // Give the linear progress indicator a chance
    // to fill up to 100% fully.
    setTimeout(() => {
        props.doneCb(props.cardId, forward);
    }, 500);
  }

  function resumeTimer(event) {
      event.stopPropagation();
      setPaused(false)
      props.pauseCB(false)
  }

  function pauseTimer(event) {
      event.stopPropagation();
      setPaused(true);
      props.pauseCB(true)
  }

  function previousCard(event) {
      event.stopPropagation();
      clearTimeout(timer);
      closeCard(false);
  }

  function nextCard(event) {
      event.stopPropagation();
      clearTimeout(timer);
      closeCard(true);
  }

  function skipForNowCB(event) {
      event.stopPropagation();
      clearTimeout(timer);
      props.skipForNowCB();
  }

  function skipForGoodCB(event) {
      event.stopPropagation();
      clearTimeout();
      props.skipForGoodCB();
  }

  return (
    <>
    <Fade in={true} timeout={600}>
    <Card className={classes.root} variant="outlined" 
        style={Object.assign({}, calcPosition(props.anchorElId, props.position), {borderColor: props.inkColor})}>
      <CardContent>
        <Typography variant="h4" component="h2" style={{color: '#003D7F'}}>
          {props.title}
        </Typography>
        <div style={{margin: '10px'}}></div>
        <Grid container spacing={2} justify="space-between" alignItems="center">
            <Grid item>
        <Typography component="p" style={{color: '#003D7F', 'fontSize': '0.6rem'}}>
          {props.helpText.map((t, k) => (
            <span key={k}>{t}<br /></span>
          ))}
        </Typography>
            </Grid>
            <Grid item>
                
                { (props.icon && !mobile) ? 
                // <img height='64px' src={"assets/images/Icons/"+props.icon+".png"}
                <i className={"fas "+props.icon}
                 style={{fontSize: "2.5rem", color: props.inkColor}}/> : null }
            </Grid>
        </Grid>
        <div style={{marginTop: '20px', marginBottom: '-20px'}}>
        <ColorLinearProgress key={props.cardId} variant="determinate" value={progress} />
        </div>
      </CardContent>
      <CardActions>
        <Grid container justify="space-between" alignItems="center">
        <Grid item>
            <Button size="large" onClick={userClosedCard} style={{ border: '1px solid grey' }}>
                { props.actionText ? props.actionText : "Got it" }
            </Button>
        </Grid>
        { props.navigation === false ? null :
        <Grid item>
            <Grid container justify="flex-end">
                <IconButton size="medium" disabled={props.cardId === 0} onClick={previousCard}><ChevronLeftIcon /></IconButton>
                { props.timeout && props.timeout != '0' ?
                    paused ?
                        <IconButton onClick={resumeTimer}><PlayCircleOutlineIcon /></IconButton> :
                        <IconButton onClick={pauseTimer}><PauseCircleOutlineIcon /></IconButton>
                    : null }
                <IconButton onClick={nextCard}><ChevronRightIcon /></IconButton>
            </Grid>
        </Grid>
        }
        { props.skipButtons === false ? null :
          <Grid container justify="center" spacing={2} style={{width: '50%'}}>
            <Grid item>
              <Typography variant="body2" color="textSecondary" onClick={skipForNowCB} style={{ cursor: 'pointer' }}>
                Skip for now
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary" onClick={skipForGoodCB} style={{ cursor: 'pointer' }}>
                Skip for good
              </Typography>
            </Grid>
          </Grid>
        }
        </Grid>
      </CardActions>
    </Card>
    </Fade>
    </>
  )
}