import React from 'react';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Typography,
    InputBase
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import clsx from "clsx";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import "./styles/newDesign.css";
import { svgIcons } from './svgIcons';
const useStyles = makeStyles((theme) => ({
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        position: "fixed",
        top: "100px",
        left: "200px",
        width: "350px !important",
        minHeight: "260px",
        zIndex: 105,
        background: "#ffffff",
        color: "#ffffff",
    },
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "10px",
        backgroundColor: "#E2E6E9 !important",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "5px",
        top: 20,
        left: 0,
        "& .MuiOutlinedInput-input": {
            padding: "12.5px 14px",
        },
        "& .MuiSelect-select:focus": {
            background: "transparent !important",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" :{
            borderColor: "#3174F5"
        }
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1",
        margin: "10px",
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    selectItem: {
        width: "315px",
        margin: "10px 0px",
        borderRadius: "999px",
    },
    createButton: {
        color: "#FFFFFF",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "999px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        width: "100px",
        padding: "6px 12px",
        textTransform: "capitalize",
        letterSpacing: "-0.078px !important",
        "&:hover": {
            color: '#2F3D5B',
        }
    },
}))
export default function CoordinateDialog(props) {
    const classes = useStyles();
    const [windowPosition, setWindowPosition] = React.useState({ x: 100, y: 400 });
    const [text, setText] = React.useState({ maxSize: "" })
    const [control, setControl] = React.useState({ positiveOnly: false, size: "medium", noTextTicks: false })

    const handleChange2 = (event) => {
        var old = { ...control, [event.target.name]: event.target.checked }
        if (event.target.name === "studentClone" && event.target.checked) {
            old.studentMove = false
        }
        if (event.target.name === "studentMove" && event.target.checked) {
            old.studentClone = false
        }
        setControl(old);
    };
    function handleDrag(e, ui) {
        if ((ui.y < 60 || ui.y > (window.innerHeight - 160)) || (ui.x < 50 || ui.x > window.innerWidth - 460)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        props.cb(null)
    }
    function create(e) {
        props.cb({ text: text, control: control })
    }
    function handleChangeText(e) {
        e.preventDefault()
        if (!e || !e.target.value) return 
        var val = e.target.value 
        if (e && e.target) {
            setText(p => { return { ...p, maxSize: val } })
        }
    }
    return (
        <Draggable
            handle="strong"
            onDrag={handleDrag}
            onStop={handleDragEnd}
            position={windowPosition}
            defaultClassName="draggable-color"
            defaultClassNameDragging="draggable2-color"
            defaultClassNameDragged="draggable3-color"
        >
            <div id="smallpalette" className={clsx(classes.draggableWindow, 'dragDiv')} style={{
                border: '5px solid ' + props.inkColor,
                // backgroundColor: '#ffffff44'
            }}>
                <div className={clsx(classes.draggableTop, 'dragDivmain')} style={{ width: "100%", backgroundColor: props.inkColor }}>
                    <strong className={clsx(classes.draggableTopHandle, 'justify-center')}>
                        <svg width="30" height="10" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: "0px", cursor: "move" }}>
                            {svgIcons.dots}
                        </svg>
                    </strong>
                </div>
                <div className={clsx(classes.cp, 'dragDivForm')} style={{ width: "90%", color: props.inkColor }}>
                    <InputBase
                        placeholder="Ticks (default: size dependent)"
                        className="inputTextFiled"
                        fullWidth
                        id="tileFact"
                        onChange={(e) => handleChangeText(e)}
                    />
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={control.size}
                        variant="outlined"
                        className= {classes.selectItem}
                        onChange={(e) => setControl(p => { return { ...p, size: e.target.value } })}
                    >
                        <MenuItem value={"small"}>Small</MenuItem>
                        <MenuItem value={"medium"}>Medium</MenuItem>
                        <MenuItem value={"large"}>Large</MenuItem>
                    </Select>
                    <div className="coOrdinateFormControl">
                    <FormControlLabel
                        control={<Checkbox checked={control.positiveOnly} onChange={handleChange2} name="positiveOnly" />}
                        label="Only positive quadrant"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={control.noTextTicks} onChange={handleChange2} name="noTextTicks" />}
                        label="No text on Ticks"
                    />
                    </div>
                    <div className='formBtns'>
                        <Typography
                            variant="contained"
                            size="small"
                            style={{marginRight: "24px", fontSize: "16px",}}
                            onClick={handleClose}
                            className='formCancel'
                        >
                            Close
                        </Typography>
                        
                        <Button
                            variant="contained"
                            size="small"
                            style={{backgroundColor:props.inkColor}}
                            className= {classes.createButton}
                            onClick={() => create()}
                        >
                            Create
                                        </Button>
                    </div>
                   
                </div>

            </div>

        </Draggable>

    );
}