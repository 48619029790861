import * as ib from './ibdata'
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Tooltip,
    Typography,
} from '@material-ui/core';
import * as Actions from "./store/actions"
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { v4 as uuid } from "uuid";
import ImageIcon from '@material-ui/icons/Image';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import UUIDValidate from 'uuid-validate';
import * as mylocalStorage from "./mylocalStorage"
import { Auth } from 'aws-amplify'

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.7rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 25,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    buttonTeach: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.9rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 25,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    dialogTitle: {
        textAlign: "center",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        color: "#3174F5",
    },
}))
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#EBBF23',
        color: '#3174F5',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);
export default function CreateSession(props) {
    const sessionDialog = useSelector((state) => state.sessionDialog);
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = useSelector((state) => state.user);
    const [joinCode, setJoinCode] = useState("");
    const [busy, setBusy] = useState(false);

    React.useEffect(() => {
        ReactGA.pageview('/home')
    })

    React.useEffect(() => {
        if (props && props.newBid) {
            const fakeEvt = { id: 'teach' }
            newBoard(fakeEvt, props.newBid)
        }
    }, [props])

    function newBoard(e, bid=null) {
        if (bid) {
            if (!UUIDValidate(bid)) {
                props.history.push("/invalid")
                return
            }
        }
        const id = bid ? bid : uuid()
        const pg1 = id + "-pgNum-1"
        var boardConfig = null
        var classr = null
        if (e.id === 'teach') {
            boardConfig = '{"multiBoardOption": true}'
            classr = id
            dispatch(Actions.setBoardConfig({ "multiBoardOption": true }))
        } else {
            dispatch(Actions.setBoardConfig({ "multiBoardOption": false }))
        }
        function findcreatelocalUser() {
            var luid = mylocalStorage.getItem('mystoreID');
            if (!luid) {
                luid = uuid()
                mylocalStorage.setItem('mystoreID', luid)
            }
            return luid
        }

        function createSession(uu) {
            if (uu) {
                var f = JSON.parse(boardConfig) 
                var r = {...uu, ...f}
                boardConfig = JSON.stringify(r)
            }
            mylocalStorage.setItem('IsStudent', false);
            ib.createSession(pg1, "unsaved", "somecontent", id, 1, null, boardConfig, classr).then((res) => {
                const redir = "/board/" + pg1
                props.history.push(redir);
                ReactGA.pageview('/createboard') 
                if (boardConfig) {
                    ib.createClassroom({ id: id, TeacherID: findcreatelocalUser(), name: "myclass", ttl: res.ttl })
                }
            })
        }

        if (!user) createSession(null) 
        else {
            var userInfo = ib.getUserInfo(user);
            ib.findCreateUserProfile(user.username, user.attributes.email,
                userInfo.avatar, userInfo.name, null, gotUser);
            //update the user's profile with what they entered
            function gotUser(u) {
                if (!u) {
                    createSession(null) 
                    return 
                }
                var vc = u.boardConfig ? JSON.parse(u.boardConfig) : {}
                var update = true 
                if (e.id === 'teach') {
                    if (vc['multiBoardOption']) update = false 
                    vc['multiBoardOption'] = true 
                } else {
                    if (!vc['multiBoardOption']) update = false 
                    vc['multiBoardOption'] = false 
                }
                if (update) {
                    u.boardConfig = JSON.stringify(vc)
                    ib.updateUserProfile(u).then((r)=> {
                        createSession(vc)
                    })
                } else {
                    createSession(vc) 
                }
            }
        }
    }

    function dialogClosed() {
        ReactGA.event({
            category: 'Entry',
            action: "closed" 
        });
        if (sessionDialog && sessionDialog.cb) sessionDialog.cb("closed", null)
        dispatch(Actions.setSessionDialog({ open: false }))
    }

    const buttons = [
        { 'name': 'Start Teaching', ex: classes.buttonTeach, tooltip: 'Create, assign, and live teach individual student boards. Upload a multipage PDF & automatically generate individual multipage workbooks - one for each student', icon: <PhotoLibraryIcon />, id: "teach" },
        { 'name': 'Start Collaborating', ex: classes.button, tooltip: 'Single board with multiple people', icon: <ImageIcon />, id: "collab" },
        { 'name': 'Manage Boards',ex: classes.button,  tooltip: 'View past saved boards', icon: <PermMediaIcon />, id: "manage" }
    ]
    function Clicked(o) {
        ReactGA.event({
            category: 'Entry',
            action: "first-"+o.id 
        });
        dispatch(Actions.setSessionDialog({ open: false }))
        if (o.id === "manage") {
            // Cleanup mylocalStorage of any stale sessions
            mylocalStorage.removeItem('manageBoardsSession');
            mylocalStorage.removeItem('manageBoardsClassroom');

            props.history.push("/manage")
            return
        }
        if (o) newBoard(o)
    }

    function updateJoinCode(e) {
        if (e && e.target) {
            const n = e.target.value;
            setJoinCode(p => n);
        }
    }

    function joinWithCode() {
        if (joinCode) {
            setBusy(true);
            //this to set the local user id through join code button
            Auth.currentUserInfo()
            .then((d) => {
                dispatch(Actions.setUser(d))
            })
            .catch(e => console.log('error: ', e))
            ib.getSessionByJoinCode(joinCode).then((res) => {
                if (res.data.queryByJoinCode.items.length > 0) {
                    const joinURL = "/join/" + res.data.queryByJoinCode.items[0].id;
                    setBusy(false);
                    try {
                        ReactGA.event({
                            category: 'User',
                            action: 'joinedWithCode'
                        });
                    } catch {}
                    props.history.push(joinURL);
                } else {
                    alert("Classroom not found. Please check your code.");
                    setBusy(false);
                }
            })
        }
    }

    function Busy(props) {
        return (
            <>
            { props.busy ?
                <Backdrop open={props.busy} style={{zIndex: 10}} onClick={(e) => { e.stopPropagation(); e.preventDefault() }}>
                    <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                        <CircularProgress />
                    </div>
                </Backdrop>
                : null }
            </>
        )
    }

    return (
        <div>
            <Dialog open={sessionDialog.open} maxWidth={"xs"} onClose={() => { dialogClosed() }} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title"
                    className={classes.dialogTitle}
                    disableTypography
                >
                    Welcome, what would you like to do?
                    </DialogTitle>
                <DialogContent>
                    {buttons.map((o) => (
                        <React.Fragment key={o.name} >
                            <LightTooltip title={o.tooltip} arrow placement="right" >

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    key={o.name}
                                    onClick={() => Clicked(o)}
                                    className={o.ex}
                                    startIcon={o.icon}
                                >
                                    {o.name}
                                </Button>
                            </LightTooltip>
                        </React.Fragment>
                    ))}
                    <React.Fragment>

                    <form onSubmit={joinWithCode} autoComplete="off" style={{ width: "auto" }}>
                        <Grid container direction="row" justify="space-evenly" alignItems="center">
                            <LightTooltip title="Join a classroom with a code." arrow placement="right" >
                            <Grid item xs={12} sm={7} style={{ textAlign: 'center' }}><input className="joinCodeInput" id="joinCode" onChange={updateJoinCode} placeholder="Enter Classroom Code"></input></Grid>
                            </LightTooltip>
                            <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={() => joinWithCode()}
                                disabled={joinCode === ""}
                                className={classes.button}
                                style={{width: '150px', margin: '0px'}}
                            >
                                Join Class
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                    </React.Fragment>
                    <div style={{textAlign: 'center', cursor: 'pointer'}}>
                    <Typography variant="caption" color="textSecondary" >
                        Using whiteboard.chat implies agreement to our
                         <span onClick={(e) => {props.history.push("/tos")}}> terms of service</span>
                         <span onClick={(e) => {props.history.push("/privacy")}}> & privacy policy</span>
                    </Typography>
                    </div>
                    <Busy busy={busy} />
                </DialogContent>
            </Dialog>
        </div >
    )
}
