import React from 'react';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    IconButton,
    FormControl,
    Switch,
    FormGroup,
    FormControlLabel,
    Checkbox,
    MenuItem,
    FormHelperText,
    Select,
    Radio,
    TextField,
    InputAdornment,
    Tooltip,
    Divider,
    Typography,
} from '@material-ui/core';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import {
    AmplifyAuthenticator, AmplifySignOut
} from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import * as ib from "./ibdata.js"
import ReactGA from 'react-ga';
import './auth.css'
import CustomAuthUI from './CustomAuthUI'
import CustomAuthNewUI from './CustomAuthNewUI'
import * as microsoftTeams from "@microsoft/teams-js";
import CloseIcon from '@material-ui/icons/Close';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import FeatureSelector from "./FeatureSelector.js"
import clsx from "clsx";
import SaveIcon from '@material-ui/icons/Save';
import * as mylocalStorage from "./mylocalStorage"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" :{
            borderColor: "#3174F5"
        }
    },
    inviteButton: {
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '999px',
        margin: "10px",
        padding: "6px 5px",
        color: "#ffffff",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
    customizeToolButton: {
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '999px',
        padding: "8px 20px",
        color: "#ffffff",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
    tooltip: {
        margin: "4px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
    customArrow: {
        color: "#1E2639",
    },
    signOutBtn: {
        display: "flex",
        flexDirection: "row",
        justifContent: "center",
        alignItems: "center",
        padding: "8px",
        boxSizing: "border-box",
        borderRadius: "999px",
        color: "white",
        margin: "8px 0",
        textTransform: "uppercase",
        fontFamily: "Lato",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "bold",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
    dialogContent: {
     overflowX: "hidden"
    },
}));
const green = "#7Bc3a6"
const red = "#fa683f"
function AuthDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authDialog = useSelector((state) => state.authDialog);
    const personalConfig = useSelector((state) => state.personalConfig);

    const user = useSelector((state) => state.user);
    const [userProfile, setUserProfile] = React.useState(null)
    const [loginState, setLoginState] = React.useState(null)
    const teacherR = useSelector((state) => state.teacher);
    const boardConfig = useSelector((state) => state.BoardConfig);
    const textAreaRef = React.useRef(null);
    const [JOINURL, setURL] = React.useState("")
    const [optIn, setOptIn] = React.useState(false)
    const [displayOptIn, setDisplayOptIn] = React.useState(true)
    const [MSTeamsContext, setMSTeamsContext] = React.useState(null)

    function handleFontChange(c) {
        const selectTool = c.target.value
        dispatch(Actions.setPersonalConfig({
            ...personalConfig,
            font: selectTool,
        }))

    }
    const handleChangePersonal = (event) => {
        const name = event.target.name
        ReactGA.event({
            category: 'Setting',
            action: name
        });
        dispatch(Actions.setPersonalConfig({
            ...personalConfig,
            [name]: event.target.checked,
        }))
    };
    function handlecopy(e) {
        if (!textAreaRef || !textAreaRef.current) return
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
    }
    const [state2, setState2] = React.useState({
        boardAuthUsersOnly: boardConfig.boardAuthUsersOnly,
        studentsCannotDrawOthers: boardConfig.studentsCannotDrawOthers,
        simpleDrawingTools: boardConfig.simpleDrawingTools,
        fourToolsStudent: boardConfig.fourToolsStudent,
        StudentWebcamVideo: boardConfig.StudentWebcamVideo,
        MultiLineText: boardConfig.MultiLineText,
        SpeechText: boardConfig.SpeechText,
        DisableImmersive: boardConfig.DisableImmersive,

        boardResolution: boardConfig.boardResolution ? boardConfig.boardResolution : "default",
        defaultTool: boardConfig.defaultTool ? boardConfig.defaultTool : "draw",
        zoom: boardConfig.zoom ? boardConfig.zoom : true
    });

    const handleChange2 = (event) => {
        const name = event.target.name
        ReactGA.event({
            category: 'Setting',
            action: name
        });
        if (boardConfig && boardConfig[name] !== event.target.checked) {
            //config change 
            if (props && props.sess) {
                ib.boardConfigSet(props.sess, name, event.target.checked)
            }
        }
        setState2({ ...state2, [name]: event.target.checked });
    };

    function handleToolChange(c) {
        const selectTool = c.target.value
        if (boardConfig && boardConfig.defaultTool !== selectTool) {
            dispatch(Actions.setBoardConfig({
                ...boardConfig,
                defaultTool: selectTool,
            }))
            //config change 
            if (props && props.sess) {
                ib.boardConfigSet(props.sess, "defaultTool", selectTool)
            }
        }
        setState2({ ...state2, "defaultTool": selectTool });
    }
    function handleResoltion(c) {
        const selectTool = c.target.value
        if (boardConfig && boardConfig.boardResolution !== selectTool) {
            dispatch(Actions.setBoardConfig({
                ...boardConfig,
                boardResolution: selectTool,
            }))
            //config change 
            if (props && props.sess) {
                ib.boardConfigSet(props.sess, "boardResolution", selectTool)
            }
        }
        setState2({ ...state2, "boardResolution": selectTool });
    }
    React.useEffect(() => {
        if (boardConfig) {
            setState2(p => {
                return ({
                    ...p, 
                    boardAuthUsersOnly: boardConfig.boardAuthUsersOnly ? true : false,
                    studentsCannotDrawOthers : boardConfig.studentsCannotDrawOthers ? true : false,
                    simpleDrawingTools: boardConfig.simpleDrawingTools ? true : false,
                    fourToolsStudent: boardConfig.fourToolsStudent ? true : false,
                    StudentWebcamVideo: boardConfig.StudentWebcamVideo ? true : false,
                    MultiLineText: boardConfig.MultiLineText ? true : false,
                    SpeechText: boardConfig.SpeechText ? true : false,
                    DisableImmersive: boardConfig.DisableImmersive ? true : false,
                    defaultTool: boardConfig.defaultTool ? boardConfig.defaultTool :  "draw",
                    boardResolution: boardConfig.boardResolution ? boardConfig.boardResolution : "default",
                })
            })
        }
    }, [boardConfig])
    React.useEffect(() => {
        if (authDialog.open) {
            mylocalStorage.setItem('redirectUrl', window.location.pathname);
            var jj = window.location.href.split("/board/")
            var url = jj[0] + "/cojoin/" + window.btoa(jj[1])
            setURL(url)
        }
    }, [authDialog.open]);

    React.useEffect(() => {
        var isStudent = mylocalStorage.getItem('isStudent')
        if (isStudent === 'true') {
            setDisplayOptIn(false)
        } else {
            setDisplayOptIn(true)
        }

        function updateUser(u) {
            function gotUser(u) {
                setUserProfile(u)
                if (u && u.boardConfig && teacherR) {
                    const jp = JSON.parse(u.boardConfig)
                    dispatch(Actions.setBoardConfig(jp))
                }
                if (u && u.personalConfig) {
                    const ppp = JSON.parse(u.personalConfig)
                    dispatch(Actions.setPersonalConfig(ppp))
                }
                //subscribe to this users' sessions 
                if (u.emailOptIn !== '') setOptIn(u.emailOptIn)

            }

            if (u && !userProfile) {
                var userInfo = ib.getUserInfo(u);
                ib.findCreateUserProfile(u.username, u.attributes.email,
                    userInfo.avatar, userInfo.name, mylocalStorage.getItem('redirectUrl'),
                    gotUser);
            } else {

            }
            dispatch(Actions.setUser(u))
        }
        Auth.currentAuthenticatedUser()
            .then(user => updateUser(user))
            .catch(() => console.log('No signed in user.'));
        Hub.listen('auth', data => {
            switch (data.payload.event) {
                case 'signIn':
                    if (authDialog.siCB) {
                        dispatch(Actions.openAuthDialog({ open: false }))
                        authDialog.siCB()
                    }
                    updateUser(data.payload.data);
                    setLoginState(null)
                    return
                case 'signOut':
                    mylocalStorage.removeItem('myname');
                    var backupId = mylocalStorage.getItem('backupLocalID');
                    if (backupId) {
                        mylocalStorage.setItem('mystoreID', backupId);
                    } else {
                        mylocalStorage.removeItem('mystoreID');
                    }
                    setLoginState('logOut')
                    updateUser(null);
                    mylocalStorage.clear();
                    window.location.href = "/"
                    return
                default:
                    return;
            }
        });
    }, [])

    React.useEffect(() => {
        if (window.insideMSTeams) {
            microsoftTeams.getContext((c) => {
                if (c) {
                    setMSTeamsContext(c);
                }
            })
        }
      }, []);

    const handleSave = () => {
        if (!userProfile) return
        var newstate = { ...state2 }
        userProfile.boardConfig = JSON.stringify(newstate)
        userProfile.personalConfig = JSON.stringify(personalConfig)
        if (userProfile.emailOptIn !== optIn) {
            userProfile.emailOptIn = optIn
            mylocalStorage.setItem('emailOptIn', optIn);
        }
        ib.updateUserProfile(userProfile)
        dispatch(Actions.setBoardConfig(newstate))

        dispatch(Actions.openAuthDialog({ open: false }))
    };

    const handleClose = () => {
        if (props && props.setShowAuth) props.setShowAuth(false)
        if (authDialog.cancelCB) authDialog.cancelCB()
        dispatch(Actions.openAuthDialog({ open: false }))
    };
     const resetSettings = () => {
        dispatch(Actions.setBoardConfig({
            boardAuthUsersOnly: false,
            studentsCannotDrawOthers: false,
            simpleDrawingTools: false,
            fourToolsStudent: false,
            StudentWebcamVideo: false,
            MultiLineText: false,
            SpeechText: false,
            DisableImmersive: false,
            boardResolution: "default",
            defaultTool: "draw"
        }))
        setState2({
            boardAuthUsersOnly: false,
            studentsCannotDrawOthers: false,
            simpleDrawingTools: false,
            fourToolsStudent: false,
            StudentWebcamVideo: false,
            MultiLineText: false,
            SpeechText: false,
            DisableImmersive: false,
            boardResolution:"default",
            defaultTool:"draw"
        })
        dispatch(Actions.setPersonalConfig({
            dblClickDisable: false,
            font: "Chewy",
            inkColor: false,
        }))
    }
    function profileClick() {
        dispatch(Actions.openAuthDialog({ open: true }))
    }
    if (props.noGear && user && authDialog.open) {
        dispatch(Actions.openAuthDialog({ open: false }))
    }

    function loginWithGoogle() {
        ReactGA.event({
            category: 'User',
            action: 'loginWithGoogle'
        });
        Auth.federatedSignIn({ provider: "Google" });
    }

    function loginWithMS() {
        ReactGA.event({
            category: 'User',
            action: 'loginWithMS'
        });
        Auth.federatedSignIn({ provider: "Microsoft" });
    }

    function loginWithFacebook() {
        ReactGA.event({
            category: 'User',
            action: 'loginWithFacebook'
        });
        Auth.federatedSignIn({ provider: "Facebook" });
    }
    const [ctI, setctI] = React.useState(false)
    function ctInvite() {
        setctI(true)
    }
    function resetPalette() {
        mylocalStorage.removeItem("palettePos")
        mylocalStorage.removeItem('palletOption')
        mylocalStorage.removeItem('colorPos')
        mylocalStorage.removeItem("timerLocation")
        dispatch(Actions.setPalette({
            open: false,
            openCol: false,
            ref: null
        }))
        window.location.reload()
    }
    const { boardAuthUsersOnly, studentsCannotDrawOthers, simpleDrawingTools, fourToolsStudent, StudentWebcamVideo, MultiLineText, DisableImmersive, SpeechText, zoom } = state2;

    var showGear = true
    if (props.noGear) showGear = false
    if (fourToolsStudent && !Boolean(teacherR)) {
        showGear = false
    }
    const [feature, setFeature] = React.useState({ open: false, cb: null })

    function FeatureSelectorClick() {
        dispatch(Actions.openAuthDialog({ open: false }))
        setFeature({ open: true, cb: done })
        function done(e) {
            setFeature({ open: false, cb: done })

        }
    }
    const color = user ? green : red
    return (
        <div className={classes.root}>
            {feature.open && <FeatureSelector {...feature} {...props} />}
            {showGear && (
                <Tooltip className={classes.tooltip}
                classes={{
                    tooltip: classes.customTooltip,
                    arrow: classes.customArrow
                }}
                title="Settings" arrow placement="top">
                    <IconButton id="settingsButton" onClick={profileClick} className='p-5 btnSqr'>
                    <SettingsIcon style={{ fontSize: '24px', fill: props.inkColor }} />
                </IconButton>
                </Tooltip>
            )}

            <Dialog open={authDialog.open} onClose={handleClose} aria-labelledby="form-dialog-title" className='signInDialog'>

                {(user && props.showSettings) ?
                <DialogTitle id="form-dialog-title" style={{padding: "0px"}}>
                    <div className='settingHeader header'>
                         <div style={{ margin: '0 60px 0px 22px'}}>
                            <span className='Content newContent'  style={{ width: Boolean(teacherR) === true ? 'auto' : '300px'}}>Settings for your boards</span>
                         </div>
                        <div className='dispFlexEnd posClose'>
                        <CloseIcon className='ndCloseIcon' style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%" }} onClick={() => { handleClose() }} />
                        </div>
                       
                    </div>
                </DialogTitle>
                    : null}

                
                <AmplifyAuthenticator usernameAlias="email">
                    <div slot="sign-in" style={{ textAlign: 'center' }}>
                        <CustomAuthNewUI handleClose={handleClose} loginState={loginState} loginWithMS={loginWithMS} loginWithFacebook={loginWithFacebook} loginWithGoogle={loginWithGoogle} />
                    </div>
                </AmplifyAuthenticator>
                {user && (
                    <>
                        {props.showSettings ? null : <DialogTitle id="form-dialog-title">Settings for your boards</DialogTitle>}

                        <DialogContent className={classes.dialogContent}>
                                    {Boolean(teacherR) && (
                                        <FormGroup>
                                             <FormControl  className={classes.formControl} variant="outlined" style={{ margin: '0px' ,paddingLeft:"0px" ,width:"480px"}}>
                                           <Typography style={{color:"#2F3D5B" ,fontSize:"14px",margin:"10px 0px 5px 0px",fontFamily: "Lato",fontStyle: "normal",fontWeight: "normal"}}>
                                           Default tool for the class
                                            </Typography>
                                            <Select
                                                className="formSelect"
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                style={{borderRadius:"999px",fontSize:"14px"}}
                                                value={state2.defaultTool}
                                                onChange={handleToolChange}
                                                IconComponent={UnfoldMoreIcon}
                                            > 
                                            <MenuItem value={"draw"}>Brush</MenuItem>
                                            <MenuItem value={"moveResize"}>Move & Resize</MenuItem>
                                            <MenuItem value={"selectAnimate"}>Select</MenuItem>
                                            </Select>
                                            </FormControl>
                                            <FormControl  className={classes.formControl} variant="outlined" style={{ margin: '0px' ,paddingLeft:"0px" ,width:"480px"}}>
                                            <Typography style={{ color: "#2F3D5B", fontSize: "14px", margin: "10px 0px 5px 0px",fontFamily: "Lato",fontStyle: "normal",fontWeight: "normal" }}>
                                                Default Screen size for class
                                            </Typography>
                                            <Select
                                                className="formSelect"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={state2.boardResolution}
                                                style={{borderRadius:"999px",fontSize:"14px"}}
                                                onChange={handleResoltion}
                                                IconComponent={UnfoldMoreIcon}
                                            >
                                                <MenuItem value={"default"}>Default Size (2000x1280)</MenuItem>
                                                <MenuItem value={"ipad"}>iPad size (1024x700)</MenuItem>
                                                <MenuItem value={"small"}>Small Size (1280x800)</MenuItem>
                                                <MenuItem value={"chromebook"}>Small Chromebook Size (1366x625)</MenuItem>
                                                <MenuItem value={"medium"}>Medium Size (1440x900)</MenuItem>
                                                <MenuItem value={"a4"}>A4 Size (750x900)</MenuItem>
                                            </Select>
                                            </FormControl>
                                            <FormControl  className={classes.formControl} variant="outlined" style={{ margin: '0px' ,paddingLeft:"0px" ,width:"480px"}}>
                                            <Typography style={{color:"#2F3D5B" ,fontSize:"14px", margin: "10px 0px 5px 0px",fontFamily: "Lato",fontStyle: "normal",fontWeight: "normal"}}>
                                            Font for text box
                                            </Typography>
                                            <Select
                                                className="formSelect"
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                style={{borderRadius:"999px",fontSize:"14px"}}
                                                value={personalConfig.font}
                                                onChange={handleFontChange}
                                                IconComponent={UnfoldMoreIcon}
                                            >
                                                <MenuItem value={"Chewy"}>Chewy</MenuItem>
                                                <MenuItem value={"Roboto"}>Roboto</MenuItem>
                                                <MenuItem value={"Poppins"}>Poppins</MenuItem>
                                                <MenuItem value={"Courier"}>Courier</MenuItem>
                                            <MenuItem value={"KgTeacherHelpers"}>KgTeacherHelpers</MenuItem>
                                            <MenuItem value={"OpenDyslexic"}>OpenDyslexic</MenuItem>
                                            <MenuItem value={"Lexend"}>Lexend</MenuItem>
                                            <MenuItem value={"Sassoon"}>Sassoon</MenuItem>
                                            <MenuItem value={"Comic Sans"}>Comic Sans</MenuItem>

                                            </Select>
                                            </FormControl>
                                            <div className = "formControlLabel">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                className='lblswt'
                                                label="Zoom On"
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={zoom} onChange={handleChange2} name="zoom" />}
                                                />
                                                </div>

                                            <Divider 
                                            style={{ margin:"20px -24px",alignSelf: "stretch"}}/>
                                            <div className="customiseTools">
                                            <Typography 
                                            className="toolsTypography"
                                            style={{ color: "#2F3D5B", fontSize: "14px",textAlign:"center", color:"#2F3D5B",fontFamily: "Lato",fontStyle: "normal",fontWeight: "normal"}}>
                                                Select tools for students
                                            </Typography>
                                            <Button 
                                            className={classes.customizeToolButton} style={{ backgroundColor: props.inkColor }} onClick={FeatureSelectorClick}
                                            variant="contained">Customize tools</Button>
                                            </div>
                                            <FormControl  className={classes.formControl} variant="outlined" style={{ margin: '0px' ,paddingLeft:"0px" ,width:"480px"}}>
                                            <div className = "formControl">
                                            <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Students see simple tools  "
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={simpleDrawingTools} onChange={handleChange2} name="simpleDrawingTools" />}
                                            />
                                            </div>
                                            <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Students see very simple tools - draw, erase, move, text  "
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={fourToolsStudent} onChange={handleChange2} name="fourToolsStudent" />}
                                            />
                                            </div>
                                            <Divider 
                                            style={{ margin:"20px -24px"}}/>
                                            <div className = "formControlLabel form_container">
                                                <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Only logged in users can join board"
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={boardAuthUsersOnly} onChange={handleChange2} name="boardAuthUsersOnly" />}
                                            />
                                            </div>
                                            <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Students can only draw on their individual or group boards "
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={studentsCannotDrawOthers} onChange={handleChange2} name="studentsCannotDrawOthers" />}
                                            />
                                            </div>
                                            <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Enable Webcam Video Recording "
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={StudentWebcamVideo} onChange={handleChange2} name="StudentWebcamVideo" />}
                                            />
                                            </div>
                                             <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Enable Enter in textbox "
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={MultiLineText} onChange={handleChange2} name="MultiLineText" />}
                                            />
                                            </div>
                                             <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Enable Speech to Text"
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={SpeechText} onChange={handleChange2} name="SpeechText" />}
                                            />
                                            </div>
                                             <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Disable Immersive Reader"
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={DisableImmersive} onChange={handleChange2} name="DisableImmersive" />}
                                            />
                                            </div>
                                            <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Pen color and board color match"
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={personalConfig.inkColor} onChange={handleChangePersonal} name="inkColor" />}
                                            />
                                            </div>
                                             <div className = "formControlLabel form_container">
                                            <FormControlLabel
                                                style={{marginLeft: "0px"}}
                                                label="Disable right and double click"
                                                labelPlacement="start"
                                                control={<Switch size="small" checked={personalConfig.dblClickDisable} onChange={handleChangePersonal} name="dblClickDisable" />}
                                            />
                                            </div>
                                        </div>
                                             </FormControl>
                                            {!ctI && <Button className={classes.inviteButton} style={{ backgroundColor: props.inkColor }} onClick={ctInvite}>Show CoTeacher Invite</Button>}
                                            {ctI && (
                                                <TextField
                                                    id="start-adornment"
                                                    inputRef={textAreaRef}
                                                    className='mr-10'
                                                    helperText="Send this link to invite coteacher"

                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment:
                                                            <InputAdornment position="start">
                                                                <Tooltip title='Copy Invite for coTeacher'>
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handlecopy}
                                                                    >
                                                                        <FileCopyOutlinedIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>,
                                                    }}
                                                    value={JOINURL}
                                                />
                                            )}
                                            <Button className={clsx(classes.inviteButton, 'mb-10')} style={{ backgroundColor: props.inkColor }} onClick={resetPalette}>Reset Palette Position</Button>
                                            {displayOptIn
                                                ?
                                                <>
                                                    <FormControl  className={classes.formControl} variant="outlined" style={{ margin: '0px' ,paddingLeft:"0px" ,width:"480px"}}>
                                                <div className="formControl">
                                                    <div className = "formControlLabel form_container">
                                                    <FormControlLabel
                                                        style={{marginLeft: "0px"}}
                                                        label="Send me feature announcements via email"
                                                        labelPlacement="start"
                                                        control={<Switch size="small" checked={optIn} onChange={() => { setOptIn(!optIn) }} name="inkColor"/>} 
                                                    />
                                                    </div>
                                                    </div>
                                                    </FormControl>
                                                </>
                                                : ''
                                            }
                                        </FormGroup>
                                    )}
                        </DialogContent>
                                   <Divider />    
                                            <DialogActions style={{height:"120px"}}>
                                                {user && (
                                                    <React.Fragment>
                                                        <div className="footer">
                                                            <div style= {{display: "flex",flexDirection: "row", justifyContent: "space-around"}}>
                                                            <p className="useremail">{ib.getUserString(user.username, user.attributes.email)}</p>
                                                        {/* {Boolean(teacherR) && <p className="resetSettings" onClick={resetSettings}>Reset settings</p>} */}
                                                        {Boolean(teacherR) && <Button onClick={handleSave} className={clsx(classes.inviteButton, 'mb-10')} style={{ backgroundColor: props.inkColor }} >
                                                            Save </Button>}
                                                            </div>
                                                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                            <Button style={{ width: Boolean(teacherR) === true ? '320px' : '250px', backgroundColor: props.inkColor }} className={classes.signOutBtn} onClick={() => { Auth.signOut() }}>Sign Out</Button>
                                                        </div>
                                                        </div>
                                                    </React.Fragment>)}
                                            </DialogActions>
                                            
                    </>
                )}
            </Dialog>
        </div>
    );
}

export default AuthDialog