function processData(allText) {
    var allTextLines = allText.split(/\r\n|\n/);
    var headers = allTextLines[0].split(',');
    var lines = [];
    console.log("HEADING", headers)
    for (var i=1; i<allTextLines.length; i++) {
        var data = allTextLines[i].split(',');
        if (data.length === headers.length) {

            var tarr = [];
            var a= {}
            for (var j=0; j<headers.length; j++) {
                a[headers[j]] = data[j]
            }
            lines.push(a);

        }
    }
    console.log(lines)
    return lines 
    // alert(lines);
}
const _boards= `Timestamp,creatorEmail,name,Country,description,Grade,Subject,link
10/29/2020 5:12:08,sid@epiphani.io,Sid,USA,Testing in Prod,4,Scribbling 101,https://www.whiteboard.chat/copy/dba13b5d-6fa4-456b-83b3-4768aa862e7f-pgNum-1
10/29/2020 19:13:59,veronicalopez51651@gmail.com,Veronica Lopez,United States,Morning work,2,Language arts and math,https://www.whiteboard.chat/copy/268036e7-0d27-4852-aa31-9f50edb84a19-pgNum-1
10/30/2020 2:19:06,ljones@crec.org,Lindsey Jones,United States,Division on a number line. ,K-5,Math,https://www.whiteboard.chat/copy/cd4a442a-2b78-4e0b-94c4-c3867ea83b6a-pgNum-1
10/30/2020 5:06:22,stefanie.argy@detroitk12.org,Stefanie Argy,United States,Halloween Bingo,K-5,Holiday,https://www.whiteboard.chat/copy/5a0f138c-b6d8-4cc4-8c6c-812a46ffe5fb-pgNum-1
11/2/2020 6:36:30,dhurwitz@bostonpublicschools.org,Ms. Hurwitz,,dimensions of rectangular prisms,5,math,https://www.whiteboard.chat/copy/332784c8-140c-4e81-a863-0c0e1f53a510-pgNum-1
11/3/2020 1:43:13,ljones@crec.org,Lindsey Jones,United States,Multiply by 5,3,math ,https://www.whiteboard.chat/copy/9a91968b-16da-41d9-8ca3-c2a52a742311-pgNum-1
11/6/2020 11:54:22,ldbehm@capousd.org,Lindsey Behm,United States,2 Truths and a Lie Ice Breaker,8,United States History,https://www.whiteboard.chat/copy/Qn6QHVrwwTwaXaoM0YIId2c4ayM9ssDpmeBAMTqq1y7ZLtshDYRBIo9ohHFocNU2
11/6/2020 12:01:29,ldbehm@capousd.org,Lindsey Behm,,"This activity automatically distributes and assigns the 17 different role cards to the students.  The situation is a town hall meeting to discuss if student should use social media.  Email me, if you want more resources: LDBehm@capousd.org",8,8th Grade United States History,https://www.whiteboard.chat/copy/99R_6oRINJFE1D2iKzL80RhaFMgyd48gcrmbXe6T7L/Fu-WiblFyl/SrTssZwKgM
11/6/2020 12:04:15,ldbehm@capousd.org,Lindsey Behm,United States,General reading overview of the Enlightenment in the 13 English colonies.  The assignment also includes text dependant questions.,8,United States History,https://www.whiteboard.chat/copy/HIARs-yuae9MFZNI1Yl70urjkh7AVR3IGMDni5n142eByAism0MoIRBHbG5Qq7bj
11/6/2020 12:10:40,ldbehm@capousd.org,Lindsey Behm,United States,"Brief google slides presentation to review the French and Indian War, including the different perspectives of the war.    The teacher Google Slides presentation is linked at the top of the board, they can keep that, or remove depending on the class.  Additionally, students type the information in, similar to ""taking notes"" then move the pictures/maps to match the information.  Provides a general overview and explanation of new taxes to the colonies.",8,United States history,https://www.whiteboard.chat/copy/YZ6LxskIdtmQDNUFHBK_vahRXvbus07ATQC9P7TzPPfHMjCSmAoV57lC1x/8Ju5V
11/8/2020 4:01:58,elliemader82@yahoo.com,GABRIELLE and Troy MADER,United States,Word Problems with 1 digit times 3 digit problems using Area Model,4th,Math,https://www.whiteboard.chat/copy/zvH_Qg2UoqYY-evMR4B9LYiQ0_cLmPX2ru1ZO64O7SWzTf9PaoVTfhtznfjSR20x
11/9/2020 3:11:04,frasier@sos-dcr.cz,Andrea Frasier,Czech Republic,Future Forms practice ,High School,English as a Foreing Language,https://www.whiteboard.chat/copy/b1HASFpvFCdzsCzPmwgeFG_2fUdbUJInFKRYnWlVjgW0AGQyWNBfcFZVH5sP2bKu
11/9/2020 5:15:04,beckyabrand@gmail.com,Becky,,100 Chart Thanksgiving Mystery Picture - please note - I did not make the original board.  This came from TPT as a free download from Freedom Fernandez https://www.teacherspayteachers.com/Product/100s-Chart-Mystery-Picture-Thanksgiving-Turkey-4179942,1,all,https://www.whiteboard.chat/copy/ngs51yFbu79tJRZh-Bl9rS54q2_s-NDLzKay5StDgD-xDiwqWlUHiuHhONYs4hmW
11/10/2020 6:53:08,jennalind88@gmail.com,Jennifer Lindberg Dower,United States,Using counting on a number line to subtract,"3, 2",math,https://www.whiteboard.chat/copy/ItOKWxGX-gaQIJXGoefTOCRHfn_3J1pR1Ui0ySCpM5VTV2f8IbU_gHet9vnVC2kR
11/10/2020 14:21:36,satishj@datatemplate.in,satish,,testing ,1,test,https://www.asia.whiteboard.chat/copy/i7_jXTD9cz-S7xof50FFsDlBcowIf6NdBQvlegx2vDvPGM3rteSzVwMP87hqH8q7
11/10/2020 19:55:46,frasier@sos-dcr.cz,Andrea Frasier,Czech Republic,United Kingdom Webquest,12th grade,English as a foreign language,https://www.whiteboard.chat/copy/ZIDE_OKhk8zCoxAZiIqEnrZkcKimG-yGnllECMfxLwmWO6N8GBVjEeBmvYwHzjZr
11/11/2020 10:59:52,satishj@datatemplate.in,Clear Frida,local host publish,,2,history, https://www.whiteboard.chat/copy/Pu1znp25X-4xACBAvd_Q5--Cd7MVl_cIhW-AB7LA6nhapODhjXo3J5eVeu1IGMDU
11/12/2020 7:17:18,betsydoyle2003@gmail.com,Elizabeth Doyle,United States,"Partitioning arrays",4,Math,https://www.whiteboard.chat/copy/La-ia7Q5F-a7FmduZGYum9CkrvZjIMLOWbNJFnoRsDD0pcfKoCi91YI1pRKbr15e
11/12/2020 7:18:48,betsydoyle2003@gmail.com,Elizabeth Doyle,United States,Labeling and drawing arrays,4,Math,https://www.whiteboard.chat/copy/O5WK6xsIM6hQrxOh4tFfR78W906NRMzpFZmlwoRcVZb2zgQCV25Q34t50AGDXJaZ
11/12/2020 7:22:16,betsydoyle2003@gmail.com,Elizabeth Doyle,United States,"Circle Forms of numbers",4,math,https://www.whiteboard.chat/copy/Tca6j8wiSeOgt9Rt1LHhtAWPIUolHfnXq7CxVyCGAhkhr3Uac5_RMGa3V9WrqLgx%     
`;

const boards1 = `Timestamp,creatorEmail,name,Country,title,description,Grade,Subject,link
10/29/2020 19:13:59,veronicalopez51651@gmail.com,Veronica Lopez,United States,Veronica Lopez,Morning work,2,Language arts and math,https://www.whiteboard.chat/copy/LyITBUzhFOGEN0Ca0Ek54E3loLH61tYToThTwrT7rzlmpYzYVbP5myTG4Pp5dWOn
10/30/2020 2:19:06,ljones@crec.org,Lindsey Jones,United States,Division on a number line,Division on a number line.,K-5,Math,https://www.whiteboard.chat/copy/sKJVZwTYhwARhH5yGhDu4_9FLbvUZ9XQCGEsAqWLfjQxOUQUAiUWZQukhFzTYMpJ
11/2/2020 6:36:30,dhurwitz@bostonpublicschools.org,Ms. Hurwitz,,Hurwitz - 11.2,dimensions of rectangular prisms,5,math,https://www.whiteboard.chat/copy/XjAVdWvuzmNq6dg63o0_Uwu0jKq0mcVwz06A0eMYqy-6Kmjc9RvZbApx0YE8_ATT
11/3/2020 1:43:13,ljones@crec.org,Lindsey Jones,United States,Multiply by 5s,Multiply by 5,3,math ,https://www.whiteboard.chat/copy/1VK_71yVDYlhf-PfKWWngjQtRCX_2JFVmOXUwnBEHUw1vcc252KqNu-_s5KQVWoQ
11/6/2020 11:54:22,ldbehm@capousd.org,Lindsey Behm,United States,0/5 2 Truths and a Lie,2 Truths and a Lie Ice Breaker,8,United States History,https://www.whiteboard.chat/copy/NcTn_3TjOi_4yKrIttQpPb_MHpI1JCF_4_--dfbo7LuB-wuK1BwSzk4jfzWAsjhC
11/6/2020 12:04:15,ldbehm@capousd.org,Lindsey Behm,United States,*Master Enlightenment Reading,General reading overview of the Enlightenment in the 13 English colonies.  The assignment also includes text dependant questions.,8,United States History,https://www.whiteboard.chat/copy/8RzLqnsKxylMXLn-l9iejiaEDT3Gs5iVYr_5J83q7Rttp4amWASOLyYXpKEv24gS
11/8/2020 4:01:58,elliemader82@yahoo.com,GABRIELLE and Troy MADER,United States,Math,Word Problems with 1 digit times 3 digit problems using Area Model,4th,Math,https://www.whiteboard.chat/copy/CHlyQ9T8VpGi9SHS0dKg3yVBdQk7LIVVn-qPWTPKtmP-HaX4cWoe-ofAoXK27JEK
11/9/2020 3:11:04,frasier@sos-dcr.cz,Andrea Frasier,Czech Republic,Future Practice 2,Future Forms practice ,High School,English as a Foreing Language,https://www.whiteboard.chat/copy/utvlGCFeZCkN6m9fEi0018CNGlGOOIKrWgczKQiKD2aXg3iGQcyD5YZbGyCS6KzR
11/9/2020 5:15:04,beckyabrand@gmail.com,Becky,,Turkey Mystery Picture,100 Chart Thanksgiving Mystery Picture - please note - I did not make the original board.  This came from TPT as a free download from Freedom Fernandez https://www.teacherspayteachers.com/Product/100s-Chart-Mystery-Picture-Thanksgiving-Turkey-4179942,1,all,https://www.whiteboard.chat/copy/bNiAzKrtqMCJeM3PmFxz8fATWR7RPJyWGkIVPqtTRnydNyLVP5TLMx7-OuN6UcVz
11/10/2020 6:53:08,jennalind88@gmail.com,Jennifer Lindberg Dower,United States,1000 minus on number line,Using counting on a number line to subtract,"3 and 2",math,https://www.whiteboard.chat/copy/vzcKEm0g5smFXMaz_gE8sc1ap1TBjL9OYuq6r-XIxtlXNso-2AS17oYJzenjYOp_
11/10/2020 19:55:46,frasier@sos-dcr.cz,Andrea Frasier,Czech Republic,UK Geography and Facts,United Kingdom Webquest,12th grade,English as a foreign language,https://www.whiteboard.chat/copy/1SuyMRbzrM6D8MKDxgtow1cek6imUnQL_HtBLmtaHoE9_JkA3MtGeFu0uV1XXoM7
11/12/2020 7:17:18,betsydoyle2003@gmail.com,Elizabeth Doyle,United States,Distributive Property with Arrays,"Partitioning arrays",4,Math,https://www.whiteboard.chat/copy/V_ImVxW8KCpozvRCALmO-cZ5TQhOcEfArzttUsov0jkPoNPzvTjfune7ew0kBB-a
11/12/2020 7:18:48,betsydoyle2003@gmail.com,Elizabeth Doyle,United States,Drawing Arrays,Labeling and drawing arrays,4,Math,https://www.whiteboard.chat/copy/Z9dOrj-cGzpwy_XqaZWYPR8FxkuW0PG1PQ7pJ3KUt8AdLXr0p9CYCxEr_tLN7QzO
11/12/2020 7:22:16,betsydoyle2003@gmail.com,Elizabeth Doyle,United States,Number Forms,"Circle Forms of numbers",4,math,https://www.whiteboard.chat/copy/JyNOvsf3-cFXej86O_8alm0kSXImFjhQ6xenkQ8kEnsrMfvjBenm43FbsWIOkIRh
`;

const boards = `Timestamp,creatorEmail,name,Country,title,description,Grade,Subject,link
7/4/2021 8:32:11,emcc.cert@gmail.com,Jeyanthi Subramanian,Singapore,Making use of web resources,Making use of web resources for blended learning,Year 7 & 8,Math,https://www.asia.whiteboard.chat/copy/8yOhX_QKM_wZISouqUQYxehWh3kC7rs8mfHbbrImX1IaS3FWEjrb0zg7aj9cgxSo
`;

export function processBoards() {
    return processData(boards)
}
