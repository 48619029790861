import React from 'react';
// import window.googlePicker from "react-window.google-picker"

import UseScript from "./UseScript.js"
const CLIENT_ID = '937905831686-l2j5os8vcgshc9lggeoncbsfm9n4ispr.apps.googleusercontent.com';
const DEVELOPER_KEY = 'AIzaSyAo_Srf7D6PODgjHD3bPOjab-jDzwK9FvU'
const SCOPE = ['https://www.googleapis.com/auth/drive.readonly'];
const PROJECT = "937905831686"
var oauthToken
var pickerApiLoaded = false;
var pickerOpen = false
export default function PickFile(props) {

    React.useEffect(() => {
        UseScript("https://apis.google.com/js/client.js", "googleclient", null)
        UseScript("https://apis.google.com/js/api.js", "googleapi", loadPicker)
    }, [])

    function loadPicker() {
        window.gapi.load('auth', { 'callback': onAuthApiLoad });
        window.gapi.load('picker', { 'callback': onPickerApiLoad });
    }

    function onPickerApiLoad() {
        pickerApiLoaded = true;
        // createPicker();
    }
    function onAuthApiLoad() {
        window.gapi.auth.authorize(
            {
                'client_id': CLIENT_ID,
                'scope': SCOPE,
                'immediate': false
            },
            handleAuthResult);
    }
    function handleAuthResult(authResult) {
        if (authResult && !authResult.error) {
            oauthToken = authResult.access_token;
            createPicker();
        }
    }

    function createPicker() {
        if (pickerApiLoaded && oauthToken && !pickerOpen) {
            pickerOpen = true
            var view = new window.google.picker.View(window.google.picker.ViewId.DOCS);
            view.setMimeTypes("image/png,image/jpeg,image/jpg,application/pdf,application/vnd.google-apps.folder");
            var picker = new window.google.picker.PickerBuilder()
                .setAppId(PROJECT)
                .setOAuthToken(oauthToken)
                .addView(view)
                .setDeveloperKey(DEVELOPER_KEY)
                .setCallback(pickerCallback)
                .build();
            picker.setVisible(true);
        }
    }
    // A simple callback implementation.
    async function pickerCallback(data) {
        pickerOpen = false
        if (data.action === window.google.picker.Action.CANCEL) {
            if (props.cb) props.cb(null)
            return
        }
        if (data.action === window.google.picker.Action.PICKED) {
            var fileId = data.docs[0].id;
            // getDownloadUrl(fileId)
            var ff = await getFile(fileId)
            if (props.cb) props.cb(ff)
        }
    }

    const URL = 'https://www.googleapis.com/drive/v3/files';
    const getFile = async (fileId) => {
        const accessToken = oauthToken;
        const fetchOptions = { headers: { Authorization: `Bearer ${accessToken}` } };


        const {
            result: { name, mimeType }
        } = await window.gapi.client.request({
            'path': '/drive/v2/files/' + fileId,
            'params': { 'maxResults': '1000' },
        })

        //window.gapi.client.drive.files.get({ fileId, fields: FIELDS });

        const blob = await fetch(`${URL}/${fileId}?alt=media`, fetchOptions).then(res => res.blob());
        const fileOptions = {
            type: mimeType,
        };

        return new File([blob], name, fileOptions);
    };

    return (
     <>
     </>
    )
}