/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const subscribeToSessionObjects = /* GraphQL */ `
  subscription SubscribeToSessionObjects($SessionID: ID) {
    subscribeToSessionObjects(SessionID: $SessionID) {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const subscribeToSessionbyOwner = /* GraphQL */ `
  subscription SubscribeToSessionbyOwner($savedOwner: ID) {
    subscribeToSessionbyOwner(savedOwner: $savedOwner) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const subscribeToSessionDelbyOwner = /* GraphQL */ `
  subscription SubscribeToSessionDelbyOwner($savedOwner: ID) {
    subscribeToSessionDelbyOwner(savedOwner: $savedOwner) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const subscribeToSessionbyparentBoardId = /* GraphQL */ `
  subscription SubscribeToSessionbyparentBoardId($parentBoardID: ID) {
    subscribeToSessionbyparentBoardID(parentBoardID: $parentBoardID) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const subscribeToSessionDelbyparentBoardId = /* GraphQL */ `
  subscription SubscribeToSessionDelbyparentBoardId($parentBoardID: ID) {
    subscribeToSessionDelbyparentBoardID(parentBoardID: $parentBoardID) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const delSessionObject = /* GraphQL */ `
  subscription DelSessionObject($SessionID: ID) {
    delSessionObject(SessionID: $SessionID) {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const subscribeToSessionUsers = /* GraphQL */ `
  subscription SubscribeToSessionUsers($SessionID: ID) {
    subscribeToSessionUsers(SessionID: $SessionID) {
      id
      name
      createdAt
      updatedAt
      SessionID
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      content
      UserProfile
      ttl
      avatar
    }
  }
`;
export const subscribeToSession = /* GraphQL */ `
  subscription SubscribeToSession($id: ID) {
    subscribeToSession(id: $id) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const subscribeToDelSession = /* GraphQL */ `
  subscription SubscribeToDelSession($id: ID) {
    subscribeToDelSession(id: $id) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const subscribeToMbByParent = /* GraphQL */ `
  subscription SubscribeToMbByParent($parentBoardID: ID) {
    subscribeToMBByParent(parentBoardID: $parentBoardID) {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToMbByParentDel = /* GraphQL */ `
  subscription SubscribeToMbByParentDel($parentBoardID: ID) {
    subscribeToMBByParentDel(parentBoardID: $parentBoardID) {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const subscribeToSessionbyClassroom = /* GraphQL */ `
  subscription SubscribeToSessionbyClassroom($Classroom: ID) {
    subscribeToSessionbyClassroom(Classroom: $Classroom) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const subscribeToSessionDelbyClassroom = /* GraphQL */ `
  subscription SubscribeToSessionDelbyClassroom($Classroom: ID) {
    subscribeToSessionDelbyClassroom(Classroom: $Classroom) {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const subscribeToClassroomById = /* GraphQL */ `
  subscription SubscribeToClassroomById($id: ID) {
    subscribeToClassroomById(id: $id) {
      id
      name
      TeacherID
      content
      Teacher {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      Users {
        items {
          id
          content
          createdAt
          updatedAt
          CurrentSessionId
          CurrentUserId
          ClassroomID
          ttl
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      TeacherList
    }
  }
`;
export const subscribeToClassroomEvents = /* GraphQL */ `
  subscription SubscribeToClassroomEvents($Classroom: ID) {
    subscribeToClassroomEvents(Classroom: $Classroom) {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const subscribeToClassroomEventsDel = /* GraphQL */ `
  subscription SubscribeToClassroomEventsDel($Classroom: ID) {
    subscribeToClassroomEventsDel(Classroom: $Classroom) {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const subscribeToClassGroup = /* GraphQL */ `
  subscription SubscribeToClassGroup($Classroom: ID) {
    subscribeToClassGroup(Classroom: $Classroom) {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const subscribeToDelClassGroup = /* GraphQL */ `
  subscription SubscribeToDelClassGroup($Classroom: ID) {
    subscribeToDelClassGroup(Classroom: $Classroom) {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const subscribeToLocalUsersByClass = /* GraphQL */ `
  subscription SubscribeToLocalUsersByClass($ClassroomID: ID) {
    subscribeToLocalUsersByClass(ClassroomID: $ClassroomID) {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const subscribeToDelLocalUsersByClass = /* GraphQL */ `
  subscription SubscribeToDelLocalUsersByClass($ClassroomID: ID) {
    subscribeToDelLocalUsersByClass(ClassroomID: $ClassroomID) {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const subscribeToFoldersbyOwner = /* GraphQL */ `
  subscription SubscribeToFoldersbyOwner($userProfileId: ID) {
    subscribeToFoldersbyOwner(userProfileId: $userProfileId) {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const subscribeToDelFoldersbyOwner = /* GraphQL */ `
  subscription SubscribeToDelFoldersbyOwner($userProfileId: ID) {
    subscribeToDelFoldersbyOwner(userProfileId: $userProfileId) {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const subscribeToPalette = /* GraphQL */ `
  subscription SubscribeToPalette($owner: ID) {
    subscribeToPalette(owner: $owner) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const subscribeToDelPalette = /* GraphQL */ `
  subscription SubscribeToDelPalette($owner: ID) {
    subscribeToDelPalette(owner: $owner) {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const subscribeToGamePlay = /* GraphQL */ `
  subscription SubscribeToGamePlay($id: ID) {
    subscribeToGamePlay(id: $id) {
      id
      Session
      type
      content
      createdAt
      updatedAt
      version
      ttl
    }
  }
`;
export const subscribeToEngagementEvent = /* GraphQL */ `
  subscription SubscribeToEngagementEvent($Classroom: ID) {
    subscribeToEngagementEvent(Classroom: $Classroom) {
      id
      createdAt
      updatedAt
      luid
      name
      time
      ttl
      Content
      Classroom
    }
  }
`;
export const onCreateSession = /* GraphQL */ `
  subscription OnCreateSession {
    onCreateSession {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const onUpdateSession = /* GraphQL */ `
  subscription OnUpdateSession {
    onUpdateSession {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const onDeleteSession = /* GraphQL */ `
  subscription OnDeleteSession {
    onDeleteSession {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        items {
          id
          name
          content
          type
          objType
          ended
          createdAt
          updatedAt
          SessionID
          ttl
          animate
          CreatedBy
          DisableSync
          SVGObj
        }
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken
      }
      parentBoardID
      folderID
      Classroom
      CreatorLocalID
      MultiBoard {
        items {
          id
          parentBoardID
          childBoardID
          ttl
          createdAt
          updatedAt
        }
        nextToken
      }
      isGroup
      joinCode
    }
  }
`;
export const onCreateCommunityBoard = /* GraphQL */ `
  subscription OnCreateCommunityBoard {
    onCreateCommunityBoard {
      id
      name
      description
      creator
      creatorEmail
      CreatorLocalID
      Country
      Grade
      Subject
      boardLink
      content
      createdAt
      updatedAt
      title
    }
  }
`;
export const onUpdateCommunityBoard = /* GraphQL */ `
  subscription OnUpdateCommunityBoard {
    onUpdateCommunityBoard {
      id
      name
      description
      creator
      creatorEmail
      CreatorLocalID
      Country
      Grade
      Subject
      boardLink
      content
      createdAt
      updatedAt
      title
    }
  }
`;
export const onDeleteCommunityBoard = /* GraphQL */ `
  subscription OnDeleteCommunityBoard {
    onDeleteCommunityBoard {
      id
      name
      description
      creator
      creatorEmail
      CreatorLocalID
      Country
      Grade
      Subject
      boardLink
      content
      createdAt
      updatedAt
      title
    }
  }
`;
export const onCreateClassroom = /* GraphQL */ `
  subscription OnCreateClassroom {
    onCreateClassroom {
      id
      name
      TeacherID
      content
      Teacher {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      Users {
        items {
          id
          content
          createdAt
          updatedAt
          CurrentSessionId
          CurrentUserId
          ClassroomID
          ttl
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      TeacherList
    }
  }
`;
export const onUpdateClassroom = /* GraphQL */ `
  subscription OnUpdateClassroom {
    onUpdateClassroom {
      id
      name
      TeacherID
      content
      Teacher {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      Users {
        items {
          id
          content
          createdAt
          updatedAt
          CurrentSessionId
          CurrentUserId
          ClassroomID
          ttl
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      TeacherList
    }
  }
`;
export const onDeleteClassroom = /* GraphQL */ `
  subscription OnDeleteClassroom {
    onDeleteClassroom {
      id
      name
      TeacherID
      content
      Teacher {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      Users {
        items {
          id
          content
          createdAt
          updatedAt
          CurrentSessionId
          CurrentUserId
          ClassroomID
          ttl
        }
        nextToken
      }
      ttl
      createdAt
      updatedAt
      TeacherList
    }
  }
`;
export const onCreateGamePlay = /* GraphQL */ `
  subscription OnCreateGamePlay {
    onCreateGamePlay {
      id
      Session
      type
      content
      createdAt
      updatedAt
      version
      ttl
    }
  }
`;
export const onUpdateGamePlay = /* GraphQL */ `
  subscription OnUpdateGamePlay {
    onUpdateGamePlay {
      id
      Session
      type
      content
      createdAt
      updatedAt
      version
      ttl
    }
  }
`;
export const onDeleteGamePlay = /* GraphQL */ `
  subscription OnDeleteGamePlay {
    onDeleteGamePlay {
      id
      Session
      type
      content
      createdAt
      updatedAt
      version
      ttl
    }
  }
`;
export const onCreateClassGroup = /* GraphQL */ `
  subscription OnCreateClassGroup {
    onCreateClassGroup {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const onUpdateClassGroup = /* GraphQL */ `
  subscription OnUpdateClassGroup {
    onUpdateClassGroup {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const onDeleteClassGroup = /* GraphQL */ `
  subscription OnDeleteClassGroup {
    onDeleteClassGroup {
      id
      createdAt
      updatedAt
      ttl
      name
      content
      Classroom
      SessionID
    }
  }
`;
export const onCreateClassroomEvent = /* GraphQL */ `
  subscription OnCreateClassroomEvent {
    onCreateClassroomEvent {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const onUpdateClassroomEvent = /* GraphQL */ `
  subscription OnUpdateClassroomEvent {
    onUpdateClassroomEvent {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const onDeleteClassroomEvent = /* GraphQL */ `
  subscription OnDeleteClassroomEvent {
    onDeleteClassroomEvent {
      id
      createdAt
      updatedAt
      ttl
      type
      event
      For
      State
      Content
      Classroom
    }
  }
`;
export const onCreateEngagementEvent = /* GraphQL */ `
  subscription OnCreateEngagementEvent {
    onCreateEngagementEvent {
      id
      createdAt
      updatedAt
      luid
      name
      time
      ttl
      Content
      Classroom
    }
  }
`;
export const onUpdateEngagementEvent = /* GraphQL */ `
  subscription OnUpdateEngagementEvent {
    onUpdateEngagementEvent {
      id
      createdAt
      updatedAt
      luid
      name
      time
      ttl
      Content
      Classroom
    }
  }
`;
export const onDeleteEngagementEvent = /* GraphQL */ `
  subscription OnDeleteEngagementEvent {
    onDeleteEngagementEvent {
      id
      createdAt
      updatedAt
      luid
      name
      time
      ttl
      Content
      Classroom
    }
  }
`;
export const onCreateLocalUsers = /* GraphQL */ `
  subscription OnCreateLocalUsers {
    onCreateLocalUsers {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const onUpdateLocalUsers = /* GraphQL */ `
  subscription OnUpdateLocalUsers {
    onUpdateLocalUsers {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const onDeleteLocalUsers = /* GraphQL */ `
  subscription OnDeleteLocalUsers {
    onDeleteLocalUsers {
      id
      content
      createdAt
      updatedAt
      CurrentSession {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      CurrentSessionId
      CurrentUserId
      CurrentUser {
        id
        name
        createdAt
        updatedAt
        SessionID
        Session {
          id
          name
          content
          createdAt
          updatedAt
          ttl
          savedOwner
          parentID
          pageNumber
          boardConfig
          parentBoardID
          folderID
          Classroom
          CreatorLocalID
          isGroup
          joinCode
        }
        content
        UserProfile
        ttl
        avatar
      }
      ClassroomID
      ttl
    }
  }
`;
export const onCreateMultiBoard = /* GraphQL */ `
  subscription OnCreateMultiBoard {
    onCreateMultiBoard {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMultiBoard = /* GraphQL */ `
  subscription OnUpdateMultiBoard {
    onUpdateMultiBoard {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMultiBoard = /* GraphQL */ `
  subscription OnDeleteMultiBoard {
    onDeleteMultiBoard {
      id
      parentBoardID
      parentBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      childBoardID
      childBoard {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const onCreateObject = /* GraphQL */ `
  subscription OnCreateObject {
    onCreateObject {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const onUpdateObject = /* GraphQL */ `
  subscription OnUpdateObject {
    onUpdateObject {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const onDeleteObject = /* GraphQL */ `
  subscription OnDeleteObject {
    onDeleteObject {
      id
      name
      content
      type
      objType
      ended
      createdAt
      updatedAt
      SessionID
      ttl
      animate
      CreatedBy
      DisableSync
      SVGObj
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
    }
  }
`;
export const onCreateUserProfile = /* GraphQL */ `
  subscription OnCreateUserProfile {
    onCreateUserProfile {
      id
      name
      createdAt
      updatedAt
      email
      boardConfig
      avatar
      personalConfig
      emailOptIn
      paidPlan
    }
  }
`;
export const onUpdateUserProfile = /* GraphQL */ `
  subscription OnUpdateUserProfile {
    onUpdateUserProfile {
      id
      name
      createdAt
      updatedAt
      email
      boardConfig
      avatar
      personalConfig
      emailOptIn
      paidPlan
    }
  }
`;
export const onDeleteUserProfile = /* GraphQL */ `
  subscription OnDeleteUserProfile {
    onDeleteUserProfile {
      id
      name
      createdAt
      updatedAt
      email
      boardConfig
      avatar
      personalConfig
      emailOptIn
      paidPlan
    }
  }
`;
export const onCreatePersonalPalette = /* GraphQL */ `
  subscription OnCreatePersonalPalette {
    onCreatePersonalPalette {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const onUpdatePersonalPalette = /* GraphQL */ `
  subscription OnUpdatePersonalPalette {
    onUpdatePersonalPalette {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const onDeletePersonalPalette = /* GraphQL */ `
  subscription OnDeletePersonalPalette {
    onDeletePersonalPalette {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const onCreateCommunityPalette = /* GraphQL */ `
  subscription OnCreateCommunityPalette {
    onCreateCommunityPalette {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const onUpdateCommunityPalette = /* GraphQL */ `
  subscription OnUpdateCommunityPalette {
    onUpdateCommunityPalette {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const onDeleteCommunityPalette = /* GraphQL */ `
  subscription OnDeleteCommunityPalette {
    onDeleteCommunityPalette {
      id
      createdAt
      updatedAt
      path
      owner
      name
      content
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      createdAt
      updatedAt
      SessionID
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      content
      UserProfile
      ttl
      avatar
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      createdAt
      updatedAt
      SessionID
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      content
      UserProfile
      ttl
      avatar
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      createdAt
      updatedAt
      SessionID
      Session {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        folderID
        Classroom
        CreatorLocalID
        MultiBoard {
          nextToken
        }
        isGroup
        joinCode
      }
      content
      UserProfile
      ttl
      avatar
    }
  }
`;
export const onCreateErrorLog = /* GraphQL */ `
  subscription OnCreateErrorLog {
    onCreateErrorLog {
      id
      name
      createdAt
      updatedAt
      content
      ttl
    }
  }
`;
export const onUpdateErrorLog = /* GraphQL */ `
  subscription OnUpdateErrorLog {
    onUpdateErrorLog {
      id
      name
      createdAt
      updatedAt
      content
      ttl
    }
  }
`;
export const onDeleteErrorLog = /* GraphQL */ `
  subscription OnDeleteErrorLog {
    onDeleteErrorLog {
      id
      name
      createdAt
      updatedAt
      content
      ttl
    }
  }
`;
export const onCreateFolder = /* GraphQL */ `
  subscription OnCreateFolder {
    onCreateFolder {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const onUpdateFolder = /* GraphQL */ `
  subscription OnUpdateFolder {
    onUpdateFolder {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const onDeleteFolder = /* GraphQL */ `
  subscription OnDeleteFolder {
    onDeleteFolder {
      id
      name
      createdAt
      updatedAt
      userProfileId
      user {
        id
        name
        createdAt
        updatedAt
        email
        boardConfig
        avatar
        personalConfig
        emailOptIn
        paidPlan
      }
      parentFolderPath
    }
  }
`;
export const onCreateServiceNotice = /* GraphQL */ `
  subscription OnCreateServiceNotice {
    onCreateServiceNotice {
      id
      createdAt
      updatedAt
      noticeConfig
    }
  }
`;
export const onUpdateServiceNotice = /* GraphQL */ `
  subscription OnUpdateServiceNotice {
    onUpdateServiceNotice {
      id
      createdAt
      updatedAt
      noticeConfig
    }
  }
`;
export const onDeleteServiceNotice = /* GraphQL */ `
  subscription OnDeleteServiceNotice {
    onDeleteServiceNotice {
      id
      createdAt
      updatedAt
      noticeConfig
    }
  }
`;
export const onCreateLatencyEndPoints = /* GraphQL */ `
  subscription OnCreateLatencyEndPoints {
    onCreateLatencyEndPoints {
      id
      createdAt
      updatedAt
      region
      content
    }
  }
`;
export const onUpdateLatencyEndPoints = /* GraphQL */ `
  subscription OnUpdateLatencyEndPoints {
    onUpdateLatencyEndPoints {
      id
      createdAt
      updatedAt
      region
      content
    }
  }
`;
export const onDeleteLatencyEndPoints = /* GraphQL */ `
  subscription OnDeleteLatencyEndPoints {
    onDeleteLatencyEndPoints {
      id
      createdAt
      updatedAt
      region
      content
    }
  }
`;
export const onCreateLatencyCalculation = /* GraphQL */ `
  subscription OnCreateLatencyCalculation {
    onCreateLatencyCalculation {
      id
      createdAt
      updatedAt
      region
      clientRegion
      content
    }
  }
`;
export const onUpdateLatencyCalculation = /* GraphQL */ `
  subscription OnUpdateLatencyCalculation {
    onUpdateLatencyCalculation {
      id
      createdAt
      updatedAt
      region
      clientRegion
      content
    }
  }
`;
export const onDeleteLatencyCalculation = /* GraphQL */ `
  subscription OnDeleteLatencyCalculation {
    onDeleteLatencyCalculation {
      id
      createdAt
      updatedAt
      region
      clientRegion
      content
    }
  }
`;
