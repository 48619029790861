import React, { useState } from 'react'
import { cloudLocations } from './CloudLocations'
import { useSelector, useDispatch } from 'react-redux'
import * as ib from "./ibdata";
import { v4 as uuid } from "uuid";

var once = false
function PerfChecks(props) {
    const clientLoc = useSelector((state) => state.clientLoc)
    const [regionsByDist, setRegionsByDist] = useState({})
    const [doneRanging, setDoneRanging] = useState(false)
    const teacherR = useSelector((state) => state.teacher);

    const servers = ["https://aws-us-west-2.latency.whiteboard.chat/echo-client",
        "https://azure-westus2.latency.whiteboard.chat/echo-client",
        "https://gcp-us-west1.latency.whiteboard.chat/echo-client"]

    async function fetchWithTimeout(resource, options) {
        const { timeout = 500 } = options;

        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);

        const response = await fetch(resource, {
            ...options,
            signal: controller.signal
        });
        clearTimeout(id);

        return response;
    }

    function getLatencyData() {
        var copy = JSON.parse(JSON.stringify(clientLoc))
        delete copy['IPv4']
        const reqid = uuid()

        var reg = JSON.stringify(copy)
        var dt = new Date()
        for (var i in servers) {
            var s = servers[i]
            fetchWithTimeout(s, {
                timeout: 3000
            }).then(async (res) => {
                var done = new Date()
                let diff = done - dt
                //var r = await res.json()
                ib.createLatencyCalc({
                    region: res.url, clientRegion: reg, content: JSON.stringify({
                        id: reqid, latency: diff
                    })
                })
            }).catch((e) => {
                var done = new Date()
                let diff = done - dt
                try {
                    ib.createLatencyCalc({
                        region: "ERROR", clientRegion: e, content: JSON.stringify({
                            id: reqid, latency: diff
                        })
                    })
                } catch { }
                // console.log("ERROR ", e, diff)
            })

        }
    }

    function doLatency() {
        if (once) return
        once = true
        let ra = Math.floor(Math.random() * 3)
        // ra = 1
        if (ra === 1) {
            setTimeout(function () {
                ib.getLantencyConfig(done)
                function done(e) {
                    if (e && e.content && !e.content.disabled) {
                        getLatencyData()
                    }
                }
            }, 5000)
        }

    }
    React.useEffect(() => {
        if (!clientLoc) return
        if (Boolean(teacherR)) {
            doLatency()
        }
        // calcNearestLocByProvider(cloudLocations, clientLoc)
        // setDoneRanging(p => true)
    }, [clientLoc, teacherR])

    React.useEffect(() => {
        if (doneRanging) {
            const providers = Object.keys(regionsByDist);
            providers.forEach(p => {
                console.log("Nearest region for", p, "is", regionsByDist[p][0].region, regionsByDist[p][0].distance[1], "km")
            })
        }
    }, [doneRanging, regionsByDist])

    function calcNearestLocByProvider(cloudLocations, clientLoc) {
        const providers = Object.keys(cloudLocations);
        providers.forEach(p => {
            const t = calcNearestLocForProvider(p, cloudLocations, clientLoc)
            setRegionsByDist(old => { old[p] = t; return { ...old } })
        })
    }

    function calcNearestLocForProvider(prov, cloudLocations, clientLoc) {
        const regions = Object.keys(cloudLocations[prov])
        const distances = []
        console.log("For provider = ", prov)
        regions.forEach(r => {
            var dist = { region: r.toString(), distance: calcDistanceToRegion(cloudLocations[prov][r], clientLoc) }
            // distances.push({ r.toString(): calcDistanceToRegion(cloudLocations[prov][r], clientLoc) })
            distances.push(dist)
        })
        distances.sort((a, b) => {
            if (a.distance[0] > b.distance[0]) {
                return 1
            }
            if (a.distance[0] < b.distance[0]) {
                return -1
            }
            return 0
        })
        console.log("distances = ", distances);
        return distances
    }

    function calcDistanceToRegion(coords, clientLoc) {
        const clientCoords = [clientLoc.latitude, clientLoc.longitude]
        // const clientCoords = [ 51.5074, 0.1278 ] // London, just to test
        const radius = 6371; // Earth radius
        const lat1 = clientCoords[0] * Math.PI / 180;
        const lat2 = coords[0] * Math.PI / 180;
        const lon1 = clientCoords[1] * Math.PI / 180;
        const lon2 = coords[1] * Math.PI / 180;
        const deltaLat = lat2 - lat1;
        const deltaLon = lon2 - lon1;
        const a = Math.sin((deltaLat) / 2) * Math.sin((deltaLat) / 2) + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const x = deltaLon * Math.cos((lat1 + lat2) / 2);
        const y = deltaLat;
        const d1 = radius * c; // Haversine distance
        const d2 = radius * Math.sqrt(x * x + y * y); // Pythagoran distance
        return [d1, d2]
    }

    return (
        <></>
    )

}

export default PerfChecks;