import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
    ListItemAvatar, Avatar, DialogActions, Dialog,
    Checkbox, IconButton, DialogContent, Button, DialogTitle,
} from "@material-ui/core"
import * as ib from "./ibdata"
import CloseIcon from '@material-ui/icons/Close';
import * as mylocalStorage from "./mylocalStorage";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    rightSide: {
        marginLeft: 'auto',
    },
    small: {
        width: "24px",
        height: "24px",
    },
    drawer: {
        top: "9vh",
        height: "auto",
        maxHeight: "70vh",
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
    },
    listItemText: {
        fontSize: "0.65rem",
        fontFamily: 'Lato',
        fontWeight: 'bold',
        textTransform: "capitalize",
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#ffffff',
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
    },
    iconButton: {
        color: '#ffffff',
    },
    inviteButton: {
        backgroundColor: "#3174F5",
        marginLeft: 'auto',
        height: '2rem',
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '5px',
        marginRight: "5px",
        padding: "5px",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
});
var gParts = []
const LUSTRING = "chatParticipants"
export default function ChatSelector(props) {
    const classes = useStyles();
    const [parts, setParts] = React.useState([])
    function handleClose() {
        if (props.cb) props.cb(false)
    }
    function handleAgree() {
        var chatFilters = { classroom: props.classroom }
        var nn = {}
        var ff = [...gParts]
        for (var i = 0; i < ff.length; i++) {
            var f = ff[i]
            if (f.checked) {
                if (f.id === 0) {
                    //default check ignore everything else 
                    mylocalStorage.removeItem(LUSTRING)
                    if (props.cb) props.cb(true)
                    return
                }
                nn[f.localID] = {id: f.localID, name: f.name}
            }
        }
        if (Object.keys(nn).length > 0) {
            chatFilters["rec"] = nn
            var rr = JSON.stringify(chatFilters)
            mylocalStorage.setItem(LUSTRING, rr)
        }

        if (props.cb) props.cb(true)
    }

    React.useEffect(() => {
        if (props && props.classroom) {
            function gotLocalUser(dat) {
                var chatFilters = { classroom: props.classroom }
                var ll2 = mylocalStorage.getItem(LUSTRING)
                var rl = null
                if (ll2) {
                    rl = JSON.parse(ll2)
                    if (rl.classroom !== props.classroom) {
                        mylocalStorage.removeItem(LUSTRING)
                        chatFilters['rec'] = {}
                    } else {
                        chatFilters['rec'] = rl.rec
                    }
                }
                var ff = [...gParts]
                var somefound = false
                for (var i = 0; i < dat.length; i++) {
                    var d = dat[i]
                    var ll = d.id.split("-CL-")
                    if (!d.CurrentUser) continue
                    var cc = JSON.parse(d.CurrentUser.content)
                    var luid = ll[0]
                    var indx = ff.findIndex(x => x.id === d.id)
                    if (indx !== -1) ff.splice(indx, 1)
                    var check = false
                    if (chatFilters.rec && chatFilters.rec[luid]) {
                        check = true
                        somefound = true
                    }
                    ff.push({
                        name: d.CurrentUser.name,
                        avatar: d.CurrentUser.avatar,
                        id: d.id,
                        localID: luid,
                        color: cc.color,
                        checked: check,
                    })
                }
                var defIndex = ff.findIndex(x => x.id === 0)
                if (defIndex !== -1) ff.splice(defIndex, 1)

                ff.sort((a, b) => {
                    const upb = b.name.toUpperCase();
                    const upa = a.name.toUpperCase();

                    if (upb < upa) return 1
                    if (upb > upa) return -1
                    return 0
                })
                ff.splice(0, 0, {
                    name: "default",
                    avatar: "default",
                    id: 0,
                    localID: 0,
                    color: "#3174F5",
                    checked: !somefound,
                })

                setParts(ff)
                gParts = ff
            }

            gParts = []
            if (props.classroom) {
                gParts = []
                ib.listLocalUsersByClass(props.classroom, gotLocalUser, null)
            }

        }

    }, [props])

    function handleCheck(a) {
        var ff = [...parts]
        var found = null
        var indx = ff.findIndex(x => x.id === a.id)
        if (indx !== -1) found = ff[indx]
        if (indx === 0) {
            ff.forEach((fff) => {
                fff.checked = false
                return fff
            })
            found.checked = true

            setParts(ff)
            gParts = ff
            return
        }
        if (found) {
            if (found.checked) {
                found.checked = false
            } else {
                ff[0].checked = false
                found.checked = true
            }
        }
        setParts(ff)
        gParts = ff
    }
    function selectAll(e) {
        e.preventDefault()
        parts.map((a) => {
            handleCheck(a)
            return a
        })
    }
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="form-dialog-title">
                <span className= "chatHeader">
                    Select Students to Chat
                       </span>
                <CloseIcon className= "closingIcon" onClick={handleClose} />
            </DialogTitle>
            <DialogContent>
                <List dense className={classes.list}>
                    {parts.map((a) => {
                        { var inverseColor = ((a.color === "white") || (a.color.toUpperCase() === "#FFFFFF")) ? "black" : "white" }
                        return (
                            <ListItem key={a.id} button dense onClick={() => handleCheck(a)} className= "listItemStyle">
                                <Checkbox
                                    checked={a.checked}
                                    edge="start"
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': a.id }}
                                />
                                <ListItemAvatar>
                                    <Avatar
                                        className={classes.small}
                                        key={a.id}
                                        src={a.avatar}
                                        style={{ color: inverseColor, backgroundColor: a.color, }}
                                    >
                                        {a.name[0].toUpperCase()}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    classes={{ primary: classes.listItemText }}
                                    primary={a.name} secondary={a.email} />

                            </ListItem>
                        )
                    })}
                </List>
                <Button fullWidth onClick={selectAll} className= "toggleAllButton" style={{ backgroundColor: props.inkColor }}>Toggle All</Button>

            </DialogContent>
            <DialogActions className="dialogAcionButton">
                <Button onClick={handleAgree} color="primary" className= "createButton" style={{ backgroundColor: props.inkColor }} autoFocus>
                    Save
          </Button>
            </DialogActions>
        </Dialog >
    );
}