import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as mylocalStorage from "./mylocalStorage";

export default function ReDirectDialog(props) {
    const [diag, setDiag] = React.useState({ open: false, url: null })
    const dispatch = useDispatch();

    const handleAgree = () => {
        ReactGA.event({
            category: "Country Change",
            action: "User Moved " + diag.url
        });
        window.location.replace(diag.url)
        setDiag({ open: false, url: null })
    };
    React.useLayoutEffect(() => {
        getIP()
    }, [])

    const handleClose = () => {
        ReactGA.event({
            category: "Country Change",
            action: "User Cancelled Move "
        });
        setDiag({ open: false, url: null })
    };
    const ASIA = "https://www.asia.whiteboard.chat/"
    const redirs = {
        "IN": ASIA, "ID": ASIA, "MM": ASIA, "CN": ASIA, "BD": ASIA,
        "PK": ASIA, "SA": ASIA, "KR": ASIA, "SG": ASIA, "JP": ASIA,
        "TW": ASIA, "LK": ASIA, "AE": ASIA, "VN": ASIA, "PH": ASIA, 
        "HK": ASIA, "MY": ASIA, "TR": ASIA, "RU": ASIA, "OM": ASIA,
        "AU": ASIA, "NZ": ASIA, 
    }

    function getIP() {
        var luid = mylocalStorage.getItem('mystoreID');
        const loc = window.location.hostname

        fetch(`https://geolocation-db.com/json/`)
            .then(response => response.json())
            .then(data => {
                try {
                    if (data) {
                        dispatch(Actions.setClientLoc(data))
                    }
                } catch (e) {
                }
                if ('country_code' in data) {
                    const cc = data['country_code']
                    dispatch(Actions.setCountry(cc))
                    if (window.location.pathname !== "/") return
                    // Temporarily do not use asia.whiteboard if instance is
                    // being run within teams.
                    if (window.insideMSTeams === true) return
                    if (loc !== "www.whiteboard.chat" &&
                        loc !== "eb.epiphani.ai" && 
                        loc !== "whiteboard.chat") return
                    if (cc in redirs && redirs[cc]) {
                        if (!luid) {
                            var rep = loc.replace("www.", "")
                            rep = loc.replace("https://", "")

                            const idx = redirs[cc].indexOf(rep)
                            if (idx === -1) {
                                ReactGA.event({
                                    category: 'Country-' + cc,
                                    action: "send to asia " + loc + " -> " + redirs[cc]
                                });
                                mylocalStorage.removeItem('mystoreID');
                                window.location.replace(redirs[cc])
                            }
                        } else {
                            setDiag({ open: true, url: redirs[cc] })
                            ReactGA.event({
                                category: 'Country-' + cc,
                                action: "kept in US " + loc
                            });
                        }
                    } else {
                        ReactGA.event({
                            category: 'Country-' + cc,
                            action: "kept in US not in list " + loc
                        });
                    }
                }
                return null
            })
            .catch(e => console.log("ERROR", e))
    }
    return (
        <div>
            <Dialog
                open={diag.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We have a new faster website closer to you. {diag.url} You may need to re-register.
            Do you want to go there instead?
          </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
          </Button>
                    <Button onClick={handleAgree} color="primary" autoFocus>
                        Yes
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}