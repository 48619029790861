import React from 'react';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button,
    Grid,
    Paper,
} from '@material-ui/core';
import { useSelector } from 'react-redux'
import paper from 'paper';
import * as ib from './ibdata'
import * as dp from "./drawPaper.js"

const useStyles = makeStyles((theme) => ({
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        position: "fixed",
        top: "6vh",
        left: "5vh",
        width: "90vw",
        height: "78%",
        backgroundColor: "#ffffff",
        zIndex: 105,
        color: "#ffffff",
    },
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        paddingBottom: "5px",
        top: 20,
        left: 0,
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    root: {
        marginTop: '2vh',
        width: "100%",
        height: "90vh",
        overflowY: "auto",
    },
    babyCanvas: {
        height: "100%",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    paper2: {
        padding: theme.spacing(2),
        textAlign: 'center',
        minHeight: "500px",
        width: "100%",
    },
}))
const DEBOUNCE_TIME = 1000 //ms

export default function ShowCaseDialog(props) {
    const classes = useStyles();
    const [windowPosition, setWindowPosition] = React.useState({ x: 40, y: 60 });
    const showCase = useSelector((state) => state.showCase);
    const [canvasObj, setCanvasObj] = React.useState([])

    React.useEffect(() => {
        var co = [...canvasObj]
        var items = {}
        for (let i=0; i<co.length;i++) {
            var ii = co[i]
            items[i] = ii 
        }
        for (let i = 0; i < showCase.events.length; i++) {
            let e = showCase.events[i]
            let idx = co.findIndex(x => x.pageID === e.Content.state)
            if (idx !== -1) {
                delete items[i]
                continue  //already existing 
            }
            co.push({ pageID: e.Content.state, index: i })
        }
        
        var keyArr = Object.keys(items)
        keyArr.sort()
        for (let i=0; i < keyArr.length; i++) {
            co.splice(keyArr[i], 1) 
        }

        setTimeout(function () { setDebounceTimedOut(true) }, DEBOUNCE_TIME)
        setCanvasObj(co)
    }, [showCase])
 
    function handleDrag(e, ui) {
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }

    function Cell(cell) {

        React.useEffect(() => {
            const cname = "babyCanvas#" + cell.cell.index
            const canvas = document.getElementById(cname)
            const ct = canvas.getContext('2d')
            var scope = null

            scope = new paper.PaperScope();
            scope.setup(cname)

            var boardWidth = 2000
            var boardHeight = 1280
            if (props.sess && props.sess.boardConfig) {
                var j = JSON.parse(props.sess.boardConfig)
                if (j.boardResolution) {
                    var r = ib.boardResoltionType[j.boardResolution]
                    boardWidth = r.w
                    boardHeight = r.h
                }
            }
            var br = scope.view.bounds
            var zmx = br.width / boardWidth
            var zmy = br.height / boardHeight
            var zm = zmy
            if (zmx < zmy) zm = zmx
            scope.view.zoom = zm
            scope.view.center = new paper.Point(boardWidth / 2, boardHeight / 2)
            drawBoard(cell.cell.pageID)
            var subsP, subs;

            async function drawBoard(id) {
                var r = await ib.getSession(id)
                var sess = r.data.getSession
                if (!sess) return
                var mypage = id.split("-pgNum-")[1]

                var parent = null
                if (sess.id !== sess.parentBoardID) {
                    parent = sess.parentBoardID.split("-pgNum-")[0]
                    parent = parent + "-pgNum-" + mypage
                }
                // function delObject(dat) {
                //     dp.delObject(dat, scope)
                // }
                async function loadObj() {
                    // function objSubCBB(objSubs) {
                    //     subs = objSubs;
                    // }
                    // function objSubCBP(objSubs) {
                    //     subsP = objSubs;
                    // }
                    // ib.ObjectSubscribe({
                    //     "sessionID": id,
                    //     "cb": gotData,
                    //     "delCB": delObject,
                    //     "subCB": objSubCBB,
                    //     "doList": true
                    // });
                    var d = await ib.listObjectSync(id)
                    gotData(d)
                    function gotData(d) {
                        dp.gotData(scope, d)
                    }

                    if (parent) {
                        //     ib.ObjectSubscribe({
                        //         "sessionID": parent,
                        //         "cb": gotData,
                        //         "delCB": delObject,
                        //         "subCB": objSubCBP,
                        //         "doList": true
                        //     });
                        d = await ib.listObjectSync(parent)
                        gotData(d)
                    }
                }
                loadObj()
            }
            return (() => {
                if (subs) {
                    subs.forEach((s) => {
                        if (s) s.unsubscribe()
                    })
                }
                if (subsP) {
                    subsP.forEach((s) => {
                        if (s) s.unsubscribe()
                    })
                }
            })
        }, [cell])
        const idIS = "babyCanvas#" + cell.cell.index
        return (
            <canvas className={classes.babyCanvas} id={idIS} style={{ height: (60/(Math.ceil(Math.sqrt(canvasObj.length))))+"vh", width: (60/(Math.ceil(Math.sqrt(canvasObj.length))))+"vw" }} />

        )
    }
    const [debounceTimedOut, setDebounceTimedOut] = React.useState(false);


    return (
        <Draggable
            handle="strong"
            onDrag={handleDrag}
            onStop={handleDragEnd}
            position={windowPosition}
            defaultClassName="draggable221-color"
            defaultClassNameDragging="draggable2221-color"
            defaultClassNameDragged="draggable3221-color"
        >
            <div id="smallpalette" className={classes.draggableWindow} style={{
                border: '5px solid ' + props.inkColor,
                // backgroundColor: '#ffffff44'
            }}>
                {/* <div className={classes.draggableTop} style={{ width: "100%", backgroundColor: props.inkColor }}>
                    <strong className={classes.draggableTopHandle}>
                        <PaneIcon />
                    </strong>
                    <div className={classes.draggableTopCloseButton}>
                        <Button
                            className={classes.windowButton}
                            onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                    </div>
                </div> */}
                <div className={classes.root}>
                    <Grid container direction="column" alignItems="center" justify="center" spacing={1} wrap='wrap' style={{ height: '100%' }}>
                        {React.useMemo(() => {
                            return (
                                <>
                                    {debounceTimedOut && canvasObj.map((e) => {
                                        return (
                                            <Grid item xs={Math.ceil(12/(Math.ceil(Math.sqrt(canvasObj.length))))}>
                                                <Paper className={classes.paper} style={{height: '100%'}}>
                                                    <Cell cell={e} />
                                                </Paper>
                                            </Grid>
                                        )
                                    })}
                                </>
                            )
                        }
                            , [canvasObj, debounceTimedOut])}
                    </Grid>
                </div>

            </div>

        </Draggable>

    );
}