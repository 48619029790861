import React, { useState, useEffect } from "react";
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    IconButton,
    TextField,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as ib from "./ibdata.js"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RefreshIcon from '@material-ui/icons/Refresh';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import BoardTile from './BoardTile'
import './index.css';
import "./SessionTableFileExplorer.css";
import * as BoardUtil from './BoardUtility'
import CircularProgress from '@material-ui/core/CircularProgress';

// FileBrowser
import Moment from 'moment'
import FileBrowser from 'react-keyed-file-browser'
import ManageBoardMenus from "./ManageBoardMenus.js";
import * as mylocalStorage from "./mylocalStorage";

var gFoldersRead = false;

// FileBrowser
var gFiles = [];
var gFoldersNameToIdMap = {};
var gFoldersIdToNameMap = {};
var gFolders = {};
var gFolderNotEmptyMap = {};
var gSessionIdToKeyMap = {};
var gSessionKeyToIdMap = {}
var gClassSessionMap = {};
var gBoardOwners = {};
var gSessions = [];
var gDebounceTimer = null;
var gBookmarkedSessions = {};

export default function SavedBoards(props) {
    var [sessions, setSession] = useState([])
    const [tileMode, setTileMode] = useState(false);
    const [filteredData1, setfilteredData1] = useState(null);
    var sessionsToMap = filteredData1 == null ? sessions : filteredData1;
    const [folders, setFolders] = useState({});
    const [files, setFiles] = useState([]);
    const [allBoardOwners, setAllBoardOwners] = useState({});
    const [allClassSessions, setAllClassSessions] = useState({});
    const DEBOUNCE_TIME = 1000 //ms
    const [debounceTimedOut, setDebounceTimedOut] = React.useState(false);
    const [wait, setWait] = React.useState(false)

    let curColor = mylocalStorage.getItem("mycolor")

    if (!(curColor)) {
        curColor = "#8f3ee0";
    }

    const [boardScrollHt, setBoardScrollHt] = useState(450) 
    useEffect(() => {
        if (window && window.innerHeight){
            setBoardScrollHt(window.innerHeight - 200)
            //200px is the height of the other above content
        }
    }, [window])

    useEffect(() => {
         setTileMode(props.tileMode)
    }, [props.tileMode])

    useEffect(() => {
        if (props.boardSearchVal && sessions) {
            let regEx = props.boardSearchVal;
            //todo : need to update this once all the real time data comes in state
            // console.log(sessions.filter(d => d.name.match(regEx) || (d.subject !== null && d.subject.match(regEx)) || (d.grade !== null && d.grade.match(regEx))))
            // setfilteredData2(sessions.filter(d => d.name.match(regEx) || (d.subject !== null && d.subject.match(regEx)) || (d.grade !== null && d.grade.match(regEx))))
            setfilteredData1(sessions.filter(d => d.name.match(regEx)))
        }

    }, [props.boardSearchVal, sessions])

    function debounceTimerFired() {
        setDebounceTimedOut(true);
    }

    function clearDebounceTimer() {
        if (gDebounceTimer) {
            clearTimeout(gDebounceTimer);
        }
        gDebounceTimer = null;
        setDebounceTimedOut(false);
    }

    function setDebounceTimer() {
        if (gDebounceTimer) clearTimeout(gDebounceTimer);
        gDebounceTimer = setTimeout(debounceTimerFired, DEBOUNCE_TIME);
    }

    function localDateFormat(date) {
        const tstamp = new Date(date);
        if (tstamp.getTime() === (ib.NO_EXPIRY_TTL * 1000)) return "--"
        var options = {
            day: 'numeric',
            month: 'short',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        var tstr = tstamp.toLocaleString('en-US', options);
        return tstr
    }

    // Return ttl * 1000 for boards with expiry date
    // Return undefined for boards with NO_EXPIRY_TTL
    // undefined renders as "-" for expiry when undefined expiry is sent to react-keyed-file-browser
    function ttlToExpiry(ttl) {
        if (ttl === ib.NO_EXPIRY_TTL) {
            return undefined
        }
        return ttl * 1000
    }

    // FileBrowser
    function cleanupSlash(key) {
        let a = key;
        if (a[a.length - 1] === '/') {
            a = a.slice(0, -1);
        }

        return a;
    }

    function getParentFolder(key) {
        let a = cleanupSlash(key);
        return a.replace(/\/?[^/]+\/?$/, '');
    }

    function getNamePathToIdPath(a) {
        if (!(a && a !== "")) return "";
        if (a in gFoldersNameToIdMap) {
            return gFoldersNameToIdMap[a];
        } else {
            // console.log("getNamePathToIdPath: Could not find Id path:", a);
        }
        return "";
    }

    function isFolder(fname) {
        if ((typeof fname === "string") &&
            (fname) &&
            (fname[fname.length - 1] === "/")) {
            return true;
        }

        return false;
    }

    function getLastPath(path) {
        // this regex takes out the path and only gives the last folder/file name
        // For e.g: "hello/world/foo" would return just "foo"
        return path.replace(/\/?(.*)\//, "");
    }

    function getParentPath(path) {
        // this regex takes out the last path and returns only remaining path
        // For e.g: "hello/world/foo" would return just "hello/world"
        // as the parent path
        let a = cleanupSlash(path);
        return a.replace(/\/?[^/]+\/?$/, "");
    }

    function getMaxCount(sessArr) {
        let max = 0;
        for (let idx in sessArr) {
            let item = sessArr[idx];
            let us = item.name.match(/^unsaved\(([\d]+)\)$/);

            if (us) {
                let cur = parseInt(us[1]);
                if (cur > max) max = cur;
            }
        }

        return max;
    }

    function renameUnsaved(sessArr, max) {
        let cur = max + 1;

        for (let idx in sessArr) {
            let item = sessArr[idx];

            if (item.name === "unsaved") {
                item.name = `unsaved(${cur})`;
                ib.setName(item, undefined, true);
                cur += 1;
            }
        }

        return max;
    }

    function handleCreateFolder(key) {
        let p = getParentPath(key);
        let a = getNamePathToIdPath(p);
        let s = cleanupSlash(key).split("/");
        let n = s[s.length - 1];
        for (let idx in gFiles) {
            let file = gFiles[idx];
            if (file.key.substr(0, key.length) === key) {
                // Found existing folder/class with same name, skipping folder creation
                return;
            }
        }
        ib.createFolder(n, props.user, a)
    }

    function renameFileInternal(newKey, idArr, tmpId) {
        let path = getParentPath(newKey);
        let fname = getLastPath(newKey);

        if (idArr.length > 1) {
            // This is a student session being renamed.
            // Remove the classroom from path
            path = getParentPath(path)
        }

        if (path !== "") {
            path = gFoldersNameToIdMap[path];
            path = getLastPath(path);
        } else {
            // Remove the folderID field from the session object
            path = null;
        }

        let tmpRow = {
            id: tmpId,
            name: fname,
        }

        if (idArr.length > 1) {
            // This is a student session being renamed.
            tmpRow.id = idArr[1];
            // Reset the path
            // b/c student boards cannot be moved
            path = undefined;
        }

        ib.setName(tmpRow, path);
    }

    function handleRenameFolder(oldKey, newKey) {
        // Folders end in '/' & that needs to be removed
        let tmpOldKey = cleanupSlash(oldKey);
        let tmpNewKey = cleanupSlash(newKey);
        let tmpSessId = gSessionKeyToIdMap[tmpOldKey];

        const newFiles = []
        gFiles.forEach((file) => {
            if (file.key.substr(0, oldKey.length) === oldKey) {
                newFiles.push({
                    ...file,
                    key: file.key.replace(oldKey, newKey),
                })
            } else {
                newFiles.push(file)
            }
        })


        if (tmpSessId) {
            // This is a Classroom folder
            let index = gSessions.findIndex(x => x.id === tmpSessId);

            if (index !== -1) {
                let tmpSess = gSessions[index];
                renameFileInternal(tmpNewKey, [tmpSessId], tmpSessId);
                delete gSessionKeyToIdMap[tmpOldKey];
                gSessionIdToKeyMap[tmpSess.id] = tmpNewKey;
                gSessionKeyToIdMap[tmpNewKey] = tmpSess.id;
            }
            gFiles = newFiles;
            setFiles(gFiles);
            return;
        }

        let tmpOldKeyIdPath = gFoldersNameToIdMap[tmpOldKey];
        let fid = getLastPath(tmpOldKeyIdPath);
        let fname = getLastPath(tmpNewKey)
        let updF = { id: fid, name: fname };
        let oldPP = getParentPath(tmpOldKey);
        let newPP = getParentPath(tmpNewKey);

        if (oldPP !== newPP) {
            // Folder was moved to a different path
            if (oldPP) {
                delete gFoldersIdToNameMap[tmpOldKeyIdPath];
            }

            if (newPP) {
                let newPPIdPath = gFoldersNameToIdMap[newPP];
                let newIdPath = `${newPPIdPath}/${fid}`;
                gFoldersIdToNameMap[newIdPath] = tmpNewKey;
                gFoldersNameToIdMap[tmpNewKey] = newIdPath;
                updF['parentFolderPath'] = newPPIdPath;
            } else {
                gFoldersIdToNameMap[fid] = tmpNewKey;
                gFoldersNameToIdMap[tmpNewKey] = fid;
                updF['parentFolderPath'] = "";
            }
        } else {
            // Folder was renamed
            gFoldersNameToIdMap[tmpNewKey] = tmpOldKeyIdPath;
            gFoldersIdToNameMap[tmpOldKeyIdPath] = tmpNewKey;
        }

        delete gFoldersNameToIdMap[tmpOldKey];
        ib.updateFolder(updF);
        gFiles = newFiles;
        setFiles(gFiles);
    }

    function handleRenameFile(oldFile, newKey) {
        var idArr = oldFile.id.split("|");

        const newFiles = []
        gFiles.forEach((file) => {
            if (file.id === oldFile.id) {
                renameFileInternal(newKey, idArr, file.id);
                newFiles.push({
                    ...file,
                    key: newKey,
                    modified: +Moment(),
                })
            } else {
                newFiles.push(file)
            }
        })
        gFiles = newFiles;
        setFiles(gFiles);
    }

    function addAncestors(folderKey, fMap) {
        let pp = folderKey;

        while (pp !== "") {
            fMap[pp] = true;
            pp = getParentPath(pp);
        }
    }

    function doDeleteFolder(delFolders, doGqlOper) {
        const newFiles = [];
        const delList = [];
        const dfMap = {};

        gFiles.forEach((file) => {
            let delFile = false;
            for (let idx in delFolders) {
                if (file.key.substr(0, delFolders[idx].length) === delFolders[idx]) {
                    delFile = true;
                    break;
                }
            }
            if (!delFile) {
                newFiles.push(file)
            } else {
                let startingPoint = file.key;

                if (isFolder(file.key)) {
                    startingPoint = cleanupSlash(file.key);
                } else {
                    delList.push(file);
                    startingPoint = getParentPath(file.key);
                }
                addAncestors(startingPoint, dfMap);
            }
        })

        // Delete files in the folders
        for (let idx in delList) {
            let curRow = delList[idx];

            if (curRow.isStudent) {
                curRow = {id: curRow.studentSessId}
            }
            if (doGqlOper) deleteAction(curRow);
        }

        // Delete folders
        delFolders = Object.keys(dfMap);
        for (let idx in delFolders) {
            let namePath = cleanupSlash(delFolders[idx]);
            let tmpSessId = gSessionKeyToIdMap[namePath];

            if (tmpSessId) {
                // This is a classroom folder, delete it as a file.
                if (doGqlOper) deleteAction({ id: tmpSessId })
                delete gSessionKeyToIdMap[namePath];
                delete gSessionIdToKeyMap[tmpSessId];
            } else {
                let idPath = getNamePathToIdPath(namePath);
                let fid = getLastPath(idPath);
                delete gFoldersNameToIdMap[namePath];
                delete gFoldersIdToNameMap[idPath];
                delete gFolders[fid]
                if (doGqlOper) ib.deleteFolder(fid);
            }
        }

        if (gFoldersRead) {
            gFolders = { ...gFolders };
            setFolders(gFolders);
        }

        gFiles = newFiles;
        setFiles(gFiles);
    }

    function handleDeleteFolder(delFolders) {
        doDeleteFolder(delFolders, true);
    }

    function handleDeleteFile(delFiles) {
        const newFiles = [];
        const delList = [];
        gFiles.forEach((file) => {
            let delFile = false;
            for (let idx in delFiles) {
                if (file.id === delFiles[idx].id) {
                    delFile = true;
                    break;
                }
            }
            if (!delFile) {
                newFiles.push(file)
            } else {
                delList.push(file);
            }
        })

        for (let idx in delList) {
            let item = delList[idx];

            if (item.isStudent) {
                item = { id: item.studentSessId };
            }

            deleteAction(item);
        }
        gFiles = newFiles
        setFiles(gFiles);
    }

    function handleViewFile(viewFiles) {
        if (Array.isArray(viewFiles) && (viewFiles.length > 0)) {
            let tmpArr = viewFiles[0].id.split("|");
            let tmpId = (tmpArr.length === 1) ? tmpArr[0] : tmpArr[1]
            props.history.push("/board/" + tmpId);
            window.location.reload();
        }
    }

    function handleViewFolder(viewFolders) {
        if (!(Array.isArray(viewFolders) && (viewFolders.length > 0))) {
            return;
        }
        let oldKey = viewFolders[0];
        let tmpOldKey = cleanupSlash(oldKey);
        let tmpSessId = gSessionKeyToIdMap[tmpOldKey];
        if (tmpSessId) {
            props.history.push("/board/" + tmpSessId);
            window.location.reload();
        }
    }

    async function handleRefreshFile(refreshFiles) {
        setWait(true);
        if (Array.isArray(refreshFiles) && (refreshFiles.length > 0)) {
            let tmpArr = refreshFiles[0].id.split("|");
            let tmpId = (tmpArr.length === 1) ? tmpArr[0] : tmpArr[1];
            var newttl;
            var isUnlimited = isUnlimitedPlan();

            try {
                newttl = await ib.refreshBoard(tmpId, {}, isUnlimited);
            } catch(ee) {
                console.error("Error while refreshing board:", tmpId, ee);
                if (isUnlimited) {
                    alert("Could not turn off board expiry. Please try again.");
                } else {
                    alert("Could not refresh board. Please try agian.");
                }
                return;
            }
            for (let fIdx in gFiles) {
                let file = gFiles[fIdx];
                let refreshFile = false;
                for (let idx in refreshFiles) {
                    if (file.id === refreshFiles[idx].id) {
                        refreshFile = true;
                        break;
                    }
                }
                if (refreshFile) {
                    file.expire = newttl === ib.NO_EXPIRY_TTL ? undefined : newttl * 1000;
                    break;
                }
            }
        }
        setWait(false);
    }

    async function handleRefreshFolder(refreshFolders) {
        setWait(true);
        var urlMap = {};
        if (!(Array.isArray(refreshFolders) && (refreshFolders.length > 0))) {
            return;
        }
        let oldKey = refreshFolders[0];
        let tmpOldKey = cleanupSlash(oldKey);
        let tmpSessId = gSessionKeyToIdMap[tmpOldKey];
        let expireVal = ib.getTTL2Week() * 1000;
        var newClassTTL, newttl;
        var isUnlimited = isUnlimitedPlan();

        if (tmpSessId) {
            try {
                newClassTTL = await ib.refreshBoard(tmpSessId, urlMap, isUnlimited);
            } catch(ee) {
                console.error("Error while refreshing folder board:", tmpSessId, ee);
                if (isUnlimited) {
                    alert("Could not turn off board expiry. Please try again.");
                } else {
                    alert("Could not refresh board. Please try agian.");
                }
                return;
            }
        }

        for (let fIdx in gFiles) {
            let file = gFiles[fIdx];
            if (file.key.substr(0, oldKey.length) === oldKey) {
                let tmpArr = file.id.split("|");
                let tmpId;

                if (tmpArr.length === 1) {
                    tmpId = tmpArr[0]
                } else {
                    tmpId = tmpArr[1];
                    file.classExpire = newClassTTL === ib.NO_EXPIRY_TTL ? undefined : expireVal;
                }
                try {
                    newttl = await ib.refreshBoard(tmpId, urlMap, isUnlimited);
                } catch(ee) {
                    console.error("Error while refreshing folder board session:", tmpId, ee);
                    if (isUnlimited) {
                        alert("Could not turn off board expiry. Please try again.");
                    } else {
                        alert("Could not refresh board. Please try agian.");
                    }
                    return;
                }
                file.expire = newttl === ib.NO_EXPIRY_TTL ? undefined : expireVal;
            }
        }
        setWait(false);
    }

    function bookmarkLimitReached() {
        if (Object.keys(gBookmarkedSessions).length >= ib.MAX_SAVED_BOARDS_BASIC_PAID_PLAN) {
            return true;
        }

        return false;
    }

    async function handleBookmarkedFolder(bookmarkFolders) {
        // console.log("handleBookmarkedFolder:", bookmarkFolders);
        setWait(true);
        var urlMap = {};
        if (!(Array.isArray(bookmarkFolders) && (bookmarkFolders.length > 0))) {
            return;
        }
        let oldKey = bookmarkFolders[0];
        let tmpOldKey = cleanupSlash(oldKey);
        let tmpSessId = gSessionKeyToIdMap[tmpOldKey];
        let expireVal = ib.getTTL2Week() * 1000;
        var newClassTTL, newttl;

        if (bookmarkLimitReached()) {
            setWait(false);
            alert("Cannot turn off expiry for this Class. Plan limit reached.");
            // console.log("Turn off class expiry: Plan limit reached:", bookmarkFolders);
            return;
        }

        if (tmpSessId) {
            // console.log("updating class:", tmpSessId);
            try {
                newClassTTL = await ib.refreshBoard(tmpSessId, urlMap, true);
                gBookmarkedSessions[tmpSessId] = true;
            } catch(ee) {
                console.error("Error while refreshing folder board:", tmpSessId, ee);
                alert("Could not turn off board expiry. Please try again.");
                return;
            }
        }

        for (let fIdx in gFiles) {
            let file = gFiles[fIdx];
            if (file.key.substr(0, oldKey.length) === oldKey) {
                let tmpArr = file.id.split("|");
                let tmpId;

                if (tmpArr.length === 1) {
                    tmpId = tmpArr[0]
                } else {
                    tmpId = tmpArr[1];
                    file.classExpire = newClassTTL === ib.NO_EXPIRY_TTL ? undefined : expireVal;
                    file.isBookmarked = true;
                }
                try {
                    newttl = await ib.refreshBoard(tmpId, urlMap, true);
                } catch(ee) {
                    console.error("Error while refreshing folder board session:", tmpId, ee);
                    alert("Could not turn off board expiry. Please try again.");
                    return;
                }
                // console.log("bookmark folder file:", file);
                file.expire = newttl === ib.NO_EXPIRY_TTL ? undefined : expireVal;
            }
        }
        setWait(false);
    }

    async function handleBookmarkRemovedFolder(bookmarkFolders) {
        // console.log("handleBookmarkRemovedFolder:", bookmarkFolders);
        setWait(true);
        var urlMap = {};
        if (!(Array.isArray(bookmarkFolders) && (bookmarkFolders.length > 0))) {
            return;
        }
        let oldKey = bookmarkFolders[0];
        let tmpOldKey = cleanupSlash(oldKey);
        let tmpSessId = gSessionKeyToIdMap[tmpOldKey];
        let expireVal = ib.getTTL2Week() * 1000;
        var newClassTTL, newttl;

        if (tmpSessId) {
            // console.log("updating class:", tmpSessId);
            try {
                newClassTTL = await ib.refreshBoard(tmpSessId, urlMap, false);
                delete gBookmarkedSessions[tmpSessId];
            } catch(ee) {
                console.error("Error while refreshing folder board:", tmpSessId, ee);
                alert("Could not turn on board expiry. Please try again.");
                return;
            }
        }

        for (let fIdx in gFiles) {
            let file = gFiles[fIdx];
            if (file.key.substr(0, oldKey.length) === oldKey) {
                let tmpArr = file.id.split("|");
                let tmpId;

                if (tmpArr.length === 1) {
                    tmpId = tmpArr[0]
                } else {
                    tmpId = tmpArr[1];
                    file.classExpire = newClassTTL === ib.NO_EXPIRY_TTL ? undefined : expireVal;
                    file.isBookmarked = false;
                }
                try {
                    newttl = await ib.refreshBoard(tmpId, urlMap, false);
                } catch(ee) {
                    console.error("Error while refreshing folder board session:", tmpId, ee);
                    alert("Could not turn off board expiry. Please try again.");
                    return;
                }
                // console.log("bookmark folder file:", file);
                file.expire = newttl === ib.NO_EXPIRY_TTL ? undefined : expireVal;
            }
        }
        setWait(false);
    }

    async function handleBookmarkedFile(bookmarkFiles) {
        // console.log("handleBookmarkedFile:", bookmarkFiles);
        setWait(true);
        if (Array.isArray(bookmarkFiles) && (bookmarkFiles.length > 0)) {
            let tmpArr = bookmarkFiles[0].id.split("|");
            let tmpId = (tmpArr.length === 1) ? tmpArr[0] : tmpArr[1];

            if (bookmarkLimitReached()) {
                setWait(false);
                alert("Cannot turn off expiry for this board. Plan limit reached.");
                // console.log("Turn off File expiry: Plan limit reached:", bookmarkFiles);
                return;
            }

            try {
                var newttl = await ib.refreshBoard(tmpId, {}, true);
                gBookmarkedSessions[tmpId] = true;
            } catch(ee) {
                console.error("Error while refreshing board:", tmpId, ee);
                alert("Could not turn off board expiry. Please try again.");
                return;
            }
            for (let fIdx in gFiles) {
                let file = gFiles[fIdx];
                let refreshFile = false;
                for (let idx in bookmarkFiles) {
                    if (file.id === bookmarkFiles[idx].id) {
                        refreshFile = true;
                        break;
                    }
                }
                if (refreshFile) {
                    // console.log("newttl:", newttl);
                    file.expire = (newttl === ib.NO_EXPIRY_TTL) ? undefined : newttl * 1000;
                    file.isBookmarked = true;
                    break;
                }
            }
        }
        setWait(false);
    }

    async function handleBookmarkRemovedFile(bookmarkFiles) {
        // console.log("handleBookmarkRemovedFile:", bookmarkFiles);
        setWait(true);
        if (Array.isArray(bookmarkFiles) && (bookmarkFiles.length > 0)) {
            let tmpArr = bookmarkFiles[0].id.split("|");
            let tmpId = (tmpArr.length === 1) ? tmpArr[0] : tmpArr[1];
            try {
                var newttl = await ib.refreshBoard(tmpId, {}, false);
                delete gBookmarkedSessions[tmpId];
            } catch(ee) {
                console.error("Error while refreshing board:", tmpId, ee);
                alert("Could not turn on board expiry. Please try again.");
                return;
            }
            for (let fIdx in gFiles) {
                let file = gFiles[fIdx];
                let refreshFile = false;
                for (let idx in bookmarkFiles) {
                    if (file.id === bookmarkFiles[idx].id) {
                        refreshFile = true;
                        break;
                    }
                }
                if (refreshFile) {
                    file.expire = (newttl === ib.NO_EXPIRY_TTL) ? undefined : newttl * 1000;
                    file.isBookmarked = false;
                    break;
                }
            }
        }
        setWait(false);
    }

    function getFolderNamePath(folder) {
        let a = folder.parentFolderPath;
        let r = ""
        let i = "";
        let n = "";

        if (a && a !== "") {
            let s = a.split("/");

            for (let idx in s) {
                let p = gFolders[s[idx]];

                if (!p) {
                    // Move the folder to the the root folder
                    delete folder['user']
                    folder.parentFolderPath = ""
                    ib.updateFolder(folder)
                    return { idPath: folder.id, namePath: folder.name };
                }

                if (r === "") {
                    r = p.name;
                } else {
                    r = r + `/${p.name}`
                }
            }
        }

        if (r !== "") {
            i = `${folder.parentFolderPath}/${folder.id}`;
            n = `${r}/${folder.name}`
        } else {
            i = folder.id;
            n = folder.name;
        }

        return { idPath: i, namePath: n };
    }

    function cleanupBoardName(name, count) {
        return name.replace(/[/\\]/g, "-");
    }

    function getEnableBookmarks() {
        if (!props.userObj) return false;

        if (props.userObj.paidPlan && (props.userObj.paidPlan.indexOf("basic") >= 0)) {
            // This user is on the basic paid plan.
            // They can turn off expiry on a file/class folder.
            return true;
        }

        return false;
    }

    function isUnlimitedPlan() {
        if (!props.userObj) return false;

        if (props.userObj.paidPlan && (props.userObj.paidPlan.indexOf("gold") >= 0)) {
            // This user is on the gold paid plan.
            // They can turn of expiry on a file/class folder.
            return true;
        }

        return false;
    }

    function getClassSessObjInternal(sessArr, tmpSess, count) {
        let tmpArr = [];
        var b = cleanupBoardName(tmpSess.name, count);
        var className;

        if (tmpSess.folderID && tmpSess.folderID !== "") {
            gFolderNotEmptyMap[tmpSess.folderID] = true;
            let paths = getFolderNamePath(gFolders[tmpSess.folderID])
            className = `${paths.namePath}/${b}`
        } else {
            className = `${b}`
        }

        gSessionIdToKeyMap[tmpSess.id] = className;
        gSessionKeyToIdMap[className] = tmpSess.id;

        for (let idx in sessArr) {
            let item = sessArr[idx];
            var a = {
                id: `${tmpSess.id}|${item.id}`,
                modified: new Date(item.updatedAt).getTime(),
                expire: ttlToExpiry(item.ttl),
                size: gBoardOwners[item.id] ? gBoardOwners[item.id] : "",
                isClassroom: false,
                classSessId: tmpSess.id,
                studentSessId: item.id,
                isStudent: true,
                isDraggable: false,
                enableBookmarks: getEnableBookmarks(),
                isBookmarked: (ttlToExpiry(tmpSess.ttl) === undefined) ? true : false,
                key: `${className}/${cleanupBoardName(item.name)}`,
                classModified: new Date(tmpSess.updatedAt).getTime(),
                classExpire: ttlToExpiry(tmpSess.ttl),
            }

            if (a.isBookmarked) {
                gBookmarkedSessions[tmpSess.id] = tmpSess;
            }

            tmpArr.push(a);
        }

        return tmpArr;
    }

    function getSessObjInternal(tmpSess, count) {
        var a = {
            id: tmpSess.id,
            modified: new Date(tmpSess.updatedAt).getTime(),
            expire: ttlToExpiry(tmpSess.ttl),
            size: "",
            isDraggable: true,
            enableBookmarks: getEnableBookmarks(),
            isBookmarked: (tmpSess.ttl === ib.NO_EXPIRY_TTL) ? true : false,
        }

        if (a.isBookmarked) {
            gBookmarkedSessions[tmpSess.id] = tmpSess;
        }

        if (tmpSess.Classroom === tmpSess.parentID) {
            a.isClassroom = true;
        }

        a.key = cleanupBoardName(tmpSess.name, count);

        if (tmpSess.folderID && tmpSess.folderID !== "") {
            gFolderNotEmptyMap[tmpSess.folderID] = true;
            let paths = getFolderNamePath(gFolders[tmpSess.folderID])
            a.key = `${paths.namePath}/${a.key}`
        }

        return a;
    }

    function isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    function getSessObj(tmpSess, count) {
        var a;
        if (gClassSessionMap[tmpSess.Classroom] && !(isEmpty(gClassSessionMap[tmpSess.Classroom]))) {
            a = getClassSessObjInternal(gClassSessionMap[tmpSess.Classroom], tmpSess, count,)
        } else {
            a = [getSessObjInternal(tmpSess, count)];
        }
        return a;
    }

    function updateSessions(tSessions, tClassSessions, tBoardOwners) {
        if (gFoldersRead) {
            gFolderNotEmptyMap = {};
            let newFiles = [];
            let maxCount = getMaxCount(gSessions);
            renameUnsaved(gSessions, maxCount);
            for (let idx in gSessions) {
                let tmpSess = gSessions[idx];
                if (tmpSess.folderID && tmpSess.folderID !== "") {
                    if (!(tmpSess.folderID in gFolders)) {
                        tmpSess.folderID = "";
                        ib.setName(tmpSess, null)
                    }
                }
                let a = getSessObj(gSessions[idx], idx, false);
                newFiles = newFiles.concat(a);
            }

            for (let fid in gFolders) {
                if (!gFolderNotEmptyMap[fid]) {
                    let paths = getFolderNamePath(gFolders[fid]);
                    // Folders have to end in a "/"
                    newFiles.splice(0, 0, { key: paths.namePath + "/" });
                }
            }

            if ((gFiles.length !== newFiles.length) || (tBoardOwners)) {
                gFiles = newFiles;
                setFiles(gFiles);
            }
        }
    }

    React.useEffect(() => {
        var s3, s4;
        var s1, s2;
        if (!props.user) return

        function setSessionArr2(newA) {
            let newCL = [];
            for (let i = 0; i < newA.length; i++) {
                var item = newA[i]
                if (item.name === "backup") continue
                if (item.id === item.parentBoardID) continue
                if (!(item.name)) item.name = "unsaved"
                var cl = gClassSessionMap[item.Classroom];
                if (!cl) cl = {}
                var pp = item.id.split("-pgNum-")
                if (pp[1] === "1") {
                    if (!(item.id in cl)) newCL.push(item)
                    cl[item.id] = item
                    gClassSessionMap[item.Classroom] = cl;
                }
            }

            return newCL;
        }

        function listUserCB(us) {
            setDebounceTimer();
            var p = gBoardOwners;
            us.forEach(u => {
                if (p[u.SessionID]) {
                    if (p[u.SessionID].indexOf(u.name) === -1)
                        p[u.SessionID] = p[u.SessionID].concat(", ", u.name);
                } else {
                    p[u.SessionID] = u.name;
                }
            });
            gBoardOwners = { ...p };
            updateSessions(null, null, gBoardOwners);
            setAllBoardOwners(gBoardOwners);
        }

        function gotClassSession(objs) {
            setDebounceTimer();
            var s2 = setSessionArr2(objs)
            if (s2) {
                s2.forEach((s) => {
                    // TODO: Handle the case if there are more than 100 users
                    // in a session
                    listUserCB(s.Users.items);
                })
            }
            gClassSessionMap = { ...gClassSessionMap };
            updateSessions(null, gClassSessionMap, null);
            setAllClassSessions(gClassSessionMap);
        }

        function subscribeClass(tmpSess) {
            if (tmpSess.Classroom &&
                tmpSess.Classroom === tmpSess.parentID) {
                // Don't need to subscribe
                ib.listSessionByClassroom(tmpSess.Classroom, gotClassSession, null, true,
                    { pageNumber: { eq: 1 } });
            }
        }

        function setSessionArr(newA, old) {
            for (let i = 0; i < newA.length; i++) {
                var item = newA[i]
                //set the color
                if (item.name === "backup") continue
                if (!(item.name)) item.name = "unsaved"
                var index = old.findIndex(x => x.parentID === item.parentID)
                if (index === -1) {
                    old.unshift(item);
                    subscribeClass(item);
                } else {
                    var pp = item.id.split("-pgNum-")
                    if (pp[1] === "1") {
                        old[index] = item //only for pg 1
                        subscribeClass(item);
                    }
                }
            }
            return [...old];
        }

        function getSessions(objs) {
            gSessions = setSessionArr(objs, sessions);
            updateSessions(gSessions, null, null);
            setSession(gSessions);
        }

        function delSession(ob) {
            var index = sessions.findIndex(x => x.id === ob.id)
            if (index === -1) return
            sessions.splice(index, 1);
            gSessions = [...sessions];
            updateSessions(gSessions, null, null);
            setSession(gSessions);
        }

        function updateFolderDicts(folder) {
            let paths = getFolderNamePath(folder);
            gFoldersIdToNameMap[paths.idPath] = paths.namePath;
            gFoldersNameToIdMap[paths.namePath] = paths.idPath;
            return paths;
        }

        function delFolders(folder) {
            if (folder.id in gFolders) {
                let paths = getFolderNamePath(folder);

                if (paths.namePath) {
                    doDeleteFolder([paths.namePath + "/"], false);
                }
            }
        }

        function gotFolders(folderList) {
            var f = gFolders;
            let newFolders = [];
            if (folderList) {
                for (let idx in folderList) {
                    let folder = folderList[idx];
                    f[folder.id] = folder;

                    if (gFoldersRead) {
                        let p = updateFolderDicts(folder);
                        newFolders.push({ key: p.namePath + "/" });
                    }
                }
                gFolders = { ...f };
                setFolders(gFolders);

                if (gFoldersRead) {
                    gFiles = gFiles.concat(newFolders)
                    setFiles(gFiles);
                } else {
                    setDebounceTimer();
                }
            } else {
                gFoldersRead = true;
                for (let folderId in f) {
                    updateFolderDicts(f[folderId]);
                }
            }
        }

        clearDebounceTimer();
        gFoldersRead = false;
        gFoldersNameToIdMap = {};
        gFoldersIdToNameMap = {};
        gClassSessionMap = {};
        gBoardOwners = {};
        gSessions = [];
        setFolders({});
        gBookmarkedSessions = {};

        function folderSubCB(folderSub) {
            [s3, s4] = folderSub;
        }

        function sessSubCB(sessSubs) {
            [s1, s2] = sessSubs;
        }
        var user = props.user
        ib.SubscribeFoldersByOwner({
            "user": user,
            "cb": gotFolders,
            "delcb": delFolders,
            "subCB": folderSubCB,
            "doList": true,
        });
        ib.SessionSubscribeByOwner({
            "ownerid": user,
            "cb": getSessions,
            "delcb": delSession,
            "doList": true,
            "subCB": sessSubCB
        });
        return () => {
            if (s1) s1.unsubscribe()
            if (s2) s2.unsubscribe()
            if (s3) s3.unsubscribe()
            if (s4) s4.unsubscribe()
        }
    }, [props.user]);

    function viewAction(row) {
        props.history.push("/board/" + row.id)
        window.location.reload()
    }
    function deleteAction(row) {
        var pp = row.id.split("-pgNum-")
        ib.getboardsbyparent(pp[0], null, delPages)
        function delPages(items) {
            if (!items) return
            items.forEach((item) => {
                try {
                    ib.delSession(item.id, function () { })
                } catch (err) {
                    // Ignore it
                }
            })
        }
    }
    function rowClick(row) {
        row.edit = true
        gSessions = [...sessions];
        updateSessions(gSessions, null, null);
        setSession(gSessions);
    }
    function setText(name, row) {
        row.name = name
        gSessions = [...sessions];
        updateSessions(gSessions, null, null);
        setSession(gSessions);
    }

    function openRow(row) {
        // Invert the state
        row.open = !row.open;
        gSessions = [...sessions];
        updateSessions(gSessions, null, null);
        setSession(gSessions);
    }

    function rowBlur(row) {
        row.edit = false
        delete row['edit']
        ib.setName(row);
        var index = sessions.findIndex((rb) => rb.id === row.id);
        sessions[index] = row;
        gSessions = [...sessions];
        updateSessions(gSessions, null, null);
        setSession(gSessions);
    }

    function handleRefresh(row) {
        ib.refreshBoard(row.id, {})
    }

    const Edit = (row) => {
        return (<>{row.edit ? (
            <>
                <form onSubmit={() => rowBlur(row)}>
                    <TextField
                        margin="dense"
                        id="name"
                        autoFocus
                        value={row.name}
                        label="Name"
                        variant="outlined"
                        type="text"
                        onBlur={() => rowBlur(row)}
                        onChange={(event) => setText(event.target.value, row)}
                    />
                </form>
            </>
        ) : row.name}</>)
    }

    function getUserDisplayString() {
        let retStr = "";
        if (props.userObj) {
            retStr = ib.getUserString(props.userObj.id, props.userObj.email);
            retStr = ` - ${retStr}`
        }

        return retStr;
    }

  
    return (<div>
       
            { wait ? <div className="screenCenter"><CircularProgress style={{ color: "#3174F5" }} /></div> : (
               
                <Grid container className='boardsScroll' style={{height: boardScrollHt}}>
                    {tileMode === true ?
                        sessionsToMap && sessionsToMap.length > 0 ? sessionsToMap.map((sb, indx) => {
                            return (

                                sb.MultiBoard && sb.MultiBoard.items && sb.MultiBoard.items.length > 0 ?
                                    <React.Fragment key={'fboard' + sb.id}>
                                        <Grid item xs={12} sm={6} md={4} lg={4} className='relative' key={'board' + sb.id}>
                                            <BoardTile data={sb} viewAction={viewAction} deleteAction={deleteAction}
                                                localDateFormat={localDateFormat} iconProps={BoardUtil.getIconColor(sb.id)}
                                                handleRefresh={handleRefresh} showExpand={true} handleOpen={openRow} rowIsOpen={sb.open}
                                                rowBlur={rowBlur} rowClick={rowClick} user={props.user} setText={setText} community={false} />
                                        </Grid>
                                        <ChildClass parent={sb} classid={sb.Classroom} sessions2={gClassSessionMap[sb.Classroom] ? Object.values(gClassSessionMap[sb.Classroom]) : []} />
                                    </React.Fragment>
                                    :
                                    <React.Fragment key={'fboard' + sb.id}>
                                        {sb &&
                                            <Grid item xs={12} sm={6} md={4} lg={4} className='relative' key={'board' + sb.id}>
                                                <BoardTile data={sb} viewAction={viewAction} deleteAction={deleteAction}
                                                    localDateFormat={localDateFormat} iconProps={BoardUtil.getIconColor(sb.id)}
                                                    handleRefresh={handleRefresh} showExpand={false} handleOpen={() => { }} rowIsOpen={sb.open}
                                                    rowBlur={rowBlur} rowClick={rowClick} user={props.user} setText={setText} community={false}/>
                                            </Grid>}
                                    </React.Fragment>

                            )
                        }) :
                            ''
                        :
                        <Grid container>
                        <Grid item style={{zIndex: '50'}}> {/* Helps popper for menu be on top */}
                        <ManageBoardMenus/>
                        </Grid>

                        <Grid item>
                        <FileBrowser
                            files={debounceTimedOut ? files : []}
                            icons={{
                                ClassFolder: <i className="fas fa-plus" style={{ color: curColor }} aria-hidden="true" />,
                                ClassFolderOpen: <i className="fas fa-minus" style={{ color: curColor }} aria-hidden="true" />,
                                StudentFile: <i className="fas fa-file-image" aria-hidden="true" />,
                                Refresh: <i className="fas fa-history" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                View: <i className="fas fa-eye" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                File: <i className="fas fa-file-image" style={{ color: curColor }} aria-hidden="true" />,
                                Image: <i className="fas fa-file-image" aria-hidden="true" />,
                                Rename: <i className="fas fa-i-cursor" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                FolderAction: <i className="fas fa-folder" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                Folder: <i className="fas fa-folder" style={{ color: "#F8D775" }} aria-hidden="true" />,
                                FolderOpen: <i className="fas fa-folder-open" style={{ color: "#F8D775" }} aria-hidden="true" />,
                                Delete: <i className="far fa-trash-alt" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                Loading: <i className="fas fa-spinner fa-spin" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                DoBookmark: <i className="far fa-bookmark" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                Bookmarked: <i className="fas fa-bookmark" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                JoinLink: <i className="fas fa-link" style={{ color: "#3174F5" }} aria-hidden="true" />,
                                Download: <i className="fas fa-download" aria-hidden="true" />,
                            }}
                            columns={['file', 'size', 'modified', 'expire', 'actions']}
                            headers={{
                                id: { name: 'id', isHidden: true },
                                file: { name: "Folder/Session" },
                                size: { name: "Participants" },
                                modified: { name: "Modified" },
                                expire: { name: "Expiring" },
                                actions: { name: "Actions" },
                            }}

                            showFoldersOnFilter={true}
                            doneLoading={debounceTimedOut}
                            detailRenderer={() => null}
                            onCreateFolder={handleCreateFolder}
                            onMoveFolder={handleRenameFolder}
                            onMoveFile={handleRenameFile}
                            onRenameFolder={handleRenameFolder}
                            onRenameFile={handleRenameFile}
                            onDeleteFolder={handleDeleteFolder}
                            onDeleteFile={handleDeleteFile}
                            onViewFile={handleViewFile}
                            onViewFolder={handleViewFolder}
                            onRefreshFile={handleRefreshFile}
                            onRefreshFolder={handleRefreshFolder}
                            onBookmarkedFolder={handleBookmarkedFolder}
                            onBookmarkRemovedFolder={handleBookmarkRemovedFolder}
                            onBookmarkedFile={handleBookmarkedFile}
                            onBookmarkRemovedFile={handleBookmarkRemovedFile}
                        />
                        </Grid>
                        </Grid>
                    }
                </Grid>
                  
            )}
    </div>)

    function ChildClass(mprops) {
        var [oldsessions2, setSession2] = useState([])
        var [oldboardOwners, setBoardOwners] = useState({})
        var sessions2 = mprops.sessions2
        var boardOwners = gBoardOwners

        function rowClick2(row) {
            row.edit = true
            setSession2([...sessions2])
        }
        function setText2(name, row) {
            row.name = name
            setSession2([...sessions2])
        }

        function rowBlur2(row) {
            row.edit = false
            delete row['edit']
            ib.setName(row);
            setSession2([...sessions2])
        }

        function deleteRow2(ob) {
            var index = sessions2.findIndex(x => x.id === ob.id)
            if (index === -1) return
            sessions2.splice(index, 1);
            setSession2([...sessions2])
            deleteAction(ob)
        }
        const Edit2 = (row) => {
            return (<>{row.edit ? (
                <>
                    <form onSubmit={() => rowBlur2(row)}>
                        <TextField
                            margin="dense"
                            id="name"
                            autoFocus
                            value={row.name}
                            label="Name"
                            variant="outlined"
                            type="text"
                            onBlur={() => rowBlur2(row)}
                            onChange={(event) => setText2(event.target.value, row)}
                        />
                    </form>
                </>
            ) : row.name}</>)
        }

        if (sessions2.length === 0) {
            return null
        }

        return (<>
            <>
                { tileMode === true ?
                    mprops.parent.open && sessions2 && sessions2.length > 0 ? sessions2.map((sb, indx) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={4} className='relative' key={'board1' + sb.id}>
                                <BoardTile data={sb} viewAction={viewAction} deleteAction={deleteAction} localDateFormat={localDateFormat} iconProps={BoardUtil.getIconColor(sb.id)} handleRefresh={handleRefresh}
                                    Edit={Edit} rowClick={rowClick} rowClick2={rowClick2} Edit2={Edit2} boardOwners={boardOwners[sb.id] ? boardOwners[sb.id] : null} parentName={mprops.parent.name} key={indx} user={props.user} community={false}/>
                            </Grid>
                        )
                    }) :
                        ''
                    :
                    mprops.parent.open && sessions2 && sessions2.length > 0 && sessions2.map(row => (
                        <TableRow key={row.id + "CHILDROW"}>
                            <TableCell />
                            <TableCell key={row.id + "name"} onClick={() => rowClick2(row)} >
                                <Typography
                                    className="inline text-14 font-500 px-4 py-4 rounded-4 flex align-center">
                                    {mprops.parent.name}
                                    <KeyboardArrowRightIcon />
                                    {Edit2(row)}
                                </Typography>
                            </TableCell>
                            <TableCell key={row.id + "participants"}>
                                <Typography
                                    className="inline text-12 font-500 px-4 py-4 rounded-4">
                                    {boardOwners[row.id] ? boardOwners[row.id] : " "}
                                </Typography>
                            </TableCell>
                            <TableCell key={row.id + "description"}>
                                <Typography
                                    className="inline text-12 font-500 px-4 py-4 rounded-4">
                                    {localDateFormat(row.updatedAt)}
                                </Typography>
                            </TableCell>
                            <TableCell key={row.id + "exp"}>
                                <IconButton size="small" onClick={() => handleRefresh(row)} >
                                    <RefreshIcon />
                                    <Typography
                                        className="inline text-12 font-500 px-4 py-4 rounded-4">
                                        {localDateFormat(new Date(row.ttl * 1000))}
                                    </Typography>
                                </IconButton>

                            </TableCell>
                            <TableCell key={row.id + "button"} component="th" scope="row">
                                <Button onClick={(event) => { viewAction(row); }}
                                    variant="text" className="custom_primary transform_none">
                                    <VisibilityIcon />
                                </Button>
                                <Button onClick={(event) => { deleteRow2(row); }}
                                    variant="text" className="custom_primary transform_none">
                                    <DeleteForeverIcon />
                                </Button>
                            </TableCell>

                        </TableRow>
                    ))
                }
            </>

        </>)
    }
}