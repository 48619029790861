import React from 'react';
import {
    TextField,
    IconButton,
    Grid,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ReactGA from 'react-ga';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as ib from "./ibdata"
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import YesNoDialog from "./YesNoDialog"
import * as mylocalStorage from "./mylocalStorage";

const MAXPAGES = 99
const GRIDPAGE = "gridpage"
function PageFooter(props) {
    const dispatch = useDispatch();
    const followTeacher = useSelector((state) => state.followTeacher);
    const teacherR = useSelector((state) => state.teacher);

    const pgSplit = props.session.split("-pgNum-")

    const [pgNum, setpgNum] = React.useState(parseInt(pgSplit[1], 10));
    const [rawPgNum, setRawPgNum] = React.useState(pgSplit[1]);

    React.useEffect(() => {
        const pgSplit2 = props.session.split("-pgNum-")
        var pgn = parseInt(pgSplit2[1])
        var idx = pgn
        var sess = props.sess
        if (sess && sess.boardConfig) {
            try {
                var bc = JSON.parse(sess.boardConfig)
            } catch { }
            if (bc.boardOrder) {
                var boardOrder = bc.boardOrder

                for (let ii in boardOrder.order) {
                    var ffbo = boardOrder.order[ii]
                    if (ffbo === pgn) {
                        idx = parseInt(ii)
                    }
                }
            }
        }
        setpgNum(idx)
        setRawPgNum(idx)
    }, [props.session, props.sess])
    var debounce = null
    function setDebounce(col) {
        if (debounce !== null) {
            clearTimeout(debounce);
            debounce = null;
        }
        debounce = setTimeout(function () {
            clearTimeout(debounce);
            debounce = null;
            setPage(col)
        }, 300);
    }
    function setPage(pnum) {
        if (props.gv.open) {
            if (pnum >= 1 && pnum <= 10) {
                mylocalStorage.setItem(GRIDPAGE, JSON.stringify({ pgNum: pnum, Classroom: props.sess.Classroom }));
                setpgNum(pnum)
            }
        } else {
            if (!Boolean(teacherR) && followTeacher.set) {
                // not allowed to change
                return
            }
            if (pnum >= 1 && pnum <= MAXPAGES) {
                setpgNum(pnum)
            }
        }
    }

    async function getActualPage(pnum) {
        if (props.gv.open) {
            return pnum
        }
        var sess = props.sess

        if (sess && sess.boardConfig) {
            try {
                var bc = JSON.parse(sess.boardConfig)
            } catch { }

            if (bc.boardOrder) {
                var requestedID = bc.boardOrder.order[pnum]
                if (requestedID) return requestedID
                else {
                    if (Boolean(teacherR)) {
                        var np = await ib.addPage(props.sess, pnum)
                        return np
                    }
                }
            }
        }
        return pnum
    }
    const [yn, setyn] = React.useState({ open: false, message: "", cb: null })

    async function setWithPass(arg, pass) {
        var lu = ib.getEscapeRoomPage(props.sess, arg.pg)
        var check = pass

        function checkpass(pass) {
            if (lu && lu.password === pass) {
                setRawPgNum(arg.pg)
                setDebounce(arg.pg)
                return true
            }
            return false
        }
        if (typeof check !== 'string') {
            //new type 
            if (check.type === "password") {
                check = check.name
                if (!checkpass(check)) setyn({ open: true, message: "Enter Password:", text: "abc", cb: doneClick })
            }
            if (check.type === "score") {
                var prevPage = await getActualPage(arg.pg - 1)
                lu = ib.getEscapeRoomPage(props.sess, prevPage)
                if (lu && parseInt(lu.score) >= parseInt(check.name)) {
                    setRawPgNum(arg.pg)
                    setDebounce(arg.pg)
                } else {
                    alert("Your score is too low. Need atleast " + check.name + "%")
                }
                //check prev. page score 
            }
        } else {
            if (!checkpass(check)) setyn({ open: true, message: "Enter Password:", text: "abc", cb: doneClick })
        }
        function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (val === check) {
                ib.setEscapeRoomPage(props.sess, arg.pg, { password: val })
                setRawPgNum(arg.pg)
                setDebounce(arg.pg)
            }
        }
    }
    async function setPageWithSkip(arg) {
        if (props.gv.open) {
            return arg.pg
        }
        var reqPage = arg.pg
        var sess = props.sess
        if (reqPage < 1 || reqPage >= MAXPAGES) {
            return 0
        }
        if (sess.boardConfig) {
            try {
                var bc = JSON.parse(sess.boardConfig)
            } catch { }
            if (bc.boardOrder) {
                if (bc.boardOrder.password && bc.boardOrder.password[reqPage] && !Boolean(teacherR)) {
                    setWithPass(arg, bc.boardOrder.password[reqPage])
                    return 0
                }

                if (bc.boardOrder.hidden[reqPage]) {
                    if (arg.kind === "type") {
                        return 0
                    }
                    if (arg.kind === "up") {
                        arg.pg++
                        return setPageWithSkip(arg)
                    }
                    if (arg.kind === "down") {
                        arg.pg--
                        return setPageWithSkip(arg)
                    }
                }
            }
        }
        return arg.pg
    }

    function setBlur() {
        setRawPgNum(pgNum)
    }

    function valueChange(arg) {
        let val = arg.target.value
        setRawPgNum(val)
        if (val.length <= 0) {
            return
        }
        setPageIfAllowed({ pg: parseInt(val), kind: "type" })
    }
    async function setPageIfAllowed(arg) {
        var newpnum = await setPageWithSkip(arg)
        if (newpnum !== 0) {
            setRawPgNum(newpnum)
            setDebounce(newpnum)
        }
    }
    function upPage() {
        setPageIfAllowed({ pg: pgNum + 1, kind: "up" })
        ReactGA.event({
            category: 'User',
            action: "PAGEUP"
        });
    }
    function downPage() {
        if (pgNum === 1) return
        setPageIfAllowed({ pg: pgNum - 1, kind: "down" })
        ReactGA.event({
            category: 'User',
            action: "PAGEDOWN"
        });
    }
    React.useEffect(() => {
        if (!Boolean(teacherR) && followTeacher.set) {
            var vv = followTeacher.events[0]
            if (vv) {
                setpgNum(parseInt(vv.Content.state, 10))
                setRawPgNum(vv.Content.state)
            }
        }
    }, [followTeacher, teacherR])
    React.useEffect(() => {

        if (!props.gv.open) {
            const pgSplit = props.session.split("-pgNum-")
            ReactGA.event({
                category: 'User',
                action: "GOTOPAGE"
            });
            if (Boolean(teacherR) && followTeacher.set) {
                // set new page for all kids
                var vv = followTeacher.events[0]
                if (vv) {
                    var copy = JSON.parse(JSON.stringify(vv))
                    copy.Content.state = pgNum
                    ib.updateClassEvent(copy)
                }
            }
            async function gotoPage() {
                var goto = await getActualPage(pgNum)
                if (goto === parseInt(pgSplit[1])) {
                    return
                }
                props.history.push("/board/" + pgSplit[0] + "-pgNum-" + goto)
            }
            gotoPage()
        } else {
            ReactGA.event({
                category: 'User',
                action: "GOTOPAGE_GV"
            });
            dispatch(Actions.setGVPage(pgNum))
        }
    }, [pgNum])
    function togglesidebar() {
        if (props.cb) props.cb()
    }
    React.useEffect(() => {
        if (props.gv.open) {
            var pnum = 1
            var pn = mylocalStorage.getItem(GRIDPAGE)
            if (pn) {
                try {
                    let r = JSON.parse(pn)
                    if (r && props && props.sess && r.Classroom === props.sess.Classroom) {
                        pnum = r.pgNum
                    }
                } catch { }
            }
            setRawPgNum(pnum)
            setpgNum(pnum)
        }
    }, [props.gv.open])
    return (
        <div>
            {yn.open && <YesNoDialog {...yn} inkColor= {props.inkColor} />}

            <Grid container id="pageNav" alignItems="center" className='newDNavi'>
                <IconButton className="btnSqr p-0" id="previousPageButton" onClick={downPage}>
                    <NavigateBeforeIcon fontSize='large' style={{ color: props.inkColor }} />
                </IconButton>
                <TextField
                    id="name"
                    type="text"
                    className="pageNum"
                    value={rawPgNum}
                    onChange={(event) => valueChange(event)}
                    onBlur={setBlur}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ size: (pgNum < 10 ? '1' : '2'), style: { textAlign: 'center', fontWeight: 'bold', fontSize: '0.65rem', color: props.inkColor } }}
                />
                <IconButton className="btnSqr p-0" id="nextPageButton" onClick={upPage}>
                    <NavigateNextIcon fontSize='large' style={{ color: props.inkColor }} />
                </IconButton>
            </Grid>
        </div>
    )
}

export default React.memo(PageFooter)