import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux'
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: "250px",
        height: "65px",
        backgroundColor: "#FFFFFF",
    },
    dialogText: {
        textAlign: "center",
    },
    okButton: {
        backgroundColor: "#3174F5",
        margin: "0px 20px",
        height: '38px',
        width: "80px",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'flex',
        justifyContent: "space-around",
        borderRadius: '999px',
        border: "1px solid #C8CEDA",
        color: "#ffffff",
        "&:hover": {
            color: '#2F3D5B !important',
        },
    },
}));

export default function AutoCorrectModal (props) {
    const teacherR = useSelector((state) => state.teacher);
    const classes = useStyles();
    const theme = useTheme();

    const handleClose = () => {
        props.setOpen(false);
    };
    return(
        <>
            <div className="dialogModal">
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open} >
                    <DialogContent className={classes.dialog}>
                        <DialogContentText id="alert-dialog-description" className={classes.dialogText} >
                            <span style={{ fontSize: "16px", color: "#2F3D5B", }}>Finished correction</span>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" className={classes.okButton} style={{ backgroundColor: props.inkColor }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}