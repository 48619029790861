import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import Draggable from 'react-draggable';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import * as ib from './ibdata'
import clsx from "clsx";
import { svgIcons } from './svgIcons';
import { RGBAToHexA } from './BoardUtility';

const pastels = [
    "#F4881C", "#3174F5", "#612AFF", "#FF0000",
    "#98269B", "#38BEBE", "#FFDF3D", "#FFB0CA",
    "#B3ECFC", "#FF866E", "#3E3AF7", "#50CDF7",
    "#EFCC52", "#F41CC4", "#AEA1FF","#68BC00"]
const useStyles = makeStyles((theme) => ({

    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        position: "absolute",
        height: "fit-content",
        zIndex: 105,
        color: "#ffffff",
        padding: '15px 0'

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        paddingBottom: "5px",
        top: 20,
        left: 0,
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        color: "#ffffff",
    },
}))
export default function BoardColorPicker(props) {
    const classes = useStyles();
    var xpos = window.innerWidth / 2 - 115
    if(props.isApiMode){
        xpos = 60
    }
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: 140 });
    var deb = null
    const [backgroundClr, setBackGroundClr] = useState('#fff')


    useEffect(()=>{
        if(props.ctx&&props.ctx.color){
            setBackGroundClr(props.ctx.color)
            return
        }
        if(props.inkColor){
            setBackGroundClr(props.inkColor)
        }
    },[props.inkColor, props.ctx])


    function colorChangeComplete(c) {
        ib.debounceClick({ timer: deb, function: colorChange, args: c })
    }

    function colorChange(c) {
        ReactGA.event({
            category: 'User',
            action: "Color Change Fill"
        });
        let hexaCode = RGBAToHexA(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)
        props.cb({ color: hexaCode })
        setBackGroundClr(c.rgb)
    }

    function handleDrag(e, ui) {
        if ((ui.y < 100 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        props.setColorPicker({ pickerOpen: false, cb: null })
    }
    
    return (
        <Draggable
                handle="strong"

                onDrag={handleDrag}
                onStop={handleDragEnd}
                position={windowPosition}
                defaultClassName="draggable33-color"
                defaultClassNameDragging="draggable32-color"
                defaultClassNameDragged="draggable33-color"
            >
                <div id="smallpalette" className={clsx(classes.draggableWindow,'boardCPdragDiv')} style={{
                    border: '5px solid ' + props.inkColor,
                }}>
                    <div className={clsx('handle', classes.drawerHeader)}>
                                    <div className={classes.draggableTop}
                                        style={{
                                            width: "100%",
                                            height: '10px',
                                            backgroundColor: '#E2E6E9',
                                            cursor: "move"
                                        }}
                                    >
                                        <strong className={clsx(classes.draggableTopHandle, 'justify-center dragImgContainer')}>
                                            <svg width="30" height="10" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: "0px" }}>
                                                {svgIcons.dots}
                                            </svg>
                                        </strong>
                                    </div>
                                </div>
                    <div className='modalHeader'>
                    <span className='lato14pxBold'>Pen Color</span>
                       <img src='/tools/close.svg' alt='close icon' onClick={handleClose} />
                    </div>
                    <div className='dragDivForm'>
                       
                    <SketchPicker
                        disableAlpha
                        presetColors={pastels}
                        color={backgroundClr}
                        onChangeComplete={colorChangeComplete} />
                    </div>
                </div>
            </Draggable>
    );
}