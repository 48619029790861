import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import 'typeface-roboto'
import './fonts/Chewy-Regular.ttf'
import './fonts/KgTeacherHelpers-q4J1.ttf'
import './fonts/OpenDyslexic-Regular.woff'
import './fonts/Lexend-Regular.ttf'

import './styles/newDesignStyles.css'
import './styles/iosStyles.css'
import './styles/wbcJrStyles.css'

require('normalize.css/normalize.css')
require('./styles/index.scss')

//window.LOG_LEVEL = 'DEBUG';

// Setup SocialAuth Redirect URLs
if (awsExports.oauth) {
  awsExports.oauth.redirectSignIn = window.location.origin + "/login/";
  awsExports.oauth.redirectSignOut = window.location.origin + "/logout/";
  awsExports.aws_appsync_authenticationType = "AWS_IAM"
}

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
