import React from "react";
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
    Button
} from "@material-ui/core";
import draftToHtml from 'draftjs-to-html';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux'

let newXOff = 80
let newYOff = 120
export default function MyRichEditor(props) {
    const [state, setState] = React.useState(null)
    const [estate, setEState] = React.useState(null)

    const richText = useSelector((state) => state.richText);
    function OnEditorStateChange(state) {
        if (!state) return
        setEState(state)
        const rs = convertToRaw(state.getCurrentContent())
        // console.log("STATE IS ", rs)
        setState(rs)
    }

    React.useEffect(() => {
        if (richText.open && richText.object) {
            setState(richText.object)
            var ff = convertFromRaw(richText.object)
            setEState(EditorState.createWithContent(ff))
        } else {
            setEState(EditorState.createEmpty())

        }
    }, [richText])
    function saveBoard() {
        const html = draftToHtml(state)
        if (!html) {
            props.cb(null, state)
            return
        }
        if (state)  {
            var something = false 
            for (let i=0; i< state.blocks.length; i++) {
                var b = state.blocks[i]
                if (b && b.text && b.text.length > 0) {
                    something = true 
                    break 
                }
            }
            if (!something) {
                props.cb(null, state)
                return // nothing needed 
            }
        }
        const el = document.getElementById("render")
        el.innerHTML = html
        var iframe = document.createElement('iframe');
        iframe.style.height = "500px";
        iframe.style.width = "500px";
        iframe.style.backgroundColor = "transparent";
        iframe.allowTransparency = "true";
        var border = `<style>
        div{border: 1px ${props.inkColor}; border-style: dashed; height: 500px; width: 500px;}
        </style> `
        document.body.appendChild(iframe);
        setTimeout(function () {
            var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
            var xx = border+ '<div>' + html + '</div>'
            iframedoc.body.innerHTML = xx;
            html2canvas(iframedoc.body, { scale: 1 }).then(
                function (canvas) {
                    var dt = canvas.toDataURL('image/jpg', 0.9)
                    props.cb(dt, state)
                    document.body.removeChild(iframe)
                }
            )
        }, 20);
    }
    function Save() {

        return <Button onClick={saveBoard} style={{ backgroundColor: props.inkColor,borderRadius: "999px", color: "#ffffff" }} >Close</Button>
    }
    const tbOptions = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'emoji', 'history'],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true }
    }

    return (
        <>
            {richText.open && richText.location && (

                <div id="editor" style={{
                    top: richText.location.y + newYOff + "px",
                    left: richText.location.x + newXOff+ "px", zIndex: 105, position: "absolute"
                }}>
                    <Editor
                        editorState={estate}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={OnEditorStateChange}
                        toolbarCustomButtons={[<Save />]}
                        onBlur={() => saveBoard()}
                        toolbar={tbOptions}
                    />
                </div>
            )}


            <div id="render"></div>
        </>
    )
}