import React from 'react';
import { useSelector } from 'react-redux'
import Draggable from 'react-draggable';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import { withStyles } from '@material-ui/core/styles';

import {
    TextField,
    Button,
    Grid,
    Slider,
    Typography,
} from '@material-ui/core';

import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import clsx from "clsx";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import "./styles/newDesign.css";
import { svgIcons } from './svgIcons';

const useStyles = makeStyles((theme) => ({

    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        width: "215px",
        height: "215px",
        zIndex: 125,
        position: "fixed",
        color: "#ffffff",
        backgroundColor: "#ffffff"

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "10px",
        backgroundColor: "#E2E6E9 !important",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        width: "90%",
        position: "fixed",
        paddingBottom: "0px",
        top: 30,
        left: 10,
        height: "110px",
        backgroundColor: "#ffffff"
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1",
        margin: "10px",
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    createButton: {
        color: "#FFFFFF",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "999px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        width: "100px",
        padding: "6px 12px",
        textTransform: "capitalize",
        letterSpacing: "-0.078px !important",
        "&:hover": {
            color: '#2F3D5B',
        }
    },
}))
const WBSlider = withStyles({
    root: {
        paddingTop: '50px',
        margin: '4px',
        paddingBottom: '6px',
    }
})(Slider);
var myClock;
var timerVal

export default function TimeMachine(props) {
    const classes = useStyles();

    const timeMachine = useSelector((state) => state.timeMachine);
    const xpos = window.innerWidth / 2
    const ypos = window.innerHeight - 480
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: ypos });
    const dispatch = useDispatch();

    React.useEffect(() => {
        return () => {
            if (myClock) clearInterval(myClock);
        }
    }, [])



    function handleDrag(e, ui) {
        if ((ui.y < 60 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        dispatch(Actions.setTimeMachine({
            open: false, realTime: true, selectedTime: null, slider: 50
        }))
    }
    function valuetext(value) {
        return `${value}°C`;
    }
    const [sliderDate, setSliderDate] = React.useState("");

    function setDate(d) {
        setSliderDate(d)
    }
    function handleSlider(e, v) {
        dispatch(Actions.setTimeMachine({
            ...timeMachine,
            cb: setDate,
            slider: v
        }))
    }
    const handleChange = (event) => {
        const name = event.target.name

        if (timeMachine && timeMachine[name] !== event.target.checked) {
            dispatch(Actions.setTimeMachine({
                ...timeMachine,
                [name]: event.target.checked,
            }))
        }
    };
    const [play, setPlay] = React.useState(false)
    function myTimer() {
        if (!timeMachine.open) {
            if (myClock) clearInterval(myClock);
            return
        }
        timerVal += 5
        if (timerVal >= 100) {
            if (myClock) clearInterval(myClock);
            setPlay(false)
        }

        dispatch(Actions.setTimeMachine({
            ...timeMachine,
            cb: setDate,
            slider: timerVal
        }))
    }

    function playClick() {
        if (myClock) clearInterval(myClock);

        if (!play) {
            timerVal = 0
            dispatch(Actions.setTimeMachine({
                ...timeMachine,
                cb: setDate,
                slider: timerVal
            }))
            myClock = setInterval(myTimer, 1000)
        } else {
            if (myClock) clearInterval(myClock);
        }
        setPlay(!play)
    }
    return (
        <> {timeMachine.open && (
            <>
                <Draggable
                    handle="strong"

                    onDrag={handleDrag}
                    onStop={handleDragEnd}
                    position={windowPosition}
                    defaultClassName="draggable-grid-33"
                    defaultClassNameDragging="draggable33-grid"
                    defaultClassNameDragged="draggable33-grid"
                >
                    <div id="smallpalette" className={clsx(classes.draggableWindow, 'dragDiv')} style={{
                        border: '5px solid ' + props.inkColor,
                    }}>
                        <div className={clsx(classes.draggableTop, 'dragDivmain')} style={{ width: "100%", backgroundColor: props.inkColor }}>
                            <strong className={clsx(classes.draggableTopHandle, 'justify-center')}>
                                    <svg width="30" height="10" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg" style={{position: "absolute",top: "0px", cursor: "move"}}>
                                        {svgIcons.dots}
                                    </svg>
                                </strong>
                        </div>
                        <CloseIcon className= "closeIconItem" onClick={handleClose} />
                        <div className={clsx(classes.cp, 'dragDivForm')} style={{ color: props.inkColor }}>
                            <Grid container alignItems="center">
                                <WBSlider
                                    defaultValue={1}
                                    aria-labelledby="discrete-slider-always"
                                    step={2}
                                    value={timeMachine.slider}
                                    valueLabelDisplay="on"
                                    onChange={handleSlider}
                                />
                                <TextField
                                    id="name"
                                    type="text"
                                    disabled
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    className='inpTextProps'
                                    value={sliderDate}
                                />
                            </Grid>
                            <div className='formBtns mt-10'>
                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{ backgroundColor: props.inkColor}}
                                    onClick={() => playClick()}
                                    className = {classes.createButton}
                                >
                                    {play ? 'Pause' :
                                        'Play'}
                                </Button>
                            {/* <FormControlLabel
                                control={<Checkbox checked={timeMachine.realTime} onChange={handleChange} name="realTime" />}
                                label="Real time view"
                            /> */}
                        </div>
                        </div>
                    </div>

                </Draggable>
            </>
        )}
        </>
    );
}