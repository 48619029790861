import React from 'react';
import "./home.css"
import "./Home/homeOver.css"
import ReactGA from 'react-ga';
import {
    Grid,
    List,
    ListItemText,
    ListItem,
    Divider,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const currentOptions =["Collaborate in groups", "Play games & learn", "Connect from multiple devices"]
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        backgroundColor: "#ffffff"
    },
    rightSide: {
        marginLeft: 'auto',
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        align: 'center',
    },

    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        backgroundColor: '#FFFFFF',
        color: '#5D5770',
    },
    listText: {
        color: '#5D5770',
        alignItems: "center",
        display: 'flex',
        justifyContent: "center",
        textAlign: 'center'

    },
    listItemText: {
        fontSize: '1.2em',//Insert your required size
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    }
}))
export default function CollabDemo(props) {
    const classes = useStyles();

    React.useEffect(() => {
        var from = "nothing";
        if (props.location && props.location.search) {
            const s = props.location.search.split('?')
            if (s && s.length > 0) from = s[1]
        }
        ReactGA.pageview('/CollabDemo')
        ReactGA.event({
            category: 'CollabDemo',
            action: from
        });    
    })

    return (
        <div className={classes.root}>
            <Grid container className="ontopTeach" spacing={1} justify="center" >
            <Grid container >
             <Button onClick={(event) => { props.history.push("/"); }} className='logoBtn'>
                    <img src='/logo_title.svg' alt='whiteboard Logo' className='cursor-pointer' />
                </Button>
            </Grid>
            <Grid container >
                <Grid item md={3} xs={false}>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.paper}>
                        <div className="TeachMsg">Collaborate in Groups & Share</div>
                        <div className="TeachSubMsg">Connect multiple people and devices to the same board. </div>
                        <List m="auto">
                        {currentOptions.map((option) => (
                            <>
                                <ListItem className={classes.listText} key={option} >
                                    <ListItemText classes={{ primary: classes.listItemText }} primary={option} />
                                </ListItem>
                                <Divider className={classes.divider} />
                            </>
                        ))}
                    </List>
                            <div className='popupHeader' style={{ height: '60vh' }}>
                                <iframe
                                    className='{classes.windowIframeOutput}'
                                    src='https://www.youtube.com/embed/JKlyNv3Ry5s'
                                    width='100%'
                                    height='100%'
                                    allow="camera;microphone"
                                />
                            </div>
                            {/* <video width="100%"
                            src="https://s3-us-west-2.amazonaws.com/epiphani.ai/whiteboard/CollabWB.mp4" playsInline controls={true} loop={true} muted autoPlay="autoplay" /> */}
                        </div>
                    </Grid>
                    <Grid item md={3} xs={false}>
                    </Grid>
                </Grid>
            </Grid>
        </div>

    )
}