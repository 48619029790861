export const queryByParent = /* GraphQL */ `
  query QueryByParent(
    $parentID: ID
    $pageNumber: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryByParent(
      parentID: $parentID
      pageNumber: $pageNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          items {
            id
            name
            createdAt
            updatedAt
            SessionID
            content
            UserProfile
            ttl
            avatar
          }
          nextToken      
        }
        parentBoardID
        Classroom
        CreatorLocalID
        folderID
        MultiBoard {
          nextToken
        }
        isGroup
      }
      nextToken
    }
  }
`;
export const queryByClassroom = /* GraphQL */ `
query QueryByClassroom(
  $Classroom: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSessionFilterInput
  $limit: Int
  $nextToken: String
) {
  queryByClassroom(
    Classroom: $Classroom
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      content
      createdAt
      updatedAt
      Objects {
        nextToken
      }
      ttl
      savedOwner
      parentID
      pageNumber
      boardConfig
      Users {
        items {
          id
          name
          createdAt
          updatedAt
          SessionID
          content
          UserProfile
          ttl
          avatar
        }
        nextToken      
      }
      parentBoardID
      Classroom
      CreatorLocalID
      folderID
      MultiBoard {
        nextToken
      }
      isGroup
    }
    nextToken
  }
}
`;

export const queryBySavedOwner = /* GraphQL */ `
  query QueryBySavedOwner(
    $savedOwner: ID
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryBySavedOwner(
      savedOwner: $savedOwner
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        content
        createdAt
        updatedAt
        Objects {
          nextToken
        }
        ttl
        savedOwner
        parentID
        pageNumber
        boardConfig
        Users {
          nextToken
        }
        parentBoardID
        Classroom
        CreatorLocalID
        folderID
        MultiBoard {
            items {
                id
                parentBoardID
                childBoardID
                createdAt
                updatedAt
            }
            nextToken
        }
      }
      nextToken
    }
  }
`;

export const queryByJoinCode = /* GraphQL */ `
  query QueryByJoinCode($joinCode: ID) {
    queryByJoinCode(joinCode: $joinCode) {
      items {
        id
        createdAt
        joinCode
      }
      nextToken
    }
  }
`;