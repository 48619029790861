import React from 'react';
import { Auth } from 'aws-amplify'
import * as ib from "./ibdata.js"
import SaveDialog from "./saveDialog"
import ReactGA from 'react-ga';
import HomeIcon from '@material-ui/icons/Home';
import LockIcon from '@material-ui/icons/Lock';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
    Typography,
    Button,
    Grid,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import AuthDialog from "./AuthDialog"
import './styles/style.css'
import { v4 as uuid } from "uuid";
import SessionTable from './SessionTable.js';
import * as mylocalStorage from "./mylocalStorage";
import "./styles/sale.scss"
import { webinarConfigs } from './Config.js';
var boardColor = null
function ManageBoards(props) {
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.user);
    const [user, setUser] = React.useState(null)
    const oldManageSession = mylocalStorage.getItem('manageBoardsSession');

    //dispatch(Actions.setRedirectUrl(window.location.pathname));

    const [saveDialog, setSaveDialog] = React.useState({ cb: null, open: false, id: null })
    
    React.useEffect(() => {
        if (!authUser) {
            return
        }
        let oldSession = mylocalStorage.getItem('manageBoardsSession');
        let oldClassroom = mylocalStorage.getItem('manageBoardsClassroom');
        let oldUserId = mylocalStorage.getItem('backupLocalID');

        function gotSessions(sessionList) {
            if (sessionList === null) {
                return;
            }

            for (let idx in sessionList) {
                let needToUpdateSession = false;
                let updObj = {}

                if ((sessionList[idx].CreatorLocalID !== authUser.username) &&
                    (sessionList[idx].CreatorLocalID === oldUserId)) {
                    updObj['CreatorLocalID'] = authUser.username;
                    needToUpdateSession = true;
                }

                if ((sessionList[idx].savedOwner !== authUser.username) &&
                    (sessionList[idx].savedOwner === oldUserId)) {
                    updObj['savedOwner'] = authUser.username;
                    needToUpdateSession = true;
                }

                if (needToUpdateSession) {
                    updObj['id'] = sessionList[idx].id;
                    ib.updateSession(updObj);
                }
            }
        }

        function gotClass(classroom) {
            if ((classroom.TeacherID) && (classroom.TeacherID === oldUserId)) {
                // Need to update classroom
                let updClass = { id: classroom.id, TeacherID: authUser.username };
                ib.updateClassroom(updClass);
                ib.getboardsbyparent(oldClassroom, null, gotSessions);
            }
        }

        function gotUser(u) {
            setUser(u)

            if (oldClassroom) {
                ib.getClassroom(oldClassroom, gotClass);
            } else if (oldSession) {
                let tmpArr = oldSession.split('-pgNum-');
                let tmpId = tmpArr[0];
                ib.getboardsbyparent(tmpId, null, gotSessions);
            }
        }

        var userInfo = ib.getUserInfo(authUser);
        ib.findCreateUserProfile(authUser.username, authUser.attributes.email,
            userInfo.avatar, userInfo.name, null, gotUser)
    }, [authUser])

    React.useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'InstanceUsed',
            label: window.location.host
          });
        function signedIn() {
            dispatch(Actions.openAuthDialog({ open: false, siCB: null }))
        }
        function signUpCancel() {
            if (window.insideMSTeams === true) {
                // User went to manage page directly, or it is in MS Teams Personal tab
                // In MS Teams, closing auth dialog should not render blank page, so we reload.
                window.location.reload()
            } else {
                props.history.goBack()
            }
        }
        ib.updateOembedLink();
        if (!authUser) {
            //No one signed in 
            dispatch(Actions.openAuthDialog({ open: true, siCB: signedIn, cancelCB: signUpCancel}))
        }
        boardColor = mylocalStorage.getItem('savedInk');
        if (!boardColor) {
            boardColor = props.location && props.location.state && props.location.state.inkColor
        }
    }, [])
    React.useEffect(() => {
        function saveDone(text) {
            setSaveDialog({ cb: null, open: false, id: null })
            if (text) {
                ib.saveBoard(text, Auth.user.username, props.match.params.boardid, null)
                ReactGA.event({
                    category: 'User',
                    action: "BoardSave"
                });
                props.history.push("/manage")
            } else {
                ReactGA.event({
                    category: 'User',
                    action: "BoardSaveCancelled"
                });
                props.history.goBack()
            }
        }
        if ('match' in props && 'params' in props.match && 'boardid' in props.match.params) {
            setSaveDialog({ cb: saveDone, open: true, id: props.match.params.boardid })
        }
    }, [props, props.match])

    function backButtonClicked(event) {
        props.history.push("/board/" + oldManageSession);
    }


    function newBoard(e) {
        const id = uuid()
        var luid = mylocalStorage.getItem('mystoreID');

        const pg1 = id + "-pgNum-1"
        var boardConfig = '{"multiBoardOption": true}'
        var j = { "multiBoardOption": true }
        if (user && user.boardConfig) {
            j = JSON.parse(user.boardConfig)
            j.multiBoardOption = true 
            boardConfig = JSON.stringify(j)
        }
        ib.createClassroom({ id: id, TeacherID: luid, name: "myclass" })
        dispatch(Actions.setBoardConfig(j))
        ib.createSession(pg1, "unsaved", "somecontent", id, 1, null, boardConfig, id).then((res) => {
            const redir = "/board/" + pg1
            props.history.push(redir);
            ReactGA.pageview('/createboard')
        })
    }

    function showStoragePlans() {
        if (window.insideMSTeams === true)
            return false
        return true
    }

    function showHomeButton() {
        if (window.insideMSTeams === true)
            return false
        return true
    }
    function webinarPacific(e) {
        window.open(webinarConfigs.webinarPacific)
    }
    return (
        <div className="session-table">
            <AuthDialog noGear={true} />
            {user && (
                
                <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                        <Grid container>
                            <Grid item md={1} lg={1} className='relative'>
                            </Grid>
                            <Grid item xs={11} sm={11} md={9} lg={9} className='relative'>
                                { showHomeButton() ? <>
                                <Button onClick={(event) => { props.history.push("/"); }} className='homebtn'>
                                    <HomeIcon className='mr-4' />
                                    <Typography className="text-16 homebtn">HOME</Typography>
                                </Button> </> : null }
                                {oldManageSession && <Button onClick={backButtonClicked} className='homebtn'>
                                    <ArrowBackIcon />
                                    <Typography className="text-16 homebtn">BACK</Typography>
                                </Button>}
                                <div className="cursor-pointer homebtn start-btn text-16" onClick={newBoard}>
                                    <img src='/ic_gesture_24px.svg' alt='ud' />
                                    <Typography className="text-16 homebtn">START TEACHING</Typography>
                                      </div>

                                {/* <Button onClick={() => { webinarPacific() }} className='homebtn'>
                                    <i className="fas fa-mouse-pointer" style={{ color: '#ffffff', marginRight: '5px' }}></i>
                                    <Typography className="text-16 homebtn">Free Webinar</Typography>
                                </Button> */}

                                { showStoragePlans() ? <>
                                <Button onClick={(event) => { props.history.push("/subs"); }} className='homebtn'>
                                    <ShoppingCartIcon className='mr-4' />
                                    <Typography className="text-16 homebtn">Storage Plans</Typography>
                                </Button>
                                <span class="newflash">New!</span> </> : null }
                                <SaveDialog {...saveDialog} inkColor={boardColor} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} className='relative' 
                                style={{ alignSelf: 'center' }}>
                                {user &&
                                    (<Button className='homebtn' onClick={() => { Auth.signOut(); window.location.reload(); }}>
                                    <LockIcon className='mr-4' />
                                    <Typography className="text-16 homebtn">Sign Out</Typography>
                                    </Button>)
                                }
                            </Grid>

                            <Grid item md={1} lg={1} className='relative'>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <SessionTable user={user.id} userObj={user} history={props.history} />
                            </Grid>
                        </Grid>
                    </Grid>
                    </Grid>

            )}
        </div>

    )
}



export default ManageBoards
