import paper from 'paper';
import pako from 'pako'
import * as iu from "./ImageUtils"

var paperObj = {}
function insertPaper(scope, data, id) {
    var loc = scope.id 
    if (loc in paperObj) {
        paperObj[loc][id] = data
    } else {
        paperObj[loc] = {}
        paperObj[loc][id] = data
    }
}

function drawPText(sess, txt, x, y, color, size, obj, font, scope) {
    var pointTextLocation = new paper.Point(x + 2, y + 10)
    scope.activate()
    var text = new scope.PointText(pointTextLocation);
    text.content = txt;
    text.style = {
        fontFamily: font,
        fontSize: size * 7,
        fillColor: color,
        justification: 'left'
    };
    var data = { scope: scope, obj: text, type: "text", gqlObj: JSON.parse(JSON.stringify(obj)) }
    insertPaper(scope, data, obj.id)
}

function drawTextRemote(sess, obj, scope) {
    if ('type' in obj) {
        try {
            if ('type' in obj) {
                obj['type'] = JSON.parse(obj['type'])
                if (obj.type && obj.type.compressed) {
                    obj.content = pako.inflate(obj.content, { to: 'string' });
                    delete obj['type']
                }
            }
        } catch {

        }
    }
    var c
    try {
        c = JSON.parse(obj.content)
    } catch (e) {
        console.log("Cannot parse ob", c)
        return
    }
    var font = c.font ? c.font : "Chewy"

    drawPText(sess, c.text, c.points.x, c.points.y, c.color, c.brushRadius, obj, font, scope)
}
function drawPaperPath(sess, obj, segments, color, radius, scope) {
    if (!scope) {
        return;
    }
    scope.activate()

    var path = new scope.Path({
        segments: segments,
        strokeColor: color,
        strokeWidth: radius * 2
    });
    path.smooth()
    var data = { scope: scope, obj: path, type: "draw", gqlObj: JSON.parse(JSON.stringify(obj)) }
    insertPaper(scope, data, obj.id)
    return path
}

function drawObj(sess, obj, scope) {
    if ('type' in obj) {
        try {
            obj['type'] = JSON.parse(obj['type'])
            if (obj.type && obj.type.compressed) {
                obj.content = pako.inflate(obj.content, { to: 'string' });
                delete obj['type']
            }
        } catch {

        }
    }
    var arr
    try {
        arr = JSON.parse(obj.content)
    } catch (e) {
        console.log("Cannot parse ", e, obj)
        return
    }
    if (!arr) {
        console.log("arr is empty, not drawing");
        return
    }

    const points = arr.points
    var color = "yellow"
    if ('color' in arr) {
        color = arr['color']
    }

    drawPaperPath(sess, obj, points, color, arr.brushRadius, scope)
}

async function drawImage(obj1, scope) {
    const obj = JSON.parse(JSON.stringify(obj1)) //make a copy
    var ct
    try {
        ct = JSON.parse(obj.content)
    } catch (e) {
        console.error("Cannot parse", e, obj)
        return
    }
    if (ct.type === "bingo") {
        return
    }
    var ff = await iu.GetImage(ct.url);

    continueLoad(scope, ff.img, obj)


    function continueLoad(scope, url, obj) {
        if (!scope) return
        scope.activate()
        var raster = new scope.Raster({ source: url });
        raster.data.createdAt = new Date(obj.createdAt).getTime() / 1000
        let idx = getInsertIndex(scope, raster)
        scope.project.activeLayer.insertChild(idx, raster);
        // raster.position = paper.view.center;
        raster.onLoad = function () {
            var xx = raster._size.width / 2
            var yy = raster._size.height / 2
            raster.position = new paper.Point(xx + 60, yy + 40)
            var data = { obj: raster, type: "picture", gqlObj: obj }
            insertPaper(scope, data, obj.id)
        }
        raster.onError = function (f) {
            console.log('The image not loaded.', f);
        };
    }
}
function getInsertIndex(scope, no) {
    var children = scope.project.activeLayer.children;

    // Iterate through the items contained within the array:
    for (var i = 0; i < children.length; i++) {
        var child = children[i];
        if (child instanceof paper.Raster) {
            if (child.data && child.data.createdAt && no.data.createdAt) {
                if (no.data.createdAt > child.data.createdAt) continue
            } else {
                continue
            }
        }
        if (child.data && child.data.background) continue
        if (child.data && child.data.grid) continue
        return i
    }
}
function gotDataOne(dat, scope) {

    if (dat.objType === "text") {
        drawTextRemote(dat.SessionID, dat, scope)
    }
    if (dat.objType === "drawFree") {
        drawObj(dat.SessionID, dat, scope)
    }
    if (dat.objType === "image") {
        drawImage(dat, scope)
    }
    if (dat.objType === "drawPaper") {
        drawPaper(dat, scope)
    }
}

function drawPaper(obj, scope) {
    if (!obj.content) return
    try {
        if ('type' in obj) {
            obj['type'] = JSON.parse(obj['type'])
            if (obj.type && obj.type.compressed) {
                obj.content = pako.inflate(obj.content, { to: 'string' });
                obj.type.compressed = false
                delete obj['type']
            }
        }
    } catch (e) {
        console.error("cannot decode", e, obj)
    }

    const sess = obj.SessionID
    if (!scope) {
        return;
    }
    scope.activate()
    var rr
    try {
        rr = JSON.parse(obj.content)
    } catch {
        console.error("cannot parse object", obj)
        return
    }
    try {
        var pobj = new scope[rr[0]]()
        if (rr[0] === "Raster") {
            try {
                const ff = JSON.parse(obj.content)
                const rrt = ff[1].source.split("?X-Amz")[0]
                ff[1].source = rrt
                obj.content = JSON.stringify(ff)
            } catch {

            }
        }
        pobj.importJSON(obj.content)
        if (rr[0] === "Raster") {
            pobj.data.createdAt = new Date(obj.createdAt).getTime() / 1000
            let idx = getInsertIndex(scope, pobj)
            scope.project.activeLayer.insertChild(idx, pobj);
        }
        if (pobj && pobj.data.bingo) {
            if (obj.id && obj.id.indexOf("pgNum") !== -1) {
                pobj.remove()
                return
            }
        }
        if (pobj.data && pobj.data.background) {
            scope.project.activeLayer.insertChild(0, pobj);
        }
        if (rr[0] === "Group" && 'data' in pobj && pobj.data.grid) {
            scope.project.activeLayer.insertChild(0, pobj);
        }
        if (pobj && pobj.data && pobj.data.background) {
        }

        pobj.selected = false
    } catch (e) {
        console.log("bad object", rr, obj, e)
        return
    }

    var data = { obj: pobj, type: "drawPaper", gqlObj: JSON.parse(JSON.stringify(obj)) }
    insertPaper(scope, data, obj.id)
}
export function gotData(scope, dat, parent) {
    for (let i = 0; i < dat.length; i++) {

        if (parent && dat[i].DisableSync &&
            dat[i].DisableSync === "disabled") {
            //teacher object not meant for me
            continue;
        }

        gotDataOne(dat[i], scope)
    }

}
export function delObject(dat, scope) {
    dat.origId = dat.id
    var po = paperObj[scope.id]
    if (!po) return
    if (dat.id in po) {
        window.paper = scope
        scope.activate()
        //scope.project.activeLayer.removeChildren();
        po.obj.remove()
        //paperObj[dat.id].scope.view.update()
        delete paperObj[scope.id][dat.id]
    }
}