import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import * as ib from './ibdata'

const useStyles = makeStyles((theme) => ({
    draggableParent: {
        position: "absolute",
        top: '0',
        left: '0',
        width: "calc(100%)",
        height: "calc(100%)",
        padding: "50px 5px 5px 50px"
    },
    draggableMedia: {
        position: "absolute",
        zIndex: "99",
        justifyContent: "center",
        alignItems: "center",
        resize: "both",
        overflow: "hidden",
        backgroundColor: "#00000000",
    },
    mediaItem: {
        objectFit: "fill",
        width: "100%",
        height: "100%"
    },
    mediaClose: {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '100',
        width: '30px',
        height: '30px',
        padding: '0',
        color: "white",
        "&:hover": {
            color: "white"
        }
    }
}));

export default function GifImage(props) {
    const classes = useStyles();

    const isTeacher = useSelector((state) => state.teacher);
    const [draggableParent, setDraggableParent] = React.useState({ width: '100%', height: '100%' });
    const minImageResolution = { width: "40px", height: "40px" };
    const [windowResolution, setWindowResolution] = React.useState({ });
    const [windowPosition, setWindowPosition] = React.useState({ x: 0, y: 0 });
    const [gifData, setGifData] = React.useState({});
    const [gifURL, setGifURL] = React.useState("");

    const [mediaRO, setMediaRO] = React.useState(null);
    const mediaRef = React.createRef(null)
    var mediaResizeUpdate = null;
    const [observerCreated, setObserverCreated] = React.useState(false);

    useEffect(() => {
        if (props.gifContent) {
            const data = JSON.parse(props.gifContent.content)

            setGifData(props.gifContent);
            setGifURL(data.gifURL);
            if (data.position) {
                setWindowPosition(data.position)
            }
            if (data.width && data.height && 
                (data.width !== windowResolution.width ||
                data.height !== windowResolution.height)) {
                setWindowResolution({ width: data.width, height: data.height });
            }
        }

        // Add offset to parent div when canvas is scrolled
        // if (props.parentOffset) {
        //     setDraggableParent(prev => {
        //         return {
        //             ...prev,
        //             offsetX: props.parentOffset.x,
        //             offsetY: props.parentOffset.y
        //         }
        //     })
        // }

        if (props.canvasData && props.canvasData.drawing) {
            setDraggableParent({
                width: props.canvasData.drawing.scrollWidth,
                height: props.canvasData.drawing.scrollHeight
            })
        }
    }, [props])

    React.useEffect(() => {
        if (gifData && gifData.id) {
            setMediaRO(new ResizeObserver(entries => {
                for (let entry of entries) {
                    const cr = entry.contentRect;
                    if (mediaResizeUpdate) clearTimeout(mediaResizeUpdate);
                    mediaResizeUpdate = setTimeout(() => {
                        var resizeResolution = { width: cr.width, height: cr.height };
                        if (resizeResolution.width < minImageResolution.width) resizeResolution.width = minImageResolution.width;
                        if (resizeResolution.height < minImageResolution.height) resizeResolution.height = minImageResolution.height;
                        // console.log(`Element size: ${cr.width}px x ${cr.height}px`);
                        setWindowResolution(resizeResolution)
                    }, 500)
                }
            }));
            if (mediaRO) {
                if (!observerCreated) {
                    setObserverCreated(true);
                    mediaRO.observe(document.getElementById(`media-${gifData.id}`))
                }
            }
        }
    }, [gifData])

    React.useEffect(() => {
        if (gifData && gifData.content) {
            const content = JSON.stringify({
                ...JSON.parse(gifData.content),
                width: windowResolution.width,
                height: windowResolution.height
            })
            ib.updateObject({
                id: gifData.id,
                content: content
            })
        }
    }, [windowResolution])

    function handleDrag(e, ui) {
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }

    function handleDragStart() {
    
    }

    function handleDragEnd(e, ui) {
        if (gifData) {
            const content = JSON.stringify({
                ...JSON.parse(gifData.content),
                position: { x: ui.lastX, y: ui.lastY }
            })
            ib.updateObject({
                id: gifData.id,
                content: content
            })
        }
    }

    function handleClose() {
        if (gifData.id) {
            ib.delObject(gifData.id)
        }
        setGifData(null);
    }

    return (
        <div className={classes.draggableParent}
            style={{
                width: draggableParent.width,
                height: draggableParent.height,
                top: draggableParent.offsetY,
                left: draggableParent.offsetX
            }}
        >
            {gifData && (
                <Draggable
                    bounds="parent"
                    handle=".handle-overlay"
                    onDrag={handleDrag}
                    onStart={handleDragStart}
                    onStop={handleDragEnd}
                    position={windowPosition}
                >
                    <div className={classes.draggableMedia}
                        id={`media-${gifData.id}`}
                        style={{
                            width: windowResolution.width,
                            height: windowResolution.height,
                            minWidth: minImageResolution.width,
                            minHeight: minImageResolution.height,
                        }}
                        ref={mediaRef}
                    >
                        <Button
                            className={classes.mediaClose}
                            style={{ 
                                minWidth: '30px',
                                display: isTeacher === 1 ? 'flex' : 'none',
                            }}
                            onClick={handleClose}>
                            <CloseIcon/>
                        </Button>
                        <div className="handle-overlay" style={{ 
                                display: "flex",
                                justifyContent: "flex-end",
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                zIndex: "99"
                            }}
                        >
                        </div>
                        <img className={classes.mediaItem} 
                            src={gifURL} 
                            alt="media-gif"
                        />
                    </div>
                </Draggable>
            )}
        </div>
    );
}
