import React from 'react';
import "../home.css"
import "./homeOver.css"
import {
    Box,
} from '@material-ui/core';

export default function Footer(props) {

    function newBoard() {
        if (props && props.setStartNewBoard){
            props.setStartNewBoard(true)
        } 
    }


    return (
        <div >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minWidth="100vw"
                className={props.sticky ? "bottomBox" : "bottomBox1"}
                textAlign="center"
            >
                <div className='footerDiv' style={{ alignItems: "center" }}>
                    <div className="headWBChatLast" >
                        <img src='WhiteboardchatBtn.svg' alt='whiteboard chat' />
                    </div>
                    <div
                        className="startDrawingButtonLast"
                        onClick={newBoard}
                    >
                        <img style={{ width: '24px' }} src='/ic_gesture_24px.png' alt='ud' />

                        Start Drawing
                    </div>
                    <div className='flex footFlex' style={{ justifyContent: 'space-evenly' }}>
                        <div className="copyright">
                            &#169;whiteboard.chat 2021
                        </div>
                        <div className="copyright">
                            feedback@whiteboard.chat
                        </div>
                    </div>
                </div>
            </Box>
        </div>

    )
}