import React from 'react';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button,
    TextField,
    Typography,
    Grid,
    Divider,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import clsx from "clsx";
import { useSelector } from 'react-redux'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: " 0.5px solid rgba(0, 0, 0, 0.08)",
        position: "absolute",
        width: "450px !important",
        minHeight: "300px",
        zIndex: 105,
        color: "#ffffff",
        backgroundColor: "#ffffff",
        boxSizing: "border-box",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16)",
        borderRadius: "4px",
    },
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    FormFactory: {
        margin: "25px 0px 0px 0px",
        padding: "0px 10px",
        fontSize: "16px",
        color: "#2F3D5B",
        fontWeight: "bolder",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    createButton: {
        color: "#FFFFFF",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "999px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        width: "100px",
        padding: "6px 12px",
        textTransform: "capitalize",
        letterSpacing: "-0.078px !important",
        "&:hover": {
            color: '#2F3D5B',
        }
    },
}))
export default function FormFactoryDialog(props) {
    const classes = useStyles();
    const [windowPosition, setWindowPosition] = React.useState({ x: 100, y: 280 });
    const [text, setText] = React.useState("")
    const boardConfig = useSelector((state) => state.BoardConfig);

    React.useEffect(()=>{
        let tEle = document.getElementById('tileFact')
        if(tEle){
            tEle.placeholder = 'l;label \n c;checkbox \n r;radiobutton \n t;text'
        }
     },[])
     
    function handleDrag(e, ui) {
        if ((ui.y < 120 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        props.cb(null)
    }
    function create(e) {
        props.cb({ text: text })
    }
    function pasteText(e) {
        e.preventDefault()
        setText(text + e.clipboardData.getData('text/plain'))
    }

        return (
            <Draggable
                handle="strong"
                onDrag={handleDrag}
                onStop={handleDragEnd}
                position={windowPosition}
                defaultClassName="draggable-color"
                defaultClassNameDragging="draggable2-color"
                defaultClassNameDragged="draggable3-color"
            >
                <div id="smallpalette"
                    className={clsx(classes.draggableWindow,'dragDiv dragDivWidth')}
                    style={{
                        border: '5px solid ' + props.inkColor,
                        // backgroundColor: '#ffffff44'
                    }}>
                    <div  className={clsx(classes.draggableTop, 'dragDivmain')}  style={{ width: "100%", backgroundColor: props.inkColor }}>
                        <strong  className={clsx(classes.draggableTopHandle, 'dragDivChild1')} >
                        <MoreHorizIcon style={{ color: "#7D8FB6", position: "absolute", top: "-7", left: "196px", cursor: "move" }} />
                        </strong>
                    </div>
                    <div className=' formPanel'>
                    <div className={classes.FormFactory}>
                        <span>Form Factory</span>
                        <CloseIcon style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%" }} onClick={handleClose} />
                    </div>
                    <div>
                        <FormFactoryPanelHelp />
                    </div>
                        <Grid container>
                            <Grid item sm={12} md={6} lg={6} className='rtBorder'>
                            <div style={{
                                color: "#2F3D5B",
                                fontSize: "14px",
                                marginLeft: "24px",
                            }}>
                                <span>E.g : </span>
                                <div>
                                    <li>l;It is best to drink:</li>
                                    <li>r;Water</li>
                                    <li>r;Juice</li>
                                    <li>c;check</li>
                                    <li>t;Type your answer.</li>
                                </div>
                            </div>
                            </Grid>
                            <Grid item sm={12} md={6} lg={6}>

                                <div className={clsx(classes.cp, 'dragDivForm')} style={{ width: "90%", marginLeft: "10px"}}>
                                    <TextField
                                        placeholder="l;label&#10;c;checkbox&#10;r;radiobutton&#10;t;text"
                                        multiline
                                        fullWidth
                                        onPaste={pasteText}
                                        id="tileFact"
                                        className='forTxtArea'
                                        rows={6}
                                        rowsMax={6}
                                        value={text}
                                        spellCheck={false}
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                </div>
                        </Grid>
                    </Grid>
                    <Divider
                        style={{ color: "#E0E5E7", margin: "10px -4px" }}
                    />
                    <div className='formBtns mt-10 mr-10 mb-10'>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        className = {classes.createButton}
                                        style={{ backgroundColor: props.inkColor }}
                                        onClick={() => create()}
                                    >
                                        Create
                                    </Button>
                                </div>
                    </div>

                </div>

            </Draggable>

        );
      
}

function FormFactoryPanelHelp() {
        return (
            <div className='flex ffHelp'>
                <div>List items in form using the following format</div>
            <div className="unorderList">
                <ul
                    style={{
                        marginTop: "0px",
                        marginLeft: "-40px",
                        lineHeight: "24px",
                    }}
                >
                    <li>Label or Value</li>
                    <li>l for label, c for checkbox, r for radiobutton, t for textbox</li>
                    <li>Label or Value is the Text placed next to the item or placeholder</li>
                    <li>Text within the textbox</li>
                </ul>
            </div>
            </div>
        )
}