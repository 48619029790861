import * as ib from './ibdata'
import React from 'react';
import "./home.css"
import { ListItem, List, ListItemText } from "@material-ui/core"
import { useDispatch } from 'react-redux'
import * as Actions from "./store/actions"

export default function View(props) {
    const dispatch = useDispatch();

    const [sess, setSession] = React.useState([])
    const [sessMap, setSessionMap] = React.useState({})
    React.useEffect(() => {
        var s1;

        dispatch(Actions.setNoUser())
        async function getSessionInf(sessl) {
            sessl['OBJ'] = await ib.listObjectSync(sessl.id)
            const iid = sessl.id
            sessMap[iid]['OBJ'] = sessl['OBJ']

            setSessionMap({
                ...sessMap,
            })
        }
        function gotSess(obj) {
            obj.map((ff) => {
                if (ff.id === "da4e6b16-5ea3-4b7d-8744-4926cbe63073-pgNum-1")
                    console.log("GOT SESSION")
                getSessionInf(ff)
                const iid = ff.id
                ff['OBJ'] = []
                const dd = new Date(ff.createdAt)
                ff['DT'] = dd.toLocaleString('en-US')
                sessMap[iid] = ff
                setSessionMap({
                    ...sessMap,
                })
            })
        }
        function allSessSubCB(allSessSub) {
            s1 = allSessSub;
        }
        ib.SessionSubscribeAll({
            "cb": gotSess,
            "doList": true,
            "subCB": allSessSubCB,
        });

        return () => {
            if (s1) s1.unsubscribe();
        }
    }, [])
    React.useEffect(() => {
        var mp = Object.values(sessMap)
        // mp.sort(function (a, b) {
        //     return b.OBJ.length - a.OBJ.length
        // })
        mp.sort((a, b) => {
            const atime = new Date(a.createdAt);
            const btime = new Date(b.createdAt);
            let ret = 0;
            /* Required to return -1, 0 or 1 else Chrome and Safari sort results are different */
            if ((atime - btime) === 0) {
                ret = 0
            } else {
                if (props.showReportSummaryFlag) ret = atime > btime ? 1 : -1;
                else ret = atime > btime ? -1 : 1;
            }
            // console.log("atime = ", atime, " btime = ", btime, "atime < btime" , (atime < btime), "ret = " , ret);
            return ret;
        });
        setSession([...mp])
    }, [sessMap])
    function Click(s) {
        const redir = "/board/" + s.id
        props.history.push(redir);

    }
    const url = window.location.href.replace("getViewXrXX", "board/");
    return (
        <>
            <div className="canvas-container" id="canvas_container">

                <List>
                    {sess.map((s) => (
                        <ListItem key={s.id}>
                            <a href={url + s.id} target="_blank">LINK</a>
                            <hr />
                            <ListItemText onClick={() => Click(s)} primary={s.id}
                                secondary={s.DT + " OBJ: " + s.OBJ.length} />
                        </ListItem>
                    ))}
                </List>
            </div>
        </>
    )
}