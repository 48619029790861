import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify'
import { Button, Grid, TextField, Typography, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import './index.css'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import { makeStyles } from '@material-ui/core/styles';
import * as mylocalStorage from "./mylocalStorage";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFormState = {
    email: '', password: '', newpassword: '', authcode: '', code: '', formType: 'signIn'
}
const useStyles = makeStyles((theme) => ({
    tooltip: {
        margin: "2px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#3174F5",
        fontSize: "0.4rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    },
}));

export default function CustomAuthUI(props) {
    const classes = useStyles();
    const [formState, updateFormState] = useState(initialFormState)
    const [inpType, setInpType] = useState('password')
    const [isPwdBS, setIsPwdBS] = useState(false);
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });

    const [content, setContent] = React.useState(null);
    const [severity, setSeverity] = React.useState('info')
    const [optIn, setOptIn] = React.useState(false)
    const [displayOptIn, setDisplayOptIn] = React.useState(true)
    useEffect(() => {
        if (props.loginState && props.loginState === 'logOut') {
            updateFormState(() => ({ ...formState, formType: 'signIn' }))
        }
    }, [props])


    useEffect(() => {
        //this is to reset on switching the tabs/forms
        setInpType('password')
        showHide('password')
    }, [formState])

 
    useEffect(()=>{
        var localOptIn = mylocalStorage.getItem('emailOptIn')
        var isStudent = mylocalStorage.getItem('IsStudent')
        if (isStudent === 'true') {
            setDisplayOptIn(false)
        } else {
            setDisplayOptIn(true)
        }
        if (localOptIn === 'true'){
            setOptIn(true)
        }else{
            setOptIn(false)
        }
    }, [])

    function onChange(e) {
        e.persist()
        let hasBlankSpace = HasBlankSpace('password')
        if (hasBlankSpace) {
            setIsPwdBS(true)
            disableButton()
        } else {
            setIsPwdBS(false)
        }
        enableButton()
        updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
    }

    function signUp(e) {
        e.preventDefault()
        const { email, password } = formState
        if(isPwdBS){
            
            handleClick()
            setContent('White spaces are not allowed')
            setSeverity('error')
            return
        }
        if (email && password) {
            const signUpInfo = {
                username: email.trim(),   // use email id as a username to login
                password: password,
            };
            Auth.signUp(signUpInfo).then(response => {
                    updateFormState(() => ({ ...formState, formType: 'confirmSignUp' }))
            })
            .catch(err => {
                console.error("AWS Validate request errored out: in SignUp ");
                console.error(err);
                setContent(err.message)
                setSeverity('error')
                handleClick()
            });
        }
    }

    function confirmSignUp(e) {
        e.preventDefault()
        const { email, authcode } = formState
        if (email && authcode) {
               const username= email.trim();   // use email id as a username to login
               const code= authcode;
            Auth.confirmSignUp(username, code).then(response => {
                    if (response === "SUCCESS") {
                        setContent('Validation complete, please login')
                        setSeverity('success')
                        handleClick()
                        updateFormState(() => ({ ...formState, formType: 'signIn' }))
                    }
                    else {
                        console.error("AWS Validate request did not have a response.user");
                    }
                }).catch(err => {
                    console.error("AWS Validate request errored out: in confirmSignUp ");
                    console.error(err);
                        setContent(err.message)
                        setSeverity('error')
                        handleClick()
                });
        }
    }

    function resendCode(){
        updateFormState(() => ({ ...formState, formType: 'resend' }))
    }

    function resendSignUp(e){
        e.preventDefault()
        var {email} = formState
        email = email.trim()
        if(email){
            Auth.resendSignUp(email).then(response => {
                    // console.log(response)
                    updateFormState(() => ({ ...formState, formType: 'signIn' }))
            })
            .catch(err => {
                console.error("AWS Validate request errored out: in resendSignUp ");
                console.error(err);
            });
        }
    }

   function signIn(e) {
        e.preventDefault();
        const { email, password } = formState
        if (email && password) {
            const signUpInfo = {
                username: email.trim(),   // use email id as a username to login
                password: password,
            };
            Auth.signIn(signUpInfo).then((response, reject) => {
                // console.log(response)
                updateFormState(() => ({ ...formState, formType: 'signedIn' }))
            })
                .catch(err => {
                    console.error("AWS Validate request errored out: in Signin ");
                    console.error(err);
                    setContent(err.message)
                    setSeverity('error')
                    handleClick()
                });
        }
    }

    function sendResetPasswordCode(e){
        e.preventDefault()
        const {email} = formState
        if(email){
            const username = email.trim()  // use email id as a username to login
            Auth.forgotPassword(username).then(response => {
                    // console.log(response)
                    updateFormState(() => ({ ...formState, formType: 'resetpwd' }))
            })
            .catch(err => {
                console.error("AWS Validate request errored out: in reset password ");
                console.error(err);
                if(err.code ==="InvalidParameterException"){
                    setContent(err.message)
                    setSeverity('error')
                    handleClick()
                }
            });
        }
    }

    function resetPassword(e){
        e.preventDefault()
        const { email, code, newpassword } = formState
        if(email){
            const username = email.trim()   // use email id as a username to login
            const password = newpassword
            Auth.forgotPasswordSubmit(username, code, password).then(response => {
                    // console.log(response)
                    updateFormState(() => ({ ...formState, formType: 'signIn' }))
            })
            .catch(err => {
                console.error("AWS Validate request errored out: in reset password ");
                console.error(err);
            });
        }
    }

    function HasBlankSpace(pId) {
        //as the this element is formsy we are not getting event.target.value,
        // it is null so using this doc.getElementById()
        let localBoolean1 = false;
        let newpwdValue = document.getElementById(pId)
        if (newpwdValue && newpwdValue.value) {
            localBoolean1 = /\s/.test(newpwdValue.value) ? true : false;
        }
        return localBoolean1
    }
    
    function showHide(inpType) {
        let fieldType = inpType === 'input' ? 'password' : 'input'
        return fieldType
    }

    const [isFormValid, setIsFormValid] = useState(false);

    function disableButton() {
        setIsFormValid(false);
    }

    function enableButton() {
        if (!isPwdBS) {
            setIsFormValid(true);
        }else{
            setIsFormValid(false);
        }
    }


    
  const handleClick = () => {
      setState({ ...state, open: true });

  };

  
  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
        setState({ ...state, open: false });
    };
    
    function push(path) {
        window.open(path)
    }
    
    const { vertical, horizontal, open } = state;


    const { formType } = formState

    function setEmailOptIn(){
        setOptIn(!optIn)
        mylocalStorage.setItem('emailOptIn', !optIn);
    }

    return (
        <div className='w-full customui'>
            {
                formType === 'signUp' && (
                    <>
                    <Grid container className='justify-center'>
                        <span className='Content'>To save your boards, please sign in.</span>

                        <form onSubmit={() => signUp}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='email' onChange={onChange} placeholder='Email'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='password' onChange={onChange} placeholder='Password' id='password'
                                    type={inpType}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start" onClick={(e) => { setInpType(showHide(inpType))}}>
                                        {inpType === 'input' ? <VisibilityOffOutlinedIcon  className="text-16 cursor-pointer" color="action" /> : <VisibilityOutlinedIcon   className="text-16 cursor-pointer" color="action"/>}
                                      </InputAdornment>
                                    }}
                                />
                                <p hidden={!isPwdBS} className='error-label p-0'>White space not allowed</p>

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <Button onClick={signUp} className='login' style={{marginTop: '0 !important'}} disabled={!isFormValid} type='submit'>SignUp</Button>
                            </Grid>
                        </form>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                            <div className='labelContent'>Back to <span className='btnLabel' onClick={() => {
                                updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                setIsPwdBS(false)
                            }}>Sign In</span></div>
                        </Grid>
                    </Grid>
                        <div style={{ textAlign: 'center', cursor: 'pointer', padding: '4px 0' }}>
                    <Typography variant="caption" color="textSecondary" >
                        Using whiteboard.chat implies agreement to our
                         <span onClick={(e) => {push('https://www.whiteboard.chat/tos')}}> terms of service</span>
                         <span onClick={(e) => {push("https://www.whiteboard.chat/privacy")}}> & privacy policy</span>
                    </Typography>
                    </div>
</>
                )
            }
            {
                formType === 'confirmSignUp' && (
                    <Grid container className='justify-center'>
                        <form onSubmit={() => confirmSignUp}>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='email' onChange={onChange} placeholder='Email'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }} 
                                    value={formState.email}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='authcode' onChange={onChange} placeholder='Confirmation code'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKeyOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>
                                <div className='displayFlexBlock wid70'>
                                    <Button onClick={resendCode} className='signBtn btn-rt-m dispBtninRow'>Resend Code</Button>
                                    <Button onClick={confirmSignUp} className='signBtn btn-lt-m dispBtninRow' type='submit'>Confirm Sign Up</Button>
                                    </div>

                                    <div className=' wid70'>
                                    <Button  onClick={() => {
                                        updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                    }}
                                    className='signBtn btn-rt-m dispBtnBlock'>Back to Sign in</Button>
                                    </div>
                            </Grid>
                        </form>
                    </Grid>
                )
            }

            {
                formType === 'signIn' && (
                    <Grid container className='justify-center'>
                        <span className='Content'>To save your boards, please sign in.</span>
                        <form onSubmit={((e) => signIn(e))}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>

                                <TextField name='email' onChange={onChange} placeholder='Email'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>

                                <TextField name='password' onChange={onChange} placeholder='Password'
                                id='password'
                                    type={inpType}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start" onClick={(e) => { setInpType(showHide(inpType))}}>
                                        {inpType === 'input' ? <VisibilityOffOutlinedIcon  className="text-16 cursor-pointer" color="action" /> : <VisibilityOutlinedIcon   className="text-16 cursor-pointer" color="action"/>}
                                      </InputAdornment>
                                    }}
                                />
                                <p hidden={!isPwdBS} className='error-label'>White space not allowed</p>

                                 <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                    <div className='labelContent wid70 flex justify-between'><span className='btnLabel text-alignleft forgotTxt' onClick={() => {
                                        updateFormState(() => ({ ...formState, formType: 'forgotpwd' }))
                                    }}>Forgot password?</span>
                                    <span className='btnLabel text-alignleft forgotTxt'onClick={() => {
                                        updateFormState(() => ({ ...formState, formType: 'confirmSignUp' }))
                                    }}>
                                        Verify Account
                                    </span>
                                    </div>
                                </Grid>
                                {displayOptIn ?
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='relative optin' onClick={() => { setEmailOptIn() }}>
                                        <input id='opt_in' type='checkbox' style={{ margin: 'auto 0' }} checked={optIn} value={optIn} onChange={() => { setEmailOptIn() }} />
                                        <Tooltip className={classes.tooltip}
                                            classes={{
                                                tooltip: classes.customTooltip,
                                            }}
                                        title="Select to allow whiteboard.chat to send you emails" aria-label="add" placement="top">
                                            <span className='btnLabel optBtn' for="opt_in">Send me feature announcements via email</span>
                                        </Tooltip>
                                    </Grid>
                                    :
                                    ''}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <Button onClick={signIn} className='login' disabled={!isFormValid} type='submit'>Sign In</Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>

                                <div className='wid70'>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Button onClick={props.loginWithGoogle} className='socialBtn'>With Google</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={props.loginWithFacebook} className='socialBtn'>With Facebook</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={props.loginWithMS} className='socialBtn'>Personal Microsoft</Button>
                                    </Grid>
                                </Grid>
                                </div>
                            </Grid>
                        </form>

                        <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-24'>
                            <div className='labelContent'>Don't have an account? <span className='btnLabel' onClick={() => {
                                setIsPwdBS(false)
                                updateFormState(() => ({ ...formState, formType: 'signUp' }))
                            }}>Sign Up</span></div>
                        </Grid>
                             <div style={{textAlign: 'center', cursor: 'pointer', padding:'4px 0'}}>
                    <Typography variant="caption" color="textSecondary" >
                        Using whiteboard.chat implies agreement to our
                         <span onClick={(e) => {push("https://www.whiteboard.chat/tos")}}> terms of service</span>
                         <span onClick={(e) => {push("https://www.whiteboard.chat/privacy")}}> & privacy policy</span>
                    </Typography>
                    </div>
                    </Grid>
                )
            }
            {
                formType === 'resend' && (
                    <Grid container className='justify-center'>
                        <form onSubmit={() => resendSignUp}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='email' onChange={onChange} placeholder='Enter Emailid'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <Button onClick={resendSignUp} className='signBtn btn-rt-m resendBtn' type='submit'>Resend Code</Button>
                            </Grid>
                        </form>
                    </Grid>
                )
            }

            {
                formType === 'forgotpwd' && (
                    <Grid container className='justify-center'>
                        <span className='Content'>Reset your password</span>
                        <form onSubmit={() => sendResetPasswordCode}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='email' onChange={onChange} placeholder='Enter Emailid'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <MailOutlineIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>

                            <div className='wid70 displayFlexBlock'>
                                <Button  onClick={() => {
                                    updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                }}
                                className='signBtn btn-rt-m dispBtninRow'>Back to Sign in</Button>
                                <Button onClick={sendResetPasswordCode} className='signBtn btn-lt-m dispBtninRow' type='submit'>Send Code</Button>
                                </div>
                            </Grid>
                        </form>
                    </Grid>
                )
            }

{
                formType === 'resetpwd' && (
                    <Grid container className='justify-center'>
                        <span className='Content'>Reset your password</span>
                        <form onSubmit={() => resetPassword}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='code' onChange={onChange} placeholder='Enter code'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <VpnKeyOutlinedIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField name='newpassword' type='password' onChange={onChange} placeholder='Enter new password'
                                     type={inpType}
                                     InputProps={{
                                         endAdornment: <InputAdornment position="start" onClick={(e) => { setInpType(showHide(inpType))}}>
                                         {inpType === 'input' ? <VisibilityOffOutlinedIcon  className="text-16 cursor-pointer" color="action" /> : <VisibilityOutlinedIcon   className="text-16 cursor-pointer" color="action"/>}
                                       </InputAdornment>
                                     }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>

                            <div className='flex wid70'>
                                <Button  onClick={() => {
                                    updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                }}
                                className='signBtn btn-rt-m'>Cancel</Button>
                                <Button onClick={resetPassword} className='signBtn btn-lt-m' type='submit'>Change Password</Button>
                                </div>
                            </Grid>
                        </form>
                    </Grid>
                )
            }
             <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}         
             anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={severity}>
          {content}
        </Alert>
      </Snackbar>
        </div >
    )
}