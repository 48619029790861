import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
    DialogTitle,
    RadioGroup,
    Radio,
    FormLabel,
    FormControlLabel,
    Typography,
    InputBase,
} from '@material-ui/core';
export default function PageAdvDialog(props) {
    const [link, setLink] = React.useState({ name: "", type: "password" });
    const handleSave = () => {
        if (props.cb) props.cb(link)
    };
    React.useEffect(() => {

    }, [props])
    const handleClose = () => {
        if (props.cb) props.cb(null)
    };
    function pasteText(e) {
        e.preventDefault()
        const v = e.clipboardData.getData('text/plain')
        if (!v) return
        v = v.replace("%", "")
        v.trim()
        setLink({
            ...link,
            [e.target.id]: v
        })
    }

    function handleType(e) {
        var v = e.target.value
        if (!v && !link.name){
            return
        } 
        v = v.replace("%", "")
        v.trim()
        setLink({
            ...link,
            [e.target.id]: v
        })
    }
    // function handleSubmit(e) {
    //     console.log("HIT SUBMIT")
    //     e.preventDefault()
    //     if (link.name.length >= 0 && link.link.length >= 0) handleSave()
    // }
    const [value, setValue] = React.useState('password');
    const helpText = {
        'password': 'Password',
        'score': 'Score (%age)',
    }
    const handleChange = (event) => {
        var v = event.target.value
        setValue(v);
        setLink({
            ...link,
            type: v
        })

    };
    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className= "mainHeader">Add Next Page Condtition</DialogTitle>
            <DialogContent>
                <DialogContentText className= "contextStyle" >
                    Please enter next page condition
          </DialogContentText>
                <FormLabel component="legend" className= "contextStyle"  style={{ marginBottom: "6px"}}>Condition Type</FormLabel>
                <div className= "radioButtonStyle">
                <RadioGroup aria-label="linkType" name="linkType" value={value} onChange={handleChange}>
                    <FormControlLabel value="score" control={<Radio />} label="Last Page Score (in %age)" />
                    <FormControlLabel value="password" control={<Radio />} label="Password" />
                </RadioGroup>
                </div>
                <InputBase
                    autoFocus
                    margin="dense"
                    id="name"
                    placeholder={helpText[value]}
                    fullWidth
                    value={link.name}
                    onPaste={pasteText}
                    onChange={(e) => handleType(e)}
                    className= "inputname"
                />
            </DialogContent>
            <DialogActions>
                <div className="positioning">
                    <Typography
                        variant="contained"
                        size="small"
                        style={{ marginRight: "24px", fontSize: "16px", }}
                        onClick={handleClose}
                        className='formCancel'
                    >
                        Cancel
                    </Typography>
                    <Button onClick={handleSave} color="primary" className="createButton" style={{ backgroundColor: props.inkColor }}
                        disabled={link.name.length <= 0}>
                        Save
                    </Button>
                </div>
            </DialogActions>

        </Dialog>
    );
}
