import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
    ListItemAvatar, Avatar, TextField, Tooltip,
    ListItemSecondaryAction, IconButton, Divider, Button
} from "@material-ui/core"
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as ib from "./ibdata"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import YesNoDialog from "./YesNoDialog"
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import * as mylocalStorage from "./mylocalStorage";

import clsx from 'clsx';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import Draggable from 'react-draggable';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Card from '@material-ui/core/Card';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Popover from '@material-ui/core/Popover';
import AutoCorrectLogo from './autoCorrect.svg';
import DeleteLogo from './delete.svg';
import LockLogo from './lock.svg';
import "./styles/drawerWalker.css";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'
import AutoCorrectModal from './AutoCorrectModal';

const useStyles = makeStyles({
    list: {
        
    },
    divList: {
        maxHeight: "50vh",
        overflow: "auto"
    },
    fullList: {
        width: 'auto',
    },
    rightSide: {
        marginLeft: 'auto',
    },
    small: {
        width: "0.7rem",
        height: "0.7rem",
    },
    threedots: {
        color: "#6B7280",
        position: "absolute",
        right: "0px",
        width: "24px",
        height: "32px",
        cursor: "pointer",
        "&:hover": {
            color: "#6B7280",
            backgroundColor: "rgba(170, 173, 181, 0.28)",
            borderRadius: "999px",
            width: "24px",
            height: "32px",
        },
    },
    drawer: {
        backgroundColor: "white",
        display: "flex",
        height: "auto",
        flexDirection: "column",
        // justifyContent: "center",
        border: "1px solid #E5E5E5",
        borderRadius: "4px",
        width: "226px !important",
        maxHeight: "70vh",
        zIndex: 105,
        color: "#ffffff",
    },
    dots: {
        color: "#7D8FB6",
        position: "relative",
        top: "-5px",
        "&:hover": {
            color: "#3174F5",
            cursor: "move",
        },
    },
    listItemText: {
        fontSize: "14px",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "capitalize",
        color: "#2F3D5B",
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#ffffff',
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
    },
    text1: {
        height: 40,
        background: "#FFFFFF",
        border: "1px solid #E0E5E7",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        borderRadius: "999px",
    },
    iconButton: {
        color: '#ffffff',
    },
    inviteButton1: {
        border: "1px solid #C8CEDA",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        margin: "10px 13px",
        padding: "9px 20px",
        height: '32px',
        width: "198px",
        fontSize: "12px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'flex',
        justifyContent: "space-around",
        border: "1px solid #C8CEDA",
        borderRadius: '999px',
        color: "#FFFFFF",
        "&:hover": {
            color: '#2F3D5B',
            backgroundColor: "#FFFFFF",
        },
    },
    inviteButton: {
        margin: "10px 13px",
        padding: "9px 20px",
        height: '32px',
        width: "198px",
        fontSize: "12px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'flex',
        justifyContent: "space-around",
        borderRadius: '999px',
        border: "1px solid #C8CEDA",
        boxShadow: "0px 1px 20px rgba(13, 20, 22, 0.1), 0px 0px 8px 2px rgba(13, 20, 22, 0.02)",
        color: "#ffffff",
        "&:hover": {
            color: '#2F3D5B',
            backgroundColor: "#FFFFFF",
        },
    },
    draggableTopHandle: {
        display: "flex",
        flexDirection: "column",
    },
    arrowUp: {
        position: "absolute",
        top: "6px",
        right: "52px",
        color: "#7D8FB6",
        cursor: "pointer",
        "&:hover": {
            color: "blue"
        }
    },
    arrowDown: {
        position: "absolute",
        top: "15px",
        right: "52px",
        color: "#7D8FB6",
        cursor: "pointer",
        "&:hover": {
            color: "blue"
        }
    },
    dialog: {
        width: "250px",
        height: "65px",
        backgroundColor: "#FFFFFF",
    },
    dialogText: {
        textAlign: "center",
    },
    okButton: {
        backgroundColor: "#3174F5",
        margin: "0px 20px",
        height: '38px',
        width: "80px",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'flex',
        justifyContent: "space-around",
        borderRadius: '999px',
        border: "1px solid #C8CEDA",
        color: "#ffffff",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
    draggableTopHandle: {
        display: "flex",
        flexDirection: "column",
    },
    arrowUp: {
        position: "absolute",
        top: "6px",
        right: "52px",
        color: "#7D8FB6",
        cursor: "pointer",
        "&:hover": {
            color: "blue"
        }
    },
    arrowDown: {
        position: "absolute",
        top: "15px",
        right: "52px",
        color: "#7D8FB6",
        cursor: "pointer",
        "&:hover": {
            color: "blue"
        }
    },
});
var gParts = []

export default function DrawerWalker(props) {
    const classes = useStyles();
    const drawerWalker = useSelector((state) => state.drawerWalker);
    const matrixGrid = useSelector((state) => state.matrixGrid);
    const allEvents = useSelector((state) => state.allEvents);

    const [parts, setParts] = React.useState([])
    const [text, setText] = React.useState("" + drawerWalker.page);
    const [open, setOpen] = React.useState(false);
    const [openAutoCorrect, setOpenAutoCorrect] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userData, setUserData] = React.useState(null);
    const xpos = window.innerWidth - 350
    const ypos = 120
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: ypos });
    const dispatch = useDispatch();
    function selectItem(e) {
        if (text === "") return
        if (e.localID && props && props.sess && props.sess.Classroom) {
            ib.findSessionbyID(props.sess.Classroom, e.localID).then((s) => {
                if (s && s.id) {
                    const s2 = s.id.split("-pgNum-")
                    var pgNum = getPage()
                    var newID = s2[0] + "-pgNum-" + pgNum
                    props.history.push("/board/" + newID)
                }
            })
        }
    }

    React.useEffect(() => {
        var s44, s45;
        var lockedArray
        if (!drawerWalker.open) {
            if (s44) s44.unsubscribe()
            if (s45) s45.unsubscribe()
            s44 = null
            s45 = null
            return
        }
        if (allEvents.events && allEvents.events.length > 0) {
            lockedArray = {}
            allEvents.events.forEach((e) => {
                if (e.type === "LockClass") {
                    if (e.Content && e.Content.creator) {
                        lockedArray[e.Content.creator] = e
                    }
                }
            })
        }
        if (props && props.sess && props.sess.Classroom) {
            function gotLocalUser(dat) {
                var ff = [...gParts]
                for (var i = 0; i < dat.length; i++) {
                    var d = dat[i]
                    var ll = d.id.split("-CL-")
                    if (!d.CurrentUser) continue
                    var cc = JSON.parse(d.CurrentUser.content)
                    var luid = ll[0]
                    var indx = ff.findIndex(x => x.id === d.id)
                    if (indx !== -1) ff.splice(indx, 1)
                    var loc = null
                    if (d.id in matrixGrid.gridMap) {
                        loc = matrixGrid.gridMap[d.id]
                    }
                    var locked = lockedArray && lockedArray[luid] ? true : false
                    var myluid = mylocalStorage.getItem('mystoreID');

                    ff.push({
                        name: ib.truncateString(d.CurrentUser.name, 10),
                        avatar: d.CurrentUser.avatar,
                        id: d.id,
                        localID: luid,
                        creator: luid === myluid ? true : false,
                        color: cc.color,
                        loc: loc,
                        locked: locked,
                    })
                }
                ff.sort((a, b) => {
                    const upb = b.name.toUpperCase();
                    const upa = a.name.toUpperCase();

                    if (upb < upa) return 1
                    if (upb > upa) return -1
                    return 0
                })
                setParts(ff)
                gParts = ff
            }

            function delLocalUser(u) {
                // var indx = ff.findIndex(x => x.id === luid)

            }
            function localUsersSubCB(localUsersSubs) {
                [s44, s45] = localUsersSubs;
            }
            gParts = []
            ib.SubscribeLocalUsersByClass({
                "ClassroomID": props.sess.Classroom,
                "cb": gotLocalUser,
                "delCB": delLocalUser,
                "doList": true,
                "subCB": localUsersSubCB
            })
        }
        return () => {
            if (s44) s44.unsubscribe()
            if (s45) s45.unsubscribe()
        }
    }, [props.sess, drawerWalker, matrixGrid, allEvents])
    function closeDrawer() {
        dispatch(Actions.setDrawerWalker({
            ...drawerWalker,
            open: false
        }))
    }

    function pasteText(e) {
        e.preventDefault()
        setText(e.clipboardData.getData('text/plain'))
    }
    function setTextVal(e) {
        setText(e.target.value)
        dispatch(Actions.setDrawerWalker({
            ...drawerWalker,
            page: e.target.value
        }))
    }
    const [yn, setyn] = React.useState({ open: false, message: "", cb: null })

    function delcb(e) {
        setOpen(false)
        var m = ""
        if (e.name) m = e.name
        setyn({ open: true, message: "Delete User " + m + " and their board?", cb: doneClick })
        function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (val === true) {
                // ib.clearAllBoards(props.sess)
                ib.deleteUserAndBoard(props.sess.Classroom, e.localID)
            }
        }
    }
    const [update, setUpdate] = React.useState(false)
    function setGrid(a, v) {
        try {
            a.loc = parseInt(v)
        } catch {
            return
        }
        // gParts[a.id] = v
        // setParts(gParts)
        setUpdate(true)
    }
    function ctInvite() {
        var luid = mylocalStorage.getItem('mystoreID');

        ib.getClassroom(props.sess.Classroom, cb)
        function cb(classR) {
            if (classR) {
                var content = {}
                if (classR.content) content = JSON.parse(classR.content)
                var ar = {}
                for (let id in gParts) {
                    var f = gParts[id]
                    ar[f.id] = gParts[id].loc
                }
                content[luid] = { gridMap: ar, classroom: props.sess.Classroom }
                classR.content = JSON.stringify(content)
                delete classR['Teacher']
                delete classR['TeacherList']
                delete classR['Users']
                ib.updateClassroom(classR).then((r) => {
                    window.location.reload()
                })
            }
        }
    }
    function ctInvite2() {
        var luid = mylocalStorage.getItem('mystoreID');

        ib.getClassroom(props.sess.Classroom, cb)
        function cb(classR) {
            if (classR) {
                var content = {}
                if (classR.content) content = JSON.parse(classR.content)
                var ar = {}
                for (let id in gParts) {
                    var f = gParts[id]
                    var idInt = parseInt(id)
                    //arrange alphabetical 
                    gParts[id].loc = idInt + 1
                    ar[f.id] = idInt + 1
                }
                content[luid] = { gridMap: ar, classroom: props.sess.Classroom }
                classR.content = JSON.stringify(content)
                delete classR['Teacher']
                delete classR['TeacherList']
                delete classR['Users']
                ib.updateClassroom(classR).then((r) => {
                    window.location.reload()
                })
            }
        }
    }
    function getPage() {
        var pgNum = text

        if (props.sess && props.sess.boardConfig) {
            try {
                var j = JSON.parse(props.sess.boardConfig)
                if (j.boardOrder) {
                    if (j.boardOrder.order[text])
                        pgNum = j.boardOrder.order[text]
                }
            } catch { }
        }
        return pgNum
    }
    function autoCorrectOne(e) {
        setOpen(false)
        setOpenAutoCorrect(true)
        var pgNum = getPage()
        ib.autoCorrect(props.sess.Classroom, e.localID, pgNum)
    }
    function lockBoard(e) {
        setOpen(false)
        var state = e.locked ? false : true
        ib.lockSingle(props.sess.Classroom, e.localID, state)

    }
    function decrementation() {
        var value = parseInt(text)
        if (value > 1) {
            setText(value - 1)
        }
    }
    function incrementation() {
        var value = parseInt(text)
        setText(value + 1)
    }

    function openDialog(ud, event) {
        setOpen(!open)
        setUserData(ud)
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    function handleDrag(e, ui) {
        if ((ui.y < 100 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }

    function handleDragEnd(e, ui) {
    }

    const CardBox = (props) => {
        return (
            <>
                <div className="popover">
                    <Popover
                        id="simple-popover"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <List>
                            <div className="cardBoxIn">
                                {!props.value.creator ?
                                    <ListItem button onClick={() => autoCorrectOne(props.value)}>
                                        <ListItemIcon>
                                            <img src={AutoCorrectLogo} alt="AutoCorrect Logo" />
                                        </ListItemIcon>
                                        <ListItemText primary="Auto correct" />
                                    </ListItem> :
                                    null}
                                <ListItem button onClick={() => lockBoard(props.value)}>
                                    <ListItemIcon>
                                        <img src={LockLogo} alt="Lock Logo" />
                                    </ListItemIcon>
                                    {!props.value.locked ? <ListItemText primary="Lock board" /> : <ListItemText primary="UnLock board" />}
                                </ListItem>
                                {!props.value.creator ?
                                    <ListItem button onClick={() => delcb(props.value)}>
                                        <ListItemIcon>
                                            <img src={DeleteLogo} alt="Delete Logo" />
                                        </ListItemIcon>
                                        <ListItemText primary="Delete board" />
                                    </ListItem>
                                    : null}

                            </div>
                        </List>
                    </Popover>
                </div>
            </>
        )
    }
    return (
        <>
            {openAutoCorrect && ( <AutoCorrectModal open={openAutoCorrect} setOpen={setOpenAutoCorrect} inkColor= {props.inkColor}/> )}
            {yn.open && <YesNoDialog {...yn} inkColor= {props.inkColor} />}
            { drawerWalker.open && (
                <Draggable
                    handle="strong"

                    onDrag={handleDrag}
                    onStop={handleDragEnd}
                    position={windowPosition}
                    defaultClassName="draggable-color"
                    defaultClassNameDragging="draggable2-color"
                    defaultClassNameDragged="draggable3-color"
                >
                    <div className={classes.drawer} >
                        <strong className={classes.draggableTopHandle}>
                            <div className="headerDots">
                                <MoreHorizIcon
                                    className={classes.dots} />
                            </div>
                            </strong>

                            <div className="participantHeader">
                                <span style={{ paddingLeft: "16px" }}> Participants </span>
                                <CloseIcon onClick={closeDrawer}
                                    style={{ backgroundColor: "#E9EBF0", borderRadius: "10px", marginRight: "10px" }}
                                />
                            </div>

                            <div className="pagenumber">
                                <span
                                    style={{ position: "absolute", zIndex: 999, top: 11, left: 40, color: "#2F3D5B", fontSize: "14px" }}
                                >Page number:</span>
                                <InputBase
                                    style={{ padding: "0px 0px 0px 104px", marginLeft: "28px", paddingTop: "2px", width: "160px", color: "#2F3D5B" }}
                                    className={classes.text1}
                                    type="text"
                                    value={text}
                                    onPaste={pasteText}
                                    onChange={setTextVal}
                                />
                                <KeyboardArrowUpIcon
                                    className={classes.arrowUp}
                                    onClick={() => incrementation()}
                                />
                                <KeyboardArrowDownIcon
                                    className={classes.arrowDown}
                                    onClick={() => decrementation()}
                                />
                            </div>
                            <Divider style={{ backgroundColor: "#F3F4F6", marginTop: "20px" }} />
                        <div className={classes.divList} >
                            <List className={classes.list}>
                                {parts.map((a) => {
                                    { var inverseColor = ((a.color === "white") || (a.color.toUpperCase() === "#FFFFFF")) ? "black" : "white" }
                                    return (
                                        <div className="listingItem">
                                            <ListItem key={a.id} fullWidth >
                                                <div className="inputNumber">
                                                    {a.loc !== null && (
                                                        <TextField
                                                            id="name"
                                                            type="text"
                                                            autoComplete="off"
                                                            // style={{color:"#2F3D5B",}}
                                                            defaultValue={a.loc}
                                                            onChange={(event) => setGrid(a, event.target.value)}
                                                            InputProps={{ disableUnderline: true }}
                                                            inputProps={{ size: '2', style: { textAlign: 'center', fontSize: '0.65rem', color: "#2F3D5B" } }}

                                                        />
                                                    )}
                                                </div>
                                                <div className="buttonName">
                                                    <Button onClick={() => selectItem(a)}>
                                                        <ListItemAvatar>
                                                            <Avatar
                                                                className={classes.small}
                                                                key={a.id}
                                                                src={a.avatar}
                                                                style={{ color: inverseColor, backgroundColor: a.color, height: "24px", width: "24px" }}
                                                            >
                                                                {a.name[0].toUpperCase()}
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            classes={{ primary: classes.listItemText }}
                                                            primary={a.name} secondary={a.email} />
                                                    </Button>
                                                </div>
                                                <MoreVertIcon
                                                    className={classes.threedots}
                                                    onClick={(event) => openDialog(a, event)}
                                                />

                                            </ListItem>
                                        </div>
                                    )
                                })}
                                {open && (<CardBox value={userData} />)}
                            </List>
                        </div>

                        {update && <Button className={classes.inviteButton1}
                            style={{ backgroundColor: props.inkColor }}

                            fullWidth
                            onClick={ctInvite}>Save Grid Position</Button>}
                        <Button
                            className={classes.inviteButton}
                            style={{ backgroundColor: props.inkColor }}
                            onClick={ctInvite2}>
                            Make Grid Alphabetical
                         </Button>
                    </div>
                </Draggable>
            )}
        </>
    );
}