import React, { useEffect } from 'react';
import * as ib from './ibdata'
import * as uu from './UsersUtils';
import ProgressComponent from '@material-ui/core/CircularProgress';
import axios from 'axios'
import AuthDialog from "./AuthDialog"
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import Subscribe from "./Subscribe";

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const urlEncode = function(data) {
  var str = [];
  for (var p in data) {
      if (data.hasOwnProperty(p) && (!(data[p] == undefined || data[p] == null))) {
          str.push(encodeURIComponent(p) + "=" + (data[p] ? encodeURIComponent(data[p]) : ""));
      }
  }
  return str.join("&");
}

var scriptLoaded = false;
var _isMounted = false;
var cbInstance = null;

const CURRENT_PLAN_TO_ID = {
    "Basic Monthly": "basic",
    "Basic Annual": "basicannual",
    "Gold Monthly": "gold",
    "Gold Annual": "goldannual",
    "Educator Free": "educator-free",
}

const CURRENT_ID_TO_PLAN = {
    "basic": "Basic Monthly",
    "basicannual": "Basic Annual",
    "gold": "Gold Monthly",
    "goldannual": "Gold Annual",
    "educator-free": "Educator Free",
}

export default function ChargeBee(props) {
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.user);
    const [user, setUser] = React.useState(null);
    const [chargeBeeId, setChargeBeeId] = React.useState(null);
    const [scriptReady, setScriptReady] = React.useState(false);
    const [showAuth, setShowAuth] = React.useState(false);
    let server = "https://oembed.whiteboard.chat:8000";

    // if (window.location.host.startsWith("localhost")) {
    //     server = "http://localhost:5005";
    // }
  
    const containerStyle = {
        width: "100%",
        height: "100%",
        marginRight: "0px",
        position: "fixed",
        left: "100px",
    };

    const loadingStyle = {
        top: "50%",
        left: "50%",
        alignItems: "center",
        position: "relative",
        justifyContent: "center"
    }

    function doneLoading() {
        // Finished loading the external_api.js script.  Now we can start Jitsi
        // console.log("Finished loading chargebee API");
        // If a conference has already started, then we do not need to call startConference
        scriptLoaded = true;
        setScriptReady(true);
    }

    React.useEffect(() => {
        if (!authUser) {
            return
        }

        function gotUser(u) {
            var prefix = "local^";

            // console.log("gotUser:", u);
            setUser(u);
            setChargeBeeId(uu.getChargeBeeId(u));
            // console.log("setChargeBeeId:", chargeBeeId);
        }

        var userInfo = ib.getUserInfo(authUser);
        ib.findCreateUserProfile(authUser.username, authUser.attributes.email,
            userInfo.avatar, userInfo.name, null, gotUser)
    }, [authUser])

    React.useEffect(() => {
        if (!chargeBeeId) {
            return
        }

        if (scriptReady) {
            // console.log("Setting ChargeBee User ID:", chargeBeeId);
            cbInstance = window.Chargebee.init({
                site: "epiphani"
            });
            cbInstance.setPortalSession(() => {
                // we have used axios for performing http requests
                // Hit your end point that returns portal session object as response
                // This sample end point will call the below api
                // https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session
                return axios.post(server + "/api/generate_portal_session", urlEncode({userId: chargeBeeId})).then((response) => {
                    // console.log("create session response:", response);
                    return response.data;
                });
            });
        }
    }, [chargeBeeId, scriptReady])

    useEffect(() => {
        // verify the JitsiMeetExternalAPI constructor is added to the global..
        if (scriptLoaded === false) {
            ib.runScript("https://js.chargebee.com/v2/chargebee.js", doneLoading);
        } else {
            doneLoading()
        }
        _isMounted = true;

        if (!authUser) {
            //No one signed in 
            dispatch(Actions.openAuthDialog({ open: true, siCB: signedIn, cancelCB: signUpCancel}))
        }

        return function cleanup() {
            _isMounted = false;
        }
    }, []);

    useEffect(()=>{
        if(showAuth === false){
            dispatch(Actions.openAuthDialog({ open: true, siCB: signedIn, cancelCB: signUpCancel}))
        }
    },[showAuth])

    function signedIn() {
        dispatch(Actions.openAuthDialog({ open: false, siCB: null }))
    }
    function signUpCancel() {
        props.history.goBack()
    }

    function handleCheckout(planId) {
        cbInstance.openCheckout({
            hostedPage() {
                return axios.post(server + "/api/generate_checkout_new_url", urlEncode({ planId: planId, userId: chargeBeeId })).then((response) => response.data)
            },
            success(hostedPageId) {
                // console.log(hostedPageId);
                user.paidPlan = planId;
                ib.updateUserProfile({ id: user.id, paidPlan: user.paidPlan }).then((res) => {
                    setUser(res.data.updateUserProfile)
                })
            }
            // close() {
            //     console.log("checkout new closed");
            // },
            // step(step) {
            //     console.log("checkout", step);
            // }
        })
    }

    function getActiveSubId(allSubs) {
        if (!allSubs) return null;
        for (let idx in allSubs) {
            if (allSubs[idx].subscription.status === "active") {
                return allSubs[idx].subscription.id;
            }
        }

        return null;
    }

    async function handleCheckoutExisting(planId) {
        let allSubs = await getCustomerSubscriptions();
        // console.log("allSubs:", allSubs);
        let activeSubId = getActiveSubId(allSubs);

        if (!activeSubId) {
            // console.log("No Active subscription found upgrade");
            return;
        } else {
            // console.log("Found Active ID:", activeSubId, "for sub:", chargeBeeId);
        }

        cbInstance.openCheckout({
            hostedPage() {
                return axios.post(server + "/api/generate_checkout_existing_url", urlEncode({ planId: planId, subId: activeSubId })).then((response) => response.data)
            },
            success(hostedPageId) {
                user.paidPlan = planId;
                ib.updateUserProfile({ id: user.id, paidPlan: user.paidPlan }).then((res) => {
                    setUser(res.data.updateUserProfile)
                })
                // console.log(hostedPageId);
            }
            // close() {
            //     console.log("checkout existing closed");
            // },
            // step(step) {
            //     console.log("checkout existing", step);
            // }
        });
    }

    function getCustomerSubscriptions() {
        return axios.post(server + "/api/get_customer_subscriptions", urlEncode({ userId: chargeBeeId })).then((response) => {
            // console.log("data:", response.data);
            return response.data;
        });
    }

    // function handlePortal() {
    //     cbInstance.createChargebeePortal().open({
    //         visit(visit) {
    //             console.log("portal visit", visit);
    //         }
    //     });
    // }

    function handleUpdatePM() {
        cbInstance.openCheckout({
            hostedPage() {
                return axios.post(server + "/api/generate_update_payment_method_url", urlEncode({ userId: chargeBeeId })).then((response) => response.data)
            }
            // close() {
            //     console.log("update payment method closed");
            // }
        });
    }

    function chargeBeeCB(event) {
        if (!authUser){
            setShowAuth(true)
            return
        } 
        
        if (event.type === "Select") {
            if (user.paidPlan) {
                handleCheckoutExisting(event.planId)
            } else {
                handleCheckout(event.planId)
            }
        } else if (event.type === "UpdatePayment") {
            handleUpdatePM()
        }
    }

    return (
        <div>
            {!scriptReady && <ProgressComponent />}
            {scriptReady && 
                <div>
                    {showAuth && <AuthDialog noGear={true} setShowAuth={setShowAuth} />}
                    {authUser ?
                        // <div>
                        //     <a href="#" onClick={handleCheckout}>Subscribe</a>
                        //     <a href="#" onClick={handleCheckoutExisting}>Upgrade</a>
                        //     <a href="#" onClick={getCustomerSubscriptions}>Show Subscriptions</a>
                        // </div>
                        <Subscribe {...props} cb={chargeBeeCB} paidPlan={(user && user.paidPlan) ? user.paidPlan : "educator-free"} />
                        : <Subscribe {...props} cb={chargeBeeCB} paidPlan="" />
                    }
                </div>
            }
        </div>
    );
}
