import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
    DialogTitle,
    RadioGroup,
    Radio,
    FormLabel,
    FormControlLabel,
    InputBase,
    Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux'
export default function LinkDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const teacherR = useSelector((state) => state.teacher);

    const [link, setLink] = React.useState({ name: "", link: "", type: "website" });
    const handleSave = () => {
        setOpen(false);
        if (props.cb) props.cb(link)
    };
    React.useEffect(() => {
        setOpen(props.open)
        if (props.noName) {
            setLink({
                ...link,
                name: "nonthing"
            })
        }
    }, [props])
    const handleClose = () => {
        setOpen(false);
        if (props.cb) props.cb(null)
    };
    function pasteText(e) {
        e.preventDefault()
        const txt = e.clipboardData.getData('text/plain')
        setLink({
            ...link,
            [e.target.id]: txt
        })
    }

    function handleType(e) {
        setLink({
            ...link,
            [e.target.id]: e.target.value
        })
    }
    // function handleSubmit(e) {
    //     console.log("HIT SUBMIT")
    //     e.preventDefault()
    //     if (link.name.length >= 0 && link.link.length >= 0) handleSave()
    // }
    const [value, setValue] = React.useState('website');
    const helpText = {
        'website': 'Link Address',
        'pageInBook': 'Page number',
    }
    const handleChange = (event) => {
        var v = event.target.value
        setValue(v);
        if (v === "autoCorrect" || v === "runCodeBlock") {
            setLink({
                ...link,
                link: "default", 
                type: v
            })
        } else {
            setLink({
                ...link,
                link: '',
                type: v
            })
        }
    };
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className= "mainHeader">Add Link</DialogTitle>
            <DialogContent>
                <DialogContentText className= "contextStyle">
                    Please input the name and URL for the new link
          </DialogContentText>
                <div className= "radioButtonStyle">
                <FormLabel component="legend" className= "contextStyle" style={{ marginBottom: "6px" }}>Link Type</FormLabel>
                <RadioGroup aria-label="linkType" name="linkType" value={value} onChange={handleChange}>
                    <FormControlLabel value="website" control={<Radio />} label="Website" />
                    <FormControlLabel value="pageInBook" control={<Radio />} label="Page In Book" />
                    {Boolean(teacherR) ? <FormControlLabel value="autoCorrect" control={<Radio />} label="Auto Correct" /> : null}
                    {Boolean(teacherR) ? <FormControlLabel value="runCodeBlock" control={<Radio />} label="Run Code Block" /> : null}
                </RadioGroup>
                </div>
                <div style= {{margin:"8px 0px"}}>
                {!props.noName && (
                    <InputBase
                        autoFocus
                        margin="dense"
                        id="name"
                        placeholder="Name"
                        fullWidth
                        value={link.name}
                        onPaste={pasteText}
                        onChange={(e) => handleType(e)}
                        className= "inputname"
                    />
                )}
                </div>
                <div style= {{margin:"8px 0px"}}>
                {(link.type !== "autoCorrect"  && link.type !== "runCodeBlock") && (
                    <InputBase
                        margin="dense"
                        id="link"
                        placeholder={helpText[value]}
                        fullWidth
                        value={link.link}
                        onChange={(e) => handleType(e)}
                        onPaste={pasteText}
                        className= "inputname"
                    />
                )}
                </div>
            </DialogContent>
            <DialogActions>
                <div>
                    <Typography
                        variant="contained"
                        size="small"
                        style={{ marginRight: "24px", fontSize: "16px", }}
                        onClick={handleClose}
                        className='formCancel'
                    >
                        Cancel
                    </Typography>
                    <Button onClick={handleSave} className="createButton" style={{ backgroundColor: props.inkColor }} disabled={link.name.length <= 0 || link.link.length <= 0}>
                        Save
                    </Button>
                </div>
            </DialogActions>

        </Dialog>
    );
}
