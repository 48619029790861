import React, { useState, useEffect } from "react";
import { Auth } from 'aws-amplify'
import { Button, Grid, TextField, Typography, Tooltip, Dialog, DialogActions,
         DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import './index.css'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import './styles/customAuth.css'
import * as microsoftTeams from "@microsoft/teams-js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialFormState = {
    email: '', password: '', newpassword: '', authcode: '', code: '', formType: 'signIn'
}
const useStyles = makeStyles((theme) => ({
    tooltip: {
        margin: "2px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#3174F5",
        fontSize: "0.4rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    },
}));

export default function CustomAuthNewUI(props) {
    const classes = useStyles();
    const [formState, updateFormState] = useState(initialFormState)
    const [inpType, setInpType] = useState('password')
    const [isPwdBS, setIsPwdBS] = useState(false);
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
      });

    const [content, setContent] = React.useState(null);
    const [severity, setSeverity] = React.useState('info')
    const [optIn, setOptIn] = React.useState(false)
    const [displayOptIn, setDisplayOptIn] = React.useState(true)
    const [confirmMSPersonalDialogOpen, setConfirmMSPersonalDialogOpen] = React.useState(false)

    useEffect(() => {
        if (window.self !== window.top) {
            microsoftTeams.initialize(() => {
              window.insideMSTeams = true;
            });
          } else {
            window.insideMSTeams = false
          }
    }, [])

    useEffect(() => {
        if (props.loginState && props.loginState === 'logOut') {
            updateFormState(() => ({ ...formState, formType: 'signIn' }))
        }
    }, [props])


    useEffect(() => {
        //this is to reset on switching the tabs/forms
        setInpType('password')
        showHide('password')
    }, [formState])
    
    useEffect(() => {
        disableButton()
    }, [formState.formType])
 
    useEffect(()=>{
        var localOptIn = localStorage.getItem('emailOptIn')
        var isStudent = localStorage.getItem('IsStudent')
        if (isStudent === 'true') {
            setDisplayOptIn(false)
        } else {
            setDisplayOptIn(true)
        }
        if (localOptIn === 'true'){
            setOptIn(true)
        }else{
            setOptIn(false)
        }
    }, [])

    function onChange(e) {
        e.persist()
        let hasBlankSpace = HasBlankSpace('password')
        if (hasBlankSpace) {
            setIsPwdBS(true)
            disableButton()
        } else {
            setIsPwdBS(false)
        }
        if (e && e.target && e.target.value) {
            enableButton()
        }else{
            disableButton()
        }
        updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
    }

    function signUp(e) {
        e.preventDefault()
        const { email, password } = formState
        if(isPwdBS){
            
            handleClick()
            setContent('White spaces are not allowed')
            setSeverity('error')
            return
        }
        if (email && password) {
            const signUpInfo = {
                username: email.trim(),   // use email id as a username to login
                password: password,
            };
            Auth.signUp(signUpInfo).then(response => {
                    updateFormState(() => ({ ...formState, formType: 'confirmSignUp' }))
            })
            .catch(err => {
                console.error("AWS Validate request errored out: in SignUp ");
                console.error(err);
                setContent(err.message)
                setSeverity('error')
                handleClick()
            });
        }
    }

    function confirmSignUp(e) {
        e.preventDefault()
        const { email, authcode } = formState
        if (email && authcode) {
               const username= email.trim();   // use email id as a username to login
               const code= authcode;
            Auth.confirmSignUp(username, code).then(response => {
                    if (response === "SUCCESS") {
                        setContent('Validation complete, please login')
                        setSeverity('success')
                        handleClick()
                        updateFormState(() => ({ ...formState, formType: 'signIn' }))
                    }
                    else {
                        console.error("AWS Validate request did not have a response.user");
                    }
                }).catch(err => {
                    console.error("AWS Validate request errored out: in confirmSignUp ");
                    console.error(err);
                        setContent(err.message)
                        setSeverity('error')
                        handleClick()
                });
        }
    }

    function resendCode(){
        updateFormState(() => ({ ...formState, formType: 'resend' }))
    }

    function resendSignUp(e){
        e.preventDefault()
        const {email} = formState
        if(email){
            Auth.resendSignUp(email.trim()).then(response => {
                    // console.log(response)
                    updateFormState(() => ({ ...formState, formType: 'signIn' }))
            })
            .catch(err => {
                console.error("AWS Validate request errored out: in resendSignUp ");
                console.error(err);
                    setContent(err.message)
                    setSeverity('error')
                    handleClick()
            });
        }
    }

   function signIn(e) {
        e.preventDefault();
        const { email, password } = formState
        if (email && password) {
            const signUpInfo = {
                username: email.trim(),   // use email id as a username to login
                password: password,
            };
            Auth.signIn(signUpInfo).then((response, reject) => {
                // console.log(response)
                updateFormState(() => ({ ...formState, formType: 'signedIn' }))
                window.location.reload()
            })
                .catch(err => {
                    console.error("AWS Validate request errored out: in Signin ");
                    console.error(err);
                    setContent(err.message)
                    setSeverity('error')
                    handleClick()
                });
        }
    }

    function sendResetPasswordCode(e){
        e.preventDefault()
        const {email} = formState
        if(email){
            const username = email.trim()  // use email id as a username to login
            Auth.forgotPassword(username).then(response => {
                    // console.log(response)
                    updateFormState(() => ({ ...formState, formType: 'resetpwd' }))
            })
            .catch(err => {
                console.error("AWS Validate request errored out: in reset password ");
                console.error(err);
                if(err.code ==="InvalidParameterException"){
                    setContent(err.message)
                    setSeverity('error')
                    handleClick()
                }
            });
        }
    }

    function resetPassword(e){
        e.preventDefault()
        const { email, code, newpassword } = formState
        if(email){
            const username = email.trim()   // use email id as a username to login
            const password = newpassword
            Auth.forgotPasswordSubmit(username, code, password).then(response => {
                    // console.log(response)
                    updateFormState(() => ({ ...formState, formType: 'signIn' }))
            })
            .catch(err => {
                console.error("AWS Validate request errored out: in reset password ");
                console.error(err);
                if (err.code === "CodeMismatchException") {
                    setContent(err.message)
                    setSeverity('error')
                    handleClick()
                }
            });
        }
    }

    function HasBlankSpace(pId) {
        //as the this element is formsy we are not getting event.target.value,
        // it is null so using this doc.getElementById()
        let localBoolean1 = false;
        let newpwdValue = document.getElementById(pId)
        if (newpwdValue && newpwdValue.value) {
            localBoolean1 = /\s/.test(newpwdValue.value) ? true : false;
        }
        return localBoolean1
    }
    
    function showHide(inpType) {
        let fieldType = inpType === 'input' ? 'password' : 'input'
        return fieldType
    }

    const [isFormValid, setIsFormValid] = useState(false);

    function disableButton() {
        setIsFormValid(false);
    }

    function enableButton() {
        if (!isPwdBS) {
            setIsFormValid(true);
        }else{
            setIsFormValid(false);
        }
    }


    
  const handleClick = () => {
      setState({ ...state, open: true });

  };

  
  const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
          return;
        }
        setState({ ...state, open: false });
    };
    
    function push(path) {
        window.open(path)
    }
    
    const { vertical, horizontal, open } = state;


    const { formType } = formState

    function setEmailOptIn(){
        setOptIn(!optIn)
        localStorage.setItem('emailOptIn', !optIn);
    }

    function MSPersonalLoginDialog(props) {
        function handleCancel() {
            setConfirmMSPersonalDialogOpen(false);
        }

        function handleContinue() {
            setConfirmMSPersonalDialogOpen(false);
            props.loginWithMS()
        }

        return (
            <div className='customui newClsAuthD'>
            <Dialog
                open={props.open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ color: '#3174F5' }}>{"Microsoft Accounts for Login"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please note that currently only Microsoft Personal accounts are supported for login.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCancel}>
                    Cancel
                </Button>
                <Button onClick={handleContinue} autoFocus>
                    Continue
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        )
    }

    return (
        <div className='w-full customui newClsAuthD'>
            {
                formType === 'signUp' && (
                    <>
                        <div style={{ margin: '0 110px 10px' }}>
                            <div className='logoContainer'>
                                <img src='/md-logo.svg' alt='logo' style={{ padding: '10px 0 0' }} />
                            </div>
                            <Grid container className='justify-center'>
                                <span className='Content newContent'>To save your boards, please sign up.</span>

                                <form onSubmit={() => signUp}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                        <TextField name='email' onChange={onChange} placeholder='Email'
                                            label='Email Address'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>
                                        <TextField name='password' onChange={onChange} label='Password' id='password'
                                            type={inpType}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <p hidden={!isPwdBS} className='error-label p-0'>White space not allowed</p>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-24'>
                                        <Button onClick={signUp} className='login' style={{ marginTop: '0 !important' }} disabled={!isFormValid} type='submit'>SignUp</Button>
                                    </Grid>
                                </form>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                    <div className='labelContent'>Already have an account? <span className='btnLabel' onClick={() => {
                                        updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                        setIsPwdBS(false)
                                    }}>Sign In</span></div>
                                </Grid>
                                { (window && window.insideMSTeams === false) ? <>
                                <div className='flexCenter dividerContent'>
                                    <img src='/Divider.svg' alt='divider' />
                                    <Typography className='joinContent'>
                                        Or continue with
                                    </Typography>
                                    <img src='/Divider.svg' alt='divider' />
                                </div>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>

                                    <div className=''>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Button onClick={props.loginWithGoogle} className='socialBtn'><img width="20px" style={{ filter: 'grayscale(100%)' }} src="/G_Logo.svg" alt='google'/></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={props.loginWithFacebook} className='socialBtn'><img src='/fb.svg' alt='facebook' /></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={(e) => { setConfirmMSPersonalDialogOpen(true)}} className='socialBtn'><img src='/mteams.svg' alt='Personal Microsoft' /></Button>
                                                {/* <Button onClick={props.loginWithMS} className='socialBtn'><img src='/mteams.svg' alt='Personal Microsoft' /></Button> */}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                </> : null }
                            </Grid>
                        </div>
                            <div className='termPriv' style={{ textAlign: 'center', cursor: 'pointer', padding: '4px 0', margin:"15px" }}>
                                <Typography variant="caption" color="textSecondary" className='lato12-info'>
                                    Using whiteboard.chat implies agreement to our
                         <span onClick={(e) => { push('https://www.whiteboard.chat/tos') }}> terms of service</span>
                                    <span onClick={(e) => { push("https://www.whiteboard.chat/privacy") }}> & privacy policy</span>
                                </Typography>
                            </div>
                        <MSPersonalLoginDialog {...props} open={confirmMSPersonalDialogOpen}/>
                    </>
                )
            }
            {
                formType === 'confirmSignUp' && (
                    <Grid container className='justify-center'>
                        <div className='dispFlexEnd posClose'>
                            <img src='/close.svg' alt='close' className='ndCloseIcon' onClick={() => {
                                props.handleClose()
                            }} />
                        </div>
                        <div style={{ margin: '0 110px 10px', width: '100%' }}>
                            <div className='logoContainer'>
                                <img src='/md-logo.svg' alt='logo' style={{ padding: '10px 0 0' }} />
                            </div>
                            <span className='Content newContent'>Verify Account</span>
                        <form onSubmit={() => confirmSignUp}>
                        <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>
                                <TextField name='email' onChange={onChange} label='Email Address'
                                   InputLabelProps={{
                                    shrink: true,
                                }}
                                    value={formState.email}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>
                                <TextField name='authcode' onChange={onChange} label='Confirmation code'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>
                                <div className='displayFlexBlock btnPanel'>
                                    <Button  onClick={resendCode} className='signBtn btn-rt-m dispBtninRow'>Resend Code</Button>
                                    <Button onClick={confirmSignUp} className='signBtn btn-lt-m dispBtninRow' type='submit'>Confirm Sign Up</Button>
                                    </div>

                                    <div className=''>
                                    <Button  onClick={() => {
                                        updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                    }}
                                    className='signBtn btn-rt-m dispBtnBlock'>Back to Sign in</Button>
                                    </div>
                            </Grid>
                        </form>
                    </div>
                    </Grid>
                )
            }

            {
                formType === 'signIn' && (
                    <div>
                    <Grid container className='justify-center'>
                        <div className='dispFlexEnd posClose'>
                        <img src='/close.svg' alt='close' className='ndCloseIcon' onClick={() => {
                            props.handleClose()
                        }}/>
                        </div>
                        <div style={{ margin: '0 110px 10px' }}>
                            <div className='logoContainer'>
                                <img src='/md-logo.svg' alt='logo' style={{ padding: '10px 0 0' }} />
                            </div>
                            <Typography className='Content newContent'>To save your boards, please sign in.</Typography>
                            <form onSubmit={((e) => signIn(e))}>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>

                                    <TextField name='email' onChange={onChange} 
                                        label='Email Address'
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                      
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>

                                    <TextField name='password' onChange={onChange} label='Password'
                                        id='password'
                                        type={inpType}
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                    />
                                    <p hidden={!isPwdBS} className='error-label'>White space not allowed</p>

                                    <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                        <div className='labelContent  flex justify-between'>
                                            <span className='btnLabel text-alignleft forgotTxt' onClick={() => {
                                                updateFormState(() => ({ ...formState, formType: 'confirmSignUp' }))
                                            }}>
                                                Verify Account
                                            </span>
                                            <span className='btnLabel text-alignleft forgotTxt' onClick={() => {
                                                updateFormState(() => ({ ...formState, formType: 'forgotpwd' }))
                                            }}>Forgot password?</span>
                                        </div>
                                    </Grid>
                                    {displayOptIn ?
                                        <Grid item xs={12} sm={12} md={12} lg={12} className='relative optin flexCenter' onClick={() => { setEmailOptIn() }}>
                                            <input id='opt_in' type='checkbox' style={{ margin: 'auto 0' }} checked={optIn} value={optIn} onChange={() => { setEmailOptIn() }} />
                                            <Tooltip className={classes.tooltip}
                                                classes={{
                                                    tooltip: classes.customTooltip,
                                                }}
                                                title="Select to allow whiteboard.chat to send you emails" aria-label="add" placement="top">
                                                <span className='btnLabel optBtn' for="opt_in">Send me feature announcements via email</span>
                                            </Tooltip>
                                        </Grid>
                                        :
                                        ''}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                    <Button onClick={signIn} className='login sigInBtn' disabled={!isFormValid} type='submit'>Sign In</Button>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} className='relative flexCenter mb-10'>
                                    <div className='labelContent'>Don't have an account? <span className='btnLabel' onClick={() => {
                                        setIsPwdBS(false)
                                        updateFormState(() => ({ ...formState, formType: 'signUp' }))
                                    }}>Sign Up</span></div>
                                </Grid>
                               
                                { (window && window.insideMSTeams === false) ? <>
                                <div className='flexCenter dividerContent'>
                                    <img src='/Divider.svg' alt='divider' />
                                    <Typography className='joinContent'>
                                        Or continue with
                                    </Typography>
                                    <img src='/Divider.svg' alt='divider' />
                                </div>
                                <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>

                                    <div className=''>
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Button onClick={props.loginWithGoogle} className='socialBtn'><img width="20px" style={{ filter: 'grayscale(100%)' }} src="/G_Logo.svg" alt='google'/></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={props.loginWithFacebook} className='socialBtn'><img src='/fb.svg' alt='facebook'/></Button>
                                            </Grid>
                                            <Grid item>
                                                <Button onClick={(e) => setConfirmMSPersonalDialogOpen(true)} className='socialBtn'><img src='/mteams.svg' alt='Personal Microsoft'/></Button>
                                                {/* <Button onClick={props.loginWithMS} className='socialBtn'><img src='/mteams.svg' alt='Personal Microsoft'/></Button> */}
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                </> : null }
                            </form>

                        </div>
                            <div  className='termPriv' style={{ textAlign: 'center', cursor: 'pointer', padding: '4px 0', margin:"15px" }}>
                                <Typography variant="caption" color="textSecondary" className='lato12-info'>
                                    Using whiteboard.chat implies agreement to our
                         <span onClick={(e) => { push("https://www.whiteboard.chat/tos") }}> terms of service</span>
                                    <span onClick={(e) => { push("https://www.whiteboard.chat/privacy") }}> & privacy policy</span>
                                </Typography>
                            </div>
                    </Grid>
                    <MSPersonalLoginDialog {...props} open={confirmMSPersonalDialogOpen}/>
                    </div>
                )
            }
            {
                formType === 'resend' && (
                    <Grid container className='justify-center'>
                        <div className='dispFlexEnd posClose'>
                            <img src='/close.svg' alt='close' className='ndCloseIcon' onClick={() => {
                                props.handleClose()
                            }} />
                        </div>
                        <div style={{ margin: '0 110px 10px', width: '100%' }}>
                            <div className='logoContainer'>
                                <img src='/md-logo.svg' alt='logo' style={{ padding: '10px 0 0' }} />
                            </div>
                            <span className='Content newContent'>Resend Code</span>
                        <form onSubmit={() => resendSignUp}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-24'>
                                <TextField name='email' onChange={onChange} label='Enter Email Address'
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative btnPanel'>
                                <div className='displayFlexBlock btnPanel mt-16'>
                                    <Button onClick={resendSignUp} disabled={!isFormValid} className='signBtn btn-rt-m' type='submit'>Resend Code</Button>
                                    <Button onClick={() => {
                                        updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                    }}
                                        className='signBtn btn-rt-m '>Back to Sign in</Button>
                                </div>
                            </Grid>
                        </form>
                        </div>
                    </Grid>
                )
            }

            {
                formType === 'forgotpwd' && (
                    <>
                        <div className='dispFlexEnd'>
                            <img src='/close.svg' alt='close' className='ndCloseIcon' onClick={() => {
                                props.handleClose()
                            }} />
                        </div>
                    <div style={{ margin: '0 110px 10px' }}>
                        <div className='logoContainer'>
                            <img src='/md-logo.svg' alt='logo' style={{ padding: '10px 0 0' }} />
                        </div>
                    <Grid container className='justify-center'>
                        <span className='Content newContent'>Reset your password</span>
                        <form onSubmit={() => sendResetPasswordCode}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='email' onChange={onChange} label='Enter Email Address'
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>

                            <div className='btnPanel displayFlexBlock'>
                                <Button  onClick={() => {
                                    updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                }}
                                className='signBtn btn-rt-m dispBtninRow'>Back to Sign in</Button>
                                <Button onClick={sendResetPasswordCode} disabled={!isFormValid} className='signBtn btn-lt-m dispBtninRow' type='submit'>Send Code</Button>
                                </div>
                            </Grid>
                        </form>
                    </Grid>
                        </div>
                        </>
                )
            }

{
                formType === 'resetpwd' && (
                    <Grid container className='justify-center'>
                         <div className='dispFlexEnd posClose'>
                        <img src='/close.svg' alt='close' className='ndCloseIcon' onClick={() => {
                            props.handleClose()
                        }}/>
                    </div>
                        <div style={{ margin: '0 110px 10px' }}>
                            <div className='logoContainer'>
                                <img src='/md-logo.svg' alt='logo' style={{ padding: '10px 0 0' }} />
                            </div>
                        <span className='Content newContent'>Reset your password</span>
                        <form onSubmit={() => resetPassword}>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative'>
                                <TextField name='code' onChange={onChange} label='Enter code'
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                                />
                                <TextField className='mt-16' name='newpassword' type='password' onChange={onChange} label='Enter new password'
                                     type={inpType}
                                     InputLabelProps={{
                                        shrink: true,
                                      }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='relative mt-16'>

                                    <div className='btnPanel displayFlexBlock'>
                                        <Button onClick={() => {
                                            updateFormState(() => ({ ...formState, formType: 'signIn' }))
                                        }}
                                            className='signBtn btn-rt-m'>Cancel</Button>
                                        <Button onClick={resetPassword} className='signBtn btn-lt-m' type='submit'>Change Password</Button>
                                    </div>
                            </Grid>
                        </form>
                    </div>
                    </Grid>
                )
            }
             <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}         
             anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={severity}>
          {content}
        </Alert>
      </Snackbar>
        </div >
    )
}
