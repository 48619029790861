import React, { useState, useEffect, useRef } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import HelpCard from './HelpCard';
import HighlightElement from './HighlightElement';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(0.5),
    color: '#FFF',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: 'rgba(20, 25, 30, 0.2)',
  },
  fab: {
    margin: theme.spacing(1),
  }
}));

export default function OverlayGuide(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(props.open);
    const [paused, setPaused] = React.useState(false);
    const [cardIdActive, setCardIdActive] = useState(0);

    const handleBackdropClick = (event) => {
        event.stopPropagation();
        // DEBUG CODE - REMOVE BEFORE COMMIT
        // setCardIdActive(wbEditorTeach.length-1);
    }
    const handleClose = (closeAction = 'shownexttime') => {
        setOpen(false);
        setCardIdActive(0);
        props.onClose(closeAction);
    };
      const handleToggle = () => {
        setOpen(!open);
    };
    useEffect(() => {
      setOpen(props.open);
    }, [props.open])
    
    const wbEditorTeach = [
        {
            title: 'Hello!',
            helpText: ['Would you like a tour of whiteboard.chat?',
                    'Please click yes below to start.',
                    '',
                    'In a hurry ? No problem, this tour can be started',
                    'again from the highlighted help icon at the top right.'],
            timeout: '0',
            actionText: "Yes, tour please",
            position: 'center',
            anchorElId: 'helpButton',
            icon: "fa-map-signs",
            flipicon: false,
            navigation: false,
        },
        {
            title: 'Toolbar',
            helpText: ['Along the left edge is a toolbar which gives quick access',
                    'to whiteboard features like drawing tools, uploading images,',
                    'board content management and a lot more.',
                    '',
                    'The tour auto updates. To move at your own pace,',
                    'you may pause and use the forward/back controls.'],
            anchorElId: 'menuBar',
            timeout: '18000',
            position: 'right-lower',
        },
        {
            title: 'Drawing Tools',
            helpText: ['Click on this to access various drawing tools for',
                    'drawing a line, circle, rectangle and a brush.'],
            anchorElId: 'drawToolsIcon',
            timeout: '8000',
            position: 'right-higher',
        },
        {
            title: 'Whiteboard Menu',
            helpText: ['This button is another way to access the board toolbar.', 
                    'It provides textual description of each tool in the toolbar.'],
            anchorElId: 'openDrawer',
            timeout: '10000',
            position: 'right-lower',
        },
        {
            title: 'Create a lesson plan',
            helpText: ['You can upload your lesson plan ahead of time. Create',
                    'workbooks by uploading a multi-page PDF or image files.',
                    'Each student will have their own copy of the workbook when',
                    'they join the class'],
            anchorElId: 'uploadIcon',
            timeout: '10000',
            position: 'right',
        },
        {
            title: 'Undo Redo',
            helpText: ['Standard Undo Redo Buttons'],
            anchorElId: 'undoRedoPanel',
            timeout: '6000',
            position: 'below-right'
        },
        {
            title: 'Board Name',
            helpText: ['Click here to rename the board. A subject, course or',
                    'class name is a good choice. Students get to name their',
                    'own board.'],
            anchorElId: 'bname',
            timeout: '12000',
            position: 'below'
        },
        {
            title: 'Quick tools',
            helpText: ['Pick your pen color, thickness and opacity on this panel.',
                    '',
                    'The line style icon switches between solid and dashed lines. The paint',
                    'drop button toggles between a pen and highlighter.',
                    '',
                    'Tip: Use the line drawing tool with a thick pen for a perfect highlighter'],
            anchorElId: 'sidebar',
            timeout: '20000',
            position: 'center'
        },
        {
            title: 'Color Picker',
            helpText: ['Select the color of your board and pen or just the pen by clicking here.',
                    'If you have enabled "Pen and Board Color Match" under setting,',
                    'the change will affect both, pen and board. Otherwise it will affect only the pen.',
                    '',
                    'You can select the color by moving the bar across color line,',
                    'and then clicking on the top to select contrast/hue.',
                    'You can type in specific hash values if you know them.',
                    'Or you can pick one of the 16 simple colors we have selected for you.'],
            anchorElId: 'colorPicker',
            timeout: '20000',
            position: 'center'
        },
        {
            title: 'Raise Hands',
            helpText: ['Students can click here to raise their hands. Teachers',
                    'will see a notification here when a student requests for help.',
                    'Teachers can then respond via chat or by joining the student\'s ',
                    'board by clicking their name.'],
            anchorElId: 'raiseHandButton',
            timeout: '12000',
            position: 'below-left'
        },
        {
            title: 'Timers',
            helpText: ['Set an alarm for timed assignments'],
            anchorElId: 'timerButton',
            timeout: '7000',
            position: 'below'
        },
        {
            title: 'Participants',
            helpText: ['Avatars here show who is currently on this board.',
                    'A flashing dot indicates participant activity. A greyed-out',
                    'avatar, indicates the participant is no longer on the board.',
                    '',
                    'Clicking this panel brings up several teacher tools like',
                    'creating breakout groups, locking students from updating boards',
                    'prematurely, set your name, and much more.'],
            anchorElId: 'avatarPanel',
            timeout: '20000',
            position: 'below-left'
        },
        {
            title: 'Board Actions',
            helpText: ['Click to control replication to student boards.',
                    'auto-correct, student board browser and to launch audio/video conference.'],
            anchorElId: 'boardActionButton',
            timeout: '10000',
            position: 'below-left'
        },
        {
            title: 'Login',
            helpText: [ 'Teachers and students can sign up and login from here.',
                    '',
                    'While whiteboard.chat can be used without login,',
                    'features like managing boards and keeping notes for',
                    'reuse, require login.'],
            anchorElId: 'settingsButton',
            timeout: '15000',
            position: 'below-left'
        },
        {
            title: 'Invite',
            helpText: ['Students and collaborators need to be sent a link to join',
                    'a classroom or a collaboration board.',
                    '',
                    'Copy the invite link from here and share it via any messaging',
                    'app or email. Teachers can also paste invite links into LMS like',
                    'Canvas and Google Classroom.'],
            anchorElId: 'inviteButton',
            timeout: '20000',
            position: 'below-left'
        },
        {
            title: 'Gizmos',
            helpText: [' Click to add manipulatives for math, science,',
                    'music, languages, stickers, reward symbols etc.'],
            anchorElId: 'gizmosButton',
            timeout: '8000',
            position: 'below-left'
        },
        {
            title: ' Widgets',
            helpText: ['Click to add configurable widgets like Spinner, Dice, Clock,',
                    'Links to external sites, Calculators as well as Tile, Table and Form Factories.'],
            anchorElId: 'widgetsButton',
            timeout: '8000',
            position: 'below-left'
        },
        {
            title: 'Manage your boards',
            helpText: ['Click to group your boards into folders,',
            'manage their expiry, and to access Community Boards.'],
            anchorElId: 'manageBoards',
            timeout: '8000',
            position: 'above'
        },
        {
            title: 'Monitor students',
            helpText: ['The grid view gives teachers a tiled view of boards of students.',
                    'Teachers can join each student\'s board and help them individually.',
                    'In the grid view mode, student boards are displayed in grid of 9 boards.',
                    'Grid View mode shows student boards in a configurable grid',
                    ],
            anchorElId: 'button_grid',
            timeout: '12000',
            position: 'above'
        },
        {
            title: 'Pages',
            helpText: ['A board can have several pages. Students and collaborators can',
                    'work on different pages at the same time.',
                    'Your current page number is displayed here. Use the forward and back',
                    'controls to flip pages.',
                    'These controls help view the next and previous set of boards.'],
            anchorElId: 'pageNav',
            timeout: '12000',
            position: 'above-right'
        },      
        {
            title: "And that's it!",
            helpText: [ 'Thanks for sparing a few seconds to familiarize yourself.',
                        '',
                        'Whiteboard.chat is built on feedback from teachers.', 
                        'Please write to us at feedback@whiteboard.chat.'],
            // anchorElId: 'helpIcon',
            timeout: '10000',
            position: 'center',
            actionText: 'Done',
            icon: 'fa-graduation-cap',
        }
    ]

    const wbEditorStudent = [
        {
            title: 'Hello!',
            helpText: ['Would you like a tour of whiteboard.chat?',
                    'Please click yes below to start.',
                    '',
                    'In a hurry ? No problem, this tour can be started',
                    'again from the highlighted help icon at the top right.'],
            timeout: '0',
            actionText: "Yes, tour please",
            position: 'center',
            anchorElId: 'helpButton',
            icon: "fa-map-signs",
            flipicon: false,
            navigation: false,
        },
        {
            title: 'Toolbar',
            helpText: ['Along the left edge is a toolbar which gives quick access',
                    'to whiteboard features like drawing tools, uploading images,',
                    'board content management and a lot more.',
                    '',
                    'The tour auto updates. To move at your own pace,',
                    'you may pause and use the forward/back controls.'],
            anchorElId: 'menuBar',
            timeout: '18000',
            position: 'right-lower',
        },
        {
            title: 'Drawing Tools',
            helpText: ['Click on this to access various drawing tools for',
                    'drawing a line, circle, rectangle and a brush.'],
            anchorElId: 'drawToolsIcon',
            timeout: '8000',
            position: 'right-higher',
        },
        {
            title: 'Whiteboard Menu',
            helpText: ['This button is another way to access the board toolbar.', 
                    'It provides textual description of each tool in the toolbar.'],
            anchorElId: 'openDrawer',
            timeout: '10000',
            position: 'right-lower',
        },
        {
            title: 'Undo Redo',
            helpText: ['Standard Undo Redo Buttons'],
            anchorElId: 'undoRedoPanel',
            timeout: '6000',
            position: 'below-right'
        },
        {
            title: 'Board Name',
            helpText: ['Click here to rename the board.'],
            anchorElId: 'bname',
            timeout: '12000',
            position: 'below'
        },
        {
            title: 'Quick tools',
            helpText: ['Pick your pen color, thickness and opacity on this panel.',
                    '',
                    'The line style icon switches between solid and dashed lines. The paint',
                    'drop button toggles between a pen and highlighter.',
                    '',
                    'Tip: Use the line drawing tool with a thick pen for a perfect highlighter'],
            anchorElId: 'sidebar',
            timeout: '20000',
            position: 'center'
        },
        {
            title: 'Color Picker',
            helpText: ['Select the color of your board and pen or just the pen by clicking here.',
                    'If you have enabled "Pen and Board Color Match" under setting,',
                    'the change will affect both, pen and board. Otherwise it will affect only the pen.',
                    '',
                    'You can select the color by moving the bar across color line,',
                    'and then clicking on the top to select contrast/hue.',
                    'You can type in specific hash values if you know them.',
                    'Or you can pick one of the 16 simple colors we have selected for you.'],
            anchorElId: 'colorPicker',
            timeout: '20000',
            position: 'center'
        },
        {
            title: 'Raise Hands',
            helpText: ['Students can click here to raise their hands. Teachers',
                    'will see a notification here when a student requests for help.',
                    'Teachers can then respond via chat or by joining the student\'s board.'],
            anchorElId: 'raiseHandButton',
            timeout: '12000',
            position: 'below-left'
        },
        {
            title: 'Participants',
            helpText: ['Avatars here show who is currently on this board.',
                    'A flashing dot indicates participant activity. A greyed-out',
                    'avatar, indicates the participant is no longer on the board.',
                    'set your name',
                    ],
            anchorElId: 'avatarPanel',
            timeout: '20000',
            position: 'below-left'
        },
        {
            title: 'Board Actions',
            helpText: ['Click to control replication to student boards.',
                    'auto-correct, student board browser and to launch audio/video conference.'],
            anchorElId: 'boardActionButton',
            timeout: '10000',
            position: 'below-left'
        },
        {
            title: 'Login',
            helpText: [ 'You can sign up and login from here.',
                    '',
                    'While whiteboard.chat can be used without login,',
                    'features like managing boards and keeping notes for',
                    'reuse, require login.'],
            anchorElId: 'settingsButton',
            timeout: '15000',
            position: 'below-left'
        },
        {
            title: 'Gizmos',
            helpText: [' Click to add manipulatives for math, science,',
                    'music, languages, stickers, reward symbols etc.'],
            anchorElId: 'gizmosButton',
            timeout: '8000',
            position: 'below-left'
        },
        {
            title: ' Widgets',
            helpText: ['Click to add configurable widgets like Spinner, Dice, Clock,',
                    'Links to external sites, Calculators as well as Tile, Table and Form Factories.'],
            anchorElId: 'widgetsButton',
            timeout: '8000',
            position: 'below-left'
        },
        {
            title: 'Manage your boards',
            helpText: ['Click to group your boards into folders,',
            'manage their expiry, and to access Community Boards.'],
            anchorElId: 'manageBoards',
            timeout: '8000',
            position: 'above'
        },
        {
            title: 'Pages',
            helpText: ['A board can have several pages. Collaborators can',
                    'work on different pages at the same time.',
                    'Your current page number is displayed here.'],
            anchorElId: 'pageNav',
            timeout: '12000',
            position: 'above-right'
        },
        {
            title: "And that's it!",
            helpText: [ 'Thanks for sparing a few seconds to familiarize yourself.',
                        '',
                        'Whiteboard.chat is built on feedback from users like you.',
                        'Please write to us at feedback@whiteboard.chat.'],
            // anchorElId: 'helpIcon',
            timeout: '10000',
            position: 'center',
            actionText: 'Done',
            icon: 'fa-graduation-cap',
        }
    ]

    const wbEditorCollab = [
        {
            title: 'Hello!',
            helpText: ['Would you like a tour of whiteboard.chat?',
                    'Please click yes below to start.',
                    '',
                    'In a hurry ? No problem, this tour can be started',
                    'again from the highlighted help icon at the top right.'],
            timeout: '0',
            actionText: "Yes, tour please",
            position: 'center',
            anchorElId: 'helpButton',
            icon: "fa-map-signs",
            flipicon: false,
            navigation: false,
        },
        {
            title: 'Toolbar',
            helpText: ['Along the left edge is a toolbar which gives quick access',
                    'to whiteboard features like drawing tools, uploading images,',
                    'board content management and a lot more.',
                    '',
                    'The tour auto updates. To move at your own pace,',
                    'you may pause and use the forward/back controls.'],
            anchorElId: 'menuBar',
            timeout: '18000',
            position: 'right-lower',
        },
        {
            title: 'Drawing Tools',
            helpText: ['Click on this to access various drawing tools for',
                    'drawing a line, circle, rectangle and a brush.'],
            anchorElId: 'drawToolsIcon',
            timeout: '8000',
            position: 'right-higher',
        },
        {
            title: 'Whiteboard Menu',
            helpText: ['This button is another way to access the board toolbar.', 
                    'It provides textual description of each tool in the toolbar.'],
            anchorElId: 'openDrawer',
            timeout: '10000',
            position: 'right-lower',
        },
        {
            title: 'Undo Redo',
            helpText: ['Standard Undo Redo Buttons'],
            anchorElId: 'undoRedoPanel',
            timeout: '6000',
            position: 'below-right'
        },
        {
            title: 'Board Name',
            helpText: ['Click here to rename the board.'],
            anchorElId: 'bname',
            timeout: '12000',
            position: 'below'
        },
        {
            title: 'Quick tools',
            helpText: ['Pick your pen color, thickness and opacity on this panel.',
                    '',
                    'The line style icon switches between solid and dashed lines. The paint',
                    'drop button toggles between a pen and highlighter.',
                    '',
                    'Tip: Use the line drawing tool with a thick pen for a perfect highlighter'],
            anchorElId: 'sidebar',
            timeout: '20000',
            position: 'center'
        },
        {
            title: 'Color Picker',
            helpText: ['Select the color of your board and pen or just the pen by clicking here.',
                    'If you have enabled "Pen and Board Color Match" under setting,',
                    'the change will affect both, pen and board. Otherwise it will affect only the pen.',
                    '',
                    'You can select the color by moving the bar across color line,',
                    'and then clicking on the top to select contrast/hue.',
                    'You can type in specific hash values if you know them.',
                    'Or you can pick one of the 16 simple colors we have selected for you.'],
            anchorElId: 'colorPicker',
            timeout: '20000',
            position: 'center'
        },
        {
            title: 'Participants',
            helpText: ['Avatars here show who is currently on this board.',
                    'A flashing dot indicates participant activity. A greyed-out',
                    'avatar, indicates the participant is no longer on the board.',
                    'Set your name',
                    ],
            anchorElId: 'avatarPanel',
            timeout: '20000',
            position: 'below-left'
        },
        {
            title: 'Board Actions',
            helpText: ['Click to control replication to student boards.',
                    'auto-correct, student board browser and to launch audio/video conference.'],
            anchorElId: 'boardActionButton',
            timeout: '10000',
            position: 'below-left'
        },
        {
            title: 'Login',
            helpText: [ 'You can sign up and login from here.',
                    '',
                    'While whiteboard.chat can be used without login,',
                    'features like managing boards and keeping notes for',
                    'reuse, require login.'],
            anchorElId: 'settingsButton',
            timeout: '15000',
            position: 'below-left'
        },
        {
            title: 'Invite',
            helpText: ['Collaborators need to be sent a link to join the whiteboard.',
                    '',
                    'Copy the invite link from here and share it via any messaging',
                    'app or email.'],
            anchorElId: 'inviteButton',
            timeout: '20000',
            position: 'below-left'
        },
        {
            title: 'Gizmos',
            helpText: [' Click to add manipulatives for math, science,',
                    'music, languages, stickers, reward symbols etc.'],
            anchorElId: 'gizmosButton',
            timeout: '8000',
            position: 'below-left'
        },
        {
            title: ' Widgets',
            helpText: ['Click to add configurable widgets like Spinner, Dice, Clock,',
                    'Links to external sites, Calculators as well as Tile, Table and Form Factories.'],
            anchorElId: 'widgetsButton',
            timeout: '8000',
            position: 'below-left'
        },
        {
            title: 'Manage your boards',
            helpText: ['Click to group your boards into folders,',
            'manage their expiry, and to access Community Boards.'],
            anchorElId: 'manageBoards',
            timeout: '8000',
            position: 'above'
        },
        {
            title: 'Pages',
            helpText: ['A board can have several pages. Collaborators can',
                    'work on different pages at the same time.',
                    'Your current page number is displayed here.'],
            anchorElId: 'pageNav',
            timeout: '12000',
            position: 'above-right'
        },
        {
            title: "And that's it!",
            helpText: [ 'Thanks for sparing a few seconds to familiarize yourself.',
                        '',
                        'Whiteboard.chat is built on feedback from users like you.',
                        'Please write to us at feedback@whiteboard.chat.'],
            // anchorElId: 'helpIcon',
            timeout: '10000',
            position: 'center',
            actionText: 'Done',
            icon: 'fa-graduation-cap',
        }
    ]

    const wbEditorMobile = [
        {
            title: 'Hello!',
            helpText: ['We would love to give a tour',
                    'of whiteboard.chat.',
                    '',
                    'The tour is better experienced',
                    'over larger screens.',
                    '',
                    'But no problem, you can run the',
                    'tour again from the help section',
                    'along the top of this page.'],
            timeout: '0',
            actionText: "OK",
            position: 'center',
            anchorElId: 'helpButton',
            navigation: false,
            timeout: '15000',
            skipButtons: false,
        },
    ]

    const helpCardDecks = {
      'wbEditorTeach' : wbEditorTeach,
      'wbEditorCollab' : wbEditorCollab,
      'wbEditorStudent': wbEditorStudent,
      'wbEditorMobile': wbEditorMobile,
    }

    const [helpCards, setHelpCards] = useState(wbEditorTeach);

    useEffect(() => {
      var helpCards;
      if (props.helpCardDeck && props.helpCardDeck != '') {
        helpCards = helpCardDecks[props.helpCardDeck];
      } else {
        helpCards = helpCardDecks['wbEditorTeach']
      }
      setHelpCards(helpCards);
    }, [props.helpCardDeck]);

    function helpCardDone(cardId, forward) {
        if (forward && cardIdActive >= helpCards.length - 1) {
            handleClose("done");
            try {
                ReactGA.event({
                    category: 'User',
                    action: 'WelcomeTourCompleted',
                    label: props.helpCardDeck
                });
            } catch {  }
            return;
        } else {
            if (forward) {
                let tourStarted = false;
                setCardIdActive(p => {
                    if (p === 0) {
                        tourStarted = true;
                    }
                    return (p + 1)
                });
                try {
                    if (tourStarted) {
                        ReactGA.event({
                            category: 'User',
                            action: 'WelcomeTourStarted',
                            label: props.helpCardDeck
                        });
                    }
                } catch {  }
            } else {
                setCardIdActive(p => (p > 0) ? (p - 1) : p);
            }
        }
    }

    function handleDoneClick() {
        handleClose("dontshow")
    }

    function handlePauseToggle(paused) {
        setPaused(p => paused);
    }

    return (
      <div>
        <Backdrop className={classes.backdrop} open={open} onClick={handleBackdropClick}>
         { open && cardIdActive < helpCards.length ?
          <>
          { helpCards[cardIdActive].anchorElId ?
            <HighlightElement key={helpCards[cardIdActive].anchorElId} elId={helpCards[cardIdActive].anchorElId} /> : null }
          <HelpCard key={cardIdActive} inkColor={props.inkColor} cardId={cardIdActive} title={helpCards[cardIdActive].title} 
            helpText={helpCards[cardIdActive].helpText} 
            anchorElId={helpCards[cardIdActive].anchorElId}
            position={helpCards[cardIdActive].position}
            timeout={helpCards[cardIdActive].timeout}
            actionText={helpCards[cardIdActive].actionText}
            icon={helpCards[cardIdActive].icon}
            navigation={helpCards[cardIdActive].navigation}
            skipButtons={helpCards[cardIdActive].skipButtons}
            paused={paused}
            skipForNowCB={handleClose}
            skipForGoodCB={handleDoneClick}
            pauseCB={handlePauseToggle}
            doneCb={helpCardDone}
            setShowTextTools={props.setShowTextTools}></HelpCard>
          </>
          : null }
        </Backdrop>
      </div>
    );
}