import config from './aws-exports'
import { Storage } from 'aws-amplify'
import { v4 as uuid } from "uuid";
import pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as ib from './ibdata'
import ReactGA from 'react-ga';
import * as uUtils from './UsersUtils'

const {
    aws_user_files_s3_bucket_region: region,
    aws_user_files_s3_bucket: bucket
} = config

// function readFileAsync(file) {
//     return new Promise((resolve, reject) => {
//         let reader = new FileReader();

//         reader.onload = () => {
//             resolve(reader.result);
//         };

//         reader.onerror = reject;

//         reader.readAsArrayBuffer(file);
//     })
// }

export var dataURItoBlob = function (dataURI) {
    var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        unescape(dataURI.split(',')[1]);
    var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var max = bytes.length;
    var ia = new Uint8Array(max);
    for (var i = 0; i < max; i++)
        ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], { type: mime });
};

async function resizeImage(settings) {
    var file = settings.file;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement('canvas');
    canvas.style.background = "#ffffff"
    var resize = function () {
        var width = image.width;
        var height = image.height;
        var resize = false
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
                resize = true
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
                resize = true
            }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d')
        if (settings.ext !== "png" || resize) {
            ctx.fillStyle = "#FFFFFF";
            ctx.fillRect(0, 0, width, height);
        }
        ctx.drawImage(image, 0, 0, width, height);
        var img = "jpeg"
        if (settings.ext === "png" && !resize) img = "png"
        var dataUrl = canvas.toDataURL('image/' + img);
        var imgR = resize ? "jpg" : settings.ext
        return ({ file: dataURItoBlob(dataUrl), ext: imgR })
    };

    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () {
                return ok(resize());
            };

            image.onerror = function (ev) {
                console.log('Image on error', ev)
            }
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};

function createImage(url, pgNum, randompages, numPages, ttl=0, text) {
    var id = uuid()
    const JOINURL = window.location.href.split("/board/")
    var fd = JOINURL[1].split("-pgNum-")
    var finalPage = Number(fd[1]) + (pgNum - 1)
    var finalSession = fd[0] + "-pgNum-" + finalPage
    var content = {
        type: "image", points: { x: 100, y: 100 }, url: url,
        ended: true, pdfText: text 
    }
    if (randompages !== "none") {
        var ff = {}
        for (let i = 1; i <= numPages; i++) {
            ff[i] = ""
        }
        content.type = randompages
        content.id = id
        content.pageArray = ff
        content['pages'] = numPages
    }
    const userid = null //needs to come from somehwere 
    ib.createObject(id, "name", finalSession, JSON.stringify(content), "image", userid, null, ttl).then(res => {
        const robj = res.data.createObject
        delete robj["Session"]
        ib.updateObject(robj); //used to trigger subs
    })
    ib.createSessionIfNotThere(finalSession)
    if (randompages === "amongUs") {
        // create objects on all pages
        var ct = JSON.stringify(content)
        for (let count = 2; count <10; count++) {
            id = uuid()
            finalSession = fd[0] + "-pgNum-" + count 
            ib.createObject(id, "name", finalSession, ct, "image", userid, null, ttl)
            ib.createSessionIfNotThere(finalSession)
        }
    }

}
async function loadPDF(file, randompages, ttl=0, maxSize) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

    pdfjsLib.getDocument(file).promise.then((pdf) => {
        //
        // Fetch the first page
        //
        ReactGA.event({
            category: 'File',
            action: "LoadPDF-" + pdf.numPages
        });
        if (pdf.numPages > 50) {
            alert("You can only have upto 50 pages per book. Not loading")
            return null
        }
        var kk = uuid()
        for (let pg = 1; pg <= pdf.numPages; pg++) {
            pdf.getPage(pg).then(async (page) => {
                var scale = 1.0;
                var viewport = page.getViewport({ scale: scale, });
                var h = viewport.height 
                var w = viewport.width
                var hscale = 1.0
                var wscale = 1.0 
                if (h > maxSize) hscale = maxSize /h 
                if (w > maxSize) wscale = maxSize /w
                if (hscale !== 1 || wscale !== 1) {
                    scale = hscale < wscale ? hscale : wscale 
                    viewport = page.getViewport({ scale: scale, });
                }
                //
                // Prepare canvas using PDF page dimensions
                //
                var canvas = document.createElement('canvas');
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                var allText = ""
                var textContent = await page.getTextContent();
                if (textContent) {
                    allText =  textContent.items.map(function (s) { return s.str; }).join('\n'); // value page text 
                }
                //
                // Render PDF page into canvas context
                //
                var task = page.render({ canvasContext: context, viewport: viewport })
                task.promise.then(async function () {
                    // console.log(canvas.toDataURL('image/jpeg'));
                    var dataUrl = canvas.toDataURL('image/jpeg', 1);

                    if (randompages === "amongUs") {
                        var avatar = context.getImageData(0,0, 100, 100);
                        var c2 = document.createElement('canvas');
                        c2.height = 100
                        c2.width = 100
                        var ctx2 = c2.getContext('2d');
                        ctx2.fillStyle = "#FFFFFF";
                        ctx2.fillRect(0, 0, 200, 200);
                        ctx2.putImageData(avatar, 0, 0)
                        var dataUrl2 = c2.toDataURL('image/jpeg');
                        // downloadImage(dataUrl2, 'my-canvas.jpeg');
        
                        var f1 = dataURItoBlob(dataUrl2);
                        var kb = kk + "-pg-" + pg + "-avatar"
                        await wroteFile(f1, kb, null, null) 
                        var rest = context.getImageData(viewport.width/2,0, viewport.width, viewport.height);
                        canvas.width = viewport.width/2;
                        context.putImageData(rest, 0, 0)
                        dataUrl = canvas.toDataURL('image/jpeg');
                    }
                    var file = dataURItoBlob(dataUrl);
                    var kb = kk + "-pg-" + pg
                    wroteFile(file, kb, function (url) {
                        if (randompages === "none" || pg === 1)
                            createImage(url, pg, randompages, pdf.numPages, ttl, allText)
                    }, null)
                });
            });
        }
    }).catch((error)=>{
        console.log('pdf load ',error)
    })
}
// Save | Download image
function downloadImage(data, filename = 'untitled.jpeg') {
    var a = document.createElement('a');
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
}

function processPDF(file, randompages, ttl=0, maxSize) {
    return new Promise(function (ok, no) {

        var fileReader = new FileReader();
        fileReader.onload = function (ev) {
            loadPDF(ev.target.result, randompages, ttl, maxSize)
            return ok(ev.target.result);
        };
        fileReader.readAsArrayBuffer(file);
    })
}

async function readPNG(file) {
    var reader = new FileReader();
    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.readAsDataURL(file);
        reader.onload = function (readerEvent) {
            return ok(dataURItoBlob(readerEvent.target.result));
        };
    });
}
export async function wroteFile(file, inKey, cb, xtn = null, noExpire=false) {
    var subFolderName = (noExpire || uUtils.userHasNoBoardExpiry()) ? 'paidImages' : 'images'
    const extension = xtn ? xtn : "jpg"
    const { type: mimeType } = file
    var kk = inKey ? inKey : uuid()
    const key = `${subFolderName}/${kk}.${extension}`
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`

    await Storage.put(key, file, {
        contentType: mimeType,
        // progressCallback(progress) {
        //     console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        // },
    }).then(result => {
        if (cb) cb(url)
        // deleteS3image(user.data.photoURL)
    })
        .catch(err => {
            console.error("changeAvatar error ", err);
            alert("file cannot be uploaded, please check the time on your computer:", err.message)
        });
    return url
}

async function fetchSignedUrl(fullUrl) {
    var fUrl = await GetS3ProfileImage(fullUrl);
    var readFile;
    try {
        // console.log("Fetching signed URL", fUrl);
        readFile = await fetch(fUrl);
    } catch(ee) {
        // console.log("Signed URL fetch failed", ee);
        return null;
    }
    return readFile;
}

export async function copyFile(fullUrl, inKey=null, xtn=null, noExpire=false) {
    // consoleerror("copyFile:", fullUrl, inKey, xtn);
    const tUrl = fullUrl.split("?X-Amz")[0];
    var url = (' ' + tUrl).slice(1);
    if (!inKey) {
        var ts = url.split('-pg-');
        var fileExtArr = url.split('.');
        if (!xtn) xtn = fileExtArr[fileExtArr.length - 1];
        if (ts.length > 1) {
            let ext = ts[1].split('.');
            inKey = uuid() + '-pg-' + ext[0];
        }
    }

    // console.log("copyFile:", fullUrl, url);
    var readFile;
    try {
        readFile = await fetch(url);
        if (readFile.status === 403) {
            // console.log("Fetch un-auth error", fullUrl, readFile.status, readFile);
            readFile = await fetchSignedUrl(fullUrl);
        }
    } catch(e) {
        // console.log("Fetch failed:", fullUrl, e);
        readFile = await fetchSignedUrl(fullUrl);
    }
    var readBlob = await readFile.blob();
    // console.log("readBlob:", readBlob);
    const { type: mimeType } = readBlob;
    var newUrl = await wroteFile(readBlob, inKey, null, xtn, noExpire);
    // console.log("copied url:", newUrl);
    return newUrl;
}

export async function WritePalette(img, cb) {

    const extension = "png";
    var file = dataURItoBlob(img)
    const { type: mimeType } = file
    var kk = uuid()
    const key = `palette/${kk}.${extension}`
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`

    await Storage.put(key, file, {
        contentType: mimeType,
        // progressCallback(progress) {
        //     console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        // },
    }).then(result => {
        if (cb) cb(url)
        // deleteS3image(user.data.photoURL)
    })
        .catch(err => {
            console.error("changeAvatar error ", err);
            alert("cannot save palette, check the time on your computer", err)
        });
    return url
}
export async function UploadFile(infile, randompages, ttl=0, maxSize=1150) {
    try {
        if (infile.type.indexOf("application/pdf") !== -1) {
            processPDF(infile, randompages, ttl, maxSize)

            return null
        }
        var ext = "jpg"
        if (infile.type.indexOf("image/png") !== -1) {
            ext = "png"
        }
        if (infile.type.indexOf("image/gif") !== -1) {
            ext = "gif"
        }
        ReactGA.event({
            category: 'File',
            action: "Load-Image"
        });
        var file
        if (ext === "gif") {
            file = await readPNG(infile)
        } else {
            var fileR = await resizeImage({
                file: infile,
                ext: ext,
                maxSize: maxSize
            })
            file = fileR.file
            ext = fileR.ext
        }

        var url = await wroteFile(file, null, null, ext)
        var loadImage = await GetS3ProfileImage(url)
        return { img: loadImage, url: url }
    } catch (e) {
        console.error("Something went wrong", e)
        alert("Could not upload file", e)

    }
    return null
}


export async function UploadImportedFile(infile, randompages, ttl = 0, maxSize = 1150) {
    try {
        if (infile.type.indexOf("application/pdf") !== -1) {
            processPDF(infile, randompages, ttl, maxSize)
            return null
        }
        var ext = "jpg"
        if (infile.type.indexOf("image/png") !== -1) {
            ext = "png"
        }
        if (infile.type.indexOf("image/gif") !== -1) {
            ext = "gif"
        }
        if (infile.type === 'mp4') {
            ext = 'mp4'
        }
        ReactGA.event({
            category: 'File',
            action: "Load-Image"
        });
        var file
        var url
        if (ext === "gif") {
            file = await readPNG(infile)
        } else {
            if (ext !== 'mp4') {
                var fileR = await resizeImage({
                    file: infile,
                    ext: ext,
                    maxSize: maxSize
                })
                file = fileR.file
                ext = fileR.ext
                url = await wroteFile(file, null, null, ext)
            }
        }
        if (ext === 'mp4') {
            url = await wroteFile(infile, null, null, "mp4")
        }
        var loadImage = await GetS3ProfileImage(url)
        return { img: loadImage, url: url }
    } catch (e) {
        console.error("Something went wrong", e)
        alert("Could not upload file", e)

    }
    return null
}


export async function writeFileReturn(b) {
    const file = dataURItoBlob(b)
    var url = await wroteFile(file, null, null, null)
    var loadImage = await GetS3ProfileImage(url)
    return { img: loadImage, url: url }
}

export async function GetImage(url) {
    var loadImage = await GetS3ProfileImage(url)
    return { img: loadImage, url: url }
}
export function GetS3ProfileImage(url) {
    if (url) {
        // console.log(url)
        if (url.includes('googleusercontent')) {
            return url;
        }
        if (!url.includes('/assets/images/') && !url.includes('/assets/paidImages/')) {
            var file = url.split('public/').pop();
            return Storage.get(file, { expires: 604800 })
                .then(result => {
                    return result
                })
                .catch(err => console.log(err));

        } else {
            return url
        }
    }
}

export function GetS3File(url) {
    if (url) {
        if (url.includes('googleusercontent')) {
            return url;
        }
        if (!url.includes('/assets/images/') && !url.includes('/assets/paidImages/')) {
            var file = url.split('public/').pop();
            return Storage.get(file, { expires: 604800, download: true })
                .then(result => {
                    return { img: result, url: url }
                })
                .catch(err => console.log(err));

        } else {
            return url
        }
    }
}