import React, { useEffect, Suspense, useState } from 'react';
import clsx from 'clsx';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    Tooltip,
    Typography,
    Snackbar,
    IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import BrushIcon from '@material-ui/icons/Brush';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import YesNoDialog from "./YesNoDialog"
import * as ib from './ibdata'
import ReactGA from 'react-ga';
import SaveIcon from '@material-ui/icons/Save';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Divider from '@material-ui/core/Divider';
import * as iu from "./ImageUtils"
import { makeStyles } from '@material-ui/core/styles';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PhotoSizeSelectLargeIcon from '@material-ui/icons/PhotoSizeSelectLarge';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import PostAddIcon from '@material-ui/icons/PostAdd';
import GridOnIcon from '@material-ui/icons/GridOn';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked';
import RectangleIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import NoteIcon from '@material-ui/icons/Note';
import NotesIcon from '@material-ui/icons/Notes';
import CasinoIcon from '@material-ui/icons/Casino';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import PublishIcon from '@material-ui/icons/Publish';
import { myName } from './WhiteBoard';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import WebcamDialog from './WebcamDialog';
import YouTubeIcon from '@material-ui/icons/YouTube';
import WebIcon from '@material-ui/icons/Web';
import AdjustIcon from '@material-ui/icons/Adjust';
import FunctionsIcon from '@material-ui/icons/Functions';
import * as en from "./encDec";
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import * as bUtils from './BoardUtility'
import SchoolIcon from '@material-ui/icons/School';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import PickFile from "./PickFile"
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import HistoryIcon from '@material-ui/icons/History';
import TimeMachine from "./TimeMachine"
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import SubjectIcon from '@material-ui/icons/Subject';
import PanToolIcon from '@material-ui/icons/PanTool';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CropFreeIcon from '@material-ui/icons/CropFree';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import TableChartIcon from '@material-ui/icons/TableChart';
import CoordinateDialog from "./CoordinateDialog.js"
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StraightenIcon from '@material-ui/icons/Straighten';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import ComputerIcon from '@material-ui/icons/Computer';
import BackupIcon from '@material-ui/icons/Backup';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MoleculeIcon from './molecule/MoleculeIcon';
import * as mylocalStorage from "./mylocalStorage"
import GavelIcon from '@material-ui/icons/Gavel';
import GestureIcon from '@material-ui/icons/Gesture';
import WbIridescentIcon from '@material-ui/icons/WbIridescent';

const MediaDialog = React.lazy(() => import('./MediaDialog'));

var gFav = {}
const useStyles = makeStyles((theme) => ({
    icon: {
        // color: theme.palette.primary.main,
        color: '#ffffff',
        "&:hover": {
            color: 'white',
            fill: 'white',
        },
    },
    divider: {
        // backgroundColor: '#72869F',
        backgroundColor: '#FAFAFA',
    },
    listText: {
        color: '#FFFFFF',
        margin: '0 10px'
    },
    tooltip: {
        margin: "4px",
    },
    favIcon: {
        margin: "0",
        padding: "0",
        color: "#ffffff",
        marginLeft: 'auto',
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
    customArrow: {
        color: "#1E2639",
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        "&:hover": {
            background: "#ffffffaa"
        }
    },
    itemTop: {
        paddingLeft: '0px'
    },
    itemIconTop: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: '40px'
    }
}));
const FAVICON = "favIcons"
const ICONHT = '1rem'

// var newOptions = [
//     //pencil
//     { name: 'Pencil', cb:drawBrush, icon: <BrushIcon style={{ height: ICONHT }} /> },
//     //text
//     { name: 'Text', cb: clickText, icon: <TextFieldsIcon style={{ height: ICONHT }} /> },
//     //line
//     { name: 'Line', cb: drawLine, icon: <ShowChartIcon /> },
//     //sqaure
//     { name: 'Rectangle', cb: drawRect, icon: <RectangleIcon /> },
//     //circle
//     { name: 'Circle', cb: drawCircle, icon: <CircleIcon /> },
//     //eraser
//     { name: 'Erase', id: "button_erase", iconType: 'svg', icon:'/tools/Eraser.svg', dnext: true,},
//     //hand/select
//     { name: 'Select', id: "button_animate", iconType: 'svg', icon: '/tools/Cursor.svg'},
//     //zoom
//     //color
// ];
 
var options = []
var randompages = "none";
var aniMessage = false
let newOps=[]
let newUploadOptions=[]
let gSelect = 'Pencil'
function WbcJrLongMenu(props) {
    const [yn, setyn] = React.useState({ open: false, message: "", cb: null })
    const inputFile = React.useRef(null)
    const importFile = React.useRef(null)
    const [favOptions, setFavOptions] = React.useState([])

    const classes = useStyles();
    const teacherR = useSelector((state) => state.teacher);
    const boardTools = useSelector((state) => state.boardTools);

    const timeMachine = useSelector((state) => state.timeMachine);

    const diceShow = useSelector((state) => state.diceShow);
    const boardLocked = useSelector((state) => state.boardLocked);
    const pageLocked = useSelector((state) => state.pageLocked);

    const boardConfig = useSelector((state) => state.BoardConfig);
    const authUser = useSelector((state) => state.user);

    const [anchorEl, setAnchorEl] = React.useState({ el: null, });
    const [firstList, SetFirstList] =React.useState({ list: [] });
    const [message, setMessage] = React.useState("")
    const [anchorElB, setAnchorElB] = React.useState({ el: null, });
    const [secondList, SetSecondList] =React.useState({ list: [] });
    const [statusMsg, setStatusMsg] = React.useState("")
    const [userRole, setUserRole] = React.useState(null)
    const dispatch = useDispatch();
    var newOptions = [
        //pencil
        { name: 'Pencil', label:'Pencil', cb:drawBrush, iconType: 'svg', icon:'/tools/pencilIcon.svg', number:1},
        //text
        { name: 'Text',label:'Text', cb: clickText, iconType: 'svg', icon:'/tools/textIcon.svg', number:2},
        //line
        { name: 'Line1',label:'Line', cb: drawLine,iconType: 'svg', icon:'/tools/lineIcon2.svg', number:3},
        //sqaure
        { name: 'Rectangle',label:'Rectangle', cb: drawRect, iconType: 'svg', icon:'/tools/rectIcon.svg', number:4},
        //circle
        { name: 'Ellipse', label:'Ellipse',cb: drawCircle, iconType: 'svg', icon:'/tools/icon-ellipse.svg', number:5},
        //eraser
        { name: 'Erase',label:'Eraser', id: "button_erase", cb: eraseCb, iconType: 'svg', icon:'/tools/eraseIcon.svg', number:6},
        //hand/select
        { name: 'Pan', label: 'Hand', cb: selectCb, iconType: 'svg', icon: '/tools/PanHand.svg', number: 7},
        //zoom
        { name: 'Zoom', label: 'Zoom', cb: zoomInCb, iconType: 'svg', icon: '/tools/zoomInIcon.svg', number: 8 },
        //color 
        { name: 'Color', label: 'Color', cb: colorCb, number: 9 },
        { name: 'Table', label: 'Table', cb: TableFactory, iconType: 'svg', icon: '/tools/cells.svg', number: 10 },

    ];

    const [currentOptions, setcurrentOption] = React.useState(newOptions)
    const [activatedOption, setActivatedOption] = React.useState(1)
    const newTools = [
        { name: 'Add Media', cb: AddMedia, iconType: 'svg', icon: '/tools/CamAdd.svg' },
        { name: 'Break Down', cb: BreakApart, icon: <GavelIcon /> },
        { name: 'Drop Zone', cb: DropZone, icon: <ArrowDropDownCircleIcon /> },
        { name: 'Grids', cb: AddGrids, iconType: 'svg', icon: '/tools/Grid.svg' },
        { name: 'Infinite Cloner', cb: drawCloner, icon: <FileCopyIcon /> },
        { name: 'Pause Animations', id: "button_pause", iconType: 'svg', icon: '/tools/pauseanime.svg', isAnimate: true },
        { name: 'Select/Crop', cb: drawSelect, icon: <CropFreeIcon /> },
        { name: 'Time Machine', cb: timeMachineToggle, icon: <HistoryIcon /> },
    ]
    useEffect(() => {
        if (props.userRole) {
            setUserRole(props.userRole)
        } else {
            setUserRole('student')
        }
    }, [props.userRole])

    useEffect(()=>{
        if (props.statusMsg && props.statusMsg !== '') {
            setStatusMsg(props.statusMsg)
            props.setStatusMsg && props.setStatusMsg('')
        }
    },[props.statusMsg])

    function colorCb(){
        props.setShowTextTools(false);
        props.cb['ColorPicker']()
    }
    function selectCb(e) {
        props.setShowTextTools(false);
        let mr = { name: 'Move & Resize', label: 'Mover', cb: selectCb, iconType: 'svg', icon: '/tools/moveresizeIcon.svg', number: 7 }
        let selectOp = { name: 'Pan', label: 'Hand', cb: selectCb, iconType: 'svg', icon: '/tools/PanHand.svg', number: 7 };
        let tt= currentOptions
        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Pan') {
                props.cb[selectOp.name](e)
            } else {
                props.cb[mr.name]()
            }
            gSelect = e
            return 
        }

        if (e === 'Pan') {
                tt.splice(6, 1, mr)
                props.cb[mr.name]()
                e = 'Move & Resize'
        } else {
            tt.splice(6, 1, selectOp)
            props.cb[selectOp.name](e)
            e = 'Pan'
        }
        gSelect = e 
        setcurrentOption([...tt])
    }
    function eraseCb() {
        props.setShowTextTools(false);
        props.cb['Erase']('Erase')
        gSelect = "Eraser"
    }

    function zoomInCb() {
        props.setShowTextTools(false);
        props.cb['ZoomIn']()
        gSelect = "ZoomIn"
    }

    const handleClickA = (event, list) => {
        setAnchorEl({ el: event.currentTarget});
        SetFirstList({list: list})
    };

    const handleClickB = (event, list) => {
        setAnchorElB({ el: event.currentTarget });
        SetSecondList({ list: list })
    };
    const handleClose = () => {
        setAnchorEl({ el: null });
        setAnchorElB({ el: null });
    };

    function statusSnackbar(msg) {
        setStatusMsg(msg)
    }

    function aniMess(mess) {
        if (aniMessage) return
        aniMessage = true
        setMessage(mess)
    }

    function GridBox(e) {
        props.setShowTextTools(false);
        props.cb["GridBox"](true)
        handleClose()
        ReactGA.event({
            category: 'User',
            action: "drawGridBox"
        });
    }

    function DotPainter(e) {
        props.setShowTextTools(false);
        props.cb["DotPainter"](true)
        handleClose()
        ReactGA.event({
            category: 'User',
            action: "drawDot"
        });
    }
    function GridPainter(e) {
        props.setShowTextTools(false);
        props.cb["GridPainter"](true)
        handleClose()
        ReactGA.event({
            category: 'User',
            action: "drawGrid"
        });
    }
    function drawGridBig() {
        props.setShowTextTools(false);
        props.cb["drawGrid"](true)
        handleClose()
        ReactGA.event({
            category: 'User',
            action: "drawGrid"
        });
    }
    function drawElkonin() {
        props.setShowTextTools(false);
        props.cb["drawElkonin"](true)
        handleClose()
        ReactGA.event({
            category: 'User',
            action: "drawElkonin"
        });
    }
    function clearGrid() {
        props.setShowTextTools(false);
        props.cb["clearGrid"](true)
        handleClose()
        ReactGA.event({
            category: 'User',
            action: "clearGrid"
        });
    }
    function drawGrid() {
        props.setShowTextTools(false);
        props.cb["drawGrid"](false)
        handleClose()
        ReactGA.event({
            category: 'User',
            action: "drawGrid"
        });
    }
    function drawRedWhite() {
        props.cb["drawRedWhite"]()
        ReactGA.event({
            category: 'User',
            action: "drawRedWhite"
        });
        handleClose()
    }
    function drawCollegeLine() {
        props.setShowTextTools(false);
        props.cb["drawCollegeLine"]()
        ReactGA.event({
            category: 'User',
            action: "drawCollegeLine"
        });
        handleClose()
    }
    const [coordialog, setCoorDialog] = React.useState({ open: false, cb: null })
    function drawCoordinatePlane() {
        props.setShowTextTools(false);
        handleClose()
        setCoorDialog({ open: true, cb: done })
        function done(e) {
            if (!e) {
                setCoorDialog({ open: false, cb: null })
                return
            }
            props.cb["drawCoordinatePlane"](e)
        }
    }

    function drawHandwrite() {
        props.setShowTextTools(false);
        props.cb["drawHandwrite"]()
        ReactGA.event({
            category: 'User',
            action: "drawHandwrite"
        });
        handleClose()
    }
    function drawHandwrite2() {
        props.setShowTextTools(false);
        props.cb["drawHandwrite2"]()
        ReactGA.event({
            category: 'User',
            action: "drawHandwrite2"
        });
        handleClose()
    }
    function drawProgramGrid() {
        props.cb["drawProgramGrid"]()
        props.setShowTextTools(false);
        ReactGA.event({
            category: 'User',
            action: "drawHandwrite2"
        });
        handleClose()
    }
    function drawMusic() {
        props.setShowTextTools(false);
        props.cb["drawMusic"]()
        ReactGA.event({
            category: 'User',
            action: "drawMusic"
        });
        handleClose()
    }
    const skipStudent = { "button_delete": true, "button_mgt": true }
    const skipNewDesign = { "button_menu": true }
    const skipSimple = {
        "button_save": true,
        "button_boards": true,
        "button_grids": true,
        "button_home": true,
        "button_mgt": true,
        "button_pause": true,
    }
    const keepfour = {
        "drawToolsIcon": true,
        "button_refresh": true,
        "button_move": true,
        "button_brush": true,
        "button_erase": true,
        "button_Text": true,
        "button_tools": true,
    }
    function hideIfInTeams(id) {
        if (window.insideMSTeams === true) {
            if (id === 'button_boards' ||
                id === 'button_home') {
                return true
            }
        }
        return false;
    }
    React.useEffect(() => {
        // setFavList()
    }, [])
    React.useEffect(() => {
            options = newOptions

        var currentOptions1 = [...options]
        var ff = []
        for (let i = 0; i < currentOptions1.length; i++) {
            if (!teacherR && currentOptions1[i].id in skipStudent) {
                continue
            }
            if (gFav[currentOptions1[i].name]) {
                continue
            }

            var ss = currentOptions1[i].id in skipSimple
            if (!Boolean(teacherR) && boardConfig.simpleDrawingTools && currentOptions1[i].id in skipSimple) {
                continue
            }
            if (!Boolean(teacherR) && boardConfig.fourToolsStudent && (!(currentOptions1[i].id in keepfour))) {
                continue
            }
            if (!Boolean(teacherR) && boardTools && boardTools[currentOptions1[i].name] && boardTools[currentOptions1[i].name].checked) {
                continue
            }
            let op = newTools.find(n=>n.isAnimate ===true)
            if(op){
                if (props.pauseMode) {
                    op.name = "Play Animations"
                    op.icon = '/tools/playanime.svg'
                } else {
                    op.name = "Pause Animations"
                    op.icon = '/tools/pauseanime.svg'
                }
            }
            newOps=[...newTools]
            if (currentOptions1[i].id === "button_pause") {
                currentOptions1[i].isAnimate= true 
                if (props.pauseMode) {
                    currentOptions1[i].name = "Play Animations"
                    currentOptions1[i].icon = '/tools/playanime.svg'
                } else {
                    currentOptions1[i].name = "Pause Animations"
                    currentOptions1[i].icon = '/tools/pauseanime.svg'
                }
            }
            if (favOptions.length > 0) {
                let fop = favOptions.find(n => n.isAnimate === true)
                if (fop) {

                    if (props.pauseMode) {
                        fop.name = "Play Animations"
                        fop.icon = '/tools/playanime.svg'
                    } else {
                        fop.name = "Pause Animations"
                        fop.icon = '/tools/pauseanime.svg'
                    }
                }
            }
            if (hideIfInTeams(currentOptions1[i].id)) {
                continue
            }
            ff.push(currentOptions1[i])

        }
        // setcurrentOption([...favOptions, ...ff])
    }, [props.eraseMode, props.pauseMode, props.drawMode, teacherR, boardConfig, boardTools, favOptions])
    React.useEffect(() => {
        var uploadOptions = [...UploadTools]
        var ff = []
        for (let i = 0; i < uploadOptions.length; i++) {
            if (!Boolean(teacherR) && boardConfig.simpleDrawingTools || !Boolean(teacherR)) {
                if (uploadOptions[i].name === "Upload Bingo" || uploadOptions[i].name === "Upload GamePlay" || uploadOptions[i].name === "Upload linked PDF") {
                    continue
                }
            }
            ff.push(uploadOptions[i])
        }
        newUploadOptions = [...ff]
    }, [teacherR, boardConfig])
    const grids = [
        { name: 'Grid', cb: drawGrid, icon: <GridOnIcon /> },
        { name: 'Big Grid', cb: drawGridBig, icon: <BorderClearIcon /> },
        { name: 'Hand writing', cb: drawHandwrite, icon: <BorderColorIcon /> },
        { name: 'College Lined', cb: drawCollegeLine, icon: <SchoolIcon /> },
        { name: 'Red & White', cb: drawRedWhite, icon: <SchoolIcon /> },

        { name: 'Coordinate plane', cb: drawCoordinatePlane, icon: <ScatterPlotIcon /> },
        { name: 'Primary lined', cb: drawHandwrite2, icon: <BorderColorIcon /> },
        { name: 'Programming', cb: drawProgramGrid, icon: <GridOnIcon /> },

        { name: 'Music Sheet', cb: drawMusic, icon: <MusicNoteIcon /> },
        { name: 'Syllaboard', cb: drawSyllable, icon: <ImportContactsIcon /> },
        { name: 'Elkonin', cb: drawElkonin, icon: <BorderClearIcon /> },
        { name: 'Grid Painter', cb: GridPainter, icon: <FormatPaintIcon /> },
        { name: 'Grid Box', cb: GridBox, icon: <CheckBoxOutlineBlankIcon /> },
        { name: 'Dot Painter', cb: DotPainter, icon: <FiberManualRecordIcon /> },

        { name: 'Clear Grid', cb: clearGrid, icon: <ClearIcon /> },

    ]
    const InkingTool = [
        { name: 'Click & Draw', cb:drawBrush, icon: <BrushIcon style={{ height: ICONHT }} /> },
        { name: 'Line', cb: drawLine, icon: <ShowChartIcon /> },
        { name: 'Arrow', cb: drawArrow, icon: <TrendingUpIcon /> },
        { name: 'Shapes', cb: drawShape, icon: <DashboardIcon />, sub: true },
        { name: 'Magic Draw', cb: magicDraw, icon: <WbIridescentIcon /> },
    ]
    const UploadTools = [
        { name: 'Upload Files From Computer', cb: handleUploadFile, icon: <ImportantDevicesIcon /> },
        { name: 'Upload Files From Google Drive', cb: handleGoogleUpload, icon: <FilterDramaIcon /> },
        { name: 'Upload Bingo', cb: uploadBingo, iconType: 'svg', icon:'/tools/bingoIcon.svg' },
        { name: 'Upload GamePlay', cb: uploadAmongUs, iconType: 'svg', icon:'/tools/gamePlayIcon.svg' },
        { name: 'Upload linked PDF', cb: uploadPDF, iconType: 'svg', icon:'/tools/pdfIcon.svg' },
    ]
    const WriteTools = [
        { name: 'Click & Type', cb: clickText, icon: <TextFieldsIcon style={{ height: ICONHT }} /> },
        { name: 'Text Box', cb: textBox, icon: <SubjectIcon /> },
        { name: 'Rich Text', cb: drawRichText, icon: <NotesIcon /> },
        { name: 'Hand Write', cb: HandWrite, icon: <GestureIcon /> },
        { name: 'Math Editor', cb: AddMathEdit, iconType: 'svg', icon: '/tools/MathFormat.svg' },
        { name: 'Note', cb: drawNote, icon: <NoteIcon />, sub: true},
    ]
    

    const Tools = [
        { name: 'Line', cb: drawLine, icon: <ShowChartIcon /> },
        { name: 'Arrow', cb: drawArrow, icon: <TrendingUpIcon /> },
        { name: 'Select/Crop', cb: drawSelect, icon: <CropFreeIcon /> },

        { name: 'Rich Text', cb: drawRichText, icon: <NotesIcon /> },
        { name: 'Infinite Cloner', cb: drawCloner, icon: <FileCopyIcon /> },
        { name: 'Add link', cb: addLink, icon: <InsertLinkIcon /> },
        { name: 'YouTube', cb: addYouTubeVideo, icon: <YouTubeIcon /> },
        { name: 'Website', cb: addWebsite, icon: <WebIcon /> },
        { name: 'Math', cb: drawMath, icon: <FunctionsIcon /> },
        { name: 'Pointer', cb: drawPointer, icon: <SwapCallsIcon /> },
        { name: 'Magic Draw', cb: magicDraw, icon: <WbIridescentIcon /> },

        { name: 'Text Box', cb: textBox, icon: <SubjectIcon /> },
        { name: 'Write Software', cb: programmingBox, icon: <ComputerIcon /> },
        { name: 'Note', cb: drawNote, icon: <NoteIcon />, sub: true},
        { name: 'Student signal', cb: studentSignal, icon: <PanToolIcon />, sub: true},
        { name: 'Calculators', cb: Calcs, icon: <KeyboardIcon />, sub: true },
        { name: 'Factories', cb: Factories, icon: <LocationCityIcon />, sub: true },
        { name: 'Music Instruments', cb: MusicInstr, icon: <LibraryMusicIcon />, sub: true},
        { name: 'Molecule Editor', cb: MoleculeEditor, icon: <MoleculeIcon /> },
        { name: 'Drop Zone', cb: DropZone, icon: <ArrowDropDownCircleIcon /> },
        { name: 'Break Down', cb: BreakApart, icon: <GavelIcon /> },
        { name: 'Hand Write', cb: HandWrite, icon: <GestureIcon /> },

    ]
    //Level 2 stuff 
    const Factory = [
        { name: 'Tile Factory', cb: TileFactory, icon: <LocationCityIcon /> },
        { name: 'Form Factory', cb: FormFactory, icon: <FormatListBulletedIcon /> },
        { name: 'Table Factory', cb: TableFactory, icon: <TableChartIcon /> },
    ]
    const Music = [
        { name: 'Piano', cb: Piano, icon: <StraightenIcon /> },
        { name: 'XyloPhone', cb: Xylophone, icon: <SignalCellularAltIcon /> },

    ]
    const Shapes = [
        { name: 'Circle', cb: drawCircle, icon: <CircleIcon /> },
        { name: 'Rectangle', cb: drawRect, icon: <RectangleIcon /> },
        { name: 'Compass', cb: addCompass, icon: <AdjustIcon /> },
        { name: 'Polygon', cb: drawPolygon, icon: <ChangeHistoryIcon /> },
    ]
    const Calculators = [
        { name: 'Basic Calculator', cb: basicCalc, icon: <KeyboardIcon /> },
        { name: 'Graphing Calculator', cb: graphCalc, icon: <KeyboardIcon /> },
        { name: 'Rekenrek', cb: DrawRekenrek, icon: <ListAltIcon /> },
    ]
    const StudentTools = [
        { name: 'Stop Light', cb: drawStopLight, icon: <Brightness7Icon /> },
        { name: 'Thumb signals', cb: drawThumbs, icon: <ThumbUpIcon /> },
        { name: 'Screen Shade', cb: drawScreenShade, icon: <BrokenImageIcon /> },
    ]
    const newStudentTools = [
        { name: 'Stop Light', cb: drawStopLight, icon: <Brightness7Icon /> },
        { name: 'Thumb signals', cb: drawThumbs, icon: <ThumbUpIcon /> },
        { name: 'Screen Shade', cb: drawScreenShade, icon: <BrokenImageIcon /> },
        { name: 'Snake Pointer', cb: drawSnake, icon: <SwapCallsIcon /> },
        { name: 'Spotlight', cb: drawSpotlight, icon: <Brightness7Icon /> },
    ]
    const PointerTools = [
        { name: 'Snake', cb: drawSnake, icon: <SwapCallsIcon /> },
        { name: 'Spotlight', cb: drawSpotlight, icon: <Brightness7Icon /> },
    ]
    const ExportTools = [
        { name: 'Save local copy', cb: () => { exportBoard(true, '/') }, icon: <SystemUpdateAltIcon /> },
        { name: 'Export to whiteboard.chat', url: 'https://www.whiteboard.chat/', cb: () => { exportBoard(false, 'https://www.whiteboard.chat', 'whiteboard') }, icon: <BackupIcon /> },
        { name: 'Export to uswest.whiteboard', url: 'https://uswest.whiteboard.chat/', cb: () => { exportBoard(false, 'https://uswest.whiteboard.chat', 'uswest') }, icon: <BackupIcon /> },
        { name: 'Export to asia.whiteboard', url: 'https://www.asia.whiteboard.chat/', cb: () => { exportBoard(false, 'https://www.asia.whiteboard.chat', 'asia') }, icon: <BackupIcon /> },
    ]
    const PostItNotes = [
        { name: 'Yellow', cb: () => drawNoteIn("Yellow"), icon: <NoteIcon style={{ color: "#fff740" }} /> },
        { name: 'Blue', cb: () => drawNoteIn("Blue"), icon: <NoteIcon style={{ color: "#7afcff" }} /> },
        { name: 'Pink', cb: () => drawNoteIn("Pink"), icon: <NoteIcon style={{ color: "#ff7eb9" }} /> },
        { name: 'Green', cb: () => drawNoteIn("Green"), icon: <NoteIcon style={{ color: "#52D017" }} /> },

    ]
    const ManageBoards = [
        { name: 'Send a Copy', cb: copyBoard, icon: <FileCopyIcon /> },
        { name: 'Send Unencrypted Copy', cb: copyBoardunc, icon: <FileCopyIcon /> },

        { name: 'Publish to community', cb: publishToCommunity, icon: <PublishIcon /> },
        { name: 'Delete Forever', cb: delForever, icon: <DeleteForeverIcon /> },
        { name: 'Delete Page', cb: delPage, icon: <DeleteForeverIcon /> },
        { name: 'Clear All Pages', cb: clearAllPages, icon: <ClearIcon /> },
        { name: 'Clear Student Pages', cb: clearStudentPages, icon: <ClearIcon /> },
        { name: 'Clear Students & pages', cb: clearStudents, icon: <ClearIcon /> },

        { name: 'Copy Page', cb: copyPage, icon: <PostAddIcon /> },
        { name: 'Upload Bingo', cb: uploadBingo, icon: <ArtTrackIcon /> },
        { name: 'Upload GamePlay', cb: uploadAmongUs, icon: <ArtTrackIcon /> },

        { name: 'Upload linked PDF', cb: uploadPDF, icon: <FileCopyIcon /> },
        { name: 'Save Copy', cb: saveCopy, icon: <SaveIcon /> },
        { name: 'Time Machine', cb: timeMachineToggle, icon: <HistoryIcon /> },

        { name: 'Export Board', cb: exportBoardMenu, icon: <FileCopyIcon />, userroles: ['teacher'] },
        { name: 'Import Board', cb: handleImportBoard, icon: <AddToQueueIcon />, userroles: ['teacher'] },

    ]
    const media = [
        { name: 'Add Webcam Photo', cb: WCClick, icon: <AddAPhotoIcon /> },
        { name: 'Add Webcam Video/Audio', cb: MediaClick, icon: <SettingsVoiceIcon /> },
    ]
    var download = [
        { name: 'My Board', cb: downloadSingle, icon: <InsertPhotoIcon /> },
        { name: 'This Page', cb: downloadThisPage, icon: <InsertPhotoIcon /> },
        { name: 'Whole Class - Single Page', cb: downloadPage, icon: <InsertPhotoIcon />, userroles: ['teacher'] },
        { name: 'Whole Class - Single PDF', cb: downloadClass, icon: <PermMediaIcon />, userroles: ['teacher'] },
        { name: 'Whole Class - One PDF per scholar', cb: downloadClass2, icon: <PermMediaIcon />, userroles: ['teacher'] },
        { name: 'Download Report', cb: DownloadReport, icon: <AddToQueueIcon />, userroles: ['teacher'] },
    ]

    function Factories(e) {
        handleClickB(e, Factory)
    }
    function drawShape(e) {
        props.setShowTextTools(true)
        handleClickB(e, Shapes)
    }
    function MusicInstr(e) {
        handleClickB(e, Music)
    }

    function Calcs(e) {
        handleClickB(e, Calculators)
    }
    function drawPointer(e) {
        handleClickB(e, PointerTools)
    }
    function studentSignal(e) {
        handleClickB(e, StudentTools)
    }
    function exportBoardMenu(e) {
        if (e) {
            handleClickB(e, ExportTools.filter(e => {
                if (e.url) {
                    var origin = window.location.origin
                    if (window.location.origin === "https://wb.whiteboard.chat") {
                        origin = "https://www.whiteboard.chat"
                    }
                    return !e.url.includes(origin)
                } else {
                    return true
                }
            }))
        }
    }
    function clearStudentPages() {
        ReactGA.event({
            category: 'Tools',
            action: "Clear Student Pages"
        });
        handleClose()
        if (!teacherR) return
        setyn({ open: true, message: "Clear all students boards, are you sure?", cb: doneClick })
        function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (val === true) {
                ib.clearAllBoards(props.sess, "student")
            }
        }
    }
    function clearStudents() {
        ReactGA.event({
            category: 'Tools',
            action: "Clear Students"
        });
        handleClose()
        if (!teacherR) return
        setyn({ open: true, message: "Clear all students (including boards), are you sure?", cb: doneClick })
        function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (val === true) {
                ib.clearAllBoards(props.sess, "studentAndPages")
            }
        }
    }

    function timeMachineToggle() {
        props.setShowTextTools(false);
        ReactGA.event({
            category: 'Tools',
            action: "Time Machine"
        });
        handleClose()
        dispatch(Actions.setTimeMachine({
            ...timeMachine,
            open: !timeMachine.open
        }))
    }
    function clearAllPages() {
        ReactGA.event({
            category: 'Tools',
            action: "Clear All Pages"
        });
        handleClose()
        if (!teacherR) return
        setyn({ open: true, message: "Clear all boards including students, are you sure?", cb: doneClick })
        function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (val === true) {
                ib.clearAllBoards(props.sess, "all")
            }
        }
    }
    const upload = [
        { name: 'From Computer', cb: handleUploadFile, icon: <ImportantDevicesIcon /> },
        { name: 'From Google Drive', cb: handleGoogleUpload, icon: <FilterDramaIcon /> },
    ]
    function handleUploadFile() {
        handleClose()
        props.setShowTextTools(false);
        randompages = "none"
        inputFile.current.click();
    }
    const [pickFile, setPickFile] = React.useState({ open: false, message: "", cb: null })

    function handleGoogleUpload() {
        handleClose()
        props.setShowTextTools(false);
        randompages = "none"
        setPickFile({ open: true, cb: doneload })
        async function doneload(f) {
            setPickFile({ open: false, cb: null })
            if (!f) return
            props.cb["Message"]("Loading Google file, please wait")
            var res = 1150
            if (boardConfig.boardResolution) {
                var r = ib.boardResoltionType[boardConfig.boardResolution]
                if (r) {
                    var touse = r.h > r.w ? r.w : r.h
                    if (touse < res) res = touse - 100
                }
                if (boardConfig.boardResolution === "a4") res = 792

            }
            var ff = await iu.UploadFile(f, randompages,
                props.sess ? props.sess.ttl : 0)
            if (ff) props.cb.loadImage(ff)
        }
    }

    function handleImportBoard() {
        props.setShowTextTools(false);
        if (authUser) {
            importFile.current.click();
        } else {
            aniMess("Please SignIn to import file")
        }
    }

    function DownloadReport() {
        handleClose()
        ib.doReport(props.sess.Classroom)
    }

    function BreakApart() {
        props.cb['BreakApart']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'BreakApart'
        });
    }
    function TableFactory() {
        gSelect = "Table"
        props.cb['TableFactory']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'TableFactory'
        });
    }
    function TileFactory() {
        props.cb['TileFactory']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'TileFactory'
        });
    }
    function HandWrite() {
        props.setShowTextTools(true);
        props.cb['HandWrite']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'HandWrite'
        });
    }
    function FormFactory() {
        props.cb['FormFactory']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'FormFactory'
        });
    }
    function drawSnake() {
        props.setShowTextTools(false);
        props.cb['drawSnake']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'drawSnake'
        });
    }

    function drawSpotlight() {
        props.setShowTextTools(false);
        props.cb['drawSpotlight']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'drawSpotlight'
        });
    }
    function DrawRekenrek() {
        setyn({
            open: true, link:
                "", message: "Number of lines",
            cb: done, text: true
        })
        async function done(e) {
            setyn({ open: false, message: "", cb: null })
            props.cb['DrawRekenrek'](parseInt(e))
        }

        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'DrawRekenrek'
        });
    }
    function DropZone() {
        props.setShowTextTools(false);
        props.cb['DropZone']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'DropZone'
        });
    }
    function textBox() {
        props.setShowTextTools(true)
        props.cb['textBox']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'textBox'
        });
    }
    function magicDraw() {
        props.setShowTextTools(true)
        props.cb['magicDraw']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'magicDraw'
        });
    }
    function programmingBox() {
        props.cb['programmingBox']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'programmingBox'
        });
    }
    function Piano() {
        props.cb['Piano']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'Piano'
        });
    }
    function Xylophone() {
        props.cb['Xylophone']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'Xylophone'
        });
    }
    function MoleculeEditor() {
        props.cb['MoleculeEditor']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'MoleculeEditor'
        });
    }
    function basicCalc() {
        props.cb['basicCalc']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'basicCalc'
        });
    }
    function graphCalc() {
        props.cb['graphCalc']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'graphCalc'
        });
    }
    function drawStopLight() {
        props.setShowTextTools(false);
        props.cb['drawStopLight']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'drawStopLight'
        });
    }
    function drawThumbs() {
        props.setShowTextTools(false);
        props.cb['drawThumbs']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'drawThumbs'
        });
    }
    function drawScreenShade() {
        props.setShowTextTools(false);
        props.cb['drawScreenShade']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'drawScreenShade'
        });
    }
    function addYouTubeVideo() {
        props.cb['YouTube']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'YouTube'
        });
    }

    function addWebsite() {
        props.cb['Website']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'Website'
        })
    }

    function addLink() {
        props.cb['handleLink']('')
        ReactGA.event({
            category: 'Tools',
            action: "handleLink"
        });
        handleClose()
    }

    function drawCloner() {
        props.setShowTextTools(false);
        props.cb['handleCloner']('')
        ReactGA.event({
            category: 'Tools',
            action: "handleCloner"
        });
        handleClose()
    }
    function addCompass() {
        props.setShowTextTools(true)
        props.cb['addCompass']('')
        ReactGA.event({
            category: 'Tools',
            action: "addCompass"
        });
        handleClose()
    }
    function drawMath() {
        props.cb['handleMath']('')
        ReactGA.event({
            category: 'Tools',
            action: "handleMath"
        })
        handleClose()
    }
    function uploadBingo() {
        props.setShowTextTools(false);
        randompages = "bingo"
        inputFile.current.click();
        ReactGA.event({
            category: 'Tools',
            action: "UploadBingo"
        });
        handleClose()
    }
    function uploadAmongUs() {
        props.setShowTextTools(false);
        randompages = "amongUs"
        inputFile.current.click();
        ReactGA.event({
            category: 'Tools',
            action: "UploadBingo"
        });
        handleClose()
    }

    function AddMedia(e) {
        props.setShowTextTools(false);
        handleClickB(e, media)
    }
    
    function AddGrids(e) {
        props.setShowTextTools(false);
        handleClickB(e, grids)
    }
    function AddMathEdit(){
        props.setShowTextTools(true)
        drawMath()
    }

    async function getFileFromUrl(url, name, defaultType = 'application/pdf') {
        // url = "https://www.dadsworksheets.com/worksheets/multiplication/spaceship-math-a-v1.pdf"
        if (url.indexOf("dadsworksheets") !== -1) {
            url = url.replace(".html", ".pdf")
        }
        //    url = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
        var proxyUrl = 'https://cors-anywhere.herokuapp.com/'
        url = proxyUrl + url
        const response = await fetch(url,
            {
                method: 'GET',
            },
        );
        var data = await response.blob()
        if (data.size === 0) return null
        return new File([data], name, {
            type: response.headers.get('content-type') || defaultType,
        });
    }

    function downloadPage() {
        handleClose()
        setyn({
            open: true, link:
                "", message: "Page Number to download",
            cb: done, text: true
        })
        async function done(e) {
            setyn({ open: false, message: "", cb: null })
            if (!e) return
            props.cb['DownloadClass'](false, e)
        }
    }
    function uploadPDF() {
        props.setShowTextTools(false);
        setyn({
            open: true, link:
                "", message: "The link to the PDF",
            cb: done, text: true
        })
        async function done(e) {
            setyn({ open: false, message: "", cb: null })
            if (e) {
                var rr = await getFileFromUrl(e, "temp.pdf")
                if (!rr) return
                if (rr && rr.type.indexOf("application/pdf") !== -1) {
                    //  rr.type = "application/pdf"
                }
                var res = 1150
                if (boardConfig.boardResolution) {
                    var r = ib.boardResoltionType[boardConfig.boardResolution]
                    if (r) {
                        var touse = r.h > r.w ? r.w : r.h
                        if (touse < res) res = touse - 100
                    }
                    if (boardConfig.boardResolution === "a4") res = 792
                }
                var ff = await iu.UploadFile(rr, randompages,
                    props.sess ? props.sess.ttl : 0, res)
                if (ff) props.cb.loadImage(ff)
            }
            ReactGA.event({
                category: 'Teacher',
                action: "uploadPDFLink"
            });

            handleClose()
        }
    }
    function publishToCommunity() {
        /* Sample URL
            https://docs.google.com/forms/d/e/1FAIpQLScaoQ_UcFrtMOI_oWjhkZOJcScTBaIbIvReKad6uILeOT3j4A/viewform?usp=pp_url&entry.1867586=Contributor@community.com&entry.1040479962=First+and+Last+Name&entry.1238796437=United+States+of+America&entry.1742748691=Title+of+board&entry.98610795=Description+of+the+board&entry.485492986=K-12+Grades&entry.718409867=The+subject+relevant+to+the+board&entry.1433769603=https://the.link.to.board.com/
        */
        var name = encodeURIComponent(myName).replace(/%20/g, '+');
        var email = null
        if (authUser) {
            try {
                email = encodeURIComponent(authUser.attributes.email).replace(/%20/g, '+');
            } catch { }
        }
        var boardLink = window.location.href
        var ffa = boardLink.split("/board/")
        const title = encodeURIComponent(props.sess.name).replace(/%20/g, '+');
        en.encrypt_data(ffa[1], cbdat)
        function cbdat(dat) {
            var copyLink = ffa[0] + "/copy/" + dat
            var publishFormURL = "https://docs.google.com/forms/d/e/1FAIpQLScaoQ_UcFrtMOI_oWjhkZOJcScTBaIbIvReKad6uILeOT3j4A/viewform?usp=pp_url"
            if (title) {
                publishFormURL = publishFormURL + "&entry.1742748691=" + title
            }
            if (email) {
                publishFormURL = publishFormURL + "&entry.1867586=" + email
            }
            if (name) {
                publishFormURL = publishFormURL + "&entry.1040479962=" + name
            }
            publishFormURL = publishFormURL + "&entry.1433769603=" + copyLink
            window.open(publishFormURL)
        }
    }
    function copyBoard() {
        var ff = window.location.href
        var ffa = ff.split("/board/")
        en.encrypt_data(ffa[1], cbdat)
        function cbdat(dat) {
            let link = ffa[0] + "/copy/" + dat
            setyn({ open: true, link: link, message: "Send the link below to allow people to create copy", cb: done, clip: true })
            function done() {
                setyn({ open: false, message: "", cb: null })
            }
        }
        ReactGA.event({
            category: 'Teacher',
            action: "CopyBoard"
        });
        handleClose()
    }
    function copyBoardunc() {
        var ff = window.location.href
        var ffa = ff.split("/board/")
        cbdat(ffa[1])
        function cbdat(dat) {
            let link = ffa[0] + "/copy/" + dat
            setyn({ open: true, link: link, message: "Send the link below to allow people to create copy", cb: done, clip: true })
            function done() {
                setyn({ open: false, message: "", cb: null })
            }
        }
        ReactGA.event({
            category: 'Teacher',
            action: "CopyBoardUnc"
        });
        handleClose()
    }
    function drawRichText() {
        props.setShowTextTools(true)
        props.cb['drawRichText']('')
        ReactGA.event({
            category: 'Tools',
            action: "drawRichText"
        });
        handleClose()
    }
    function drawNote(e) {
        props.setShowTextTools(true)
        handleClickB(e, PostItNotes)
    }

    function drawNoteIn(color) {
        props.cb['drawNote'](color)
        ReactGA.event({
            category: 'Tools',
            action: "drawNote"
        });
        handleClose()
    }
    function drawSyllable() {
        props.setShowTextTools(false);
        props.cb['drawSyllable']('')
        ReactGA.event({
            category: 'Tools',
            action: "drawSyllable"
        });
        handleClose()
    }
    function drawArrow() {
        props.setShowTextTools(true)
        props.cb['Arrow']('Erase')
        ReactGA.event({
            category: 'Tools',
            action: "Arrow"
        });
        handleClose()
    }
    function drawBrush(e) {
        props.setShowTextTools(true)
        let wo = { name: 'WhiteOut', label: 'WhiteOut', cb: drawBrush, iconType: 'svg', icon: '/tools/whiteOutIcon.svg', number: 1 }
        let penOp = { name: 'Pencil', label: 'Pencil', cb: drawBrush, iconType: 'svg', icon: '/tools/pencilIcon.svg', number: 1 }
        let tt = currentOptions
        
        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Pencil') {
                props.cb['Draw']('Draw')
            } else {
                props.cb['Draw']('Draw', 'white')
            }
            gSelect = e
            return 
        }

        if (e === 'Pencil') {
            tt.splice(0, 1, wo)
            props.cb['Draw']('Draw', 'white')
            e = 'WhiteOut';
        } else {
            tt.splice(0, 1, penOp)
            props.cb['Draw']('Draw')
            e = 'Pencil'
        }
        gSelect = e 
        setcurrentOption([...tt])
    }
    function drawPolygon(e) {
        handleClose()
        props.setShowTextTools(true)
        setyn({
            open: true, link:
                "", message: "Number of sides",
            cb: done, text: true
        })
        async function done(e) {
            setyn({ open: false, message: "", cb: null })
            if (e) props.cb['PolyGon'](e)
        }
    }
    function drawCircle(e) {
        props.setShowTextTools(true)
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'Circle'
        });
        let elipOp = { name: 'Ellipse', label: 'Ellipse', cb: drawCircle, iconType: 'svg', icon: '/tools/icon-ellipse.svg', number:5}
        let cirOp =  { name: 'Circle', label:'Circle',cb: drawCircle, iconType: 'svg', icon:'/tools/icon-circle.svg', number:5}
        let tt = currentOptions

        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Ellipse') {
                props.cb['Circle']()
            } else {
                props.cb['Circle']('Circle')
            }
            gSelect = e
            return 
        }

        if (e === 'Ellipse') {
            tt.splice(4, 1, cirOp)
            props.cb['Circle']('Circle')
            e = 'Circle'
        } else {
            tt.splice(4, 1, elipOp)
            props.cb['Circle']()
            e = 'Ellipse'
        }
        gSelect = e 
        setcurrentOption([...tt])
    }
    function drawRect(e) {
        props.setShowTextTools(true)
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'Rect'
        });
        let rectOp = { name: 'Rectangle', label: 'Rectangle', cb: drawRect, iconType: 'svg', icon: '/tools/rectIcon.svg', number: 4 }
        let sqrOp = { name: 'Square', label: 'Square', cb: drawRect, iconType: 'svg', icon: '/tools/sqaureIcon.svg', number: 4 }
        let tt = currentOptions

        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Rectangle') {
                props.cb['Rect']()
            } else {
                props.cb['Rect']('Square')
            }
            gSelect = e
            return 
        }

        if (e === 'Rectangle') {
            tt.splice(3, 1, sqrOp)
            props.cb['Rect']('Square')
            e = 'Square'
        } else {
            tt.splice(3, 1, rectOp)
            props.cb['Rect']()
            e = 'Rectangle'
        }
        gSelect = e 
        setcurrentOption([...tt])
    }
    function drawSelect() {
        props.setShowTextTools(false);
        props.cb['drawSelect']()
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'drawSelect'
        });
    }
    function drawLine(e) {
        props.setShowTextTools(true)
        handleClose()
        ReactGA.event({
            category: 'Draw',
            action: 'Line'
        });
        //Line1 is normal 
        let line1 = { name: 'Line1', label: 'Line', cb: drawLine, iconType: 'svg', icon: '/tools/lineIcon2.svg', number: 3 }
        let line2 = { name: 'Line2', label: 'Line', cb: drawLine, iconType: 'svg', icon: '/tools/lineIcon1.svg', number: 3 }
        let tt = currentOptions
        let switchTool = (e === gSelect); 

        if (!switchTool) {
            if (e === 'Line1') {
                props.cb['Line']()
            } else {
                props.cb['Line']('Line')
            }
            gSelect = e
            return 
        }

        if (e === 'Line1') {
            tt.splice(2, 1, line2)
            props.cb['Line']('Line')
            e = 'Line2'
        } else {
            tt.splice(2, 1, line1)
            props.cb['Line']()
            e = 'Line1'
        }
        gSelect = e
        setcurrentOption([...tt])
    }

    function manageBoards(sendSession) {
        mylocalStorage.setItem('manageBoardsSession', props.sessionId);

        if (props.sess && props.sess.Classroom) {
            mylocalStorage.setItem('manageBoardsClassroom', props.sess.Classroom);
        } else {
            mylocalStorage.removeItem('manageBoardsClassroom');
        }

        var urlPath = "/manage/";

        if (sendSession) {
            urlPath += props.sessionId;
        }

        props.history.push(urlPath);
    }

    function downloadThisPage() {
        props.cb['downloadThisPage']()
        handleClose()
        ReactGA.event({
            category: 'Download',
            action: 'downloadThisPage'
        });
    }

    function downloadSingle() {
        props.cb['Download']()
        handleClose()
        ReactGA.event({
            category: 'Download',
            action: 'SingleBoard'
        });
    }

    function downloadClass() {
        props.cb['DownloadClass'](false, 0)
        handleClose()
        ReactGA.event({
            category: 'Download',
            action: 'WholeClass'
        });
    }
    function downloadClass2() {
        props.cb['DownloadClass'](true, 0)
        handleClose()
        ReactGA.event({
            category: 'Download',
            action: 'WholeClass2'
        });
    }
    function sendDice(event) {
        if (props.sess.Classroom) {
            var classID = props.sess.id
            if (Boolean(teacherR)) {
                classID = props.sess.Classroom
            }
            ib.sendClassEvent("SendDice", event, props.sess.Classroom, classID, null, props.sess.ttl, true, null);
        }
    }
    function saveCopy() {
        manageBoards(true);
    }
    function showDice() {
        handleClose()

        ReactGA.event({
            category: 'User',
            action: 'showDice'
        });
        sendDice("Start")
        // dispatch(Actions.showDice(true))
    }
    function hideDice() {
        handleClose()

        sendDice("Done")
        //  dispatch(Actions.showDice(false))
    }
    const handleClick = (e, event) => {
        if (e.fav && e.cb) {
            return e.cb(event)
        }
        const v = e.name

        if (teacherR) {
            //download menu options are moved above
        } else {
            if (boardLocked.locked || pageLocked.locked) return
        }

        if (v === 'Menu') props.handleDrawerOpen()
        if (v === 'Refresh') window.location.reload()

        if (v === 'Home') props.history.push("/")
        if (v === 'Clear') setyn({ open: true, message: "This will clear this board for everyone?", cb: clearBoard })
        if (v === 'Hide') props.cb[v]()
        if (v === 'Draw') props.cb[v](v)
        if (v === 'Erase'){
            props.setShowTextTools(false);
            props.cb[v](v)
        } 
        if (v === 'Text') props.cb[v]()
        if (v === 'Box') props.cb[v]()
        if (v === 'Circle') props.cb[v]()
        if (v === 'Pause Animations') {
            props.setShowTextTools(false);
            props.cb[v]()
            handleClose()
        }
        if (v === 'Play Animations') {
            props.cb[v]()
            handleClose()
        }
        if (v === 'Select') {
            props.setShowTextTools(false);
            props.cb[v]()
        }
        if (v === 'Download') handleClickA(event, download)
        if (v === 'Line') props.cb[v]()
        if (v === "Upload File") handleClickA(event, upload)//WCClick()
        // if (v === "Add Media") handleClickA(event, media)//WCClick()
        if (v === 'Tools') {
            props.setShowTextTools(false);
            var tt = [...Tools]
            var check = false
            if (Boolean(teacherR)) {
                check = diceShow.teacher.open
            } else {
                check = diceShow.board.open
            }
            if (!check) {
                tt.splice(8, 0, { name: 'Show Dice', cb: showDice, icon: <CasinoIcon /> })
            } else {
                tt.splice(8, 0, { name: 'Hide Dice', cb: hideDice, icon: <KeyboardHideIcon /> })
            }
                tt = newOps.length === 0 ? [...newTools] : [...newOps]
            if (!Boolean(teacherR) && boardConfig.fourToolsStudent === undefined && boardConfig.simpleDrawingTools === undefined) {
                for (let i = 0; i < tt.length; i++) {
                    if (tt[i].name === "Time Machine") {
                        tt.splice(i, 1);
                    }
                }
            }
            if (!Boolean(teacherR) && boardConfig.simpleDrawingTools) {
                var newTT = []
                for (let i = 0; i < tt.length; i++) {
                    if (tt[i].name === "Grids" || tt[i].name === "Select/Crop" || tt[i].name === "Infinite Cloner" || tt[i].name === "Time Machine") {
                        continue
                    }
                    newTT.push(tt[i])
                }
                tt = newTT
            }
            if (!Boolean(teacherR) && boardConfig.fourToolsStudent) {
                var newTT = []
                for (let i = 0; i < tt.length; i++) {
                    if (tt[i].name !== "Add Media") {
                        continue
                    }
                    newTT.push(tt[i])
                }
                tt = newTT
            }
            if (!Boolean(teacherR) && boardTools && boardTools['Tools'] && boardTools['Tools'].sub && Object.keys(boardTools['Tools'].sub).length > 0) {
                var newTT = []
                for (var i = 0; i < tt.length; i++) {
                    let t = tt[i]
                    if (boardTools['Tools'].sub[t.name] && boardTools['Tools'].sub[t.name].checked) continue
                    newTT.push(t)
                }
                tt = newTT
            }

            if (!Boolean(teacherR)) {

                // remove teacher only
                var ind = tt.findIndex(x => (x.name === "YouTube" || x.name === "Website"))
                if (ind !== -1) {
                    tt.splice(ind, 1)
                }
            }
            handleClickA(event, tt)
        }
        if (v === 'Move & Resize'){
            props.setShowTextTools(false);
             props.cb[v]()
        } 

        if (v === 'Lasso'){
            props.setShowTextTools(false);
             props.cb[v]()
        } 
        // if (v === 'Grids') handleClickA(event, grids)
        if (v === 'Drawing Tools') {
            if (boardConfig.fourToolsStudent && Boolean(!teacherR)) {
                drawBrush()
            }
            else {
            var tt = [...InkingTool]
            if (!Boolean(teacherR) && boardTools && boardTools['Drawing Tools'] && boardTools['Drawing Tools'].sub && Object.keys(boardTools['Drawing Tools'].sub).length > 0) {
                var newTT = []
                for (var i = 0; i < tt.length; i++) {
                    let t = tt[i]
                    if (boardTools['Drawing Tools'].sub[t.name] && boardTools['Drawing Tools'].sub[t.name].checked) continue
                    newTT.push(t)
                }
                tt = newTT
            }
            handleClickA(event, tt)
        }
        }
        if (v === 'Upload'){
            props.setShowTextTools(false); 
            var tt = [...newUploadOptions]
            if (!Boolean(teacherR) && boardTools && boardTools['Upload'] && boardTools['Upload'].sub && Object.keys(boardTools['Upload'].sub).length > 0) {
                var newTT = []
                for (var i = 0; i < tt.length; i++) {
                    let t = tt[i]
                    if (boardTools['Upload'].sub[t.name] && boardTools['Upload'].sub[t.name].checked) continue
                    newTT.push(t)
                }
                tt = newTT
            }
            handleClickA(event, tt)
        } 
        if (v === 'Write') {
            if (boardConfig.fourToolsStudent && Boolean(!teacherR)) {
                clickText()
            }
           else {
            var tt = [...WriteTools]
            if (!Boolean(teacherR) && boardTools && boardTools['Write'] && boardTools['Write'].sub && Object.keys(boardTools['Write'].sub).length > 0) {
                var newTT = []
                for (var i = 0; i < tt.length; i++) {
                    let t = tt[i]
                    if (boardTools['Write'].sub[t.name] && boardTools['Write'].sub[t.name].checked) continue
                    newTT.push(t)
                }
                tt = newTT
            }
            handleClickA(event, tt)
         }
        }
        if (v === 'Student signals') {
            props.setShowTextTools(false);
            handleClickA(event, newStudentTools)
        }
        if (v === 'Manage Boards') {
            props.setShowTextTools(false);
            manageBoards(false);
        }

        if (v === 'Manage Book' && teacherR) handleClickA(event, ManageBoards)
        ReactGA.event({
            category: 'User',
            action: v
        });
    };

    function copyPage() {
        ib.makePageCopy(props.sess.id, props.sess, fin)
        function fin(pg, success) {
            var msg = "Successfully created page #"
            if (!success) msg = "Could not create new page, it already exists #"
            msg = msg + pg
            props.cb["Message"](msg)
        }
    }
    function delPage() {
        if (!teacherR) return
        setyn({ open: true, message: "This will delete this page permanently, are you sure?", cb: delPageyn })
    }
    function delForever() {
        if (!teacherR) return
        setyn({ open: true, message: "This will delete the board permanently, are you sure?", cb: ynClick })
    }
    async function loadImage(img) {
        const f = img.target.files
        if (f.length !== 1) return
        props.cb["Message"]("Loading image, please wait")
        var res = 1150
        if (boardConfig.boardResolution) {
            var r = ib.boardResoltionType[boardConfig.boardResolution]
            if (r) {
                var touse = r.h > r.w ? r.w : r.h
                if (touse < res) res = touse - 100
            }
            if (boardConfig.boardResolution === "a4") res = 792
        }
        var ff = await iu.UploadFile(f[0], randompages,
            props.sess ? props.sess.ttl : 0, res)
        if (ff) props.cb.loadImage(ff)
    }

    async function loadFile(file) {
        var user = mylocalStorage.getItem('mystoreID');
        bUtils.loadFile(file, user, statusSnackbar)
    }


    function ynClick(val) {
        setyn({ open: false, message: "", cb: null })
        if (val === true) {
            ib.delSession(props.sessionId)
            props.history.push("/")
        }
    }

    function delPageyn(val) {
        setyn({ open: false, message: "", cb: null })
        if (val === true) {
            const pgSplit = props.sessionId.split("-pgNum-")

            ib.deletePage(props.sessionId, done)
            function done() {
                if (pgSplit[1] !== "1") {
                    var ff = parseInt(pgSplit[1]) - 1
                    var url = "/board/" + pgSplit[0] + "-pgNum-" + ff
                    props.history.push(url)
                    window.location.reload()
                    return
                } else {
                    props.history.push("/")
                }
            }
        }
    }
    function clearBoard(val) {
        setyn({ open: false, message: "", cb: null })
        if (val === true) {
            props.cb.clearCanvas()
        }
    }

    //  this is to check whether sidebar is opned? if so not allowing to use features on whiteboard
    function IsAllowedToUse() {
        var lu = mylocalStorage.getItem('sidebar')
        var allowedToUse = true
        if (lu) allowedToUse = false
        return allowedToUse
    }

    const [wcdialog, setWCDialog] = React.useState({ open: false, cb: null })
    function WCClick() {
        props.setShowTextTools(false);
        if (!IsAllowedToUse()) {
            return
        }
        handleClose()
        if (wcdialog.open) {
            return
        }
        setWCDialog({ open: true, cb: done })

        async function done(e) {
            setWCDialog({ open: false, cb: null })
            if (e) {
                var ff = await iu.UploadFile(e, false,
                    props.sess ? props.sess.ttl : 0)
                if (ff) props.cb.loadImage(ff)
            }
        }
    }
    const [mediaDialog, setMediaDialog] = React.useState({ open: false, cb: null })
    function MediaClick() {
        props.setShowTextTools(false);
        if (!IsAllowedToUse()) {
            return
        }
        handleClose()
        if (mediaDialog.open) {
            return
        }
        var video = false
        if (boardConfig.StudentWebcamVideo) video = true
        setMediaDialog({ open: true, cb: done, videoOn: video })

        async function done(e) {
            setMediaDialog({ open: false, cb: null })
            if (e) {
                var ff = await iu.wroteFile(e.file, null, null, "mp4")
                if (ff) props.cb.mediaInsert({ file: ff, name: e.name })
            }
        }
    }

    function exportBoard(local, path, instance) {
        if (local) {
            props.cb["Export"](local)
        } else {
            props.cb["Export"](local, path, instance)
        }
    }

    function setFavList() {
        var lu = null
        gFav = {}
        if (!lu) {
            setFavOptions([])
            return
        } else {
            lu = JSON.parse(lu)
        }
        var final = []
        lu.forEach(o => {

            function checkOption(oo) {
                var fnd = false
                var ix = oo.findIndex(x => x.name === o)
                if (ix !== -1) {
                    var x = oo[ix]
                    final.push({ name: x.name, icon: x.icon, cb: x.cb, fav: true, iconType: x.iconType, isAnimate: x.isAnimate })
                    fnd = true
                }
                return fnd
            }
            var f = false
            if (!f) f = checkOption(options)
            if (!f) f = checkOption(Tools)
            if (!f) f = checkOption(newOps.length === 0 ? newTools : newOps)
            if (!f) f = checkOption(grids)
            if (!f) f = checkOption(Music)
            if (!f) f = checkOption(Shapes)
            if (!f) f = checkOption(Factory)

            if (!f) f = checkOption(Calculators)
            if (!f) f = checkOption(StudentTools)
            if (!f) f = checkOption(PointerTools)
            if (!f) f = checkOption(PostItNotes)
            if (!f) f = checkOption(ManageBoards)
            if (!f) f = checkOption(media)
            if (!f) f = checkOption(download)
            if (!f) f = checkOption(InkingTool)
            if (!f) f = checkOption(UploadTools)
            if (!f) f = checkOption(WriteTools)
            if (!f) f = checkOption(newOptions)

            gFav[o] = true
        })
        if (final.length > 0) {
            final[final.length - 1].dnext = true
        }
        // setFavOptions(final)
    }
    function favItem(e) {
        var lu = mylocalStorage.getItem(FAVICON)
        if (!lu) {
            lu = []
        } else {
            lu = JSON.parse(lu)
        }
        var idx = lu.indexOf(e.name)
        if (idx !== -1) {
            lu.splice(idx, 1)
        } else {
            lu.push(e.name)
        }
        mylocalStorage.setItem(FAVICON, JSON.stringify(lu))

        setFavList()
    }

    function clickText(){
        props.setShowTextTools(true)
        props.cb['Text']()
        gSelect = 'Text'
        handleClose()
    }

    function handleCb(option, index) {
        if (option.name !== 'Color') {
            setActivatedOption(option.number)
        }
        option.cb(option.name)
    }

    return (
        <>
            <YesNoDialog {...yn} inkColor={props.inkColor}  />
            {timeMachine.open && (<TimeMachine {...props} />)}
            {wcdialog.open && (<WebcamDialog {...wcdialog} inkColor={props.inkColor}/>)}
            {coordialog.open && <CoordinateDialog {...coordialog} inkColor={props.inkColor} />}
            {mediaDialog.open && (
                <Suspense fallback={<div>Loading...</div>}>
                    <MediaDialog {...mediaDialog} inkColor={props.inkColor} />
                </Suspense>
            )}
            <div id='wbcMenu' className='longMenuDiv'>
                {/* Resetting the value as we are not going to be using the built-in file input value at all. */}
                {pickFile.open && (<PickFile {...pickFile} />)}
                <input type='file' id='file' accept="application/pdf,image/*" onChange={loadImage} ref={inputFile} style={{ display: 'none' }} value={""} />
                <input type='file' id='importFile' accept=".zip,application/json,.wbc" onChange={loadFile} ref={importFile} style={{ display: 'none' }} value={""} />
                <List id="menuBar" dense={true} style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto', overflowX: 'hidden' }}>
                    {currentOptions.map((option, index) => (
                        <React.Fragment key={option.name}>
                            {/* <Tooltip className={classes.tooltip}
                                classes={{
                                    tooltip: classes.customTooltip,
                                    arrow: classes.customArrow
                                }}
                                title={option.name} arrow placement="right"> */}
                            <ListItem button
                                classes={{ root: clsx('tool', classes.item, [classes.itemTop]) }}
                                onClick={(e) => handleCb(option)} id={option.id}
                                selected={option.number === activatedOption} >
                                        <ListItemIcon className={classes.icon}
                                            classes={{
                                                root: clsx(classes.itemIconTop, 'newItemTop')
                                            }}
                                        >
                                          {option.iconType ?
                                        <ListItemIcon className={classes.icon}
                                            classes={{
                                                root: clsx(classes.itemIconTop, 'newItemTop')
                                            }}
                                        >
                                            <img src={option.icon} alt={option.icon} />
                                        </ListItemIcon>
                                        :
                                        <IconButton className='colorICBtn' style={{ background: props.penBtnColor}} aria-label="Pen Opacity"  >
                                        <span ></span>
                                    </IconButton>
                                    }
                                        </ListItemIcon>
                                <ListItemText className='tool-name' primary={option.label ? option.label : option.name} />
                               
                            </ListItem>
                                {/* </Tooltip> */}
                            {option.dnext && (<Divider className={classes.divider} />)}

                        </React.Fragment>

                        // <div key={option.name}>
                        //     <Tooltip arrow title={option.name} placement="left" >
                        //         <IconButton className="minFrameButton" size="small"
                        //             onClick={() => handleClick(option)} >
                        //             {option.icon}
                        //         </IconButton>
                        //     </Tooltip>
                        // </div>
                    ))}
                </List>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={message !== ""}
                    onClose={() => setMessage("")}
                    message={message}
                    key={"top center"}
                    autoHideDuration={3000}
                />

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={statusMsg !== ""}
                    onClose={() => setStatusMsg("")}
                    message={statusMsg}
                    key={"status SnackBar"}
                    autoHideDuration={3000}
                />
            </div>
        </>
    );
}

export default React.memo(WbcJrLongMenu)
