import React, { useState, useEffect } from "react";
import {
    Grid
} from "@material-ui/core";
import * as ib from "./ibdata.js"
import BoardTile from './BoardTile'
import './index.css';
import "./SessionTableFileExplorer.css";
import { Snackbar } from "@material-ui/core"
// import * as en from "./encDec";



export default function CommunityBoard(props) {
    var [boardsInfo, setBoardsInfo] = useState([])
    const [filteredData2, setfilteredData2] = useState(null);
    var sessionsToMapCommunity = filteredData2 === null ? boardsInfo : filteredData2;

    const [message, setMessage] = React.useState("")
    function aniMess(mess) {
        setMessage(mess)
    }

    useEffect(() => {
        if (props.boardSearchVal && boardsInfo) {
            let regEx = props.boardSearchVal;
            //todo : need to update this once all the real time data comes in state
            // console.log(sessions.filter(d => d.name.match(regEx) || (d.subject !== null && d.subject.match(regEx)) || (d.grade !== null && d.grade.match(regEx))))
            // setfilteredData2(sessions.filter(d => d.name.match(regEx) || (d.subject !== null && d.subject.match(regEx)) || (d.grade !== null && d.grade.match(regEx))))
            setfilteredData2(boardsInfo.filter(bi => {
                if (bi.name && bi.name.match(regEx)) return true
                if (bi.title && bi.title.match(regEx)) return true
                if (bi.Grade && bi.Grade.match(regEx)) return true
                if (bi.Subject && bi.Subject.match(regEx)) return true
                if (bi.description && bi.description.match(regEx)) return true
                return false
            }))
        }

    }, [props.boardSearchVal, boardsInfo])

    function CopyBoard(bid){
        props.history.push("/copy/" + bid)
    }

    React.useEffect(() => {
        if (!props.user) return
        
        async function getCommBoardsInfo() {
            var commBoards = await ib.listCommunityBoardsSync()
            setBoardsInfo(p => [...commBoards])
        }
        getCommBoardsInfo()
    }, [props.user]);


    function setText(name, row) {
        // row.name = name
        // gSessions = [...sessions];
        // setSessions(gSessions);
    }

    function rowBlur(row) {
        // row.edit = false
        // delete row['edit']
        // ib.setName(row);
        // var index = sessions.findIndex((rb) => rb.id === row.id);
        // sessions[index] = row;
        // gSessions = [...sessions];
        // setSessions(gSessions);
    }

    function viewAction(row) {
        props.history.push("/board/" + row.id)
        window.location.reload()
    }

    function deleteAction(row) {
        var pp = row.id.split("-pgNum-")
        ib.getboardsbyparent(pp[0], null, delPages)
        function delPages(items) {
            if (!items) return
            items.forEach((item) => {
                try {
                    ib.delSession(item.id, function () { })
                } catch (err) {
                    // Ignore it
                }
            })
        }
    }

    return (
        <>
        <div>
            <Grid container className='boardsScroll' style={{ height: props.boardScrollHt }}>
                {sessionsToMapCommunity && sessionsToMapCommunity.length > 0 ? sessionsToMapCommunity.map((sb, indx) => {
                    return (
                        <React.Fragment key={'fboard' + sb.id}>
                            {sb &&
                                <Grid item xs={12} sm={6} md={4} lg={4} className='relative' key={'board' + sb.id}>
                                    <BoardTile data={sb} viewAction={viewAction} deleteAction={deleteAction}
                                        localDateFormat={props.localDateFormat} iconProps={props.getIconColor(sb.id)}
                                        handleRefresh={props.handleRefresh} showExpand={false} handleOpen={() => { }} rowIsOpen={false}
                                        rowBlur={rowBlur} rowClick={props.rowClick} user={props.user} setText={setText} 
                                        community={true} CopyBoard={CopyBoard} editable={false}
                                        boardInfo={sb} />
                                </Grid>}
                        </React.Fragment>
                    )
                }) :
                    ''

                }
            </Grid>
            <Snackbar
                open={message !== ""}
                onClose={() => setMessage("")}
                message={message}
                key={"top center"}
            />
        </div>
        </>
    )
}