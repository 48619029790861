import React from 'react';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    Grid,
    InputBase,
    Divider
} from '@material-ui/core';
import clsx from "clsx";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: " 0.5px solid rgba(0, 0, 0, 0.08)",
        position: "absolute",
        width: "450px !important",
        minHeight: "360px",
        zIndex: 105,
        color: "#ffffff",
        backgroundColor: "#ffffff",
        boxSizing: "border-box",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16)",
        borderRadius: "4px",
    },
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "relative",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        paddingBottom: "1px",
        left: 0,
        padding:0,
        margin: '0px 0px 20px 0px !important'
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    createButton: {
        color: "#FFFFFF",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "999px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        width: "100px",
        padding: "6px 12px",
        textTransform: "capitalize",
        letterSpacing: "-0.078px !important",
        "&:hover": {
            color: '#2F3D5B',
        }
    },
}))
export default function TileFactoryDialog(props) {
    const classes = useStyles();
    const [windowPosition, setWindowPosition] = React.useState({ x: 100, y: 280 });
    const [text, setText] = React.useState("")
    const [control, setControl] = React.useState({ studentClone: false, studentMove: false, fixSize: false, fontColorBlack: false, makeCards: false, makeMatchCards: false, makeASLTile: false})
    React.useEffect(()=>{
        let tEle = document.getElementById('tileFact')
        if(tEle){
            tEle.placeholder = 'Tile, Color, fontcolor\n[Color and fontColor are optional]\nCards front; back [back optional]'
        }
     },[])
    const handleChange2 = (event) => {
        var old = { ...control, [event.target.name]: event.target.checked }
        if (event.target.name === "studentClone" && event.target.checked) {
            old.studentMove = false
        }
        if (event.target.name === "studentMove" && event.target.checked) {
            old.studentClone = false
        }
        setControl(old);
    };
    function handleDrag(e, ui) {
        if ((ui.y < 120 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        props.cb(null)
    }
    function create(e) {
        props.cb({ text: text, control: control })
    }
    function pasteText(e) {
        e.preventDefault()
        setText(text + e.clipboardData.getData('text/plain'))
    }
        return (
            <Draggable
                handle="strong"
                onDrag={handleDrag}
                onStop={handleDragEnd}
                position={windowPosition}
                defaultClassName="draggable-color"
                defaultClassNameDragging="draggable2-color"
                defaultClassNameDragged="draggable3-color"
            >
                <div id="smallpalette" className={clsx(classes.draggableWindow,'dragDiv')}
                    style={{
                        border: '5px solid ' + props.inkColor,
                        // backgroundColor: '#ffffff44'
                    }}>
                    <div className={clsx(classes.draggableTop, 'dragDivmain')}
                        style={{ width: "100%", backgroundColor: props.inkColor }}>
                        <strong className={clsx(classes.draggableTopHandle, 'dragDivChild1')} >
                        <MoreHorizIcon style={{ color: "#7D8FB6", position: "absolute", top: "-7", left: "196px", cursor: "move" }} />
                        </strong>
                    </div>
                    <div className={clsx(classes.cp, 'dragDivForm tileFactContainer')}
                        style={{ width: "90%" }}>
                          
                          <div className=' formPanel'>
                        <div style={{
                            margin: "0px 0px 10px 0px", fontSize: "16px", color: "#2F3D5B", fontWeight: "bolder",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <span> Tile Factory</span>
                            <CloseIcon style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%" }} onClick={handleClose} />
                        </div>
                        <div>
                            <TileFactoryPanelHelp/>
                        </div>
                        <Grid container style={{ marginTop: "20px" }}>
                            <Grid item sm={12} md={6} lg={6} className='rtBorder'>
                        <div className='TileChkBoxes'>

                        <FormControlLabel
                            control={<Checkbox checked={control.studentClone} onChange={handleChange2} name="studentClone" />}
                            label="Students Can Clone"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={control.studentMove} onChange={handleChange2} name="studentMove" />}
                            label="Students Can Move "
                        />
                        <FormControlLabel
                            control={<Checkbox checked={control.fixSize} onChange={handleChange2} name="fixSize" />}
                            label="Fix the size"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={control.fontColorBlack} onChange={handleChange2} name="fontColorBlack" />}
                            label="Default font black"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={control.makeCards} onChange={handleChange2} name="makeCards" />}
                            label="Make Cards"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={control.makeMatchCards} onChange={handleChange2} name="makeMatchCards" />}
                            label="Make Matching Cards"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={control.makeASLTile} onChange={handleChange2} name="makeASLTile" />}
                            label="Make ASL tiles"
                        />
                        </div>
                            </Grid>
                            <Grid item sm={12} md={6} lg={6}>
                                <div>
                                    <TextField
                                        placeholder="Tile, Color, fontcolor&#10;[Color and fontColor are optional]&#10;Cards front; back [back optional]"
                                        fullWidth
                                        onPaste={pasteText}
                                        multiline
                                        id="tileFact"
                                        rows={6}
                                        rowsMax={6}
                                        value={text}
                                        style={{ marginLeft: "10px" }}
                                        className='tileTxtArea'
                                        onChange={(e) => setText(e.target.value)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: "10px -22px" }} />
                        <div className='formBtns'>
                            <Button
                                variant="contained"
                                size="small"
                                className = {classes.createButton}
                                style={{ backgroundColor: props.inkColor }}
                                onClick={() => create()}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                   </div>

                </div>

            </Draggable>

        );
   
}

function TileFactoryPanelHelp() {
    return (
        <div className='tfHelp'>
            <div><b>Tile Format (Colors optional)</b></div>
            <div>
                <li>Text, TileColor, TextColor , e.g. A, Red, White</li>
            </div>
            <div><b>Card Format (Back Text optional)</b></div>
            <div>
                <li>Front Text; Back Text ,e.g: A; Apple, B; Baby</li>
            </div>
            <div><b>Matching Cards Format</b></div>
            <div>
                <li>Card Text; Matching Card Text,e.g: 2+2;4, 5-3;2</li>
            </div>
        </div>
    )
}