import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TextField, InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

export default function YesNoDialog(props) {
  const joinCodeTextAreaRef = React.useRef(null);
  const [text, setText] = React.useState("");

  const handleAgree = () => {
    if (props.cb) props.cb(true)
  };

  const handleClose = () => {
    if (props.cb) props.cb(false)
  };
  function handlecopyCode(e) {
    if (!joinCodeTextAreaRef || !joinCodeTextAreaRef.current) return
    joinCodeTextAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  }

  function loadClicked() {
    if (props.cb) props.cb(text)
  }
  function handleLoad(e) {
    setText(e.target.value)
  }
  function pasteText(e) {
    e.preventDefault()
    const txt = e.clipboardData.getData('text/plain')
    setText(txt)
  }
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!props.clip && !props.text && (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" className="dialogMessage">
                {props.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialogActionButtons">
              <Button onClick={handleClose} color="primary" style={{backgroundColor:props.inkColor}} className= "createButton" >
                No
          </Button>
              <Button onClick={handleAgree} color="primary" autoFocus style={{backgroundColor:props.inkColor}} className= "createButton" >
                Yes
          </Button>
            </DialogActions>
          </>
        )}
        {props.text && (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" className="dialogMessage">
                {props.message}
              </DialogContentText>
              <div className="textFiledInput">
              <TextField
                id="joinCodeTextField"
                InputProps={{
                  readOnly: false,
                }}
                value={text}
                onChange={handleLoad}
                onPaste={pasteText}
                variant="outlined"
                style={{width: "100%"}}
              />
              </div>

            </DialogContent>
            <DialogActions className="dialogActionButtons">
              <Button onClick={loadClicked} color="primary" autoFocus style={{backgroundColor:props.inkColor, marginRight: "10px"}} className= "createButton">
                Set
          </Button>
              <Button onClick={handleClose} color="primary" style={{backgroundColor:props.inkColor}} className= "createButton">
                Close
          </Button>

            </DialogActions>
          </>
        )}
        {props.clip && (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" className="dialogMessage">
                {props.message}
              </DialogContentText>
              <div className="textFiledInput" >
              <TextField
                id="joinCodeTextField"
                inputRef={joinCodeTextAreaRef}
                InputProps={{
                  readOnly: true,
                  endAdornment:
                    <InputAdornment position="start">
                      <Tooltip title='Copy Classroom Copy Link'>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handlecopyCode}
                        >
                          <FileCopyOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>,
                }}
                variant="outlined"
                style={{width: "350px"}}
                value={props.link}
              />
              </div>

            </DialogContent>
            <DialogActions className="dialogActionButtons">
              <Button onClick={handleClose} color="primary" style={{backgroundColor:props.inkColor}} className= "createButton">
                Close
          </Button>

            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}