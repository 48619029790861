import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button, Select, MenuItem, FormHelperText
} from '@material-ui/core';
import SettingsVoiceIcon from '@material-ui/icons/SettingsVoice';
import * as ib from "./ibdata.js";
import io from 'socket.io-client';
import MicOffIcon from '@material-ui/icons/MicOff';
import CancelIcon from '@material-ui/icons/Cancel';
import * as mylocalStorage from "./mylocalStorage";

const useStyles = makeStyles((theme) => ({

}));
var gLang = "en-US"
var gTranslate = "default"
var dataTimer
const langList = [
    { name: "Afrikaans (South Africa)", id: "af-ZA" },
    { name: "Albanian (Albania)", id: "sq-AL" },
    { name: "Amharic (Ethiopia)", id: "am-ET" },
    { name: "Arabic (Algeria)", id: "ar-DZ" },
    { name: "Arabic (Bahrain)", id: "ar-BH" },
    { name: "Arabic (Egypt)", id: "ar-EG" },
    { name: "Arabic (Iraq)", id: "ar-IQ" },
    { name: "Arabic (Israel)", id: "ar-IL" },
    { name: "Arabic (Jordan)", id: "ar-JO" },
    { name: "Arabic (Kuwait)", id: "ar-KW" },
    { name: "Arabic (Lebanon)", id: "ar-LB" },
    { name: "Arabic (Morocco)", id: "ar-MA" },
    { name: "Arabic (Oman)", id: "ar-OM" },
    { name: "Arabic (Qatar)", id: "ar-QA" },
    { name: "Arabic (Saudi Arabia)", id: "ar-SA" },
    { name: "Arabic (State of Palestine)", id: "ar-PS" },
    { name: "Arabic (Tunisia)", id: "ar-TN" },
    { name: "Arabic (United Arab Emirates)", id: "ar-AE" },
    { name: "Arabic (Yemen)", id: "ar-YE" },
    { name: "Armenian (Armenia)", id: "hy-AM" },
    { name: "Azerbaijani (Azerbaijan)", id: "az-AZ" },
    { name: "Basque (Spain)", id: "eu-ES" },
    { name: "Bengali (Bangladesh)", id: "bn-BD" },
    { name: "Bengali (India)", id: "bn-IN" },
    { name: "Bosnian (Bosnia and Herzegovina)", id: "bs-BA" },
    { name: "Bulgarian (Bulgaria)", id: "bg-BG" },
    { name: "Burmese (Myanmar)", id: "my-MM" },
    { name: "Catalan (Spain)", id: "ca-ES" },
    { name: "Chinese Cantonese (Traditional Hong Kong)", id: "yue-Hant-HK" },
    { name: "Chinese Simplified", id: "zh" },
    { name: "Chinese Traditional", id: "zh-TW" },
    { name: "Croatian (Croatia)", id: "hr-HR" },
    { name: "Czech (Czech Republic)", id: "cs-CZ" },
    { name: "Danish (Denmark)", id: "da-DK" },
    { name: "Dutch (Belgium)", id: "nl-BE" },
    { name: "Dutch (Netherlands)", id: "nl-NL" },
    { name: "English (Australia)", id: "en-AU" },
    { name: "English (Canada)", id: "en-CA" },
    { name: "English (Ghana)", id: "en-GH" },
    { name: "English (Hong Kong)", id: "en-HK" },
    { name: "English (India)", id: "en-IN" },
    { name: "English (Ireland)", id: "en-IE" },
    { name: "English (Kenya)", id: "en-KE" },
    { name: "English (New Zealand)", id: "en-NZ" },
    { name: "English (Nigeria)", id: "en-NG" },
    { name: "English (Pakistan)", id: "en-PK" },
    { name: "English (Philippines)", id: "en-PH" },
    { name: "English (Singapore)", id: "en-SG" },
    { name: "English (South Africa)", id: "en-ZA" },
    { name: "English (Tanzania)", id: "en-TZ" },
    { name: "English (United Kingdom)", id: "en-GB" },
    { name: "English (United States)", id: "en-US" },
    { name: "Estonian (Estonia)", id: "et-EE" },
    { name: "Filipino (Philippines)", id: "fil-PH" },
    { name: "Finnish (Finland)", id: "fi-FI" },
    { name: "French (Belgium)", id: "fr-BE" },
    { name: "French (Canada)", id: "fr-CA" },
    { name: "French (France)", id: "fr-FR" },
    { name: "French (Switzerland)", id: "fr-CH" },
    { name: "Galician (Spain)", id: "gl-ES" },
    { name: "Georgian (Georgia)", id: "ka-GE" },
    { name: "German (Austria)", id: "de-AT" },
    { name: "German (Germany)", id: "de-DE" },
    { name: "German (Switzerland)", id: "de-CH" },
    { name: "Greek (Greece)", id: "el-GR" },
    { name: "Gujarati (India)", id: "gu-IN" },
    { name: "Hebrew (Israel)", id: "iw-IL" },
    { name: "Hindi (India)", id: "hi-IN" },
    { name: "Hungarian (Hungary)", id: "hu-HU" },
    { name: "Icelandic (Iceland)", id: "is-IS" },
    { name: "Indonesian (Indonesia)", id: "id-ID" },
    { name: "Italian (Italy)", id: "it-IT" },
    { name: "Italian (Switzerland)", id: "it-CH" },
    { name: "Japanese (Japan)", id: "ja-JP" },
    { name: "Javanese (Indonesia)", id: "jv-ID" },
    { name: "Kannada (India)", id: "kn-IN" },
    { name: "Khmer (Cambodia)", id: "km-KH" },
    { name: "Korean (South Korea)", id: "ko-KR" },
    { name: "Lao (Laos)", id: "lo-LA" },
    { name: "Latvian (Latvia)", id: "lv-LV" },
    { name: "Lithuanian (Lithuania)", id: "lt-LT" },
    { name: "Macedonian (North Macedonia)", id: "mk-MK" },
    { name: "Malay (Malaysia)", id: "ms-MY" },
    { name: "Malayalam (India)", id: "ml-IN" },
    { name: "Marathi (India)", id: "mr-IN" },
    { name: "Mongolian (Mongolia)", id: "mn-MN" },
    { name: "Nepali (Nepal)", id: "ne-NP" },
    { name: "Norwegian Bokmål (Norway)", id: "no-NO" },
    { name: "Persian (Iran)", id: "fa-IR" },
    { name: "Polish (Poland)", id: "pl-PL" },
    { name: "Portuguese (Brazil)", id: "pt-BR" },
    { name: "Portuguese (Portugal)", id: "pt-PT" },
    { name: "Punjabi (Gurmukhi India)", id: "pa-Guru-IN" },
    { name: "Romanian (Romania)", id: "ro-RO" },
    { name: "Russian (Russia)", id: "ru-RU" },
    { name: "Serbian (Serbia)", id: "sr-RS" },
    { name: "Sinhala (Sri Lanka)", id: "si-LK" },
    { name: "Slovak (Slovakia)", id: "sk-SK" },
    { name: "Slovenian (Slovenia)", id: "sl-SI" },
    { name: "Spanish (Argentina)", id: "es-AR" },
    { name: "Spanish (Bolivia)", id: "es-BO" },
    { name: "Spanish (Chile)", id: "es-CL" },
    { name: "Spanish (Colombia)", id: "es-CO" },
    { name: "Spanish (Costa Rica)", id: "es-CR" },
    { name: "Spanish (Dominican Republic)", id: "es-DO" },
    { name: "Spanish (Ecuador)", id: "es-EC" },
    { name: "Spanish (El Salvador)", id: "es-SV" },
    { name: "Spanish (Guatemala)", id: "es-GT" },
    { name: "Spanish (Honduras)", id: "es-HN" },
    { name: "Spanish (Mexico)", id: "es-MX" },
    { name: "Spanish (Nicaragua)", id: "es-NI" },
    { name: "Spanish (Panama)", id: "es-PA" },
    { name: "Spanish (Paraguay)", id: "es-PY" },
    { name: "Spanish (Peru)", id: "es-PE" },
    { name: "Spanish (Puerto Rico)", id: "es-PR" },
    { name: "Spanish (Spain)", id: "es-ES" },
    { name: "Spanish (United States)", id: "es-US" },
    { name: "Spanish (Uruguay)", id: "es-UY" },
    { name: "Spanish (Venezuela)", id: "es-VE" },
    { name: "Sundanese (Indonesia)", id: "su-ID" },
    { name: "Swahili (Kenya)", id: "sw-KE" },
    { name: "Swahili (Tanzania)", id: "sw-TZ" },
    { name: "Swedish (Sweden)", id: "sv-SE" },
    { name: "Tamil (India)", id: "ta-IN" },
    { name: "Tamil (Malaysia)", id: "ta-MY" },
    { name: "Tamil (Singapore)", id: "ta-SG" },
    { name: "Tamil (Sri Lanka)", id: "ta-LK" },
    { name: "Telugu (India)", id: "te-IN" },
    { name: "Thai (Thailand)", id: "th-TH" },
    { name: "Turkish (Turkey)", id: "tr-TR" },
    { name: "Ukrainian (Ukraine)", id: "uk-UA" },
    { name: "Urdu (India)", id: "ur-IN" },
    { name: "Urdu (Pakistan)", id: "ur-PK" },
    { name: "Uzbek (Uzbekistan)", id: "uz-UZ" },
    { name: "Vietnamese (Vietnam)", id: "vi-VN" },
    { name: "Zulu (South Africa)", id: "zu-ZA" },
    { name: "Afrikaans (South Africa)", id: "af-ZA" },
]
// Stream Audio
let bufferSize = 2048,
    AudioContext,
    context,
    processor,
    input,
    globalStream;

//audioStream constraints
const constraints = {
    audio: true,
    video: false
};
var socket
let AudioStreamer = {
    /**
     * @param {function} onData Callback to run on data each time it's received
     * @param {function} onError Callback to run on an error if one is emitted.
     */
    initRecording: function (onData, onError) {
        socket = io('wss://oembed.whiteboard.chat:8443');
        socket.on("connection", sock => {
        })
        socket.emit('startGoogleCloudStream', {
            config: {
                encoding: 'LINEAR16',
                sampleRateHertz: 16000,
                languageCode: gLang.trim(),
                profanityFilter: true,
                enableWordTimeOffsets: true
            },
            interimResults: true // If you want interim results, set this to true
        }); //init socket Google Speech Connection
        AudioContext = window.AudioContext || window.webkitAudioContext;
        context = new AudioContext();
        processor = context.createScriptProcessor(bufferSize, 1, 1);
        processor.connect(context.destination);
        context.resume();

        var handleSuccess = function (stream) {
            globalStream = stream;
            if (!context) {
                if (onError) onError()
                return 
            }
            input = context.createMediaStreamSource(stream);
            input.connect(processor);

            processor.onaudioprocess = function (e) {
                microphoneProcess(e);
            };
        };

        navigator.mediaDevices.getUserMedia(constraints)
            .then(handleSuccess);

        // Bind the data handler callback
        if (onData) {
            socket.on('speechData', (data) => {
                onData(data);
            });
        }

        socket.on('googleCloudStreamError', (error) => {
            if (onError) {
                onError('error');
            }
            // We don't want to emit another end stream event
            closeAll();
        });
    },

    stopRecording: function () {
        if (!socket) return
        socket.emit('endGoogleCloudStream', '');
        closeAll();
    }
}

// export default AudioStreamer;

// Helper functions
/**
 * Processes microphone data into a data stream
 * 
 * @param {object} e Input from the microphone
 */
function microphoneProcess(e) {
    if (!socket) return
    var left = e.inputBuffer.getChannelData(0);
    var left16 = convertFloat32ToInt16(left);
    socket.emit('binaryAudioData', left16);
}

/**
 * Converts a buffer from float32 to int16. Necessary for streaming.
 * sampleRateHertz of 1600.
 * 
 * @param {object} buffer Buffer being converted
 */
function convertFloat32ToInt16(buffer) {
    let l = buffer.length;
    let buf = new Int16Array(l / 3);

    while (l--) {
        if (l % 3 === 0) {
            buf[l / 3] = buffer[l] * 0xFFFF;
        }
    }
    return buf.buffer
}

/**
 * Stops recording and closes everything down. Runs on error or on stop.
 */
function closeAll() {
    // Clear the listeners (prevents issue if opening and closing repeatedly)
    socket.off('speechData');
    socket.off('googleCloudStreamError');
    let tracks = globalStream ? globalStream.getTracks() : null;
    let track = tracks ? tracks[0] : null;
    if (track) {
        track.stop();
    }

    if (processor) {
        if (input) {
            try {
                input.disconnect(processor);
            } catch (error) {
                console.warn('Attempt to disconnect input failed.')
            }
        }
        processor.disconnect(context.destination);
    }
    if (context) {
        context.close().then(function () {
            input = null;
            processor = null;
            context = null;
            AudioContext = null;
        });
    }
    socket.close()
    socket = null
}

var finalStr = ""
var tmpStr = ""
const translateLangD = [{ "id": "default", "long": "default" }, { "long": "Afrikaans", "id": "af" },
{ "long": "Amharic", "id": "am" }, { "long": "Arabic", "id": "ar" }, { "long": "Azerbaijani", "id": "az" }, {
    "long": "Belarusian", "id": "be"
}, { "long": "Bulgarian", "id": "bg" }, { "long": "Bengali", "id": "bn" },
{ "long": "Bosnian", "id": "bs" }, { "long": "Catalan; Valencian", "id": "ca" }, { "long": "ceb", "id": "ceb" },
{ "long": "Corsican", "id": "co" }, { "long": "Czech", "id": "cs" }, { "long": "Welsh", "id": "cy" },
{ "long": "Danish", "id": "da" }, { "long": "German", "id": "de" }, { "long": "Greek, Modern", "id": "el" },
{ "long": "English", "id": "en" }, { "long": "Esperanto", "id": "eo" }, { "long": "Spanish; Castilian", "id": "es" },
{ "long": "Estonian", "id": "et" }, { "long": "Basque", "id": "eu" }, { "long": "Persian", "id": "fa" },
{ "long": "Finnish", "id": "fi" }, { "long": "French", "id": "fr" }, { "long": "Western Frisian", "id": "fy" },
{ "long": "Irish", "id": "ga" }, { "long": "Scottish Gaelic; Gaelic", "id": "gd" }, { "long": "Galician", "id": "gl" },
{ "long": "Gujarati", "id": "gu" }, { "long": "Hausa", "id": "ha" }, { "long": "haw", "id": "haw" },
{ "long": "Hebrew (modern)", "id": "he" }, { "long": "Hindi", "id": "hi" }, { "long": "hmn", "id": "hmn" },
{ "long": "Croatian", "id": "hr" }, { "long": "Haitian; Haitian Creole", "id": "ht" },
{ "long": "Hungarian", "id": "hu" }, { "long": "Armenian", "id": "hy" }, { "long": "Indonesian", "id": "id" },
{ "long": "Igbo", "id": "ig" }, { "long": "Icelandic", "id": "is" }, { "long": "Italian", "id": "it" },
{ "long": "iw", "id": "iw" }, { "long": "Japanese", "id": "ja" }, { "long": "jw", "id": "jw" },
{ "long": "Georgian", "id": "ka" }, { "long": "Kazakh", "id": "kk" }, { "long": "Khmer", "id": "km" },
{ "long": "Kannada", "id": "kn" }, { "long": "Korean", "id": "ko" }, { "long": "Kurdish", "id": "ku" },
{ "long": "Kirghiz, Kyrgyz", "id": "ky" }, { "long": "Latin", "id": "la" },
{ "long": "Luxembourgish, Letzeburgesch", "id": "lb" }, { "long": "Lao", "id": "lo" },
{ "long": "Lithuanian", "id": "lt" }, { "long": "Latvian", "id": "lv" }, { "long": "Malagasy", "id": "mg" },
{ "long": "Māori", "id": "mi" }, { "long": "Macedonian", "id": "mk" }, { "long": "Malayalam", "id": "ml" },

{ "long": "Mongolian", "id": "mn" }, { "long": "Marathi (Marāṭhī)", "id": "mr" },
{ "long": "Malay", "id": "ms" }, { "long": "Maltese", "id": "mt" }, { "long": "Burmese", "id": "my" },
{ "long": "Nepali", "id": "ne" }, { "long": "Dutch", "id": "nl" }, { "long": "Norwegian", "id": "no" },
{ "long": "Chichewa; Chewa; Nyanja", "id": "ny" }, { "long": "Oriya", "id": "or" },
{ "long": "Panjabi, Punjabi", "id": "pa" }, { "long": "Polish", "id": "pl" },
{ "long": "Pashto, Pushto", "id": "ps" }, { "long": "Portuguese", "id": "pt" },
{ "long": "Romanian, Moldavian, Moldovan", "id": "ro" }, { "long": "Russian", "id": "ru" },
{ "long": "Kinyarwanda", "id": "rw" }, { "long": "Sindhi", "id": "sd" },
{ "long": "Sinhala, Sinhalese", "id": "si" }, { "long": "Slovak", "id": "sk" },
{ "long": "Slovene", "id": "sl" }, { "long": "Samoan", "id": "sm" },
{ "long": "Shona", "id": "sn" }, { "long": "Somali", "id": "so" }, { "long": "Albanian", "id": "sq" },
{ "long": "Serbian", "id": "sr" }, { "long": "Southern Sotho", "id": "st" },
{ "long": "Sundanese", "id": "su" }, { "long": "Swedish", "id": "sv" }, { "long": "Swahili", "id": "sw" },
{ "long": "Tamil", "id": "ta" }, { "long": "Telugu", "id": "te" }, { "long": "Tajik", "id": "tg" },
{ "long": "Thai", "id": "th" }, { "long": "Turkmen", "id": "tk" }, { "long": "Tagalog", "id": "tl" },
{ "long": "Turkish", "id": "tr" }, { "long": "Tatar", "id": "tt" }, { "long": "Uighur, Uyghur", "id": "ug" },
{ "long": "Ukrainian", "id": "uk" }, { "long": "Urdu", "id": "ur" }, { "long": "Uzbek", "id": "uz" }, 
{ "long": "Vietnamese", "id": "vi" }, { "long": "Xhosa", "id": "xh" }, { "long": "Yiddish", "id": "yi" }, 
{ "long": "Yoruba", "id": "yo" }, { "long": "Chinese", "id": "zh" }, { "long": "zh-CN", "id": "zh-CN" }, 
{ "long": "zh-TW", "id": "zh-TW" }, { "long": "zu", "id": "zu" }]
export default function PeerConnection(props) {
    const [lang, setLang] = React.useState("en-US")
    const [translateLang, setTranslateLang] = React.useState("default")

    React.useEffect(() => {

        return () => {
            AudioStreamer.stopRecording()
        }
    }, []);
    React.useEffect(() => {
        if (props.open) {
            finalStr = ""
            tmpStr = ""
            gLang = "en-US"
            gTranslate = "default"
            var l1 = mylocalStorage.getItem("stLang")
            if (l1) {
                gLang = l1
            }
            var l2 = mylocalStorage.getItem("stTranslate")
            if (l2) {
                gTranslate = l2
            }
            setLang(gLang)
            setTranslateLang(gTranslate)
        } else {
            AudioStreamer.stopRecording()
        }

    }, [props])
    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    //https://github.com/lifeCoder123/Speech-to-Text-Converter/blob/master/js/recorder.js
    const empty = ""
    function stopRecordingD() {
        AudioStreamer.stopRecording()
        setMicOn(false)
    }
    function recordVoice(clicked) {
        if (clicked) {
            if (micOn) {
                AudioStreamer.stopRecording()
                setMicOn(!micOn)
                if (props.cb) props.cb(null, null, null)
                return
            }
            if (props.butClick) props.butClick()
            setMicOn(!micOn)
        }
        AudioStreamer.initRecording(data, ()=>{setMicOn(false)})
        async function data(d) {
            if (dataTimer !== null) {
                clearTimeout(dataTimer);
                dataTimer = null;
            }
            dataTimer = setTimeout(function () {
                stopRecordingD()
                clearTimeout(dataTimer);
                dataTimer = null;
            }, 5000);

            var isFinal = false
            if (d.results && d.results[0] && d.results[0].alternatives) {
                var text = ""
                d.results[0].alternatives.forEach((a) => {
                    text = a.transcript
                })
                if (d.results[0].isFinal) {
                    isFinal = true
                    finalStr = text
                    tmpStr = ""
                } else {
                    tmpStr = text
                }
            }
            if (props.cb) {
                var translate = ""
                if (isFinal) {
                    var rr = mylocalStorage.getItem("stTranslate")
                    if (rr && rr !== "default") {
                        var r = await ib.gTranslate(finalStr, rr)
                        if (r) {
                            translate = r
                        }
                    }
                }
                props.cb(finalStr, tmpStr, translate)
                finalStr = ""
            }
        }
    }
    function handleToolChange(c) {
        const selectTool = c.target.value
        if (props.butClick) props.butClick()
        gLang = selectTool
        mylocalStorage.setItem("stLang", gLang)
        setLang(selectTool)
        if (socket) {
            AudioStreamer.stopRecording()
            setTimeout(function () {
                recordVoice(false)
            }, 300)
        }
    }
    function handleTranslate(c) {
        const selectTool = c.target.value
        if (props.butClick) props.butClick()
        setTranslateLang(selectTool)
        gTranslate = selectTool
        mylocalStorage.setItem("stTranslate", gTranslate)
        if (socket) {
            AudioStreamer.stopRecording()
            setTimeout(function () {
                recordVoice(false)
            }, 300)
        }
    }
    function handleClose() {
        stopRecordingD()
        if (props.cb) props.cb(null, null, null)
    }
    const [micOn, setMicOn] = React.useState(false)

    return (
        <div             
        className={"speechTool"}
        style={{
            display: props.open ? "flex" : "none",
            position: "absolute",
            zIndex: 99,
            top: props.top,
            left: props.left
        }}>
            <Button aria-label="Speech to Text" onClick={() => recordVoice(true)}>
                {micOn ? <MicOffIcon style={{ color: "red" }} /> : <SettingsVoiceIcon style={{ color: "green" }} />}
            </Button>
            <div>

                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    aria-label="Select Language"
                    value={lang}
                    onOpen={() => props.butClick()}
                    onChange={handleToolChange}
                >
                    {langList.map((l) => (
                        <MenuItem key={l.id} value={l.id}>{l.name}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>Spoken Language</FormHelperText>
            </div>
            <div>

                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    aria-label="Translate to Language"
                    value={translateLang}
                    onOpen={() => props.butClick()}
                    onChange={handleTranslate}
                >
                    {translateLangD.map((l) => (
                        <MenuItem key={l.id} value={l.id}>{l.long}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>Translated</FormHelperText>
            </div>
            <Button onClick={handleClose}
                size='small'
                variant='contained'
                startIcon={<CancelIcon />}
                aria-label="Close Text box"
                style={{ backgroundColor: props.inkColor, margin:"10px", padding: "0", height: "30px", color: "#ffffff" }} >Close</Button>
        </div >
    )
}