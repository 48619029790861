import React from 'react';
import './MSTabApp.css';
import './styles/WBCMSTeamsStyles.css';
import * as microsoftTeams from "@microsoft/teams-js";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import clsx from 'clsx';
import { whiteboardChatHomeURL } from './App.js';
import { v4 as uuid } from "uuid";

function Tab(props) {
    const [context, setContext] = React.useState(null)
    const [ctxDone, setCtxDone] = React.useState(null)

    React.useEffect(() => {
        try {
        microsoftTeams.getContext((context, error) => {
            setContext(p => context)
            if (error) {
                setContext({})
            } else {
                console.log("Whiteboard.chat - could not get teams context.")
            }
            });
        } catch { }
        setTimeout(() => { setCtxDone(true) }, 8000)
    }, [])

    React.useEffect(() => {
        if (context !== null) {
            setCtxDone(true)
        }
    }, [context]);

    React.useEffect(() => {
        if (ctxDone && !context) {
            props.history.push("/")
        }
    })

    function signIn(e) {
        microsoftTeams.settings.setSettings({
            "contentUrl": whiteboardChatHomeURL+"/manage",
            "suggestedDisplayName": "Whiteboard.chat"
        });
    }

    function signUp(e) {
        signIn(e)
    }

    return (
        <div className={clsx("MSTeamsSignInBg", (context && context.theme === "dark" ? "bgThemeDark" : "bgThemeLight"))}>
        <div className={clsx("MSTeamsSignInPanel", (context && context.theme === "dark" ? "bgThemeDark" : "bgThemeLight"))}>
          {/* { context ? <iframe title="Whiteboard.chat" width="1280" height="720" src={whiteboardChatHomeURL} frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope" allowfullscreen></iframe> :
            <div style={{ marginTop: "40vh" }}><Spinner size={SpinnerSize.large} /></div> } */}
          {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: "5vh" }}>{(ctxDone && !context) ? "This is app is to be used only inside Microsoft Teams. Thank You!" : null}</div> */}
          {/* <div>{JSON.stringify(context)}</div> */}
          { context ? <>
            <object type="image/svg+xml" data="wbc_blue_logo.svg"></object>
            <div className={clsx("PlatformDescriptionTitleText", (context && context.theme === "dark" ? "bgThemeDark" : "bgThemeLight"))} style={{ marginTop: '32px' }}>
                Student Engagement and Observation Platform
            </div>
            <div className={clsx("PlatformDescriptionText", (context && context.theme === "dark" ? "bgThemeDark" : "bgThemeLight"))}>
                Connect multiple people and devices to the same board. Draw with your
                finger, mouse, pencil.
            </div>
            <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'center', flexDirection: 'row', width: '100%'}}>
            <button type="button" className="SignInButton" onClick={signIn}>Sign In</button>
            <button type="button" className="SignInButton" onClick={signUp}>Sign Up</button>
            </div>
            </> : null
          }
          { (!ctxDone && !context) ? <>
            <div style={{ position: 'absolute', top: '45vh' }}><Spinner size={SpinnerSize.large} /></div>
            </> : null
          }
        </div>
        </div>
    );
}

export default Tab;
