import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Draggable from 'react-draggable';
import * as ib from './ibdata'
import {
    TextField,
    IconButton
} from '@material-ui/core';
import ResizeObserver from 'resize-observer-polyfill';
import clsx from "clsx";
import { svgIcons } from './svgIcons';

const useStyles = makeStyles((theme) => ({
    draggableParent: {
        position: "absolute",
        top: '0',
        left: '80px',
        width: "calc(100%)",
        height: "calc(100%)",
    },
    draggableWindow: {
        position: "absolute",
        zIndex: "99",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        resize: "both",
        overflow: "auto",
        backgroundColor: "#FAFAFA55",
        backdropFilter: "blur(5px)",
        borderRadius: "5px"
    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "25px",
        justifyContent: "flex-end",
        cursor: "grab",
        color: "white"
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowHeader: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        // padding: "10px 5px 5px"
    },
    windowURLInput: {
        display: "flex",
        flexGrow: "1"
    },
    windowButton: {
        display: "flex",
        padding: '0',
        color: "white",
        "&:hover": {
            color: "white"
        }
    },
    windowIframeOutput: {
        position: 'absolute',
        display: "flex",
        backgroundColor: "#DCDCDC",
        padding: "0px",
        width: "100%",
        height: "inherit"
    },
    windowIframeOverlay: {
        position: "absolute",
        display: "flex",
        backgroundColor: "transparent",
        width: "100%",
        height: "calc(100% - 20px)",
        marginBottom: "-20px"
    },
    verticalEdge: {
        display: "flex",
        width: "2px",
        height: "100%",
        cursor: "n-resize",
    },
    horizontalEdge: {
        display: "flex",
        width: "100%",
        height: "2px",
    }
}));

export default function ExternalWebpage(props) {
    const classes = useStyles();
    const [draggableParentSize, setDraggableParentSize] = React.useState({ width: '100%', height: '100%' });
    const [windowResolution, setWindowResolution] = React.useState({ width: "600px", height: "400px" });
    const minWindowResolution = { width: "400px", height: "400px" };
    const [windowPosition, setWindowPosition] = React.useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = React.useState(false);
    const [iframeUrl, setIframeUrl] = React.useState("");
    const [currentUrl, setCurrentUrl] = React.useState("");
    const isTeacher = useSelector((state) => state.teacher);
    const [videoPlayer, setVideoPlayer] = React.useState(null);
    const [videoPlayerCreated, setVideoPlayerCreated] = React.useState(false);
    const [showVideoPlayer, setShowVideoPlayer] = React.useState(true);
    const [extWebpage, setExtWebpage] = React.useState(null);
    const [currentExtWebpageId, setCurrentExtWebpageId] = React.useState(null);

    const [windowRO, setWindowRO] = React.useState(null);
    const windowRef = React.createRef(null)
    var windowResizeUpdate = null;
    const [observerCreated, setObserverCreated] = React.useState(false);

    React.useEffect(() => {
        if (props.extWebpage) {
            const data = JSON.parse(props.extWebpage.content)
            setWindowPosition(data.position)
            if (data.windowType && data.windowType === "website") setShowVideoPlayer(false);
            if (data.width && data.height) setWindowResolution({ width: data.width, height: data.height })
        }
    }, [])

    React.useEffect(() => {
        if (props.extWebpage) {
            setExtWebpage(props.extWebpage);
            setCurrentExtWebpageId(props.extWebpage.id);
            const data = JSON.parse(props.extWebpage.content)
            // Uncomment if we want to sync window positions
            setWindowPosition(data.position);
            if (data.width && data.height && 
                (data.width !== windowResolution.width ||
                data.height !== windowResolution.height)) {
                setWindowResolution({ width: data.width, height: data.height });
            }
            setIframeUrl(data.iframeUrl);
            setCurrentUrl(data.iframeUrl);
            if (data.sTime) {
                setSTime(data.sTime)
            }
            if (currentExtWebpageId && props.extWebpage.id !== currentExtWebpageId) {
                setWindowPosition(data.position)
            }
        } else {
            setExtWebpage(null);
        }

        if (props.canvasData && props.canvasData.drawing) {
            setDraggableParentSize({
                width: props.canvasData.drawing.scrollWidth,
                height: props.canvasData.drawing.scrollHeight
            })
        }
    }, [props])

    React.useEffect(() => {
        if (extWebpage && extWebpage.id) {
            setWindowRO(new ResizeObserver(entries => {
                for (let entry of entries) {
                    const cr = entry.contentRect;
                    if (windowResizeUpdate) clearTimeout(windowResizeUpdate);
                    windowResizeUpdate = setTimeout(() => {
                        var resizeResolution = { width: cr.width + 10, height: cr.height + 10 };
                        if (resizeResolution.width < minWindowResolution.width) resizeResolution.width = minWindowResolution.width;
                        if (resizeResolution.height < minWindowResolution.height) resizeResolution.height = minWindowResolution.height;
                        // console.log(`Element size: ${cr.width}px x ${cr.height}px`);
                        setWindowResolution(resizeResolution)
                    }, 500)
                }
            }));
            if (windowRO) {
                if (!observerCreated) {
                    setObserverCreated(true);
                    windowRO.observe(document.getElementById(`window-${extWebpage.id}`))
                }
            }
        }
    }, [extWebpage])

    React.useEffect(() => {
        if (extWebpage && extWebpage.content) {
            const content = JSON.stringify({
                ...JSON.parse(extWebpage.content),
                width: windowResolution.width,
                height: windowResolution.height
            })
            ib.updateObject({
                id: extWebpage.id,
                content: content
            })
        }
    }, [windowResolution])

    React.useEffect(() => {
        if (!window.YT) {
            // Setup YouTube Iframe API
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';

            window.onYouTubeIframeAPIReady = loadVideo;

            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }

        if (iframeUrl) {
            if (showVideoPlayer) {
                const youtubeVideoId = getYouTubeVideoId(iframeUrl);
                if (window.YT && window.YT.Player) {
                    if (!videoPlayerCreated && youtubeVideoId) {
                        loadVideo();
                    } else if (youtubeVideoId && videoPlayer && videoPlayer.loadVideoById) {
                        var videoStartTime = 0;
                        videoPlayer.loadVideoById(youtubeVideoId, videoStartTime);
                    } 
                }
            }
            setCurrentUrl(iframeUrl);
        }
    }, [iframeUrl])

    function getYouTubeVideoId(videoURL) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = videoURL.match(regExp);
        if (match && match[2].length > 0) {
            return match[2];
        } else {
            return "";
        }
    }

    function loadVideo() {
        // need to change this to the youtube video id
        if (iframeUrl && iframeUrl.length > 0) {
            var videoStartTime = sTime && sTime.startTime ? sTime.startTime : 0;
            var videoEndtime = sTime && sTime.endTime ? sTime.endTime : 0;
            var youtubeVideoId = getYouTubeVideoId(iframeUrl);
            if (!youtubeVideoId) return;
            setVideoPlayerCreated(true);
            var pv = {
                'start': videoStartTime,
                'autoplay': 0,
                'controls': 1,
                'rel': 0
            }
            if (videoEndtime !== 0) pv['end'] = videoEndtime
            setVideoPlayer(new window.YT.Player(`videoPlayer-${extWebpage.id}`, {
                width: windowResolution.width,
                height: windowResolution.height,
                videoId: youtubeVideoId,
                playerVars: pv,
                events: {
                    onReady: onPlayerReady,
                    // onStateChange: onPlayerStateChange
                }
            }));
        }
    }

    function onPlayerReady(event) {
        //  event.target.playVideo();
    }

    function handleDrag(e, ui) {
        if ((ui.y < 0|| ui.y > (window.innerHeight - 160)) || (ui.x < 0 || ui.x > window.innerWidth - 460)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }

    function handleDragStart() {
        setIsDragging(true);
    }

    function handleDragEnd(e, ui) {
        setIsDragging(false);
        if ((ui.y < 0 || ui.y > (window.innerHeight - 160)) || (ui.x < 0 || ui.x > window.innerWidth - 460)) {
            return
        }
        if (extWebpage) {
            const content = JSON.stringify({
                ...JSON.parse(extWebpage.content),
                position: { x: ui.lastX, y: ui.lastY }
            })
            ib.updateObject({
                id: extWebpage.id,
                content: content
            })
        }
    }

    function pasteText(event) {
        event.clipboardData.items[0].getAsString((data) => {
            setCurrentUrl(currentUrl + data);
        });
    }

    function handleURLChange(event) {
        var url = event.target.value
        setCurrentUrl(p => url);
    }
    const [sTime, setSTime] = React.useState({ startTime: null, endTime: null })

    var debounce = null
    function setDebounce(col) {
        if (debounce !== null) {
            clearTimeout(debounce);
            debounce = null;
        }
        debounce = setTimeout(function () {
            clearTimeout(debounce);
            debounce = null;
            setTimeUpdate(col)
        }, 300);
    }
    function setTimeUpdate(newTime) {
        if (extWebpage) {
            const content = JSON.stringify({
                ...JSON.parse(extWebpage.content),
                sTime: newTime,
                iframeUrl: currentUrl,
            })
            ib.updateObject({
                id: extWebpage.id,
                content: content
            })
        }
    }
    function handleTimeChange(event) {
        var newTime = {
            ...sTime,
            [event.target.name]: event.target.value
        }
        setSTime(p => newTime);
    }

    function updateIframeURL() {
        setIframeUrl(currentUrl);
        const content = JSON.stringify({
            ...JSON.parse(extWebpage.content),
            iframeUrl: currentUrl,
            sTime: sTime,
            width: windowResolution.width,
            height: windowResolution.height
        })
        ib.updateObject({
            id: extWebpage.id,
            content: content
        })
        if (!videoPlayerCreated) {
            loadVideo();
        }
    }

    function handleClose() {
        if (extWebpage.id) {
            ib.delObject(extWebpage.id)
        }
        setExtWebpage(null);
    }

    // Sync all student videos to the same time
    // function syncTime() {
    //     if (videoPlayer) {
    //         videoPlayer.pauseVideo();
    //         updateExtWebpage({
    //             data: {
    //                 currentTime: videoPlayer.getCurrentTime(),
    //                 playerState: videoPlayer.playerState()
    //             }
    //         })
    //     }
    // }

    return (
        <div className={classes.draggableParent}
            style={{
                width: draggableParentSize.width,
                height: draggableParentSize.height
            }}
        >
            {extWebpage && (
                <Draggable
                    handle="strong"
                    bounds="parent"
                    onDrag={handleDrag}
                    onStart={handleDragStart}
                    onStop={handleDragEnd}
                    position={windowPosition}
                    disabled={isTeacher !== 1}
                >
                    <div
                        id={`window-${extWebpage.id}`}
                        className={classes.draggableWindow}
                        ref={windowRef}
                        style={{
                            width: windowResolution.width,
                            height: windowResolution.height,
                            minWidth: minWindowResolution.width,
                            minHeight: minWindowResolution.height,
                            border: `5px solid transparent`,
                            resize: isTeacher === 1 ? 'both' : 'none',
                            overflow: isTeacher === 1 ? 'auto' : 'none',
                        }}>
                        <div 
                            className={classes.draggableTop} 
                            style={{ 
                                width: "100%",
                                height: '10px',
                                backgroundColor: '#E2E6E9',
                                cursor: isTeacher === 1 ? (isDragging ? "grabbing" : "grab") : "auto"
                            }}
                        >
                            <strong className={clsx(classes.draggableTopHandle,'justify-center')}>
                                <svg width="30" height="10" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: "0px"}}>
                                    {svgIcons.dots}
                                </svg>
                            </strong>
                        </div>
                        <div
                            id="windowHeader"
                            className={classes.windowHeader}
                            style={{ display: isTeacher === 1 ? 'flex' : 'none' }}>
                            <Grid container spacing={1}>
                                <Grid item xs={showVideoPlayer ? 6 : 12} className='flex'>
                                    <img src='/globIcon.svg' alt='globIcon' className='gIcon'/>
                                    <TextField
                                        className={clsx(classes.windowURLInput, 'inpUtube')}
                                        value={currentUrl}
                                        placeholder={showVideoPlayer ? "Enter a YouTube URL" : "Enter a website URL"}
                                        onPaste={pasteText}
                                        onChange={handleURLChange}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                updateIframeURL(e)
                                            }
                                        }}
                                    />
                                </Grid>
                                { showVideoPlayer && (
                                <>
                                <Grid item xs={3}>
                                    <TextField
                                        className={classes.windowURLInput}
                                        value={sTime.startTime}
                                        placeholder={"Start Time(sec)"}
                                        name="startTime"
                                        onChange={handleTimeChange}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                handleTimeChange(e)
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        className={clsx(classes.windowURLInput, 'inpUtube')}
                                        value={sTime.endTime}
                                        placeholder={"End Time(sec)"}
                                        name="endTime"
                                        onChange={handleTimeChange}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                handleTimeChange(e)
                                            }
                                        }}
                                    />
                                </Grid>
                                </>
                                )}
                            </Grid>
                            <Tooltip title={ showVideoPlayer ? "Load Video" : "Load Webpage"} arrow placement="top">
                                <IconButton
                                    className={classes.windowButton}
                                    style={{
                                        color: props.inkColor,
                                        "&:hover": {
                                            color: props.inkColor
                                        }
                                    }}
                                    onClick={updateIframeURL}>
                                    <img src='/loadIcon.svg' alt='Load Icon' className='gIcon'/>
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                className={classes.windowButton}
                                style={{
                                    display: isTeacher === 1 ? 'flex' : 'none',
                                    color: props.inkColor,
                                }}
                                onClick={handleClose}>
                                    <img src='/closeIcon.svg' alt='Close Icon' className='gIcon'/>
                            </IconButton>
                            {/* <Tooltip title="Sync Video" arrow placement="top">
                            <Button
                                className={classes.windowButton}
                                onClick={syncTime}>
                                <SyncIcon/>
                            </Button>
                        </Tooltip> */}
                        </div>
                        <div style={{display: 'flex', position: 'relative', width: '100%', height: '100%'}}>
                            { showVideoPlayer ? (
                                <div id={`videoPlayer-${extWebpage.id}`} className={classes.windowIframeOutput}/>
                            ) : (
                                <iframe 
                                    className={classes.windowIframeOutput}
                                    src={iframeUrl} 
                                    width={windowResolution.width} 
                                    height={windowResolution.height}
                                    allow="camera;microphone"
                                />
                            )}
                        </div>
                    </div>
                </Draggable>
            )}
        </div>
    );
}