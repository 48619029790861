import React from 'react'
import { whiteboardChatHomeURL } from './App.js'

function SessionIframe(props) {
    const [boardId, setBoardId] = React.useState(null)
  
    React.useEffect(() => {
      if ('match' in props && 'params' in props.match && 'boardid' in props.match.params) {
        var iduse = props.match.params.boardid
        setBoardId(iduse)
      }
    }, [props, props.match])
  
    return (<>
      {/* <h1>Went to sess {boardId}</h1> */}
      { boardId ?
      <iframe title="Whiteboard.chat" width="1280" height="720" src={whiteboardChatHomeURL+"/createjoin/"+boardId} frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope" allowfullscreen></iframe>
        : null }
    </>)
  }
  
  export default SessionIframe;