import React, { Suspense, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import {
    TextField, InputAdornment, Dialog, Tooltip, Hidden, IconButton, Grid,
    Menu, MenuItem, ListItemIcon, Popover, Select, FormHelperText, Button, Icon
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Avatars from "./Avatars"
import LongMenu from './LongMenu'
import WbcJrLongMenu from './WbcJrLongMenu'
import AuthDialog from "./AuthDialog"
import TipsDialog from './TipsDialog'
import HelpIcon from '@material-ui/icons/Help';
// import { ReactComponent as ShareIcon } from './share.svg';
import { QRCode } from "react-qr-svg";
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import ReactGA from 'react-ga';
import './styles/style.css'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as ib from "./ibdata"
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ParticipantDrawer from "./ParticipantDrawer";
import EventPopper from "./EventPopper";
import HandRaise from './HandRaise';
import ClassTimer from "./ClassTimer";
import PaletteIcon from '@material-ui/icons/Palette';
import ColorPicker from './ColorPicker';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import Popper from '@material-ui/core/Popper';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import DrawerWalker from './DrawerWalker';
import TranslateIcon from '@material-ui/icons/Translate';
import { isoLangs } from "./LangName"
import LetItSnow from './LetItSnow'
import { LocalFlorist } from '@material-ui/icons';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import BackColorPicker from "./BackColorPicker"
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import LockIcon from '@material-ui/icons/Lock';
import FaceIcon from '@material-ui/icons/Face';
import GoogleShareToClassRoom from 'google-classroom-share';
import LinkIcon from '@material-ui/icons/Link';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PollIcon from '@material-ui/icons/Poll';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import * as mylocalStorage from "./mylocalStorage";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import YesNoDialog from "./YesNoDialog"
import * as en from "./encDec";
import * as bUtils from './BoardUtility'
import PageFooter from "./PageFooter"
import MultiboardNav from "./MultiBoardNav"
import VideocamIcon from '@material-ui/icons/Videocam';
import CallIcon from '@material-ui/icons/Call';
import { svgIcons } from './svgIcons';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CorrectIcon from "./correctIcon.svg";
import CloseIcon from '@material-ui/icons/Close';
import Draggable from 'react-draggable';
import AutoCorrectModal from './AutoCorrectModal';
const Palette = React.lazy(() => import('./Palette'));

const tabIcons = {
    'whiteboard': <CallIcon style={{ fontSize: 24 }} />,
    'conference': <VideocamIcon style={{ fontSize: 24 }} />
}
const drawerWidth = 250;
const styleVariables = {
    'colorPrimary': "#f2530b",
    'colorOthers': "#3da3db",
    'bgColor': "#ffffff",
    'toolColor': "#E5E5E5"
}
const TabStyle = withStyles((theme) => ({
    root: {
        color: '#ffffff',
        padding: '0px',
        fontSize: '0.2rem',
        margin: '5px',
        width: "40px",
        height: "50px",
        borderRadius: "5px",

        '&:hover': {
            background: "#ffffff",
        },
        '&$selected': {
            color: '#612AFF',
            fill: '#612AFF',
        },
    },
    selected: {}

}))((props) => <Tab {...props} />)
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        // zIndex: theme.zIndex.drawer + 1,
        background: 'transparent',
        boxShadow: 'none',
        color: '#72869F',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    typographyCenter: {
        flexGrow: 1,
        align: "center"
    },
    menuButton: {
        background: "#3174F5",
        color: "#ffffff",
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        height: 'auto',
        // backgroundColor: "#3174F5",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        top: "8vh",
        height: "auto",
        // background: "#3174F5",
        borderRadius: "0px",
        overflowX: 'hidden',
        width: theme.spacing(6) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6) + 1,
        },
    },
    drawerCloseTop: {
        top: "8vh",
        margin: '0px 0px 0px 10px',
        width: '60px',
        height: 'auto',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerCloseTopMSTeams: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpenTop: {
        margin: '0px 0px 0px 10px',
        width: drawerWidth,
        borderRadius: '0px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerOpenTopMSTeams: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 0),
        // necessary for content to be below app bar
        // ...theme.mixins.toolbar,
    },
    iconButton: {
        color: '#ffffff',
    },
    iconButtonHelp: {
        color: '#3174F5',
        marginRight: "15px",
    },
    inviteButton: {
        backgroundColor: "#3174F5",
        fontSize: "0.5rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: '5px',
        marginRight: "5px",
        padding: "3px",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    rightSide: {
        marginLeft: 'auto',
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    inviteBar: {
        backgroundColor: '#D9FFA2',
        justifyContent: 'space-between !important'
    },
    ml4: {
        marginLeft: '4px'
    },
    paper: {
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        maxHeight: "56vh",
        width: "345px",
        overflow: "auto",
        zIndex: "105",
        boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
    },
    tooltip: {
        margin: "4px 0",
    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
    customArrow: {
        color: "#1E2639",
    },
    dialog: {
        width: "250px",
        height: "65px",
        backgroundColor: "#FFFFFF",
    },
    dialogText: {
        textAlign: "center",
    },
    okButton: {
        backgroundColor: "#3174F5",
        margin: "0px 20px",
        height: '38px',
        width: "80px",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'flex',
        justifyContent: "space-around",
        borderRadius: '999px',
        border: "1px solid #C8CEDA",
        color: "#ffffff",
        "&:hover": {
            color: '#2F3D5B !important',
        },
    },
    appRelative: {
        left: '0px',
        background: 'white',
        padding: '0 10px 0 65px',
        boxShadow: '0px 0px 8px 2px rgba(13, 20, 22, 0.02)'
    },
    drawerNew: {
        width: '50px',
        top: '0',
        margin: '0',
        borderRadius: '0',
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        overflow: 'hidden'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // background: "#3174F5",
        borderRadius: "0px",
        overflowX: 'hidden',
        width: theme.spacing(6) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(6) + 1,
        },
    },
    marginy10: {
        margin: '0px 0px 0px 10px'
    },
    dots: {
        color: "#7D8FB6",
        position: "relative",
        top: "-5px",
        "&:hover": {
            color: "#3174F5",
            cursor: "move",
        },
    },
    headerDots: {
        width: "100%",
        height: "7px",
        display: "flex",
        justifyContent: "space-around",
        backgroundColor: "#E2E6E9",
    },
    replication: {
        fontSize: "16px",
        fontFamily: 'lato',
        fontWeight: 'normal',
        color: "#2F3D5B",
        marginRight: "10px",
    },
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        position: "absolute",
        height: "fit-content",
        zIndex: 105,
        color: "#ffffff",
        padding: '8px 10px 2px',
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF'

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        color: "#ffffff",
    },
}));

function MiniDrawer(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [openInvite, setInvite] = React.useState(null)
    const boardType = useSelector((state) => state.boardType);
    const background = useSelector((state) => state.background);
    const boardConfig = useSelector((state) => state.BoardConfig);
    const boardTools = useSelector((state) => state.boardTools);
    const tab = useSelector((state) => state.tab);
    const [tabValue, setTabValue] = React.useState(0);
    const dispatch = useDispatch();
    const [creator, setCreator] = React.useState(false)
    const teacherR = useSelector((state) => state.teacher);
    const drawerWalker = useSelector((state) => state.drawerWalker);
    const showCase = useSelector((state) => state.showCase);
    const clientLoc = useSelector((state) => state.clientLoc)

    const syncDisabled = useSelector((state) => state.syncDisabled);
    const tpopperRef = React.useRef(null)
    const popperRef = React.useRef(null)
    const [boardName, setBoardName] = React.useState("");
    const [allowBoardRename, setAllowBoardRename] = React.useState(false);
    const palletDrawer = useSelector((state) => state.palletDrawer);
    const [joinCode, setJoinCode] = React.useState("Unavailable");
    const [letItSnow, setLetItSnow] = React.useState(false);
    const letItSnowEvt = useSelector((state) => state.letItSnowEvt);
    const [openAutoCorrect, setOpenAutoCorrect] = React.useState(false);
    const authUser = useSelector((state) => state.user);
    const importFile = React.useRef(null)
    const [statusMsg, setStatusMsg] = React.useState("")
    const xpos = window.innerWidth - 420
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: 140 });
    const [openBoardName, setOpenBoardName] = React.useState(true)
    const [openSimpleTools, setOpenSimpleTools] = React.useState(true)

    const NOVIDEO = "wb.whiteboard.chat"
    const boardColor = props.inkColor;
    const teacherStyle = {
        color: "#3174F5",
        padding: "2px 10px",
        margin: "2px",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        background: "linear-gradient(180deg, #FFFFFF 0%, #F4F5F6 100%)",
        border: "1px solid #C8CEDA",
        borderRadius: "999px",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
    };
    var videoEnabled = true
    const [userRole, setUserRole] = React.useState(null)

    React.useEffect(() => {
        if (props.userRole) {
            setUserRole(props.userRole)
        } else {
            setUserRole('student')
        }
    }, [props.userRole])

    if (window.location.hostname === NOVIDEO) videoEnabled = false
    if (window.insideMSTeams === true) videoEnabled = false
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        dispatch(Actions.setTab({
            ...tab,
            selected: newValue
        }))
    };
    React.useEffect(() => {
        var lu = mylocalStorage.getItem("palette")
        if (lu) {
            dispatch(Actions.setPalette(JSON.parse(lu)))
        }
        if (props.sess) {
            var jj = JSON.parse(props.sess.boardConfig)
            if (jj && jj['Video'] && tab.allTabs.length === 0) {
                addVideoCall(jj['Video'])
                ReactGA.event({
                    category: 'Video',
                    action: "CallJoined"
                });
            }
            if (jj && jj['Video'] === false && tab.allTabs.length !== 0) {
                //close video 
                dispatch(Actions.setTab({
                    selected: 0,
                    allTabs: []
                }))
            }

            var luid = mylocalStorage.getItem('mystoreID');
            if (luid && props.sess.CreatorLocalID === luid && teacherR) {
                setCreator(true)
            }

            if (boardName !== props.sess.name) {
                let bname = props.sess.name;
                if (props.sess.name === "unsaved" || props.sess.name.match(/^unsaved\(([\d]+)\)$/)) {
                    bname = "";
                }
                setBoardName(p => bname);
            }

            setJoinCode(props.sess.joinCode);
        } else {
            dispatch(Actions.setTab({
                selected: 0,
                allTabs: []
            }))
        }
    }, [props.sess, props.gridView, teacherR])

    function disableSync() {
        handleClose()
        if (syncDisabled) {
            mylocalStorage.removeItem("syncDisabled")
        } else {
            mylocalStorage.setItem("syncDisabled", true)
        }
        dispatch(Actions.setSyncDisabled(!syncDisabled))
    }
    // Determines whether a board can be renamed
    // group boards cannot be renamed
    // owners of boards can rename boards
    // teachers can rename their and student boards
    React.useEffect(() => {
        if (!props.sess) {
            setAllowBoardRename(p => false);
            return;
        }
        if (props.sess.isGroup) {
            setAllowBoardRename(p => false);
            return;
        }
        var localID = "";
        if (props.user) {
            localID = JSON.parse(props.user.content).localID;
        }
        if (props.sess.CreatorLocalID === localID) {
            setAllowBoardRename(p => true);
            return;
        }
        setAllowBoardRename(p => Boolean(teacherR));
    }, [props.sess, props.user, teacherR])

    React.useEffect(() => {
        if (!teacherR && letItSnowEvt) {
            setLetItSnow(p => true)
            setTimeout(() => { setLetItSnow(p => false) }, 10000);
        }
    }, [letItSnowEvt])

    const [COJOINURL, setURL] = React.useState("")
    React.useEffect(() => {
        var jj = window.location.href.split("/board/")
        var url = jj[0] + "/cojoin/" + window.btoa(jj[1])
        setURL(url)
    }, []);

    function addVideoCall(server) {
        var tabA = []
        mylocalStorage.setItem("videoserver", server)
        dispatch(Actions.setBoardConfig({
            ...boardConfig,
            Video: server,
        }))
        tabA.push({ name: 'Whiteboard', type: 'whiteboard' })
        tabA.push({ name: 'Conference', type: 'conference' })
        dispatch(Actions.setTab({
            ...tab,
            selected: 0,
            allTabs: [...tabA]
        }))
        setTabValue(0);
    }

    function endVideoCall() {
        var tabA = []
        dispatch(Actions.setBoardConfig({
            ...boardConfig,
            Video: false,
        }))
        dispatch(Actions.setTab({
            ...tab,
            selected: 0,
            allTabs: [...tabA]
        }))
        setTabValue(0);
    }

    function getVideoCallLoc(cb) {

        function checkLock(loc) {
            var domain = 'meet2.whiteboard.chat';
            if (loc && loc.longitude) {
                if (loc.longitude > -100 && loc.longitude < 45) {
                    domain = 'meet3.whiteboard.chat'
                }
            }
            cb(domain)
        }
        if (clientLoc) {
            checkLock(clientLoc)
        } else {
            fetch(`https://geolocation-db.com/json/`)
                .then(response => response.json())
                .then(data => {
                    try {
                        if (data) {
                            dispatch(Actions.setClientLoc(data))
                            checkLock(data)
                        } else {
                            checkLock(null)
                        }
                    } catch (e) {
                        checkLock(null)
                    }
                })
                .catch(e => {
                    checkLock(null)
                })
        }
    }
    function handleVideoCall() {
        handleClose()
        getVideoCallLoc(done)
        function done(server) {
            ReactGA.event({
                category: 'Video',
                action: "CallStart"
            });
            addVideoCall(server)
            ib.VideoConferenceSet(props.sess, server)
        }
    }
    function handleVideoClose() {
        ReactGA.event({
            category: 'Video',
            action: "CallEnd"
        });
        endVideoCall()
        ib.VideoConferenceSet(props.sess, false)
        handleClose()
    }
    const handleDrawerOpen = () => {
        setOpen(true);
        if (props.drawerCB) {
            props.drawerCB(true);
        }
    };

    const handleDrawerClose = () => {
        setOpen(false);
        if (props.drawerCB) {
            props.drawerCB(false);
        }
    };
    const handleClick = (event) => {
        ReactGA.event({
            category: 'Invite',
            action: "OpenInvite"
        });
        setInvite(event.currentTarget);
    };
    const letItSnowHandler = (event) => {
        ReactGA.event({
            category: 'User',
            action: 'letItSnow'
        })
        setLetItSnow(p => true)
        var event = props.gSession.Classroom + "-" + props.gSession.parentID
        var data = {
            action: "letItSnow",
        }
        var cmd = {
            event: event, Classroom: props.gSession.Classroom, ttl: props.gSession.ttl,
            type: "LetItSnow", State: "Start"
        }
        cmd['For'] = props.gSession.id
        ib.createClassroomEvent(cmd).then((r) => {
            const pp = r.data.createClassroomEvent
        })
        setTimeout(() => {
            setLetItSnow(p => false)
        }, 10000)
    }
    function InvitePopper() {
        const open = Boolean(openInvite);
        const id = open ? 'simple-popover' : undefined;
        const JOINURL = window.location.href.replace("/board", "/join")
        const URL = "mailto:?subject=Please join this whiteboard.chat board&body=Please click on the link to join my whiteboard.chat %0D%0A%0D%0A" + JOINURL
        const textAreaRef = React.useRef(null);
        const coRef = React.useRef(null);
        const joinCodeTextAreaRef = React.useRef(null);
        const [ref] = useHookWithRefCallback();
        const [showTeaCopy, setShowTeaCopy] = useState(false)

        function useHookWithRefCallback() {
            const ref = React.useRef(null)
            const setRef = React.useCallback(node => {
                if (ref.current) {
                    // Make sure to cleanup any events/references added to the last instance
                }

                if (node) {
                    // Check if a node is actually passed. Otherwise node would be null.
                    // You can now do what you need to, addEventListeners, measure, etc.
                    try {
                        window.shareToMicrosoftTeams.renderButtons()
                    } catch {

                    }
                }

                // Save a reference to the node
                ref.current = node
            }, [])

            return [setRef]
        }

        function handlecopyCode(e) {
            if (!joinCodeTextAreaRef || !joinCodeTextAreaRef.current) return
            joinCodeTextAreaRef.current.select();
            document.execCommand('copy');
            e.target.focus();
            props.cb['Message']("Classroom Code copied to clipboard, please paste it and share")
        }

        function handlecopy(e) {
            if (!textAreaRef || !textAreaRef.current) return
            textAreaRef.current.select();
            document.execCommand('copy');
            e.target.focus();
            props.cb['Message']("Invite copied to clipboard, please paste it and share")
        }
        function handlecopyCoJoin(e) {
            if (!coRef || !coRef.current) return
            coRef.current.select();
            document.execCommand('copy');
            e.target.focus();
            props.cb['Message']("Invite copied to clipboard, please paste it and share")
        }

        function handlecopyCoJoin(e) {
            if (!coRef || !coRef.current) return
            coRef.current.select();
            document.execCommand('copy');
            e.target.focus();
            props.cb['Message']("Invite copied to clipboard, please paste it and share")
        }

        return (
            <Dialog
                id={id}
                open={openInvite}
                maxWidth="sm"
                anchorel={openInvite}
                onClose={() => setInvite(null)}
                className='shareDialog newDesignsd'
            >
                <div className='dispFlexEnd'>
                    <CloseIcon className='ndCloseIcon' style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%", position: "absolute" }} onClick={() => {
                        setInvite(null)
                    }} />
                </div>
                <div className='flexCenter'>
                    <Typography className='invtContent'>
                        Invite others to this board
                    </Typography>
                </div>
                <>
                    <div className='flexCenter'>
                        <QRCode
                            fgColor="#003D7F"
                            bgColor={styleVariables.toolColor}
                            level="Q"
                            className="qrcode"
                            value={JOINURL}
                        />
                    </div>
                    <Typography className='scanContent lato12px'>Scan QR code with phone camera</Typography>
                    <Hidden smUp>
                        <div className='sharebyUrlBox'>
                            <div className='shareSection2'>
                                <div className='flexCenter'>
                                    <img src='/Divider.svg' alt='divider' />
                                    <Typography className='joinContent'>
                                        Or copy invite
                                    </Typography>
                                    <img src='/Divider.svg' alt='divider' />
                                </div>
                                <div className='sharebyUrlBigBox flex-a-c'>
                                    <TextField
                                        variant='outlined'
                                        inputRef={textAreaRef}
                                        className='joinInp'
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment>
                                                    <IconButton className="inviteICon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            {svgIcons.student}
                                                        </svg>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}

                                        value={JOINURL}
                                    />
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handlecopy}
                                        className='urlCopyBtn'
                                        style={{ background: "#3174F5" }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            {svgIcons.copying}
                                        </svg>
                                    </IconButton>

                                </div>
                                <div className='sharebyUrlBigBox flex-a-c'>

                                    <TextField
                                        variant='outlined'
                                        inputRef={joinCodeTextAreaRef}
                                        className='joinInp codeMono'
                                        InputProps={{
                                            readOnly: true,
                                        }}

                                        value={joinCode}
                                    />
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title='Copy Classroom Code'>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handlecopyCode}
                                            className='urlCopyBtn'
                                            style={{ background: "#3174F5" }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                {svgIcons.copying}
                                            </svg>
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                <div className="qrtext inMobLink" style={{ marginTop: '2px', marginBottom: '2px' }}>
                                    <a href={URL} className='click2Email'>
                                        Email invite link</a>
                                </div>
                                <div className="shareBtns">
                                    {props && props.sess && (
                                        <>
                                            <div className='gClassRoom'>
                                                <GoogleShareToClassRoom
                                                    body={"whiteboard.chat " + props.sess.name}
                                                    // itemType="assignment"
                                                    url={JOINURL}
                                                    size={25}
                                                    title={"whiteboard.chat " + props.sess.name}
                                                />
                                                <Tooltip classes={{
                                                    tooltip: classes.customTooltip,
                                                    arrow: classes.customArrow
                                                }} title='Share in Google classroom'>
                                                    <div style={{ cursor: 'default' }} className='shrBtnlbl'>
                                                        Classroom
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div className='gClassRoom'>
                                                <div ref={ref}
                                                    id="teams-share-button-div"
                                                    className="teams-share-button"
                                                    data-href={JOINURL}
                                                    data-icon-px-size="30"
                                                    data-preview="false"
                                                    data-msg-text={"Whiteboard.chat " + props.sess.name}
                                                    data-assign-title={"Whiteboard.chat " + props.sess.name}
                                                    data-assign-instr={"Please join the whiteboard.chat board using the included link. "}>
                                                </div>
                                                <Tooltip classes={{
                                                    tooltip: classes.customTooltip,
                                                    arrow: classes.customArrow
                                                }} title='Share to Microsoft Teams'>
                                                    <div onClick={() => { document.getElementById("teams-share-button-div").click() }}
                                                        className='shrBtnlbl' style={{ left: '-10px' }}>
                                                        Teams
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </>
                                    )}

                                </div>
                                <div style={{ marginTop: '30px' }}>
                                    {!showTeaCopy && <div className='urlCopyBtn shareSection2 showTeaCls shrBtnlbl' onClick={() => setShowTeaCopy(true)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            {svgIcons.teacher}
                                        </svg>
                                        Show co-teacher invite
                                    </div>}
                                    {showTeaCopy && <div>
                                        <div className='flexCenter'>
                                            <img src='/Divider.svg' alt='divider' />
                                            <Typography className='joinTeacherContent'>
                                                Copy co-Teacher invite
                                            </Typography>
                                            <img src='/Divider.svg' alt='divider' />
                                        </div>
                                        <div className='sharebyUrlBigBox flex-a-c'>
                                            <TextField
                                                variant='outlined'
                                                inputRef={coRef}
                                                className='joinInp'
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton className="inviteICon">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    {svgIcons.teacher}
                                                                </svg>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}

                                                value={COJOINURL}
                                            />
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handlecopyCoJoin}
                                                className='urlCopyBtn'
                                                style={{ background: "#ffffff" }}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    {svgIcons.teacherCopying}
                                                </svg>
                                            </IconButton>
                                        </div>
                                    </div>}


                                </div>
                            </div>
                        </div>

                    </Hidden>

                    <Hidden xsDown>
                        <div className='shareSection2'>
                            <div className='flexCenter'>
                                <img src='/Divider.svg' alt='divider' />
                                <Typography className='joinContent'>
                                    Copy student invite
                                </Typography>
                                <img src='/Divider.svg' alt='divider' />
                            </div>

                            <div className='sharebyUrlBigBox flex-a-c'>
                                <TextField
                                    variant='outlined'
                                    inputRef={textAreaRef}
                                    className='joinInp'
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton className="inviteICon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        {svgIcons.student}
                                                    </svg>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}

                                    value={JOINURL}
                                />
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handlecopy}
                                    className='urlCopyBtn'
                                    style={{ background: "#3174F5" }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        {svgIcons.copying}
                                    </svg>
                                </IconButton>

                            </div>
                            <div className='sharebyUrlBigBox flex-a-c'>

                                <TextField
                                    variant='outlined'
                                    inputRef={joinCodeTextAreaRef}
                                    className='joinInp codeMono'
                                    InputProps={{
                                        readOnly: true,
                                    }}

                                    value={joinCode}
                                />
                                <Tooltip classes={{
                                    tooltip: classes.customTooltip,
                                    arrow: classes.customArrow
                                }} title='Copy Classroom Code'>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handlecopyCode}
                                        className='urlCopyBtn'
                                        style={{ background: "#3174F5" }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            {svgIcons.copying}
                                        </svg>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="qrtext ml-5 elink" style={{ marginTop: '2px', marginBottom: '2px' }}>
                                <a href={URL} className='click2Email'>
                                    Email invite link</a>
                            </div>
                            <div className="shareBtns">
                                {props && props.sess && (
                                    <>
                                        <div className='gClassRoom'>
                                            <GoogleShareToClassRoom
                                                body={"whiteboard.chat " + props.sess.name}
                                                // itemType="assignment"
                                                url={JOINURL}
                                                size={25}
                                                title={"whiteboard.chat " + props.sess.name}
                                            />
                                            <Tooltip classes={{
                                                tooltip: classes.customTooltip,
                                                arrow: classes.customArrow
                                            }} title='Share in Google classroom'>
                                                <div style={{ cursor: 'default' }} className='shrBtnlbl'>
                                                    Classroom
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className='gClassRoom'>
                                            <div ref={ref}
                                                id="teams-share-button-div"
                                                className="teams-share-button"
                                                data-href={JOINURL}
                                                data-icon-px-size="30"
                                                data-preview="false"
                                                data-msg-text={"Whiteboard.chat " + props.sess.name}
                                                data-assign-title={"Whiteboard.chat " + props.sess.name}
                                                data-assign-instr={"Please join the whiteboard.chat board using the included link. "}>
                                            </div>
                                            <Tooltip classes={{
                                                tooltip: classes.customTooltip,
                                                arrow: classes.customArrow
                                            }} title='Share to Microsoft Teams'>
                                                <div onClick={() => { document.getElementById("teams-share-button-div").click() }}
                                                    className='shrBtnlbl' style={{ left: '-10px' }}>
                                                    Teams
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </>
                                )}

                            </div>
                            <div style={{ marginTop: '30px' }}>
                                {!showTeaCopy && <div className='urlCopyBtn shareSection2 showTeaCls shrBtnlbl' onClick={() => setShowTeaCopy(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        {svgIcons.teacher}
                                    </svg>
                                    Show co-teacher invite
                                </div>}

                                {showTeaCopy && <div>
                                    <div className='flexCenter'>
                                        <img src='/Divider.svg' alt='divider' />
                                        <Typography className='joinTeacherContent'>
                                            Copy co-Teacher invite
                                        </Typography>
                                        <img src='/Divider.svg' alt='divider' />
                                    </div>
                                    <div className='sharebyUrlBigBox flex-a-c'>
                                        <TextField
                                            variant='outlined'
                                            inputRef={coRef}
                                            className='joinInp'
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <IconButton className="inviteICon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                {svgIcons.teacher}
                                                            </svg>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}

                                            value={COJOINURL}
                                        />
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handlecopyCoJoin}
                                            className='urlCopyBtn'
                                            style={{ background: "#ffffff" }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                {svgIcons.teacherCopying}
                                            </svg>
                                        </IconButton>
                                    </div>
                                </div>}

                            </div>
                        </div>

                    </Hidden>


                </>
            </Dialog>
        )
    }

    function nameOnClick(e) {
        document.addEventListener('click', clickAwayBoardName);
    }

    function clickAwayBoardName(e) {
        document.removeEventListener('click', clickAwayBoardName);
        var name = document.getElementById("bname");
        if (!name) return;
        if (e.target !== name) {
            handleBoardNameChange(e);
        }
    }

    function handleBoardNameChange(e) {
        e.preventDefault();
        document.removeEventListener('click', clickAwayBoardName);
        // Don't use boardName here, it could be stale.
        props.renameCB(document.getElementById("bname").value);
    }

    function updateBoardName(e) {
        if (e && e.target) {
            const n = e.target.value;
            setBoardName(p => n);
        }
    }

    React.useEffect(() => {
        if ((!Boolean(teacherR) && boardConfig.fourToolsStudent)) {
            setOpenBoardName(false)
        }
        else {
            setOpenBoardName(true)
        }
    }, [teacherR, boardConfig])
    const palletRef = React.useRef(null)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    function openClass() {
        dispatch(Actions.setDrawerWalker({
            ...drawerWalker,
            open: true
        }))
        handleClose()
    }
    function openEngagement() {
        handleClose()
        props.cb['openEngagement']()
    }
    const [langPopper, setLangPopper] = React.useState([]);

    async function setChatLang() {
        var langs = await ib.getLanguages()
        // let languageNames = new Intl.DisplayNames(['en'], {type: 'language'});
        handleClose()
        // if (!langs) {
        //     langs = { 0: { language: "en" }, 1: { language: "es" } }
        // }
        if (langs) {
            // var ff = [{ id: "default", long: "default" }]
            var ff = []
            for (let i in langs) {
                const r = langs[i]
                //    var ff2 = languageNames.of(r.language);
                var ff2 = r.language
                if (r.language in isoLangs) {
                    ff2 = isoLangs[r.language].nativeName
                }
                ff.push({ long: ff2, id: r.language })
            }
            ff.sort((a, b) => {
                return a.id.localeCompare(b.id);
            })
            ff.unshift({ id: "default", long: "default" })
            // console.log("FF IS ", JSON.stringify(ff))
            setLangPopper(ff)
            return
        }
        setLangPopper([])
    }
    function LangPopper(props) {
        var rr = mylocalStorage.getItem("chatLang")
        rr = rr ? rr : "default"
        const [sel, setSel] = React.useState(rr)

        function handleChange(c) {
            const selectTool = c.target.value
            if (selectTool === "default") {
                mylocalStorage.removeItem("chatLang")
            } else {
                mylocalStorage.setItem("chatLang", selectTool)
            }
            setSel(selectTool)
            setLangPopper([])
        }

        return (
            <Popover
                anchorReference="anchorPosition"
                onClose={() => setLangPopper([])}
                anchorPosition={{ top: 60, left: window.innerWidth - 220 }}
                open={Boolean(langPopper.length > 0)} >
                <div style={{ "zIndex": "210", color: "#ffffff", backgroundColor: props.inkColor, width: 200 }}>
                    <div className='chatlng'>
                        <FormHelperText className='font14px' style={{ color: "#ffffff" }}>
                            Select Chat Language
                        </FormHelperText>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={sel}
                            onChange={handleChange}
                            inputProps={{
                                classes: {
                                    icon: classes.iconButton,
                                },
                            }}
                            style={{ padding: "5px", width: '90%', color: "#ffffff" }}

                        >
                            {langPopper.map((v) => (
                                <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff !important" }} value={v.id} key={v.id}>
                                    <Typography variant="h6" style={{ color: "#ffffff" }}>{v.long}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>

            </Popover>
        )
    }
    const [icoDisable, setIcoDisable] = React.useState({ palette: false, login: false })
    React.useEffect(() => {
        var pd = false
        if (!Boolean(teacherR)) {
            if (boardTools && boardTools['Palette'] && boardTools['Palette'].checked) pd = true
        }
        setIcoDisable({ palette: pd })
    }, [boardTools, teacherR])
    function setBackGround() {
        handleClose()
        dispatch(Actions.setBackGround({
            ...background,
            pickerOpen: true
        }))
    }
    function resetBorder() {
        mylocalStorage.removeItem("backgroundFrame")
        handleClose()
        window.location.reload()
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    function autoCorrect() {
        setOpenAutoCorrect(true)
        handleClose()
        if (props.sess && props.sess.Classroom) {
            ib.autoCorrect(props.sess.Classroom, false, null)
        }
    }
    function autoCorrectRemove() {
        handleClose()
        if (props.sess && props.sess.Classroom) {
            ib.autoCorrectRemove(props.sess.Classroom, false, null)
        }
    }
    function lockAllobjects() {
        handleClose()
        props.cb['lockAllObj']()
    }
    function openDemoStudent() {
        const demos = {
            "www.uswest.whiteboard.chat": "www.uswestwb.epiphani.ai",
            "uswest.whiteboard.chat": "www.uswestwb.epiphani.ai",
            "www.whiteboard.chat": "shayr.us",
            "www.asia.whiteboard.chat": "www.whiteboardchat.asia"
        }
        handleClose()
        var host = window.location.hostname
        var path = window.location.pathname
        if (!demos[host]) {
            alert("Demo student not available in this location, please use other browser or incognito window")
            return
        }
        path = path.replace("board", "join")
        var newPath = "https://" + demos[host] + path
        window.open(newPath)
    }
    function copyToClipboard(text) {
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        return result;
    }
    function handleCopyCodeFromTitleBar(e) {
        copyToClipboard(joinCode)
        props.cb['Notice']("Classroom Code copied to clipboard, please paste it and share")
    }
    function handleCopyJoinLinkFromTitleBar(e) {
        const JOINURL = window.location.href.replace("/board", "/join")
        copyToClipboard(JOINURL)
        props.cb['Notice']("Invite copied to clipboard, please paste it and share")
    }
    const mobile = (typeof window.orientation !== 'undefined' && (window.screen.width < 500))
    const tabMob = (typeof window.orientation !== 'undefined' && (window.innerWidth < 900))
    const landscapemode = (typeof window.orientation !== 'undefined' && (window.screen.width < 1100))
    const safari = window.safari !== undefined;
    const renderHand = (!mobile || !Boolean(teacherR))
    const openDrawer = palletDrawer.openDrawer

    function showWBCTitle() {
        // Hide "Whiteboard.chat" text in title bar in MS Teams tab. Recommended by Teams App Review team.
        if (window.insideMSTeams === true)
            return false
        return true
    }
    const [yn, setyn] = React.useState({ open: false, message: "", cb: null })

    const [menuAnchorEl, setMenuAnchorEl] = React.useState({ el: null });
    const [firstList, setFirstList] = React.useState({ list: [] });
    const [menuAnchorElB, setMenuAnchorElB] = React.useState({ el: null });
    const [secondList, setSecondList] = React.useState({ list: [] });

    const Tools = [
        { name: 'Copy Page', cb: copyPage, userroles: ['teacher'] },
        { name: 'Download PDF', cb: downloadPdf, icon: <ArrowRightIcon /> },
        { name: 'Export', cb: exportBoardMenu, icon: <ArrowRightIcon />, userroles: ['teacher'] },
        { name: 'Import', cb: handleImportBoard, userroles: ['teacher'] },
        { name: 'Save Copy', cb: saveCopy, userroles: ['teacher'] },
        { name: 'Send a Copy', cb: copyBoard, userroles: ['teacher'] },
        { name: 'Send Unencrypted Copy', cb: copyBoardunc, userroles: ['teacher'] },
        { name: 'Share With Community', cb: publishToCommunity, userroles: ['teacher'] },
    ]

    const ExportTools = [
        { name: 'Save local copy', cb: () => { exportBoard(true, '/') } },
        { name: 'Export to whiteboard.chat', url: 'https://www.whiteboard.chat/', cb: () => { exportBoard(false, 'https://www.whiteboard.chat', 'whiteboard') } },
        { name: 'Export to uswest.whiteboard', url: 'https://uswest.whiteboard.chat/', cb: () => { exportBoard(false, 'https://uswest.whiteboard.chat', 'uswest') } },
        { name: 'Export to asia.whiteboard', url: 'https://www.asia.whiteboard.chat/', cb: () => { exportBoard(false, 'https://www.asia.whiteboard.chat', 'asia') } },
    ]
    const [downloadDisable, setDownloadDisable] = React.useState({ Download: false })
    React.useEffect(() => {
        var pd = false
        if (!Boolean(teacherR)) {
            if (boardTools && boardTools['Download'] && boardTools['Download'].checked) pd = true
        }
        setDownloadDisable({ Download: pd })
    }, [boardTools, teacherR])

    function exportBoard(local, path, instance) {
        if (local) {
            props.cb["Export"](local)
        } else {
            props.cb["Export"](local, path, instance)
        }
    }
    function exportBoardMenu(e) {
        if (e) {
            handleClickOpenMenuB(e, ExportTools.filter(e => {
                if (e.url) {
                    var origin = window.location.origin
                    if (window.location.origin === "https://wb.whiteboard.chat") {
                        origin = "https://www.whiteboard.chat"
                    }
                    return !e.url.includes(origin)
                } else {
                    return true
                }
            }))
        }
    }

    function handleImportBoard() {
        props.setShowTextTools(false);
        if (authUser) {
            importFile.current.click();
        } else {
            setStatusMsg("Please SignIn to import file")
        }
    }

    function statusSnackbar(msg) {
        setStatusMsg(msg)
    }

    async function loadFile(file) {
        var user = mylocalStorage.getItem('mystoreID');
        bUtils.loadFile(file, user, statusSnackbar)
    }

    var download = [
        { name: 'My Board', cb: downloadSingle },
        { name: 'This Page', cb: downloadThisPage },
        { name: 'Whole Class - Single Page', cb: downloadPage, userroles: ['teacher'] },
        { name: 'Whole Class - Single PDF', cb: downloadClass, userroles: ['teacher'] },
        { name: 'Whole Class - One PDF per scholar', cb: downloadClass2, userroles: ['teacher'] },
        { name: 'Download Report', cb: DownloadReport, userroles: ['teacher'] },
    ]

    function downloadPdf(e) {
        handleClickOpenMenuB(e, download)
    }

    function bookManage(e) {
        handleClickOpenMenu(e, Tools)
    }

    function downloadThisPage() {
        props.cb['downloadThisPage']()
        handleCloseMenu()
        ReactGA.event({
            category: 'Download',
            action: 'downloadThisPage'
        });
    }

    function downloadSingle() {
        props.cb['Download']()
        handleCloseMenu()
        ReactGA.event({
            category: 'Download',
            action: 'SingleBoard'
        });
    }

    function downloadClass() {
        props.cb['DownloadClass'](false, 0)
        handleCloseMenu()
        ReactGA.event({
            category: 'Download',
            action: 'WholeClass'
        });
    }
    function downloadClass2() {
        props.cb['DownloadClass'](true, 0)
        handleCloseMenu()
        ReactGA.event({
            category: 'Download',
            action: 'WholeClass2'
        });
    }
    function downloadPage() {
        handleCloseMenu()
        setyn({
            open: true, link:
                "", message: "Page Number to download",
            cb: done, text: true
        })
        async function done(e) {
            setyn({ open: false, message: "", cb: null })
            if (!e) return
            props.cb['DownloadClass'](false, e)
        }
    }

    const handleClickOpenMenu = (event, list) => {
        setMenuAnchorEl({ el: event.currentTarget });
        setFirstList({ list: list });
    };


    const handleClickOpenMenuB = (event, list) => {
        setMenuAnchorElB({ el: event.currentTarget });
        setSecondList({ list: list });
    };

    function copyBoard() {
        var ff = window.location.href
        var ffa = ff.split("/board/")
        en.encrypt_data(ffa[1], cbdat)
        function cbdat(dat) {
            let link = ffa[0] + "/copy/" + dat
            setyn({ open: true, link: link, message: "Send the link below to allow people to create copy", cb: done, clip: true })
            function done() {
                setyn({ open: false, message: "", cb: null })
            }
        }
        ReactGA.event({
            category: 'Teacher',
            action: "CopyBoard"
        });
        handleClose()
    }
    function publishToCommunity() {

        /* Sample URL
            https://docs.google.com/forms/d/e/1FAIpQLScaoQ_UcFrtMOI_oWjhkZOJcScTBaIbIvReKad6uILeOT3j4A/viewform?usp=pp_url&entry.1867586=Contributor@community.com&entry.1040479962=First+and+Last+Name&entry.1238796437=United+States+of+America&entry.1742748691=Title+of+board&entry.98610795=Description+of+the+board&entry.485492986=K-12+Grades&entry.718409867=The+subject+relevant+to+the+board&entry.1433769603=https://the.link.to.board.com/
        */
        var myName = mylocalStorage.getItem('myname');
        var name = encodeURIComponent(myName).replace(/%20/g, '+');
        var email = null
        if (authUser) {
            try {
                email = encodeURIComponent(authUser.attributes.email).replace(/%20/g, '+');
            } catch { }
        }
        var boardLink = window.location.href
        var ffa = boardLink.split("/board/")
        const title = encodeURIComponent(props.sess.name).replace(/%20/g, '+');
        en.encrypt_data(ffa[1], cbdat)
        function cbdat(dat) {
            var copyLink = ffa[0] + "/copy/" + dat
            var publishFormURL = "https://docs.google.com/forms/d/e/1FAIpQLScaoQ_UcFrtMOI_oWjhkZOJcScTBaIbIvReKad6uILeOT3j4A/viewform?usp=pp_url"
            if (title) {
                publishFormURL = publishFormURL + "&entry.1742748691=" + title
            }
            if (email) {
                publishFormURL = publishFormURL + "&entry.1867586=" + email
            }
            if (name) {
                publishFormURL = publishFormURL + "&entry.1040479962=" + name
            }
            publishFormURL = publishFormURL + "&entry.1433769603=" + copyLink
            window.open(publishFormURL)
        }
    }


    function copyBoardunc() {
        var ff = window.location.href
        var ffa = ff.split("/board/")
        cbdat(ffa[1])
        function cbdat(dat) {
            let link = ffa[0] + "/copy/" + dat
            setyn({ open: true, link: link, message: "Send the link below to allow people to create copy", cb: done, clip: true })
            function done() {
                setyn({ open: false, message: "", cb: null })
            }
        }
        ReactGA.event({
            category: 'Teacher',
            action: "CopyBoardUnc"
        });
        handleClose()
    }

    function saveCopy() {
        manageBoards(true);
    }

    function manageBoards(sendSession) {
        mylocalStorage.setItem('manageBoardsSession', props.sessionId);

        if (props.sess && props.sess.Classroom) {
            mylocalStorage.setItem('manageBoardsClassroom', props.sess.Classroom);
        } else {
            mylocalStorage.removeItem('manageBoardsClassroom');
        }

        var urlPath = "/manage/";

        if (sendSession) {
            urlPath += props.sessionId;
        }

        props.history.push({ pathname: urlPath, state: { 'inkColor': props.inkColor } });
    }


    function copyPage() {
        ib.makePageCopy(props.sess.id, props.sess, fin)
        function fin(pg, success) {
            var msg = "Successfully created page #"
            if (!success) msg = "Could not create new page, it already exists #"
            msg = msg + pg
            props.cb["Message"](msg)
        }
    }

    function getPath(bType) {
        if (bType.includes('Single')) {
            return svgIcons.singleBoard
        } else if (bType.includes('Student')) {
            return svgIcons.studentBoard
        } else if (bType.includes('Instructor')) {
            return svgIcons.ins
        }
    }

    function handlePaletteDrawer(type) {
        if (type !== 'close') mylocalStorage.setItem('palletOption', type);
        const openDrawer = palletDrawer.openDrawer
        dispatch(Actions.setPalette({
            openDrawer: !openDrawer,
            ref: palletRef.current
        }))
    }

    function DownloadReport() {
        handleCloseMenu()
        ib.doReport(props.sess.Classroom)
    }


    const handleCloseMenu = () => {
        setMenuAnchorEl({ el: null });
        setMenuAnchorElB({ el: null });
    };

    function handleDrag(e, ui) {
        if ((ui.y < 100 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    React.useEffect(() => {
        if ((!Boolean(teacherR) && boardConfig.simpleDrawingTools) || (!Boolean(teacherR) && boardConfig.fourToolsStudent)) {
            setOpenSimpleTools(false)
        }
        else {
            setOpenSimpleTools(true)
        }
    }, [teacherR, boardConfig])

    return (
        <div className={classes.root}>

            {props.isApiMode ?
                <Drawer
                    variant="permanent"
                    className='newDrawer wbcDrawer'
                >
                    <EventPopper  {...props} popperRef={popperRef.current} />

                    <WbcJrLongMenu {...props} handleDrawerOpen={handleDrawerOpen} statusMsg={statusMsg} setStatusMsg={setStatusMsg} penBtnColor={props.penBtnColor} />
                </Drawer>
                :
                <>
                    <YesNoDialog {...yn} inkColor={props.inkColor} />
                    <Drawer
                        variant="permanent"
                        className={clsx('newDrawer', classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerNew]: open,
                            [classes.drawerClose]: !open,
                            background: props.inkColor,
                            backgroundColor: props.inkColor,
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerClose]: !open,
                                [window.insideMSTeams === true ? classes.drawerOpenTopMSTeams : (classes.drawerOpenTop, classes.drawerNew)]: open,
                                [window.insideMSTeams === true ? classes.drawerCloseTopMSTeams : (classes.drawerCloseTop, classes.drawerNew)]: !open
                            }),
                        }}
                    >
                        <div
                            style={{ backgroundColor: props.inkColor }}
                        >
                            <div className={clsx('ChevronRight', classes.toolbar)} style={{ backgroundColor: props.inkColor, zIndex: '11' }}>
                                {open && (
                                    <IconButton onClick={handleDrawerClose}
                                        style={{ backgroundColor: props.inkColor, minHeight: '45px' }}>
                                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon className={classes.iconButton} />}
                                    </IconButton>
                                )}
                            </div>
                            <div className='menuIconCls' style={{ backgroundColor: props.inkColor, zIndex: '11', paddingTop: props.isBorderSet ? '10px' : '' }}>
                                {!open && (
                                    <IconButton onClick={handleDrawerOpen} id="openDrawer" style={{ backgroundColor: props.inkColor, minHeight: '45px' }}>
                                        <img src='/tools/HambIcon.svg' alt='menu icon' />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                        <Divider style={{ backgroundColor: "#FAFAFA" }} />
                        <LongMenu {...props} handleDrawerOpen={handleDrawerOpen} statusMsg={statusMsg} setStatusMsg={setStatusMsg} />
                    </Drawer>
                    <input type='file' id='importFile' accept=".zip,application/json,.wbc" onChange={loadFile} ref={importFile} style={{ display: 'none' }} value={""} />
                    <LetItSnow open={letItSnow} />
                    <TipsDialog setopen={props.openTip} cb={() => props.setopenTip(false)} tourRequestedCB={props.tourRequestedCB} />
                    <InvitePopper />
                    {background.pickerOpen && (<BackColorPicker {...props} />)}
                    <DrawerWalker {...props} />
                    {langPopper.length > 0 && (<LangPopper inkColor={props.inkColor} />)}
                    <CssBaseline />
                    {syncDisabled && <Draggable
                        handle="strong"
                        op
                        onDrag={handleDrag}
                        onStop={handleDragEnd}
                        position={windowPosition}
                        defaultClassName="draggable33-color"
                        defaultClassNameDragging="draggable32-color"
                        defaultClassNameDragged="draggable33-color"
                    >
                        <div id="smallpalette" className={clsx(classes.draggableWindow, 'boardCPdragDiv')} style={{
                            border: '5px solid ' + props.inkColor,
                        }}>
                            <div className={clsx('handle', classes.drawerHeader)}>
                                <div className={classes.draggableTop}
                                    style={{
                                        width: "100%",
                                        height: '8px',
                                        backgroundColor: '#E2E6E9',
                                        cursor: "move"
                                    }}
                                >
                                    <strong className={clsx(classes.draggableTopHandle, 'justify-center')}>
                                        <svg width="30" height="10" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg">
                                            {svgIcons.dots}
                                        </svg>
                                    </strong>

                                </div>
                            </div>

                            <div className='dragDivForm'>
                                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                    <IconButton id="timerButton">
                                        <img src={CorrectIcon} alt="Correct Icon" />
                                    </IconButton>
                                    <span className={classes.replication}>Realtime replication disabled</span>
                                    <Button variant="contained" style={teacherStyle} onClick={disableSync}>Enable</Button>
                                </div>
                            </div>
                        </div>
                    </Draggable>}
                    <AppBar
                        className={clsx(classes.appBar, classes.appRelative, 'newHeader', 'respMode-pl',
                            { 'openDHeader': open, })}
                        style={{ top: props.isBorderSet ? '6px' : '' }}
                    >
                        <Toolbar color="transparent" variant="dense" disableGutters={true} className='newTopHeader'>
                            {/* <Hidden xsDown> */}
                            <div className={clsx({ 'moveHeader': props.menuDrawerOpen }, 'flex-align-center flex z1')}>
                                {window.insideMSTeams === false && openSimpleTools &&
                                    <Tooltip className={classes.tooltip}
                                        classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }}
                                        title="Home" arrow placement="top">
                                        <img src='/newlogwc.svg' alt='wc' style={{ cursor: 'pointer' }} onClick={() => { props.history.push("/") }} />
                                    </Tooltip>}
                                <Typography align="center" className={clsx(classes.typographyCenter,
                                    [window.insideMSTeams === true ? '' : classes.marginy10])} variant="h6" noWrap ref={popperRef} >
                                    {!showCase.locked && (
                                        <form onSubmit={handleBoardNameChange} autoComplete="off" style={{ width: "auto" }}>
                                            <Grid container justify="center" alignItems="center">
                                                {(props.gridView && props.gridView.open) ?
                                                    <>
                                                        <svg style={{ marginRight: '8px' }} width="18" height="14" viewBox="0 0 14 11" fill={props.inkColor} xmlns="http://www.w3.org/2000/svg">
                                                            {getPath(boardType.name)}
                                                        </svg>
                                                        {!tabMob && <Grid item className='bTypeLbl'>{"Instructor Grid View"}</Grid>}
                                                    </>
                                                    :
                                                    <>
                                                        <svg style={{ marginRight: '8px' }} width="18" height="14" viewBox="0 0 14 11" fill={props.inkColor} xmlns="http://www.w3.org/2000/svg">
                                                            {getPath(boardType.name)}
                                                        </svg>
                                                        <Grid item className='bTypeLbl'>
                                                            <span>
                                                                {tabMob ? null : boardType.name + ": "}
                                                            </span>
                                                            {allowBoardRename ?
                                                                <div style={{ marginBottom: safari ? "4px" : "0px" }}>
                                                                    <input name="bname" id="bname" className="boardNameInput" value={boardName}
                                                                        placeholder="Unnamed" onChange={updateBoardName} onClick={nameOnClick}
                                                                        size={boardName.length <= 50 ? boardName.length : 50} />
                                                                </div>
                                                                : <div id="bname">{boardName}</div>
                                                            }
                                                            <IconButton className='btnSqr p-0' onClick={bookManage}>
                                                                <ArrowDropDownIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </form>
                                    )}
                                </Typography>

                                {joinCode !== "Unavailable" && teacherR ?
                                    <div className='codePill'>
                                        <Box fontFamily="Monospace" fontSize="h6.fontSize"
                                            onClick={handleCopyCodeFromTitleBar} style={{ cursor: "pointer", color: props.inkColor, margin: tabMob ? '10px 5px' : '10px' }}>
                                            {joinCode}
                                        </Box>
                                    </div> : null}
                            </div>

                            {/* </Hidden> */}


                            <Hidden xsDown>

                                {/* <Typography align="center" className={classes.typographyCenter} variant="h6" noWrap ref={popperRef} >
                            {!showCase.locked && (
                                <form onSubmit={handleBoardNameChange} autoComplete="off" style={{ width: "auto" }}>
                                    <Grid container justify="center" alignItems="center">
                                        {(props.gridView && props.gridView.open) ?
                                            <Grid item>{"Instructor Grid View"}</Grid> :
                                            <>
                                                <Grid item>{boardType.name + ": "}</Grid>
                                                <Grid item>
                                                    {allowBoardRename ?
                                                        <input name="bname" id="bname" className="boardNameInput" value={boardName}
                                                            placeholder="Unnamed" onChange={updateBoardName} onClick={nameOnClick}
                                                            size={boardName.length <= 50 ? boardName.length : 50} /> :
                                                        <div id="bname">{boardName}</div>
                                                    }
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </form>
                            )}
                        </Typography> */}
                                {props.sess && props.sess.Classroom && popperRef && (
                                    <EventPopper  {...props} popperRef={popperRef.current} />
                                )}
                            </Hidden>

                            <div style={{ marginRight: props.menuDrawerOpen ? '8px' : '0px' }}>
                                <div className="flex">
                                    <div className={clsx({ ' chip2Overflow': props.menuDrawerOpen }, 'chip2')}>
                                        {teacherR ?
                                            <Tooltip className={classes.tooltip}
                                                classes={{
                                                    tooltip: classes.customTooltip,
                                                    arrow: classes.customArrow
                                                }}
                                                title='It is Summer!!' arrow placement="top">
                                                <IconButton
                                                    id="letItSnowButton"
                                                    color="inherit"
                                                    aria-label="open drawer"
                                                    edge="end"
                                                    onClick={letItSnowHandler}
                                                    style={{ color: props.inkColor, marginLeft: (props.menuDrawerOpen && tabMob) ? '90px' : (props.menuDrawerOpen && landscapemode) ? "180px" : "0px" }}
                                                >
                                                    {/* <AcUnit /> */}
                                                    <LocalFlorist />
                                                </IconButton></Tooltip> : null}
                                        <Avatars {...props} tabMob={tabMob} />
                                        <Tooltip className={classes.tooltip}
                                            classes={{
                                                tooltip: classes.customTooltip,
                                                arrow: classes.customArrow
                                            }}
                                            title='Actions' arrow placement="top">
                                            <IconButton id="boardActionButton" className={clsx(classes.iconButtonHelp, 'p-5 btnSqr')} onClick={handleClickMenu}
                                                style={{ color: props.inkColor, margin: 0 }}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </Tooltip>

                                        {/* <div className="tabs">
                                        {tab.allTabs.length > 0 && (

                                            <Tabs
                                                value={tabValue}
                                                onChange={handleTabChange}
                                                variant="standard"
                                                indicatorColor="secondary"
                                                textColor="inherit"
                                                aria-label="icon label tabs example"
                                            >
                                                {tab.allTabs.map((o) => (
                                                    <TabStyle key={o.name} icon={tabIcons[o.type]} />
                                                ))}

                                            </Tabs>
                                        )}

                                    </div> */}

                                        <AuthDialog sess={props.sess} showSettings inkColor={props.inkColor} />
                                        {teacherR ? (
                                            <IconButton id="inviteButton" ref={tpopperRef} className={clsx('m-10', classes.inviteButton)} onClick={handleClick}
                                                style={{ backgroundColor: props.inkColor }}>
                                                INVITE
                                            </IconButton>
                                        ) : null}
                                    </div>
                                    {openAutoCorrect && (<AutoCorrectModal open={openAutoCorrect} setOpen={setOpenAutoCorrect} inkColor={props.inkColor} />)}
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        {Boolean(teacherR) && (
                                            <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={autoCorrect}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><PlaylistAddCheckIcon /></ListItemIcon>
                                                <Typography variant="h6">Auto-Correct all boards</Typography>
                                            </MenuItem>
                                        )}
                                        {Boolean(teacherR) && (
                                            <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={autoCorrectRemove}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><PlaylistAddCheckIcon /></ListItemIcon>
                                                <Typography variant="h6">Delete Auto-Correct</Typography>
                                            </MenuItem>
                                        )}
                                        <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={() => setChatLang()}>
                                            <ListItemIcon style={{ color: "#ffffff" }}><TranslateIcon /></ListItemIcon>
                                            <Typography variant="h6">Chat Language</Typography>
                                        </MenuItem>
                                        {Boolean(teacherR) && (
                                            <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={lockAllobjects}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><LockIcon /></ListItemIcon>
                                                <Typography variant="h6">Lock all objects on this page</Typography>
                                            </MenuItem>
                                        )}
                                        {(!mobile && teacherR && props.sess && props.sess.Classroom) ? (
                                            <MenuItem className='newOpts' style={{ backgroundColor: props.inkColor, color: "#ffffff", cursor: 'default' }} >
                                                <ListItemIcon style={{ color: "#ffffff" }}><PollIcon /></ListItemIcon>
                                                <ParticipantDrawer {...props} handleClose={handleClose} />
                                            </MenuItem>
                                        ) : null}
                                        {Boolean(teacherR) && (

                                            <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={openClass}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><OfflinePinIcon /></ListItemIcon>
                                                <Typography variant="h6">Open Class Boards</Typography>
                                            </MenuItem>
                                        )}
                                        {Boolean(teacherR) && (
                                            <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={openDemoStudent}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><FaceIcon /></ListItemIcon>
                                                <Typography variant="h6">Open Demo Student</Typography>
                                            </MenuItem>
                                        )}
                                        <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={() => resetBorder()}>
                                            <ListItemIcon style={{ color: "#ffffff" }}><FlipToFrontIcon /></ListItemIcon>
                                            <Typography variant="h6">Reset Border</Typography>
                                        </MenuItem>
                                        {!background.notmine && (
                                            <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={() => setBackGround()}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><FlipToFrontIcon /></ListItemIcon>
                                                <Typography variant="h6">Set BackGround</Typography>
                                            </MenuItem>
                                        )}
                                        {(videoEnabled && creator && tab.allTabs.length === 0) ? (
                                            <MenuItem className='newOpts' style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={handleVideoCall}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><VideoCallIcon /></ListItemIcon>
                                                <IconButton className={classes.inviteButton}
                                                    style={{ backgroundColor: props.inkColor }}>
                                                    <Typography variant="h6"> Start Audio/Video call</Typography>
                                                </IconButton>
                                            </MenuItem>
                                        ) : null}
                                        {(creator && tab.allTabs.length !== 0) ? (
                                            <MenuItem className='newOpts' style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={handleVideoClose} >
                                                <ListItemIcon style={{ color: "#ffffff" }}><VideocamOffIcon /></ListItemIcon>

                                                <IconButton className={classes.inviteButton}
                                                    style={{ backgroundColor: props.inkColor }}>
                                                    <Typography variant="h6"> End Audio/Video call </Typography>
                                                </IconButton>
                                            </MenuItem>
                                        ) : null}

                                        {Boolean(teacherR) && (
                                            <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={disableSync}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><SyncDisabledIcon /></ListItemIcon>
                                                <Typography variant="h6">Toggle replication to students</Typography>
                                            </MenuItem>
                                        )}

                                        {Boolean(teacherR) && (
                                            <MenuItem style={{ backgroundColor: props.inkColor, color: "#ffffff" }} onClick={openEngagement}>
                                                <ListItemIcon style={{ color: "#ffffff" }}><TrendingUpIcon /></ListItemIcon>
                                                <Typography variant="h6">View Engagement Report</Typography>
                                            </MenuItem>
                                        )}

                                    </Menu>

                                    {/* {(!mobile && teacherR && props.sess && props.sess.Classroom) ? (
                                <ParticipantDrawer {...props} />
                            ) : null} */}
                                    {/* {(videoEnabled && creator && tab.allTabs.length === 0) ? (
                                <IconButton className={classes.inviteButton} onClick={handleVideoCall}
                                    style={{ backgroundColor: props.inkColor }}>
                                    Video call
                                </IconButton>
                            ) : null}
                            {(creator && tab.allTabs.length !== 0) ? (
                                <IconButton className={classes.inviteButton} onClick={handleVideoClose}
                                    style={{ backgroundColor: props.inkColor }}>
                                    End Video
                                </IconButton>
                            ) : null} */}
                                    {!icoDisable.palette && (
                                        <div className='rigtSideBar' style={{ width: palletDrawer.openDrawer ? '12px' : 'fit-content', right: props.isBorderSet ? '10px' : '' }}>
                                            {palletDrawer.openDrawer ?

                                                <div>
                                                    <img src='/tools/doubleArrows.svg' alt='right arrow icon' className='rightArr' onClick={() => { handlePaletteDrawer('close') }} />
                                                </div>

                                                : <div className='palOptions' id="symbolsButton" >
                                                    {authUser && <div className='pal_icons'>
                                                        <Tooltip className={classes.tooltip}
                                                            classes={{
                                                                tooltip: classes.customTooltip,
                                                                arrow: classes.customArrow
                                                            }}
                                                            title='My Objects' arrow placement="top">
                                                            <IconButton className='btnSqr p-5' onClick={() => { handlePaletteDrawer('myobj') }}>
                                                                <svg width="20" height="20" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                                                    {svgIcons.myObj}
                                                                </svg>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>}
                                                    <div className='pal_icons' id="gizmosButton">
                                                        <Tooltip className={classes.tooltip}
                                                            classes={{
                                                                tooltip: classes.customTooltip,
                                                                arrow: classes.customArrow
                                                            }}
                                                            title='Gizmos' arrow placement="top">
                                                            <IconButton className='btnSqr p-5' onClick={() => { handlePaletteDrawer('math') }}>
                                                                <svg width="20" height="20" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                                                    {svgIcons.manipulative}
                                                                </svg>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className='pal_icons' id="widgetsButton">
                                                        <Tooltip className={classes.tooltip}
                                                            classes={{
                                                                tooltip: classes.customTooltip,
                                                                arrow: classes.customArrow
                                                            }}
                                                            title='Widgets' arrow placement="top">
                                                            <IconButton className='btnSqr p-5' onClick={() => { handlePaletteDrawer('widgets') }}>
                                                                <svg width="20" height="20" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                                                    {svgIcons.widgets}
                                                                </svg>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                    <div className='pal_icons'>
                                                        <Tooltip className={classes.tooltip}
                                                            classes={{
                                                                tooltip: classes.customTooltip,
                                                                arrow: classes.customArrow
                                                            }}
                                                            title='Search Palette' arrow placement="top">
                                                            <IconButton className='btnSqr p-5' onClick={() => { handlePaletteDrawer('search') }}>
                                                                <svg width="20" height="20" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                                                    {svgIcons.search}
                                                                </svg>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>}
                                        </div>)}

                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>

                    {palletDrawer.openDrawer &&
                        <Suspense fallback={<div>Loading...</div>}>
                            <Palette {...props} teacherR={teacherR} />
                        </Suspense>
                    }
                    <ColorPicker {...props} />
                    {!downloadDisable.Download && openBoardName && (
                        <Menu
                            id="BookManage"
                            anchorEl={menuAnchorEl.el}
                            keepMounted
                            open={Boolean(menuAnchorEl.el)}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {firstList.list.filter(a => (a.userroles === undefined || (a.userroles !== undefined && a.userroles.includes(userRole)))).map((e) => (

                                <MenuItem key={e.name} className="customNewMenuItem" onClick={e.cb && e.cb}>
                                    <Typography variant="h6">{e.name}</Typography>
                                    {e.icon && <ListItemIcon>{e.icon}</ListItemIcon>}
                                </MenuItem>
                            ))}
                        </Menu>)}

                    <Menu
                        id="subBM"
                        anchorEl={menuAnchorElB.el}
                        keepMounted
                        open={Boolean(menuAnchorElB.el)}
                        onClose={handleCloseMenu}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                        {secondList.list.filter(a => (a.userroles === undefined || (a.userroles !== undefined && a.userroles.includes(userRole)))).map((e) => (

                            < MenuItem key={e.name} className="customNewMenuItem" onClick={e.cb}>
                                <Typography variant="h6">{e.name}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            }

        </div >
    );
}
export default React.memo(MiniDrawer)