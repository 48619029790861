import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Tooltip,
    IconButton,
    Grid,
    Radio,
    FormControlLabel,
    Checkbox,
    Switch,
    TextField,
    Button,
    Typography
} from '@material-ui/core';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import AppsIcon from '@material-ui/icons/Apps';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import * as mylocalStorage from "./mylocalStorage"
import clsx from "clsx";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Draggable from 'react-draggable';
import GridBrowser from './GridBrowser';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        zIndex: 100,
        color: '#ffffff',
        '& > *': {
            margin: theme.spacing(0.4),
        },
    },
    listroot: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        zIndex: 105,
        position: "absolute",
        color: "#ffffff",
        backgroundColor: "#ffffff",
        right: 'calc(100% - 35%)'

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        paddingBottom: "5px",
        top: 20,
        left: 0,
        backgroundColor: "#ffffff"
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1",
        height: '7px',
        backgroundColor:'#E2E6E9'
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
}));
const MAXPAGES = 99

const ICONHT = '1rem'

const options = [
    { name: 'Grid View', id: "button_grid", dnext: true,  path: '/tools/GridView.svg' },
    { name: 'Goto my book', id: "button_child", icon: <AddToQueueIcon style={{ height: ICONHT }} />, path: '/tools/mybook.svg' },

];

function MultiBoardNav(props) {
    const classes = useStyles();
    //  const dispatch = useDispatch();
    const [currentOptions, setcurrentOption] = React.useState(options)
    // const [gridOptionsOpen, setGridOptionsOpen] = React.useState(false)
    const dispatch = useDispatch();
    const gridBrowser = useSelector((state) => state.gridBrowser);
    const boardConfig = useSelector((state) => state.BoardConfig);
    const xpos = 2
    const ypos = window.innerHeight - 480
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: ypos });
    const [pgNum, setpgNum] = React.useState(gridBrowser.pageNum);


    const handleClick = (e) => {
        if (props.cb) props.cb(e)
    }
    React.useEffect(() => {
        for (let i = 0; i < currentOptions.length; i++) {
            if (currentOptions[i].id === "button_child") {
                if (!props.amIParent) {
                    currentOptions[i].name = "Goto Instructor"
                    currentOptions[i].path = '/tools/instructorBoard.svg'
                } else {
                    //hide if instructor 
                    currentOptions[i].name = "Goto my book"
                }
            }
            if (currentOptions[i].id === "button_grid") {
                if (props.gv.open) {
                    currentOptions[i].name = "Single View"
                    currentOptions[i].path = '/singleView.svg'
                } else {
                    currentOptions[i].name = "Grid View"
                    currentOptions[i].path = '/tools/GridView.svg'
                }
            }
        }
        
        setcurrentOption([...currentOptions])
        // setGridOptionsOpen(props.openGo)
    }, [props, props.gv])

    const useStylesCard = makeStyles({
        root: {
            width: '400px',
            height: 'fit-content',
        },
        title: {
            fontfamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '20px',
            color: '#1E2639',
            margin: '24px 32px',
            textAlign: 'left'
        },
        content: {
            fontSize: '0.55rem',
        },
        media: {
          height: 140,
        },
        sublbl:{
            fontfamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: '#1E2639',
            textAlign: 'left'
        },
        tlml:{
            textAlign: 'left',
            margin: '4px 25px'
        },
        bb:{
            margin: '0 25px',
            display: 'flex',
            justifyContent: 'space-between'
        }
      });

    function gridOptionsClicked() {
        // setGridOptionsOpen(p => !p)
        props.setOpenGo(o => !o)
    }

    function setPageIfAllowed(pnum) {

        if (pnum >= 1 && pnum <= MAXPAGES) {
            setpgNum(pnum)
            dispatch(Actions.setGridBrowser({
                ...gridBrowser,
                pageNum: pnum,
            }))
        }
    }
    return (
        <>
                    <div className={clsx('disp-fc-hf', classes.root)}>
                        {currentOptions.map((option) => (
                            <div key={option.name} id="button_grid" className={clsx('footerBtn',
                            {'activeTabFooter': props.tabMode === option.name})}>
                                <Tooltip classes={{
                                    tooltip: classes.customTooltip,
                                }} arrow title={option.name} placement="top" >
                                    <IconButton id={option.id} className="minFrameButton btnSqr p-0" size="small"
                                        onClick={() => handleClick(option)} >
                                        <img src={option.path}  alt={option.name} style={{ width: '16px' }} />
                                        <span className='footerNavlbl'  style={{ color: props.tabMode === option.name ? '#40537B' : '#7D8FB6' }}>{option.name}</span>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ))}

                    </div>
        </>
    )
}
export default React.memo(MultiBoardNav)