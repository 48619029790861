import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  primary: {
    fontSize: '0.5rem',//Insert your required size
    fontFamily: 'Roboto',
    margin: '5px',
    padding: '5px',
    color: "#003D7F"
  },
}))
export default function SelectMenu(props) {
  const state = { mouseX: props.x, mouseY: props.y }
  const classes = useStyles();

  function handleClose(e, m) {
    if (props.cb) props.cb(m, e)
  };

  const mm = props.menu
  return (
    <div style={{ zIndex: 99, cursor: 'context-menu' }}>
      {/* <ClickAwayListener onClickAway={()=>handleClose("none")}> */}
      <Menu
        keepMounted
        open={props.open}
        onClose={handleClose}
        dense="true"
        autoFocus
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        {mm.map((m) =>
          <MenuItem classes={{ root: classes.primary }} key={m} onClick={(e) => handleClose(e, m)}>{m}</MenuItem>
        )}
      </Menu>
      {/* </ClickAwayListener> */}

    </div>
  );
}