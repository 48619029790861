import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import { Snackbar } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import paper from 'paper';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '16px'
    },
}))
var stars = []
var gOpen = false
function random(max) {
    return Math.floor(Math.random() * max)
}
// const images = [
//     '/snow/051-butterfly-1.png',
//     '/snow/051-butterfly-6.png',
//     '/snow/051-butterfly-9.png',
//     '/snow/001-balloon.png',
//     '/snow/001-orchid.png',
//     '/snow/033-chamomile.png',

//     '/snow/003-dahlia.png',
//     '/snow/004-sunflower.png',
//     '/snow/021-daisy.png',
//     '/snow/022-lily.png',
//     '/snow/047-anemone.png',
//     '/snow/003-dahlia.png',
// ]
const images = [
    '/snow/049-waves.png',
    '/snow/008-crab.png',
    '/snow/010-flamingo.png',
    '/snow/011-Flip Flop.png',
    '/snow/012-flower.png',
    '/snow/015-hammock.png',

    '/snow/018-island.png',
    '/snow/028-icecream.png',
    '/snow/030-sandbucket.png',
    '/snow/036-sunbed.png',
    '/snow/041-coconut.png',
    '/snow/045-turtle.png',
    '/snow/001-ball.png',
    '/snow/001-ball.png',
    '/snow/046-ukulele.png',
    '/snow/048-watermelon.png',
    '/snow/048-watermelon.png',

]
// const images = [
//     '/snow/c1.png',
//     '/snow/c2.png',
//     '/snow/c3.png',
//     '/snow/c4.png',
//     '/snow/c5.png',
//     '/snow/c6.png',
//     '/snow/c7.png',
//     '/snow/c8.png',
//     '/snow/c9.png',
//     '/snow/c10.png',
//     '/snow/c11.png',
//     '/snow/c12.png',
//     '/snow/c13.png',
//     '/snow/c14.png',
//     '/snow/c15.png',
//     '/snow/c16.png',
//     '/snow/c17.png',
//     '/snow/c17.png',

// ]
export default function MiniDrawer(props) {
    const classes = useStyles();
    const MAX_OBJ = 60
    const XSPEED = 7
    const YSPEED = 5

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function setupStars(scope) {
        stars = []
        for (var i = 0; i < MAX_OBJ; i++) {
            let img = images[random(images.length - 1)]
            scope.activate()

            var raster = new scope.Raster({ crossOrigin: 'anonymous', source: img, });
            var dat = {
                x: random(window.innerWidth),
                y: window.innerHeight + random(150),  // make this plus to go up window.innerHeight + random(150)
                xspeed: random(XSPEED) + 1,
                yspeed: random(YSPEED) + 1,
                xdir: random(2),
                img: img,
                changeCount: 20+random(20),
            }
            raster.position = new paper.Point(dat.x, dat.y)
            raster.data = dat
            stars.push(raster)
            raster.onLoad = function () {
                // this.position = new paper.Point(p, 250)
            }
            raster.onError = function (f) {
                console.log('The image not loaded.', f, img);
            };
        }
    }

    React.useEffect(() => {
        gOpen = props.open
        if (props.open) {
            var scope = new paper.PaperScope();
            scope.setup('canvas_drawing2');
            scope.activate()
            setupStars(scope)
            loop()
            function onFrame(step) {
                if (stars.length <= 0) return
                stars.forEach(s => {
                    if (s.data.xdir === 1) {
                        s.position.x -= s.data.xspeed
                    } else {
                        s.position.x += s.data.xspeed
                    }
                    s.position.y -= s.data.yspeed // make - to go down

                    if (s.position.x <= 0) {
                        s.position.x = window.innerWidth
                    }
                    if (s.position.x > window.innerWidth) {
                        s.position.x = 20
                    }
                    if (s.data.changeCount === 0) {
                        s.data.xspeed = random(XSPEED) + 1;
                        s.data.yspeed = random(YSPEED) + 1;
                        s.data.xdir = random(2);
                        s.data.changeCount = 20+random(20);
                    } else {
                        s.data.changeCount--
                    }
                })
            }
            function loop() {
                if (gOpen) {
                    window.requestAnimationFrame((step) => {
                        onFrame(step)
                        loop()
                    })
                }
            }
        }
    }, [props.open])
    return (
        <>
            { props.open ?
                <>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={props.open}
                    >
                        <Alert severity="info" className={classes.root}>
                            {/* Class is Frozen! Storm for the next 10 seconds! */}
                            {/* Spring Time! Let's take 10 seconds to stop and smell the flowers! */}
                            {/* Happy Cinco de Mayo  */}
                            It is Summer! Go out to Play. 

            </Alert>
                    </Snackbar>
                    <canvas id="canvas_drawing2" style={{ position: 'absolute', height: '100%', width: '100%', zIndex: '20' }} />
                </>
                : null}
        </>
    )
}