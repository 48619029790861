import React from 'react';
// import "./home.css"
import "./homeOver.css"
import ReactGA from 'react-ga';
import {
    Grid,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SocialIcon } from 'react-social-icons';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        backgroundColor: "#f3f8fe"
    },
    rightSide: {
        marginLeft: 'auto',
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center',
        align: 'center',
    },

    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        backgroundColor: '#FFFFFF',
        color: '#5D5770',
    },
    listText: {
        color: '#5D5770',
        alignItems: "center",
        display: 'flex',
        justifyContent: "center",
        textAlign: 'center'

    },
    listItemText: {
        fontSize: '1.2em',//Insert your required size
        fontFamily: 'Poppins',
        fontWeight: 'normal',
    }
}))
export default function AboutUs(props) {
    const classes = useStyles();

    React.useEffect(() => {
        var from = "nothing";
        if (props.location && props.location.search) {
            const s = props.location.search.split('?')
            if (s && s.length > 0) from = s[1]
        }
        ReactGA.pageview('/aboutus')
        ReactGA.event({
            category: 'AboutUs',
            action: from
        });    
    })

    return (
        <div className={classes.root}>
            <div className="" style={{ position: 'relative', top: '4vh', left: '5vh', zIndex: '200' }}>
                <Button onClick={()=>{props.history.push("/")}}>
                    <img src='/logo_title.svg' alt='whiteboard Logo' className='cursor-pointer' />
                </Button>
            </div>
            <Grid container className="ontopTeach" spacing={1} justify="center" direction="column" alignItems="center">
                <Grid item xs={12} md={6}>
                    <div className={classes.paper}>
                        <div className="AboutUsTitle">About Us</div>
                        <div className="AboutUsPara">
                            whiteboard.chat was created by Epiphani inc.<br/>
                            Epiphani is an early stage startup based in San Jose, CA.<br/>
                            We are parents of children attending elementary, middle and high schools.<br/><br/>
                            whiteboard.chat was inspired by teachers and has now evolved based on feedback
                            received from teachers around the world.
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}