import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Grow, Snackbar, Paper, Popper, MenuItem, MenuList } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
import MenuIcon from '@material-ui/icons/Menu'
import * as bUtils from './BoardUtility';
import BatchBoardExporter from "./BoardExporter";
import SendIcon from '@material-ui/icons/Send';
import * as mylocalStorage from "./mylocalStorage";

var multiExport = false
var exportBoardIDs = [
  '05a0622a-4cf4-425b-9209-d819cb302534-pgNum-1',
  '7adf3907-ec8b-47c7-a3f1-08590baa8b18-pgNum-1'
]
var exportingIndex = null;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

export default function ManageBoardMenus(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const importFile = React.useRef(null)
  const [message, setMessage] = React.useState("")
  const [exportSessionID, setExportSessionID] = React.useState(null)

  let curColor = mylocalStorage.getItem("mycolor")
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  React.useEffect(() => {
    var multiExportStr = mylocalStorage.getItem("MultiExport")
    if (multiExportStr) {
      // console.log("MultiExport = ", Boolean(multiExportStr));
      multiExport = Boolean(multiExportStr)
    }
  }, []);

  function handleImportBoard() {
    importFile.current.click();
  }

  function handleExportBoard() {
    handleToggle()
    exportingIndex = 0
    console.log("Exporting ", exportBoardIDs[exportingIndex])
    setExportSessionID(exportBoardIDs[exportingIndex])
  }

  function exportDoneCB() {
    console.log("Board exported");
    exportingIndex++
    if (exportingIndex < exportBoardIDs.length) {
      console.log("Exporting ", exportBoardIDs[exportingIndex])
      setExportSessionID(exportBoardIDs[exportingIndex])
    } else {
      setExportSessionID(null)
    }
  }

  async function loadFile(file) {
    var user = mylocalStorage.getItem('mystoreID');
    bUtils.loadFile(file, user, aniMess)
  }

  function aniMess(mess) {
    setMessage(mess)
}

  return (
    <div className={classes.root}>
      <div>
        <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className='hambBtn'
          style={{ color: curColor }}
        >
          <MenuIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleImportBoard}> <AddToQueueIcon className='mr-8' /> Import Board</MenuItem>
                    {multiExport ? <MenuItem onClick={handleExportBoard}> <SendIcon className='mr-8' /> Export Boards</MenuItem> : null }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <input type='file' id='importFile' accept=".zip,application/json,.wbc" onChange={loadFile} ref={importFile} style={{ display: 'none' }} value={""} />
      <Snackbar
                     anchorOrigin={{ vertical: "top", horizontal: "center" }}
                     open={message !== ""}
                     onClose={() => setMessage("")}
                     message={message}
                     key={"top center"}
                     autoHideDuration={3000}
                     />
      <BatchBoardExporter exportSessionID={exportSessionID} exportDoneCB={exportDoneCB}/>
    </div>
  );
}
