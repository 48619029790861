import React, { useState, Suspense } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import * as ib from './ibdata'
import { LazyBrush } from 'lazy-brush'
import { Catenary } from 'catenary-curve'
import ResizeObserver from 'resize-observer-polyfill'
//import styleVariables from '!!sass-variable-loader!./../styles/_variables.scss'
import { v4 as uuid } from "uuid";
import pako from 'pako'
import Peer from 'peerjs';
import LockIcon from '@material-ui/icons/Lock';
import PaneIcon from '@material-ui/icons/ControlCamera';
import {
    FormControlLabel, IconButton, Slider,
    Snackbar, Switch, Grid, SnackbarContent, Divider,
    Popover, Select, MenuItem, Typography, FormHelperText, Menu, ListItemIcon
} from "@material-ui/core"
import ReactGA from 'react-ga';
import paper, { Color, Path } from 'paper';
import ContextMenu from './ContextMenu'
import Point from 'catenary-curve/lib/Point';
import { useSelector, useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import PageFooter from "./PageFooter"
import jsPDF from "jspdf"
import MultiboardNav from "./MultiBoardNav"
import * as iu from "./ImageUtils"
import MiniDrawer from "./MiniDrawer"
import JitsiMeetComponent from "./SharedScreensJitsi"
import KiddieFrame from "./KiddieFrame"
import 'react-chat-widget/lib/styles.css';
import { Widget, addResponseMessage, addUserMessage, setQuickButtons } from 'react-chat-widget';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import OpacityIcon from '@material-ui/icons/Opacity';
import OverlayGuide from './OverlayGuide';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import MyRichEditor from "./MyRichEditor"
import LinkDialog from './LinkDialog';
import LoopDialog from './LoopDialog';

import ExternalWebpage from "./ExternalWebpage"
import { addStyles, EditableMathField } from 'react-mathquill'
import { ChevronLeft, ChevronRight, ControlPointSharp } from '@material-ui/icons';
import SavePaletteDialog from "./SavePaletteDialog";
import AnswerDialog from "./AnswerDialog";
import CodingDialog from "./CodingDialog";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SideBar from './SideBar.js'
import GraphCalc from "./GraphCalc.js"
import ChatSelector from "./ChatSelector.js"
import FillColorPicker from "./FillColorPicker.js"
import * as cc from "./Calc.js"
import SelectMenu from "./SelectMenu.js"
import { Radio } from '@material-ui/core';
import { svgIcons } from './svgIcons';
import PerfChecks from './PerfChecks';
import TileFactoryDialog from "./TileFactoryDialog"
import FormFactoryDialog from "./FormFactoryDialog"
// import FormResults from "./FormResults"

import TableFactoryDialog from "./TableFactoryDialog"
import PeerConnection from "./PeerConnection"
import WBCImmersiveReader from './WBCImmersiveReader';
import SpeechText from "./SpeechText";
import ReadAloudDialog from "./ReadAloudDialog"
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import YesNoDialog from "./YesNoDialog"
import clsx from "clsx";
import FT from "fractional"
import * as microsoftTeams from "@microsoft/teams-js";
import { whiteboardChatHomeURL } from './App';
import * as bUtils from './BoardUtility'

import * as mylocalStorage from "./mylocalStorage"
import GifImage from './GifImage';
import JSZip from 'jszip'
import { saveAs } from 'file-saver';
import AutoCorrectModal from "./AutoCorrectModal";
import WebsiteAlertMessage from "./WebsiteAlertMessage";
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import HandRaise from './HandRaise';
import ClassTimer from "./ClassTimer";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CallIcon from '@material-ui/icons/Call';
import BoardColorPicker from './BoardColorPicker'
import PanoramaIcon from '@material-ui/icons/Panorama';
import Draggable from 'react-draggable';
import GridBrowser from './GridBrowser';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ShowCaseDialog from './ShowCaseDialog';
import { Lang } from "./hand/handLang.js"
const Fractional = FT.Fraction;
var engagementScore = null;
const HandWriteDialog = React.lazy(() => import('./hand/HandWriteDialog'));
const MagicWriteDialog = React.lazy(() => import('./hand/MagicWriteDialog'));

const Xylophone = React.lazy(() => import('./piano/Xylophone'));
const Piano = React.lazy(() => import('./piano/Piano'));

const FormResults = React.lazy(() => import('./FormResults'));
const EngagementResults = React.lazy(() => import('./EngagementResults'));
const DiceRoll = React.lazy(() => import('./DiceRoll'));
const NestedGrid = React.lazy(() => import('./NestedGrid'));
const MoleculeEditor = React.lazy(() => import('./molecule/MoleculeEditor'));
var apiBoardCreated = false

// import *  as lat from "./latency.js"
const styleVariables = {
    'colorPrimary': "#f2530b",
    'colorOthers': "#3da3db",
    'bgColor': "#ffffff",
    'toolColor': "#E5E5E5"
}

const profilesMap = {
    "daVinci": true,
    "VanGogh": true,
    "Rembrandt": true,
    "Monet": true,
    "Michelangelo": true,
    "Picasso": true,
    "Matisse": true,
    "Frida": true,
}

const profiles = Object.keys(profilesMap);

const verbsMap = {
    "Calm": true,
    "Content": true,
    "Joyful": true,
    "Tranquil": true,
    "Gloomy": true,
    "Dark": true,
    "Exciting": true,
    "Natural": true,
    "Clear": true,
    "Abstract": true,
    "Lively": true,
    "Vivid": true,
    "Intense": true,
}

const verbs = Object.keys(verbsMap);

export const epiColors = [
    "#003D7F",
    "#EF6C35",
    "#004899",
    "#F4B400",
    "#71C001",
    "#9F8FCE",
    "#F0728A",
    "#EF6C35",
    "#ED7D31",
    "#4A86E3",
    "#913791",
    "#0F9D58",
    "#882ff6",
    "#3174F5",
]
const defaultFrameColor = "#3174F5"
var worker = new Worker(process.env.PUBLIC_URL + '/tex2svg.js');
var maxWidth = 2000
var maxHeight = 1280
var smallMaxWidth = 1440
var smallMaxHeight = 1280
var mobileWidth = 1000
var mobileHeight = 800
const initObj = { id: null, ended: false, content: null, created: false }
const defaultObj = { id: null, ended: false, content: null, created: false }
var session = null
var myObj = initObj
var objDict = {}
const defaultPeer = { connection: null, id: null, oldmouse: null }
var peers = {}
var myPeerID = null
var debounceTimer = null
export var myName = "noone"
var gSessionID = null
var gParentSession = null
var gLocked = false
var onceMessage = false
var paperObj = {}
var rotateObj = {}
var colorObj = {}
var moveObjs = {}
var animatePath = null
var gpause = false
var aniMessage = false
var gUser = null
var drawGridCfg = null
var ggridView = { open: false }
var gMultiBoard = { multiBoardOption: false, participantsSeeEachOther: false, amIParent: false, parentID: null }
var lastTap = 0;
var dtTimeout
var savedLocalUsers = {}
var lastText;
var lastObjDraw = false;
var lassoPath = null
var renderedMessages = {}
var onceGA = true
var keepaliveTimer = null;
var gSession = null;
var renamed = false
var selectedObj = null
var gGrid = null
var gTables = 0
var gBackGround = null
var tourType = null
var gTeacher = 0
var boundBRect = null
var defaultTool = "draw"
var boundRect = null
var debouceRect = null
var defaultTimer = null
var gBoardConfig
var gSyncDisabled = false
var compassSelect
var mycompass
var clockSelect
var sessClockWidgets = 0
var gUsers = []
var gButtons = []
var gExt = false
var spinnerSelect
var sessSpinnerWidgets = 0
var buttonClicks = 0
var buttonClicked
var gEditingSpinner = null
var gText = 5
var gEnter = false;
var gSpeechText = false;
var contorlButtonClick = false
var observeCanvas;
var observeSidebar;
var lastObjTS
var gGamePlay = null // {id: xxx, timer:xxx, obj: xxx}
var savedObj = []
var savePos = null
var snake = { obj: null }
var gStopLight = {}
var gStopLightParent = {}
var gTextBox = {}
var hasMathInput = false
var inpBox = null
var inpMath = null
var hasInput = false
var inValue = null
var sessFDiceWidgets = 0
var fdiceSelect = null
var gEditingFdice = null
var gCtx = {}
var stars = []
var clockSaving = false
var fdiceSaving = false
var spinnerSaving = false
var gEditingClock = null
var clock_type = ["free-hand-clock", "gear-clock"];
var tileBox = { x: 150, y: 120 }
var formBox = { x: 150, y: 120 }
var gDropZone = {}
var gridPainter = {}
var gResolution = "default"
var gBoardTools = {}
var gPeer = null
var gShadeObj = null
var gRBeadObj = null
var gZoomEnabled = true
const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
var myRect = { obj: null, start: null, end: null }
var gLoadMaps = {}
var magicDrawObj = {}
var gPanned = false
var gScrollFollow = null
var positionTimer = null
class Ink {
    constructor(xcoords, ycoords, times) {
        this.xcoords = xcoords;
        this.ycoords = ycoords;
        this.times = times;
    }
}

const SNAKESIZE = 20
const DEFTOOLCURSOR = {
    "draw": "auto",
    "edit": "crosshair",
    "moveResize": "move",
    "selectAnimate": "pointer",
}

const DEFAULT_SPIN_SPEED = 25
const DEFAULT_ROLL_SPEED = 25
var gShowcase = null
var evCache = []
var prevDiff = -1;
const WBSlider = withStyles({
    root: {
        paddingTop: '6px',
        paddingBottom: '6px',
        color: '#ffffff',
    },
    thumb: {
        backgroundColor: "#fff",
        color: "#3174F5",
    },
})(Slider);

function mkColor(color, opacity) {
    if (!opacity) return color;
    if (color && !color.includes("#")) return color;
    const onlyColor = color && color.substr(0, 7);
    const alpha = (Math.floor(255 * opacity / 100) + 0x100).toString(16).substr(-2).toUpperCase()
    return onlyColor + alpha;
}

function getElPosition(el) {
    var xPos = 0;
    var yPos = 0;

    while (el) {
        if (el.tagName == "BODY") {
            // deal with browser quirks with body/window/document and page scroll
            var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
            var yScroll = el.scrollTop || document.documentElement.scrollTop;

            xPos += (el.offsetLeft - xScroll + el.clientLeft);
            yPos += (el.offsetTop - yScroll + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }

        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
}

function SpinnerConfigPanelHelp(props) {
    if (props.participantsSpinner) {
        return (
            <>
                <div>Spinner Automatically Includes Student names.</div>
                <div>Optionally you may customize colors of slices.</div>
                <div>Slice color,Text color</div>
                <div>E.g</div>
                <div>Red</div>
                <div>Green</div>
                <div>Yellow, Black</div>
            </>
        )
    } else {
        return (
            <>
                <div>Enter Possible Spinner Results. One per line.</div>
                <div>Result,Color,TextColor (Colors are optional)</div>
                <div>E.g</div>
                <div>Apple, Red, White</div>
                <div>Banana, Yellow, Black</div>
                <div>Cherry, HotPink, Purple</div>
            </>
        )
    }
}

function SpinnerConfigPanel(props) {
    function handleSyncStudentSpins(event) {
        props.setSpinnerSyncStudentSpins(event.target.checked)
    }

    function handleStudentsCanSpin(event) {
        props.setSpinnerStudentsCanSpin(event.target.checked)
    }

    return (
        <div id="spinnerConfigPanel" className="spinnerConfigPanel"
            style={{
                display: props.spinnerConfigPanelOpen ? "block" : "none",
                border: '2px solid ' + props.inkColor,
                top: props.spinnerConfigPanelPos.top, left: props.spinnerConfigPanelPos.left
            }}>
            <div style={{ padding: '10px', width: '100%', userSelect: 'text' }}>
                <SpinnerConfigPanelHelp {...props} />
                <Divider />
                <TextField
                    id="spinnerResultsTextField"
                    multiline
                    rowsMax={10}
                    value={props.participantsSpinner ? props.spinnerParticipantsColorsStr : props.spinnerResultsValuesStr}
                    placeholder={""}
                    onChange={props.participantsSpinner ? props.handleSpinnerParticipantsColorChange : props.handleSpinnerResultsChange}
                    fullWidth={true}
                    inputProps={{ style: { fontSize: 18, lineHeight: 1.2 } }}
                />
            </div>
            <Grid container direction="column" justify='flex-start' style={{ marginLeft: '0.65rem' }}>
                <FormControlLabel control={
                    <Switch
                        checked={props.spinnerStudentsCanSpin}
                        onChange={handleStudentsCanSpin}
                        name="Students can spin"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color='secondary'
                    />}
                    label="Students can spin"
                />
                <FormControlLabel control={
                    <Switch
                        checked={props.spinnerSyncStudentSpins}
                        onChange={handleSyncStudentSpins}
                        name="Sync Student Spins"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color='secondary'
                        disabled={!props.spinnerStudentsCanSpin}
                    />}
                    label="Sync Student Spins"
                />
            </Grid>
            <Grid container direction='row' justify='center'>
                <Button onClick={() => props.closeSpinnerConfigPanel()}>Close</Button>
                <Button onClick={() => {
                    props.setSpinnerResultsValuesStr(p => "")
                    props.setSpinnerParticipantsColorsStr(p => "")
                    props.setSpinnerSyncStudentSpins(false)
                    props.setSpinnerStudentsCanSpin(true)
                }}>Clear</Button>
                {props.spinnerResultsValuesStr === "" ?
                    <Button onClick={() => {
                        props.setSpinnerResultsValuesStr(p => props.spinnerDefaultsStr)
                        props.setSpinnerParticipantsColorsStr(p => props.spinnerParticipantsColorsDefaultsStr)
                        props.setSpinnerSyncStudentSpins(false)
                        props.setSpinnerStudentsCanSpin(true)
                    }}>Default</Button> :
                    <Button onClick={() => props.updateSpinner()} disabled={props.spinnerResultsValuesStr === ""}>Update</Button>
                }
            </Grid>
        </div>
    )
}

function FdiceConfigPanel(props) {

    function handleSyncStudentRolls(event) {
        props.setFdiceSyncStudentRolls(event.target.checked)
    }
    function handleStudentsCanRoll(event) {
        props.setFdiceStudentsCanRoll(event.target.checked)
    }

    const handleNumberOfDiceChange = (event) => {
        props.setFdiceNumberOfDice(Number(event.target.value))
    }

    return (
        <div id="fdiceConfigPanel" className="fdiceConfigPanel"
            style={{
                display: props.fdiceConfigPanelOpen ? "block" : "none",
                border: '2px solid ' + props.inkColor,
                top: props.fdiceConfigPanelPos.top, left: props.fdiceConfigPanelPos.left
            }}>
            <div style={{ padding: '10px', width: '100%' }}>
                <div> Choose number of dice</div>

                <Divider />

            </div>
            <Grid container alignItems="center">
                {/* <Grid item xs={4}>
                        <div style={{paddingRight: '0px',paddingLeft:'10px'}}>Number of dice:</div>
                    </Grid> */}
                <Grid item style={{ paddingRight: '0px', paddingLeft: '10px' }}>
                    {['1', '2', '3', '4'].map((i) => {
                        return (
                            <React.Fragment key={"nDiceRadio" + i}>
                                {i}
                                <Radio
                                    checked={props.fdiceNumberOfDice === Number(i)}
                                    onChange={handleNumberOfDiceChange}
                                    value={i}
                                    name="nDiceRadio"
                                    inputProps={{ 'aria-label': i }}
                                    size="small"
                                    style={{ marginLeft: '-5px', marginRight: '5px' }}
                                />
                            </React.Fragment>
                        )
                    })
                    }
                </Grid>
            </Grid>
            <div style={{ padding: '10px', width: '100%', userSelect: 'text' }}>
                <div> Enter comma separated possible Dice face values.</div>
                <div> Values can be fractions, integers or letters.</div>
                <div> Optionally add dice colors after a semicolon.</div>
                <div> E.g</div>
                <div>1/2, 1/3, 1/4, 1/6, 1/8, 1/12; gold, orchid, orange, limegreen; red, white, black, yellow</div>
                <Divider />
                <TextField
                    id="fdiceResultsTextField"
                    multiline
                    rowsMax={10}
                    value={props.fdiceResultsValuesStr}
                    placeholder={""}
                    onChange={props.handleFdiceResultsChange}
                    fullWidth={true}
                    inputProps={{ style: { fontSize: 18, lineHeight: 1.2 } }}
                />
            </div>
            <Grid container direction="column" justify='flex-start' style={{ marginLeft: '0.65rem' }}>
                <FormControlLabel control={
                    <Switch
                        checked={props.fdiceStudentsCanRolls}
                        onChange={handleStudentsCanRoll}
                        name="Students can roll"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color='secondary'
                    />}
                    label="Students can roll"
                />
                <FormControlLabel control={
                    <Switch
                        checked={props.fdiceSyncStudentRolls}
                        onChange={handleSyncStudentRolls}
                        name="Sync Student Roll"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color='secondary'
                        disabled={!props.fdiceStudentsCanRolls}
                    />}
                    label="Sync Student Roll"
                />
            </Grid>
            <Grid container direction='row' justify='center'>
                <Button onClick={() => props.closeFdiceConfigPanel()}>Close</Button>
                <Button onClick={() => {
                    props.setFdiceResultsValuesStr(p => "")
                    props.setFdiceSyncStudentRolls(true)
                    props.setFdiceStudentsCanRoll(true)
                }}>Clear</Button>
                {(!props.fdiceResultsValuesStr || props.fdiceResultsValuesStr.replace(/\s/g, '') === "") ?
                    <Button onClick={() => {
                        props.setFdiceResultsValuesStr(p => props.fdiceDefaultsStr)
                        props.setFdiceSyncStudentRolls(true)
                        props.setFdiceStudentsCanRoll(true)
                    }}>Default</Button> :
                    <Button onClick={() => props.updateFdice()} disabled={props.fdiceResultsValuesStr === ""}>Update</Button>
                }
            </Grid>
        </div>
    )
}


function ClockConfigPanel(props) {
    function handleSetDefaultClockType(event) {
        props.setClockChanged(false)
        props.setClockUpdatedType(clock_type[0])
    }
    function handleSetClockType(event) {
        props.setClockChanged(event.target.checked)
        var ctype = event.target.checked ? clock_type[1] : clock_type[0]
        props.setClockUpdatedType(ctype)
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);
    return (
        <div id="clockConfigPanel" className="clockConfigPanel"
            style={{
                display: props.clockConfigPanelOpen ? "block" : "none",
                border: '2px solid ' + props.inkColor,
                top: props.clockConfigPanelPos.top, left: props.clockConfigPanelPos.left
            }}>
            <div style={{ padding: '10px', width: '100%' }}>
                <div> Choose type of the clock</div>

                <Divider />

            </div>
            <Grid container direction="column" justify='flex-start' style={{ marginLeft: '0.65rem' }}>
                <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>Free hand clock</Grid>
                    <Grid item>
                        <AntSwitch checked={props.clockChanged}
                            onChange={handleSetClockType}
                            name="clocktype"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            color='default' />
                    </Grid>
                    <Grid item>Geared clock</Grid>
                </Grid>
            </Grid>
            <Grid container direction='row' justify='center'>
                <Button onClick={() => props.closeClockConfigPanel()}>Close</Button>
                {/* <Button onClick={() => {
                    props.setClockUpdatedType(p => props.clockDefaultType)
                }}>Clear</Button> */}
                <Button onClick={() => {
                    handleSetDefaultClockType()
                }}>Default</Button>
                <Button onClick={() => props.updateClock()} >Update</Button>

            </Grid>
        </div>
    )
}

addStyles();
const useStyles = makeStyles((theme) => ({
    customTooltip: {
        // I used the rgba color for the standard "secondary" color
        backgroundColor: "#1E2639",
        fontSize: "0.8rem",
        fontFamily: 'Roboto',
        fontWeight: 'normal',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16)'
    },
    customArrow: {
        color: "#1E2639",
    }
    ,
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        zIndex: 105,
        position: "absolute",
        color: "#ffffff",
        backgroundColor: "#ffffff",
        right: 'calc(100% - 35%)',
        top: '0'
    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        paddingBottom: "5px",
        top: 20,
        left: 0,
        backgroundColor: "#ffffff"
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1",
        height: '10px',
        backgroundColor: '#E2E6E9'
    },
    draggableTopCloseButton: {
        display: "flex"
    },
}));

const useStylesCard = makeStyles({
    root: {
        width: '400px',
        height: 'fit-content',
    },
    title: {
        fontfamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '20px',
        color: '#1E2639',
        margin: '24px 32px',
        textAlign: 'left'
    },
    content: {
        fontSize: '0.55rem',
    },
    media: {
        height: 140,
    },
    sublbl: {
        fontfamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#1E2639',
        textAlign: 'left'
    },
    tlml: {
        textAlign: 'left',
        margin: '4px 25px'
    },
    bb: {
        margin: '0 25px',
        display: 'flex',
        justifyContent: 'space-between'
    }
});

let newXOff = 80
let newYOff = 120

function WhiteBoard(props) {
    const classes = useStyles();
    const [sess, setSession] = React.useState(null);
    const [ctx, setCtx] = React.useState(gCtx)
    const [ctxMenu, setCtxMenu] = React.useState({ open: false, x: 0, y: 0 })
    const [selectMenu, setSelectMenu] = React.useState({ open: false, x: 0, y: 0 })

    var [user, setUser] = React.useState(null)

    const [scrollL, setScrollL] = React.useState(true)
    const [eraseMode, seteraseMode] = React.useState(false)
    const [drawMode, setdrawMode] = React.useState({ name: "draw" })
    const [inkColor, setInkColor] = React.useState(defaultFrameColor);
    const [sliderCollapsed, setSliderCollapsed] = React.useState(false);

    const [pauseMode, setPauseMode] = React.useState(false)
    const [authBoardOnly, setAuthBoardOnly] = React.useState(false)
    const [multBoardCfg, setMultiBoardCfg] = React.useState(gMultiBoard)
    const [gridView, setgridView] = React.useState(ggridView)
    const [gridOptions, setGridOptions] = React.useState(null)

    const [undoStack, setUndoStack] = React.useState([]);
    const [redoStack, setRedoStack] = React.useState([]);
    const [undoing, setUndoing] = React.useState([]);
    const [redoing, setRedoing] = React.useState([]);
    const [undoKeyPressed, doUndo] = React.useState(false);
    const [redoKeyPressed, doRedo] = React.useState(false);
    const [undoIconDisabled, setUndoIconDisabled] = React.useState(true);
    const [redoIconDisabled, setRedoIconDisabled] = React.useState(true);
    // const [highlighterMode, setHighlighterMode] = React.useState(false);
    const [currentOpacity, setCurrentOpacity] = React.useState(100);
    const [lineStyle, setLineStyle] = React.useState(null);
    const [currentBrush, setCurrentBrush] = React.useState(3);
    const [overlayHelpOpen, setOverlayHelpOpen] = React.useState(true);
    const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);
    const [extWebpages, setExtWebpages] = React.useState({});
    const [mathInput, setMathInput] = React.useState(null);
    const [mathQuillContext, setMathQuillContext] = React.useState(null);
    const [mathSvg, setMathSvg] = React.useState(null);

    const [spinnerConfigPanelOpen, setSpinnerConfigPanelOpen] = React.useState(false);
    const [spinnerConfigPanelPos, setSpinnerConfigPanelPos] = React.useState({ top: 100, left: 100 })
    const [spinnerResultsValuesStr, setSpinnerResultsValuesStr] = React.useState("")
    const [spinnerParticipantsColorsStr, setSpinnerParticipantsColorsStr] = React.useState("")
    const [spinnerSyncStudentSpins, setSpinnerSyncStudentSpins] = React.useState(false)
    const [spinnerStudentsCanSpin, setSpinnerStudentsCanSpin] = React.useState(true)
    const [participantsSpinner, setParticipantsSpinner] = React.useState(undefined)
    const spinnerEvent = useSelector((state) => state.spinnerEvt)
    const boardTools = useSelector((state) => state.boardTools)
    const showCase = useSelector((state) => state.showCase);
    const diceShow = useSelector((state) => state.diceShow);

    const timeMachine = useSelector((state) => state.timeMachine)

    const personalConfig = useSelector((state) => state.personalConfig);

    const authDialog = useSelector((state) => state.authDialog);
    const teacherR = useSelector((state) => state.teacher);
    const syncDisabled = useSelector((state) => state.syncDisabled);

    const richText = useSelector((state) => state.richText);
    const boardLocked = useSelector((state) => state.boardLocked);
    const pageLocked = useSelector((state) => state.pageLocked);

    const dispatch = useDispatch();

    const [message, setMessage] = React.useState("")
    const authUser = useSelector((state) => state.user);
    const noUser = useSelector((state) => state.noUser);

    const boardConfig = useSelector((state) => state.BoardConfig);
    const tab = useSelector((state) => state.tab);
    const countryCode = useSelector((state) => state.countryCode);
    const boardChat = useSelector((state) => state.boardChat);
    const [userRole, setUserRole] = useState(null)
    const [paletteDialog, setPaletteDialog] = React.useState({ open: false, obj: null, cb: null })
    const [answerDialog, setAnswerDialog] = React.useState({ open: false, obj: null, cb: null })
    const [codingDialog, setCodingDialog] = React.useState({ open: false, obj: null, cb: null })

    const [chatSelector, setChatSelector] = React.useState({ open: false, cb: null })

    const fdiceEvent = useSelector((state) => state.fdiceEvt)
    const [fdiceSyncStudentRolls, setFdiceSyncStudentRolls] = React.useState(false)
    const [fdiceStudentsCanRolls, setFdiceStudentsCanRoll] = React.useState(true)
    const [fdiceConfigPanelOpen, setFdiceConfigPanelOpen] = React.useState(false);
    const [fdiceConfigPanelPos, setFdiceConfigPanelPos] = React.useState({ top: 500, left: 500 })
    const [fdiceResultsValuesStr, setFdiceResultsValuesStr] = React.useState("")
    const [fdiceDefaultValuesStr, setFdiceDefaultValuesStr] = React.useState("")
    const [fdiceNumberOfDice, setFdiceNumberOfDice] = React.useState(1)
    const [clockConfigPanelOpen, setClockConfigPanelOpen] = React.useState(false);
    const [clockUpdatedType, setClockUpdatedType] = React.useState("")
    const [clockConfigPanelPos, setClockConfigPanelPos] = React.useState({ top: 500, left: 500 })
    const [clockChanged, setClockChanged] = React.useState(false)

    const [IRContent, setIRContent] = React.useState(null)
    const [autoCorrect, setAutoCorrect] = React.useState(false);
    const [websiteAlertMessage, setWebsiteAlertMessage] = React.useState(false);

    const participants = useSelector((state) => state.participants);
    const [showTextTools, setShowTextTools] = React.useState(false)
    const [tabMode, setTabMode] = React.useState('Single View')
    const [openTip, setopenTip] = React.useState(false)
    const palletDrawer = useSelector((state) => state.palletDrawer);
    const xpos = 2
    const ypos = window.innerHeight - 480
    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: ypos });
    const gridBrowser = useSelector((state) => state.gridBrowser);
    const [icoDisable, setIcoDisable] = React.useState({ manageboard: false, clear: false })
    const [parentBoardData, setParentBoardData] = useState([])
    const [isBorderSet, setIsBorderSet] = React.useState(false)
    const [openSimpleTools, setOpenSimpleTools] = React.useState(true)

    //initialize jsZip
    var zip = new JSZip();
    const [penBtnColor, setPenBtnColor] = React.useState(defaultFrameColor);
    function handleQuickButtonClicked(b) {
        setChatSelector({ open: true, cb: done, classroom: sess.Classroom })
        function done(e) {
            setChatSelector({ open: false, cb: null, classroom: null })
            setButtonsChat()
        }
    }
    React.useEffect(() => {
        if ((!Boolean(teacherR) && boardConfig.simpleDrawingTools) || (!Boolean(teacherR) && boardConfig.fourToolsStudent)) {
            setOpenSimpleTools(false)
        }
        else {
            setOpenSimpleTools(true)
        }
    }, [teacherR, boardConfig])

    React.useEffect(() => {
        if (showCase.dialog) {
            gShowcase = showCase
        } else {
            gShowcase = null
        }
    }, [showCase])

    function setButtonsChat() {
        if (teacherR !== 0) {
            var rr = mylocalStorage.getItem("chatParticipants")
            if (rr) {
                var rl = JSON.parse(rr)
                if (rl.classroom !== sess.Classroom) {
                    mylocalStorage.removeItem("chatParticipants")
                    rr = null
                }
            }

            var def = "people on this board"
            if (sess && sess.Classroom && sess.parentID === sess.Classroom) {
                def = "whole class"
            }
            if (rr) def = "selected"
            const buttonsQ = [{ label: "recipients:" + def, value: "abc" }]
            setQuickButtons(buttonsQ)
        }
    }
    function handleNewUserMessage(newMessage) {
        var luid = mylocalStorage.getItem('mystoreID');
        var event = sess.Classroom + "-" + sess.parentID
        var jj = {
            action: "Chat", msg: newMessage, from: user, luid: luid,
            isTeacher: Boolean(teacherR), sessName: sess.name
        }
        var forWho = sess.parentID
        if (Boolean(teacherR)) {
            var rr = mylocalStorage.getItem("chatParticipants")
            if (rr) {
                var rl = JSON.parse(rr)
                if (rl.classroom !== sess.Classroom) {
                    mylocalStorage.removeItem("chatParticipants")
                } else {
                    jj['filter'] = rl.rec
                    forWho = sess.Classroom
                }
            }
        }
        var cmd = {
            event: event, Classroom: sess.Classroom, ttl: sess.ttl,
            type: "Chat", State: "Sent", Content: JSON.stringify(jj)
        }
        cmd['For'] = forWho
        ib.createClassroomEvent(cmd).then((r) => {
            const pp = r.data.createClassroomEvent
            if (pp) {
                renderedMessages[pp.id] = true
            }
        })
        if (teacherR === 0) {
            var copy = JSON.parse(JSON.stringify(cmd))
            copy['For'] = sess.Classroom // another one for the teachers 
            ib.createClassroomEvent(copy).then((r) => {
                const pp = r.data.createClassroomEvent
                if (pp) {
                    renderedMessages[pp.id] = true
                }
            })
        };
    }

    function handleBoardRename(name) {
        renamed = true;
        setSession(p => {
            p.name = name;
            gSession = { ...p };
            ib.setName(p, name)
            return gSession;
        })
    }

    React.useEffect(() => {
        ReactGA.event({
            category: 'User',
            action: 'InstanceUsed',
            label: window.location.host
        });

        let border = mylocalStorage.getItem("backgroundFrame")
        if (border) {
            setIsBorderSet(true)
        } else {
            setIsBorderSet(false)
        }
        if (worker) {
            worker.onmessage = function (e) {
                // Call to create math svg
                setMathSvg(e.data.svg);
                setMathInput(null);
            }
        }
    }, [])
    React.useEffect(() => {
        if (mathInput && mathInput.inputValue) {
            if (mathInput.sendInput === true) {
                hasMathInput = false
                // Get the svg of the current latex input value
                worker.postMessage({
                    formula: mathInput.inputValue,
                    display: true,
                });
            }
        }
    }, [mathInput])
    React.useEffect(() => {
        if (mathSvg) {
            if (mathInput && mathInput.x && mathInput.y) {
                drawSvg(mathSvg, mathInput.x, mathInput.y);
            }
            if (mathInput && mathInput.cb) {
                mathInput.cb(mathSvg)
            }
            // Set to null so user can put the same math svg again
            setMathSvg(null);
        }
    }, [mathSvg])

    React.useEffect(() => {
        if (gridView && gridView.open) {
            removeAllButtons()
        }
    }, [gridView])

    React.useEffect(() => {
        var luid = mylocalStorage.getItem('mystoreID');
        setButtonsChat()
        for (var i = 0; i < boardChat.messages.length; i++) {
            const m = boardChat.messages[i]
            if (m.id in renderedMessages) continue
            var gg = ""
            if (m.Content.sessName && m.Content.sessName.indexOf("group-") !== -1) {
                gg = m.Content.sessName.replace("group-", "")
                gg = "[" + gg + "] "

            }
            if (m.Content && m.Content.action === "Chat") {
                if (m.Content.luid === luid) {
                    addUserMessage(m.Content.msg)
                } else {
                    var f = m.Content.from && m.Content.from.name ? m.Content.from.name + ":" : ""
                    addResponseMessage(gg + f + m.Content.msg)
                }
            }
            renderedMessages[m.id] = true

        }
    }, [boardChat, teacherR])

    React.useEffect(() => {
        if (!paper || !spinnerEvent || !gUser) return;
        const spinner = paper.project.getItem({
            data: function (data) {
                return (data.spinnerType && data.spinnerType === "spinner" && data.spinnerId === spinnerEvent.Content.spinnerId)
            }
        })
        var luid = mylocalStorage.getItem('mystoreID');
        if (spinner && (spinner.data.syncStudentSpins || spinnerEvent.Content.spunBy === luid || spinnerEvent.Content.isTeacher))
            spinSpinner(spinner, spinnerEvent)
    }, [spinnerEvent])

    React.useEffect(() => {
        if (!paper || !fdiceEvent || !gUser) return;
        const fdice = paper.project.getItem({
            data: function (data) {
                return (data.fdiceType && data.fdiceType === "fdice" && data.fdiceId === fdiceEvent.Content.fdiceId)
            }
        })
        var luid = mylocalStorage.getItem('mystoreID');
        if (fdice && (fdice.data.syncStudentFdice || fdiceEvent.Content.rolledBy === luid || fdiceEvent.Content.isTeacher))
            rollFDice(fdice, fdiceEvent)
    }, [fdiceEvent])

    const LAZY_RADIUS = 0
    const BRUSH_RADIUS = 2
    const SCROLLMODE_DRAW = true
    const mobile = (typeof window.orientation !== 'undefined')
    const minWidthForTour = 700
    var ua = window.navigator.userAgent;
    var smallScreen = false
    //CrOS
    if (onceGA && (ua.indexOf("CrOS") !== -1) && window.screen.width < 1280) {
        //low end chomebook
        onceGA = false
        ReactGA.event({
            category: 'ChomeOS',
            action: "RESO" + window.screen.width
        });
        maxWidth = smallMaxWidth
        maxHeight = smallMaxHeight
    } else {
        onceGA = false
    }
    if (mobile) {
        if (window.screen.width < 500) {
            maxWidth = mobileWidth
            maxHeight = mobileHeight
            smallScreen = true
        }
    }
    function setMyObj() {
        myObj = JSON.parse(JSON.stringify(defaultObj))
    }
    function clearWidgetCount() {
        sessClockWidgets = 0
        sessSpinnerWidgets = 0
        sessFDiceWidgets = 0
    }
    function clearGlobal() {
        paperObj = {}
        rotateObj = {}
        colorObj = {}
        moveObjs = {}
        objDict = {}
        tileBox = { x: 150, y: 120 }
        formBox = { x: 150, y: 120 }
        gridPainter = {}
        gGrid = null
        gBackGround = null
        dispatch(Actions.setBackGround(null))
        clearWidgetCount()
        setMyObj()
    }
    var inkColorDebounceTimer = null
    function setInkColorDebounced(col) {
        if (inkColorDebounceTimer !== null) {
            clearTimeout(inkColorDebounceTimer);
            inkColorDebounceTimer = null;
        }
        inkColorDebounceTimer = setTimeout(function () {
            // console.log("Setting color to = ", col);
            if (col !== "#ffffff") {
                setInkColor(p => col);
                mylocalStorage.setItem("savedInk", col)
            }
            clearTimeout(inkColorDebounceTimer);
            inkColorDebounceTimer = null;
        }, 200);
    }
    window.onload = function () {
        //paper.install(window);
        //  paper.view.onFrame = onFrame
    }
    if (authBoardOnly && !authDialog.open && !authUser) {
        function signedIn() {
            dispatch(Actions.openAuthDialog({ open: false, siCB: null }))
        }
        dispatch(Actions.openAuthDialog({ open: true, siCB: signedIn }))

    }

    function findcreatelocalUser() {
        var authid = authUser && authUser.username ? authUser.username : null
        var luid = mylocalStorage.getItem('mystoreID');
        if (!luid) {
            luid = uuid()
            mylocalStorage.setItem('mystoreID', luid)
        }
        if (authid && luid !== authid) {
            mylocalStorage.setItem('mystoreID', authid)
            mylocalStorage.setItem('backupLocalID', luid);
            luid = authid //from now on the authenicated user and the stored user will be same
        }
        return luid
    }
    function updateEngagement(type) {
        if (!engagementScore) engagementScore = JSON.parse(JSON.stringify(ENGAGEMENTDEFAULT))
        engagementScore[type]++
    }
    function findUpdateLocalUserIfNeeded() {
        /**
         * Since this is called from useEffect for handling
         * user timeouts, we can no longer use sess, instead
         * we have to use a global session object (gSession).
         */
        if (gSession && gSession.Classroom) {
            var luid = findcreatelocalUser()
            const localId = luid + "-CL-" + gSession.Classroom
            ib.getLocalUsers(localId).then((dat) => {
                // var sp = document.getElementById('canvas_container')
                // var win = {height: window.innerHeight, width: window.innerWidth, sy: sp.scrollTop, sx: sp.scrollLeft}
                const lu = dat.data.getLocalUsers
                if (!lu) {
                    const userid = gUser ? gUser.id : null
                    if (userid) {
                        ib.createLocalUsers({
                            id: localId, CurrentSessionId: gSessionID,
                            CurrentUserId: userid, ClassroomID: gSession.Classroom,
                            ttl: gSession.ttl
                        })
                    }
                } else {
                    ib.updateLocalUsers({ id: localId, CurrentSessionId: gSessionID, CurrentUserId: gUser.id })
                }
            })
        }
    }
    const [roname, Setroname] = React.useState({})
    React.useEffect(() => {
        var updateSession = false
        gBoardConfig = JSON.parse(JSON.stringify(boardConfig))
        if (sess && authUser) {
            if (sess.savedOwner === null) {
                //who created this?
                if (sess.CreatorLocalID && sess.CreatorLocalID === findcreatelocalUser()) {
                    sess.savedOwner = authUser.username
                    updateSession = true
                }
                if (!sess.CreatorLocalID) {
                    sess.savedOwner = authUser.username
                    updateSession = true
                }
            }
            if (sess.id !== sess.parentBoardID && authUser.attributes && authUser.attributes.name) {
                Setroname({ 'readonly': 'true' })
            } else {
                Setroname({})
            }
        }
        if (sess && boardConfig && authUser) {
            const jp = JSON.stringify(boardConfig)
            if (sess.boardConfig !== jp && sess.savedOwner === authUser.username) {
                if (boardConfig.multiBoardOption) {
                    if (!sess.Classroom) {
                        const pgSplit = sess.parentBoardID.split("-pgNum-")
                        if (pgSplit[0] === sess.parentID) {
                            //here 
                            ib.createClassroom({ id: sess.parentID, TeacherID: authUser.username, name: "myclass", ttl: sess.ttl })
                            sess.Classroom = sess.parentID
                            findUpdateLocalUserIfNeeded() // for the teacher
                        }
                    }
                }
                if (sess.parentBoardID === sess.id) {
                    //only allowed for non inherited boards 
                    var oc = {}
                    try {
                        oc = JSON.parse(sess.boardConfig)
                    } catch { }
                    var nc = { ...oc, ...boardConfig }
                    if (sess && sess.Classroom) nc.multiBoardOption = true
                    var rr = JSON.stringify(nc)
                    var f1 = ib.clearUndefined(nc)
                    var f2 = ib.clearUndefined(JSON.parse(sess.boardConfig))
                    if (!ib.deepCompare(f1, f2)) {
                        sess.boardConfig = rr
                        updateSession = true
                    }
                }
            }
        }

        if (sess && sess.boardConfig) {
            function getPrevPage(pnum) {
                var cp = pnum
                var prevPage = pnum - 1
                if (sess && sess.boardConfig) {
                    try {
                        var bc = JSON.parse(sess.boardConfig)
                    } catch { }

                    if (bc.boardOrder) {
                        for (let l in bc.boardOrder.order) {
                            if (bc.boardOrder.order[l] === pnum) {
                                cp = l
                            }
                        }
                        var requestedID = bc.boardOrder.order[cp - 1]
                        if (requestedID) return requestedID
                    }
                }
                return prevPage
            }
            function getMapPage(pnum) {
                if (sess && sess.boardConfig) {
                    try {
                        var bc = JSON.parse(sess.boardConfig)
                    } catch { }

                    if (bc.boardOrder) {
                        for (let l in bc.boardOrder.order) {
                            if (bc.boardOrder.order[l] === pnum) {
                                return l
                            }
                        }
                    }
                }
                return pnum
            }
            const j = JSON.parse(sess.boardConfig)
            function redir(w) {
                alert("Cannot go to this page yet - check - " + w)
                const JOINURL = window.location.href.replace("/board", "/join")
                window.location.href = JOINURL
                setTimeout(function () {
                    window.location.reload()
                }, 300);
            }
            if (j.boardOrder && sess.pageNumber > 1 && sess.id !== sess.parentBoardID && !gTeacher) {
                if (j.boardOrder.password) {
                    var act = getMapPage(sess.pageNumber)
                    if (j.boardOrder.password[act]) {
                        var lu = ib.getEscapeRoomPage(sess, act)
                        var check = j.boardOrder.password[act]
                        if (check && typeof check !== 'string') {
                            if (check.type === "password") {
                                if (!lu || lu.password !== check.name) {
                                    redir("password")
                                }
                            }
                            if (check.type === "score") {
                                var pnum = getPrevPage(sess.pageNumber)
                                lu = ib.getEscapeRoomPage(sess, pnum)
                                if (!lu || parseInt(lu.score) < parseInt(check.name)) {
                                    redir("score")
                                }
                            }
                        }
                    }
                }
            }
            if ('boardAuthUsersOnly' in j) {
                setAuthBoardOnly(j.boardAuthUsersOnly)
            }
            if ('boardShowGrid' in j) {
                if (!drawGridCfg && j.boardShowGrid) {
                    // if (!mobile) drawGrid(ctx.context.grid)
                    drawGridCfg = "grid"
                } else {
                    if (drawGridCfg && !j.boardShowGrid) {
                        // if (!mobile) ctx.context.grid.clearRect(0, 0, ctx.context.grid.canvas.width, ctx.context.grid.canvas.height)
                    }
                    drawGridCfg = null
                }
            }
            if ('boardShowMusicGrid' in j && drawGridCfg === null) {
                if (!drawGridCfg && j.boardShowMusicGrid) {
                    // if (!mobile) drawMusicGrid(ctx.context.grid)
                    drawGridCfg = "music"
                } else {
                    if (drawGridCfg && !j.boardShowMusicGrid) {
                        // ctx.context.grid.clearRect(0, 0, ctx.context.grid.canvas.width, ctx.context.grid.canvas.height)
                    }
                    drawGridCfg = null
                }
            }
            if ('multiBoardOption' in j) {
                var ff = multBoardCfg
                ff.multiBoardOption = j.multiBoardOption
                ff.participantsSeeEachOther = j.participantsSeeEachOther
                if (ff.multiBoardOption) {
                    ff.parentID = sess.parentBoardID
                    if (ff.parentID === sess.id) {
                        //I am on the parent
                        ff.amIParent = true
                    } else {
                        // I should subscribe to the parent too?
                        ff.amIParent = false
                    }
                } else {
                    if (sess && sess.Classroom) ff.multiBoardOption = true
                }
                gMultiBoard = ff
                setMultiBoardCfg({ ...ff })
            }
        }

        if (renamed) {
            renamed = false;
            updateSession = true;
        }

        if (updateSession && sess) {
            ib.updateSession(sess)
        }

    }, [authUser, sess, boardConfig])


    React.useEffect(() => {
        if (user && authUser) {
            if (user.UserProfile !== authUser.username) {
                user.UserProfile = authUser.username
                delete user['Session']
                ib.updateUser(user)
                gUser = user
            } else {
                var needsUpdate = false;

                if (!(user && user.name && user.name !== "") && authUser.attributes.name) {
                    user.name = authUser.attributes.name;
                    needsUpdate = true;
                }
                if (!(user && user.avatar && user.avatar !== "")) {
                    var userInfo = ib.getUserInfo(authUser);
                    if (userInfo.avatar) {
                        user.avatar = userInfo.avatar;
                        needsUpdate = true;
                    }
                }

                if (needsUpdate) {
                    ib.updateUser(user);
                    gUser = user;
                }
            }
        }
    }, [user, authUser])

    function updUser(u1) {
        ib.updateUser(u1).then((u) => {
            const r = u.data.updateUser
            if (!r) return
            var updTime = new Date(r.updatedAt)
            var locTime = new Date()
            var delta = (locTime - updTime) / 1000
            if (Math.abs(delta) > 3) dispatch(Actions.setDrift(delta))
        });
    }
    const [engagementR, setEngagementR] = React.useState({ open: false, cb: null })
    function openEngagement() {
        setEngagementR({ open: true, cb: done, sess: gSession, inkColor: inkColor })
        function done() {
            setEngagementR({ open: false, cb: null })

        }
    }
    const ENGAGEMENTDEFAULT = { click: 0, move: 0, type: 0, calculated: 0, ts: 0 }
    function updateEngagementGQL(ct) {
        if (!gSession || !gSession.Classroom || gTeacher) return
        var luid = mylocalStorage.getItem('mystoreID');
        if (!engagementScore) {
            return
        }
        var s = ct.toString()
        if (s === engagementScore.ts) return

        let calc = engagementScore.click + engagementScore.move * 2 + engagementScore.type;
        if (calc === engagementScore.calculateed) return
        engagementScore.calculated = calc
        engagementScore.ts = s

        var cmd = { luid: luid, name: myName, time: ct.toString(), Classroom: gSession.Classroom }
        cmd.Content = JSON.stringify({ engagementScore: engagementScore })
        ib.createEngagementEvent(cmd)
        engagementScore = JSON.parse(JSON.stringify(ENGAGEMENTDEFAULT))
        engagementScore.ts = ct
    }
    const keepaliveTimerFiredCB = React.useCallback(() => {
        //check time to see if 5 mins or 1
        var dt = new Date()
        var ft = Math.floor(dt.getTime() / 60000) * 60000
        var xt = new Date(ft)
        updateEngagementGQL(xt)
        var ffq = ft % 300000
        if (ffq !== 0) {
            return
        }
        if (gSession) {
            findUpdateLocalUserIfNeeded();
        }
        if (gUser) {
            updUser(gUser)
        }
    }, [sess, user]);

    React.useEffect(() => {
        gSyncDisabled = syncDisabled
    }, [syncDisabled])
    const [canvasClass, setCanvasClass] = React.useState("canvas_reg outlineNone")
    const [zoomEnabled, setZoomEnabled] = React.useState(true)

    React.useEffect(() => {
        keepaliveTimer = window.setInterval(keepaliveTimerFiredCB,
            ib.KEEPALIVE_TIMEOUT_SECONDS * 1000);
        gShowcase = null
        ggridView.open = false
        setSession(() => { gSession = null; return null; });
        setgridView(ggridView)
        if (mylocalStorage.getItem("zoomDisabled")) {
            gZoomEnabled = false
            setZoomEnabled(gZoomEnabled)
        }
        var notif = mylocalStorage.getItem('setNotif')
        if (notif) {
            dispatch(Actions.setNotification(notif))
        }
        let sss = mylocalStorage.getItem('syncDisabled')
        if (sss) {
            dispatch(Actions.setSyncDisabled(sss))
        }
        dispatch(Actions.setChatMsg(null))
        dispatch(Actions.setBoardLock(null))
        dispatch(Actions.setPageLock(null))

        dispatch(Actions.setRichText({ open: false, object: null, cb: textEdit, loc: null }))
        gLocked = false
        if (window.location.href.includes("/export/")) gLocked = true
        gGrid = null
        renderedMessages = {}
        let ra = Math.floor(Math.random() * epiColors.length)

        let ct = gCtx
        // ct.sidebar = document.getElementById('sidebar')
        ct.canvasContainer = document.getElementById('Cc')
        ct.message = document.getElementById('message')
        ct.colorSelect = document.getElementById('colorSelect')
        ct.uname = document.getElementById('uname')
        ct.minNav = document.getElementById('minNav')
        var col = epiColors[ra]
        var col2 = mylocalStorage.getItem('mycolor');
        if (col2) col = col2
        ct.color = col
        ct.SavedColor = col
        setPenBtnColor(col)
        // ct.colorSelect.value = ct.color
        // ct.uname.style.color = ct.color
        let icol = col
        if (mylocalStorage.getItem("inkCopy")) {
            icol = mylocalStorage.getItem("savedInk")
            if (!icol) icol = col
        }
        setInkColor(icol)
        ct.scrollLock = SCROLLMODE_DRAW
        ct.button = {}
        const button = {
            // lazy: 'button_lazy',
            draw: 'button_draw',
            scrollLock: 'button_scroll',
        }
        Object.keys(button).forEach(b => {
            ct.button[b] = document.getElementById(button[b])
        })

        ct.slider = {}
        const slider = {
            brush: 'slider_brush',
            lazy: 'slider_lazy',
            opacity: 'slider_opacity',
        }
        Object.keys(slider).forEach(s => {
            ct.slider[s] = document.getElementById(slider[s])
        })

        // Set initial value for range sliders
        if (ct.slider && ct.slider.brush && ct.slider.brush.value) {
            ct.slider.brush.value = BRUSH_RADIUS
        }
        setCurrentBrush(BRUSH_RADIUS)
        // ct.slider.lazy.value = LAZY_RADIUS
        var opa = 100;
        var opa2 = mylocalStorage.getItem('myopacity');
        if (opa2) opa = opa2;
        setCurrentOpacity(opa);
        ct.opacity = opa;
        ct.lineStyle = null;

        ct.canvas = {}
        ct.context = {}
        var canvas = {
            // interface: 'canvas_interface',
            drawing: 'canvas_drawing',
            // temp: 'canvas_temp',
            // others: 'canvas_others',
        }
        //  if (!mobile) canvas['grid'] = 'canvas_grid'
        paper.setup('canvas_drawing');
        ct.tempLayer = new paper.Layer();
        ct.tempLayer.visible = true
        ct.drawLayer = new paper.Layer();
        ct.drawLayer.activate();
        // var tool = new paper.Tool();

        // tool.onMouseDrag = paperMouseDrag;
        // tool.onMouseUp = paperMouseUp;
        // tool.onMouseDown = paperMouseDown;

        Object.keys(canvas).forEach(c => {
            const el = document.getElementById(canvas[c])
            ct.canvas[c] = el
            ct.context[c] = el.getContext('2d')

            if (mobile && smallScreen) {
                //not changing 
            } else {
                ct.canvas[c].minWidth = maxWidth + "px !important"
                ct.canvas[c].minHeight = maxHeight + "px !important"
            }
        })
        var mobSize = mobile && smallScreen ? "canvas_mobile" : "canvas_reg"
        if (maxWidth === smallMaxWidth) mobSize = "canvas_cb"
        if (mobSize !== "canvas_reg") {
            mobSize = mobSize + " outlineNone"
            setCanvasClass(mobSize)
        }

        ct.canvas.interface = ct.canvas.drawing
        ct.context.interface = ct.context.drawing

        ct.catenary = new Catenary()

        ct.lazy = new LazyBrush({
            radius: LAZY_RADIUS,
            enabled: true,
            initialPoint: {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2
            }
        })

        ct.points = []
        ct.drawPath = new Path();

        ct.mouseHasMoved = true
        ct.valuesChanged = true
        ct.isDrawing = false
        ct.isPressing = false

        ct.points = []
        ct.mode = defaultTool
        ct.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        ct.drawBrushRadius = BRUSH_RADIUS
        // var br = mylocalStorage.getItem("brushSize")
        // if (br) {
        //     ct.drawBrushRadius = br
        //     if (ct && ct.slider && ct.slider.brush && ct.slider.brush.value) ct.slider.brush.value = br
        //     setCurrentBrush(br)
        // }
        setText(ct.drawBrushRadius, false, false)
        ct.eraseBrushRadius = 5 * ct.drawBrushRadius
        ct.brushRadius = ct.drawBrushRadius
        ct.chainLength = LAZY_RADIUS

        ct.dpi = 1
        setCtx(gCtx)
        // console.log("platform = ", window.navigator.platform);


        function rem() {
            if (keepaliveTimer) {
                window.clearInterval(keepaliveTimer);
            }
            document.onpaste = null;
            removeAllButtons();
            observeCanvas && observeCanvas.unobserve(document.getElementById('Cc'));
            // if(document.getElementById('sidebar')){
            //     observeSidebar.unobserve(document.getElementById('sidebar'));
            // }
        }

        const savedGridOpts = mylocalStorage.getItem("gridOptions");
        if (savedGridOpts && savedGridOpts !== undefined) {
            const savedGridOptions = JSON.parse(savedGridOpts);
            setGridOptions(savedGridOptions);
        }
        return rem;
    }, [])

    React.useEffect(() => {
        // Determine the tourType even if we don't show
        // the tour so manual start of tour shows the right one.
        var doTour = false;
        gTeacher = teacherR

        if (sess && sess.Classroom !== null) {
            if (Boolean(teacherR)) {
                // console.log("Detected teacher")
                tourType = "wbEditorTeach"
                doTour = true;
            } else {
                // console.log("Detected student board")
                tourType = "wbEditorStudent"
            }
        } else if (sess && sess.Classroom === null) {
            // console.log("Detected collaboration board")
            tourType = "wbEditorCollab"
            doTour = true;
        } // else {
        // console.log("Not detected board type yet")
        // }
        if (isApiMode) {
            doTour = false
            mylocalStorage.setItem('tourDone', true)
        }

        if (window.screen.width < minWidthForTour) {
            tourType = "wbEditorMobile"
        }

        let tourDone = mylocalStorage.getItem('tourDone')
        if (tourDone && tourDone === "true") {
            doTour = false;
        }
        setOverlayHelpOpen(p => doTour);
        if (gSessionID && gSessionID !== "") {
            try {
                microsoftTeams.settings.getSettings((s) => {
                    const bid = gSessionID.split("-pgNum", 1)[0]
                    if (!s.contentUrl.includes(whiteboardChatHomeURL + "/createjoin/")) {
                        microsoftTeams.settings.setSettings({
                            "contentUrl": whiteboardChatHomeURL + "/createjoin/" + bid,
                            "suggestedDisplayName": "Whiteboard.chat"
                        });
                    }
                });
            } catch { }
        }
    }, [sess, teacherR]);

    React.useEffect(() => {
        clearGlobal()
        gSessionID = props.match.params.boardid
        gParentSession = null
    }, [props])

    async function handleDrop(event) {
        // This supports copy paste into the preset Dice Rolls text field and
        // spinner text field
        if (event.target.id === "presetRollsTextField" ||
            event.target.id === "spinnerResultsTextField" ||
            event.target.id === "fdiceResultsTextField" ||
            event.target.name === "message" ||
            event.target.nodeName && event.target.nodeName === "TEXTAREA")
            return;
        if (gLocked) return

        event.preventDefault()
        event.stopPropagation()
        if (event.target && event.target.id) return
        if (event.target.nodeName && event.target.nodeName !== "BODY") return
        var items = (event.clipboardData || event.originalEvent.clipboardData).items;
        var hasFile = false
        for (let index in items) {
            if (items[index].kind === 'file') hasFile = true
        }
        for (let index in items) {
            var item = items[index];
            if (item.kind === 'file') {
                aniMess("Pasting Image file")
                var blob = item.getAsFile();

                var ff = await iu.UploadFile(blob, false, gSession ? gSession.ttl : 0)
                if (ff) loadImage(ff)

                // var reader = new FileReader();
                // reader.onload = function (event) {
                //     console.log(event.target.result)
                // }; // data url!
                // reader.readAsDataURL(blob);
            }
            if (!hasFile && item.kind === "string" && item.type === "text/plain") {
                item.getAsString(cb)
                function cb(data) {
                    const trimmedData = data.trim() // svg tags sometimes end with newline after them
                    try {
                        if (data.includes("WBC_OBJ")) {
                            var j = JSON.parse(data)
                            const ct = JSON.parse(j.obj)
                            var pobj = new paper[ct[0]]()
                            pobj.importJSON(ct)
                            selectedObj = pobj
                            processClone(selectedObj, pobj)
                            ctx.lastMode = ctx.mode
                            ctx.mode = "edit"
                            ctx.canvas.interface.style.cursor = "crosshair"
                            return
                        } else if (trimmedData.startsWith("<svg") && trimmedData.endsWith("</svg>")) {
                            paper.project.importSVG(trimmedData, function (item) {
                                item.position = new paper.Point(lastx, lasty);
                                item.data.svgImage = true
                                item.data.origSvg = data
                                updateDrawPaperObj(item, donePaper)
                                function donePaper(nObj) {
                                    item.remove()
                                }
                            });
                            ctx.lastMode = ctx.mode
                            ctx.mode = "edit"
                            ctx.canvas.interface.style.cursor = "crosshair"
                            return
                        } else if ((trimmedData.startsWith("http") && trimmedData.includes("gif")) || trimmedData.endsWith(".webp")) {
                            const userid = gUser ? gUser.id : null;
                            const id = uuid();
                            ib.createObject(id, "name", session.id, JSON.stringify({
                                type: "media-gif",
                                position: { x: lastx - 50, y: lasty - 50 },
                                gifURL: trimmedData,
                                windowType: "media-gif"
                            }), "extWindow", userid, null, session.ttl).then(res => {
                                const copy = res.data.createObject
                                delete copy["Session"];
                                ib.updateObject({ ...copy });
                                setExtWebpages(prev => {
                                    const tempExtWebpages = { ...prev };
                                    tempExtWebpages[copy.id] = copy;
                                    if (Object.keys(tempExtWebpages).length > 0) gExt = true
                                    else gExt = false
                                    return tempExtWebpages;
                                });
                            })
                            ctx.lastMode = ctx.mode
                            ctx.mode = "edit"
                            ctx.canvas.interface.style.cursor = "crosshair"
                            return
                        }
                    } catch (e) {
                        console.log("error", e)
                    }
                    const brush = getBrushfromLazy()
                    drawText(data, brush.x, brush.y, null)
                }
            }
        }
    }
    React.useEffect(() => {
        if (boardConfig.zoom !== undefined) {
            setZoomEnabled(boardConfig.zoom)
        }
        if (boardConfig && boardConfig.defaultTool) {
            defaultTool = boardConfig.defaultTool
            if (ctx) {
                ctx.mode = defaultTool
                ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
            }
        }
        if (boardConfig && ctx && boardConfig.boardResolution) {
            setResolution(boardConfig.boardResolution)
            getOBJsAgain()

        }
        if (Boolean(teacherR)) {
            //cannot lock the teacher
            gLocked = false
            if (window.location.href.includes("/export/")) gLocked = true

            return
        }
        if (boardLocked.locked || pageLocked.locked) {
            gLocked = true
        }
        if (!boardLocked.locked && !pageLocked.locked) {
            gLocked = false
        }
        var luid = mylocalStorage.getItem('mystoreID');
        if (sess && !sess.isGroup && sess.Classroom && (sess.CreatorLocalID !== luid)) {
            //student on another board
            if (boardConfig && boardConfig.studentsCannotDrawOthers) {
                gLocked = true
                aniMess("Students cannot write on other student boards")
            }
        }
        if (window.location.href.includes("/export/")) gLocked = true
        //should be removed later
    }, [boardLocked, boardLocked.locked, teacherR, ctx, sess, boardConfig, pageLocked])

    React.useEffect(() => {

        function init() {
            // Listeners for mouse events
            ctx.canvas.interface.addEventListener('mouseout', handleMouseOut)
            if (!mobile) ctx.canvas.interface.addEventListener('mousedown', handlePointerDown)
            ctx.canvas.interface.addEventListener('mouseup', handlePointerUp)
            ctx.canvas.interface.addEventListener('mousemove', pointerMove)
            ctx.canvas.interface.addEventListener('contextmenu', (e) => handleContextMenu(e))
            ctx.canvas.interface.addEventListener("mousewheel", handleMouseWheel, false);
            ctx.canvas.interface.addEventListener('pointerdown', spointerdown)

            var dbl = mylocalStorage.getItem("dblClickDisable")
            if (!dbl) {
                ctx.canvas.interface.addEventListener('dblclick', (e) => handleDblClick(e))
            }
            ctx.canvas.interface.addEventListener('click', (e) => handleClick(e))
            ctx.canvas.interface.addEventListener('keydown', (e) => handleKeyPress(e))
            ctx.canvas.interface.addEventListener('keyup', (e) => handleKeyUp(e))

            // ctx.canvas.interface.addEventListener('drop', (e) => handleDrop(e))
            // ctx.canvasContainer.addEventListener('paste', (e) => handleDrop(e))
            document.onpaste = handleDrop;

            // Listeners for touch events
            ctx.canvas.interface.addEventListener('touchstart', (e) => handleTouchStart(e))
            ctx.canvas.interface.addEventListener('touchend', (e) => handleTouchEnd(e))
            ctx.canvas.interface.addEventListener('touchmove', (e) => handleTouchMove(e))

            // Listeners for click events on butons
            // ctx.button.draw.addEventListener('click', (e) => handleButtonDraw(e))
            if (mobile)
                ctx.button.scrollLock.addEventListener('click', (e) => handleButtonScroll(e))

            // ctx.colorSelect.addEventListener('input', (e) => colorChange(e))
            // ctx.uname.addEventListener('input', (e) => unameChange(e))

            // ctx.button.lazy.addEventListener('click', (e) => handleButtonLazy(e))

            // Listeners for input events on range sliders
            ctx.slider.brush && ctx.slider.brush.addEventListener('input', (e) => handleSliderBrush(e))
            // ctx.slider.lazy.addEventListener('input', (e) => handleSliderLazy(e))
            ctx.slider.brush && ctx.slider.brush.addEventListener('input', (e) => handleSliderOpacity(e))

            observeCanvas = new ResizeObserver((entries, observer) => handleCanvasResize(entries, observer))
            observeCanvas.observe(ctx.canvasContainer)

            // var options = {
            //     rootMargin: '0px',
            //     threshold: 1.0
            //   }
            // var observer = new IntersectionObserver(scrollhandler, options);
            // observer.observe(ctx.canvasContainer)
            document.querySelector('#canvas_container').addEventListener('scroll', scrollhandler);
            // observeSidebar = new ResizeObserver((entries, observer) => handleSidebarResize(entries, observer))
            // observeSidebar.observe(ctx.sidebar)

            loop()

            window.setTimeout(() => {
                const initX = window.innerWidth / 2
                const initY = window.innerHeight / 2
                ctx.lazy.update({ x: initX - (ctx.chainLength / 4), y: initY }, { both: true })
                ctx.lazy.update({ x: initX + (ctx.chainLength / 4), y: initY }, { both: false })
                ctx.mouseHasMoved = true
                ctx.valuesChanged = true
                clearCanvas()
            }, 100)
        }
        if (ctx) init()
    }, [ctx])

    React.useEffect(() => {
        setUndoIconDisabled(p => { return undoStack.length <= 0 })
    }, [undoStack]);

    React.useEffect(() => {
        setRedoIconDisabled(p => (redoStack.length <= 0))
    }, [redoStack]);

    React.useEffect(() => {
        if (undoKeyPressed) {
            doUndo(p => false);
            undo();
        }
    }, [undoKeyPressed]);

    React.useEffect(() => {
        if (redoKeyPressed) {
            doRedo(p => false);
            redo();
        }
    }, [redoKeyPressed]);

    React.useEffect(() => {
        if (Object.keys(participants).length === 0) { return }
        if (Object.keys(paperObj).length === 0) { return }
        var discSectors = null
        Object.keys(paperObj).forEach((k) => {
            var po = paperObj[k]
            if (po && po.obj && po.obj.data && po.obj.data.spinner) {
                var sp = po.obj
                if (!sp.data.participantsSpinner) return;
                if (!discSectors) {
                    discSectors = Object.keys(participants).map((pk) => {
                        var n = "Name not found"
                        try {
                            n = participants[pk].name
                            if (!n || n === "") {
                                var content = participants[pk].content
                                if (content && content !== "") {
                                    var email = JSON.parse(content).email
                                    if (email && email !== "") {
                                        n = email
                                    }
                                }
                            }
                        } catch { }
                        return n.substring(0, 12);
                    })
                    if (sp.data.discSectors.length < discSectors.length) {
                        sp.data.discSectors = discSectors;
                        sp.data.modified = true
                        updateSpinnerDisc(sp)
                        spinnerSave(sp)
                    }
                }
            }
        })
    }, [participants]);

    function setCanvasReSize(height, width) {
        // console.log("SETTING NEW HEIGHt", height, width)
        setCanvasSize(ctx.canvas.drawing, width, height, 1)
        // setCanvasSize(ctx.canvas.others, width, height, 1)
        //        setCanvasSize(ctx.canvas.white, width, height, 1)
        // if (!mobile) setCanvasSize(ctx.canvas.grid, width, height, 2)
        loop({ once: true })

    }
    function scrollhandler(e) {
        // const t = e.target
        // console.log("scroll", t.scrollTop, t.scrollHeight, t.scrollLeft, t.scrollWidth)
        sendPositionUpdate()
        // console.log("Height is ", ctx.canvasContainer.offsetHeight)
        // var ht = ctx.canvasContainer.offsetHeight + t.scrollTop
        // if (ht > t.scrollHeight * 0.9) {
        //     // console.log("hit 90", t.scrollHeight * 0.9)
        //     var height = 1.2 * t.scrollHeight
        //     setCanvasReSize(height, maxWidth)
        //     t.height = height
        //     t.style.height = height
        // }
    }
    async function sendPeers(o) {
        try {
            var msg = { msg: o, myID: myPeerID, sess: gSessionID }
            var ff = JSON.stringify(msg)
            for (var k in peers) {
                var p = peers[k]
                if (p.connection) {
                    p.connection.send(ff)
                }
            }
        } catch {
            return
        }
    }
    const col = [
        "#3da3db",
        "#707275",
        "#e8543f",
        "#3ddb88",
        "#ebb531",
    ]
    function delObject(dat) {
        if (dat.id in paperObj) {
            delete objDict[dat.id]

            if (paperObj[dat.id].obj) {
                if ((paperObj[dat.id].obj.data && paperObj[dat.id].obj.data.studentMove)) {
                    // teacher deleted student move obj 
                    var d = paperObj[dat.id].obj.data.studentMoveParentID
                    Object.keys(paperObj).forEach((k) => {
                        var po = paperObj[k]
                        if (po.obj && po.obj.data.studentMoveParent === d) {
                            ib.delObject(k)
                            po.obj.remove()
                        }
                    })
                }
                if ((paperObj[dat.id].obj.data && paperObj[dat.id].obj.data.studentMoveParent)) {
                    // student deleted the parent object, put back
                    var d = paperObj[dat.id].obj.data.studentMoveParent
                    Object.keys(paperObj).forEach((k) => {
                        var po = paperObj[k]
                        if (po.obj && po.obj.data.studentMoveParentID === d) {
                            paper.project.activeLayer.addChild(po.obj)
                        }
                    })
                }
                if ((paperObj[dat.id].obj.data && paperObj[dat.id].obj.data.rectPainter))
                    delete gridPainter[paperObj[dat.id].obj.data.coord]

                if (paperObj[dat.id].obj.data.linkData) buttonClicks--
                checkWidgetDeleted(paperObj[dat.id].obj.data)
                if (paperObj[dat.id].obj.data.button) paperObj[dat.id].obj.data.button.remove()
                paperObj[dat.id].obj.remove()
                if (paperObj[dat.id].obj.data && paperObj[dat.id].obj.data.stoplightGrp) {
                    const sl = paperObj[dat.id].obj.data.stoplightGrp
                    if (gStopLight[sl]) {
                        ib.delObject(gStopLight[sl].data.id)
                        delete gStopLight[sl]
                    }
                }
            }
            delete paperObj[dat.id]
            if (gGrid && gGrid.data.id === dat.id) {
                gGrid = null
            }
            if (gBackGround && gBackGround.data.id === dat.id) {
                gBackGround = null
                dispatch(Actions.setBackGround(null))
            }

        }
        if (dat.objType === "extWindow") {
            switch (JSON.parse(dat.content).windowType) {
                case 'xylophone':
                    setOpenXylo({ open: false, cb: null })
                    break;
                case 'piano':
                    setOpenPiano({ open: false, cb: null })
                    break;
                case 'moleculeeditor':
                    setOpenMoleculeEditor({ open: false, cb: null })
                    break;
                default:
                    setExtWebpages(prev => {
                        const tempExtWebpages = { ...prev };
                        delete tempExtWebpages[dat.id];
                        if (Object.keys(tempExtWebpages).length > 0) gExt = true
                        else gExt = false
                        return tempExtWebpages;
                    });
                    break;
            }
        }
    }
    React.useEffect(() => {
        if (!timeMachine.open || !lastObjTS || timeMachine.slider === null) return
        var end = new Date(lastObjTS)
        var start = new Date(sess.createdAt)
        const secs = (end - start) / 1000
        const percentsecs = secs * timeMachine.slider / 100
        var sliderDate = new Date(start.getTime() + (percentsecs * 1000));

        var convertedDate = ib.dateConvert(sliderDate)
        if (timeMachine.cb) timeMachine.cb(convertedDate)

        // get all the objects
        for (let i in paperObj) {
            const o = paperObj[i]
            if (o && o.obj && o.gqlObj && o.gqlObj.SessionID === gSessionID) {
                var bo = new Date(o.gqlObj.updatedAt)
                if (bo > sliderDate)
                    o.obj.visible = false
                else
                    o.obj.visible = true
            }
        }

    }, [timeMachine])
    function getPgNum(s) {
        var pgNum = null
        try {
            if (s) {
                var pgSplit = s.split("-pgNum-")
                if (pgSplit.length > 1) pgNum = pgSplit[1]
            }
        } catch {
            return null
        }
        return pgNum
    }
    function gotData(dat) {
        // console.log("gotData:", dat);
        if (ggridView.open || gShowcase) return
        paper.activate()
        const pgNum = getPgNum(gSessionID)
        var hasExt = false
        for (let i = 0; i < dat.length; i++) {
            const vv = getPgNum(dat[i].SessionID)
            if (pgNum !== vv) {
                continue
            }
            ib.gotObjMonitor(dat[i])
            if (dat[i].SessionID !== gSessionID &&
                dat[i].DisableSync &&
                dat[i].DisableSync === "disabled") {
                //teacher object not meant for me
                continue;
            }
            //dispatch(Actions.addActive({ id: dat[i].CreatedBy, obj: dat[i] }))

            if (
                ((dat[i].objType === "drawFree" && dat[i].ended) ||
                    (dat[i].objType === "text") ||
                    (dat[i].objType === "image") ||
                    (dat[i].objType === "drawPaper"))) {
                pushUndoStack('add', dat[i]);
            }
            updateAnimate(dat[i])
            var idx = 0
            if (dat[i].id === myObj.id) {
                // console.log("gotData: id matches continue");
                //my object ignore
                myObj.updatedAt = dat[i].updatedAt
                continue
            }

            if (dat[i].id in objDict) {
                if (objDict[dat[i].id].mine && dat[i].objType !== "text") {
                    // console.log("gotData: id matches in map, continue");
                    //another my update
                    continue
                }
                idx = objDict[dat[i].id].lastIndex

            } else {
                objDict[dat[i].id] = { lastIndex: 0, mine: false, peerObj: [] }
            }
            if (dat[i].SessionID === gSessionID) lastObjTS = dat[i].updatedAt
            if (dat[i].objType === "text") {
                drawTextRemote(dat[i])
            }
            if (dat[i].objType === "drawFree") {
                objDict[dat[i].id].obj = dat[i]
                var drawn = drawObj(dat[i], idx, col[i % col.length])
                if (drawn > 0) objDict[dat[i].id].lastIndex = drawn - 1
            }
            if (dat[i].objType === "image") {
                objDict[dat[i].id].obj = dat[i]
                drawImage(dat[i])
            }
            if (dat[i].objType === "drawPaper") {
                objDict[dat[i].id].obj = dat[i]
                drawPaper(dat[i], null)
            }
            if (dat[i].objType === "extWindow") {
                objDict[dat[i].id].obj = dat[i]
                hasExt = true
                gExt = true
                switch (JSON.parse(dat[i].content).windowType) {
                    case 'xylophone':
                        setOpenXylo({ open: true, cb: xyloDone, obj: dat[i] })
                        break;
                    case 'piano':
                        setOpenPiano({ open: true, cb: pianoDone, obj: dat[i] })
                        break
                    case 'moleculeeditor':
                        setOpenMoleculeEditor({ open: true, cb: pianoDone, obj: dat[i] })
                        break
                    default:
                        setExtWebpages(prev => {
                            const tempExtWebpages = { ...prev };
                            tempExtWebpages[dat[i].id] = dat[i];
                            return tempExtWebpages;
                        });
                        break;
                }
                function xyloDone() {
                    setOpenXylo({ open: false, cb: null })
                }
                function pianoDone() {
                    setOpenPiano({ open: false, cb: null })
                }
            }
            updateAnimate(dat[i]) // purposely to make sure we animate stuff
        }

        if (hasExt || gExt) {
            // Filter extWebpages not on current page
            setExtWebpages(prev => {
                const tempExtWebpages = { ...prev };
                Object.keys(tempExtWebpages).map((key) => {
                    if (getPgNum(tempExtWebpages[key].SessionID) !== pgNum) {
                        delete tempExtWebpages[key];
                    }
                })
                if (Object.keys(tempExtWebpages).length > 0) gExt = true
                else gExt = false
                return tempExtWebpages;
            })
        }
    }

    function clearPaperObj() {
        paper.project.activeLayer.removeChildren();

        rotateObj = {}
        colorObj = {}
        moveObjs = {}
        paperObj = {}

        paper.project._needsUpdate = true;
        paper.project.view.update();
    }

    function getOBJsAgain() {
        if (gSessionID) {
            objDict = {}
            setMyObj()
            clearPaperObj()
            clearWidgetCount()
            if (gParentSession && gParentSession !== gSessionID) {
                ib.listObject(gParentSession, null, gotData)
            }
            ib.listObject(gSessionID, null, gotData)
        }
    }

    function allowChange(tool) {
        var bt = boardTools && Object.keys(boardTools).length > 0 ? boardTools : gBoardTools
        var allow = true
        if (gTeacher) return allow
        if (!bt) return allow

        if (tool in bt) {
            if (bt[tool].value && bt[tool].value.length > 0)
                return false
        }
        return allow
    }

    function setStudentDraw(dat) {
        if (!dat.boardConfig) return
        const j = JSON.parse(dat.boardConfig)
        var cfgChange = false
        var newCfg = {}
        gBoardTools = {}
        if (j) {
            if (j.boardTools) {
                if (dat.id !== dat.parentBoardID) {
                    //set student tools
                    if (j.boardTools['brushSize'] && j.boardTools['brushSize'].value && j.boardTools['brushSize'].value.length > 0) {
                        handleSliderBrush(null, parseInt(j.boardTools['brushSize'].value), true)
                    }
                    if (j.boardTools['fontSize'] && j.boardTools['fontSize'].value && j.boardTools['fontSize'].value.length > 0) {
                        setText(parseInt(j.boardTools['fontSize'].value), true, true)
                    }
                    if (j.boardTools['brushColor'] && j.boardTools['brushColor'].value && j.boardTools['brushColor'].value.length > 0) {
                        updateColor(j.boardTools['brushColor'].value, true, true)
                    }
                }
                gBoardTools = j.boardTools
                dispatch(Actions.setBoardTools(j.boardTools))
            } else {
                dispatch(Actions.setBoardTools({}))
            }
        }
        if (j && j.studentsCannotDrawOthers) {
            if (boardConfig &&
                boardConfig.studentsCannotDrawOthers !== j.studentsCannotDrawOthers) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    studentsCannotDrawOthers: j.studentsCannotDrawOthers,
                }
                cfgChange = true
            }
        }
        if (j && j.simpleDrawingTools) {
            if (boardConfig &&
                boardConfig.simpleDrawingTools !== j.simpleDrawingTools) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    simpleDrawingTools: j.simpleDrawingTools,
                }
                cfgChange = true
            }
        }
        if (j && j.fourToolsStudent) {
            if (boardConfig &&
                boardConfig.fourToolsStudent !== j.fourToolsStudent) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    fourToolsStudent: j.fourToolsStudent,
                }
                cfgChange = true
            }
        }
        if (j && j.StudentWebcamVideo) {
            if (boardConfig &&
                boardConfig.StudentWebcamVideo !== j.StudentWebcamVideo) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    StudentWebcamVideo: j.StudentWebcamVideo,
                }
                cfgChange = true
            }
        }
        if (j && j.MultiLineText) {
            if (boardConfig &&
                boardConfig.MultiLineText !== j.MultiLineText) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    MultiLineText: j.MultiLineText,
                }
                cfgChange = true
                gEnter = j.MultiLineText
            }
        }
        if (j && j.SpeechText) {
            if (boardConfig &&
                boardConfig.SpeechText !== j.SpeechText) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    SpeechText: j.SpeechText,
                }
                cfgChange = true
                gSpeechText = j.SpeechText
            }
        }
        if (j && j.DisableImmersive) {
            if (boardConfig &&
                boardConfig.DisableImmersive !== j.DisableImmersive) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    DisableImmersive: j.DisableImmersive,
                }
                cfgChange = true
            }
        }
        if (j && j.boardAuthUsersOnly) {
            if (boardConfig &&
                boardConfig.boardAuthUsersOnly !== j.boardAuthUsersOnly) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    boardAuthUsersOnly: j.boardAuthUsersOnly,
                }
                cfgChange = true
            }
        }
        if (j && j.defaultTool) {
            if (boardConfig &&
                boardConfig.defaultTool !== j.defaultTool) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    defaultTool: j.defaultTool,
                }
                cfgChange = true
            }
        }
        if (j && j.boardResolution) {
            if (boardConfig &&
                boardConfig.boardResolution !== j.boardResolution) {
                newCfg = {
                    ...boardConfig,
                    ...newCfg,
                    boardResolution: j.boardResolution,
                }
                cfgChange = true
                setResolution(j.boardResolution)
            }
        }
        if (cfgChange) {
            dispatch(Actions.setBoardConfig(newCfg))
        }
    }

    function setResolution(res) {
        if (gResolution === res) {
            return
        }
        if (!ctx) return
        gResolution = res
        setCanvasClass("canvas_cb_" + res + " outlineNone")
        var r = ib.boardResoltionType[res]
        ctx.canvas.drawing.width = r.w
        ctx.canvas.drawing.height = r.h
        maxHeight = r.h
        maxWidth = r.w
        var col = inkColor ? inkColor : "#3174F5"
        ctx.canvas.drawing.style.border = "1px dashed  " + col
    }

    React.useEffect(() => {
        var peer = null
        var needToUpdateSessionUser = false;
        var s1, s2, s3, s4, s5, s12, s42, s44, s45, s54, s55;

        function getSession(dat) {
            setSession(() => { gSession = dat; return dat; });
            if (dat.boardConfig) {
                var refresh = false
                const j = JSON.parse(dat.boardConfig)
                if (j && j.boardAuthUsersOnly) {
                    if (!authUser) refresh = true
                }
                setStudentDraw(dat)
                if (refresh) window.location.reload();
            }
        }

        function disconnectPeer(peer) {
            var p = peers[peer]
            if (!p) return

            if (p.oldmouse) {
                p.oldmouse.remove()
            }
            delete peers[peer]
        }
        function drawMouse(mm) {
            if (!defaultTimer) {
                defaultTimer = window.setInterval(clearRectAll, 5000);
            }
            const m = mm.msg
            try {
                m.x = parseInt(m.x)
                m.y = parseInt(m.y)
                m.brush = parseInt(m.brush)
            } catch {
                return
            }
            if (mm.myID === myPeerID) return
            var p = peers[mm.myID]
            if (!p) {
                return
            }

            if (p && p.oldmouse) {
                // var mult = p.oldmouse.brush
                // if (mult < 4) mult = 4
                // const oldR = mult * 2
                // ct.beginPath();
                // if ('name' in p.oldmouse) {
                //     var text = ct.measureText(p.oldmouse.name);
                //     var w = text.width
                // } else {
                //     w = 100
                // }
                // ct.clearRect(p.oldmouse.x - oldR, p.oldmouse.y - oldR, (2 * oldR) + w + 10,
                //     (2 * oldR));
                // ct.closePath();
                var ff = p.oldmouse.bounds

                p.oldmouse.position.x = m.x + (ff.width / 2)
                p.oldmouse.position.y = m.y
                p.oldmouse.fillColor = m.color
            } else {
                if (ctx.tempLayer) {
                    const RADIUS = 5
                    ctx.tempLayer.activate()
                    var pt = new paper.Point(m.x, m.y)
                    var myCircle = new Path.Circle(pt, RADIUS);
                    myCircle.fillColor = m.color

                    var text = new paper.PointText(new paper.Point(m.x + RADIUS + 5, m.y + RADIUS));
                    text.content = " ";
                    text.style = {
                        fontFamily: 'roboto',
                        fontSize: 12,
                        fillColor: m.color,
                        justification: 'left'
                    };
                    var group = new paper.Group([myCircle, text])
                    ctx.drawLayer.activate()

                    p.oldmouse = group
                }
            }

            // ct.beginPath()
            // ct.fillStyle = m.color
            // ct.arc(m.x, m.y, m.brush * 2, 0, Math.PI * 2, true)
            // ct.fill()
            // if ('name' in m) {
            //     ct.font = '10px roboto';
            //     ct.fillText(m.name, m.x + (m.brush * 2) + 5, m.y + (m.brush));
            // }
            // p.oldmouse = m
        }
        function drawPObj(mm) {
            const m = mm.msg
            if (m.id in objDict) {

            } else {
                objDict[m.id] = { lastIndex: 0, mine: false, peerObj: [] }
            }
            var ct = ctx.context.drawing
            ct.lineWidth = m.brush * 2
            ct.lineJoin = 'round'
            ct.lineCap = 'round'
            ct.strokeStyle = m.color
            ct.dashArray = m.dashArray
            const p2 = m.p2
            const p1 = m.p1
            if (!objDict[m.id].peerObj) return
            objDict[m.id].peerObj.push({ x: p2.x, y: p2.y })
            objDict[m.id].peerdat = m
            ct.moveTo(p2.x, p2.y)
            ct.beginPath()
            var midPoint = midPointBtw(p1, p2)
            //     console.log("Doing curve", p1.x, p1.y, midPoint.x, midPoint.y)
            ct.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
            ct.stroke()
            drawMouse(mm)
        }

        function gotDataCommon(d) {
            var msg = JSON.parse(d)
            if (msg && msg.sess) {
                var forMe = false
                if (sess.parentBoardID !== sess.id && msg.sess === sess.parentBoardID) forMe = true
                if (msg.sess === gSessionID) forMe = true
                if (!forMe) {
                    return
                }
            }
            if (msg && 'msg' in msg && msg.msg.type === "mouseMove") drawMouse(msg)
            if (msg && 'msg' in msg && msg.msg.type === "scrollMove") drawScrollMove(msg)
            if (msg && 'msg' in msg && msg.msg.type === "snakeMove") {
                drawRemoteSnake(msg)
                drawMouse(msg)
            }
            if (msg && 'msg' in msg && msg.msg.type === "mouseDraw") drawPObj(msg)
            if (msg && 'msg' in msg && msg.msg.type === "logMessage") console.log(msg.msg.msg)

        }
        function gotData22(data) {
            gotDataCommon(data)
        }

        function newPeer2(d) {
            d.on('data', (data) => {
                gotDataCommon(data)
            });
            d.on('call', answerCall)
            d.on('disconnected', function () { disconnectPeer(d.peer) })
            d.on('close', function () {
                disconnectPeer(d.peer)
            })

            var xx = JSON.parse(JSON.stringify(defaultPeer))
            xx.id = d.peer
            xx.connection = d
            peers[d.peer] = xx
        }

        function gotUser(dat) {

            async function processPeer(p) {
                if (!peer) {
                    return
                }
                try {
                    var d = JSON.parse(JSON.stringify(defaultPeer))
                    d.id = p
                    d.connection = peer.connect(p)
                    if (!d.connection) return
                    d.connection.serialization = 'json';
                    d.connection.on('open', function () {
                        d.connection.on('data', gotData22)
                        d.connection.on('disconnected', function () { disconnectPeer(d.connection.peer) })
                        d.connection.on('close', function () { disconnectPeer(d.connection.peer) })
                    })
                    peers[p] = d
                } catch (err) {
                    console.log("error in connect", err)
                }
            }
            for (var u in dat) {
                if (!dat[u]) continue
                dispatch(Actions.addParticipant(dat[u]))
                let index = gUsers.findIndex(x => x.id === dat[u].id);
                if (index === -1) gUsers.push(dat[u])
                else {
                    gUsers.splice(index, 1)
                    gUsers.push(dat[u])
                }
                try {
                    var x = JSON.parse(dat[u].content)
                } catch {
                    continue
                }
                if ('peerID' in x) {
                    if (myPeerID === x['peerID']) continue
                    if (!(x['peerID'] in peers))
                        processPeer(x['peerID'])
                }
            }
        }
        function gotEngagement(dat) {
            if (dat) {
                dispatch(Actions.updateEngagementScore(dat))
            }
        }
        function gotLocalUser(dat) {
            for (var i = 0; i < dat.length; i++) {
                var d = dat[i]
                if (d.id in savedLocalUsers) {
                    if (d.CurrentUser && d.CurrentUser.content) {
                        // //delete old peer?
                        // var incomingContent
                        // try {
                        //     incomingContent = JSON.parse(d.CurrentUser.content)
                        // } catch {
                        //     continue
                        // }

                        // const incomingPeer = incomingContent.peerID
                        var savedContent
                        try {
                            savedContent = JSON.parse(savedLocalUsers[d.id].CurrentUser.content)
                        } catch {
                            continue
                        }
                        const savedPeer = savedContent.peerID
                        //disconnectPeer(savedPeer)
                        gotUser([d.CurrentUser])
                    }
                } else {
                    //new User 
                    gotUser([d.CurrentUser])
                }
                savedLocalUsers[d.id] = JSON.parse(JSON.stringify(d))
            }
        }
        if (sess && ctx) {
            if (!noUser) {
                if ((user && user.SessionID !== sess.id) || !user) {
                    var peerId = {};
                    var name = mylocalStorage.getItem('myname');
                    var generatedName = false;
                    if (name && name !== "") {
                        let spName = name.split(" ");

                        if (spName.length === 2) {
                            if (verbsMap[spName[0]] && profilesMap[spName[1]]) {
                                generatedName = true;
                            }
                        }

                        myName = name
                    } else {
                        let ra1 = Math.floor(Math.random() * profiles.length)
                        let ra2 = Math.floor(Math.random() * verbs.length)
                        myName = verbs[ra2] + " " + profiles[ra1]
                        generatedName = true;
                    }
                    var uid = null
                    var avatar = null;
                    if (authUser && authUser.username) {
                        uid = authUser.username
                        avatar = authUser.avatar;
                        peerId['email'] = authUser.attributes.email

                        if ((generatedName && authUser.attributes.name) || (name === "undefined")) {
                            myName = ((!authUser.attributes.name) || (authUser.attributes.name === "undefined")) ? authUser.attributes.email : authUser.attributes.name;
                            mylocalStorage.setItem('myname', myName);
                        }
                    }
                    var redir = mylocalStorage.getItem('redir');
                    var isStu = mylocalStorage.getItem('IsStudent');
                    if (!isStu && isApiMode) {
                        mylocalStorage.setItem('IsStudent', true);
                        isStu = true
                    }
                    if (((isStu === undefined) || (isStu === null)) &&
                        !(redir && redir === sess.Classroom) &&
                        (window.location.href.indexOf('/board') > 0)) {
                        aniMess("Redirect to join board")
                        const JOINURL = window.location.href.replace("/board", "/join")
                        mylocalStorage.setItem('redir', sess.Classroom)
                        window.location.href = JOINURL
                        setTimeout(function () {
                            window.location.reload()
                        }, 300);
                        return;
                    }
                    var luid = findcreatelocalUser()
                    peerId['localID'] = luid

                    // ctx.uname.value = myName

                    const PeerServer = ["terminal.epiphani.ai", "peer2.epiphani.ai"]
                    function findUpdateSessionUser() {
                        return new Promise((resolve, reject) => {
                            function createPeer(id, content) {
                                try {
                                    var UU = sess.Classroom ? sess.Classroom : sess.parentID
                                    var ff = Number.parseInt(UU[UU.length - 1], 16) % 2
                                    if (ff > PeerServer.length) {
                                        ff = 0
                                    }
                                    var server = PeerServer[ff]
                                    myPeerID = id;
                                    peer = new Peer(id, {
                                        port: 9000,
                                        path: '/colab-xxx-1121',
                                        debug: 2,
                                        host: server,
                                        secure: 'true'
                                    });
                                    peer.on('connection', newPeer2)
                                    peer.on('error', function (error) {
                                        console.log("Connection error", id, error);
                                        // peer = null
                                    });
                                    peer.on('call', answerCall)
                                    content['peerID'] = id;
                                    content['color'] = ctx.color;
                                    gPeer = peer
                                    var ss2 = mylocalStorage.getItem('myid');
                                    if (ss2) {
                                        content['useridsaved'] = ss2
                                    }
                                } catch (e) {
                                    console.error("ERROR PEER", id, e)
                                    peer = null
                                }
                            }

                            function findUserHandler(userList, content) {
                                var readContent;

                                if (!userList || userList.length === 0) {
                                    // Did not find any user
                                    var newId = uuid();
                                    createPeer(newId, content);
                                    ib.createUser(myName, sess.id, JSON.stringify(content), uid, avatar, sess.ttl).then(res => {
                                        setUser(res.data.createUser)
                                        gUser = res.data.createUser
                                        findUpdateLocalUserIfNeeded()
                                        resolve();
                                    })
                                } else if (userList.length === 1) {
                                    // Found a match
                                    readContent = JSON.parse(userList[0].content);
                                    updateColor(readContent.color, false, false);
                                    createPeer(readContent.peerID, readContent);
                                    setUser(userList[0]);
                                    gUser = userList[0];
                                    if (generatedName) {
                                        // ctx.uname.value = userList[0].name;
                                        myName = userList[0].name;
                                    }
                                    updUser(userList[0]);
                                    findUpdateLocalUserIfNeeded();
                                    resolve();
                                } else {
                                    // Found more than 1 match, pick the first one for now.
                                    readContent = JSON.parse(userList[0].content);
                                    updateColor(readContent.color, false, false);
                                    createPeer(readContent.peerID, readContent);
                                    setUser(userList[0]);
                                    gUser = userList[0];
                                    if (generatedName) {
                                        // ctx.uname.value = userList[0].name;
                                        myName = userList[0].name;
                                    }
                                    updUser(userList[0]);
                                    findUpdateLocalUserIfNeeded();
                                    resolve();
                                }
                            }
                            if (sess && sess.Users && sess.Users.items) {
                                var found = null
                                if (sess.CreatorLocalID === uid && sess.parentID === sess.Classroom) {
                                    dispatch(Actions.setTeacher(1))
                                }
                                for (let i = 0; i < sess.Users.items.length; i++) {
                                    var uuu = sess.Users.items[i]
                                    if (uuu.userProfileID === uid) {
                                        found = uuu; break;
                                    }
                                    if (peerId['email'] && uuu.content.includes(`"email":"${peerId['email']}"`)) {
                                        found = uuu; break;
                                    }
                                    if (peerId['localID'] && uuu.content.includes(`"localID":"${peerId['localID']}"`)) {
                                        found = uuu; break;
                                    }
                                }
                                if (found) {
                                    //got users already no need to search 
                                    findUserHandler([uuu], peerId)
                                    return
                                }
                            }
                            ib.findUser(sess.id, peerId, uid, findUserHandler)
                        })
                    }
                    needToUpdateSessionUser = true;
                    findUpdateSessionUser().then((result) => {
                        doAPILoads()
                        doSubscriptions();
                    })
                }
            }

            function doSubscriptions() {
                function delSession(ses) {
                    // props.history.push("/")
                }

                function listUserCB(dat) {
                    for (var u in dat) {
                        dispatch(Actions.addParticipant(dat[u]))
                        let index = gUsers.findIndex(x => x.id === dat[u].id);
                        if (index === -1) gUsers.push(dat[u])
                    }
                }
                dispatch(Actions.flushParticipants())
                ib.listUser(sess.id, null, listUserCB)
                var bn = ""
                if (sess.name !== "unsaved") bn = ":" + sess.name
                var teacher = 1
                if (sess.Classroom) {
                    ib.getClassroom(sess.Classroom, function (r) {
                        if (r) {
                            var luid = mylocalStorage.getItem('mystoreID');
                            if (luid !== r.TeacherID) {
                                if (r.TeacherList &&
                                    r.TeacherList.indexOf(luid) !== -1) {
                                    // if this is another teacher
                                    setUserRole('coTeacher')
                                    dispatch(Actions.setTeacher(teacher))
                                    doClassSubs(true)
                                } else {
                                    dispatch(Actions.setTeacher(0))
                                    doClassSubs(false)
                                }
                                return
                            } else {
                                setUserRole('teacher')
                            }
                        }
                        dispatch(Actions.setTeacher(teacher))
                        doClassSubs(true)
                    })
                } else {
                    dispatch(Actions.setBoardType({ name: 'Single Board' }))
                    dispatch(Actions.setTeacher(teacher))
                    setUserRole('collaborate')
                }
                function doClassSubs(teacher) {
                    function classObjSubCB(objSubs) {
                        [s12, s42] = objSubs
                    }

                    function localUsersSubCB(localUsersSubs) {
                        [s44, s45] = localUsersSubs;
                    }

                    function engagementSubCB(localUsersSubs) {
                        [s54, s55] = localUsersSubs;
                    }
                    if (sess.parentBoardID !== sess.id) {
                        //subscribe to the parents objects too
                        ib.AddSubMonitor(sess.parentBoardID, disconCb);
                        ib.ObjectSubscribe({
                            "sessionID": sess.parentBoardID,
                            "cb": gotData,
                            "delCB": delObject,
                            "subCB": classObjSubCB,
                            "doList": true
                        });
                        dispatch(Actions.setBoardType({ name: 'Student Board' }))
                    } else {
                        // this is the main board
                        if (sess.CreatorLocalID === findcreatelocalUser() && sess.Classroom) {
                            savedLocalUsers = {};
                            ib.SubscribeLocalUsersByClass({
                                "ClassroomID": sess.Classroom,
                                "cb": gotLocalUser,
                                "delCB": delLocalUser,
                                "doList": true,
                                "subCB": localUsersSubCB
                            })
                            ib.SubscribeEngagementEventsByOwner({
                                "Classroom": sess.Classroom,
                                "cb": gotEngagement,
                                "doList": true,
                                "subCB": engagementSubCB
                            })
                            dispatch(Actions.setBoardType({ name: 'Instructor Board' }))
                        } else {
                            if (sess.Classroom) {
                                if (teacher) {
                                    // secondary teacher on teacher board
                                    savedLocalUsers = {};
                                    ib.SubscribeLocalUsersByClass({
                                        "ClassroomID": sess.Classroom,
                                        "cb": gotLocalUser,
                                        "delCB": delLocalUser,
                                        "doList": true,
                                        "subCB": localUsersSubCB
                                    })
                                    dispatch(Actions.setBoardType({ name: 'Instructor Board [T]' }))
                                } else {
                                    ReactGA.event({
                                        category: 'Teacher',
                                        action: "Wrong URL Invite - Send to Student"
                                    });
                                    var redir = mylocalStorage.getItem('redir');
                                    if (redir && redir === sess.Classroom) {
                                        ReactGA.event({
                                            category: 'Teacher',
                                            action: "Wrong URL Invite - Send to Student 2x"
                                        });
                                        aniMess("Joining Teacher Board, click invite link to go to student board")
                                        dispatch(Actions.setBoardType({ name: 'Instructor Board [s]' }))
                                    } else {
                                        aniMess("Redirect to student board")
                                        const JOINURL = window.location.href.replace("/board", "/join")
                                        mylocalStorage.setItem('redir', sess.Classroom)
                                        window.location.href = JOINURL
                                        setUserRole('student')
                                    }
                                }
                            }
                        }
                    }
                }
                function objSubCB(objSubs) {
                    [s1, s4] = objSubs;
                }
                function userSubCB(userSub) {
                    s3 = userSub;
                }
                function sessSubCB(sessSubs) {
                    [s2, s5] = sessSubs;
                }
                gUsers = []
                gTextBox = {}
                setSidebarVal(true)
                // removeAllButtons()
                ib.AddSubMonitor(sess.id, disconCb);
                // lat.getLatencyData()
                // ib.getService() 
                // console.log("SESS IS", sess) 
                ib.ObjectSubscribe({
                    "sessionID": sess.id,
                    "cb": gotData,
                    "delCB": delObject,
                    "subCB": objSubCB,
                    "doList": true
                });
                ib.SessionSubscribe({
                    "sessionID": sess.id,
                    "cb": getSession,
                    "delcb": delSession,
                    "subCB": sessSubCB
                });
                // UserSubscribe call here already had `true` for noList,
                // it was inverted for doList
                ib.UserSubscribe({
                    "sessionID": sess.id,
                    "cb": gotUser,
                    "doList": false,
                    "subCB": userSubCB
                });
            }
            function disconCb() {
                aniMess("Network Error. Please refresh your browser to reconnect")
                if (countryCode) {
                    var mm = "REFRESH-" + window.location.hostname + "-" + countryCode
                } else {
                    mm = "REFRESH-" + window.location.hostname
                }
                ReactGA.event({
                    category: 'Performance',
                    action: mm
                });
                // window.location.reload()
            }
            function delLocalUser(u) {
                window.location.reload()
            }
            if (!needToUpdateSessionUser) {
                doSubscriptions();
            }
        }
        return () => {
            ib.ResetSubsMonitor()
            if (s1) s1.unsubscribe()
            if (s2) s2.unsubscribe()
            if (s3) s3.unsubscribe()
            if (s4) s4.unsubscribe()
            if (s5) s5.unsubscribe()
            if (s12) s12.unsubscribe()
            if (s42) s42.unsubscribe()
            if (s44) s44.unsubscribe()
            if (s45) s45.unsubscribe()
            if (s54) s54.unsubscribe()
            if (s55) s55.unsubscribe()
        }
    }, [sess, ctx])
    const getQueryStringParams = query => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
                )
            : {}
    };
    const isApiMode = window.location.href.includes("/boards/") || window.location.href.includes("/export/");
    async function doAPILoads() {
        if (isApiMode) {
            mylocalStorage.setItem("dblClickDisable", "yes")
            paper.view.viewSize = [maxWidth, maxHeight]

            let q = getQueryStringParams(props.location.search)
            if (q.scrollFollow) {
                gScrollFollow = { x: 0, y: 0, zoom: 0 }
            }
            if (q.bg) {
                if (q.bg in gLoadMaps) {
                    return
                }
                if (q.bgh) {
                    ctx.canvas.drawing.style.height = q.bgh + "px"
                    ctx.canvas.drawing.height = q.bgh
                    maxHeight = parseInt(q.bgh)
                    paper.view.viewSize = [maxWidth, maxHeight]
                }
                gLoadMaps[q.bg] = "loading"
                let ii = await ib.getImage(q.bg)
                let args = { source: ii.body, crossOrigin: 'anonymous' }
                var raster = new paper.Raster(args);
                let x = parseInt(q.xAxis) + (q.bgw / 2)
                let y = parseInt(q.yAxis) + (q.bgh / 2)
                raster.position = new paper.Point(x, y)

                paper.project.activeLayer.insertChild(0, raster);
                raster.onLoad = function () {
                    if (!q.xAxis) {
                        raster.position = new paper.Point(raster.size._width / 2 + 100,
                            raster.size._height / 2 + 100)
                    }
                    if (q.bgw && q.bgh) {
                        let w = q.bgw / raster.width
                        let h = q.bgh / raster.height
                        raster.scale(w, h)
                        if (w !== 1 || h !== 1) {
                            let x = parseInt(q.xAxis) + (q.bgw / 2)
                            let y = parseInt(q.yAxis) + (q.bgh / 2)
                            raster.position = new paper.Point(x, y)

                        }
                    }
                    doneLoading()
                    if (apiBoardCreated) {
                        // let svg = raster.exportSVG({ asString: true })
                        // let file = new File([svg], "svg.svg", { type: "text/plain"})
                        // let bid = window.location.href.split("/boards/")[1]
                        // bid = bid.split("?")[0]
                        // iu.wroteFile(file, bid, wroteFILE) 
                        // function wroteFILE(url) {
                        //     console.log("URL IS ", url) 
                        // }
                    }
                }
            } else {
                doneLoading()
            }
            // function drawInlineSVG(rawSVG) {
            //     var svg = new Blob([rawSVG], {type:"image/svg+xml;charset=utf-8"});
            //     var url = URL.createObjectURL(svg);
            //     var img = new Image();
            //     img.src = url;
            //     console.log("OPEN", url) 
            //     img.addEventListener('load', function () {
            //         window.open(url)
            //     });
            // }

            async function doneLoading() {
                if (!window.location.href.includes("/export/")) return
                await new Promise(r => setTimeout(r, 800));
                let svg = paper.project.exportSVG({ asString: true })
                // document.documentElement.dangerouslySetInnerHTML = svg;
                document.querySelector('html').innerHTML = svg;
                // drawInlineSVG(svg)
                // document.open();
                // document.write(svg);  // htmlCode is the variable you called newDocument
                // document.close();

                // document.body.parentElement.innerHTML = 
                // var fileName = "custom.svg"
                // var url = "data:image/svg+xml;utf8," + encodeURIComponent(paper.project.exportSVG({ asString: true }));
                // var link = document.createElement("a");
                // link.download = fileName;
                // link.href = url;
                // link.click();
            }
        }
    }
    React.useEffect(() => {
        if ('params' in props.match && 'boardid' in props.match.params) {
            ReactGA.pageview('/joinsession')
            if (ctx && ctx.context) clearCanvas()
            removeAllButtons()
            ib.getSession(props.match.params.boardid).then((res) => {
                session = res.data.getSession;
                if (!session) {
                    //node deleted
                    if (isApiMode) {
                        let bid = props.match.params.boardid.split("?")[0]
                        apiBoardCreated = true
                        ib.createSession(bid, "unsaved", "somecontent", bid, 1, null, null, null).then((res2) => {
                            session = res2.data.createSession
                            gSessionID = session.id
                            gParentSession = session.parentBoardID
                            setMyObj()
                            setSession(() => { gSession = session; return session; });
                        })
                    } else {
                        ib.createSessionNotFound(props.match.params.boardid).then((res2) => {
                            session = res2.data.createSession
                            gSessionID = session.id
                            gParentSession = session.parentBoardID
                            setMyObj()
                            setSession(() => { gSession = session; return session; });
                            //props.history.push("/board/"+props.match.params.boardid)
                        }).catch((e) => {
                            setWebsiteAlertMessage(true)

                        })
                    }
                    return
                }
                // Confirm there is a joinCode, if not create one it may be
                // an old board being reused.
                if (!session.joinCode || session.joinCode === "") {
                    session.joinCode = ib.genJoinCode();
                    ib.updateSession({ 'id': session.id, 'joinCode': session.joinCode });
                }
                setSession(() => { gSession = session; return session; });
                gSessionID = session.id
                gParentSession = session.parentBoardID
                setStudentDraw(session)
                setMyObj()
            })
        } else {

            //create new board
            ib.createSession("unsaved", "somecontent").then((res) => {
                console.log("created", res)
            })
        }
    }, [props.match])
    const [tabValue, setTabValue] = React.useState({
        shown: 0,
        display0: "block",
        display1: "none",
    });
    React.useEffect(() => {
        function handleChangeTab(newtab) {
            setTabValue({
                shown: newtab,
                display0: newtab === 0 ? "block" : "none",
                display1: newtab === 1 ? "block" : "none",
            });
        }
        handleChangeTab(tab.selected)
    }, [tab])
    function sendMobile(msg) {
        sendPeers({
            type: "logMessage", msg: msg
        })
    }

    function getTouchPos(e) {
        var bcr = e.target.getBoundingClientRect();
        if (!e || !e.changedTouches) return [0, 0]
        var x = e.changedTouches[0].clientX - bcr.x;
        var y = e.changedTouches[0].clientY - bcr.y;
        var b = changeXYZoom(x, y)
        x = b.x
        y = b.y
        return [x, y]
    }

    function getTouchPosAct(e) {
        var bcr = e.target.getBoundingClientRect();
        if (!e || !e.changedTouches) return [0, 0]
        var x = e.changedTouches[0].clientX - bcr.x;
        var y = e.changedTouches[0].clientY - bcr.y;
        return [x, y]
    }

    const state = {
        mapbox: null,
        panStart: { x: 0, y: 0 }
    }

    function mtStart(event) {
        event.stopImmediatePropagation()
        event.preventDefault()

        let x = 0
        let y = 0

        for (let touch of Array.from(event.touches)) {
            x += touch.screenX
            y += touch.screenY
        }

        state.panStart.x = x / event.touches.length
        state.panStart.y = y / event.touches.length
    }

    function mtMove(event) {
        event.stopImmediatePropagation()
        event.preventDefault()

        let x = 0
        let y = 0
        if (event.touches.length === 2) {
            // Calculate the distance between the two pointers
            var t1 = event.touches[0]
            var t2 = event.touches[1]

            var vx = Math.abs(t1.screenX - t2.screenX)
            var vy = Math.abs(t1.screenY - t2.screenY)
            var lx = t1.screenX > t2.screenX ? t2.screenX : t1.screenX;
            var ly = t1.screenY > t2.screenY ? t2.screenY : t1.screenY;

            let area = vx * vy
            var change = false
            if (prevDiff > 0) {
                var d = Math.abs(prevDiff - area)
                if (d > 200 && gZoomEnabled) {
                    if (area > prevDiff) {
                        // The distance between the two pointers has increased
                        // console.log("Pinch moving OUT -> Zoom in", event);
                        // event.target.style.background = "pink";

                        handleTouchZoom(lx + vx / 2, ly + vy / 2, -1, area / prevDiff)
                        change = true
                    }
                    if (area < prevDiff) {
                        // The distance between the two pointers has decreased
                        // console.log("Pinch moving IN -> Zoom out", event);
                        // event.target.style.background = "lightblue";
                        handleTouchZoom(lx + vx / 2, ly + vy / 2, 1, area / prevDiff)
                        change = true
                    }
                }
            }

            // Cache the distance for the next move event
            prevDiff = area;
        }
        if (change) return
        for (let touch of Array.from(event.touches)) {
            x += touch.screenX
            y += touch.screenY
        }

        const movex = (x / event.touches.length) - state.panStart.x
        const movey = (y / event.touches.length) - state.panStart.y

        state.panStart.x = x / event.touches.length
        state.panStart.y = y / event.touches.length

        ctx.canvasContainer.scroll(ctx.canvasContainer.scrollLeft - movex,
            ctx.canvasContainer.scrollTop - movey)

        // console.log("MOVE SCROLL", state.panStart.x, state.panStart.y)
        // state.mapbox.panBy(
        //   [
        //     (movex * 1) / -1,
        //     (movey * 1) / -1
        //   ],
        //   { animate: false }
        // )
    }
    function handleTouchStart(e) {
        handleBlur(e)
        evCache.push(e);
        if (e.touches && e.touches.length === 2) {
            return mtStart(e)
        }
        // ctx.message.innerHTML = "touches is "+touches.length
        if (evCache.length >= 2) {
            // sendMobile(["Multitouch ignore 2  ", JSON.stringify(evCache)])
            return
        }
        if (ctx.scrollLock === SCROLLMODE_DRAW) {
            // e.preventDefault() remove this cause the link window.open doesnt work
            //single touch
            const [x, y] = getTouchPos(e)
            // sendMobile(["Mobile Event", JSON.stringify([x,y])])
            ctx.lazy.update({ x: x, y: y }, { both: true })
            handlePointerDown(e)

            ctx.mouseHasMoved = true
        }
    }

    function handleMultiBoardCB(args) {
        ReactGA.event({
            category: 'User',
            action: args.name
        });
        if (args.name === "Goto my book") {
            ib.findCreateSession(sess.Classroom).then((r) => {
                if (r) {
                    props.history.push("/board/" + r.id)
                    window.location.reload()

                } else {
                    const id = uuid()
                    const pg1 = id + "-pgNum-1"
                    //parentBoard should ID 1 too incase teacher clicked on new board
                    const pgSplit = sess.parentBoardID.split("-pgNum-")
                    const ppid = pgSplit[0] + "-pgNum-1"
                    ib.createMultiBoard(ppid, id, sess.ttl)
                    ib.createSession(pg1, "unsaved", "somecontent",
                        id, 1, ppid, sess.boardConfig, sess.Classroom, false, sess.ttl).then((res2) => {
                            props.history.push("/board/" + pg1)
                            window.location.reload()
                        })
                }
            })
        }
        if (args.name === "Goto Instructor") {
            const parentSp = sess.parentBoardID.split("-pgNum-")
            const currSp = props.match.params.boardid.split("-pgNum-")
            const gotoP = parentSp[0] + "-pgNum-" + currSp[1]
            props.history.push("/board/" + gotoP)
            window.location.reload()
        }

        if (args.name === "Grid View") {
            ggridView = { ...ggridView, open: true }
            setgridView({ ...ggridView })
            ctx.canvasContainer.style.display = "none";
            removeAllButtons();
        }
        if (args.name === "Single View") {
            ggridView = { ...ggridView, open: false }
            setgridView({ ...ggridView })
            ctx.canvasContainer.style.display = "block";
            window.location.reload(); //hack for now
        }
        if (args.name === "Grid Options") {
            setGridOptions(p => ({ ...args.gridOptions }));
        }
        setTabMode(args.name)
    }

    function handleTouchMove(e) {
        // var touches = e.changedTouches;

        // Find this event in the cache and update its record with this event
        for (var i = 0; i < evCache.length; i++) {
            if (e.pointerId === evCache[i].pointerId) {
                evCache[i] = e;
                break;
            }
        }
        if (e.touches && e.touches.length === 2) {
            return mtMove(e)
        }
        //https://github.com/mapbox/mapbox-gl-js/issues/2618
        if (evCache.length >= 2) {
            // sendMobile(["Multitouch ignore  ", JSON.stringify(evCache)])
            return
        }
        if (ctx.scrollLock === SCROLLMODE_DRAW) {

            e.preventDefault()
            const [x, y] = getTouchPos(e)

            // const tract = {
            //     down: ctx.isPressing,
            //     drawing: ctx.isDrawing,
            //     x: x,
            //     y: y
            // }
            // sendMobile(["Mobile Move ", JSON.stringify(tract)])

            // ctx.message.innerHTML = "touches is "+touches.length
            handlePointerMove(x, y, e)
        }
    }
    function checkDoubleTap(event) {
        var currentTime = new Date().getTime();
        var tapLength = currentTime - lastTap;
        clearTimeout(dtTimeout);
        if (tapLength < 500 && tapLength > 0) {
            lastTap = 0
            event.preventDefault();
            var dbl = mylocalStorage.getItem("dblClickDisable")
            if (!dbl) handleDblClick(event)
        } else {
            dtTimeout = setTimeout(function () {
                clearTimeout(dtTimeout);
            }, 500);
        }
        lastTap = currentTime;
    }
    function handleTouchEnd(e) {
        evCache = []
        prevDiff = -1;
        if (ctx.mode === 'draw') {
            setShowTextTools(true)
        }
        if (ctx.mode === "text") {
            return handleClick(e)
        }
        if (ctx.mode === "math") {
            return handleClick(e);
        }
        if (ctx.mode === "richText") {
            return handleClick(e)
        }
        if (ctx.mode === "arrow") return startArrow(e)

        if (ctx.mode === "line") {
            startLine(e)
            return
        }
        if (ctx.mode === "rect") return startRect(e)
        if (ctx.mode === "circle") return startCircle(e)
        if (ctx.mode === "youtube") return handleClick(e)
        if (ctx.mode === "website") return handleClick(e)
        if (ctx.mode === "breakApart") return handleClick(e)

        if (hasInput) {
            return handleBlur(e)
        }
        checkDoubleTap(e)

        handlePointerUp(e)
        const brush = getBrushfromLazy()
        ctx.lazy.update({ x: brush.x, y: brush.y }, { both: true })
        ctx.mouseHasMoved = true
    }
    function handleContextMenu(e) {
        e.preventDefault()
    }
    function handleButtonHide(e) {
        // ctx.sidebar.style.display = "none";
        ctx.minNav.style.display = "none";

        ctx.button.show.style.display = "block"
        document.body.classList.toggle('menu-visible')
    }

    // function handleButtonShow(e) {
    //     e.preventDefault()
    //     ctx.button.show.style.display = "none"
    //     ctx.sidebar.style.display = "block";
    //     ctx.minNav.style.display = "block";

    //     document.body.classList.toggle('menu-visible')
    // }

    function updateColor(newColor, userset, force) {
        var ctxUse = ctx ? ctx : gCtx
        if (!ctxUse) return
        if (!force && !allowChange("brushColor")) return

        var luColor = mylocalStorage.getItem('mycolor');
        if (userset) {
            mylocalStorage.setItem('mycolor', newColor)
            luColor = newColor
        }
        if (!userset && newColor === "#ffffff") return
        if (luColor) newColor = luColor
        var ll = mylocalStorage.getItem("inkCopy")
        if (!ll) setInkColorDebounced(newColor);
        ctxUse.color = newColor
        ctxUse.SavedColor = newColor
        // ctxUse.colorSelect.value = newColor
        // ctxUse.uname.style.color = newColor
    }

    var bgColorDebounce = null
    const background = useSelector((state) => state.background);

    function setBgColorDebounce(col) {
        if (bgColorDebounce !== null) {
            clearTimeout(bgColorDebounce);
            bgColorDebounce = null;
        }
        bgColorDebounce = setTimeout(function () {
            // console.log("Setting color to = ", col);
            if (col !== "#ffffff") {
                if (!checkDeletebg()) return
                var rect = new Path.Rectangle({
                    point: [0, 0],
                    size: [paper.view.size.width, paper.view.size.height],
                    strokeColor: 'white',
                    selected: false
                });
                rect.sendToBack();
                rect.fillColor = col;
                rect.data.backGroundCol = { color: col }
                updateDrawPaperObj(rect, donePaper)
                function donePaper(nObj) {
                    rect.remove()
                    gBackGround = nObj
                }
                dispatch(Actions.setBackGround({
                    ...background,
                    notmine: false,
                    color: col
                }))
            }
            clearTimeout(bgColorDebounce);
            bgColorDebounce = null;
        }, 200);
    }

    function checkDeletebg() {
        if (!gBackGround) return true
        if (gBackGround.data.id && paperObj[gBackGround.data.id]) {
            var rr = paperObj[gBackGround.data.id]
            if (rr.gqlObj && rr.gqlObj.Session) {
                if (rr.gqlObj.Session.id !== gSessionID)
                    return false
            }

        }
        if (gBackGround) ib.delObject(gBackGround.data.id)
        return true
    }
    function handleBackGround(bg) {
        if (!bg) {
            checkDeletebg()
            return
        }
        setBgColorDebounce(bg.color)
    }
    function handleSimpleColor(col) {
        updateColor(col, true, false);
    }
    function colorChange(e) {
        e.preventDefault()
        updateColor(e.target.value, true, false)
        ReactGA.event({
            category: 'User',
            action: "Color Change"
        });
    }

    function handleSubmit(e) {
        e.preventDefault()
        ctx.uname.blur()
    }
    function unameChange(myName) {
        if (gUser && gUser.UserProfile && !gTeacher) {
            //logged in users not allowed to change name
            alert("Logged in students cannot change name")
            return
        }
        if (myName !== "") {
            mylocalStorage.setItem('myname', myName);
            checkUpdateUserName(myName)
        } else {
            mylocalStorage.removeItem('myname');
        }
        ReactGA.event({
            category: 'User',
            action: "Name Change"
        });
    }

    function ZoomInApiMode() {
        ctx.mode = 'zoomIn'
        ctx.canvas.interface.style.cursor = "zoom-in"
    }

    function PanMode() {
        ctx.mode = 'panMode'
        ctx.canvas.interface.style.cursor = "move"
    }
    function handleButtonScroll(e) {
        e.preventDefault()
        ctx.scrollLock = !ctx.scrollLock
        setScrollL(ctx.scrollLock)
        ReactGA.event({
            category: 'User',
            action: "Scroll Lock"
        });
    }
    function handleLine(e) {
        if (e === 'Line') {
            mylocalStorage.setItem('lineMode', e)
        } else {
            mylocalStorage.setItem('lineMode', 'normal')
        }
        aniMess('Click to start drawing a line, select brush to get out of line mode')
        myLineStart = { obj: null, start: null }
        ctx.mode = "line"
        ctx.color = ctx.SavedColor
        ctx.brushRadius = ctx.drawBrushRadius
        if (ctx && ctx.slider && ctx.slider.brush && ctx.slider.brush.value) ctx.slider.brush.value = ctx.brushRadius
        seteraseMode(false)
        setdrawMode({
            ...drawMode,
            name: ctx.mode
        })
        ctx.canvas.interface.style.cursor = "crosshair"
    }
    function handleArrow(e) {
        handleEscape()
        myArrowStart = { obj: null, start: null }
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "arrow"
    }

    function stylePath(path) {
        path.fillColor = {
            hue: 180,
            saturation: 0.93,
            lightness: 0.28,
            alpha: 0.2,
        };
        path.closed = false;

        path.strokeColor = '#BEE7F5';
        path.strokeWidth = 1;
    }
    function isInside(_selection, _item) {
        try {
            var result = _item.subtract(_selection);
            var insideSelection = result.isEmpty();
            result.remove();
        } catch (e) {
            if (_item instanceof paper.Layer) return false
            return true
        }
        return insideSelection;
    }

    function startLasso(e) {
        if (lassoPath) {
            lassoPath.selected = false;
            lassoPath.remove()
        }
        lassoPath = new paper.Path();
        stylePath(lassoPath);
    }
    function moveLasso(x, y) {
        if (!lassoPath) return
        lassoPath.add(new Point(x, y));
    }
    function endLasso(event) {
        // When the mouse is released, simplify it:
        // Select the path, so we can see its segments:
        if (!lassoPath) return
        lassoPath.closed = true;
        lassoPath.fullySelected = true;
        var items = paper.project.getItems({ inside: lassoPath.bounds })
        var objects = []
        var ids = []
        items.map((item) => {
            var vv = isInside(lassoPath, item)
            if (vv) {
                selectedObj = item
                var gg = findSelectedGQL()
                if (gg && gg.SessionID !== gSessionID) {
                    //skip teacher
                } else {
                    if (item.data.id) {
                        item.data.lassoed = true
                        ids.push(item.data.id)
                        delete item.data['id']
                        objects.push(item)
                    }
                }
            }
        })
        lassoPath.remove()
        if (objects.length > 0) {
            try {
                var group = new paper.Group(objects)
            } catch (e) {
                return
            }
            group.data.lasso = true
            updateDrawPaperObj(group, donePaper)
            function donePaper(nObj) {
                nObj.selected = true
                objects.map((o) => {
                    o.selected = false
                    o.remove()
                    selectedObj = nObj
                    nObj.data.lasso = true
                    openMenu(event, null)
                })
                ids.forEach(c => {
                    ib.delObject(c)
                })
            }
        }
        ctx.mode = defaultTool
        ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
    };
    function handleLasso(e) {
        handleEscape()

        if (lassoPath) {
            lassoPath.selected = false;
            lassoPath.remove()
            lassoPath = null
        }
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "lasso"
    }
    function handleText(e) {
        handleEscape()
        typeHelp()

        ctx.canvas.interface.style.cursor = "text"
        ctx.mode = "text"
    }
    function textBox() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "rect"
        ctx.subMode = "textBox"
    }
    function DropZone() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "rect"
        ctx.subMode = "DropZone"
    }
    function programmingBox() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "rect"
        ctx.subMode = "textBox"
        ctx.subsub = 'programmingBox'
    }
    function drawScreenShade() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "rect"
        ctx.subMode = "ScreenShade"
    }
    function drawSelect() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "rect"
        ctx.subMode = "select"
    }

    function BreakApart() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "breakApart"
    }

    function handleRect(e) {
        if (e === 'Square') {
            mylocalStorage.setItem('rectMode', 'Square')
        } else {
            mylocalStorage.setItem('rectMode', 'normal')
        }
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "rect"
        ctx.subMode = "none"
    }
    function DotPainter() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "dot"
        ctx.subMode = "none"
    }
    function GridBox() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "rect"
        ctx.subMode = "gridBox"
    }
    function GridPainter() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "gridpainter"
    }
    function gridPainterFill(x, y, e) {
        if (!gGrid || !ctx.isPressing || !gGrid.data.gridSize) return null
        var foundx = 2000, foundy = 0, sz = 0;
        sz = gGrid.data.gridSize / 2

        foundx = Math.floor(x / sz) * sz
        foundy = Math.floor(y / sz) * sz
        if (foundx < 2000 && foundy > 0) {
            var rr = {
                x: foundx,
                y: foundy,
                width: sz,
                height: sz
            }
            if (gridPainter[foundx + "-" + foundy]) {
                return
            }
            var rrT = new paper.Rectangle(rr)
            let rectanglePath = new Path.Rectangle(rrT)
            rectanglePath.fillColor = mkColor(ctx.color, ctx.opacity)
            rectanglePath.data.rectPainter = true
            rectanglePath.data.coord = foundx + "-" + foundy
            gridPainter[foundx + "-" + foundy] = rectanglePath
            updateDrawPaperObj(rectanglePath, donePaper)
            rectanglePath.remove()
            function donePaper(nObj) {
            }
        }
    }
    function handleCircle(e) {
        if (e === 'Circle') {
            mylocalStorage.setItem('circleMode', 'Circle')
        } else {
            mylocalStorage.setItem('circleMode', 'normal')
        }
        handleEscape()
        ctx.canvas.interface.style.cursor = "crosshair"
        ctx.mode = "circle"
    }
    function handlePolygon(e) {
        handleEscape()
        var x = window.innerWidth / 2
        var y = window.innerHeight / 2

        var pointer = new paper.Path.RegularPolygon(new Point(x, y), parseInt(e), 80)
        pointer.data.shape = true
        pointer.data.stackwithtime = true

        pointer.strokeColor = mkColor(ctx.color, ctx.opacity);
        pointer.strokeWidth = ctx.brushRadius * 2
        pointer.dashArray = ctx.lineStyle;
        ctx.mode = "edit"
        ctx.canvas.interface.style.cursor = "crosshair"
        clearSelect()
        selectedObj = pointer
    }
    function handleButtonDraw(e, f) {
        if (e === 'Draw') {
            ctx.mode = "draw"
            ctx.canvas.interface.style.cursor = "auto"
            setdrawMode({ name: 'draw' })
            if (f === undefined) {
                mylocalStorage.setItem('SavedColor', ctx.SavedColor)
                ctx.color = ctx.SavedColor
            } else {
                let SavedColor = mylocalStorage.getItem('SavedColor')
                let clr = SavedColor ? SavedColor : ctx.SavedColor
                ctx.color = f ? f : clr
            }
            ctx.brushRadius = ctx.drawBrushRadius
            if (ctx.slider && ctx.slider.brush && ctx.slider.brush.value) ctx.slider.brush.value = ctx.brushRadius
            seteraseMode(false)
        }
        if (e === 'Erase') {
            //currently drawing. button says erase
            // ctx.color = styleVariables.bgColor
            // ctx.brushRadius = ctx.eraseBrushRadius
            if (ctx.slider && ctx.slider.brush && ctx.slider.brush.value) ctx.slider.brush.value = ctx.brushRadius
            ctx.canvas.interface.style.cursor = "cell"

            ctx.mode = "erase"
            setdrawMode({ name: 'draw' })
            seteraseMode(true)
        }
    }
    function aniMess(mess) {
        if (aniMessage) return
        aniMessage = true
        setMessage(mess)
    }
    function HandleAnimate(e) {
        handleEscape()
        aniMess("Select object to animate or move (can also double click it)")
        ctx.mode = "selectAnimate"
        ctx.canvas.interface.style.cursor = "pointer"
    }

    function handleButtonPause(e) {
        handleEscape()

        gpause = !pauseMode
        aniMess("Double click object to animate")

        setPauseMode(!pauseMode)
    }

    function handleButtonClear() {
        handleEscape()
        if (showCase && !gTeacher && showCase.locked) {
            return
        }
        tileBox = { x: 150, y: 120 }
        formBox = { x: 150, y: 120 }
        const gg = gSession ? gSession.isGroup : false
        const gu = gUser ? gUser.id : null
        ib.delAllObjSession(gSessionID, gg, gu)
        setUndoStack(p => { return [] })
        setRedoStack(p => { return [] })
        removeAllButtons()
        // get the parent back
        // if (gParentSession && gParentSession !== gSessionID) {
        //     ib.listObject(gParentSession, null, gotData)
        // }
        handleCloseMenu()
    }

    // function handleButtonLazy(e) {
    //     e.preventDefault()
    //     ctx.valuesChanged = true
    //     ctx.button.lazy.classList.toggle('disabled')

    //     if (ctx.lazy.isEnabled()) {
    //         ctx.button.lazy.innerHTML = 'Off'
    //         ctx.lazy.disable()
    //     } else {
    //         ctx.button.lazy.innerHTML = 'On'
    //         ctx.lazy.enable()
    //     }
    // }

    function handleSidebarResize(entries, observer) {
        for (const entry of entries) {
            //         const { left, top, width, height } = entry.contentRect
            loop({ once: true })
        }
    }
    function handleCanvasResize(entries, observer) {
        ctx.dpi = 1//window.devicePixelRatio
        for (const entry of entries) {
            setReRender(entry)
            var { width, height } = entry.contentRect
            width = Math.max(width, maxWidth)
            height = Math.max(height, maxHeight)
            setCanvasSize(ctx.canvas.drawing, width, height, 1)
            // setCanvasSize(ctx.canvas.others, width, height, 1)

            // if (!mobile) setCanvasSize(ctx.canvas.grid, width, height, 2)
            // if (!mobile && drawGridCfg && drawGridCfg === "grid") drawGrid(ctx.context.grid)
            // if (!mobile && drawGridCfg && drawGridCfg === "music") drawMusicGrid(ctx.context.grid)

            loop({ once: true })
        }
        gLoadMaps = {}
        doAPILoads()
        getOBJsAgain()
    }

    function handleSliderBrush(e, v, force) {
        const val = v
        if (!force && !allowChange("brushSize")) return
        mylocalStorage.setItem("brushSize", val)
        if (ctx) {
            ctx.valuesChanged = true
            ctx.brushRadius = val
            // draw or erase?
            if (ctx.color === styleVariables.bgColor) {
                ctx.eraseBrushRadius = ctx.brushRadius
            } else {
                ctx.drawBrushRadius = ctx.brushRadius
            }
            setText(ctx.drawBrushRadius, false, false)

        } else {
            gCtx.valuesChanged = true
            gCtx.brushRadius = val
            // draw or erase?
            if (gCtx.color === styleVariables.bgColor) {
                gCtx.eraseBrushRadius = gCtx.brushRadius
            } else {
                gCtx.drawBrushRadius = gCtx.brushRadius
            }
            setText(gCtx.drawBrushRadius, false, false)
        }
        setCurrentBrush(v)
    }

    // function handleSliderLazy(e) {
    //     ctx.valuesChanged = true
    //     const val = parseInt(e.target.value)
    //     ctx.chainLength = val
    //     ctx.lazy.setRadius(val)
    // }

    function handleSliderOpacity(e, v) {
        const val = v
        ctx.valuesChanged = true
        ctx.opacity = val
        setCurrentOpacity(val);
    }

    const [currentText, setCurrentText] = React.useState(BRUSH_RADIUS)
    function setText(v, user, force) {
        if (!force && !allowChange("brushSize")) return

        // var bz = mylocalStorage.getItem("textSize")
        // if (bz && !user) {
        //     //user set something, no need to change with brush size 
        //     gText = parseInt(bz)
        //     setCurrentText(gText)
        //     return
        // }
        if (user) {
            mylocalStorage.setItem("textSize", v)
        }
        gText = v
        // var font = mylocalStorage.getItem("font")
        // if (font && font === "KgTeacherHelpers") gText = v * 5
        setCurrentText(v)
    }
    function handleSliderText(e, v) {
        setText(v, true, false)
    }

    const IDEAL_HIGHLIGHTER_OPACITY = 40
    const [toggleHL, setToggleHL] = useState(false)
    const [reRender, setReRender] = useState(false)
    function toggleHighlighter(e) {
        setToggleHL(!toggleHL)
        let newOpacity = IDEAL_HIGHLIGHTER_OPACITY
        if (ctx.opacity < 100) {
            newOpacity = 100
        } else {
        }
        ctx.valuesChanged = true
        ctx.opacity = newOpacity
        setCurrentOpacity(p => newOpacity)
        try {
            ReactGA.event({
                category: 'User',
                action: 'ChangedHighlight'
            });
        } catch { }
    }

    const dashArray = [10, 6]
    function changeLineStyle(e) {
        let newLineStyle;
        if (ctx.lineStyle) {
            newLineStyle = null
        } else {
            newLineStyle = dashArray
        }
        ctx.valuesChanged = true
        ctx.lineStyle = newLineStyle
        setLineStyle(p => newLineStyle)
        try {
            ReactGA.event({
                category: 'User',
                action: 'ChangedLineStyle'
            });
        } catch { }
    }

    function setCanvasSize(canvas, width, height, maxDpi = 4) {
        if (maxWidth !== 2000) return
        let dpi = ctx.dpi
        // reduce canvas size for hidpi desktop screens
        if (window.innerWidth > 1024) {
            dpi = Math.min(ctx.dpi, maxDpi)
        }
        canvas.width = width * dpi

        canvas.height = height * dpi
        canvas.style.width = width
        canvas.style.height = height
        canvas.getContext('2d').scale(dpi, dpi)
    }
    function clearSelect() {
        if (selectedObj) {
            document.removeEventListener('contextmenu', handleContextMenu)

            setCtxMenu({ open: false, x: 0, y: 0 })
            clearAllSelected()
            selectedObj = null
        }
    }

    function findSelectedKey() {
        var kobjs = Object.keys(paperObj)
        var foundKey = null
        kobjs.forEach((key) => {
            var obj = paperObj[key]
            if (selectedObj.id === obj.obj.id) {
                foundKey = key
            }
        })
        return foundKey
    }

    function findSelectedGQL(s = null) {
        if (!s) s = selectedObj
        var kobjs = Object.keys(paperObj)
        var foundKey = null
        kobjs.forEach((key) => {
            var obj = paperObj[key]
            if (s.id === obj.obj.id) {
                foundKey = obj.gqlObj
            }
        })
        return foundKey
    }

    function getPaperPoint(e) {
        var pt
        if (mobile) {
            const [x, y] = getTouchPos(e)
            pt = new paper.Point(x, y)
        } else {
            let b = getOffsetZoom(e)
            pt = new paper.Point(b.x, b.y)
        }
        return pt
    }

    function handleLassoStd(typ, fk, vvv) {
        for (let i = 0; i < selectedObj.children.length; i++) {
            var child = selectedObj.children[i]
            if (child.data.lassoed) {
                delete child.data['lassoed']
                child.data[typ] = vvv
                if (typ === "studentMove") {
                    child.data.studentMoveParentID = uuid()
                }
                updateDrawPaperObj(child, null)
            } else {
            }
        }
        clearSelect()
        if (fk) {
            ib.delObject(fk)
        }
    }

    function handleEditMath(foundKey) {
        var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
        setShowTextTools(true)
        var rect = selectedObj.bounds
        var loc = { x: rect.x, y: rect.y }
        savePos = { ...loc, fonts: selectedObj.data.fonts }
        var sy = ctx.canvasContainer.scrollTop
        var sx = ctx.canvasContainer.scrollLeft
        addMathInput(loc.x - sx + drawCanvasPos.x, loc.y - sy + drawCanvasPos.y, selectedObj.data.mathValue);
        clearSelect()
        if (objDict && objDict[foundKey] && objDict[foundKey].obj) {
            pushUndoStack('mod', objDict[foundKey].obj);
        }
        ib.delObject(foundKey)
    }
    const [loopProps, setLoopDialog] = React.useState({ open: false, cb: null })

    function codingHit(e, evt) {
        let f = e
        var p = e.parent
        var pid = p.data.id

        if (f.data.linkData && f.data.linkData.formType === "text") {
            setLoopDialog({ open: true, cb: done })
            return
        }
        function done(e1) {
            setLoopDialog({ open: false, cb: null })
            if (!e1) return
            p.children.forEach(c => {
                if (c.data.codingType && c.data.codingType.type === "loopCondition") {
                    c.content = e1.type + ":" + e1.value
                    c.data.codingType.loopValue = e1
                    p.data.codingType.loopValue = e1
                }
            })
            if (paperObj[pid].gqlObj.SessionID === gSessionID) {
                updateDrawPaperGqlObj(paperObj[pid].gqlObj, p, doneD)
                function doneD() {
                    p.remove()
                }
            }
        }
    }
    function drawLoop() {
        var center = new Point(window.innerWidth / 2, window.innerHeight / 2)
        var id = uuid()
        const cardwidth = 200
        const cardHeight = 250
        let rr = {
            x: center.x,
            y: center.y,
            width: cardwidth,
            height: cardHeight,
        }
        var obj = []
        var cornerSize = new paper.Size(10, 10);

        var rrT2 = new paper.Rectangle(rr)
        let rectanglePath2 = new Path.Rectangle(rrT2, cornerSize);
        rectanglePath2.data.codingType = { loop: true, type: "loopRect" }
        rectanglePath2.strokeWidth = 5;
        rectanglePath2.fillColor = mkColor(ctx.SavedColor, 5)
        rectanglePath2.strokeColor = ctx.SavedColor;

        obj.push(rectanglePath2)

        var font = mylocalStorage.getItem("font")
        font = font ? font : "Roboto"
        var b = rectanglePath2.bounds.topLeft
        var text = new paper.PointText(new paper.Point(b.x + 10, b.y + 25));
        text.content = "Repeat:";
        text.style = {
            fontFamily: font,
            fontSize: 12,
            fillColor: ctx.SavedColor,
            justification: 'left'
        };
        text.data.codingType = { loop: true, type: "textlabel" }
        obj.push(text)


        rr = {
            x: b.x + 60,
            y: b.y + 7,
            height: 40,
            width: 80,
        }
        var rrT = new Path.Rectangle(rr)
        rrT.strokeColor = ctx.SavedColor
        rrT.width = 1
        rrT.dashArray = [2, 2]
        rrT.fillColor = mkColor(ctx.SavedColor, 2)
        rrT.data.linkData = {
            type: "coding", formType: "text", id: id, placeholder: "loop",
            value: "loop-" + 1, display: "loop"
        }
        rrT.data.codingType = { loop: true, type: "loopBox" }
        rrT.data.linkDataButtonDraw = true
        rrT.data.linkButton = true
        obj.push(rrT)


        text = new paper.PointText(new paper.Point(rrT.bounds.x + 2, rrT.bounds.center.y));
        text.content = "once";
        text.style = {
            fontFamily: font,
            fontSize: 12,
            fillColor: ctx.SavedColor,
            justification: 'left'
        };
        text.data.codingType = { loop: true, type: "loopCondition" }
        text.data.linkData = {
            type: "coding", formType: "text", id: id, placeholder: "loop",
            value: "loop-" + 1, display: "loop"
        }
        text.data.linkDataButtonDraw = true
        text.data.linkButton = true

        obj.push(text)

        var g1 = new paper.Group(obj)
        g1.data.codingType = { loop: true, type: "group" }
        g1.data.linkData = { type: "coding", formType: "GROUP", id: id }
        g1.data.linkDataButtonDraw = true
        g1.data.stackwithtime = true

        selectedObj = g1
        ctx.mode = "edit"
        ctx.canvas.interface.style.cursor = "crosshair"


    }

    function addCoded(o) {
        const functions = {
            'Loop': drawLoop
        }
        if (o && o.name in functions) {
            functions[o.name]()
        }
    }
    function addFromPalette(o) {
        function processVars(ob) {
            if (ob instanceof paper.PointText) {
                if (ob.content.includes("$date")) {
                    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

                    ob.content = ob.content.replace("$date", new Date().toLocaleDateString("en-US", options))
                }
            }
            if (ob.children) {
                ob.children.forEach(pc => {
                    processVars(pc)
                })
            }
        }
        var k = pako.inflate(o.content, { to: 'string' });
        const ct = JSON.parse(k)
        var pobj = new paper[ct[0]]()
        pobj.importJSON(ct)
        selectedObj = pobj
        processVars(pobj)
        processClone(selectedObj, pobj)
        if (selectedObj.data.studentMoveParentID) {
            selectedObj.data.studentMoveParentID = uuid() //new one for the clone 
        }
        ctx.lastMode = ctx.mode
        ctx.mode = "edit"
        ctx.canvas.interface.style.cursor = "crosshair"
    }

    const [yn, setyn] = React.useState({ open: false, message: "", cb: null })

    async function makeJigsaw() {
        clearAllSelected()
        await new Promise(r => setTimeout(r, 1000));

        setyn({
            open: true, link:
                "", message: "Number of Pieces",
            cb: doneye, text: true
        })
        async function doneye(e) {

            setyn({ open: false, message: "", cb: null })
            if (!e) return
            var pie = parseInt(e)
            if (pie > 20 || !pie) {
                alert("Invalid number of pieces - please pick <= 20")
                return
            }
            var newCanvas = document.createElement('canvas');
            let rect = selectedObj.bounds
            var newContext = newCanvas.getContext('2d');

            var x = rect.topLeft.x - 2
            var y = rect.topLeft.y - 2
            var w = rect.width + 4
            var h = rect.height + 4
            var n = pie
            var countHor = Math.floor(Math.sqrt(n));
            var countVer = Math.ceil(n / countHor);
            var ew = w / countVer;
            var eh = h / countHor;

            var oldc = document.getElementById("canvas_drawing")
            for (let rc = 0; rc < countVer; rc++)
                for (let i = 0; i < countHor; i++) {
                    newCanvas.width = ew;
                    newCanvas.height = eh;
                    x = rect.topLeft.x + rc * ew
                    y = rect.topLeft.y + i * eh
                    newContext.drawImage(oldc, x, y, ew, eh, 0, 0, ew, eh);
                    var img = newCanvas.toDataURL("image/png", 1)
                    let dx = rect.topRight.x + ew + 10 + (rc * ew)
                    let dy = y + 40
                    var raster = new paper.Raster({ crossOrigin: 'anonymous', source: img, });
                    raster.scale(0.9)
                    raster.position = new Point(dx, dy)
                    raster.data.studentMoveParentID = uuid()
                    raster.data.studentMove = true
                    raster.data.fixSize = true
                    updateDrawPaperObj(raster, null)
                    raster.remove()
                }
            clearSelect()
        }

    }

    async function addPalette() {
        clearAllSelected()
        await new Promise(r => setTimeout(r, 1000));

        var sel = selectedObj.clone()
        sel.data.id = null
        function resolveChildren(o) {
            if (o instanceof paper.Raster) {
                o.source = o.toDataURL('image/png')
            }
        }
        if (sel.children) {
            for (let i = 0; i < sel.children.length; i++) {
                var child = sel.children[i]
                resolveChildren(child)
            }
        }
        resolveChildren(sel)

        var newCanvas = document.createElement('canvas');
        let rect = selectedObj.bounds
        var newContext = newCanvas.getContext('2d');
        var x = rect.topLeft.x - 10
        var y = rect.topLeft.y - 10
        var w = rect.width + 20
        var h = rect.height + 20
        var oldc = document.getElementById("canvas_drawing")
        newCanvas.width = w;
        newCanvas.height = h;
        newContext.drawImage(oldc, x, y, w, h, 0, 0, w, h);
        var img = newCanvas.toDataURL("image/png", 1)
        // var newImage = document.createElement('img');
        // newImage.src = img; 
        // document.body.appendChild(newImage);

        setPaletteDialog({ open: true, obj: img, cb: done })
        // var pdf = new jsPDF('l', 'pt', 'a4');
        // pdf.addImage(img, 'PNG',0,0, w, h) 
        // pdf.save("epic-board.pdf")

        async function done(e) {
            clearSelect()
            setPaletteDialog({ open: false, obj: null, cb: null })
            if (!e) return
            var written = await iu.WritePalette(img)
            var luid = mylocalStorage.getItem('mystoreID');
            var cmd = {
                path: written,
                owner: luid,
                name: e.name
            }
            if (!e.imageOnly) {
                var j = sel.exportJSON()
                cmd.content = pako.deflate(j, { to: 'string' });
            }
            ib.createPersonalPalette(cmd)
            if (e.checked) {
                ib.createCommunityPalette(cmd)
            }
        }
    }
    const [fillColor, setFillColor] = React.useState({ pickerOpen: false, cb: null })
    const [colorPicker, setColorPicker] = React.useState({ pickerOpen: false, cb: null })

    function handleColorPicker() {
        setColorPicker({ pickerOpen: true, cb: done })
        function done(e) {
            if (e.color) {
                updateColor(e.color, true, false);
                setPenBtnColor(e.color)
            }
        }
    }
    function handleFillColor(obj, key) {
        clearSelect()
        setFillColor({ pickerOpen: true, cb: done })
        function done(e) {
            if (!e) {
                setFillColor({ pickerOpen: false, cb: null })
                return
            }
            if (e.action === 'colorPick') {
                if (e.where === "stroke") {
                    obj.strokeColor = e.color
                } else {
                    obj.fillColor = e.color
                }
            }
            if (e.action === 'removeFill') {
                if (e.where === "stroke") {
                    obj.strokeColor = null
                } else {
                    obj.fillColor = null
                }
            }
            updateDrawPaperObj(obj, done2)
            function done2(newObj) {
                ib.delObject(key)
                key = newObj.data.id
            }
        }
    }

    function loadRaster(img, x, y, scale) {
        var raster = new paper.Raster({ crossOrigin: 'anonymous', source: img, });
        raster.scale(scale)
        raster.position = new Point(x, y)
        var p = new Promise((resolve, reject) => {
            raster.onLoad = function () {
                raster.position = new Point(x, y + (raster.height * scale / 2))
                resolve(raster)
            }
            raster.onError = function (f) {
                console.log("CANNOT LOAD", img, f)
                reject(f)
            }
        })
        return ({ r: raster, p: p })
    }


    function IsNumber(text) {
        if (text === '00') {
            return false
        }
        for (var i = 0; i < text.length; i++) {
            var c = text[i].toLowerCase()
            if (c >= 0 && c <= 30) {
                return true
            } else {
                return false
            }
        }
    }


    async function makeSignTile(text, x, y, col) {
        var obj = []
        var startx = x + 40
        var starty = y
        var cx = 0
        var cy = starty
        var rScale = 0.5
        var prom = []
        var strArr = text.split(' ')
        var lengOfStr = 0
        strArr.forEach(element => {
            for (var i = 0; i < element.length; i++) {
                var c = element[i].toLowerCase()
                var posX = false
                var write = false
                var isNo = IsNumber(element)

                if (element.length === 2 && isNo && element >= 0 && element <= 30) {
                    c = element
                    write = true
                    i += 2
                    posX = true
                    rScale = 0.8
                } else {
                    if (c >= 'a' && c <= 'z') {
                        c = c + "1"
                        write = true
                        rScale = 0.5
                    }
                    else if (c >= 0 && c <= 30) {
                        write = true
                        rScale = 0.8
                    }
                }

                if (c === "\n" || !c || c === ' ') {
                    continue
                    // cy += 120
                    // cx = i + 1
                }
                if (write) {
                    var img = "/asl/" + c + '.png'
                    var tempI = lengOfStr + (posX ? i - 2 : i)
                    let p = loadRaster(img, startx + ((tempI - cx) * 60), cy, rScale)
                    obj.push(p.r)
                    prom.push(p.p)
                }
            }
            lengOfStr += element.length
        });

        await Promise.all(prom)

        var g = new paper.Group(obj)
        let b = g.bounds
        var cornerSize = new paper.Size(2, 2);
        let rectanglePath = new Path.Rectangle(b, cornerSize)
        rectanglePath.strokeColor = col
        rectanglePath.strokeWidth = 3
        g.addChild(rectanglePath)
        return g
    }
    async function handleSignLang(e, foundKey) {
        let iv = { text: selectedObj._content }
        var b = selectedObj.bounds

        var r = await makeSignTile(iv.text, b.bottomRight.x, b.bottomRight.y, ctx.SavedColor)
        clearSelect()

        selectedObj = r
        ctx.mode = "edit"
        ctx.canvas.interface.style.cursor = "crosshair"
    }

    function handleEditText(e, foundKey) {
        if (selectedObj.data.richText) return editRichText(foundKey)
        inValue = { text: selectedObj._content, size: selectedObj.data.textBoxSize }
        if (selectedObj.data.fonts) {
            const f = selectedObj.data.fonts
            inValue['font'] = f.font
            inValue['color'] = f.color
            inValue['brushSize'] = f.sz
        } else {
            if (objDict && objDict[foundKey] && objDict[foundKey].obj) {
                try {
                    let c2 = JSON.parse(objDict[foundKey].obj.content)
                    if ('font' in c2) {
                        inValue['font'] = c2.font
                        inValue['color'] = c2.color
                        inValue['brushSize'] = c2.brushRadius
                    }

                } catch (e) {
                    console.error("ERROR ", e)
                }
            }
        }
        handleKeyDown(e, selectedObj)
        selectedObj.remove()
        clearSelect()
        if (objDict && objDict[foundKey] && objDict[foundKey].obj) {
            pushUndoStack('mod', objDict[foundKey].obj);
        }
        ib.delObject(foundKey)
    }
    const valueCoin = {
        '1c': { v: 0.01, s: "$", d: 2 },
        '2c': { v: 0.02, s: "$", d: 2 },
        '5c': { v: 0.05, s: "$", d: 2 },
        '10c': { v: 0.1, s: "$", d: 2 },
        '20c': { v: 0.2, s: "$", d: 2 },
        '25c': { v: 0.25, s: "$", d: 2 },
        '50c': { v: 0.5, s: "$", d: 2 },

        '1d': { v: 1, s: "$", d: 2 },
        '1': { v: 1, s: "$", d: 2 },
        '5': { v: 5, s: "$", d: 2 },
        '10': { v: 10, s: "$", d: 2 },
        '20': { v: 20, s: "$", d: 2 },
        '50': { v: 50, s: "$", d: 2 },
        'cent25': { v: 0.25, s: "$", d: 2 },
        'cent10': { v: 0.10, s: "$", d: 2 },
        'cent5': { v: 0.5, s: "$", d: 2 },
        'dollar1': { v: 1, s: "$", d: 2 },
        'dollar2': { v: 2, s: "$", d: 2 },
        'bill5': { v: 5, s: "$", d: 2 },
        'bill10': { v: 10, s: "$", d: 2 },
        'bill20': { v: 20, s: "$", d: 2 },
        'bill50': { v: 50, s: "$", d: 2 },
        'bill100': { v: 100, s: "$", d: 2 },

        '100eu': { v: 100, s: "€", d: 2 },
        'coin-2eu.png': { v: 2, s: "€", d: 2 },
        'coin-1eu.png': { v: 1, s: "€", d: 2 },
        'coin-50c.png': { v: 0.50, s: "€", d: 2 },
        'coin-20c.png': { v: 0.20, s: "€", d: 2 },
        'coin-10c.png': { v: 0.1, s: "€", d: 2 },
        'coin-5c.png': { v: 0.05, s: "€", d: 2 },
        'bill-500eu.png': { v: 500, s: "€", d: 2 },
        'bill-200eu.png': { v: 200, s: "€", d: 2 },
        'bill-100eu.png': { v: 100, s: "€", d: 2 },
        'bill-50eu.png': { v: 50, s: "€", d: 2 },
        'bill-20eu.png': { v: 20, s: "€", d: 2 },
        'bill-10eu.png': { v: 10, s: "€", d: 2 },
        'bill-5eu.png': { v: 5, s: "€", d: 2 },

        '25p': { v: 0.25, s: "₹", d: 2 },
        '50p': { v: 0.50, s: "₹", d: 2 },
        '1rs': { v: 1, s: "₹", d: 2 },
        '2rs': { v: 2, s: "₹", d: 2 },
        '5rs': { v: 5, s: "₹", d: 2 },
        '10rs': { v: 10, s: "₹", d: 2 },
        '20rs': { v: 20, s: "₹", d: 2 },
        '50rs': { v: 20, s: "₹", d: 2 },
        '100rs': { v: 100, s: "₹", d: 2 },
        '200rs': { v: 200, s: "₹", d: 2 },
        '500rs': { v: 500, s: "₹", d: 2 },
        '2000rs': { v: 2000, s: "₹", d: 2 },

        'pvdisk1000': { v: 1000, s: "", d: 2 },
        'pvdisk100': { v: 100, s: "", d: 2 },
        'pvdisk10': { v: 10, s: "", d: 2 },
        'pvdisk1': { v: 1, s: "", d: 2 },
        'pvdiskpoint1': { v: 0.1, s: "", d: 2 },
        'pvdiskpoint01': { v: 0.01, s: "", d: 2 },
        '1000block': { v: 1000, s: "", d: 0 },
        '100block': { v: 100, s: "", d: 0 },
        '10block': { v: 10, s: "", d: 0 },
        '1block': { v: 1, s: "", d: 0 },

        '1fraction': { v: "1", s: "", d: 0, fract: true },
        '12%20fract': { v: "1/2", s: "", d: 0, fract: true },
        '13f': { v: "1/3", s: "", d: 0, fract: true },
        '14f': { v: "1/4", s: "", d: 0, fract: true },
        '15f': { v: "1/5", s: "", d: 0, fract: true },
        '16f': { v: "1/6", s: "", d: 0, fract: true },
        '18f': { v: "1/8", s: "", d: 0, fract: true },
        '110f': { v: "1/10", s: "", d: 0, fract: true },
        '112f': { v: "1/12", s: "", d: 0, fract: true },
        '116f': { v: "1/16", s: "", d: 0, fract: true },
        'posx2al': { v: "x^2", s: "", d: 0, algebric: true },
        'pos1al': { v: "1", s: "", d: 0, algebric: true },
        'posxal': { v: "x", s: "", d: 0, algebric: true },
        'posyal': { v: "y", s: "", d: 0, algebric: true },
        'posxyal': { v: "xy", s: "", d: 0, algebric: true },

        'posy2al': { v: "y^2", s: "", d: 0, algebric: true },
        'neg1al': { v: "-1", s: "", d: 0, algebric: true },
        'negxal': { v: "-x", s: "", d: 0, algebric: true },
        'negx2al': { v: "-x^2", s: "", d: 0, algebric: true },
        'negyal': { v: "-y", s: "", d: 0, algebric: true },
        'negy2al': { v: "-y^2", s: "", d: 0, algebric: true },
        'negxyal': { v: "-xy", s: "", d: 0, algebric: true },

    }
    function MyRep(str, replaceWhat, replaceTo) {
        replaceWhat = replaceWhat.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        var re = new RegExp(replaceWhat, 'g');
        return str.replace(re, replaceTo);
    }
    function CheckAllDropCurrency(inObj = null) {
        var removals = [".png", "ncoin", "head", "-", "coin", "bill", "tail", "Coin"]
        var skips = ["eu"]
        var k = Object.keys(gDropZone)
        var sym = ""
        var dec = 2
        var typeofX = "curr"
        k.forEach(kk => {
            var dz = gDropZone[kk]
            // check parent is right 
            if (dz.data.linkData && dz.data.linkData.dzType === "group" && dz.data.linkData.subtype === "currencyCounter") {
                var pid = dz.data.id
                if (!paperObj[pid]) {
                    return
                }
                var gql = paperObj[pid].gqlObj
                if (gql.SessionID !== gSessionID) {
                    return // not mine 
                }
                var arr = []
                var value = 0
                // get value first 
                dz.children.forEach(c => {
                    if (c.data.linkData && c.data.linkData.dzType === "dropZone") {
                        var foundPassed = false
                        if (!inObj || inObj.dz.data.linkData.id !== dz.data.linkData.id) foundPassed = true
                        var items = paper.project.getItems({ inside: c.bounds })
                        items.map((item) => {
                            var vv = isInside(c, item)
                            if (vv) {
                                if (inObj && inObj.dz.data.linkData.id === dz.data.linkData.id) {
                                    // if the passed obj is counted already dont double count
                                } else {
                                    processElemMeat(item, arr)
                                }

                            }
                        })
                        if (!foundPassed) {
                            // console.log("NOT FOUND PASSED", inObj.obj)
                            processElemMeat(inObj.obj, arr)
                        }
                    }
                })
                typeofX = "curr"
                arr.forEach(a => {
                    if (a.includes("http")) {
                        var filename = a.split(/[\\\/]/).pop();
                        var skip = false
                        skips.forEach(r => {
                            if (filename.includes(r)) {
                                skip = true
                            }
                        })
                        if (!skip) {
                            removals.forEach(r => {
                                filename = MyRep(filename, r, "")
                            })
                        }
                        filename = filename.toLowerCase()
                        console.log("FILENAME IS ", filename)
                        if (filename in valueCoin) {
                            let v = valueCoin[filename]
                            sym = v.s
                            dec = v.d
                            if (v.fract) {
                                if (value === 0) {
                                    value = new Fractional(v.v)
                                    typeofX = "fract"
                                } else {
                                    value = value.add(new Fractional(v.v))
                                }
                            } else if (v.algebric) {
                                if (value === 0) {
                                    value = v.v
                                    typeofX = "algebric"
                                } else {
                                    value = value + "+" + v.v
                                }
                            } else {
                                value += v.v
                            }
                        }
                    }

                })
                function removeOldSVG() {
                    var rem = null
                    dz.children.forEach(c => {
                        if (c.data.linkData && c.data.linkData.dzType === "svg") {
                            rem = c
                        }
                    })
                    if (rem) {
                        rem.remove();
                    }
                }
                dz.children.forEach(c => {
                    if (c.data.linkData && c.data.linkData.dzType === "text") {
                        let t = c.data.linkData
                        if (t.value !== value) {
                            removeOldSVG()
                            if (typeofX === "fract") {
                                c.content = value.toString()
                                doneUpdate()
                            } else if (typeofX === "algebric") {
                                import("mathjs").then(math => {
                                    let al = math.simplify(value).toString()
                                    c.content = " "
                                    setMathInput({ inputValue: al, sendInput: true, cb: doneMath })
                                    function doneMath(sv) {
                                        var newsv = paper.project.importSVG(sv, function (item) {
                                            var svg = item
                                            svg.scale(7 * 3)
                                            svg.position = new paper.Point(c.bounds.topRight.x + 20 + (item.bounds.width / 2),
                                                c.bounds.topRight.y);
                                            svg.fillColor = ctx.SavedColor;
                                            svg.data.linkData = {
                                                type: "DropZone", dzType: "svg", subtype: "currencyCounter", value: al
                                            }
                                        });
                                        dz.addChild(newsv)
                                        doneUpdate()
                                    }
                                })
                            } else {
                                c.content = sym + " " + value.toFixed(dec);
                                doneUpdate()
                            }
                            function doneUpdate() {
                                t.value = value
                                c.fillColor = ctx.SavedColor
                                updateDrawPaperGqlObj(paperObj[pid].gqlObj, dz, null)
                                dz.remove()
                            }
                        }
                    }
                })
            }
        })
    }
    function setBorder() {
        var arr = []
        processElemMeat(selectedObj, arr)
        if (arr[0]) {
            if (arr[0].includes("http")) {
                mylocalStorage.setItem("backgroundFrame", arr[0])
                setIsBorderSet(true)
            }
            else {
                mylocalStorage.setItem("backgroundFrame", arr[0])
                setIsBorderSet(true)
            }
        }
    }
    function DropZoneHit(e, evt) {
        if (e.data.linkData.dzType === "toss") return tossClick(e)
        if (e.data.linkData.dzType !== "reset") return
        var p = e
        var c = 7
        while (p && p.data && p.data.linkData && p.data.linkData.dzType !== "group") {
            p = p.parent
            c--
            if (c === 0) {
                return
            }
        }
        if (!p) return
        var pid = p.data.id
        var gql = paperObj[pid] ? paperObj[pid].gqlObj : null
        if (!gql) {
            return
        }
        var dz = p
        if (gql.SessionID !== gSessionID) return
        dz.children.forEach(c => {
            if (c.data.linkData && c.data.linkData.dzType === "dropZone") {
                var items = paper.project.getItems({ inside: c.bounds })
                items.map((item) => {
                    var vv = isInside(c, item)
                    if (vv) {
                        if (item.data.id) {
                            ib.delObject(item.data.id)
                        }
                    }
                })
            }
            if (c.data.linkData && c.data.linkData.dzType === "text") {
                c.content = "$ 0"
                c.data.linkData.value = 0
            }
        })
        updateDrawPaperGqlObj(paperObj[pid].gqlObj, dz, null)
    }
    function dropOnCurrency(dz, obj) {
        var p = dz
        if (p.data.linkData.dzType !== "group") {
            p = p.parent
        }

        var pid = p.data.id
        var gql = paperObj[pid] ? paperObj[pid].gqlObj : null
        if (!gql) return
        if (gql.SessionID !== gSessionID && p.data.studentMove) {
            p.remove()
            var ff = p.clone()
            ff.data.id = null
            ff.data.studentMove = false
            ff.data.studentMoveParent = p.data.studentMoveParentID;
            ff.data.cannotdelete = true
            ff.data.lock = true
            ff.data.fixSize = p.data.fixSize;
            ff.data.linkData = JSON.parse(JSON.stringify(p.data.linkData))
            ff.data.linkData.id = uuid()
            updateDrawPaperObj(ff, done)
            function done(o) {
                CheckAllDropCurrency({ dz: o, obj: obj })
            }
        } else {
            CheckAllDropCurrency(null)
        }
    }
    function makeCurrency(obj, fk) {
        var id = uuid()
        if (obj.data.linkData.subtype === "currencyCounter") return
        function drawText(sx, sy, col, nameSet) {
            var font = "Roboto"
            var text = new paper.PointText(new paper.Point(sx, sy));
            text.content = nameSet
            text.style = {
                fontFamily: font,
                fontSize: 21,
                fillColor: col,
                justification: 'left'
            };
            return text
        }
        var f = obj.bounds
        var t = drawText(f.topLeft.x, f.topLeft.y - 20, ctx.SavedColor, "$ 0")
        obj.data.linkData.subtype = "currencyCounter";
        obj.data.linkData.dzType = "dropZone"
        t.data.linkData = {
            type: "DropZone", id: id, dzType: "text", subtype: "currencyCounter", value: 0
        }

        let rr2 = {
            x: obj.bounds.bottomLeft.x,
            y: obj.bounds.bottomLeft.y + 10,
            height: 30,
            width: 80,
        }
        var rrLink = { type: "DropZone", subtype: "currencyCounter", dzType: "reset" }

        var but = createButtonCommon(obj, rrLink, "Reset", rr2)
        var g1 = new paper.Group([obj, t, but])
        g1.data.linkData = {
            type: "DropZone", id: id, dzType: "group", subtype: "currencyCounter"
        }
        g1.data.linkButton = true
        g1.data.studentMoveParentID = id
        g1.data.studentMove = true
        g1.data.linkDataButtonDraw = true
        g1.data.stackwithtime = true

        updateDrawPaperObj(g1, done)
        function done() {
            clearSelect()
            g1.remove()
            t.remove()
            obj.remove()
            ib.delObject(fk)
        }
    }


    function recursiveFinds(b, itemArr, maxR) {
        var wid = 100;
        if (maxR === 0) {
            console.error("MAX RECURSU")
            return
        }
        if (b.id in itemArr) {
            return
        }
        var inside = [];
        var items = paper.project.getItems({ inside: b.bounds })
        items.map((item) => {
            var vv = isInside(b, item)
            if (item.id === b.id) return null
            if (vv) {
                // adjust width
                let wh = item.bounds
                if (wh.width < 0.8 * wid) {
                    wid = wh.width
                }
                if (item.data.codingType && item.data.codingType.type === "group") {
                    recursiveFinds(item, itemArr, maxR - 1)
                }
            }
        })
        //second loop for objects inside 
        items.map((item) => {
            var vv = isInside(b, item)
            if (vv) {
                //check if its inside another block in item already then dont process
                var found = false;
                Object.keys(itemArr).forEach(k => {
                    var ii = itemArr[k]
                    if (ii.array && ii.array.length > 0) {
                        ii.array.forEach(kk => {
                            if (kk.item && kk.item.id === item.id) {
                                found = true
                            }
                        })
                    }
                })
                if (item.id === b.id) return null
                if (found) return null
                let wh = item.bounds.center
                var cols = Math.floor(b.bounds.bottomRight.x / wid)
                var rowNum = Math.floor(wh.y / wid)
                var colNum = Math.floor(wh.x / wid)
                var lnux = ((rowNum - 1) * cols) + colNum
                if (item.data.codingType && item.data.codingType.type === "group") {
                    var p = { codeBlock: item.id, lineNum: lnux, item: item }
                    inside.push(p)
                }
                var arr = []
                processElemMeat(item, arr)
                if (arr.length > 0 && arr[0].includes("arrow")) {
                    var filename = arr[0].split(/[\\\/]/).pop();
                    var p = { code: filename, lineNum: lnux, item: item }
                    inside.push(p)
                }
            }
        })
        inside.sort(function (a, b) {
            if (a.lineNum > b.lineNum) {
                return 1
            } else {
                return -1
            }
        })
        itemArr[b.id] = { array: inside, obj: b }
    }
    function processCodingBox(b) {
        var allArr = {};
        var subject = null;
        recursiveFinds(b, allArr, 6)
        var items = paper.project.getItems({ inside: b.bounds })
        items.map((item) => {
            var vv = isInside(b, item)
            if (vv) {
                var arr = []
                if (item.data.codingType) {
                    return null
                }
                processElemMeat(item, arr)
                if (arr.length > 0 && arr[0].includes("arrow")) {
                } else {
                    if (subject) {
                        alert("Cannot have 2 subjects in coding box");
                    }
                    var arr = []
                    processElemMeat(item, arr)
                    subject = { item: item, meat: arr[0] }
                }
            }
        })
        if (!subject) {
            alert("subject not found")
            return
        }

        //find existing clones ?

        var subs = []
        paper.project.activeLayer.children.forEach(c => {
            var arr = []
            processElemMeat(c, arr)
            if (arr[0]) {
                if (arr[0] === subject.meat) {
                    if (c.id === subject.item.id) {
                        return
                    }
                    if (c.data.AutoClone) return
                    subs.push(c)

                }
            }
        })

        subs.forEach(r => {
            runCode(b, r, allArr)
        })
    }
    function delObj(sid) {
        paper.project.activeLayer.children.forEach(c => {
            if (c.data && c.data.AutoClone === sid) {
                c.remove()
                ib.delObject(c.data.id)
            }
        })
    }
    function runCode(b, sub, itemArr) {
        if (!itemArr[b.id] || !itemArr[b.id].array) return
        delObj(sub.data.id)
        var basics = `
        async function MoveObject(dir, obj) {
            return await window.code('MoveObject', dir, obj)
        }
        async function AddObject(obj) {
            return await window.code('AddObject', obj)
        }
        `;
        const translate = {
            'forward.png': "await MoveObject('forward',obj);",
            'down.png': "await MoveObject('down',obj);",
            'up.png': "await MoveObject('up',obj);",
            'back.png': "await MoveObject('back',obj);",
            'turnright.png': "await MoveObject('rotater',obj);",
            'turnleft.png': "await MoveObject('rotatel',obj);",

        }
        window.paper = paper;
        window.codingKey = b.data.codingKey; //should go in a variable 
        window.code = blockCodeFunction;
        var j = sub.exportJSON()
        var rr = JSON.parse(j)
        rr[1]['data'].AutoClone = sub.data.id;
        j = JSON.stringify(rr);

        var fn = "RunMe" + b.id
        basics += "async function " + fn + "() {"
        basics += "var obj = await AddObject('" + j + "');\n";

        function processCodeBlock(c) {
            var str = ""
            if (c.item && itemArr[c.codeBlock]) {
                let g = c.item.data
                if (g.codingType && g.codingType.loopValue) {
                    let l = g.codingType.loopValue
                    str += "for (let i=0; i<" + l.value + ";i++) {\n"
                    for (let i = 0; i < itemArr[c.codeBlock].array.length; i++) { //main
                        let d = itemArr[c.codeBlock].array[i]

                        if (d.code in translate) {
                            str += translate[d.code] + "\n";
                        }
                        if (d.codeBlock) {
                            str += processCodeBlock(d)
                        }
                    }
                    str += "}\n"
                }
            }
            return str
        }
        for (let i = 0; i < itemArr[b.id].array.length; i++) { //main
            let c = itemArr[b.id].array[i]
            if (c.code in translate) {
                basics += translate[c.code] + "\n";
            }
            if (c.codeBlock) {
                basics += processCodeBlock(c)
            }
        }
        basics += "}\n" + fn + "();\n"
        try {
            window.eval(basics)
        } catch (e) {
            console.log("ERROR", e)
            alert('error', e)
        }
    }
    const Outfunctions2 = {
        'MoveObject': MoveObject,
        'AddObject': AddObject,
    }
    async function MoveObject(dir, obj) {
        return new Promise((resolve, reject) => {

            if (dir === "up") {
                obj.position.y -= window.codingKey.stepSize;
            }
            if (dir === "down") {
                obj.position.y += window.codingKey.stepSize;
            }
            if (dir === "forward") {
                obj.position.x += window.codingKey.stepSize;
            }
            if (dir === "back") {
                obj.position.x -= window.codingKey.stepSize;
            }
            if (dir === "rotater") {
                obj.rotate(90);
            }
            if (dir === "rotatel") {
                obj.rotate(-90);
            }
            var rr = obj.data.id
            if (rr && paperObj[rr] && paperObj[rr].gqlObj) {
                updateDrawPaperGqlObj(paperObj[rr].gqlObj, obj, null)
                setTimeout(function () {
                    resolve();
                    return
                }, 1000)
            }
        })
    }

    async function AddObject(objStr) {
        return new Promise((resolve, reject) => {
            const ct = JSON.parse(objStr);
            var pobj = new paper[ct[0]]()
            pobj.importJSON(ct)
            updateDrawPaperObj(pobj, donePaper);
            function donePaper(nObj) {
                pobj.remove()
                resolve(nObj)
            }
        })
    }
    async function blockCodeFunction(ftype, ...args) {
        if (ftype in Outfunctions2) {
            return await Outfunctions2[ftype](...args)
        }
    }

    function runCodeBlock() {
        paper.project.activeLayer.children.forEach(c => {
            if (c.data && c.data.codingKey && c.data.DropZone) {
                processCodingBox(c)
            }
        });
    }
    function menuSelect(m, e) {
        const tof = typeof m
        if (tof !== "string") {
            // click away 
            clearSelect()
            return
        }
        ReactGA.event({
            category: 'User',
            action: m
        });
        if (!selectedObj) return
        var foundKey = findSelectedKey()
        if (m === "Make auto counter" && foundKey !== null) {
            makeCurrency(selectedObj, foundKey);
            clearSelect();
        }
        if (m === "Add Answers and Points" && foundKey !== null) {
            var children = null
            if (selectedObj.data.DropZone) {
                var id = selectedObj.data.linkData.id
                children = []
                paper.project.activeLayer.children.forEach(c => {
                    if (c.data.DropZoneID === id) {
                        children.push(c)
                    }
                })
            }
            answerDialogCall(selectedObj, children)
            clearSelect()

            return
        }
        if (m === "Make Coding Box" && foundKey !== null) {
            // var children = null
            // if (selectedObj.data.DropZone) {
            //     var id = selectedObj.data.linkData.id
            //     children = []
            //     paper.project.activeLayer.children.forEach(c => {
            //         if (c.data.DropZoneID === id) {
            //             children.push(c)
            //         }
            //     })
            // }
            codingDialogCall(selectedObj)
            clearSelect()

            return
        }
        if (m === "Make Toss Zone" && foundKey !== null) {
            TossSelect(selectedObj)
            clearSelect()

            return
        }
        if (m === "Animate-Rotate" && foundKey !== null) {
            rotateObj[foundKey] = paperObj[foundKey].obj
            var animate = {}
            if (paperObj[foundKey].gqlObj && paperObj[foundKey].gqlObj.animate) {
                try {
                    animate = JSON.parse(paperObj[foundKey].gqlObj.animate)
                } catch {
                    return
                }
            }
            animate['rotate'] = true
            if (paperObj[foundKey].gqlObj) {
                paperObj[foundKey].gqlObj.animate = JSON.stringify(animate)
                ib.updateAnimateObject(paperObj[foundKey].gqlObj)
            }
            clearSelect()
        }
        if (m === "Animate-Color" && foundKey !== null) {
            var fft = boundBlink(selectedObj)
            var grp = new paper.Group([fft, selectedObj])
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }
            updateDrawPaperObj(grp, done)
            function done(newObj) {
                clearSelect()
                ib.delObject(foundKey)
                fft.remove()
                grp.remove()

                foundKey = newObj.data.id
                colorObj[foundKey] = paperObj[foundKey].obj
                var animate = {}
                if (paperObj[foundKey].gqlObj && paperObj[foundKey].gqlObj.animate) {
                    try {
                        animate = JSON.parse(paperObj[foundKey].gqlObj.animate)
                    } catch {
                        return
                    }
                }
                animate['color'] = true
                if (paperObj[foundKey].gqlObj) {
                    paperObj[foundKey].gqlObj.animate = JSON.stringify(animate)
                    ib.updateAnimateObject(paperObj[foundKey].gqlObj)
                }
            }
            clearSelect()
        }
        if (m === "Animate-Move" && foundKey !== null) {
            ctx.mode = "move"
            ctx.canvas.interface.style.cursor = "crosshair"
        }
        if (m === "Move" && foundKey !== null) {
            var mine = false
            if (paperObj[foundKey] && paperObj[foundKey].gqlObj) {
                let g = paperObj[foundKey].gqlObj
                if (g.SessionID === gSessionID) mine = true
            }
            if (selectedObj.data.studentMove && !mine) {
                clearAllSelected()
                let rect = selectedObj.clone()
                rect.data.id = null
                selectedObj.remove() //remove the parent 
                rect.data.studentMove = false
                rect.data.studentMoveParent = selectedObj.data.studentMoveParentID;
                rect.data.cannotdelete = true
                rect.data.fixSize = selectedObj.data.fixSize;
                selectedObj = rect
                rect.selected = true
            }
            ctx.mode = "edit"
            ctx.canvas.interface.style.cursor = "crosshair"
        }
        if (m === "Delete" && foundKey !== null) {
            clearSelect()
            if (objDict && objDict[foundKey] && objDict[foundKey].obj) {
                pushUndoStack('del', objDict[foundKey].obj);
            }
            ib.delObject(foundKey)
        }
        if (m === "Edit Text") {
            setShowTextTools(true)
            handleEditText(e, foundKey)
        }
        if (m === "Add sign language") {
            handleSignLang(e, foundKey)
        }
        if (m === "Edit Math") {
            handleEditMath(foundKey)
        }
        if (m === "Edit Immersive Reader") {
            handleEditRead(selectedObj)
            clearSelect();
        }
        if (m === "Send to back") {
            var rect = selectedObj
            selectedObj.data.background = true
            var gg = findSelectedGQL()
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }
            updateDrawPaperObj(selectedObj, done)
            function done() {
                clearSelect()
                if (gg) {
                    ib.delObject(gg.id)
                }
            }
        }
        if (m === "Fill Color") {
            return handleFillColor(selectedObj, foundKey)
        }
        if (m === "Copy") {
            clearSelect()
            return
        }
        if (m === "UnGroup") {
            if (selectedObj.data.lasso && selectedObj.data.studentClone) {
                return handleLassoStd("studentClone", foundKey, true)
            }
            if (selectedObj.data.lasso && selectedObj.data.studentMove) {
                return handleLassoStd("studentMove", foundKey, true)
            }
            handleLassoStd("unlasso", foundKey, true)
        }
        if (m === "Enable Student Clone") {
            // if (selectedObj.data.lasso) {
            //     return handleLassoStd("studentClone", foundKey, true)
            // }
            selectedObj.data.studentClone = true
            selectedObj.data.studentMove = false
            delete selectedObj.data["studentMoveParentID"]
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }
            updateDrawPaperObj(selectedObj, done)
            function done() {
                clearSelect()
                if (foundKey) {
                    ib.delObject(foundKey)
                }
            }
        }
        if (m === "Fix size") {
            selectedObj.data.fixSize = true
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }
            updateDrawPaperObj(selectedObj, done)
            function done() {
                clearSelect()
                if (foundKey) {
                    ib.delObject(foundKey)
                }
            }
        }
        if (m === "Unfix size") {
            delete selectedObj.data['fixSize']
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }
            updateDrawPaperObj(selectedObj, done)
            function done() {
                clearSelect()
                if (foundKey) {
                    ib.delObject(foundKey)
                }
            }
        }
        if (m === "Disable Student Clone") {
            // if (selectedObj.data.lasso) {
            //     return handleLassoStd("studentClone", foundKey, false)
            // }
            selectedObj.data.studentClone = false
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }
            updateDrawPaperObj(selectedObj, done)
            function done() {
                clearSelect()
                if (foundKey) {
                    ib.delObject(foundKey)
                }
            }
        }
        if (m === "Add Link") {
            addLink(foundKey)
        }
        if (m === "Flip Horizontal") {
            selectedObj.scale(-1, 1)
            updateDrawPaperObj(selectedObj, done)
            function done() {
                clearSelect()
                if (foundKey) {
                    ib.delObject(foundKey)
                }
            }
        }
        if (m === "Flip Vertical") {
            selectedObj.scale(1, -1)
            updateDrawPaperObj(selectedObj, done)
            function done() {
                clearSelect()
                if (foundKey) {
                    ib.delObject(foundKey)
                }
            }
        }
        if (m === "Add to Palette") {
            addPalette()
        }

        if (m === "Make Jigsaw") {
            makeJigsaw()
        }
        if (m === "Set Border") {
            setBorder()
        }
        if (m === "Enable Student Move") {
            // if (selectedObj.data.lasso) {
            //     return handleLassoStd("studentMove", foundKey, true)
            // }
            selectedObj.data.studentMove = true
            selectedObj.data.studentClone = false
            selectedObj.data.studentMoveParentID = foundKey //original ID 
            updateDrawPaperObj(selectedObj, done)
            function done() {
                selectedObj.remove()
                clearSelect()
                if (foundKey) {
                    ib.delObject(foundKey)
                }
            }
        }
        if (m === "Disable Student Move") {
            // if (selectedObj.data.lasso) {
            //     return handleLassoStd("studentMove", foundKey, false)
            // }
            selectedObj.data.studentMove = false
            selectedObj.data.studentMoveParentID = foundKey //original ID 
            updateDrawPaperObj(selectedObj, done)
            function done() {
                selectedObj.remove()
                clearSelect()
                if (foundKey) {
                    ib.delObject(foundKey)
                }
            }
        }
        if (m === "ResiZe" && foundKey !== null) {
            ctx.mode = "resize"
            var rect = selectedObj
            var pp = getPaperPoint(e)
            if (rect && rect.data) {
                rect.data.state = 'resizing';
                rect.data.bounds = rect.bounds.clone();
                rect.data.scaleBase = pp.subtract(rect.bounds.center);
            }
        }
        if ((m === "Lock" || m === "UnLock") && foundKey !== null) {
            if (selectedObj.data && selectedObj.data.lock)
                selectedObj.data.lock = false
            else
                selectedObj.data.lock = true
            //del obj 
            var gg = findSelectedGQL()
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }
            updateDrawPaperObj(selectedObj, done)
            function done() {
                clearSelect()
                if (gg) {
                    ib.delObject(gg.id)
                }
            }
        }
        if (m === "Clone" && foundKey !== null) {
            ctx.mode = "edit"
            ctx.canvas.interface.style.cursor = "crosshair"
            clearAllSelected()
            let rect = selectedObj.clone()
            rect.data.id = null
            if (selectedObj.data.studentMoveParentID) {
                rect.data.studentMoveParentID = uuid() //new one for the clone 
            }
            processClone(selectedObj, rect)
            rect.data.fixSize = selectedObj.data.fixSize;
            doPixieMagic({
                x: rect.bounds.center.x, y: rect.bounds.center.y,
                num: 20, type: "pixiedust"
            })
            rect = adjustClonedWidget(rect)
            rect.data.studentClone = false
            selectedObj = rect
        }
        if (m === "Rotate" && foundKey !== null) {
            ctx.mode = "rotate"
            let rect = selectedObj
            if (rect && rect.data) {
                rect.data.state = 'rotating';
                rect.data.lastPoint = pp;
            }
        }
        if (m === "Read in Immersive Reader" && foundKey !== null) {
            var irc = { title: "Whiteboard.chat Microsoft Immersive Reader" }
            try {
                if (selectedObj.content) {
                    irc.text = selectedObj.content.replace(/(\r\n|\n|\r)/gm, "")
                    setIRContent(irc)
                } else if (selectedObj.data && selectedObj.data.richText) {
                    var richtext = ""
                    selectedObj.data.saved.blocks.forEach(b => {
                        richtext += "\n" + b.text
                    })
                    irc.text = richtext
                    setIRContent(irc)
                } else if (selectedObj.data && selectedObj.data.pdfText) {
                    irc.text = selectedObj.data.pdfText
                    setIRContent(irc)
                    // console.log("selectedObj = ", selectedObj)
                }
            } catch {
                setMessage("Immersive Reader Unavailable For this Object")
            }
        }
        document.removeEventListener('contextmenu', handleContextMenu)
        setCtxMenu({ open: false, x: 0, y: 0 })
    }

    function objMove(x, y) {
        //sendMobile(["OBJ MOVE", x])
        if (selectedObj) {
            selectedObj.position.x = x
            selectedObj.position.y = y
        }
    }

    function drawPaper(obj, cb = null) {
        if (!obj.content) return
        try {
            if ('type' in obj) {
                if (obj['type'].length <= 1) {
                    console.error("OBJ TOO SHORT", obj)
                } else {
                    obj['type'] = JSON.parse(obj['type'])
                    if (obj.type && obj.type.compressed) {
                        obj.content = pako.inflate(obj.content, { to: 'string' });
                        delete obj['type']
                    }
                }
            }
        } catch (e) {
            console.error("cannot decode", e, obj)
            return
        }

        if (obj.id in paperObj) {
            if (paperObj[obj.id].gqlObj && paperObj[obj.id].gqlObj.updatedAt ===
                obj.updatedAt) {
                if (cb) cb(paperObj[obj.id].obj)
                return
            }
            checkWidgetDeleted(paperObj[obj.id].obj.data)
            paperObj[obj.id].obj.remove()
            delete paperObj[obj.id]
            delete gStopLight[obj.id]
        }
        var rr
        try {
            rr = JSON.parse(obj.content)
        } catch {
            return
        }
        if (!rr.length || rr.length === 0) return
        //        if (selectedObj instanceof paper.Raster) {
        try {
            var pobj = new paper[rr[0]]()
        } catch {
            // Objects that contain a gradient component, cause the
            // json representation to be a dictionary, which needs
            // to be created as a paper group. new paper["dictionary"]
            // does not work.
            if (rr[0][0] === "dictionary") {
                pobj = new paper["Group"]()
            } else {
                console.warn("Object of unknown type rr[0] = ", rr[0])
                return;
            }
        }
        if (rr[0] === "Raster") {
            try {
                const ff = JSON.parse(obj.content)
                const rrt = ff[1].source.split("?X-Amz")[0]
                // rrt = rrt.replace("https://www.whiteboard.chat/", "http://localhost:3001/")

                ff[1].source = rrt
                obj.content = JSON.stringify(ff)
            } catch (e) {
                console.error("ERROR IS ", e)
            }
        }
        if (rr[0] === "Group") {
            var found = false
            try {
                if (rr[1] && rr[1].children) {
                    for (let i = 0; i < rr[1].children.length; i++) {
                        let c = rr[1].children[i]
                        if (c[0] === 'Raster') {
                            if (c[1] && c[1].source && c[1].source.includes("X-Amz")) {
                                const rrt = c[1].source.split("?X-Amz")[0]
                                c[1].source = rrt
                                found = true
                            }
                        }
                    }
                    if (found) {
                        obj.content = JSON.stringify(rr)
                    }
                }
            } catch (e) {
                console.error("ERROR IS ", e)
            }
        }

        pobj.data.id = obj.id
        // console.error("drawPaper:", obj);
        pobj.importJSON(obj.content)
        if (rr[0] === "Raster") {
            pobj.onError = function (f) {
                // cross domain failures for images?
                const ff = JSON.parse(obj.content)
                console.log("pobj.onError:", ff);
                iu.GetImage(ff[1].source).then((rr) => {
                    ff[1].source = rr.img
                    obj.content = JSON.stringify(ff)
                    pobj.importJSON(obj.content)
                })
            }
        }
        pobj.data.id = obj.id
        if (rr[0] === "Raster" || pobj.data.stackwithtime) {
            pobj.data.createdAt = new Date(obj.createdAt).getTime() / 1000

            let idx = getInsertIndex(pobj)
            paper.project.activeLayer.insertChild(idx, pobj);
            moveGridDown()
        }
        if (rr[0] === "Group" && 'data' in pobj) {
            if (pobj.data.grid) {
                paper.project.activeLayer.insertChild(0, pobj);
                gGrid = pobj
                if (gBackGround) gBackGround.sendToBack()
            }
            if (pobj.data.lasso) {
                var children = pobj.children;
                var rasterChild = false
                // Iterate through the items contained within the array:
                for (var i = 0; i < children.length; i++) {
                    var child = children[i];
                    if (child instanceof paper.Raster) {
                        rasterChild = true
                    }
                }
                if (rasterChild) {
                    let idx = getInsertIndex(pobj)
                    paper.project.activeLayer.insertChild(idx, pobj);
                    moveGridDown()
                }
            }
        }
        if (pobj.data.table) {
            gTables++
        }

        if (pobj.data.backGroundCol) {
            var mine = obj.SessionID !== gSessionID ? true : false
            dispatch(Actions.setBackGround({
                ...background,
                notmine: mine,
                color: pobj.data.backGroundCol.color
            }))
            gBackGround = pobj
            pobj.sendToBack();
        }
        if (pobj.data.stoplight) {
            const sl = pobj.data.stoplight
            if (sl.sess === gSessionID) {
                if (gStopLight[sl.stoplightID] && gStopLight[sl.stoplightID].data.id !== obj.id) {
                    ib.delObject(gStopLight[sl.stoplightID].data.id)
                }
                gStopLight[sl.stoplightID] = pobj
                if (gStopLightParent[sl.stoplightID]) pobj.insertAbove(gStopLightParent[sl.stoplightID])
            }
        }
        setTilebox(pobj)
        if (pobj.data.rectPainter) {
            gridPainter[pobj.data.coord] = pobj
        }
        if (pobj.data.stoplightGrp) {
            const sl = pobj.data.stoplightGrp
            gStopLightParent[sl] = pobj
            if (gStopLight[sl]) {
                pobj.insertBelow(gStopLight[sl])
            }
        }
        //condition need to add here
        if (rr[0] === "Group" && pobj.data && pobj.data.linkData && !pobj.data.linkDataButtonDraw && !ggridView.open) {
            var rect = pobj.bounds
            var button = document.createElement('BUTTON');
            button.style.position = 'absolute';
            button.style.left = rect.topLeft.x + 85 + "px"
            button.style.top = rect.topLeft.y + 50 + "px"
            button.style.width = "80"
            button.style.height = "50"
            button.style.padding = "2px"

            button.style.font = 15 + 'px Roboto'
            button.innerHTML = "CLICK"
            button.style.background = "#3174F5";
            button.style.color = "#ffffff";

            button.style.zIndex = 105
            button.onclick = () => butClick(pobj)
            pobj.data.htmlObj = button
            function butClick(pobj) {
                handleLinkData(pobj, null)
            }
            let index = gButtons.findIndex(x => x.id === pobj.id);
            if (index === -1) gButtons.push({ id: pobj.id, button: button })
            else {
                gButtons.splice(index, 1)
                gButtons.push({ id: pobj.id, button: button })
            }

            document.body.appendChild(button);
        }
        if (pobj.data && pobj.data.gamePlayAvatar) {
            updateGamePlayAvatar(pobj)
        }
        if (pobj.data && pobj.data.background) {
            paper.project.activeLayer.insertChild(0, pobj);
            gBackGround = pobj

        }
        if (pobj && pobj.data.bingo) {
            if (obj.SessionID !== gSessionID) {
                //teachers' object?
                pobj.remove()
            }
        }
        if (pobj.data.studentMove) ib.checkStudentMove(pobj, gSessionID)
        pobj.selected = false
        paperObj[obj.id] = { obj: pobj, type: "drawPaper", gqlObj: JSON.parse(JSON.stringify(obj)) }
        attachTools(pobj)
        if (rr[0] !== "Raster") {
            if (cb) cb(pobj)
        } else {
            pobj.onLoad = function () {
                if (cb) {
                    cb(pobj)
                }
            }
        }
        // Used to adjust how a widget appears based on owner and any other config items
        adjustWidget(pobj, obj)
    }

    function clearBound(timer = false) {

        clearTimeout(debouceRect);
        if (boundRect && boundRect.data.boundClicked && timer) {
            return
        }
        if (boundRect) {
            if (boundRect.data.c1) {
                boundRect.data.c1.remove()
                boundRect.data.c2.remove()
                boundRect.data.c3.remove()
                boundRect.data.c4.remove()
                boundRect.data.c5.remove()
            }
            boundRect.remove()
            boundRect.data.boundClicked = false
            boundRect = null
        }
    }

    function brCircle(x, y, pobj, cir) {
        var rad = 6
        if (cir) rad = 12
        var myCircle = new Path.Circle(new Point(x, y), rad);
        if (cir) {
            myCircle.strokeWidth = 2
            myCircle.strokeColor = "#3174F5"; //mkColor(ctx.SavedColor, 3)
            myCircle.dashArray = [2, 2]
            myCircle.fillColor = mkColor(ctx.SavedColor, 3);
            myCircle.data.boundingRotate = true

        } else {
            myCircle.fillColor = ctx.SavedColor;
            myCircle.strokeWidth = 3
            myCircle.strokeColor = ctx.SavedColor;;
            myCircle.data.boundingResize = true
        }
        myCircle.data.boundingObj = pobj
        //   myCircle.selected = true
        return myCircle
    }
    function createBound(pobj, enter, clicked) {
        var allowed = {
            'moveResize': true,
        }
        if (enter) {
            allowed['edit'] = true
        }
        if (!clicked) {
            if (boundRect && boundRect.data.boundClicked) {
                return; //something selected by a click
            }
        }
        clearBound()
        if (!(ctx.mode in allowed)) {
            return
        }

        selectedObj = pobj
        var gg = findSelectedGQL()
        var skip = false
        var resizeAllowed = true
        if (ctx.subMode === "copy") resizeAllowed = false
        if (!gg) skip = true
        if (selectedObj.data.fixSize) resizeAllowed = false
        if (gg && gg.SessionID !== gSessionID) {
            skip = true
            if (selectedObj.data.studentClone || selectedObj.data.studentMove) {
                resizeAllowed = false
                skip = false
            }
        }
        if (skip === true) {
            clearSelect()
            return
        }
        debouceRect = setTimeout(function () {
            clearBound(true)
            return
        }, 2000)
        var ff = pobj.bounds
        var rr = {}
        rr.x = ff.x - 10
        rr.y = ff.y - 10
        rr.height = ff.height + 20
        rr.width = ff.width + 20
        let rectanglePath = new Path.Rectangle(rr);
        rectanglePath.strokeColor = ctx.SavedColor;
        rectanglePath.strokeWidth = 3
        rectanglePath.data.boundClicked = clicked
        rectanglePath.data.boundingObj = pobj
        rectanglePath.data.boundingResize = false

        boundRect = rectanglePath
        // boundRect.selected = true
        if (resizeAllowed) {
            rectanglePath.data.c1 = brCircle(rr.x, rr.y, pobj, false)
            rectanglePath.data.c5 = brCircle(rr.x, rr.y, pobj, true)
            rectanglePath.data.c2 = brCircle(rr.x + rr.width, rr.y, pobj, false)
            rectanglePath.data.c3 = brCircle(rr.x + rr.width, rr.y + rr.height, pobj, false)
            rectanglePath.data.c4 = brCircle(rr.x, rr.y + rr.height, pobj, false)
        }
    }
    function attachTools(pobj) {
        if (pobj.data.linkData) {
            buttonClicks++
        }
        if (pobj.data.linkData && pobj.data.linkData.type === "DropZone") {
            gDropZone[pobj.data.linkData.id] = pobj
        }
        pobj.onMouseEnter = function (event) {
            if (this.data && this.data.lock) {
                return
            }
            if (this.data && this.data.grid) {
                return
            }
            if (this.data && this.data.backGroundCol) {
                return
            }
            createBound(this, false, false)
            if (this && this.data.linkData) {

                createButton(this)
            }
        }
        pobj.onMouseLeave = function (event) {
            // if (boundRect) boundRect.remove()
            // boundRect = null 
        }
        // // pobj.onMouseDrag = function (event) {
        // //     console.log("EVENT IS ",event)
        // // }
        // // pobj.onMouseMove = function (event) {
        // //     console.log("EVENT IS ",event)
        // // }
        // pobj.onMouseClick = function (event) {
        //     this.fillColor = 'yellow';
        //     console.log("EVENT IS ", event)
        // }
    }

    function getCompressedSvg(o) {
        if (!isApiMode) return null
        let svg = o.exportSVG({ asString: true })
        let def = pako.deflate(svg, { to: 'string' });
        return def
    }
    function createDrawPaperObj(objIn, cb = null, failCb = null, id = null) {
        if (!id)
            id = uuid()
        var ff = objIn.exportJSON()
        if (!session) return
        const userid = gUser ? gUser.id : null
        var copy = compress(ff, true)
        var svg = getCompressedSvg(objIn)

        ib.createObject(id, "name", session.id, copy.content, "drawPaper", userid, copy.type, session.ttl, svg).then(res => {
            var obj = res.data.createObject
            delete obj['Session']
            if (obj) {
                objDict[id] = { lastIndex: 0, mine: true, obj: obj }
            }
            drawPaper(obj, cb)
        }, error => {
            if (failCb) {
                failCb(error)
            }
        })
    }
    function updateDrawPaperGqlObj(gqlObj, objIn, cb = null) {
        const id = gqlObj.id
        var ff = objIn.exportJSON()
        if (!session) return
        const userid = gUser ? gUser.id : null
        var copy = compress(ff, true)
        gqlObj.content = copy.content
        gqlObj.type = copy.type
        var svg = getCompressedSvg(objIn)
        if (svg) {
            gqlObj.SVGObj = svg
        }
        delete gqlObj['updatedAt'] // Ensures we get an updated timestamp so recipients act on the fresh obj
        ib.updateObject(gqlObj).then(res => {
            var obj = res.data.updateObject
            delete obj['Session']
            if (obj) {
                objDict[id] = { lastIndex: 0, mine: true, obj: obj }
            }
            drawPaper(obj, cb)
        })
    }
    function updateDrawPaperObj(objIn, cb = null) {
        const id = uuid()
        delete objIn.data['blink']
        objIn.data.drawPaper = true
        var ff = objIn.exportJSON()
        if (!session) return
        const userid = gUser ? gUser.id : null
        var copy = compress(ff, true)
        var svg = getCompressedSvg(objIn)

        ib.createObject(id, "name", session.id, copy.content, "drawPaper", userid, copy.type, session.ttl, svg).then(res => {
            var obj = res.data.createObject
            delete obj['Session']
            if (obj) {
                objDict[id] = { lastIndex: 0, mine: true, obj: obj }
            }
            drawPaper(obj, cb)
        })
    }

    function doneMove(e) {
        updateEngagement("move")
        if (ctx && ctx.lastMode && ctx.lastMode === "moveResize") {
            delete ctx['lastMode']
            ctx.mode = "moveResize"
            ctx.canvas.interface.style.cursor = ctx.subMode
            if (ctx.AutoMove) {
                ctx.mode = ctx.AutoMove
                if (ctx.mode in DEFTOOLCURSOR) {
                    ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[ctx.mode]
                }
                delete ctx['AutoMove']
            }
        } else if (ctx && ctx.lastMode && ctx.lastMode === "rect") {
            ctx.mode = ctx.lastMode
            ctx.canvas.interface.style.cursor = "crosshair"
            delete ctx['lastMode']
        } else {
            ctx.mode = defaultTool
            ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        }

        if (selectedObj) {
            //del obj 
            if (Object.keys(gDropZone).length > 0) {
                checkDropZoneHit(selectedObj, e)
            }
            if (selectedObj.data.id && selectedObj.data.drawPaper) {
                let pid = selectedObj.data.id
                let po = paperObj[pid] && paperObj[pid].gqlObj ? paperObj[pid].gqlObj : null
                if (po) {
                    var ggcopy = { ...paperObj[pid].gqlObj }
                    ggcopy.type = JSON.stringify(ggcopy.type)
                    pushUndoStack("mod", ggcopy)

                    updateDrawPaperGqlObj(po, selectedObj, newObj)
                    selectedObj.remove()
                    selectedObj = null
                    clearBound()
                }
                return
            }
            var gg = findSelectedGQL()
            if (gg) {
                // Need to create a copy to make type a string again.
                var ggcopy = { ...gg }
                ggcopy.type = JSON.stringify(ggcopy.type)
                pushUndoStack("mod", ggcopy)
                ib.delObject(ggcopy.id)
            }
            selectedObj.remove()
            checkWidgetDeleted(selectedObj)//this is for case of stud moved obj
            removeSelected(selectedObj)
            //objDict[id] = { lastIndex: 0, mine: true, obj: null }

            updateDrawPaperObj(selectedObj, newObj)
            selectedObj = null
            clearBound()
            function newObj(o) {
                if (ctx.mode === "moveResize") {
                    createBound(o, true, true)
                }
            }
            return

        }

    }
    function openMenu(e, gql) {
        let point = paper.DomEvent.getOffset(e, ctx.canvasContainer)
        point = paper.view.viewToProject(point)
        var path = selectedObj instanceof paper.PointText ? "text" : ""
        if (path === "") path = selectedObj instanceof paper.Raster ? "image" : ""
        if (path === "image" && selectedObj.data.richText) path = "text"
        var creator = null
        if (gTeacher && gql && gql.CreatedBy) {
            let index = gUsers.findIndex(x => x.id === gql.CreatedBy);
            if (index !== -1) {
                const c = gUsers[index]
                creator = c.name
            }
        }
        document.addEventListener('contextmenu', handleContextMenu)

        setCtxMenu({
            open: true, x: point.x, y: point.y,
            cb: menuSelect, otype: path, event: e, selectedObj: selectedObj,
            creator: creator
        })
    }

    function checkDropZoneHit(obj, e) {
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        var point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        var found = false
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]
            if (fooo) {
                var ga = fooo.item
                if (ga.data.DropZone) {
                    found = true
                    if (ga.data.linkData && ga.data.linkData.subtype === "currencyCounter") {
                        dropOnCurrency(ga, obj)
                        return true
                    }
                    var arr = []
                    processElemMeat(obj, arr)
                    obj.data.DropZoneID = ga.data.linkData.id
                    obj.data.DropZoneMeat = arr
                }
            }
        }
        if (!found) {
            CheckAllDropCurrency(null)
            delete obj.data['DropZoneID']
        }
        return found
    }
    function getHitObjSticky(e, pointIn) {
        clearSelect()
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        var point
        if (e) {
            point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        }
        if (pointIn) point = pointIn
        point = paper.view.viewToProject(point)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]
            if (fooo) {
                fooo.item.selected = false
                if (fooo.item && fooo.item.data && fooo.item.data.sticky) {
                    selectedObj = fooo.item
                }
                if (fooo && fooo.item.parent) {
                    var parent = fooo.item.parent
                    if (parent instanceof paper.Group) {
                        if (parent.data.sticky) {
                            selectedObj = parent
                            parent.children.map((c) => {
                                //c.selected = true
                            })
                            //group of sticky
                            let gg = findSelectedGQL()
                            if (gg && gg.SessionID !== gSessionID) {
                                //skip teacher
                                selectedObj = null;
                                continue
                            }
                            return { group: selectedObj, single: null, gg: gg }
                        }

                    }
                }
            }
        }
        if (selectedObj && selectedObj.data.sticky) {
            //single stickly
            //selectedObj.selected = true 
            let gg = findSelectedGQL()
            if (gg && gg.SessionID !== gSessionID) {
                selectedObj = null;
                return null
            }
            return { single: selectedObj, group: null, gg: gg }
        }
        return null
    }

    function updateSticky(e, pointIn, newObj) {
        let ht = getHitObjSticky(e, pointIn)
        if (ht) {
            if (ht.single) {
                var group = new paper.Group([ht.single, newObj])
                group.data = { ...ht.single.data }
                group.data.sticky = true
                let gg = ht.gg
                updateDrawPaperObj(group, done)
                function done() {
                    group.remove()
                    newObj.remove()
                    if (gg) {
                        ib.delObject(gg.id)
                    }
                }
            } else {
                ht.group.addChild(newObj)
                selectedObj = ht.group
                let gg = ht.gg
                updateDrawPaperObj(ht.group, done)
                function done() {
                    ht.group.remove()
                    newObj.remove()
                    if (gg) {
                        ib.delObject(gg.id)
                    }
                }
            }
            return true
        }
        return false
    }
    function getHitObj(e) {
        clearSelect()
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var fooo = paper.project.hitTest(point, hitOptions)
        if (fooo) {
            fooo.item.selected = true
            selectedObj = fooo.item
            if (selectedObj.data.backGroundCol) {
                selectedObj = null
                fooo.item.selected = false
                return null
            }
            if (selectedObj.data.unselectable) {
                selectedObj = null
                fooo.item.selected = false
                return null
            }
            if (fooo && fooo.item.parent) {
                var parent = fooo.item.parent
                var count = 7
                while (parent) {
                    count--
                    if (count === 0) return null
                    if (parent instanceof paper.Group && parent.data.id) {
                        if (parent.data.grid || parent.data.backGroundCol) {
                            selectedObj = null
                            fooo.item.selected = false
                            return null
                        }
                        selectedObj = parent
                        fooo.item.selected = false
                        parent.children.map((c) => {
                            if (!(c instanceof paper.Group))
                                c.selected = true
                        })
                        break
                    }
                    if (parent && parent.item && parent.item.parent instanceof paper.Group) {
                        parent = parent.item.parent
                    } else {
                        if (parent && parent.parent && parent.parent instanceof paper.Group) {
                            parent = parent.parent
                        } else {
                            parent = null
                        }
                    }
                }
            }
        }
        return selectedObj
    }
    function clearAllSelected() {
        if (!selectedObj) return
        var parent = selectedObj
        if (parent.children) {
            parent.children.map((c) => {
                c.selected = false
            })
        }
        selectedObj.selected = false
    }
    // var zapTimer = null
    // var zapQueue = []

    // function handleZap(e, lb) {
    //     console.log("ZAP", zapTimer, e, lb)
    //     if (zapTimer !== null) {
    //         clearTimeout(zapTimer)
    //         zapTimer = null 
    //     }
    //     zapTimer = setTimeout(function () {
    //         ZapObjects(e)
    //         clearTimeout(zapTimer);
    //         zapTimer = null;
    //     }, 200);
    // }
    function getHitObjZap(e) {
        clearSelect()
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 10 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        var objs = []
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]

            if (fooo) {
                fooo.item.selected = true
                selectedObj = fooo.item
                if (selectedObj.data.backGroundCol) {
                    selectedObj = null
                    fooo.item.selected = false
                    continue
                }
                if (fooo && fooo.item.parent) {
                    var parent = fooo.item.parent
                    var count = 7
                    while (parent) {
                        count--
                        if (count === 0) {
                            selectedObj = null
                            break
                        }
                        if (parent instanceof paper.Group && parent.data.id) {
                            if (parent.data.grid || parent.data.backGroundCol) {
                                fooo.item.selected = false
                                selectedObj = null
                                continue
                            }
                            selectedObj = parent
                            parent.children.map((c) => {
                                c.selected = true
                            })
                            break
                        }
                        if (parent && parent.item && parent.item.parent instanceof paper.Group) {
                            parent = parent.item.parent
                        } else {
                            if (parent && parent.parent && parent.parent instanceof paper.Group) {
                                parent = parent.parent
                            } else {
                                parent = null
                            }
                        }
                    }
                }
            }
            if (selectedObj) objs.push(selectedObj)
        }
        return objs
    }
    function handleZap(e) {
        var selectedObjs = getHitObjZap(e)
        for (let i = 0; i < selectedObjs.length; i++) {
            selectedObj = selectedObjs[i]

            if (selectedObj && selectedObj.data && selectedObj.data.cursor) {
                clearSelect()
                continue
            }
            if (selectedObj) {
                if (selectedObj instanceof paper.Raster) {
                    if (!selectedObj.data.palette) {
                        clearSelect()
                        continue
                    }
                }
                if (selectedObj && selectedObj.data && selectedObj.data.lock) {
                    clearSelect()
                    continue
                }
                var gg = findSelectedGQL()
                if (gg && gg.SessionID !== gSessionID) {
                    clearSelect()
                    continue
                }
                if (!Boolean(gTeacher) && gSession && gSession.isGroup) {
                    if (gg && gg.CreatedBy && gUser && gUser.id !== gg.CreatedBy) {
                        clearSelect()
                        continue
                    }
                }
                if (!gg) {
                    //should delete orphans?
                    selectedObj.remove()
                    removeSelected(selectedObj)
                    clearSelect()
                    continue
                }
                delSelectedObj(gg)
            }
        }
    }
    function delSelectedObj(gg) {
        if (selectedObj && selectedObj.data && selectedObj.data.cannotdelete) {
            return
        }
        if (!teacherR && selectedObj.data.notSession) return
        selectedObj.remove()
        removeSelected(selectedObj)
        var ggcopy = { ...gg }
        ggcopy.type = JSON.stringify(ggcopy.type)
        pushUndoStack("del", ggcopy)
        ib.delObject(gg.id)
    }
    function removeSelected(o) {
        if (o.data && o.data.htmlObj) {
            try {
                document.body.removeChild(o.data.htmlObj);
            } catch (e) {
                console.error("Cannot remove", e)
            }
        }
    }
    function removeAllButtons() {
        for (let i = 0; i < gButtons.length; i++) {
            var b = gButtons[i]
            try {
                document.body.removeChild(b.button);
            } catch (e) {
                console.error("Cannot remove", i, b)
            }
        }
        gButtons = [];
    }
    function handleDblClick(e) {
        e.preventDefault()
        if (gLocked) return
        if (isApiMode) return
        selectedObj = getHitObj(e)
        if (selectedObj) {
            if (!e.ctrlKey && e.button === 0) {
                if (selectedObj && selectedObj.data.linkData) {
                    if (!boundBRect) {
                        handleLinkData(selectedObj, e)
                        clearSelect()
                        return
                    }
                }
            }
            if (!Boolean(gTeacher) && gBoardConfig.fourToolsStudent && gBoardConfig.fourToolsStudent === true) {
                clearSelect()
                return
            }

            var skip = false
            var gg = findSelectedGQL()
            if (!gg) {
                skip = true
            }
            if (gg && gg.SessionID !== gSessionID) {
                skip = true;
                var canberead = false
                if (selectedObj.content) {
                    canberead = true
                }
                if (selectedObj.data && selectedObj.data.richText) {
                    canberead = true
                }
                if (selectedObj.data && selectedObj.data.pdfText) {
                    canberead = true
                }
                if (selectedObj.data.studentClone || selectedObj.data.studentMove) {
                    selectedObj.data.notSession = true
                    skip = false
                }
                if (canberead) {
                    if (gBoardConfig.DisableImmersive && skip) {
                        skip = true
                    } else {
                        skip = false
                        selectedObj.data.canberead = true
                        selectedObj.data.notSession = true
                    }

                }
            }
            if (!Boolean(gTeacher) && gSession && gSession.isGroup) {
                if (gg && gUser && gg.CreatedBy && gUser.id !== gg.CreatedBy) {
                    skip = true; //cannot change other kids stuff on group
                }
            }
            if (skip === true) {
                clearSelect()
                return
            }
            openMenu(e, gg)
        } else {
            if (ctx.mode === "selectAnimate") {
                ctx.mode = defaultTool
                ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
            } else {
                if (!mobile) handleKeyDown(e, null)
            }
        }
    }
    var myLineStart = { obj: null, start: null, end: null }
    function getPoint(e, scrollAdjust = false) {
        var x, y
        var offset = [0, 0]
        if (scrollAdjust) {
            const canvasEl = document.getElementById("cs_container")
            if (canvasEl) { offset = [canvasEl.scrollLeft - 80, canvasEl.scrollTop - 130] }
            // add these numbers to set the wdigetwindow at cursor point
        }
        if (mobile) {
            [x, y] = getTouchPos(e)
        } else {
            let b = getOffsetZoom(e)
            x = b.x
            y = b.y
        }
        if (!x && !y) {
            [x, y] = getTouchPos(e); // for stylus, cant get offset
        }
        x -= offset[0]
        y -= offset[1]
        return [x, y]
    }
    function regularSnapPoint(s, e) {
        var p
        var delX = Math.abs(s.x - e.x)
        var delY = Math.abs(s.y - e.y)
        if (delX < delY) { p = { x: s.x, y: e.y } }
        if (delX > delY) { p = { x: e.x, y: s.y } }
        if (delX === delY) { p = e }
        return p
    }
    function regularSnapPointLine(curLine, e) {
        let x = e.x
        let y = e.y
        var alpha = Math.atan2(y - curLine.y, x - curLine.x);
        var d = Math.hypot(y - curLine.y, x - curLine.x);
        var increment = 2 * Math.PI / 16;
        alpha = Math.round(alpha / increment) * increment;
        x = curLine.x + d * Math.cos(alpha);
        y = curLine.y + d * Math.sin(alpha);
        return { x: x, y: y }
    }
    function regularSnapLength(s, e) {
        var p
        var delX = Math.abs(s.x - e.x)
        var delY = Math.abs(s.y - e.y)
        var del = delX < delY ? delX : delY
        var signX = s.x - e.x > 0 ? -1 : 1
        var signY = s.y - e.y > 0 ? -1 : 1
        p = { x: s.x + del * signX, y: s.y + del * signY }
        return p
    }
    function startLine(e) {
        if (myLineStart.start) {
            lineEnd()
            return
        }
        myLineStart = { obj: null, start: null }
        const [x, y] = getPoint(e)
        myLineStart.start = new paper.Point(x, y)
    }
    function lineEnd() {
        if (myLineStart.obj) {
            updateDrawPaperObj(myLineStart.obj, null)
            myLineStart.obj.remove()
            lastObjDraw = true;
        }
        myLineStart = { obj: null, start: null }
    }
    function lineMove(x, y, e, isLine) {
        if (!myLineStart.start) return
        if (e.shiftKey || isLine) {
            var end = regularSnapPointLine(myLineStart.start, { x: x, y: y })
            myLineStart.end = new Point(end.x, end.y)
        } else {
            myLineStart.end = new Point(x, y)
        }
        if (myLineStart.obj) {
            myLineStart.obj.remove()
        }
        myLineStart.obj = new paper.Path.Line(myLineStart.start, myLineStart.end)

        myLineStart.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
        myLineStart.obj.strokeWidth = ctx.brushRadius * 2
        myLineStart.obj.dashArray = ctx.lineStyle
    }
    var myArrowStart = { obj: null, start: null, end: null }

    function startArrow(e) {
        if (myArrowStart.start) {
            arrowEnd()
            return
        }
        myArrowStart = { obj: null, start: null }
        const [x, y] = getPoint(e)
        myArrowStart.start = new paper.Point(x, y)
    }
    function arrowEnd() {
        if (myArrowStart.obj) {
            updateDrawPaperObj(myArrowStart.obj, null)
            myArrowStart.obj.remove()
            lastObjDraw = true;
        }
        myArrowStart = { obj: null, start: null }
    }
    function drawArrow(start, end) {
        var headLength = 10;
        var headAngle = 150;
        var tailVector = end.subtract(start)
        var headLine = tailVector.normalize(headLength);

        var arrow = new paper.Group([
            new paper.Path([start, end]),
            new paper.Path([
                end.add(headLine.rotate(headAngle)),
                end,
                end.add(headLine.rotate(-headAngle))
            ])
        ]);
        return arrow
    }
    function arrowMove(x, y, e) {

        if (!myArrowStart.start) return
        if (e.shiftKey) {
            var end = regularSnapPoint(myArrowStart.start, { x: x, y: y })
            myArrowStart.end = new paper.Point(end.x, end.y)
        } else {
            myArrowStart.end = new paper.Point(x, y)
        }
        if (myArrowStart.obj) {
            myArrowStart.obj.remove()
        }
        myArrowStart.obj = drawArrow(myArrowStart.start, myArrowStart.end)

        myArrowStart.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
        myArrowStart.obj.strokeWidth = ctx.brushRadius * 2
        myArrowStart.obj.dashArray = ctx.lineStyle
    }
    function clearRect() {
        myRect = { obj: null, start: null }
    }
    function startRect(e) {
        if (myRect.start) {
            rectEnd(e)
            return
        }
        myRect = { obj: null, start: null }
        const [x, y] = getPoint(e)
        myRect.start = new paper.Point(x, y)

    }
    var selectCrop = null
    function endSelect(obj, e) {
        if (selectCrop) selectCrop.remove()
        selectCrop = obj
        selectCrop.data.selectedObj = true

        myRect = { obj: null, start: null }
        var point = selectCrop.bounds.bottomRight

        var selectedObjs = getHitObjZap(e)
        for (let i = 0; i < selectedObjs.length; i++) {
            selectedObj = selectedObjs[i]
            if (selectedObj && selectedObj.data && selectedObj.data.lock) {
                clearSelect()
                continue
            }
            if (selectedObj && selectedObj.data && selectedObj.data.selectedObj) {
                clearSelect()
                continue
            }
            var gg = findSelectedGQL()
            if (gg && gg.SessionID !== gSessionID) {
                clearSelect()
                continue
            }
            if (!gg) {
                clearSelect()
                continue
            }
        }
        if (selectedObj) selectedObj.selected = false
        selectCrop.selected = false
        var nocrop = selectedObj ? false : true

        var menu = ["Cancel", "Copy"]
        if (!nocrop) menu = [...menu, "Crop"]
        setSelectMenu({
            open: true, x: point.x, y: point.y,
            cb: done, menu: menu
        })
        async function done(e, evt) {
            setSelectMenu({
                open: false,
            })
            var b = selectCrop.bounds
            selectCrop.visible = false
            if (selectCrop) selectCrop.remove()
            selectCrop = null

            if (e === "Cancel") {
                return
            }
            var avatar = ctx.context.drawing.getImageData(b.x + 1, b.y + 1, b.width - 2, b.height - 2);

            var c2 = document.createElement('canvas');
            c2.width = b.width
            c2.height = b.height
            var ctx2 = c2.getContext('2d');
            if (e === "Copy") {
                ctx2.putImageData(avatar, 0, 0)
                var dataUrl2 = c2.toDataURL('image/png');
                let raster = new paper.Raster({ source: dataUrl2, crossOrigin: 'anonymous' });
                raster.position = b.center;
                var j = ib.copyObjtoClipBoard(raster)
                navigator.clipboard.writeText(j)

                raster.onLoad = function (evt) {
                    selectedObj = raster
                    ctx.lastMode = ctx.mode
                    ctx.mode = "edit"
                    ctx.canvas.interface.style.cursor = "crosshair"
                }
                raster.onError = function (f) {
                    console.log('The image not loaded.', f);
                };
            }
            if (e === "Crop") {
                ctx2.fillStyle = "#FFFFFF";
                ctx2.fillRect(0, 0, c2.width, c2.height);
                ctx2.putImageData(avatar, 0, 0)
                var dataUrl2 = c2.toDataURL('image/jpeg');
                var rr = await iu.writeFileReturn(dataUrl2)
                var raster = new paper.Raster({ source: rr.img, crossOrigin: 'anonymous' });
                raster.position = b.center

                updateDrawPaperObj(raster, doneAll)
                function doneAll() {
                    ib.delObject(gg.id)
                    selectedObj.remove()
                    clearSelect()
                }
            }
        }
    }

    function ScreenShadeDone() {
        if (gShadeObj) {
            var po = paperObj[gShadeObj.id] && paperObj[gShadeObj.id].gqlObj ? paperObj[gShadeObj.id].gqlObj : null
            if (po) {
                updateDrawPaperGqlObj(po, gShadeObj.parent, done)
                function done() {
                    gShadeObj.parent.remove()
                    gShadeObj = null
                }
            } else {
                gShadeObj = null
            }
        }
        handleEscape()
    }
    function ScreenShadeMove(x, y, evt) {
        let e = selectedObj
        if (!gShadeObj) return
        if (!e || !e.data.linkData || !e.data.linkData.type === "ScreenShade") return

        var f = e.data.linkData
        var epb = e.parent.bounds
        //find inner rect 
        if (e.parent && e.parent.children) {
            e.parent.children.forEach(c => {
                if (c.data && c.data.linkData && c.data.linkData.dir === "screen") {
                    epb = c.bounds
                }
            })
        }
        var newRect = null
        if (f.dir === "west") {
            newRect = new paper.Path.Rectangle(new Point(epb.topLeft.x, epb.topLeft.y),
                new Point(x, epb.bottomRight.y))
        }
        if (f.dir === "east") {
            newRect = new paper.Path.Rectangle(new Point(x, epb.topLeft.y),
                new Point(epb.bottomRight.x, epb.bottomRight.y))
        }
        if (f.dir === "north") {
            newRect = new paper.Path.Rectangle(new Point(epb.topLeft.x, y),
                new Point(epb.bottomRight.x, epb.bottomRight.y))
        }
        if (f.dir === "south") {
            newRect = new paper.Path.Rectangle(new Point(epb.topLeft.x, epb.topLeft.y),
                new Point(epb.bottomRight.x, y))
        }
        if (newRect) {
            if (gShadeObj.parent) gShadeObj.parent.remove()
            gShadeObj.parent = drawScreenShadeIn(newRect, false)
            e.parent.remove()
        }
    }
    function isMine(obj) {
        if (obj && obj.data.id && paperObj[obj.data.id] && paperObj[obj.data.id].gqlObj) {
            var g = paperObj[obj.data.id].gqlObj
            if (g.SessionID === gSessionID) return true
        }
        return false
    }
    function ScreenShadeHit(e, evt) {
        if (!isMine(e.parent)) return
        selectedObj = e;
        gShadeObj = { parent: e.parent, orig: e.parent, id: e.parent.data.id }
        ctx.mode = "ScreenShade"
    }
    function drawScreenShadeIn(obj, upd) {
        var ob = []
        const id = uuid()

        obj.strokeWidth = 1;
        obj.strokeColor = ctx.SavedColor
        obj.fillColor = "lightgrey"
        obj.dashArray = [4, 2];
        obj.data.linkData = {
            type: "ScreenShade", id: id, dir: "screen"
        }
        ob.push(obj)
        const HT = 40
        const W = 20

        var r = obj.bounds
        var l1 = r.topLeft.x
        var rr = {
            x: r.topLeft.x - W / 2,
            y: r.topLeft.y + (r.height / 2) - HT / 2,
            height: HT,
            width: W,
        }
        var rp = drawButton(rr, "east")
        ob.push(rp)
        rr.x = r.topRight.x - W / 2;
        rp = drawButton(rr, "west")
        ob.push(rp)

        rr = {
            y: r.topLeft.y - W / 2,
            x: r.topLeft.x + (r.width / 2) - HT / 2,
            height: W,
            width: HT,
        }
        rp = drawButton(rr, "north")
        ob.push(rp)
        rr.y = r.bottomLeft.y - W / 2;
        rp = drawButton(rr, "south")
        ob.push(rp)
        function drawButton(rr, dir) {
            var cornerSize = new paper.Size(10, 10);
            var rrT = new paper.Rectangle(rr)
            let rectanglePath = new Path.Rectangle(rrT, cornerSize);
            rectanglePath.fillColor = ctx.SavedColor
            rectanglePath.data.linkData = {
                type: "ScreenShade", id: id, dir: dir
            }
            rectanglePath.data.linkButton = true
            rectanglePath.data.linkDataButtonDraw = true

            return rectanglePath
        }
        var group = new paper.Group(ob);

        group.data.linkDataButtonDraw = true
        group.data.linkButton = true
        group.data.linkData = {
            type: "ScreenShade", id: id, dir: "group"
        }
        if (upd) {
            updateDrawPaperObj(group, null)
            group.remove()
            ob.forEach(c => {
                c.remove()
            })
            return null
        } else {
            return group
        }
    }
    function rectEnd(e) {
        if (myRect.obj) {
            if (ctx.subMode === "DropZone") {
                const id = uuid()
                var obj = myRect.obj
                obj.strokeWidth = 1;
                obj.strokeColor = ctx.SavedColor
                obj.fillColor = mkColor(ctx.color, 2)
                obj.dashArray = [2, 2];
                obj.data.DropZone = true
                obj.data.lock = true
                obj.data.stackwithtime = true
                obj.data.linkData = {
                    type: "DropZone", id: id,
                }
            }
            if (ctx.subMode === "ScreenShade") {
                drawScreenShadeIn(myRect.obj, true)
                myRect = { obj: null, start: null }
                return
            }
            if (ctx.subMode === "textBox") {
                const id = uuid()
                const outid = uuid()
                var obj = myRect.obj
                if (ctx.subsub === 'programmingBox') {
                    var output = obj.clone()
                    output.strokeColor = "black"
                    output.strokeWidth = 2;
                    output.position.x = obj.bounds.bottomRight.x + 50 + output.bounds.width / 2
                    output.data.stoplightGrp = outid
                    output.data.linkData = {
                        type: "stoplight", id: outid
                    }
                    output.data.fixSize = true
                    updateDrawPaperObj(output, null)
                }
                obj.fillColor = mkColor(ctx.color, 4)
                obj.data.linkData = {
                    type: "textBox", id: id,
                    stroke: ctx.brushRadius * 2, fontsz: gText
                }
                obj.data.shape = true
                obj.data.stackwithtime = true
                obj.data.linkDataButtonDraw = true
                obj.data.linkButton = true
                obj.data.fixSize = true
                if (ctx.subsub === 'programmingBox') {
                    let linkData = {
                        type: "runProgram", id: id, outid: outid,
                    }
                    function createClickProg(gr, link, textIn) {
                        var ff = gr.bounds.topLeft
                        var rr = {}
                        rr.x = ff.x
                        rr.y = ff.y - 45
                        rr.height = 30
                        rr.width = 80
                        return createButtonCommon(gr, link, textIn, rr)
                    }
                    var r = createClickProg(obj, linkData, "RUN")
                    var group = new paper.Group([obj, r])

                    group.data.linkDataButtonDraw = true
                    group.data.linkButton = true
                    group.data.linkData = obj.data.linkData
                    updateDrawPaperObj(group, null)

                    group.remove()
                    obj.remove()
                    r.remove()
                    delete ctx['subsub']
                    handleEscape()
                    myRect.obj.remove()
                    myRect = { obj: null, start: null }
                    return
                }
            } else if (ctx.subMode === "select") {
                return endSelect(myRect.obj, e)
            } else if (ctx.subMode === "gridBox") {
                return endGridBox(myRect.obj, e)
            } else {
                myRect.obj.data.shape = true
                myRect.obj.data.stackwithtime = true

            }
            updateDrawPaperObj(myRect.obj, null)
            myRect.obj.remove()
            lastObjDraw = true;
        }
        myRect = { obj: null, start: null }
    }
    function programClickRun(e, evt) {
        var basics = `
        function Circle(x, y, radius) {
            return window.code('Circle', x, y, radius)
        }
        function Rectangle(x, y, x1, y1) {
            return window.code('Rectangle', x, y, x1, y1)
        }
        function Line(x, y, x1, y1) {
            return window.code('Line', x, y, x1, y1)
        }
        function Print(str) {
            window.code('Print', str)
        }
        function AddRobot(str) {
            return window.code('AddRobot', str)
        }
        function SaveObject(obj) {
            return window.code('SaveObject', obj)
        }
        `;

        if (gTextBox[e.data.linkData.id]) {
            var old = gTextBox[e.data.linkData.id]
            var text = old._content
            var ff = basics + text
            window.paper = paper;
            window.code = codeFunction;
            window.output = e.data.linkData.outid
            window.code('Print', null); //empty out the buffer 
            try {
                window.eval(ff)
            } catch (e) {
                console.log("ERROR", e)
                window.code('Print', "\n---ERROR---\n")
                window.code('Print', e)
            }
            //  https://neil.fraser.name/software/JS-Interpreter/index.html
        }
    }
    const Outfunctions = {
        'Print': printFn,
        'Circle': printCircle,
        'SaveObject': SaveObject,
        'Rectangle': printRectanle,
        'Line': printLine,
        'AddRobot': AddRobot,

    }

    function AddRobot(o, obj) {
        var path = "/robots/" + obj.toLowerCase() + ".png"
        let raster = new paper.Raster({ source: path, crossOrigin: 'anonymous' });
        raster.position = paper.view.center;
        return raster
    }
    function SaveObject(o, obj) {
        updateDrawPaperObj(obj, null)
        obj.remove()
    }
    function printLine(o, x, y, x1, y1) {
        var myCircle = new Path.Line(new paper.Point(x, y), new paper.Point(x1, y1));
        return myCircle
    }
    function printRectanle(o, x, y, x1, y1) {
        var myCircle = new Path.Rectangle(new paper.Point(x, y), new paper.Point(x1, y1));
        return myCircle
    }
    function printCircle(o, x, y, radius) {
        var myCircle = new Path.Circle(new paper.Point(x, y), radius);
        return myCircle
    }

    function printFn(o, str) {
        var ld = o.data.linkData
        function updateGQL() {
            var rr = gStopLight[ld.id].data.id
            if (rr && paperObj[rr] && paperObj[rr].gqlObj) {
                updateDrawPaperGqlObj(paperObj[rr].gqlObj, gStopLight[ld.id], null)
            }
        }

        if (gStopLight[ld.id]) {
            // ihave a text object already 
            if (str === null) gStopLight[ld.id].content = ""
            else
                gStopLight[ld.id].content += str
            updateGQL()
            return
        }

        var b = o.bounds.topLeft
        var text = new paper.PointText(new paper.Point(b.x + 20, b.y + 20))
        text.data.stoplight = { stoplightID: ld.id, index: ld.index, sess: gSessionID }
        text.content = str
        text.style = {
            fontFamily: 'roboto',
            fontSize: 14,
            fillColor: ctx.SavedColor,
            justification: 'left'
        };
        updateDrawPaperObj(text, null)
        text.remove()
    }
    function codeFunction(ftype, ...args) {
        var obj = gStopLightParent[window.output]
        if (ftype in Outfunctions) {
            return Outfunctions[ftype](obj, ...args)
        }
    }
    function rectMove(x, y, e, isRect) {
        if (!myRect.start) return
        if (e.shiftKey || isRect) {
            var end = regularSnapLength(myRect.start, { x: x, y: y })
            myRect.end = new Point(end.x, end.y)
        } else {
            myRect.end = new Point(x, y)
        }
        if (myRect.obj) {
            myRect.obj.remove()
        }
        myRect.obj = new paper.Path.Rectangle(myRect.start, myRect.end)
        if (ctx.subMode === "select") {
            myRect.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
            myRect.obj.strokeWidth = 1
            myRect.obj.dashArray = [2, 2];
        } else {
            myRect.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
            myRect.obj.strokeWidth = ctx.brushRadius * 2
            myRect.obj.dashArray = ctx.lineStyle;
        }
    }
    var myCircle = { obj: null, start: null, end: null }
    function startCircle(e) {
        if (myCircle.start) {
            circleEnd()
            return
        }
        myCircle = { obj: null, start: null }
        const [x, y] = getPoint(e)
        myCircle.start = new paper.Point(x, y)

    }
    function circleEnd() {
        if (myCircle.obj) {
            myCircle.obj.data.shape = true
            myCircle.obj.data.stackwithtime = true

            updateDrawPaperObj(myCircle.obj, null)
            myCircle.obj.remove()
            lastObjDraw = true;
        }
        myCircle = { obj: null, start: null }
    }
    function circleMove(x, y, e, isCircle) {
        if (!myCircle.start) return
        if (e.shiftKey || isCircle) {
            var end = regularSnapLength(myCircle.start, { x: x, y: y })
            myCircle.end = new paper.Point(end.x, end.y)
        } else {
            myCircle.end = new paper.Point(x, y)
        }
        if (myCircle.obj) {
            myCircle.obj.remove()
        }
        var vect = myCircle.end.subtract(myCircle.start)
        myCircle.obj = new paper.Path.Ellipse({
            point: myCircle.start,
            size: vect
        })

        myCircle.obj.strokeColor = mkColor(ctx.color, ctx.opacity);
        myCircle.obj.strokeWidth = ctx.brushRadius * 2
        myCircle.obj.dashArray = ctx.lineStyle
    }
    function startIframe(e, windowType) {
        const [x, y] = getPoint(e, true);
        const userid = gUser ? gUser.id : null;
        const id = uuid();
        var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
        ib.createObject(id, "name", session.id, JSON.stringify({
            type: "extWindow",
            position: { x: x - drawCanvasPos.x, y: y - drawCanvasPos.y },
            iframeUrl: "",
            windowType: windowType
        }), "extWindow", userid, null, session.ttl).then(res => {
            const copy = res.data.createObject
            delete copy["Session"];
            ib.updateObject({ ...copy });
            setExtWebpages(prev => {
                const tempExtWebpages = { ...prev };
                tempExtWebpages[copy.id] = copy;
                if (Object.keys(tempExtWebpages).length > 0) gExt = true
                else gExt = false
                return tempExtWebpages;
            });
        })
    }
    function fixEvent(event) {
        var x = event.offsetX
        var y = event.offsetY
        if (!event.hasOwnProperty('offsetX')) {
            x = event.layerX - event.currentTarget.offsetLeft;
            y = event.layerY - event.currentTarget.offsetTop;
        }
        return [x, y]
    }

    function handleDot(e) {
        if (ctx.pointertype && ctx.mode === "draw") {
            return DrawDotHere(e)
        }
        if (ctrlDown) {
            handleDblClick(e)
            return
        }
        if (boundBRect) return (handleBClicked(e))
        var [x, y] = fixEvent(e)
        var currentTime = new Date().getTime();
        var lastTextTime = currentTime - lastText;

        if (lastTextTime < 300) return
        var tapLength = currentTime - lastTap;
        clearTimeout(dtTimeout);
        if (tapLength < 500 && tapLength > 0) {
            lastTap = 0
            lastObjDraw = false;
            //double click 
        } else {
            dtTimeout = setTimeout(function () {
                if (!lastObjDraw) {
                    lastObjDraw = true;
                    return
                }
                clearTimeout(dtTimeout);
                ctx.AutoMove = ctx.mode
                startMoveResize(e)
                //single click
                return // no more dots 

            }, 500);
        }
        lastTap = currentTime;
    }
    function DrawDotHere(e) {
        const [x, y] = getPoint(e)
        var myCircle = new Path.Circle(new Point(x, y), ctx.brushRadius);
        var col = mkColor(ctx.color, ctx.opacity)
        myCircle.strokeColor = col;
        myCircle.fillColor = col;
        myCircle.strokeWidth = ctx.brushRadius
        myCircle.dashArray = ctx.lineStyle
        updateDrawPaperObj(myCircle, null)
        myCircle.remove()
    }
    function handleClick(e) {
        e.preventDefault()
        if (gLocked) return
        if (ctx.mode === 'draw') setShowTextTools(true)
        if (ctx.mode === "text") {
            setShowTextTools(true)
            selectedObj = getHitObj(e)
            if (selectedObj) {
                var skip = false
                var gg = findSelectedGQL()
                if (!gg) skip = true
                if (gg && gg.SessionID !== gSessionID) {
                    skip = true;
                }
                if (!skip && selectedObj instanceof paper.PointText) {
                    var foundKey = findSelectedKey()
                    return handleEditText(e, foundKey)
                }
            }
            handleKeyDown(e, null)
            // ctx.mode = defaultTool
            // ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        }
        if (ctx.mode === "richText") {
            setShowTextTools(true)
            startRichText(e)
            ctx.mode = defaultTool
            ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        }
        if (ctx.mode === "youtube") {
            setShowTextTools(false)
            startIframe(e, 'youtube');
            ctx.mode = defaultTool
            ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        }
        if (ctx.mode === "website") {
            setShowTextTools(false)
            startIframe(e, 'website');
            ctx.mode = defaultTool
            ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        }
        if (ctx.mode === "breakApart") return checkBreak(e);
        if (ctx.mode === "math") {
            setShowTextTools(true)
            selectedObj = getHitObj(e)
            if (selectedObj) {
                let skip = false
                let gg = findSelectedGQL()
                if (!gg) skip = true
                if (gg && gg.SessionID !== gSessionID) {
                    skip = true;
                }
                if (!skip && selectedObj.data && selectedObj.data.math) {
                    let foundKey = findSelectedKey()

                    return handleEditMath(foundKey)
                }
            }
            if (!mathInput) handleMathInput(e, null);
        }
    }

    const breakApartList = {
        '1000block.png': { v: 10, s: "100Block.png", d: 'h' },
        '100Block.png': { v: 10, s: '10Block.png', d: 'h' },
        '10Block.png': { v: 10, s: "1Block.png", d: 'v' },
        'ncoin-1d-head.png': { v: 2, s: "ncoin-50c-head.png", d: 'h' },
        'ncoin-1d-tail.png': { v: 2, s: "ncoin-50c-tail.png", d: 'h' },
        'ncoin-50c-tail.png': { v: 2, s: "ncoin-25c-tail.png", d: 'h' },
        'ncoin-50c-head.png': { v: 2, s: "ncoin-25c-head.png", d: 'h' },
        'ncoin-25c-head.png': { v: 5, s: "ncoin-5c-head.png", d: 'h' },
        'ncoin-25c-tail.png': { v: 5, s: "ncoin-5c-tail.png", d: 'h' },
        'ncoin-10c-head.png': { v: 2, s: "ncoin-5c-head.png", d: 'h' },
        'ncoin-10c-tail.png': { v: 2, s: "ncoin-5c-tail.png", d: 'h' },
        'ncoin-5c-head.png': { v: 5, s: "ncoin-1c-head.png", d: 'h' },
        'ncoin-5c-tail.png': { v: 5, s: "ncoin-1c-tail.png", d: 'h' },
        'bill-50.png': { v: 5, s: "bill-10.png", d: 'v' },
        'bill-20.png': { v: 2, s: "bill-10.png", d: 'v' },
        'bill-10.png': { v: 2, s: "bill-5.png", d: 'v' },
        'bill-5.png': { v: 5, s: "bill-1.png", d: 'v' },
        'bill-1.png': { v: 4, s: "ncoin-25c-tail.png", d: 'h' },
        'coin-2eu.png': { v: 2, s: "coin-1eu.png", d: 'h' },
        'coin-1eu.png': { v: 2, s: "coin-50c.png", d: 'h' },
        'coin-50c.png': { v: 5, s: "coin-10c.png", d: 'h' },
        'coin-20c.png': { v: 2, s: "coin-10c.png", d: 'h' },
        'coin-10c.png': { v: 2, s: "coin-5c.png", d: 'h' },
        'coin-5c.png': { v: 5, s: "coin-1c.png", d: 'h' },
        'bill-500eu.png': { v: 5, s: "bill-100eu.png", d: 'v' },
        'bill-200eu.png': { v: 2, s: "bill-100eu.png", d: 'v' },
        'bill-100eu.png': { v: 2, s: "bill-50eu.png", d: 'v' },
        'bill-50eu.png': { v: 5, s: "bill-10eu.png", d: 'v' },
        'bill-20eu.png': { v: 2, s: "bill-10eu.png", d: 'v' },
        'bill-10eu.png': { v: 2, s: "bill-5eu.png", d: 'v' },
        'bill-5eu.png': { v: 5, s: "coin-1eu.png", d: 'h' },

        'Bill-100.png': { v: 2, s: "Bill-50.png", d: 'v' },
        'Bill-50.png': { v: 5, s: "Bill-10.png", d: 'v' },
        'Bill-20.png': { v: 2, s: "Bill-10.png", d: 'v' },
        'Bill-10.png': { v: 2, s: "Bill-5.png", d: 'v' },
        'Bill-5.png': { v: 5, s: "Coin-Dollar-1.png", d: 'h' },
        'Coin-Dollar-2.png': { v: 2, s: "Coin-Dollar-1.png", d: 'h' },
        'Coin-Dollar-1.png': { v: 4, s: "Coin-Cent-25.png", d: 'h' },
        'Coin-Cent-25.png': { v: 5, s: "Coin-Cent-5.png", d: 'h' },
        'Coin-Cent-10.png': { v: 2, s: "Coin-Cent-5.png", d: 'h' },


        'coin-50p.png': { v: 2, s: "coin-25p.png", d: 'h' },
        'coin-1rs.png': { v: 2, s: "coin-50p.png", d: 'h' },
        'coin-2rs.png': { v: 2, s: "coin-1rs.png", d: 'h' },
        'coin-5rs.png': { v: 5, s: "coin-1rs.png", d: 'h' },
        'coin-10rs.png': { v: 2, s: "coin-5rs.png", d: 'h' },
        'coin-20rs.png': { v: 2, s: "coin-10rs.png", d: 'h' },
        'bill-1rs.png': { v: 2, s: "coin-50p.png", d: 'h' },
        'bill-5rs.png': { v: 5, s: "bill-1rs.png", d: 'v' },
        'bill-10rs.png': { v: 2, s: "bill-5rs.png", d: 'v' },
        'bill-20rs.png': { v: 2, s: "bill-10rs.png", d: 'v' },

        'bill-100rs.png': { v: 5, s: "bill-20rs.png", d: 'v' },
        'bill-200rs.png': { v: 2, s: "bill-100rs.png", d: 'v' },
        'bill-500rs.png': { v: 5, s: "bill-100rs.png", d: 'v' },
        'bill-2000rs.png': { v: 4, s: "bill-500rs.png", d: 'v' },


    }
    function loadImageWithPromise(img, x, y) {
        return new Promise((resolve, reject) => {
            var raster = new paper.Raster({ source: img, crossOrigin: 'anonymous' });
            raster.onLoad = function () {
                var xx = raster._size.width / 2
                var yy = raster._size.height / 2
                xx += x;
                yy += y
                raster.position = new Point(xx, yy)
                updateDrawPaperObj(raster, donewrite)
                function donewrite(obj) {
                    raster.remove()
                    resolve(raster)
                }
            }
            raster.onError = function (f) {
                reject(f)
            }
        })
    }

    function checkBreak(e) {
        selectedObj = getHitObj(e)
        if (selectedObj) {
            let bobj = selectedObj
            let skip = false
            let gg = findSelectedGQL()
            if (!gg) skip = true
            if (gg && gg.SessionID !== gSessionID) {
                skip = true;
            }
            clearSelect()
            if (!skip) {
                let arr = []
                processElemMeat(bobj, arr)
                arr.forEach(async a => {
                    if (a.includes("http")) {
                        var filename = a.split(/[\\\/]/).pop();
                        let orig = filename
                        if (filename in breakApartList) {
                            let ba = breakApartList[filename]
                            let img = a.replace(orig, ba.s)
                            var x = bobj.bounds.topLeft.x
                            var y = bobj.bounds.topLeft.y
                            doPixieMagic({
                                x: bobj.bounds.center.x, y: bobj.bounds.center.y,
                                num: 20, type: "pixiedust"
                            })
                            bobj.remove()
                            ib.delObject(gg.id)
                            for (let i = 0; i < ba.v; i++) {
                                let r = await loadImageWithPromise(img, x, y);
                                await new Promise(r => setTimeout(r, 100));

                                if (ba.d === "v") {
                                    y += r.bounds.height / 1.5
                                } else {
                                    x += r.bounds.width / 1.5
                                }
                            }
                        }
                    }
                })
            }
        }
    }

    function delSnake(sn, now, force) {
        if (sn.snakeObj && sn.lastSnake) {
            var diff = now - sn.lastSnake
            if (diff > 5000 || force) {
                sn.snakeObj.remove()
                delete sn['snakeObj']
                delete sn['lastSnake']
            }
        }
    }
    function clearRectAll() {
        var dt = new Date()
        if (ctx.isDrawing) return
        if (snake.snakeObj) {
            delSnake(snake, dt, false)
        }
        for (let p in peers) {
            delSnake(peers[p], dt, false)
        }
        // const width = ctx.canvas.temp.width
        // const height = ctx.canvas.temp.height
        // ctx.context.temp.clearRect(0, 0, width, height)
    }
    function snakeMove(x, y, savesnake, updateGql, color, type) {
        if (type === "snake") {
            snakeMoveSnake(x, y, savesnake, updateGql, color)
        } else {
            snakeMoveSpotLight(x, y, savesnake, updateGql, color)
        }
    }

    function snakeMoveSnake(x, y, savesnake, updateGql, color) {
        var path = savesnake.snakeObj
        var lastUpdate = new Date()
        savesnake.lastSnake = lastUpdate
        if (!path) {
            if (!defaultTimer) {
                defaultTimer = window.setInterval(clearRectAll, 5000);
            }
            path = new paper.Path({
                //  strokeColor: '#E4141B',
                strokeWidth: 20,
                strokeCap: 'round',
            });

            var start = paper.view.center / [10, 1];
            for (var i = 0; i < SNAKESIZE; i++)
                path.add(start + new paper.Point(i * 35, 0));
            savesnake.snakeObj = path
        }
        var tempPoint = new paper.Point(x, y)

        path.firstSegment.point = tempPoint;
        for (var i = 0; i < SNAKESIZE - 1; i++) {
            var segment = path.segments[i];
            var nextSegment = segment.next;
            var vector = segment.point.subtract(nextSegment.point)
            vector.length = 35;
            nextSegment.point = segment.point.subtract(vector)
        }
        var stokecol = {
            gradient: {
                // blue from 0% to 33%, mix between blue and red from 33% to 66%, and remaining red (66% to 100%)
                // mix between red and black from 20% to 100%:
                //  stops: [['blue', 0.10], ['red', 0.10], ['yellow', 0.10]]
                stops: [[color, 0.05], ['yellow', 0.2], ['red', 1]],

            },
            origin: path.position,
            destination: path.bounds.rightCenter
        }
        path.strokeColor = stokecol
        path.smooth({ type: 'continuous' });
        savesnake.snakeObj = path
        if (updateGql) {
            sendPeers({
                type: "snakeMove", x: x, y: y,
                color: ctx.color, name: myName, brush: ctx.brushRadius, sub: ctx.subMode,
            })
        }
        return path
    }

    function snakeMoveSpotLight(x, y, savesnake, updateGql, color) {
        var path = savesnake.snakeObj
        var lastUpdate = new Date()
        savesnake.lastSnake = lastUpdate
        if (!path) {
            if (!defaultTimer) {
                defaultTimer = window.setInterval(clearRect, 5000);
            }
            var start = paper.view.center;
            path = new Path.Circle(start, 40)
            path.strokeWidth = 2
            path.strokeColor = 'yellow';
            path.fillColor = mkColor(color, 50)
            savesnake.snakeObj = path
        }
        path.position.x = x
        path.position.y = y
        savesnake.snakeObj = path
        if (updateGql) {
            sendPeers({
                type: "snakeMove", x: x, y: y, sub: ctx.subMode,
                color: ctx.color, name: myName, brush: ctx.brushRadius,
            })
        }
        return path
    }


    function drawRemoteSnake(mm) {
        const m = mm.msg

        try {
            m.x = parseInt(m.x)
            m.y = parseInt(m.y)
        } catch (e) {
            console.log("ERRORE", e)
            return
        }
        var p = peers[mm.myID]
        if (!p) {
            console.log("PEER NOT FOUND", mm.myID)
            return
        }
        snakeMove(m.x, m.y, p, false, m.color, m.sub)
    }
    var debounceOff = null
    function handlePointerDown(e) {
        var leftClick = !e.button || e.button === 0
        updateEngagement("click")
        if (gLocked) {
            if (Boolean(gTeacher)) {
                if (debounceOff) {
                    return
                }
                if (isApiMode) return
                alert("Please close sidebar from bottom left, when the side bar is open, the board is read only")
                debounceOff = setTimeout(function () {
                    clearTimeout(debounceOff)
                    debounceOff = null
                }, 5000)
            }
            return
        }
        if (ctx.mode === 'zoomIn') {
            ctx.isPressing = true
            return zoomClick(e)
        }

        if (sessClockWidgets > 0 && clockHit(e)) {
            return
        }
        if (sessSpinnerWidgets > 0 && spinnerHit(e)) {
            return
        }
        if (sessFDiceWidgets > 0 && fDiceHit(e)) {
            return
        }
        if (buttonClicks > 0 && buttonHit(e)) {
            return
        }
        if (ctx.mode === "insertLink") return linkInsertClick(e)
        if (ctx.mode === "dot") return DrawDotHere(e)
        if (ctx.mode === "line") {
            startLine(e)
            return
        }
        if (ctx.mode === "moveResize" && leftClick) {
            return startMoveResize(e)
        }
        if (ctx.mode === "arrow") return startArrow(e)
        if (ctx.mode === "rect" && leftClick) {
            startRect(e)
            return
        }
        if (ctx.mode === "circle") {
            startCircle(e)
            return
        }
        if (ctx.mode === "selectAnimate") {
            handleDblClick(e)
            ctx.canvas.interface.style.cursor = "auto"
            return
        }
        if (ctx.mode === "compass") return compassDown(e);
        // get position 
        const [x, y] = getPoint(e)
        ctx.startPoint = { x: x, y: y }
        ctx.isPressing = true
        if (ctx.mode === "lasso") return startLasso(e)
        if (e.button === 2) {
            if (!isApiMode) handleButtonDraw('Erase')
            return
        }

    }

    var lastSec = 0
    function boundBlink(pobj) {
        var ff = pobj.bounds
        var rr = {}
        rr.x = ff.x - 10
        rr.y = ff.y - 10
        rr.height = ff.height + 20
        rr.width = ff.width + 20
        var cornerSize = new paper.Size(10, 10);
        var rrT = new paper.Rectangle(rr)

        let rectanglePath = new Path.Rectangle(rrT, cornerSize)
        rectanglePath.strokeWidth = 8
        rectanglePath.strokeColor = "#3174F5"
        rectanglePath.data.blinker = true
        return rectanglePath
    }

    function setBlink(pobj) {
        pobj.children.forEach((c) => {
            //c.selected = true
            if (c.data.blinker) {
                pobj.data.blink = c
                return
            }
        })
    }

    function onFrame(step) {
        if (gpause) return
        //console.log("LAST OBJ", lastObj)
        for (let key in rotateObj) {
            var r = rotateObj[key]
            // Each frame, rotate the copy by 1 degree:
            r.rotate(1);
        }
        animateStars()
        for (let key in colorObj) {
            let r = colorObj[key]
            if (!r.data.blink) {
                setBlink(r)
            }
            // if (!r.data.blink) {
            //     r.data.blink = boundBlink(r)
            // }
            if (r.data.blink)
                r.data.blink.strokeColor.hue += 1
        }
        if (moveObjs !== {}) {
            var ff = Math.round(step / 500)
            if (lastSec !== ff) {
                lastSec = ff
                for (let key in moveObjs) {
                    var m = moveObjs[key]
                    if ('index' in m) {
                        var idx = m['index'] + 1
                    } else {
                        idx = 0
                    }
                    if (idx >= m.path.length) {
                        idx = 0
                    }
                    var cur = m.path[idx]
                    if (cur && m.obj && m.obj.position) {
                        m.obj.position.x = cur.x
                        m.obj.position.y = cur.y
                    }
                    m['index'] = idx
                }
            }
        }
    }

    function updateAnimate(dat) {
        if (dat.animate && (dat.id in paperObj)) {
            const ff = JSON.parse(dat.animate)
            if (ff && 'rotate' in ff) {
                if (ff['rotate']) {
                    if (!(dat.id in rotateObj)) {
                        rotateObj[dat.id] = paperObj[dat.id].obj
                    }
                } else {
                    delete rotateObj[dat.id]
                }
            }
            if (ff && 'color' in ff) {
                if (ff['color']) {
                    if (!(dat.id in colorObj)) {
                        colorObj[dat.id] = paperObj[dat.id].obj
                    }
                } else {
                    delete colorObj[dat.id]
                }
            }
            if (ff && 'path' in ff) {
                moveObjs[dat.id] = { obj: paperObj[dat.id].obj, path: ff.path }
            }
        }
    }
    function drawPaperPath(obj, segments, color, radius, lineStyle) {
        const id = obj.id
        if (id in paperObj) {
            updateAnimate(obj)
            if (paperObj[id].gqlObj && obj.ended && paperObj[id].gqlObj.ended) {
                //already drawn
                paperObj[id].gqlObj = obj
                paperObj[id].gqlObj.id = id
                // paperObj[id].gqlObj.CreatedBy = obj.CreatedBy
                // paperObj[id].gqlObj.updatedAt = obj.updatedAt
                return
            }
            var path = paperObj[id].obj
            if (!path || !path.add) return
            paperObj[id].gqlObj = JSON.parse(JSON.stringify(obj))
            for (var i = 0; i < segments.length; i++)
                path.add(segments[i])
            path.smooth({ type: 'continuous' })
            return
        }
        path = new paper.Path({
            segments: segments,
            strokeColor: color,
            strokeWidth: radius * 2
        });
        path.dashArray = lineStyle
        // path.simplify(2);
        paperObj[id] = { obj: path, type: "draw", gqlObj: JSON.parse(JSON.stringify(obj)) }
        attachTools(path)
        paperObj[id].gqlObj.id = id
        path.data.id = id
        path.smooth({ type: 'continuous' })
        //path.simplify();
        updateAnimate(obj)
        return path
    }
    function drawPText(txt, x, y, color, size, obj, font) {
        if (obj.id in paperObj) {
            if (paperObj[obj.id].gqlObj && paperObj[obj.id].gqlObj.updatedAt ===
                obj.updatedAt) {
                return paperObj[obj.id].obj
            }
            paperObj[obj.id].obj.remove()
            delete paperObj[obj.id]
            delete gStopLight[obj.id]
        }
        var pointTextLocation = new paper.Point(x + 2, y + 10);
        var text = new paper.PointText(pointTextLocation);
        text.data.id = obj.id
        text.content = txt;
        text.style = {
            fontFamily: font,
            fontSize: 36,
            fillColor: color,
            justification: 'left'
        };
        paperObj[obj.id] = { obj: text, type: "text", gqlObj: JSON.parse(JSON.stringify(obj)) }
        attachTools(text)
        return text
    }
    function drawPSvg(svgString, x, y, color, size) {
        paper.project.importSVG(svgString, function (item) {
            var svg = item
            svg.scale(size * 3)
            svg.position = new paper.Point(x + (0.5 * svg.bounds.width), y + (0.5 * svg.bounds.height));
            svg.fillColor = color
            svg.data.math = true
            svg.data.mathValue = mathInput.inputValue
            svg.data.fonts = { color: color, sz: size }
            updateDrawPaperObj(svg, donePaper)
            function donePaper(nObj) {
                svg.remove()
            }
        });
        // paperObj[obj.id] = { obj: svg, type: "svg", gqlObj: JSON.parse(JSON.stringify(svg)) }
        // attachTools(svg)
        // return svg
    }
    function donePath(e) {
        var oldId = myObj.id
        var pobj = drawPaperPath(myObj, ctx.points, mkColor(ctx.color, ctx.opacity),
            ctx.brushRadius, ctx.lineStyle)
        if (!pobj) return false
        if (pobj) {
            let ht = updateSticky(e, null, pobj)
            if (ht) {
                if (oldId) {
                    ib.delObject(oldId)
                }
                return false
            }
        }
        return true
        //        var segmentCount = path.segments.length;

        // When the mouse is released, simplify it:
        //path.simplify(10);

        // Select the path, so we can see its segments:
    }
    function pathMove(x, y) {
        if (!ctx.isPressing) return
        if (!animatePath) {
            animatePath = new paper.Path({
                segments: new Point(x, y),
                strokeColor: "black",
                strokeWidth: 2
            });
            animatePath.dashArray = [10, 12];
            return
        }
        animatePath.add(new Point(x, y))
    }

    function pathUp(e) {
        ctx.mode = "draw"
        ctx.canvas.interface.style.cursor = "auto"
        if (animatePath) {
            animatePath.simplify(2)
            if (selectedObj) {
                var foundKey = findSelectedKey()
                var pathSave = []
                animatePath.segments.forEach((f) => {
                    pathSave.push({ x: f.point.x, y: f.point.y })
                })
                if (!paperObj[foundKey] || !paperObj[foundKey].obj) {
                    clearSelect()
                    animatePath.remove()
                    return
                }
                moveObjs[foundKey] = { obj: paperObj[foundKey].obj, path: pathSave }
                var animate = {}
                if (paperObj[foundKey].gqlObj && paperObj[foundKey].gqlObj.animate) {
                    animate = JSON.parse(paperObj[foundKey].gqlObj.animate)
                }
                animate['path'] = pathSave
                if (paperObj[foundKey].gqlObj) {
                    paperObj[foundKey].gqlObj.animate = JSON.stringify(animate)
                    ib.updateAnimateObject(paperObj[foundKey].gqlObj)
                }
            }
            animatePath.remove()
        }
        animatePath = null
        clearSelect()
    }

    function handleMouseOut(e) {
        if (ctx.isDrawing) {
            handlePointerUp(e)
        }
    }
    function handlePointerUp(e) {
        ctx.isPressing = false
        if (clockSelect) {
            handleClockPointerUp(clockSelect, e)
            clockSelect = null
            return
        }
        if (ctx.mode === "rect") {
            rectEnd(e)
            return
        }
        if (ctx.mode === "circle") {
            circleEnd(e)
            return
        }
        if (ctx.mode === "line") {
            lineEnd()
            return
        }
        if (spinnerSelect) {
            handleSpinnerPointerUp(spinnerSelect, e)
            spinnerSelect = null
            return
        }
        if (fdiceSelect) {
            handleFdicePointerUp(fdiceSelect, e)
            fdiceSelect = null
            return
        }
        if (buttonClicked) {
            if (ctx.mode === "ScreenShade") return ScreenShadeDone()
            if (ctx.mode === "rbead") return RbeadDone()

            buttonClicked = null
            return
        }
        if (capslock) {
            return
        }
        if (gLocked) return
        e.preventDefault()
        if (ctx.mode === "moveResize" && e.button === 0) {
            return
        }
        //  e.preventDefault()
        if (ctx.mode === "selectAnimate") {
            //handleDblClick(e)
            return
        }
        if (ctx.mode === "compass") return compassUp(e);

        myObj.ended = true

        if (ctx.isDrawing) {
            lastObjDraw = true;
            let ud = donePath(e)
            if (ud) updateDraw(false, true)
            if (ctx.mode === "magicDraw") magicDrawTimer(true)
            if (ctx.mode === "magicHand") magicDrawTimer(true)

        } else {
            if (ctx.mode === "draw") {
                handleDot(e)
            }
            if (ctx.mode === "erase" && e.button === 2) {
                //right click
                var dbl = mylocalStorage.getItem("dblClickDisable")
                if (!dbl) handleDblClick(e)
            }

        }
        ctx.isDrawing = false

        if (ctx.drawPath) ctx.drawPath.remove();
        if (ctx.mode === "move") return pathUp(e)
        if (ctx.mode === "lasso") return endLasso(e)
        ctx.points.length = 0

        if (ctx.mode === "edit") {
            lastTap = 0
            doneMove(e)
            return
        }
        if (ctx.mode === "resize" || ctx.mode === "rotate") {
            lastTap = 0
            doneMove(e)
            return
        }
        if (e.button === 2) {
            //clearCanvas()
            var dbl = mylocalStorage.getItem("dblClickDisable")
            if (!dbl) handleButtonDraw('Draw')
            return
        }
    }

    function midPointBtw(p1, p2) {
        return {
            x: p1.x + (p2.x - p1.x) / 2,
            y: p1.y + (p2.y - p1.y) / 2
        };
    }
    function prepObj(obj, compressed) {
        var copy = JSON.parse(JSON.stringify(obj))
        if (!copy.content) return null

        delete copy['created']
        copy.type = JSON.stringify({ 'compressed': compressed })
        if (compressed)
            copy.content = pako.deflate(copy.content, { to: 'string' });
        return copy
    }
    function compress(content, compressed) {
        var copy = {}
        copy.type = JSON.stringify({ 'compressed': compressed })
        if (compressed)
            copy.content = pako.deflate(content, { to: 'string' });
        return copy
    }
    function checkUpdateUserColor() {
        var ct = {}
        if (!gUser) {
            return
        }
        if (gUser.content) {
            ct = JSON.parse(gUser.content)
        }
        if (ct.color !== ctx.color) {
            ct.color = ctx.color
            gUser.content = JSON.stringify(ct)
            setUser(gUser)
            delete gUser['Session']
            ib.updateUser(gUser)
        }
    }

    function checkUpdateUserName(myName) {
        if (!gUser) {
            return
        }

        if (gUser.name !== myName) {
            gUser.name = myName
            setUser(gUser)
            delete gUser['Session']
            ib.updateUser(gUser)
        }
    }

    function updateDraw(newSession, endSession) {
        var compressed = false
        if (!session && newSession) {
            return
        }
        if (ctx.points.length > 70) {
            compressed = true
        }
        var content = {
            type: "drawFree", points: ctx.points, ended: endSession,
            color: mkColor(ctx.color, ctx.opacity), brushRadius: ctx.brushRadius, compressed: compressed,
            dashArray: ctx.lineStyle
        }
        if (newSession) {
            const id = uuid()
            myObj.id = id
            myObj.created = false
            myObj.SessionID = gSessionID
            objDict[id] = { lastIndex: 0, mine: true, obj: JSON.parse(JSON.stringify(myObj)) }
            try {
                checkUpdateUserColor()
                const userid = gUser ? gUser.id : null
                ib.createObject(id, "name", session.id, JSON.stringify(content), "drawFree", userid, null, session.ttl).then(res => {
                    const obj = res.data.createObject
                    if ((ctx.mode === "magicDraw" || ctx.mode === "magicHand") && magicDrawObj.pobj) {
                        magicDrawObj.pobj.push(obj);
                    }
                    // myObj.CreatedBy = obj.CreatedBy
                    // myObj.updatedAt = obj.updatedAt
                    // if finshed by now we should update 
                    if (obj && objDict[obj.id] && objDict[obj.id].obj && objDict[obj.id].obj.ended) {
                        var copy = prepObj(objDict[obj.id].obj, compressed)
                        if (copy) {
                            copy.id = obj.id
                            var svg = getCompressedSvg(ctx.drawPath)
                            if (svg) copy.SVGObj = svg
                            ib.updateObject(copy)
                        }
                    }
                    if (obj && objDict[obj.id] && objDict[obj.id].obj) {
                        objDict[obj.id].obj.CreatedBy = userid
                        objDict[obj.id].obj.created = true
                    }
                })
            } catch (err) {
                console.error("ERROR D", err)
            }
        } else {
            if (myObj.id && myObj.id in objDict) {
                var obj = objDict[myObj.id].obj
                if (!obj) return
                obj.ended = endSession
                obj.content = JSON.stringify(content)
                if (!obj.created) return
                if (endSession) {
                    clearTimeout(debounceTimer)
                    debounceTimer = null
                    var copy = prepObj(obj, compressed);
                    if (copy) {
                        var svg = getCompressedSvg(ctx.drawPath)
                        if (svg) copy.SVGObj = svg
                        ib.updateObject(copy)
                    }
                    setMyObj()
                    myObj.id = null
                } else {
                    if (debounceTimer === null) {
                        debounceTimer = setTimeout(() => {
                            async function write() {
                                if (obj && obj.id) {
                                    var copy = prepObj(obj, compressed)
                                    copy.ended = false
                                    if (copy) ib.updateObject(copy)
                                }
                                // Once the debounceTimer fires, we have to reset it
                                debounceTimer = null;
                            }
                            write()
                        }, 800)
                    }
                }
            }
        }
    }

    function drawText(txt, x, y, size) {
        var font = mylocalStorage.getItem("font")
        font = font ? font : "Roboto"

        var col = savePos && savePos.fonts ? savePos.fonts.color : mkColor(ctx.SavedColor, ctx.opacity)
        font = savePos && savePos.fonts ? savePos.fonts.font : font
        var sz = savePos && savePos.fonts ? savePos.fonts.sz : gText
        var content = {
            type: "text", points: { x: x, y: y }, text: txt,
            ended: true, color: col,
            brushRadius: sz, font: font
        }
        if (!session) return
        const id = uuid()
        myObj.id = id
        myObj.SessionID = gSessionID
        objDict[id] = { lastIndex: 0, mine: true, obj: null }
        let pobj = drawPText(txt, x, y, content.color, content.brushRadius, myObj, content.font)
        if (size) pobj.data.textBoxSize = size
        pobj.data.fonts = { font: font, sz: sz, color: col }
        if (savePos && savePos.tsz) {
            if (pobj.bounds.topLeft.y < y) {
                pobj.position.y += y - pobj.bounds.topLeft.y
                content['points'] = { x: x, y: pobj.position.y }
            }
        }
        if (size) content["size"] = size
        if (pobj) {
            let ht = updateSticky(null, new Point(x, y), pobj)
            if (ht) return
        }
        if (savePos && savePos.tbdata) {
            if (savePos.tbdata.obj) {
                var obd = savePos.tbdata.obj.data.linkData
                gTextBox[obd.id] = pobj
                content['textBoxID'] = obd.id
            }
        }
        const userid = gUser ? gUser.id : null
        var svg = null
        if (pobj)
            svg = getCompressedSvg(pobj)
        ib.createObject(id, "name", session.id, JSON.stringify(content), "text", userid, null, session.ttl, svg).then((res) => {
            const robj = res.data.createObject
            if (robj && robj.id && objDict[robj.id]) {
                objDict[robj.id].obj = robj
            }
            // myObj.CreatedBy = robj.CreatedBy
            // myObj.updatedAt = robj.updatedAt
        })
        return pobj
    }
    function drawSvg(svg, x, y) {
        var color = savePos && savePos.fonts ? savePos.fonts.color : ctx.color
        var sz = savePos && savePos.fonts ? savePos.fonts.sz : gText
        drawPSvg(svg, x, y, color, sz)
    }
    function drawTextRemote(obj) {
        try {
            if ('type' in obj) {
                obj['type'] = JSON.parse(obj['type'])
                if (obj.type && obj.type.compressed) {
                    obj.content = pako.inflate(obj.content, { to: 'string' });
                    delete obj['type']
                }
            }
        } catch {

        }
        try {
            var c = JSON.parse(obj.content)
        } catch (e) {
            console.log("Cannot parse ob", e, c)
            return
        }
        var font = c.font ? c.font : "Roboto"
        let pobj = drawPText(c.text, c.points.x, c.points.y, c.color, c.brushRadius, obj, font)
        if (c['textBoxID']) {
            if (obj.SessionID === gSessionID && pobj)
                gTextBox[c['textBoxID']] = pobj
        }
        if (c.pointObj && pobj) {
            if (c.text.includes("Score:")) {
                var r = c.text.replace("Score: ", "")
                r = r.split("%")[0]
                r = r.split(".")[0]
                ib.setEscapeRoomPage(obj.Session, obj.Session.pageNumber, { score: r })
            }
            doPixieMagic({ x: window.innerWidth / 2, y: window.innerHeight / 2, num: 70, type: "stars" })
            pobj.data.lock = true
            pobj.data.pointObj = true
            pobj.data.cannotdelete = true
            pobj.data.unselectable = true
        }
        if (pobj && c.size) {
            pobj.data.textBoxSize = c.size
            pobj.data.fonts = { font: font, sz: c.brushRadius, color: c.color }
        }
    }
    function findGrid(x, y) {
        if (!gGrid && gTables === 0) return null
        var point = new Point(x, y)
        var tol = gGrid && gGrid.data && gGrid.data.gridSize ? gGrid.data.gridSize : 60
        if (tol === 70) tol = 60
        if (gTables && !gGrid) tol = 5
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: tol };
        var foooA = paper.project.hitTestAll(point, hitOptions)
        var foundx = 2000, foundy = 0, sz = 0, tsz = null;
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]
            if (fooo && fooo.item) {
                var ga = fooo.item
                if (ga instanceof paper.PointText) {
                    var oldSel = selectedObj
                    selectedObj = ga
                    var skip = false
                    var gg = findSelectedGQL()
                    if (!gg) skip = true
                    if (gg && gg.SessionID !== gSessionID) {
                        skip = true;
                    }
                    if (skip) {
                        selectedObj = oldSel
                        continue
                    }
                    var foundKey = findSelectedKey()
                    handleEditText(null, foundKey)
                    selectedObj = oldSel
                    return { grid: null, edit: true }
                }
                // if (ga instanceof paper.PointText) {
                //     selectedObj = ga 
                //     let gg = findSelectedGQL()
                //     console.log("FOUND TEXT", gg)

                //     if (gg && gg.SessionID === gSessionID) {
                //         //mine
                //         inValue = { text: selectedObj._content, size: selectedObj.data.textBoxSize }
                //         ib.delObject(gg.id)
                //     }
                // }
                if (ga && ga.data && ga.data.tableRowLine) {
                    var b = ga.bounds
                    var colw = b.width / ga.data.tableRowLine.columns
                    var colh = b.height
                    var fx = x - b.topLeft.x
                    fx = colw * Math.floor(fx / colw) + b.topLeft.x
                    var fy = y - b.topLeft.y
                    fy = colh * Math.floor(fy / colh) + b.topLeft.y
                    if (x > fx && x < (fx + colw) && y > fy && y < (fy + colh)) {
                        //only if in between
                        foundx = fx + 10
                        foundy = fy + 10

                        var ffp = new paper.Point(foundx + 5, foundy + 5)
                        // ffp = paper.view.viewToProject(ffp);

                        hitOptions.tolerance = 10
                        var foooB = paper.project.hitTestAll(ffp, hitOptions)
                        for (let i = 0; i < foooB.length; i++) {
                            let fooo = foooB[i]
                            if (fooo && fooo.item) {
                                let ga = fooo.item
                                if (ga instanceof paper.PointText) {
                                    var oldSel2 = selectedObj
                                    selectedObj = ga
                                    let skip = false
                                    let gg = findSelectedGQL()
                                    if (!gg) skip = true
                                    if (gg && gg.SessionID !== gSessionID) {
                                        skip = true;
                                    }
                                    if (skip) {
                                        selectedObj = oldSel2
                                        continue
                                    }
                                    var foundKey = findSelectedKey()
                                    handleEditText(null, foundKey)
                                    return { grid: null, edit: true }
                                }
                            }
                        }

                        tsz = { w: colw - 10, h: colh - 10 }
                        if (ga.parent & ga.parent.data.table) {
                            tsz.bounds = ga.parent.bounds
                        }
                    } else {

                    }
                }

                if (ga && ga.data && ga.data.gridLine) {
                    sz = ga.data.gridSize
                    if (ga.data.xPos) {
                        if (ga.data.xPos < foundx) {
                            foundx = ga.data.xPos
                        }
                    }
                    if (ga.data.yPos) {
                        if (ga.data.yPos > foundy) {
                            foundy = ga.data.yPos
                        }
                    }
                } else {
                }
            }
        }

        if (foundy > 0 && foundx === 2000 && sz !== 0) {
            foundx = Math.floor(x / sz) * sz
        }
        if (foundx < 2000 && foundy > 0) {
            let div = sz
            if (sz === 0 && tsz) {
                div = 0
            }
            let ret = { x: foundx, y: foundy - div / 2, sz: sz, tsz: tsz }
            return { grid: ret, edit: false }
        }
        return { grid: null, edit: false }
    }

    function handleEscape() {
        if (inpBox) {
            try {
                inpBox.blur()
            } catch { }
        }
        if (mathQuillContext) {
            mathQuillContext.blur()
        }
        delCompass()
        clearBound()
        clearRect()
        if (isApiMode) return
        ctx.mode = defaultTool
        ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[defaultTool]
        setdrawMode({ name: 'draw' })
        ctx.color = ctx.SavedColor
        ctx.brushRadius = ctx.drawBrushRadius
        if (ctx.slider && ctx.slider.brush && ctx.slider.brush.value) ctx.slider.brush.value = ctx.brushRadius
        seteraseMode(false)
        delete ctx['lastMode']

        if (snake.snakeObj) {
            var dt = new Date()
            delSnake(snake, dt, true)
        }
        return
    }
    function sizeKeys(e) {
        const KEY = "b"
        if (isMac) {
            if (e.ctrlKey) return "";
            if (e.metaKey && e.shiftKey && e.key.toLowerCase() === KEY) return "B";
            if (e.metaKey && e.key.toLowerCase() === KEY) return "b";
        } else {
            if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === KEY) {
                return "B";
            }
            if (e.ctrlKey && e.key.toLowerCase() === KEY) return "b";

        }
        return "";
    }
    function pasteKeys(e) {
        if (isMac) {
            if (e.ctrlKey) return false;
            if (e.metaKey && !e.shiftKey && e.key === "v") return true;
        } else {
            if (e.ctrlKey && !e.shiftKey && e.key === "v") return true;
        }
        return false;
    }
    function copyKeys(e) {
        if (isMac) {
            if (e.ctrlKey) return false;
            if (e.metaKey && !e.shiftKey && e.key === "c") return true;
        } else {
            if (e.ctrlKey && !e.shiftKey && e.key === "c") return true;
        }
        return false;
    }
    function undoKeys(e) {
        if (isMac) {
            if (e.ctrlKey) return false;
            if (e.metaKey && !e.shiftKey && e.key === "z") return true;
        } else {
            if (e.ctrlKey && !e.shiftKey && e.key === "z") return true;
        }
        return false;
    }
    function redoKeys(e) {
        if (isMac) {
            if (e.ctrlKey) return false;
            if (e.metaKey && e.shiftKey && e.key === "z") return true;
            if (e.metaKey && !e.shiftKey && e.key === "y") return true;
        } else {
            if (e.ctrlKey && e.shiftKey && e.key === "z") return true;
            if (e.ctrlKey && !e.shiftKey && e.key === "y") return true;
        }
        return false;
    }
    function nextPageKeys(e) {
        if (isMac) {
            if (e.ctrlKey) return false;
            if (e.code === 'KeyN' && e.altKey && !e.metaKey && !e.shiftKey) return true;
        } else {
            if (e.ctrlKey && e.altKey && e.key === "n") return true;

        }
        return false;
    }
    function prevPageKeys(e) {
        if (isMac) {
            if (e.ctrlKey) return false;
            if (e.code === 'KeyP' && e.altKey && !e.metaKey && !e.shiftKey) return true;
        } else {
            if (e.ctrlKey && e.altKey && e.key === "p") return true;

        }
        return false;
    }

    function brushSize(s) {
        var v
        if (s === "B") {
            v = ctx.brushRadius + 1
            if (v >= 20) v = 20

        }
        if (s === "b") {
            v = ctx.brushRadius - 1
            if (v <= 1) v = 1
        }
        handleSliderBrush(null, v, false)
        let slider = document.getElementById("slider_brush")
        slider.value = v;
    }
    var capslock
    function handleKeyPress(e) {
        if (gLocked || hasMathInput) return
        var keyCode = e.keyCode;
        if (keyCode === 27) {//escape
            return handleEscape()
        }
        if (keyCode === 18) { // alt
            capslock = true
            return
        }
        if (keyCode === ctrlKey && !inpBox) {
            ctrlDown = true
        }
        if (e.key === "Backspace") {
            if (boundRect) {
                selectedObj = boundRect.data.boundingObj;
                clearBound()
            }
            if (selectedObj) {
                var gg = findSelectedGQL()
                if (gg) delSelectedObj(gg)
            }
            if (ctx.AutoMove) {
                ctx.mode = ctx.AutoMove
                if (ctx.mode in DEFTOOLCURSOR) {
                    ctx.canvas.interface.style.cursor = DEFTOOLCURSOR[ctx.mode]
                }
                delete ctx['AutoMove']
            }
            return
        }
        // const s = sizeKeys(e)
        // if (s !== "") {
        //     e.preventDefault()
        //     brushSize(s)
        //     return
        // }
        if (copyKeys(e)) {
            if (boundRect) {
                selectedObj = boundRect.data.boundingObj;
                clearBound()
            }
            if (selectedObj) {
                var j = ib.copyObjtoClipBoard(selectedObj)
                navigator.clipboard.writeText(j)
            }
            return
        }
        if (undoKeys(e)) {
            doUndo(p => true);
            return;
        }
        if (redoKeys(e)) {
            doRedo(p => true);
            return;
        }
        if (nextPageKeys(e)) {
            document.getElementById('nextPageButton').click()
            return;
        }
        if (prevPageKeys(e)) {
            document.getElementById('previousPageButton').click()
            return;
        }
        if (e.metaKey && e.key === "Meta") return;
        if (e.ctrlKey && e.key === "Control") {
            // Only control pressed, ignore.
            // Can't make it go into text mode else
            // Ctrl/Meta + other keys stop getting detected.
            return;
        }
        if (e.shiftKey && e.key === "Shift") return;
        if (pasteKeys(e)) {
            return
        }
        if (ctx.mode !== "text") return
        clearTimeout(dtTimeout);

        var sy = ctx.canvasContainer.scrollTop
        var sx = ctx.canvasContainer.scrollLeft
        if (mobile) {
            var [x, y] = getTouchPos(e)
        } else {
            const brush = getBrushfromLazy()
            x = brush.x
            y = brush.y
        }
        savePos = { x: x, y: y }

        var grid = findGrid(x, y)
        if (grid && grid.grid) {
            x = grid.grid.x
            y = grid.grid.y
            savePos = { x: x, y: y, sz: grid.grid.sz, tsz: grid.grid.tsz }
        }
        if (grid && grid.edit) return
        var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
        // console.log('handleKeyPress', savePos)
        addInput(x - sx + drawCanvasPos.x, y - sy + drawCanvasPos.y);
        //need to improve for 1st cell in big grid
        // let tempX = x - sx
        // let posX = tempX < 30 ? 0: x - sx + drawCanvasPos.x
        // addInput(posX, y - sy + drawCanvasPos.y);
    }

    function handleKeyDown(e, obj) {
        var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
        updateEngagement("type")
        if (hasInput && !mobile) return;
        var pageX, pageY;
        if (e) {
            if (mobile) {
                var [x, y] = getTouchPosAct(e)
                pageX = x
                pageY = y
                var b = changeXYZoom(x, y)
                x = b.x
                y = b.y

                savePos = { x: b.x, y: b.y }
                pageX += drawCanvasPos.x
                pageY += drawCanvasPos.y
            } else {
                let b = getOffsetZoom(e)
                savePos = { x: b.x, y: b.y }
                pageX = e.pageX
                pageY = e.pageY
                if (!pageX || !pageY) {
                    let [x, y] = getTouchPosAct(e)
                    pageX = x + drawCanvasPos.x;
                    pageY = y + drawCanvasPos.y;
                }
            }

        }
        if (obj && obj.point) {
            var sy = ctx.canvasContainer.scrollTop
            var sx = ctx.canvasContainer.scrollLeft
            savePos = { x: obj.point.x - 2, y: obj.point.y - 10 }
            pageX = savePos.x - sx + drawCanvasPos.x
            pageY = savePos.y - sy + drawCanvasPos.y

            var mousePosition = new paper.Point(pageX, pageY);
            var viewPosition = paper.view.projectToView(mousePosition);

            pageX = viewPosition.x
            pageY = viewPosition.y
            //fix for offset due to newdesign for text edit
        }
        if (e) {
            var oldSel = selectedObj
            var l = getHitObjSticky(e)
            selectedObj = oldSel
            if (l) {
                var ff = l.group ? l.group : l.single
                var bd = ff.bounds

                pageX = bd.topLeft.x + 110
                pageY = bd.topLeft.y + 140
                inValue = { size: { h: bd.height - 40, w: bd.width - 40 } }
                savePos = { x: pageX - 100, y: pageY - 120 }
                if (l.group) {
                    for (let i = 0; i < l.group.children.length; i++) {
                        var child = l.group.children[i]
                        if (child instanceof paper.PointText) {
                            inValue.text = child._content
                            child._content = ""
                        }
                    }
                }
            }
        }
        if (!obj) {
            var gpageX = pageX, gpageY = pageY;
            if (e) {
                let mousePosition = new paper.Point(pageX, pageY);
                let viewPosition = paper.view.viewToProject(mousePosition);

                gpageX = viewPosition.x
                gpageY = viewPosition.y
            }
            var grid = findGrid(gpageX, gpageY)
            if (grid && grid.grid) {
                pageX = grid.grid.x
                pageY = grid.grid.y
                savePos = { x: pageX - drawCanvasPos.x, y: pageY - drawCanvasPos.y, sz: grid.grid.sz, tsz: grid.grid.tsz }
            }
            if (grid && grid.edit) return
        }
        if (grid && grid.grid && grid.grid.tsz) {
            //for table

            let mousePosition = new paper.Point(pageX + drawCanvasPos.x, pageY + drawCanvasPos.y);
            let viewPosition = paper.view.projectToView(mousePosition);

            addInput(viewPosition.x, viewPosition.y);
            savePos.x = pageX
            savePos.y = pageY
        } else {
            addInput(pageX, pageY);
        }

    }
    function handleMathInput(e, obj) {
        if (hasInput) return;
        if (mobile) {
            const [x, y] = getTouchPos(e)
            savePos = { x: x, y: y }
        } else {
            let b = getOffsetZoom(e)
            savePos = { x: b.x, y: b.y }
        }
        var pageX = e.pageX
        var pageY = e.pageY
        if (obj && obj.point) {
            savePos = { x: obj.point.x - 4, y: obj.point.y - 12 }
            pageX = savePos.x
            pageY = savePos.y
        }

        addMathInput(pageX, pageY, mathInput ? mathInput.inputValue : null);
    }

    function ApplyLineBreaks(oTextarea) {
        if (oTextarea.wrap) {
            oTextarea.setAttribute("wrap", "off");
        }
        else {
            oTextarea.setAttribute("wrap", "off");
            var newArea = oTextarea.cloneNode(true);
            newArea.value = oTextarea.value;
            oTextarea.parentNode.replaceChild(newArea, oTextarea);
            oTextarea = newArea;
        }

        var strRawValue = oTextarea.value;
        oTextarea.value = "";
        var nEmptyWidth = oTextarea.clientWidth;
        var nLastWrappingIndex = -1;
        for (var i = 0; i < strRawValue.length; i++) {
            var curChar = strRawValue.charAt(i);
            if (curChar === ' ' || curChar === '-' || curChar === '+')
                nLastWrappingIndex = i;
            oTextarea.value += curChar;
            if (oTextarea.scrollWidth > nEmptyWidth) {
                var buffer = "";
                if (nLastWrappingIndex >= 0) {
                    for (var j = nLastWrappingIndex + 1; j < i; j++)
                        buffer += strRawValue.charAt(j);
                    nLastWrappingIndex = -1;
                }
                buffer += curChar;
                oTextarea.value = oTextarea.value.substr(0, oTextarea.value.length - buffer.length);
                oTextarea.value += "\n" + buffer;
            }
        }
        oTextarea.setAttribute("wrap", "");
    }
    function closeInput() {
        if (hasInput) {
            if (inpBox.value.length > 0) {

                try {
                    ApplyLineBreaks(inpBox)
                } catch (e) {
                    console.log("CANNOT APPLY BREAK", e)
                }
                var size = { w: inpBox.clientWidth, h: inpBox.clientHeight }
                if (savePos && savePos.tbdata && savePos.tbdata.cb) {
                    savePos.tbdata.cb(inpBox.value)
                } else {
                    drawText(inpBox.value, savePos.x, savePos.y, size);
                }
            }
            lastText = new Date().getTime();
            lastObjDraw = false;
            hasInput = false;
            try {
                document.body.removeChild(inpBox);
            } catch {
                console.log("Cannot remove")
            }
            inpBox = null
            setSpText({ open: false, top: 0, left: 0, inp: inpBox })
        }
    }
    function handleBlur(e) {
        if (!inpBox) {
            return
        }
        if (savePos.tbdata) {
            var rr = new Date()
            var ff = rr - savePos.tbdata.tm
            if (ff < 300) {
                inpBox.focus()
                return
            }
        }
        if (!contorlButtonClick && !gSpeechText) closeInput()
        if (gSpeechText) {
            setTimeout(function () {
                if (!contorlButtonClick) closeInput()
            }, 300)
            return
        }
        contorlButtonClick = false
    }
    function endGridBox(obj, e) {
        var menu = ["Cancel", "Grid", "Big Grid", "College Line", "Red & White"]
        myRect = { obj: null, start: null }
        handleEscape()

        var point = obj.bounds.bottomRight
        setSelectMenu({
            open: true, x: point.x, y: point.y,
            cb: done, menu: menu
        })
        async function done(e, evt) {
            setSelectMenu({
                open: false,
            })
            if (e === "Cancel") return
            if (e === "Grid") drawPaperGrid(obj.bounds, 30, obj)
            if (e === "Big Grid") drawPaperGrid(obj.bounds, 70, obj)
            if (e === "College Line") drawCollegeLineInside(obj.bounds, obj)
            if (e === "Red & White") drawRedWhiteInside(obj.bounds, obj)

        }
    }

    function typeHelp() {
        if (!onceMessage && !mobile) {
            setMessage("Just click and type to draw text at mouse pointer")
            onceMessage = true
        }
    }
    const [spText, setSpText] = React.useState({ open: false, top: 0, left: 0, inp: null })
    function drawAudioButton(x, y) {
        if (!gSpeechText) return
        var tempText = ""
        var finalized = inpBox.value
        function butClick() {
            contorlButtonClick = true;
        }
        setSpText({
            open: true, top: y - 55, left: x - 20, inp: inpBox, cb: gotText,
            butClick: butClick
        })
        function gotText(f, t, translate) {

            if (inpBox) {
                if (finalized.length <= 0) {
                    finalized = inpBox.value
                }
                if (!t && !f && !translate) {
                    setSpText({ open: false, top: 0, left: 0, inp: null })
                    setTimeout(function () {
                        contorlButtonClick = false;
                        if (inpBox) closeInput()
                    }, 100)
                    return
                }
                if (f !== "") {
                    if (tempText !== "") {
                        //take out old temp from here
                        inpBox.value.replace(tempText, "")
                    }

                    inpBox.value = finalized + " " + f
                    if (translate && translate !== "") {
                        inpBox.value += " [" + translate + "] "
                    }
                    finalized = inpBox.value
                    tempText = ""
                } else {
                    inpBox.value = finalized + " " + t
                    tempText = " " + t
                }
            }
        }
    }
    function addInput(x, y) {
        typeHelp()
        if (inpBox && hasInput) {
            closeInput()
        }
        var font = mylocalStorage.getItem("font")
        font = font ? font : "Roboto"
        var input = document.createElement('textarea');
        // input.type = 'text';
        var sz = gText * 7
        //replace(/\+/g, '-')
        if (inValue && inValue.text && !savePos.tbdata) input.value = inValue.text
        if (inValue && inValue.text && savePos.tbdata) input.value = inValue.text
        if (!gEnter) {
            input.placeholder = "Ctrl+Enter for new line"
        } else {
            input.placeholder = "Please enter text"
        }
        var left = x - 4
        var top = y - 4
        if (savePos && savePos.sz && savePos.sz !== 0) {
            //grid mode
            left = left + 4
            top = 4 + top - savePos.sz / 2
            input.placeholder = ""
            if (!inValue) {
                inValue = {}
                if (savePos.sz > 40) {
                    inValue['size'] = { h: savePos.sz, w: savePos.sz }
                }
                if (savePos.sz === 40) {
                    inValue['size'] = { h: savePos.sz, w: 700 }
                }
            }
        }
        if (savePos && savePos.tsz) {
            if (!inValue) {
                inValue = {}
            }

            inValue['size'] = { h: savePos.tsz.h, w: savePos.tsz.w }
        }
        input.style.color = ctx.SavedColor
        if (inValue && inValue.font) {
            sz = inValue.brushSize * 7
            font = inValue.font
            input.style.color = inValue.color
            savePos['fonts'] = { sz: inValue.brushSize, font: font, color: inValue.color }
        }
        if (!Boolean(gTeacher)) {
            input.setAttribute('spellcheck', false)
            input.setAttribute('autocorrect', 'off')
        }
        input.style.font = 35 + 'px ' + font;
        input.style.position = 'absolute';
        input.style.left = left + 'px';
        input.style.top = top + 'px';
        input.style.maxWidth = maxWidth - x + "px";
        input.style.maxHeight = maxHeight - y + "px";

        input.style.zIndex = 100
        if (inValue && inValue.size) {
            input.style.height = inValue.size.h + "px"
            input.style.width = inValue.size.w + "px"
        }
        if (gBackGround && gBackGround.data.backGroundCol) input.style.background = gBackGround.data.backGroundCol.color
        else input.style.background = styleVariables.bgColor;
        if (savePos && savePos.tbdata) {
            input.style.background = "transparent"
            input.style.borderStyle = "none"
            input.style.outline = "none"
            input.placeholder = ""
        }
        input.wrap = "hard";
        input.onblur = handleBlur;
        input.onkeydown = handleEnter;
        input.onkeyup = handleKeyUp;
        document.body.appendChild(input);

        ///make windo grow bigger when you keep typing 
        var observe;
        if (window.attachEvent) {
            observe = function (element, event, handler) {
                element.attachEvent('on' + event, handler);
            };
        }
        else {
            observe = function (element, event, handler) {
                element.addEventListener(event, handler, false);
            };
        }
        function delayedResize() {
            window.setTimeout(resize, 0);
        }
        function resize() {
            if (input.scrollHeight > input.clientHeight) {
                input.style.height = 'auto';
                input.style.height = input.scrollHeight + 'px';
            }
        }
        observe(input, 'change', resize);
        observe(input, 'cut', delayedResize);
        observe(input, 'paste', delayedResize);
        observe(input, 'drop', delayedResize);
        observe(input, 'keydown', delayedResize);
        /// end windo keep growing 
        input.focus();
        hasInput = true;
        inpBox = input
        drawAudioButton(left, top)
        inValue = null
    }
    function addMathInput(x, y, inputvalue) {
        const textSize = savePos && savePos.fonts ? savePos.fonts.sz : gText;
        var inkColor = mylocalStorage.getItem('savedInk')
        if (!inkColor) inkColor = 'black';
        inpMath = <>
            <EditableMathField
                latex={inputvalue && inputvalue ? inputvalue : ""}
                style={{
                    position: 'absolute',
                    left: x + 'px',
                    top: y + 'px',
                    zIndex: '100',
                    fontSize: textSize * 7,
                    // Keeping background color white during editing is helpful
                    // because the cursor color is black. Does not seem to change with caretColor property.
                    backgroundColor: 'white',
                    color: inkColor,
                }}
                config={{
                    autoCommands: 'pi theta lambda'
                }}
                onChange={(mathQuill) => {
                    var mathQuillInput = mathQuill.latex();
                    mathQuillInput = mathQuillInput.replace(/(\\)*#/g, '\\#') // All '\' before '#' replaced by single '\'
                    setMathInput(prev => {
                        return { ...prev, inputValue: mathQuillInput, sendInput: false }
                    })
                }}
                onBlur={(e) => {
                    setMathInput(prev => {
                        if (prev.inputValue) {
                            return { ...prev, sendInput: !prev.sendInput, x: savePos.x, y: savePos.y }
                            //matheditor displaced issue
                        }
                        return null;
                    })
                }}
                onFocus={(e) => {
                    setMathInput(prev => {
                        return { ...prev, inputValue: inputvalue, sendInput: false, x: savePos.x, y: savePos.y }
                    })
                }}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        setMathInput(prev => {
                            if (prev.inputValue) {
                                return { ...prev, sendInput: true, x: savePos.x, y: savePos.y }
                            }
                            return null;
                        })
                        setTimeout(function () {
                            var yoff = gText * 8
                            savePos = { x: savePos.x, y: savePos.y + yoff, fonts: savePos.fonts }
                            addMathInput(x, y + yoff, null)
                        }, 200)
                    }
                    if (e.keyCode === 27) {//escape
                        setMathInput(prev => {
                            if (prev.inputValue) {
                                return { ...prev, sendInput: true, x: savePos.x, y: savePos.y }
                            }
                            return null;
                        })
                    }
                }}
                mathquillDidMount={(mathQuill) => {
                    setMathQuillContext(mathQuill);
                    setMathInput(prev => {
                        return { ...prev, x: savePos.x, y: savePos.y }
                    })
                    mathQuill.focus();
                }}
            />
        </>

        setMathInput(prev => {
            return { ...prev, element: inpMath }
        });
        hasMathInput = true;
    }
    var ctrlDown = false
    const ctrlKey = 17

    function handleKeyUp(e) {
        if (e.keyCode === ctrlKey) ctrlDown = false;
        if (e.keyCode === 18) {
            capslock = false;
            if (ctx.isPressing) handlePointerUp(e)
        }
    }

    function handleEnter(e) {
        var keyCode = e.keyCode;
        if (e.keyCode === ctrlKey) ctrlDown = true;
        if (e.keyCode === 27) return handleEscape()
        var keymove = inpBox && hasInput && (gGrid || gTables !== 0)
        if (inpBox && ctrlDown && keyCode === 13) {
            inpBox.value = inpBox.value + "\r\n"
            return
        }
        if (inpBox && hasInput && keyCode === 13 && !gEnter) {
            e.preventDefault();
            var x = savePos.x
            var y = savePos.y
            closeInput()
            // inpBox.onblur = null
            // handleBlur(e)
            if (gGrid) {
                if (savePos.sz) {
                    if (savePos.sz === 40)
                        y += 30
                    else
                        y += savePos.sz
                } else {
                    y += 70
                }
                setupNext(x, y)
            }

        }
        if (keymove && keyCode === 9) {
            e.preventDefault();
            var x = savePos.x
            var y = savePos.y
            closeInput()
            if (savePos.sz) {
                x += savePos.sz
            } else {
                x += 70
            }
            if (savePos.tsz) {
                x += savePos.tsz.w
            }

            setupNext(x, y)
        }
        //37, 38, 39, 40 - left, up, right, down
        if (keymove && keyCode === 37) {
            e.preventDefault();
            var x = savePos.x
            var y = savePos.y
            closeInput()
            if (savePos.sz) {
                x -= savePos.sz
            } else {
                x -= 70
            }
            if (savePos.tsz) {
                x -= savePos.tsz.w
            }
            setupNext(x, y)
        }
        if (keymove && keyCode === 38) {
            e.preventDefault();
            var x = savePos.x
            var y = savePos.y
            closeInput()
            if (savePos.sz) {
                if (savePos.sz === 40)
                    y -= 45
                else
                    y -= savePos.sz
            } else {
                y -= 70
            }
            if (savePos.tsz) {
                y -= savePos.tsz.h
            }
            setupNext(x, y)
        }
        if (keymove && keyCode === 39) {
            e.preventDefault();
            var x = savePos.x
            var y = savePos.y
            closeInput()
            if (savePos.sz) {
                x += savePos.sz
            } else {
                x += 70
            }
            if (savePos.tsz) {
                x += savePos.tsz.w
            }
            setupNext(x, y)
        }
        if (keymove && keyCode === 40) {
            e.preventDefault();
            var x = savePos.x
            var y = savePos.y
            closeInput()
            if (savePos.sz) {
                if (savePos.sz === 40)
                    y += 30
                else {
                    y += savePos.sz * (1.5)
                }
            } else {
                y += 70
            }
            if (savePos.tsz) {
                y += savePos.tsz.h
            }
            setupNext(x, y)
        }

        function setupNext(x, y) {
            //var half = savePos./2

            var sy = ctx.canvasContainer.scrollTop
            var sx = ctx.canvasContainer.scrollLeft
            savePos = { x: x, y: y }
            var grid = findGrid(x, y)
            if (grid && grid.grid) {
                x = grid.grid.x
                y = grid.grid.y
                savePos = { x: x, y: y, sz: grid.grid.sz, tsz: grid.grid.tsz }
            }
            if (grid && grid.edit) return
            var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
            if (grid && grid.tsz) {
                //for table
                addInput(x + drawCanvasPos.x, y + drawCanvasPos.y);
                savePos.x = x
                savePos.y = y
            } else {
                addInput(x - sx + drawCanvasPos.x, y - sy + drawCanvasPos.y);
            }
        }

    }
    function drawObj(obj, lastIdx, col) {
        try {
            if ('type' in obj) {
                obj['type'] = JSON.parse(obj['type'])
                if (obj.type && obj.type.compressed) {
                    obj.content = pako.inflate(obj.content, { to: 'string' });
                    delete obj['type']
                }
            }
            var arr = JSON.parse(obj.content)
        } catch (e) {
            console.error("cannot decode", e, obj)
            return
        }
        if (!arr) {
            return
        }
        objDict[obj.id].content = arr

        const points = arr.points
        if (points.length <= lastIdx + 1) {
            return lastIdx + 1
        }
        var color = null
        if ('color' in arr) {
            color = arr['color']
        } else {
            color = col
        }
        var ct = ctx.context.drawing

        // if (color === "#ffffff")
        //     ct = ctx.context.white

        ct.strokeStyle = color
        ct.lineStyle = ctx.lineStyle
        ct.lineWidth = ctx.brushRadius * 2
        if ('brushRadius' in arr)
            ct.lineWidth = arr.brushRadius * 2
        ct.lineStyle = arr.dashArray
        ct.lineJoin = 'round'
        ct.lineCap = 'round'
        drawPaperPath(obj, points.slice(lastIdx, points.length), color, ct.lineWidth / 2, ct.lineStyle, ct.lineStyle)
        return points.length
    }

    function handleResize(x, y) {
        var rect = selectedObj
        var pt = new paper.Point(x, y)
        if (!rect) return
        // scale by distance from down point
        var bounds = rect.data.bounds;
        var scale = pt.subtract(bounds.center).length /
            rect.data.scaleBase.length;
        if (scale > 8) scale = 8
        if (scale < 0.2) scale = 0.2
        //rect.scale(scale)
        var tlVec = bounds.topLeft.subtract(bounds.center).multiply(scale);
        var brVec = bounds.bottomRight.subtract(bounds.center).multiply(scale);
        var newBounds = new paper.Rectangle(tlVec.add(bounds.center), brVec.add(bounds.center));
        rect.bounds = newBounds;
        // createBound(rect)
    }

    function handleRotate(x, y) {
        var rect = selectedObj
        var pt = new paper.Point(x, y)
        if (!rect) return
        var center = rect.bounds.center;
        var lastPoint = rect.data.lastPoint
        var baseVec = center.subtract(lastPoint);
        var nowVec = center.subtract(pt);
        var angle = nowVec.angle - baseVec.angle;
        rect.rotate(angle);
        rect.data.lastPoint = pt
    }
    // var myPath;

    // function paperMouseDown(event) {
    //     myPath = new Path();
    //     myPath.strokeWidth = 5 * 2;
    //     myPath.strokeColor = 'green';
    // }

    // function paperMouseDrag(event) {
    //     myPath.add(event.point);
    // }

    // function paperMouseUp(event) {
    //     var myCircle = new Path.Circle({
    //         center: event.point,
    //         radius: 10
    //     });
    //     myCircle.strokeColor = 'black';
    //     myCircle.fillColor = 'white';
    // }
    const [fontAnchor, setAnchorFontAnchor] = React.useState(null);
    function FontPopper(props) {
        const fontsList = ["Roboto", "Roboto", "Roboto", "Courier", "KgTeacherHelpers", "OpenDyslexic", "Lexend", "Sassoon", "Comic Sans"]
        function handleFontChange(c) {
            const selectTool = c.target.value
            // if (selectTool === "KgTeacherHelpers") {
            //     gText = currentText * 5
            // } else {
            //     gText = currentText
            // }
            dispatch(Actions.setPersonalConfig({
                ...personalConfig,
                font: selectTool,
            }))
            setAnchorFontAnchor(null)
        }
        return (
            <Popover
                anchorReference="anchorPosition"
                onClose={() => setAnchorFontAnchor(null)}
                anchorPosition={{ top: window.innerHeight - 150, left: window.innerWidth - 100 }}
                open={Boolean(fontAnchor)} >
                <div style={{ "zIndex": "210", color: "#ffffff", backgroundColor: inkColor }}>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={personalConfig.font}
                        onChange={handleFontChange}
                        style={{ padding: "5px", color: "#ffffff", backgroundColor: inkColor }}
                    >
                        {fontsList.map((f) => (
                            <MenuItem style={{ backgroundColor: inkColor, color: "#ffffff" }} value={f}>
                                <Typography variant="h6">{f}</Typography>
                            </MenuItem>

                        ))}

                    </Select>
                    <FormHelperText style={{ color: "#ffffff", backgroundColor: inkColor }}>
                        Select Font
                    </FormHelperText>

                </div>

            </Popover>
        )
    }
    function fontChange(e) {
        setAnchorFontAnchor(e.currentTarget);
    }
    var lastx = 0, lasty = 0;
    function changeZoom(oldZoom, delta, c, p, factor) {
        var newZoom;

        factor = Math.min(factor, 2)
        if (delta < 0) {
            newZoom = oldZoom * factor;
        }
        if (delta > 0) {
            newZoom = oldZoom / factor;
        }
        let beta = oldZoom / newZoom;
        p.add(new Point(7.5, 7.5));
        let pc = p.subtract(c);
        let a = p.subtract(pc.multiply(beta)).subtract(c);
        return [newZoom, a];
    };

    function getOffsetZoom(e) {
        let x = e.offsetX, y = e.offsetY;
        if (!x && !y) {
            [x, y] = getTouchPos(e); // for stylus, cant get offset
            return { x: x, y: y }
        }
        return changeXYZoom(x, y)
    }
    function getBrushfromLazy() {
        var brush = ctx.lazy.getBrushCoordinates()
        if (translateIfNeed()) return brush
        var mousePosition = new paper.Point(brush.x, brush.y);
        var viewPosition = paper.view.viewToProject(mousePosition);
        brush.x = viewPosition.x
        brush.y = viewPosition.y
        return brush
    }

    function translateIfNeed() {
        if (paper.view.zoom === 1 && !gPanned) return true
        return !gPanned
    }
    function changeXYZoom(x, y) {
        var b = { x: x, y: y }
        if (translateIfNeed()) return b
        var mousePosition = new paper.Point(b.x, b.y);
        var viewPosition = paper.view.viewToProject(mousePosition);
        if (viewPosition) {
            b.x = viewPosition.x
            b.y = viewPosition.y
        }
        return b
    }

    function pointerMove(e) {
        if (translateIfNeed()) return handlePointerMove(e.offsetX, e.offsetY, e)
        var mousePosition = new paper.Point(e.offsetX, e.offsetY);

        var viewPosition = paper.view.viewToProject(mousePosition);
        if (viewPosition) handlePointerMove(viewPosition.x, viewPosition.y, e)
        else handlePointerMove(e.offsetX, e.offsetY, e)
        // handlePointerMove(e.offsetX, e.offsetY, e)

    }
    const [zoomSz, setZoomSz] = React.useState(100 + "%")

    function drawBoundries() {
        if (gPanned) return
        gPanned = true

        let rectanglePath = new Path.Rectangle(paper.view.bounds)
        rectanglePath.strokeColor = "#1CB1C4"
        rectanglePath.strokeWidth = 1;
        rectanglePath.dashArray = [2, 2];

    }
    function checkZoomLimit(req) {
        drawBoundries()
        if (req > 0.4 && req < 6) return { zm: req, reached: false }
        if (req < paper.view.zoom) return { zm: 0.4, reached: true }
        return { zm: 6, reached: true }
    }
    function zoomSetCommon(delta) {
        let view = paper.view
        let _ref1 = changeZoom(view.zoom, delta, view.center, view.center, 1.05);
        var newZoom = _ref1[0];
        var offset = _ref1[1];
        let zm = checkZoomLimit(newZoom);
        view.zoom = zm.zm
        if (zm.reached) return
        setZoomSz(Math.floor(view.zoom * 100) + "%")
        view.center = view.center.add(offset);
        sendPositionUpdate()
    }
    function ResetZoom() {
        paper.view.zoom = 1
        paper.view.center = new paper.Point(maxWidth / 2, maxHeight / 2)
        setZoomSz(100 + "%")
    }
    function ZoomIn() {
        if (!gZoomEnabled) return
        zoomSetCommon(-1)
    }
    function ZoomOut() {
        if (!gZoomEnabled) return
        zoomSetCommon(1)
    }
    function zoomChange() {
        gZoomEnabled = !gZoomEnabled
        setZoomEnabled(gZoomEnabled)
        if (!gZoomEnabled) mylocalStorage.setItem("zoomDisabled", gZoomEnabled)
        else mylocalStorage.removeItem("zoomDisabled")
    }

    function spointerdown(e) {
        var mousePosition = new paper.Point(e.offsetX, e.offsetY);

        ctx.pointertype = { type: e.pointerType, pressure: e.pressure, downPoint: mousePosition }
    }

    function zoomClick(e) {
        e.preventDefault()
        // let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        var delta = 1
        var mousePosition = new paper.Point(e.offsetX, e.offsetY);
        let view = paper.view
        var viewPosition = view.viewToProject(mousePosition);
        var factor = 1.05
        delta = delta * -1
        let _ref1 = changeZoom(view.zoom, delta, view.center, viewPosition, factor);
        var newZoom = _ref1[0];
        var offset = _ref1[1];

        let zm = checkZoomLimit(newZoom);
        view.zoom = zm.zm
        if (zm.reached) return
        setZoomSz(Math.floor(view.zoom * 100) + "%")
        //canvas.style.width = (origCanvasWidth * newZoom) + "px";
        //canvas.style.height = (origCanvasHeight * newZoom) + "px";            
        view.center = view.center.add(offset);
        sendPositionUpdate()

    }

    function PanModeMove(x, y, e) {
        if (!ctx.isPressing) return
        sendPositionUpdate()
        let p1 = paper.DomEvent.getOffset(e, ctx.canvasContainer)

        let point = paper.view.viewToProject(p1)
        let downPoint = paper.view.viewToProject(ctx.pointertype.downPoint)
        var pan_offset = point.subtract(downPoint);
        paper.view.center = paper.view.center.subtract(pan_offset);
        ctx.pointertype.downPoint = p1
        drawBoundries()

        // paper.view.draw()
        // var point = new paper.Point(x ,y)
        // var a = ctx.pointertype.downPoint.subtract(point);
        // a = a.add(paper.view.center);
        // paper.view.center = a;

        // let view = paper.view

        // var viewPosition = view.viewToProject(point);

        // let _ref1 = changeZoom(view.zoom, 1, view.center, viewPosition, 1);
        // var offset = _ref1[1];

        // view.center = view.center.add(offset);
    }
    function handleTouchZoom(x, y, delta, f) {
        var mousePosition = new paper.Point(x, y);

        let view = paper.view
        var viewPosition = view.viewToProject(mousePosition);
        var factor = 1.05 * (1 + Math.abs(f / 100))

        let _ref1 = changeZoom(view.zoom, delta, view.center, viewPosition, factor);
        var newZoom = _ref1[0];
        var offset = _ref1[1];

        let zm = checkZoomLimit(newZoom);
        view.zoom = zm.zm
        if (zm.reached) return

        setZoomSz(Math.floor(view.zoom * 100) + "%")
        view.center = view.center.add(offset);
        // paper.view.draw()

    }
    function handleMouseWheel(e) {
        if (e.ctrlKey && gZoomEnabled || ctx.mode === "zoomIn" || ctx.mode === "panMode") {
            // cross-browser wheel delta
            e.preventDefault()
            var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
            var mousePosition = new paper.Point(e.offsetX, e.offsetY);
            let view = paper.view
            var viewPosition = view.viewToProject(mousePosition);
            var factor = 1.05 * (1 + Math.abs(e.deltaY / 100))
            delta = delta * -1
            let _ref1 = changeZoom(view.zoom, delta, view.center, viewPosition, factor);
            var newZoom = _ref1[0];
            var offset = _ref1[1];

            let zm = checkZoomLimit(newZoom);
            view.zoom = zm.zm
            if (zm.reached) return
            setZoomSz(Math.floor(view.zoom * 100) + "%")
            //canvas.style.width = (origCanvasWidth * newZoom) + "px";
            //canvas.style.height = (origCanvasHeight * newZoom) + "px";            
            view.center = view.center.add(offset);
            sendPositionUpdate()

            // paper.view.draw()

            // var beforeLeft = parseInt((canvas.style.left.split("px"))[0]);
            // var beforeTop = parseInt((canvas.style.top.split("px"))[0]);
            // var beforeWidth = parseInt((canvas.style.width.split("px"))[0]);
            // var beforeHeight = parseInt((canvas.style.height.split("px"))[0]);

            // var canvasDiff = origCanvasWidth - (origCanvasWidth * newZoom);

            //canvas.style.left = Math.max(0, Math.round(beforeLeft+canvasDiff/2)) + "px";
            //canvas.style.top = Math.max(0, Math.round(beforeTop+canvasDiff/2)) + "px";
        }
        return false;
    }


    function getMagicData() {
        setShowTextTools(true)
        var inkArray = []
        var stroke = new Ink(magicDrawObj.xarr, magicDrawObj.yarr, magicDrawObj.timeArr); //ink object created with x and y

        inkArray.push(stroke); // put the completed stroke into array
        //console.log("stroke'un 0. x coordu: " + stroke.xcoords[0]);
        var postArray = inkArray.map(function (inkObj) {
            return [inkObj.xcoords, inkObj.ycoords, inkObj.times];
        });
        if (ctx.mode === 'magicHand') {
            postArray = magicDrawObj.trace
        }

        var url = 'https://inputtools.google.com/request?ime=handwriting&app=autodraw&dbg=1&cs=1&oe=UTF-8';
        var requestBody = {
            input_type: 0,
            requests: [{
                ink: postArray,
                language: 'autodraw',
                writing_guide: {
                    height: 2000,
                    width: 2000
                }
            }]
        };
        if (ctx.mode === 'magicHand') {
            url = 'https://inputtools.google.com/request?ime=handwriting&app=mobilesearch&dbg=1&cs=1&oe=UTF-8';

            var rr = mylocalStorage.getItem("writeLang")
            rr = rr ? rr : "English"
            let mylang = Lang[rr]
            requestBody = {
                input_type: 0,
                "options": "enable_pre_space",
                requests: [{
                    ink: postArray,
                    language: mylang,
                    writing_guide: {
                        height: 2000,
                        width: 2000
                    }
                }]
            };
        }
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json; charset=utf-8'
            }),
            body: JSON.stringify(requestBody),
        }).then(function (response) {
            return response.json();
        }).then(function (jsonResponse) {
            if (ctx.mode === "magicHand") {
                setHandWriteDialog({ open: true, cb: doneMagicHand, objs: jsonResponse[1][0][1] })
            } else {
                setMagicWriteDialog({ open: true, cb: doneMagicDraw, objs: jsonResponse[1][0][1] })
            }
            // displaySuggestions(jsonResponse[1][0][1]);
        });
    }

    function doneMagicHand(e) {
        if (e) {
            var g1 = []
            magicDrawObj.pobj.forEach((r) => {
                let o = paperObj[r.id]
                if (o && o.obj) g1.push(o.obj)
            })
            var group = new paper.Group(g1)
            let bounds = group.bounds;
            let x = bounds.topLeft.x;
            let y = bounds.center.y;
            magicDrawObj.pobj.forEach((r) => {
                ib.delObject(r.id)
            })
            var font = mylocalStorage.getItem("font")
            font = font ? font : "Roboto"
            var sz = bounds.height / 7;
            if (sz < 3) sz = 3;

            savePos = {
                x: x, y: y,
                fonts: {
                    color: mkColor(ctx.SavedColor, ctx.opacity),
                    font: font,
                    sz: sz,
                }
            };
            drawText(e.text, x, y, null)

        }
        setHandWriteDialog({ open: false, cb: doneMagicHand, objs: [] })

        createMagicDraw()
    }
    function doneMagicDraw(e) {
        if (e) {
            var g1 = []
            magicDrawObj.pobj.forEach((r) => {
                let o = paperObj[r.id]
                if (o && o.obj) g1.push(o.obj)
            })
            var group = new paper.Group(g1)
            let bounds = group.bounds
            let x = bounds.topLeft.x;
            let y = bounds.topLeft.y;
            magicDrawObj.pobj.forEach((r) => {
                ib.delObject(r.id)
            })
            paper.project.importSVG(e.url, function (item) {
                var svg = item
                var scalex = bounds.width / svg.bounds.width
                var scaley = bounds.height / svg.bounds.height
                var scaleF = scaley
                if (scalex < scaley) {
                    scaleF = scalex
                }
                svg.scale(scaleF * 2)
                let neww = svg.bounds.width * scaleF * 2
                let newh = svg.bounds.height * scaleF * 2
                svg.position = new paper.Point(x + neww / 2, y + newh / 2);
                svg.strokeColor = ctx.SavedColor
                svg.strokeWidth = ctx.brushRadius * 2
                updateDrawPaperObj(svg, donePaper)
                function donePaper(nObj) {
                    svg.remove()
                }
            });
        }
        setMagicWriteDialog({ open: false, cb: doneMagicDraw, objs: [] })

        createMagicDraw()
    }
    function magicDrawTimer(onMouseUp) {
        if (ctx.isPressing) return
        if (ctx.mode === "magicHand") {
            var w = [];

            if (magicDrawObj.xarr.length >= 0) {
                w.push(magicDrawObj.xarr)
                w.push(magicDrawObj.yarr)
                w.push([])
                magicDrawObj.trace.push(w)
                magicDrawObj.xarr = []
                magicDrawObj.yarr = []
            }
        }
        if (ctx.mode !== "magicDraw" && ctx.mode !== "magicHand") {
            clearInterval(magicDrawObj.timer)
            return
        }
        if ('lastLen' in magicDrawObj && magicDrawObj.lastLen !== magicDrawObj.timeArr.length) {
            magicDrawObj.lastLen = magicDrawObj.timeArr.length
            if (magicDrawObj.lastLen > 0) getMagicData()
        } else {
        }
    }

    function createMagicDraw() {
        if (magicDrawObj.timer) {
            clearInterval(magicDrawObj.timer)
            magicDrawObj.timer = null
        }

        let timer = setInterval(function () {
            magicDrawTimer(false)
        }, 3000);

        magicDrawObj = {
            start: Date.now(),
            xarr: [],
            yarr: [],
            trace: [],
            timeArr: [],
            timer: timer,
            pobj: [],
            lastLen: 0,
            language: "en",
        }
    }

    function addMagic(x, y) {
        if (!magicDrawObj.start) return
        let st = magicDrawObj.start
        var tm = Date.now() - st
        magicDrawObj.xarr.push(x)
        magicDrawObj.yarr.push(y)
        magicDrawObj.timeArr.push(tm)
    }

    function handlePointerMove(x, y, e) {
        if (gLocked) return
        if (lastx === x && lasty === y) {
            return
        }
        lastx = x;
        lasty = y;
        if (ctx.mode !== "draw") {
            if (ctx && ctx.lazy) ctx.lazy.update({ x: x, y: y })
            if (ctx.mode === "panMode") return PanModeMove(x, y, e)
            if (ctx.mode === "zoomIn") return PanModeMove(x, y, e)
            if (ctx.mode === "move") return pathMove(x, y)
            if (ctx.mode === "ScreenShade") return ScreenShadeMove(x, y, e)
            if (ctx.mode === "rbead") return RbeadMove(x, y, e)

            if (ctx.mode === "edit") return objMove(x, y)
            if (ctx.mode === "line") {
                let rm = mylocalStorage.getItem('lineMode')
                if (rm && rm === 'Line') {
                    return lineMove(x, y, e, true)
                } else {
                } return lineMove(x, y, e);
            }
            if (ctx.mode === "arrow") return arrowMove(x, y, e);

            if (ctx.mode === "rect") {
                let rm = mylocalStorage.getItem('rectMode')
                if (rm && rm === 'Square') {
                    return rectMove(x, y, e, true)
                } else {
                    return rectMove(x, y, e);
                }
            }
            if (ctx.mode === "gridpainter") return gridPainterFill(x, y, e);

            if (ctx.mode === "circle") {
                let rm = mylocalStorage.getItem('circleMode')
                if (rm && rm === 'Circle') {
                    return circleMove(x, y, e, true);
                } else {
                    return circleMove(x, y, e);
                }
            }

            if (ctx.mode === "lasso") return moveLasso(x, y);

            if (ctx.mode === "resize") return handleResize(x, y)
            if (ctx.mode === "rotate") return handleRotate(x, y)
            if (ctx.mode === "compass") return compassmove(x, y);
            if (ctx.mode === "snake") {
                snakeMove(x, y, snake, true, ctx.color, ctx.subMode);
                return
            }
            if (ctx.mode === "text") {
                return
            }

        }
        if (clockSelect) {
            if (clockAdjust(x, y, e) !== null)
                return
        }

        if (spinnerSelect) {
            if (spinnerAdjust(x, y, e) !== null)
                return
        }

        if (fdiceSelect) {
            if (fdiceAdjust(x, y, e) !== null)
                return
        }

        if (!session) {
            return
        }
        lastTap = 0
        ctx.lazy.update({ x: x, y: y })
        if (ctx.isPressing && !ctx.isDrawing) {
            ctx.isDrawing = true
            // ctx.points.push(ctx.lazy.brush.toObject())
            ctx.drawPath = null
            if (ctx.startPoint) {
                var sp = ctx.startPoint
                ctx.points.push({ x: sp.x, y: sp.y });
            } else {
                ctx.points.push({ x: x, y: y });
                return
            }
        }
        if (!ctx.isDrawing || ctx.mode === "erase") {
            sendPeers({
                type: "mouseMove", x: x, y: y, brush: ctx.brushRadius,
                color: mkColor(ctx.SavedColor, ctx.opacity), name: myName,
            })
        }

        if (ctx.isPressing && ctx.isDrawing) {
            if (ctx.mode === "magicDraw" || ctx.mode === "magicHand") {
                addMagic(x, y)
            }
            if (!ctx.drawPath) {
                ctx.drawPath = new Path();
                ctx.drawPath.strokeColor = mkColor(ctx.color, ctx.opacity);
                ctx.drawPath.strokeWidth = ctx.brushRadius * 2;
                if (ctx.points && ctx.points.length > 0) {
                    let tempPoint = new Point(ctx.points[0].x, ctx.points[0].y)
                    ctx.drawPath.add(tempPoint);
                }

                updateDraw(true, false)
            }
            var tempPoint = new paper.Point(x, y)
            ctx.drawPath.add(tempPoint);

            // var lb = ctx.lazy.brush.toObject()
            ctx.mouseHasMoved = true
            if (ctx.mode === "erase") {
                return handleZap(e)
            }
            var len = ctx.points.length
            var lp = ctx.points[len - 1]
            ctx.points.push({ x: x, y: y })
            if (len % 5 === 0) ctx.drawPath.smooth({ type: 'continuous' });
            updateDraw(false, false)
            if (!gSyncDisabled) {
                sendPeers({
                    type: "mouseDraw", p1: { x: lp.x, y: lp.y }, p2: { x: x, y: y },
                    draw: ctx.isDrawing, brush: ctx.brushRadius, x: x, y: y,
                    color: mkColor(ctx.color, ctx.opacity), name: myName, id: myObj.id, dashArray: ctx.lineStyle
                })
            }
            // ctx.context.temp.moveTo(p2.x, p2.y)
            // ctx.context.temp.beginPath()

            // for (var i = 1, len = ctx.points.length; i < len; i++) {
            //     // we pick the point between pi+1 & pi+2 as the
            //     // end point and p1 as our control point
            //     var midPoint = midPointBtw(p1, p2)
            //     ctx.context.temp.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
            //     p1 = ctx.points[i]
            //     p2 = ctx.points[i + 1];
            // }
            // Draw last line as a straight line while
            // we wait for the next point to be able to calculate
            // the bezier control point
            // ctx.context.temp.lineTo(p1.x, p1.y)
            // ctx.context.temp.stroke()
        }

        ctx.mouseHasMoved = true
    }

    function clearCanvas() {
        ctx.valuesChanged = true
        ctx.context.drawing.clearRect(0, 0, ctx.canvas.drawing.width, ctx.canvas.drawing.height)
        // ctx.context.others.clearRect(0, 0, ctx.canvas.others.width, ctx.canvas.others.height)
        paper.project.activeLayer.removeChildren();
        clearGlobal()
        paper.view.draw();

    }

    const autoScroll = useSelector((state) => state.autoScroll);

    React.useEffect(() => {
        if (autoScroll) {
            var dt = new Date()
            var eventDat = new Date(autoScroll.updatedAt)
            var diff = dt - eventDat
            if (diff > 20000) {
                return
            }
            processScroll(autoScroll.Content.state)
        }
    }, [autoScroll])

    function drawScrollMove(mm) {
        if (mm.myID === myPeerID) return
        var p = peers[mm.myID]
        if (!p) {
            return
        }
        const m = mm.msg
        processScroll(m.scroll)
    }
    function processScroll(sc) {
        if (gScrollFollow) return // i am teacher 
        if (!sc.scroll) return

        // let s =sc.scroll
        // if (s.lastScrollUpdate < lastScrollUpdate) return 
        // lastScrollUpdate = s.lastScrollUpdate
        if (!paper || !paper.view) return
        paper.view.center = new Point(sc.scroll.x, sc.scroll.y)
        paper.view.zoom = sc.scroll.zoom
        var doc = document.querySelector('#canvas_container')
        doc.scroll(sc.scroll.left, sc.scroll.top)
    }
    function sendPositionUpdate() {

        if (!gScrollFollow) return

        function calcScroll(calc) {

            var x = paper.view.center.x, y = paper.view.center.y, zoom = paper.view.zoom
            var doc = document.querySelector('#canvas_container'),
                scrollPositionTop = doc.scrollTop,
                scrollPositionLeft = doc.scrollLeft

            if (gScrollFollow.x === x && gScrollFollow.y === y && gScrollFollow.zoom === zoom &&
                gScrollFollow.top === scrollPositionTop && gScrollFollow.left === scrollPositionLeft && !calc) 
                return null

            //scrollbar 
            gScrollFollow = {
                x: x, y: y, zoom: zoom, left: scrollPositionLeft, top: scrollPositionTop
            }
            var position = {
                scroll: gScrollFollow
            }
            if (positionTimer && !calc) {
                sendPeers({
                    type: "scrollMove", scroll: gScrollFollow,
                    color: mkColor(ctx.SavedColor, ctx.opacity), name: myName,
                })
                return null
            }
            return position
        }

        let c = calcScroll(false)
        if (!c) return
        positionTimer = setTimeout(function () {
            let position = calcScroll(true)
            ib.sendClassEvent("SendPosition", position,
                gSession.parentID, gSession.parentID, null, gSession.ttl, true, null);
            positionTimer = null
            return
        }, 500)

    }
    function loop({ once = false } = {}) {
        if (ctx.mouseHasMoved || ctx.valuesChanged) {
            const pointer = ctx.lazy.getPointerCoordinates()
            drawCursor(pointer.x, pointer.y)
            //   drawInterface(ctx, pointer, brush)
            ctx.mouseHasMoved = false
            ctx.valuesChanged = false
        }

        if (!once) {
            window.requestAnimationFrame((step) => {
                // console.log("STEP", step)
                onFrame(step)
                loop()
            })
        } else {

        }
    }
    function drawMusicGrid() {
        handleEscape()
        if (!cleanOldGrid()) return
        const br = paper.view.bounds
        const gridSize = 20

        let countY = 110
        var i = 8;
        var obj = []

        while (countY < br.height - 100) {
            for (let lines = 0; lines < 5; lines++) {
                countY += gridSize
                var leftPoint = new paper.Point(120, countY);
                var rightPoint = new paper.Point(1350, countY);
                let line = new paper.Path.Line(leftPoint, rightPoint);
                line.strokeColor = '#000000';
                line.strokeWidth = 1;
                obj.push(line)
            }
            countY += gridSize
            countY += gridSize
            countY += gridSize
            i -= 1
            if (i <= 0) break

        }
        var group = new paper.Group(obj)
        group.data.grid = true
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
            // var layer = new paper.Layer({ name: "test" });
            // paper.project.addLayer(layer); 
            //nObj.remove() 
        }
    }
    function drawRichText() {
        handleEscape()
        ctx.mode = "richText"
        ctx.canvas.interface.style.cursor = "text"
    }

    function drawNote(color) {
        const colorsp = {
            'Pink': "#ff7eb9",
            'Blue': "#7afcff",
            'Yellow': "#fff740",
            'Green': "#52D017"
        }
        handleEscape()
        const ht = 200
        const wid = 200
        const brush = getBrushfromLazy()
        const yellow = colorsp[color]

        let leftPoint = new paper.Point(brush.x, brush.y);
        let brightPoint = new paper.Point(brush.x + ht, brush.y + wid);
        let rr = new paper.Path.Rectangle(leftPoint, brightPoint)
        rr.fillColor = yellow;
        rr.data.studentClone = true
        ctx.mode = "edit"
        ctx.canvas.interface.style.cursor = "crosshair"
        clearSelect()
        rr.data.sticky = true
        selectedObj = rr

    }
    function drawSyllable() {
        handleEscape()
        if (!cleanOldGrid()) return
        const br = paper.view.bounds
        const gridSize = 180
        let countY = 70
        var i = 6;
        var obj = []
        const colors = [
            "#A97CA3",
            "#186BAD",
            "#35A744",
            "#842181",
        ]
        const yellow = "#F0DC00"
        for (let i = 0; i < 4; i++) {
            let leftPoint = new paper.Point(120, countY);
            let brightPoint = new paper.Point(br.right - 400, countY + gridSize);
            let rr = new paper.Path.Rectangle(leftPoint, brightPoint)
            rr.fillColor = colors[i];
            countY += gridSize
            obj.push(rr)
        }
        var group = new paper.Group(obj)
        group.data.grid = true
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
        }

        let leftPoint = new paper.Point(120, 90);
        let brightPoint = new paper.Point(420, 90 + (gridSize * 0.8));
        let rr = new paper.Path.Rectangle(leftPoint, brightPoint)
        rr.fillColor = yellow;
        countY += gridSize
        rr.data.studentClone = true
        rr.data.sticky = true
        updateDrawPaperObj(rr, function () {
            rr.remove()
        })
    }

    function drawElkonin() {
        handleEscape()
        if (!cleanOldGrid()) return
        const br = paper.view.bounds
        const gridSize = 140
        let countY = 100
        var obj = []
        const left = 50
        const right = window.innerWidth - 50

        const boxes = [
            3, 4, 5,
        ]
        for (let i = 0; i < boxes.length; i++) {
            var boxWidth = boxes[i] * gridSize
            var space = right - left - boxWidth
            var leftSpace = space / 2
            for (let j = 0; j < boxes[i]; j++) {
                let leftPoint = new paper.Point(leftSpace, countY);
                let brightPoint = new paper.Point(leftSpace + gridSize, countY + gridSize);
                let rr = new paper.Path.Rectangle(leftPoint, brightPoint)
                leftSpace += gridSize

                rr.strokeColor = '#968d8d';
                rr.strokeWidth = 0.5;
                obj.push(rr)
            }
            countY += gridSize
            countY += 20
        }
        var group = new paper.Group(obj)
        group.data.grid = true
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
        }
    }

    function drawCollegeLine() {
        handleEscape()
        drawCollegeLineInside(paper.view.bounds, null)
    }

    function drawCollegeLineInside(br, objIn) {
        if (!cleanOldGrid()) return
        const gridSize = 40
        let countY = 120 + br.top
        var obj = []
        if (objIn) obj.push(objIn)
        var left = 100
        var right = 200
        var bottomOff = 200
        if (objIn) {
            left = 0
            right = 0
            countY = br.top
            bottomOff = 40
        }
        while (countY < (br.top + br.height) - bottomOff) {
            countY += gridSize
            var leftPoint = new paper.Point(br.left + left, countY);
            var rightPoint = new paper.Point(br.right - right, countY);
            let line = new paper.Path.Line(leftPoint, rightPoint);

            line.data.gridLine = true
            line.data.gridSize = gridSize
            line.data.yPos = countY

            line.strokeColor = '#3174F5';
            line.strokeWidth = 1;
            obj.push(line)

        }
        leftPoint = new paper.Point(br.left + +left + 80, br.top + left);
        rightPoint = new paper.Point(br.left + left + 80, countY);
        let line = new paper.Path.Line(leftPoint, rightPoint);

        line.strokeColor = '#D96758';
        line.strokeWidth = 1;
        obj.push(line)

        var group = new paper.Group(obj)
        group.data.grid = true
        group.data.gridSize = gridSize
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
        }
    }

    function drawRedWhite() {
        handleEscape()
        drawRedWhiteInside(paper.view.bounds, null)
    }

    function drawRedWhiteInside(br, objIn) {
        if (!cleanOldGrid()) return
        const gridSize = 40
        let countY = 120 + br.top
        var obj = []
        if (objIn) obj.push(objIn)
        var left = 100
        var right = 200
        var bottomOff = 200
        if (objIn) {
            left = 0
            right = 0
            countY = br.top
            bottomOff = 40
        }
        var linecnt = 0
        while (countY < (br.top + br.height) - bottomOff) {

            countY += gridSize
            if (linecnt === 4) {
                linecnt = 0
                continue
            }
            var leftPoint = new paper.Point(br.left + left, countY);
            var rightPoint = new paper.Point(br.right - right, countY);
            let line = new paper.Path.Line(leftPoint, rightPoint);

            line.data.gridLine = true
            line.data.gridSize = gridSize
            line.data.yPos = countY
            if (linecnt === 0 || linecnt === 3) {
                line.strokeColor = '#D96758';

            } else {
                line.strokeColor = '#3174F5';
            }
            line.strokeWidth = 1;
            obj.push(line)
            linecnt++
        }
        leftPoint = new paper.Point(br.left + +left + 80, br.top + left);
        rightPoint = new paper.Point(br.left + left + 80, countY);
        let line = new paper.Path.Line(leftPoint, rightPoint);

        line.strokeColor = '#D96758';
        line.strokeWidth = 1;
        obj.push(line)

        var group = new paper.Group(obj)
        group.data.grid = true
        group.data.gridSize = gridSize
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
        }
    }

    function drawHandwrite() {
        handleEscape()
        if (!cleanOldGrid()) return
        const br = paper.view.bounds
        const gridSize = 40
        let countY = 110
        var i = 6;
        var obj = []
        var fff = 0

        while (countY < br.height - 200) {
            for (let lines = 0; lines < 5; lines++) {
                fff = fff + 1

                countY += gridSize
                var leftPoint = new paper.Point(120, countY);
                var rightPoint = new paper.Point(br.right - 200, countY);
                let line = new paper.Path.Line(leftPoint, rightPoint);

                line.data.gridLine = true
                line.data.gridSize = gridSize
                line.data.yPos = countY

                line.strokeColor = '#968d8d';
                line.strokeWidth = 1;
                if (fff === 2) {
                    var brightPoint = new paper.Point(br.right - 200, countY + gridSize);
                    var rr = new paper.Path.Rectangle(leftPoint, brightPoint)
                    rr.fillColor = '#e9e9ff';
                    obj.push(rr)
                }
                if (fff === 3) {
                    fff = 0
                }
                obj.push(line)
            }
            i -= 1
            if (i <= 0) break

        }
        var group = new paper.Group(obj)
        group.data.grid = true
        group.data.gridSize = gridSize
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
        }
    }

    function drawHandwrite2() {
        handleEscape()
        if (!cleanOldGrid()) return
        const br = paper.view.bounds
        const gridSize = 40
        let countY = 110
        var i = 6;
        var obj = []
        var fff = 0

        while (countY < br.height - 200) {
            for (let lines = 0; lines < 5; lines++) {
                fff = fff + 1

                countY += gridSize
                var leftPoint = new paper.Point(120, countY);
                var rightPoint = new paper.Point(br.right - 200, countY);
                let line = new paper.Path.Line(leftPoint, rightPoint);
                if (fff === 1) {
                    line.strokeColor = '#3174F5';
                    line.strokeWidth = 1;
                }
                if (fff === 2) {
                    line.dashArray = [10, 12];
                    line.strokeColor = '#968d8d';
                    line.strokeWidth = 1;
                }
                if (fff === 3) {
                    line.strokeColor = '#000000';
                    line.strokeWidth = 1;
                    fff = 0
                }
                obj.push(line)
            }
            i -= 1
            if (i <= 0) break

        }
        var group = new paper.Group(obj)
        group.data.grid = true
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
        }
    }

    function cleanOldGrid() {
        if (gGrid && gGrid.data.id) {
            var gg = paperObj[gGrid.data.id] ? paperObj[gGrid.data.id].gqlObj : null
            if (gg && gg.SessionID !== gSessionID) {
                return false
            }
            ib.delObject(gGrid.data.id)
            gGrid.remove()
            gGrid = null
        }
        return true
    }

    function getInsertIndex(no) {
        var children = paper.project.activeLayer.children;

        // Iterate through the items contained within the array:
        for (var i = 0; i < children.length; i++) {
            var child = children[i];
            if (child instanceof paper.Raster || child.data.stackwithtime) {
                if (child.data && child.data.createdAt && no.data.createdAt) {
                    if (no.data.createdAt > child.data.createdAt) continue
                } else {
                    continue
                }
            }
            if (child.data && child.data.background) continue
            if (child.data && child.data.grid) continue
            if (child.data && child.data.backGroundCol) continue
            return i
        }
    }

    function moveGridDown() {
        if (gGrid) {
            paper.project.activeLayer.insertChild(0, gGrid);
            if (gBackGround) gBackGround.sendToBack()
        }
    }
    function drawPaperGrid(boundingRect, cellSize, objIn) {
        if (!cleanOldGrid()) return
        var vCellsNum = boundingRect.height / cellSize;
        var hCellsNum = boundingRect.width / cellSize;
        var obj = []
        if (objIn) obj.push(objIn)
        for (var i = 0; i <= hCellsNum; i++) {
            var offsetXPos = Math.ceil(boundingRect.left / cellSize) * cellSize;
            var xPos = offsetXPos + i * cellSize;
            var topPoint = new paper.Point(xPos, boundingRect.top);
            var bottomPoint = new paper.Point(xPos, boundingRect.bottom);
            let line = new paper.Path.Line(topPoint, bottomPoint);
            if (cellSize > 30) {
                line.data.gridLine = true
                line.data.gridSize = cellSize
                line.data.xPos = xPos
            }
            line.strokeColor = '#333333';
            line.strokeWidth = 0.5;
            obj.push(line)
        }

        for (let i = 0; i <= vCellsNum; i++) {
            var offsetYPos = Math.ceil(boundingRect.top / cellSize) * cellSize;
            var yPos = offsetYPos + i * cellSize;
            var leftPoint = new paper.Point(boundingRect.left, yPos);
            var rightPoint = new paper.Point(boundingRect.right, yPos);
            let line = new paper.Path.Line(leftPoint, rightPoint);
            if (cellSize > 30) {
                line.data.gridLine = true
                line.data.gridSize = cellSize
                line.data.yPos = yPos
            }
            line.strokeWidth = 0.5;
            line.strokeColor = '#333333';
            obj.push(line)
        }
        var group = new paper.Group(obj)
        group.data.grid = true
        group.data.gridSize = cellSize
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
            // var layer = new paper.Layer({ name: "test" });
            // paper.project.addLayer(layer); 
            //nObj.remove() 
        }
    }

    function drawCoordinatePlane(args) {
        var positive = args.control.positiveOnly
        var size = args.control.size
        var notext = args.control.noTextTicks
        var maxVal = 0
        if (args.text.maxSize.length > 0)
            maxVal = parseInt(args.text.maxSize)
        if (!positive) maxVal = 2 * maxVal

        var font = mylocalStorage.getItem("font")
        font = font ? font : "Roboto"
        var cellSize = 20
        const tickSize = 10
        const fontSize = 12
        const axisSize = 3
        var minX = 100, maxX = 700, minY = 100, maxY = 700
        if (positive) {
            minX = 100; maxX = 700; minY = 100; maxY = 700;
        }
        if (size === "small") {
            maxX = 500
            maxY = 500
        }
        if (size === "large") {
            maxX = 900
            maxY = 900
        }

        if (!cleanOldGrid()) return
        var originX = minX + ((maxX - minX) / 2)
        var originY = minY + ((maxY - minY) / 2)

        var vCellsNum = (maxX - minX) / cellSize;
        var hCellsNum = (maxY - minY) / cellSize;
        if (maxVal !== 0) {
            vCellsNum = maxVal
            hCellsNum = maxVal
            cellSize = (maxX - minX) / maxVal
        }
        var color = mkColor(ctx.color, ctx.opacity)

        var halfX = (vCellsNum / 2) * -1
        var halfY = (hCellsNum / 2)
        if (positive) {
            originX = minX
            originY = maxY
            halfX = 0
            halfY = hCellsNum
        }
        var obj = []
        for (var i = 0; i <= hCellsNum; i++) {
            var xPos = minX + i * cellSize;
            var topPoint = new paper.Point(xPos, minY);
            var bottomPoint = new paper.Point(xPos, maxY);
            let line = new paper.Path.Line(topPoint, bottomPoint);
            line.strokeColor = '#333333';
            line.strokeWidth = 0.5;
            if (i % 5 === 0) {
                //thick line
                line.strokeWidth = 1;

            } else {
                line.strokeWidth = 0.5;
            }
            obj.push(line)

            // tick
            let tick = new paper.Path.Line(new paper.Point(xPos, originY - tickSize),
                new paper.Point(xPos, originY + tickSize))

            tick.strokeColor = color
            tick.strokeWidth = axisSize
            obj.push(tick)
            if (!notext) {
                var text
                if (halfX !== 0) {

                    text = new paper.PointText(new paper.Point(xPos - (tickSize / 2),
                        originY + 2.5 * tickSize));
                    text.content = halfX + "";
                    text.style = {
                        fontFamily: font,
                        fontSize: fontSize,
                        fillColor: color,
                    };
                } else {
                    if (positive)
                        text = new paper.PointText(new paper.Point(xPos - (tickSize / 2),
                            originY + 2.5 * tickSize));
                    else
                        text = new paper.PointText(new paper.Point(originX - (tickSize),
                            originY + 1.5 * tickSize))
                    text.content = "0";
                    text.style = {
                        fontFamily: font,
                        fontSize: fontSize,
                        fillColor: color,
                    };
                }
                obj.push(text)

            }
            halfX++

        }

        for (let i = 0; i <= vCellsNum; i++) {
            var yPos = minY + i * cellSize;
            var leftPoint = new paper.Point(minX, yPos);
            var rightPoint = new paper.Point(maxX, yPos);
            let line = new paper.Path.Line(leftPoint, rightPoint);

            if (i % 5 === 0) {
                //thick line
                line.strokeWidth = 1;

            } else {
                line.strokeWidth = 0.5;
            }
            line.strokeColor = '#333333';
            obj.push(line)
            // tick
            let tick = new paper.Path.Line(new paper.Point(originX - tickSize, yPos),
                new paper.Point(originX + tickSize, yPos))

            tick.strokeColor = mkColor(ctx.color, ctx.opacity)
            tick.strokeWidth = axisSize
            obj.push(tick)
            if (halfY !== 0) {
                let oY = positive ? -2.5 * tickSize : 2 * tickSize
                if (!notext) {
                    let text = new paper.PointText(new paper.Point(originX + oY,
                        yPos + 5))

                    text.content = halfY + "";
                    text.style = {
                        fontFamily: font,
                        fontSize: fontSize,
                        fillColor: color,
                    };
                    obj.push(text)
                }
            }
            halfY--
        }
        //arrows
        var origin = new paper.Point(originX, originY)

        var ar1 = drawArrow(origin, new paper.Point(originX, minY - 20))
        ar1.strokeColor = mkColor(ctx.color, ctx.opacity)
        ar1.strokeWidth = axisSize
        obj.push(ar1)
        ar1 = drawArrow(origin, new paper.Point(maxX + 20, originY))
        ar1.strokeColor = mkColor(ctx.color, ctx.opacity)
        ar1.strokeWidth = axisSize
        obj.push(ar1)

        if (!positive) {
            ar1 = drawArrow(origin, new paper.Point(originX, maxY + 20))
            ar1.strokeColor = color
            ar1.strokeWidth = axisSize
            obj.push(ar1)

            ar1 = drawArrow(origin, new paper.Point(minX - 20, originY))
            ar1.strokeColor = mkColor(ctx.color, ctx.opacity)
            ar1.strokeWidth = axisSize
            obj.push(ar1)
        }
        var group = new paper.Group(obj)
        group.data.grid = true
        group.data.gridSize = cellSize
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            obj.map((o) => {
                o.remove()
            })
            group.remove()
            gGrid = nObj
            // var layer = new paper.Layer({ name: "test" });
            // paper.project.addLayer(layer); 
            //nObj.remove() 
        }
    }
    function drawProgramGrid(ctx) {
        var img = "/pattern/gridProgram.png"

        var raster = new paper.Raster({ source: img, crossOrigin: 'anonymous' });
        raster.data.createdAt = new Date().getTime() / 1000
        paper.project.activeLayer.insertChild(0, raster);
        raster.data.grid = true
        raster.onLoad = function () {
            var xx = raster._size.width / 2
            var yy = raster._size.height / 2
            raster.position = new Point(xx + 60, yy + 40)
            updateDrawPaperObj(raster, donePaper)
            function donePaper(nObj) {
                raster.remove()
                gGrid = nObj
                // var layer = new paper.Layer({ name: "test" });
                // paper.project.addLayer(layer); 
                //nObj.remove() 
            }
        }
    }

    function drawGrid(ctx) {
        handleEscape()
        var size = 30
        if (ctx) size = 70

        return drawPaperGrid(paper.view.bounds, size, null);

        // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        // ctx.beginPath()
        // ctx.setLineDash([5, 1])
        // ctx.setLineDash([])
        // // ctx.strokeStyle = styleVariables.colorInterfaceGrid
        // ctx.strokeStyle = 'rgba(150,150,150,0.17)'
        // ctx.lineWidth = 0.5

        // const gridSize = 20

        // let countX = 0
        // while (countX < ctx.canvas.width) {
        //     countX += gridSize
        //     ctx.moveTo(countX, 0)
        //     ctx.lineTo(countX, ctx.canvas.height)
        // }
        // ctx.stroke()

        // let countY = 0
        // while (countY < ctx.canvas.height) {
        //     countY += gridSize
        //     ctx.moveTo(0, countY)
        //     ctx.lineTo(ctx.canvas.width, countY)
        // }
        // ctx.stroke()
    }
    function drawCursor(x, y) {
        if (ctx.mode === "erase") {
            if (!ctx.cursor) {
                // ctx.tempLayer.activate()
                var point = new Point(x - 15, y - 7);
                var size = new paper.Size(30, 15);
                var dial = new paper.Shape.Rectangle(point, size)
                dial.rotate(45);
                dial.data.cursor = true
                ctx.cursor = dial;
                // ctx.drawPath.strokeWidth = ctx.brushRadius * 2;
                // ctx.drawPath.strokeColor = mkColor(ctx.color, ctx.opacity);
                ctx.cursor.fillColor = styleVariables.colorPrimary
            }
            ctx.cursor.position.x = x
            ctx.cursor.position.y = y
            return //handleZap(e)
        }
        if (ctx.cursor) {
            ctx.cursor.remove()
            ctx.cursor = null
        }
    }
    function drawInterface(ct, pointer, brush) {
        //   ct.clearRect(0, 0, ct.canvas.width, ct.canvas.height)
        // console.log("IN DRAW INTF", ctx, ctx.tempLayer) 
        // if (!ctx || !ctx.tempLayer) return

        if (!ctx.cursor) {
            // ctx.tempLayer.activate()
            var pt = new paper.Point(brush.x, brush.y)
            var myCircle = new Path.Circle(pt, 50);
            myCircle.strokeWidth = 2
            myCircle.strokeColor = 'black';
            ctx.cursor = myCircle
        }
        if (ctx.mode === "erase") {
            ctx.cursor.fillColor = styleVariables.colorPrimary
        } else {
            ctx.cursor.fillColor = "red"//mkColor(ctx.SavedColor, ctx.opacity)
        }
        //console.log("DRAW LAYER",  ctx.cursor, pointer.x, pointer.y)

        ctx.cursor.position.x = pointer.x
        ctx.cursor.position.y = pointer.y

    }

    function getRandomInt(mm) {
        let min = 1
        let max = Math.ceil(mm)
        return Math.floor(Math.random() * max) + min
    }

    function checkImage(obj, ct, path) {
        if (!obj.content) return
        try {
            if ('type' in obj) {
                obj['type'] = JSON.parse(obj['type'])
                if (obj.type && obj.type.compressed) {
                    obj.content = pako.inflate(obj.content, { to: 'string' });
                    delete obj['type']
                }
            }
        } catch (e) {
            console.error("cannot decode", e, obj)
            return false
        }

        var rr
        try {
            rr = JSON.parse(obj.content)
        } catch {
            return false
        }
        if (!rr || rr.length <= 1) return false
        if (rr[0] === "Raster" && rr[1].source && rr[1].source.indexOf(path) !== -1) return true
        return false
    }
    async function drawAvatar(url) {
        var luid = mylocalStorage.getItem('mystoreID');

        if (gGamePlay && gGamePlay.obj) {
            var rr = url.replace(".jpg", "-avatar.jpg")
            var pg = rr.split("-pg-")[1]
            pg = pg.replace("-avatar", "")
            pg = pg.split(".jpg")[0]

            gGamePlay.location = { url: rr, luid: luid }
            if (pg === "1") gGamePlay.location.imposter = true
            savedObj.forEach((o) => updateGamePlayAvatar(o))
            savedObj = []

            updateGamePlay()
            // renderUpdateAvatars()
        }
    }
    function GPshortURL(url) {
        var sp = url.split("-pg-")[1]
        sp = sp.replace(".jpg", "")
        sp = sp.replace("-avatar", "")
        return sp
    }
    function handleGPButton(e) {
        var copy = JSON.parse(JSON.stringify(gGamePlay.obj))
        var ct = copy.content
        if (!ct.event) ct.event = []
        var who = GPshortURL(e.hit.url)
        const id = uuid()
        var now = new Date().toISOString()
        if (e.gpType === "bump") {
            if (!ct.bumped) ct.bumped = {}
            ct.bumped[who] = true
            ct.event.push({ id: id, type: e.gpType, who: who, when: now })
        }
        if (e.gpType === "report") {
            if (!ct.report) ct.reported = {}
            ct.reported[who] = true
            ct.event.push({ id: id, type: e.gpType, who: who, when: now })
        }
        copy.content = JSON.stringify(ct)
        copy.content = pako.deflate(copy.content, { to: 'string' });

        ib.updateGamePlay(copy).then(() => { })
            .catch((er) => {
                console.log("ERROR ", er)
                handleGPButton(e)
            })
    }

    function renderAvatar(av, epoch) {
        return new Promise((resolve, reject) => {
            var who = GPshortURL(av.url)
            var raster = new paper.Raster({ source: av.url, crossOrigin: 'anonymous' });
            raster.onLoad = function () {
                var pgInt = parseInt(who)
                var isMe = false
                if (gGamePlay.location.url === av.url) {
                    isMe = true
                }
                var xx = raster._size.width / 2
                var yy = raster._size.height / 2
                var starty = 700
                var x = xx + 60 + (100 * pgInt)
                var y = starty - yy - 80
                raster.position = new Point(x, y)

                var text = new paper.PointText(new Point(x - xx, starty - 90));
                text.content = av.name
                text.style = {
                    fontFamily: "Roboto",
                    fontSize: 20,
                    fillColor: "#1ca8ddff",
                    justification: 'left'
                };
                var objs = []
                objs.push(raster)
                objs.push(text)

                var rcog = new paper.Group(objs)
                var temp = { group: rcog, epoch: epoch }

                if (gGamePlay.obj && gGamePlay.obj.content.bumped) {
                    if (gGamePlay.obj.content.bumped[who]) {
                        var bound = temp.group.bounds
                        let rectanglePath = new Path.Rectangle(bound);
                        rectanglePath.strokeColor = "red";
                        rectanglePath.strokeWidth = 3
                        temp.group.data.bumped = rectanglePath
                        objs.push(rectanglePath)
                    }
                }
                if (gGamePlay.obj && gGamePlay.obj.content.reported) {
                    if (gGamePlay.obj.content.reported[who]) {
                        var bound = temp.group.bounds
                        let rectanglePath = new Path.Rectangle(bound);
                        rectanglePath.strokeColor = "purple";
                        rectanglePath.strokeWidth = 6
                        temp.group.data.reported = rectanglePath
                        objs.push(rectanglePath)
                    }
                }
                if (isMe) {
                    var rcog2 = new paper.Group(objs)
                    rcog2.data.gamePlayAvatar = true
                    rcog2.data.gamePlayAv = { url: av.url }
                    rcog2.data.lock = true
                    updateDrawPaperObj(rcog2, donewrite)
                }
                function donewrite(obj) {
                    rcog.remove()
                    rcog2.remove()
                    resolve(obj)
                }
            }
        })
    }
    function updateGamePlayAvatar(obj) {
        if (!gGamePlay) {
            savedObj.push(obj)
            return
        }
        if (gGamePlay.location && gGamePlay.location.imposter) {
            if (!obj.data.gamePlayAv) return
            var who = GPshortURL(obj.data.gamePlayAv.url)

            var button = true
            if (gGamePlay.obj && gGamePlay.obj.content.bumped) {
                if (gGamePlay.obj.content.bumped[who]) button = false
            }
            if (gGamePlay.location.url === obj.data.gamePlayAv.url) {
                //no button for self
                button = false
            }
            if (button) {
                var bounds = obj.bounds.bottomLeft
                var rr = {}
                rr.x = bounds.x
                rr.y = bounds.y + 10
                rr.height = 30
                rr.width = 80
                var r = createButtonCommon(obj, { type: "gameplay", gpType: "bump", hit: obj.data.gamePlayAv }, "Bump", rr)
                obj.data.button = r
                buttonClicks++
            }
        } else {
            //not imposter 
            if (!obj.data.gamePlayAv) return
            var who = GPshortURL(obj.data.gamePlayAv.url)
            var button = false
            if (gGamePlay.obj && gGamePlay.obj.content.bumped && gGamePlay.obj.content.bumped[who]) {
                button = true
                if (gGamePlay.obj.content.reported && gGamePlay.obj.content.reported[who]) button = false
            }
            if (gGamePlay.location.url === obj.data.gamePlayAv.url) {
                //no button for self
                button = false
            }
            if (button) {
                var bounds = obj.bounds.bottomLeft
                var rr = {}
                rr.x = bounds.x
                rr.y = bounds.y + 10
                rr.height = 30
                rr.width = 80
                var r = createButtonCommon(obj, { type: "gameplay", gpType: "report", hit: obj.data.gamePlayAv }, "Report", rr)
                obj.data.button = r
                buttonClicks++
            }
        }
    }

    async function updateGamePlay() {
        var luid = mylocalStorage.getItem('mystoreID');
        var lookupID = gSessionID
        if (!gGamePlay || !gGamePlay.obj) {
            return
        }
        var con = gGamePlay.obj.content
        if (!con.location) {
            con.location = {}
        }
        var update = false
        for (let i in con.location) {
            var ll = con.location[i]
            if (luid in ll) {
                if (i !== lookupID) {
                    // i moved from this old location 
                    if (ll[luid].avatar_icon) {
                        ib.delObject(ll[luid].avatar_icon)
                    }
                    delete ll[luid]
                    update = true
                }
            }
        }

        if (lookupID in con.location) {
            if (con.location[lookupID][luid] && con.location[lookupID][luid].url === gGamePlay.location.url) {
                if (!update) return
            }
            if (con.location[lookupID] && con.location[lookupID][luid] && con.location[lookupID][luid].avatar_icon) {
                ib.delObject(con.location[lookupID][luid].avatar_icon)
            }
            con.location[lookupID][luid] = { url: gGamePlay.location.url, name: myName }
        } else {
            con.location[lookupID] = { [luid]: { url: gGamePlay.location.url, name: myName } }
        }

        var ren = await renderAvatar(con.location[lookupID][luid])
        if (ren) con.location[lookupID][luid].avatar_icon = ren.data.id
        var copy = JSON.parse(JSON.stringify(gGamePlay.obj))
        copy.content = JSON.stringify(copy.content)
        copy.content = pako.deflate(copy.content, { to: 'string' });

        ib.updateGamePlay(copy).then().catch((e) => {
            console.log("ERROR", e)
            if (ren && ren.data.id) ib.delObject(ren.data.id)
            updateGamePlay()
        })
    }

    // var epoch = 0
    // async function renderUpdateAvatars() {
    //     if (!gGamePlay || !gGamePlay.obj || !gGamePlay.obj.content.location) {
    //         return
    //     }
    //     var con = gGamePlay.obj.content
    //     epoch++
    //     if (gSessionID in con.location) {
    //         for (let i in con.location[gSessionID]) {
    //             var av = con.location[gSessionID]
    //             var count = 0
    //             for (let a in av) {
    //                 count++
    //                 await renderAvatar(av[a], epoch)
    //             }
    //         }
    //         deleteOld(epoch)
    //     }
    // }

    // function deleteOld(epoch) {
    //     for (let i in rendered) {
    //         var r = rendered[i]
    //         if (r.epoch !== epoch) {
    //             r.group.remove()
    //             if (r.group.data.bumped) r.group.data.bumped.remove()
    //             delete rendered[i]
    //         }
    //     }
    // }
    var processedEvents = {}
    function processEvents(ev) {
        for (let i = 0; i < ev.length; i++) {
            var e = ev[i]
            if (e.id in processedEvents) continue
            if (!e.when) continue
            var dt = new Date()
            var eventDat = new Date(e.when)
            var diff = dt - eventDat
            if (diff > 20000) {
                //maybe should delete 
                processedEvents[e.id] = true
                continue
            }
            if (e.type === "report") {
                aniMess("Player " + e.who + " bumped")
                // goto page 2
                const pgSplit = gSessionID.split("-pgNum-")
                if (pgSplit[1] !== "2") {
                    const newPage = pgSplit[0] + "-pgNum-2"
                    props.history.push("/board/" + newPage)
                    startVote()
                } else {
                    //on page 2 vote?
                    startVote()
                }

            }
            processedEvents[e.id] = true
        }
    }
    function startVote() {
        if (!gGamePlay.location || !gGamePlay.location.imposter) {
            return
        }
        var forArray = []
        var names = ""
        for (let i in gGamePlay.obj.content.location) {
            var l = gGamePlay.obj.content.location[i]
            for (let lu in l) {
                var r = l[lu]
                var who = GPshortURL(r.url)
                if (gGamePlay.obj.content.bumped[who]) {
                    continue
                }
                forArray.push({ name: r.name, localID: lu })
                if (names !== "") names = names + ","
                names = names + r.name
            }
        }
        var jj = { action: "vote", text: names }
        var event = uuid()

        var cmd = {
            event: event, Classroom: sess.Classroom, ttl: sess.ttl,
            type: "Poll", State: "Sent", Content: JSON.stringify(jj)
        }
        forArray.forEach((r) => {
            var copy = JSON.parse(JSON.stringify(cmd))
            copy['For'] = r.localID + ":" + r.name
            ib.createClassroomEvent(copy)
        })
    }
    function processNewObj(dat, timer) {
        dat.content = pako.inflate(dat.content, { to: 'string' });
        dat.content = JSON.parse(dat.content)
        gGamePlay.obj = dat;
        var ct = dat.content
        if (ct.event) {
            processEvents(ct.event)
        }
        updateGamePlay()
        // renderUpdateAvatars()
    }
    function gpTimerFiredCB() {
        ib.findGamePlay(gGamePlay.id).then((res) => {
            var dat = res.data.getGamePlay
            if (dat) {
                processNewObj(dat, true)
                if (gTeacher) {
                    ib.gamePlayGarbage(dat, gSession.Classroom)
                }
            }
        })
    }
    function gotGamedata(d) {
        if (d[0]) {
            processNewObj(d[0], false)
        }
    }
    function setupGPTimer(iduse, dat) {
        if (!gGamePlay) {
            // window.clearInterval(keepaliveTimer);

            var gpTimer = window.setInterval(gpTimerFiredCB,
                10 * 1000);
            gGamePlay = { id: iduse, timer: gpTimer }
            gGamePlay.sub = ib.SubscribeGamePlay({
                "id": iduse,
                "cb": gotGamedata,
                "subCB": gameObjRe,
                "doList": true
            })
            function gameObjRe(objSubs) {
                gGamePlay.sub = objSubs
            }
        }
        if (dat) {
            dat.content = pako.inflate(dat.content, { to: 'string' });
            dat.content = JSON.parse(dat.content)
            gGamePlay.obj = dat

        }
    }
    async function processAmongUs(ct, cb, count) {
        var url = ct.url
        var fd = url.split("-pg-")
        var luid = mylocalStorage.getItem('mystoreID');
        count++
        if (!gSession) return
        var sess = gSession.Classroom ? gSession.Classroom : gSessionID
        const idUse = ct.id + "-GP-" + sess
        ib.findGamePlay(idUse).then((res) => {
            var dat = res.data.getGamePlay
            var create = false
            if (!dat) {
                create = true
                dat = {
                    content: {
                        pageArray: ct.pageArray, numPages: ct.pages, url: fd[0], assigned: {},
                    },
                    id: idUse
                }
            } else {
                dat.content = pako.inflate(dat.content, { to: 'string' });
                dat.content = JSON.parse(dat.content)
            }
            if (dat.content.assigned[luid]) {
                //found assigned
                var copy = JSON.parse(JSON.stringify(dat))
                copy.content = JSON.stringify(copy.content)
                copy.content = pako.deflate(copy.content, { to: 'string' });
                setupGPTimer(idUse, copy)
                cb(dat.content.assigned[luid])
                return
            }
            var emptys = []
            for (let i = 1; i <= ct.pages; i++) {
                if (dat.content.pageArray[i] === "") {
                    emptys.push(i)
                }
            }
            if (emptys.length === 0) {
                //all are used 
                var ff = getRandomInt(ct.pages)
            } else {
                var ff = getRandomInt(emptys.length)
                ff = emptys[ff - 1]
                dat.content.pageArray[ff] = luid
            }
            dat.content.assigned[luid] = dat.content.url + "-pg-" + ff + ".jpg"
            url = dat.content.assigned[luid]
            dat.content = JSON.stringify(dat.content)
            dat.content = pako.deflate(dat.content, { to: 'string' });
            if (create) {
                ib.createGamePlay(dat, gSession.ttl).then((g) => {
                    let rr = g.data.createGamePlay
                    setupGPTimer(idUse, rr)
                    cb(url)
                }).catch((e) => {
                    console.log("FAILED", e)
                    // someone wrote it arleady 
                    processAmongUs(ct, cb, count)
                })
            } else {
                ib.updateGamePlay(dat).then((g) => {
                    let rr = g.data.updateGamePlay
                    setupGPTimer(idUse, rr)

                    cb(url)
                }).catch((e) => {
                    // someone wrote it arleady 
                    console.log("FAILED", e, dat)

                    processAmongUs(ct, cb, count)
                })
            }
        })
    }
    function drawImage(obj) {
        // console.log("drawImage:", obj);
        if (!obj.content) return
        if (obj.id in paperObj) {
            return
        }
        // paperObj[obj.id] = { obj: null, type: "picture", gqlObj: obj }
        try {
            if ('type' in obj) {
                obj['type'] = JSON.parse(obj['type'])
                if (obj.type && obj.type.compressed) {
                    obj.content = pako.inflate(obj.content, { to: 'string' });
                }
            }
        } catch (e) {
            console.error("cannot decode", e, obj)
            return
        }
        const ct = JSON.parse(obj.content)
        var url = ct.url
        if (ct.type === "bingo") {
            ib.listObject(gSessionID, null, function (items) {
                var found = false
                var ff = getRandomInt(ct.pages)
                var fd = url.split("-pg-")
                for (let i = 0; i < items.length; i++) {
                    const item = items[i]
                    found = checkImage(item, ct, fd[0])
                    if (found) return;
                }
                if (!found) {
                    url = fd[0] + "-pg-" + ff + ".jpg"
                    renderImage()
                    return
                }
            })
        } else if (ct.type === "amongUs") {
            processAmongUs(ct, doneProcess, 0)
            function doneProcess(u) {
                url = u
                renderImage()
                drawAvatar(url)
            }
        } else {
            renderImage()
        }
        function renderImage() {
            // url.replace("https://www.whiteboard.chat", "http://localhost:3001/")
            // console.log("renderImage called");
            var raster = new paper.Raster({ crossOrigin: 'anonymous', source: url });
            paperObj[obj.id] = { obj: raster, type: "picture", gqlObj: obj }

            raster.onError = function (f) {
                console.log('The image not loaded.', url, f);
                iu.GetImage(url).then((ff) => {
                    raster = new paper.Raster({ crossOrigin: 'anonymous', source: ff.img });
                    raster.onLoad = function () {
                        done(raster)
                    }
                })
            };
            // raster.position = paper.view.center;
            raster.onLoad = function () {
                done(raster)
            }
            function done(raster) {
                paperObj[obj.id] = { obj: raster, type: "picture", gqlObj: obj }
                raster.data.createdAt = new Date(obj.createdAt).getTime() / 1000
                let idx = getInsertIndex(raster)
                paper.project.activeLayer.insertChild(idx, raster);
                moveGridDown()
                if (ct && ct.pdfText) {
                    raster.data.pdfText = ct.pdfText;
                }
                var xx = raster._size.width / 2
                var yy = raster._size.height / 2

                raster.position = new Point(xx + 100, yy + 100)
                raster.data.id = obj.id
                updateAnimate(obj)
                if (ct.type === "bingo") {
                    raster.data.bingo = true;
                    raster.data.lock = true;
                    updateDrawPaperObj(raster, donePaper)
                    function donePaper() {
                        raster.remove() // remove the original image 
                    }
                    return
                }
                attachTools(raster)
            }
        }
    }

    function loadImage(file) {
        if (gLocked) return
        var raster = new paper.Raster({ source: file.img, crossOrigin: 'anonymous' });
        raster.data.createdAt = new Date().getTime() / 1000
        let idx = getInsertIndex(raster)
        paper.project.activeLayer.insertChild(idx, raster);
        moveGridDown()
        handleEscape()
        //raster.position = paper.view.center;

        raster.onLoad = function () {
            const id = uuid()
            myObj.id = id
            var xx = raster._size.width / 2
            var yy = raster._size.height / 2
            raster.position = new Point(xx + 80, yy + 90)
            paperObj[id] = { obj: raster, type: "picture", gqlObj: null }
            raster.data.id = id
            var content = {
                type: "image", points: { x: 0, y: 0 }, url: file.url,
                ended: true, color: mkColor(ctx.SavedColor, ctx.opacity),
                brushRadius: ctx.drawBrushRadius
            }
            const userid = gUser ? gUser.id : null
            objDict[id] = { lastIndex: 0, mine: true, obj: null }
            ib.createObject(id, "name", gSessionID, JSON.stringify(content), "image", userid, null, gSession ? gSession.ttl : 0).then(res => {
                console.error("loadImage:", file, res);
                const robj = res.data.createObject
                if (robj && robj.id && objDict[robj.id]) {
                    objDict[robj.id].obj = robj
                }
                delete robj["Session"]
                paperObj[id].gqlObj = robj
            })
        };
        raster.onError = function (f) {
            console.log('The image not loaded.', f);
        };

        //     var img = new Image();
        //     img.src = fr.result;
        //     img.onload = function () {
        //         var ct = ctx.context.drawing

        //         var hratio = (ct.canvas.width - 300) / img.width
        //         var vratio = (ct.canvas.height - 20) / img.height
        //         var multi = 1
        //         if (vratio < 1 || hratio < 1) {
        //             if (vratio < hratio) multi = vratio
        //             else multi = hratio
        //         }
        //         ct.drawImage(img, 20, 20, img.width * multi, img.height * multi);
        //     }
        // }
    }

    function pushUndoStack(opType, d) {
        if (!d) return;
        if (!gUser || !gUser.id || !gUser.id === "") {
            console.log("No userid set yet, skipping add to undo stack.");
            return;
        } else {
            // console.log("gUser = ", gUser);
        }
        if (d.CreatedBy && d.CreatedBy !== gUser.id) return;

        // Don't push an item that was undone back onto stack
        var doPush = true;
        setUndoing(p => {
            if (p.length === 0) return p;
            if (p.includes(d.id)) {
                doPush = false;
                var f = p.filter((i) => { return i !== d.id })
                return f;
            }
            return p;
        })
        if (!doPush) return;

        // Flush the redo stack if a new action is performed
        var doFlushRedo = true;
        setRedoing(p => {
            if (p.length === 0) return p;
            if (p.includes(d.id)) {
                doFlushRedo = false;
                var f = p.filter((i) => { return i !== d.id })
                return f;
            }
            return [];
        })
        if (doFlushRedo) setRedoStack(p => { if (p.length === 0) return p; else return [] });

        setUndoStack(p => {
            if (!("objType" in d)) {
                for (var i = 0; i < p.length; i++) {
                    if (p[i].obj.id === d.id) {
                        d.objType = p[i].obj.objType;
                        d.CreatedBy = p[i].obj.CreatedBy;
                        d.type = p[i].obj.type;
                        d.content = p[i].obj.content;
                        // d.updatedAt = undoStack[i].updatedAt;
                        break;
                    }
                }
            }
            var op = {
                opType: opType,
                obj: { ...d },
                // objType: d.objType,
                // CreatedBy: d.CreatedBy,
                // updatedAt: d.updatedAt,
                // content: d.content,
                // animate: d.animate,
            }
            var l = p.length
            if (l > 0) {
                var top = p[l - 1]
                if (top.obj.id === op.obj.id && top.opType === op.opType && top.obj.updatedAt === op.obj.updatedAt) {
                    p.pop();
                }
            }
            p.push(op);
            if (undoIconDisabled) setUndoIconDisabled(ico => { return false });
            return p;
        })
    }

    async function undo() {
        if (!gUser || !gUser.id || !gUser.id === "") {
            // console.log("No userid set yet, skipping undo request.");
            return;
        } else {
            // console.log("gUser = ", gUser);
        }
        var s = undoStack;
        var op = null;
        // Find the latest op this user did
        while (s.length > 0 && op === null) {
            op = s.pop();
            if (op.obj.CreatedBy !== gUser.id) {
                op = null;
            }
        }
        if (op === null) return;
        var popNeeded = false;
        switch (op.opType) {
            case 'add':
                if (s.length > 0) {
                    var peek = s[s.length - 1];
                    if (peek && (peek.opType === 'mod')) {
                        popNeeded = true;
                    } else {
                        // Following is attempt to undo animation, not working yet.
                        // var prior = null;
                        // for(var i = s.length-1; i >= 0; i++) {
                        //     if (s[i].objId === op.objId) {
                        //         prior = s[i]
                        //         break;
                        //     }
                        // }
                        // if (prior && prior.opType === 'add') {
                        //     setUndoing(p => { return [ ...p, op.objId ] });
                        //     ib.reCreateObject(prior.objId, "name", session.id, prior.content, prior.objType, prior.CreatedBy, true, prior.animate).then((res) => {
                        //         const robj = res.data.createObject
                        //         delete objDict[robj.id];
                        //         delete robj["Session"]
                        //         ib.updateObject(robj);
                        //     })
                        //     delete s[i];
                        // }
                    }
                }
                setRedoStack(p => { return [...p, op] })
                await ib.delObject(op.obj.id);
                break;
            case 'del':
            case 'mod':
                setUndoing(p => { return [...p, op.obj.id] });
                delete objDict[op.obj.id];
                setMyObj()
                await ib.reCreateObject(op.obj.id, "name", session.id, op.obj.content, op.obj.objType, op.obj.CreatedBy,
                    true, op.obj.animate, op.obj.type, session.ttl).then((res) => {
                        const robj = res.data.createObject
                    })
                setRedoStack(p => { return [...p, op] })
                break;
        }
        setUndoStack(p => {
            setUndoIconDisabled(ico => { return s.length <= 0 });
            return s;
        })
        if (popNeeded) {
            undo();
        }
    }

    async function redo() {
        if (redoStack.length <= 0) return;
        if (!gUser || !gUser.id || !gUser.id === "") {
            return;
        }
        var s = redoStack;
        var op = s.pop();
        if (op === null) return;
        var popNeeded = false;
        switch (op.opType) {
            case 'add':
                setRedoing(p => { return [...p, op.obj.id] });
                setMyObj();
                await ib.reCreateObject(op.obj.id, "name", session.id, op.obj.content, op.obj.objType, op.obj.CreatedBy,
                    true, op.obj.animate, op.obj.type, session.ttl).then((res) => {
                        const robj = res.data.createObject

                    })
                break;
            case 'del':
            case 'mod':
                if (s.length > 0) {
                    var peek = s[s.length - 1];
                    if (peek && (peek.opType === 'add')) {
                        popNeeded = true;
                    }
                }
                setUndoStack(p => {
                    p.push(op);
                    setUndoIconDisabled(ico => { return p.length <= 0 });
                    return p;
                })
                await ib.delObject(op.obj.id);
                break;
        }
        setRedoStack(p => ([...s]));
        if (popNeeded) {
            redo();
        }
    }

    function showDebug() {
        console.log("Undo stack = ", undoStack);
        console.log("Redo stack = ", redoStack);
        console.log("Undoing = ", undoing);
        console.log("Redoing = ", redoing);
        // console.log("objDict = ", objDict);
        // console.log("Paper = ", paperObj);

    }

    const mainClass = mobile ? "main_mobile" : "main"
    const confClass = mobile ? "Conference_mobile" : "Conference";

    function HandleMoveResize() {
        handleEscape()
        ctx.mode = "moveResize"
        ctx.subMode = "move"
        ctx.canvas.interface.style.cursor = "move"
    }

    function adjustClonedWidget(pObj) {
        try {
            if (pObj && pObj.data && pObj.data.spinner) {
                pObj.data.spinnerId = uuid()
            }
            if (pObj && pObj.data && pObj.data.fdice) {
                pObj.data.fdiceId = uuid()
            }
        } catch { }
        return pObj
    }

    function adjustWidget(pObj, gObj) {
        try {
            if (pObj && pObj.data && pObj.data.clock) {
                sessClockWidgets++
                if (gObj.CreatedBy !== gUser.id) {
                    pObj.children.forEach(c => {
                        if (c.data.clockType === "handle") {
                            c.visible = false
                        }
                    })
                }
            }
            if (gSession && gSession.isGroup &&
                pObj && pObj.data && pObj.data.spinner && !pObj.data.spinnerIsGroup) {
                try {
                    var pClone = pObj.clone()
                    pClone.data.id = null
                    pClone.data.spinnerIsGroup = true;
                    pClone.data.spinnerId = uuid();
                    createDrawPaperObj(pClone, createdSpinnerClone, failedCreatingClone, gObj.id + '-' + gSessionID)
                    function createdSpinnerClone() {
                        pClone.remove()
                    }
                    function failedCreatingClone(error) {
                        pClone.remove()
                    }
                } catch {
                    pClone.remove()
                }
            }

            if (pObj && pObj.data && pObj.data.spinner) {
                sessSpinnerWidgets++
                if (pObj.data.studentsCanSpin === undefined) { // Backwards compatibility
                    pObj.data.studentsCanSpin = true
                }
                var luid = mylocalStorage.getItem('mystoreID');
                var hideControls = true
                if (!gSession.isGroup) {
                    if ((gUser && gObj.CreatedBy === gUser.id) ||
                        (gUser && gObj.CreatedBy === gUser.UserProfile) ||
                        (gObj.CreatedBy === luid) ||
                        (gObj.Session && gObj.Session.CreatorLocalID === luid)
                    ) {
                        hideControls = false
                    }
                }
                if (hideControls) {
                    pObj.children.forEach(c => {
                        if (c.data.spinnerType === "handle" || c.data.spinnerType === "configPanel") {
                            c.visible = false
                        }
                        if (c.data.spinnerType === "spinButton") {
                            c.visible = pObj.data.studentsCanSpin
                        }
                    })
                }
                if (gSession.isGroup && !pObj.data.spinnerIsGroup) {
                    pObj.visible = false
                }
            }

            if (gSession && gSession.isGroup &&
                pObj && pObj.data && pObj.data.fdice && !pObj.data.fdiceIsGroup) {
                try {
                    var pClone = pObj.clone()
                    pClone.data.id = null
                    pClone.data.fdiceIsGroup = true;
                    pClone.data.fdiceId = uuid();
                    createDrawPaperObj(pClone, createdfDiceClone, failedCreatingfDiceClone, gObj.id + '-' + gSessionID)
                    function createdfDiceClone() {
                        pClone.remove()
                    }
                    function failedCreatingfDiceClone(error) {
                        pClone.remove()
                    }
                } catch {
                    pClone.remove()
                }
            }

            if (pObj && pObj.data && pObj.data.fdice) {
                sessFDiceWidgets++
                if (pObj.data.studentsCanRollDice === undefined) { // Backwards compatibility
                    pObj.data.studentsCanRollDice = true
                }
                var luid = mylocalStorage.getItem('mystoreID');
                var hideControls = true
                if (!gSession.isGroup) {
                    if ((gUser && gObj.CreatedBy === gUser.id) ||
                        (gUser && gObj.CreatedBy === gUser.UserProfile) ||
                        (gObj.CreatedBy === luid) ||
                        (gObj.Session && gObj.Session.CreatorLocalID === luid)
                    ) {
                        hideControls = false
                    }
                }
                if (hideControls) {
                    pObj.children.forEach(c => {
                        if (c.data.fdiceType === "handle" || c.data.fdiceType === "configPanel") {
                            c.visible = false
                        }
                        if (c.data.fdiceType === "fdiceButton") {
                            c.visible = pObj.data.studentsCanRollDice
                        }
                    })
                }
                if (gSession.isGroup && !pObj.data.fdiceIsGroup) {
                    pObj.visible = false
                }
            }
        } catch { }
    }

    function checkWidgetDeleted(data) {
        if (data.clock) {
            sessClockWidgets--
        }
        if (data.spinner) {
            sessSpinnerWidgets--
        }
        if (data.fdice) {
            sessFDiceWidgets--
        }
    }

    function drawHand(p1, p2, color) {
        var start = p1;
        var end = p2;
        const headLength = 16;
        const tailLength = 6;
        const headAngle = 40;
        const tailAngle = 110;

        var arrowVec = start.subtract(end);

        // construct the arrow
        var arrowHead = arrowVec.normalize(headLength);
        var arrowTail = arrowHead.normalize(tailLength);

        var p3 = end; // arrow point

        var p2 = end.add(arrowHead.rotate(-headAngle));   // leading arrow edge angle
        var p4 = end.add(arrowHead.rotate(headAngle));    // ditto, other side

        var p1 = p2.add(arrowTail.rotate(tailAngle));     // trailing arrow edge angle
        var p5 = p4.add(arrowTail.rotate(-tailAngle));    // ditto

        // specify all but the last segment, closed does that
        var path = new paper.Path(start, p1, p2, p3, p4, p5);
        path.closed = true;

        path.strokeWidth = 1
        path.strokeColor = color
        path.fillColor = color

        return path
    }

    function drawClock(c, r, colored = false) {
        // Dial
        var dial = new paper.Shape.Circle(c, r)
        dial.strokeColor = new Color(0.2, 0.2, 0.2)
        dial.strokeWidth = 2;

        var slice = Math.PI / 6;
        var x = r * Math.cos(slice * 9)
        var y = r * Math.sin(slice * 9)

        var grp = [dial]
        if (colored) {
            const clockColors = [
                "#D2343E", "#DB564F", "#D77668",
                "#E68445", "#ECA249", "#F7CB60",
                "#86C4A5", "#ABD4BE", "#BDDED6",
                "#5C88BA", "#7A9EC9", "#95B5D6",
            ]
            for (var i = 1; i < 13; i++) {
                var secColor = clockColors[i - 1]
                var ii = i + 8
                var arcValues = {
                    from: { x: c.x + Math.cos(ii * slice) * r, y: c.y + Math.sin(ii * slice) * r },
                    through: { x: c.x + Math.cos(ii * slice + slice / 2) * r, y: c.y + Math.sin(ii * slice + slice / 2) * r },
                    to: { x: c.x + Math.cos((ii + 1) * slice) * r, y: c.y + Math.sin((ii + 1) * slice) * r },
                    strokeColor: secColor
                }
                var arc = new paper.Path.Arc(arcValues)
                var sect = new paper.Path()
                sect.add(c)
                sect.add(new paper.Point(c.x + Math.cos(ii * slice) * r, c.y + Math.sin(ii * slice) * r))
                sect.addSegments(arc.segments)
                arc.remove()
                sect.closed = true
                sect.strokeWidth = 1
                sect.strokeColor = "#FFFFFFAA"
                sect.fillColor = secColor
                sect.data.clock = true
                grp.push(sect)
            }
        }

        // Digits
        for (var i = 1; i < 13; i++) {
            var x = (r - 14) * Math.cos(slice * (i + 9)) - 6
            var y = (r - 14) * Math.sin(slice * (i + 9)) + 6
            if (i >= 10) {
                x = x - (3 * (i - 10))
            }
            var n = new paper.PointText(new paper.Point(c.x + x, c.y + y))
            n.fillColor = "black"
            n.content = i.toString();
            n.fontFamily = "Roboto"
            n.fontWeight = "Bold"
            n.fontSize = "20px"
            grp.push(n)
        }

        // var rect2 = new paper.Path.Rectangle(0, 0, 18, 18)
        // rect2.fillColor = '#FFFFFF11'
        // rect2.strokeWidth = 0
        // rect2.data.clock = true
        // rect2.data.selected = false
        // rect2.data.clockType = "gearIcon"
        // rect2.strokeColor = '#cccccc'
        // var gear = new paper.Group([rect2])
        // var gearIcon = rect2.importSVG('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/></svg>')
        // gearIcon.selected = false
        // gearIcon.data.clock = true
        // gearIcon.data.clockType = "configPanel"
        // gearIcon.strokeColor = '#AAAAAAAA'
        // gearIcon.fillColor = '#AAAAAA44'
        // gearIcon.scale(4)
        // gearIcon.position = new paper.Point(c.x, c.y)

        // Minute Markings
        slice = Math.PI / 30;
        for (var i = 0; i < 60; i++) {
            var x = (r - 3) * Math.cos(slice * i)
            var y = (r - 3) * Math.sin(slice * i)
            var n = paper.Shape.Circle(new paper.Point(c.x + x, c.y + y), i % 5 ? 1 : 2)
            n.fillColor = "black"
            grp.push(n)
        }

        slice = Math.PI / 6;
        var minLen = r * 0.85
        var x = minLen * Math.cos(slice * 9)
        var y = minLen * Math.sin(slice * 9)
        var minhand = drawHand(c, new paper.Point(c.x + x, c.y + y), 'blue')
        minhand.selected = false
        minhand.data.clock = true
        minhand.data.clockType = "minhand"

        var hrLen = r * 0.6
        var x = hrLen * Math.cos(slice * 9)
        var y = hrLen * Math.sin(slice * 9)
        var hrhand = drawHand(c, new paper.Point(c.x + x, c.y + y), 'red')
        hrhand.selected = false
        hrhand.data.clock = true
        hrhand.data.clockType = "hrhand"

        var rect = new paper.Path.Rectangle(0, 0, 18, 18)
        rect.fillColor = '#FFFFFF11'
        rect.strokeWidth = 0
        rect.data.clock = true
        rect.data.selected = false
        rect.data.clockType = "config"
        rect.strokeColor = '#cccccc'
        var rcog = new paper.Group([rect])
        var hamb = rcog.importSVG('<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z"/></svg>')
        rcog.selected = false
        rcog.data.clock = true
        rcog.data.clockType = "configPanel"
        rcog.strokeColor = '#dddddd'
        rcog.position = new paper.Point(c.x - r + rcog.strokeBounds.width / 2, c.y - r + rcog.strokeBounds.height / 2)

        var moveHandle = new paper.Path({ strokeColor: '#dddddd', fillColor: '#dddddd' })
        moveHandle.add(c)
        const len = r * 0.2
        moveHandle.add(new paper.Point(c.x + len, c.y), new paper.Point(c.x + len, c.y + len))
        moveHandle.closePath()
        moveHandle.position = new paper.Point(c.x + r - moveHandle.strokeBounds.width / 2, c.y - r + moveHandle.strokeBounds.height / 2)
        moveHandle.selected = false
        moveHandle.data.clock = true
        moveHandle.data.clockType = "handle"

        var centerPin = new paper.Shape.Circle(c, 4)
        centerPin.fillColor = 'black'
        centerPin.selected = false
        centerPin.data.clock = true
        centerPin.data.clockType = "centerPin"

        grp.push(hrhand)
        grp.push(minhand)
        grp.push(centerPin)
        // grp.push(gearIcon)
        grp.push(rcog)
        grp.push(moveHandle)
        var clockGroup = new paper.Group(grp)
        hrhand.parent = clockGroup
        minhand.parent = clockGroup
        rcog.parent = clockGroup
        clockGroup.data.clock = true
        clockGroup.data.clockId = uuid()
        clockGroup.data.clock_core_type = clock_type[0]
        clockGroup.data.clockType = "clock"
        return clockGroup;
    }

    function addColoredClock(e) {
        addClock(e, true)
    }

    function addClock(e, colored = false) {
        var clockWidget = drawClock(new paper.Point(500, 300), 80, colored)
        sessClockWidgets++
        updateDrawPaperObj(clockWidget, doneAddingClock)
        function doneAddingClock(clk) {
            checkWidgetDeleted(clockWidget.data)
            clockWidget.remove()
        }
        try {
            ReactGA.event({
                category: 'User',
                action: 'ClockWidgetAdded'
            });
        } catch { }

    }
    function handleClockPointerUp(clock, e) {
        if (clockSelect.data.clockType !== 'config') {
            clockSelect.data.modified = true;
            clockSave(clockSelect); return;
        }
        else if (clockSelect.data.clockType === 'config') {
            // disabled config panel of clock
            var paperClock = clock.parent.parent
            var clockType = paperClock.data.clockType
            var clock_core_type = paperClock.data.clock_core_type
            var clockflag = clock_core_type == clock_type[0] ? false : true
            setClockChanged(clockflag)
            setClockUpdatedType(clockType)
            const pos = getPoint(e, true)
            setClockConfigPanelPos(p => { return { top: pos[1], left: pos[0] } })
            setClockConfigPanelOpen(p => {
                gEditingClock = gEditingClock ? null : paperClock
                return (!p)
            })
        }
    }

    function clockHit(e) {
        if (ctx.mode === 'edit') return false;
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]
            if (fooo && fooo.item && fooo.item.data && fooo.item.data.clock) {
                var c = fooo.item.data
                try {
                    var clk = c.clockType === "config" ? fooo.item.parent.parent : fooo.item.parent
                    var gqlClk = objDict[clk.data.id].obj
                    if (gqlClk.CreatedBy !== gUser.id) {
                        continue
                    }
                } catch { continue }
                if (c.clockType === "minhand" || c.clockType === "hrhand" ||
                    c.clockType === "handle" || c.clockType === "config") {
                    if (clockSaving) {
                        return true
                    }
                    clockSelect = fooo.item
                    return true
                }
            }
        }
        return false
    }

    function closeClockConfigPanel() {
        gEditingClock = null
        setClockConfigPanelOpen(p => false)
    }

    function updateClock() {
        const clock = paper.project.getItem({
            data: function (data) {
                return (data.clockType && data.clockType === "clock" && data.clockId == gEditingClock.data.clockId)
            }
        })
        if (clock) {
            clock.data.modified = true
            clock.data.clock_core_type = clockUpdatedType
            clockSave(clock)
        }
        closeClockConfigPanel()
    }

    function clockAdjust(x, y, e) {
        if (!clockSelect) return null;
        if (clockSaving) return null;
        var pp = getPaperPoint(e)
        var clk = clockSelect.parent
        if (clk.data.lock) {
            return clockSelect
        }
        if (clockSelect.data.clockType === "minhand" || clockSelect.data.clockType === "hrhand") {
            var center = clockSelect.parent.bounds.center
            var hand = clockSelect
            var p1 = hand.firstSegment.point
            var p2 = hand.lastSegment.point
            var p = p2;
            if (p2.x === center.x && p2.y === center.y) {
                p = p1
            }
            var baseVec = center.subtract(p);
            var nowVec = center.subtract(new paper.Point(x, y));
            var angle = nowVec.angle - baseVec.angle;
            var snap = 6
            if (clockSelect.data.clockType === "hrhand") {
                var snap = 0.5
            }
            angle = Math.floor(angle / snap) * snap
            hand.rotate(angle, center);
            if (clockSelect.parent.data.clock_core_type === "gear-clock") {
                if (clockSelect.data.clockType === "minhand") {
                    var hrHand = hand.previousSibling
                    if (angle < -180) { angle += 360 }
                    else if (angle > 180) { angle -= 360 }
                    hrHand.rotate(angle / snap * 0.5, center)
                } else if (clockSelect.data.clockType === "hrhand") {
                    var minHand = hand.nextSibling
                    if (angle < -180) { angle += 360 }
                    else if (angle > 180) { angle -= 360 }
                    minHand.rotate(angle / snap * 6, center)
                }
            }
            clk.data.modified = true
            return clockSelect
        } else if (clockSelect.data.clockType === "handle") {
            var pos = clk.position
            var delta = new paper.Point(pp.x - clockSelect.position.x, pp.y - clockSelect.position.y)
            clk.translate(delta)
            clk.data.modified = true
            return clockSelect
        }
    }

    function clockSave(clockSelect) {
        if (!clockSelect) return
        if (clockSaving) return

        const clk = clockSelect.data.clockType === 'clock' ? clockSelect : clockSelect.parent
        if (clk.data.lock) {
            return
        }
        if (!clockSelect.data.modified)
            return
        clockSaving = true
        delete clk.data['modified']

        var gg = findSelectedGQL(clk)
        if (gg) {
            // Need to create a copy to make type a string again.
            var ggcopy = { ...gg }
            ggcopy.type = JSON.stringify(ggcopy.type)
            pushUndoStack("mod", ggcopy)
            delete gg['Session']
            updateDrawPaperGqlObj(gg, clk, removeObj)
        } else {
            updateDrawPaperObj(clk, removeObj)
        }
        function removeObj() {
            clk.remove()
            clockSaving = false;
        }
        return
    }

    const discColors = [
        '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#FDA1FF',
        '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#808900', '#808900'
    ]

    const discSectors = [
        'Apple', 'Banana', 'Cherry', 'Durian', 'Elderberry', 'Fig', 'Guava', 'Honeydew', 'Imbe', 'Jackfruit'
    ]

    function handleSpinnerResultsChange(e) {
        var str = e.target.value
        setSpinnerResultsValuesStr(p => str)
    }

    function handleSpinnerParticipantsColorChange(e) {
        var str = e.target.value
        setSpinnerParticipantsColorsStr(p => str)
    }

    function closeSpinnerConfigPanel() {
        gEditingSpinner = null
        setSpinnerConfigPanelOpen(p => false)
    }


    function updateSpinner() {
        var sectors = spinnerResultsValuesStr.split('\n')
        var participantsColors = (spinnerParticipantsColorsStr && spinnerParticipantsColorsStr !== "") ? spinnerParticipantsColorsStr.split('\n') : null
        if (sectors.length > 0) {
            sectors = sectors.map(i => {
                i.replace(/(^\s+|\s+$)/g, "")
                var parts = i.split(',')
                parts = parts.map(part => part.replace(/(^\s+|\s+$)/g, ""))
                // Limit user configured sector name to 12 chars
                // Don't apply limit to the color part of the configured string
                parts[0] = parts[0].substring(0, 12)
                return parts.join()
            })
        }
        if (participantsColors && participantsColors.length > 0) {
            participantsColors = participantsColors.map(i => {
                i.replace(/(^\s+|\s+$)/g, "")
                var parts = i.split(',')
                parts = parts.map(part => part.replace(/(^\s+|\s+$)/g, ""))
                return parts.join()
            })
        }
        /* This getItem is required in the event that the paper objects are refreshed due to relisting of all gql objects.
           This could happen if the browser size is such that the spinner config panel happens to go beyond browser edges,
           which causes the resize observer to kick in, and get a list of all objects again. This causes the editingSpinner
           object to become stale and it ends up leaving a stale copy of the gql object and paper object on the canvas while
           doing spinnerSave */
        const spinner = paper.project.getItem({
            data: function (data) {
                return (data.spinnerType && data.spinnerType === "spinner" && data.spinnerId === gEditingSpinner.data.spinnerId)
            }
        })
        if (spinner) {
            if (spinner.data.participantsSpinner) {
                if (participantsColors && participantsColors.length > 0) {
                    spinner.data.participantsDiscColors = participantsColors
                } else {
                    delete spinner.data['participantsDiscColors']
                }
            } else {
                if (sectors && sectors.length > 0)
                    spinner.data.discSectors = sectors
            }
            spinner.data.modified = true
            spinner.data.syncStudentSpins = spinnerSyncStudentSpins
            spinner.data.studentsCanSpin = spinnerStudentsCanSpin
            updateSpinnerDisc(spinner)
            spinnerSave(spinner)
            if (!spinner.data.participantsSpinner) {
                const spinnerStr = JSON.stringify(spinner.data)
                mylocalStorage.setItem("userSpinner", spinnerStr)
            }
        }
        closeSpinnerConfigPanel()
    }

    function drawSpinnerDisc(c, r, discColors, discSectors) {
        const discPaperColors = discColors.map(dC => { return dC.split(",")[0] })
        const discInkColors = discColors.map(dC => { return dC.split(",")[1] })
        var slice = 2 * Math.PI / discSectors.length
        var nColors = discColors.length
        var sectors = []
        for (var i = 0; i < discSectors.length; i++) {
            var configSplit = discSectors[i].split(",")
            var secColor = discPaperColors[i % discPaperColors.length] ? discPaperColors[i % discPaperColors.length] : "#000000"
            var secInk = discInkColors[i % discInkColors.length] ? discInkColors[i % discInkColors.length] : "#ffffff"
            if (configSplit.length > 1) {
                secColor = configSplit[1]
            }
            if (configSplit.length > 2) {
                secInk = configSplit[2]
            }
            var arcValues = {
                from: { x: c.x + Math.cos(i * slice) * r, y: c.y + Math.sin(i * slice) * r },
                through: { x: c.x + Math.cos(i * slice + slice / 2) * r, y: c.y + Math.sin(i * slice + slice / 2) * r },
                to: { x: c.x + Math.cos((i + 1) * slice) * r, y: c.y + Math.sin((i + 1) * slice) * r },
                strokeColor: secColor
            }
            var arc = new paper.Path.Arc(arcValues)
            var sect = new paper.Path()
            sect.add(c)
            sect.add(new paper.Point(c.x + Math.cos(i * slice) * r, c.y + Math.sin(i * slice) * r))
            sect.addSegments(arc.segments)
            arc.remove()
            sect.closed = true
            sect.strokeWidth = 1
            sect.strokeColor = secColor
            sect.fillColor = secColor
            sect.data.spinner = true
            sect.data.spinnerType = discSectors[i]
            sect.data.selected = false
            var sectorText = new paper.PointText(new Point(c.x + Math.cos(i * slice + slice / 2) * r * 0.6, c.y + Math.sin(i * slice + slice / 2) * r * 0.6))
            sectorText.justification = 'center'
            sectorText.style = {
                fontFamily: 'Roboto',
                fontSize: "2em",
                fillColor: secInk,
            };
            sectorText.content = configSplit[0]
            sectorText.bounds.y += sectorText.bounds.height / 3
            sectorText.rotate((i * slice + slice / 2) * 180 / Math.PI)
            sectorText.data.spinner = true
            sectorText.data.selected = false
            sectorText.data.spinnerType = discSectors[i] + 'Text'

            sectors.push(sect)
            sectors.push(sectorText)
        }

        return sectors
    }

    function updateSpinnerDisc(spinner) {
        var disc = spinner.getItem({ data: function (data) { return data.spinnerType === 'disc' } })
        var r = disc.bounds.width / 2
        var c = disc.bounds.center
        disc.removeChildren()
        var discColors = spinner.data.discColors
        if (spinner.data.participantsSpinner && spinner.data.participantsDiscColors) {
            discColors = spinner.data.participantsDiscColors
        }
        var sectors = drawSpinnerDisc(c, r, discColors, spinner.data.discSectors)
        disc.addChildren(sectors)
        // Reset the rotated by degrees
        spinner.data.rotated = 0
    }

    function drawSpinner(c, discColors, discSectors, syncStudentSpins = false, studentsCanSpin = true) {
        var r = 150

        var sectors = drawSpinnerDisc(c, r, discColors, discSectors)
        var path = new paper.Path(c, new paper.Point(c.x, c.x + 100))
        path.strokeWidth = 2
        path.strokeColor = 'black'
        path.data.spinner = true
        path.data.selected = false
        path.data.spinnerType = 'line'

        var disc = new paper.Group([path])
        disc.addChildren(sectors)
        disc.data.spinner = true
        disc.data.spinnerType = "disc"
        disc.data.spinSpeed = DEFAULT_SPIN_SPEED
        disc.data.selected = false
        var spinButton = new paper.Shape.Circle({
            center: new paper.Point(c.x + r - 10, c.y + r - 10),
            radius: 20,
            onMouseEnter: function () {
                // ...set canvas cursor to pointer
                paper.view.element.style.setProperty('cursor', 'pointer');
            },
            // on mouse leave...
            onMouseLeave: function () {
                // ...set canvas cursor to default
                paper.view.element.style.setProperty('cursor', null);
            }
        })
        spinButton.strokeWidth = 0
        spinButton.fillColor = 'red'
        spinButton.data.spinner = true
        spinButton.data.selected = false
        spinButton.data.spinnerType = 'spinButton'

        var spinText = new paper.PointText({
            point: new Point(c.x + r - 10, c.y + r + 4 - 10),
            onMouseEnter: function () {
                // ...set canvas cursor to pointer
                paper.view.element.style.setProperty('cursor', 'pointer');
            },
            // on mouse leave...
            onMouseLeave: function () {
                // ...set canvas cursor to default
                paper.view.element.style.setProperty('cursor', null);
            }

        })
        spinText.justification = 'center'
        spinText.fillColor = 'white'
        spinText.content = 'Spin'
        spinText.data.spinner = true
        spinText.data.selected = false
        spinText.data.spinnerType = 'spinButton'
        spinText.fontWeight = 'bold'

        var sides = 6
        var pointer = new paper.Path.RegularPolygon(new Point(c.x, c.y - r), 3, sides)
        pointer.fillColor = 'black'
        pointer.rotate(180)
        pointer.scale(1, 3)
        pointer.data.spinner = true
        pointer.data.selected = false
        pointer.data.spinnerType = 'pointer'

        var moveHandle = new paper.Path({ strokeColor: '#888', fillColor: '#888' })
        moveHandle.add(c)
        const len = r * 0.2
        moveHandle.add(new paper.Point(c.x + len, c.y), new paper.Point(c.x + len, c.y + len))
        moveHandle.closePath()
        moveHandle.position = new paper.Point(c.x + r - moveHandle.strokeBounds.width / 2, c.y - r + moveHandle.strokeBounds.height / 2)
        moveHandle.selected = false
        moveHandle.data.spinner = true
        moveHandle.data.spinnerType = "handle"

        var rect = new paper.Path.Rectangle(0, 0, 18, 18)
        rect.fillColor = '#FFFFFF11'
        rect.strokeWidth = 0
        rect.data.spinner = true
        rect.data.selected = false
        rect.data.spinnerType = "config"
        var spnrConf = new paper.Group([rect])
        var hamb = spnrConf.importSVG('<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z"/></svg>')

        spnrConf.selected = false
        spnrConf.data.spinner = true
        spnrConf.data.spinnerType = "configPanel"
        spnrConf.strokeColor = '#dddddd'
        spnrConf.scale(1.5, 1.5)
        spnrConf.position = new paper.Point(c.x - r + spnrConf.strokeBounds.width / 2, c.y - r + spnrConf.strokeBounds.height / 2)

        var spinner = new paper.Group([disc, spinButton, spinText, pointer, moveHandle, spnrConf])
        spinner.data.spinner = true
        spinner.data.selected = false
        spinner.data.spinnerType = 'spinner'
        spinner.data.rotated = 0
        spinner.data.discColors = discColors
        spinner.data.discSectors = discSectors
        spinner.data.spinnerId = uuid()
        spinner.data.syncStudentSpins = syncStudentSpins
        spinner.data.studentsCanSpin = studentsCanSpin

        return spinner
    }

    function spinnerSave(spinner) {
        if (!spinner) return
        if (spinnerSaving) return
        if (spinner.data.spinnerType !== 'spinner') {
            spinner = spinner.parent
            if (spinner.data.spinnerType !== 'spinner')
                spinner = spinner.parent
            if (spinner.data.spinnerType !== 'spinner') {
                console.error("Wasn't able to find the top level spinner paper obj");
            }
        }
        // const clk = clockSelect.parent
        if (spinner.data.lock) {
            return
        }
        if (!spinner.data.modified)
            return
        spinnerSaving = true
        delete spinner.data['modified']
        var gg = findSelectedGQL(spinner)
        if (gg) {
            var ggcopy = { ...gg }
            ggcopy.type = JSON.stringify(ggcopy.type)
            pushUndoStack("mod", ggcopy)
            delete gg['Session']
            updateDrawPaperGqlObj(gg, spinner, removeObj)
        } else {
            updateDrawPaperObj(spinner, removeObj)
        }
        function removeObj() {
            spinner.remove()
            spinnerSaving = false
        }

        return
    }

    function addParticipantsSpinner(e) {
        var dC = discColors
        var dS = Object.keys(participants).map((pk) => {
            var n = "Name not found"
            try {
                n = participants[pk].name
                if (!n || n === "") {
                    var content = participants[pk].content
                    if (content && content !== "") {
                        var email = JSON.parse(content).email
                        if (email && email !== "") {
                            n = email
                        }
                    }
                }
            } catch { }
            return n.substring(0, 12);
        })
        var syncStudentSpins = false
        var studentsCanSpin = true
        var spinnerWidget = drawSpinner(new paper.Point(300, 300), dC, dS, syncStudentSpins, studentsCanSpin)
        spinnerWidget.data.participantsSpinner = true
        sessSpinnerWidgets++
        updateDrawPaperObj(spinnerWidget, doneAddingSpinner)
        function doneAddingSpinner(spin) {
            checkWidgetDeleted(spinnerWidget.data)
            spinnerWidget.remove()
        }
        try {
            ReactGA.event({
                category: 'User',
                action: 'ParticipantsSpinnerWidgetAdded'
            });
        } catch { }
    }

    function addSpinner(e) {
        var dC = discColors
        var dS = discSectors
        var syncStudentSpins = false
        var studentsCanSpin = true
        const userSpinnerStr = mylocalStorage.getItem("userSpinner");
        if (userSpinnerStr && userSpinnerStr !== "") {
            const userSpinner = JSON.parse(userSpinnerStr)
            if (userSpinner.discColors && userSpinner.discSectors) {
                dC = userSpinner.discColors
                dS = userSpinner.discSectors
                syncStudentSpins = userSpinner.syncStudentSpins
                studentsCanSpin = userSpinner.studentsCanSpin
            }
        }
        var spinnerWidget = drawSpinner(new paper.Point(300, 300), dC, dS, syncStudentSpins, studentsCanSpin)
        sessSpinnerWidgets++
        updateDrawPaperObj(spinnerWidget, doneAddingSpinner)
        function doneAddingSpinner(spin) {
            checkWidgetDeleted(spinnerWidget.data)
            spinnerWidget.remove()
        }
        try {
            ReactGA.event({
                category: 'User',
                action: 'SpinnerWidgetAdded'
            });
        } catch { }
    }

    function spinSpinner(spinner, event) {
        var disc = spinner.getItem({ data: function (data) { return data.spinnerType === 'disc' } })
        if (!disc) {
            return
        }
        var data = event.Content
        var luid = mylocalStorage.getItem('mystoreID');
        if (data.spunBy !== luid) {
            setMessage(data.spunByName + " spun the spinner.")
            onceMessage = true
        }
        var delay = data.delay
        var targetRotated = data.targetRotated
        disc.onFrame = spinFrame;
        /* Only save our instance if we created it or it is in group board and I spun it */
        var gqlObj = findSelectedGQL(spinner)
        if (!spinner.data.spinnerIsGroup) {
            if ((gUser && gqlObj.CreatedBy === gUser.id) ||
                (gUser && gqlObj.CreatedBy === gUser.UserProfile) ||
                (gqlObj.CreatedBy === luid) ||
                (gqlObj.Session && gqlObj.Session.CreatorLocalID === luid)
            ) {
                spinner.data.modified = true
            }
        } else {
            if (gSession.isGroup && luid === event.Content.spunBy) {
                spinner.data.modified = true
            }
        }
        var startTime = Date.now();

        function spinFrame(event) {
            this.rotate(Math.floor(this.data.spinSpeed))
            this.parent.data.rotated = Math.floor((this.parent.data.rotated + this.data.spinSpeed) % 360)
            var hardTimeOut = false
            if (Date.now() - startTime > 10000) {
                hardTimeOut = true
            }
            if (Date.now() - startTime > 1200) {
                if (((this.parent.data.rotated + 60) % 360) < targetRotated)
                    this.data.spinSpeed = 1.5;
            } else {
                this.data.spinSpeed = Math.floor(DEFAULT_SPIN_SPEED / ((Date.now() - startTime) / 200))
            }
            if (!hardTimeOut && ((this.parent.data.rotated + 90) % 360) < targetRotated) return;
            if (hardTimeOut || this.data.spinSpeed < 2) {
                // Stop and save spin if targetRotation has been reached or if spinner has been spinning for too long
                if (this.parent.data.rotated === targetRotated || (Date.now() - startTime > 5000)) {
                    if (this.parent.data.rotated !== targetRotated) {
                        const balanceRotation = (targetRotated - this.parent.data.rotated + targetRotated + 360) % 360
                        // console.log("Spinner took too long, stopping it, balanceRotation = ", balanceRotation)
                        // Fix the rotation if spinner was stopped because it was too long
                        this.rotate(balanceRotation)
                        this.parent.data.rotated = targetRotated
                    }
                    this.data.spinSpeed = DEFAULT_SPIN_SPEED
                    this.onFrame = null
                    /* we call spinner save, but it will only save if modified is true */
                    spinnerSave(this.parent)
                } else {
                    this.data.spinSpeed = 1
                }
            }
        }
    }

    function handleSpinnerPointerUp(spinner, e) {
        if (spinnerSelect.data.spinnerType === 'handle') { spinnerSave(spinnerSelect); return; }
        if (spinnerSelect.data.spinnerType === 'spinButton') {
            var disc = spinner.parent.getItem({ data: function (data) { return data.spinnerType === 'disc' } })
            if (!disc) {
                return
            }
            // Add random delay before spinning slows down
            var delay = Math.floor(Math.random() * 4)
            var targetRotated = Math.floor((Math.random() * new Date().getMilliseconds()) % 360)
            var luid = mylocalStorage.getItem('mystoreID');
            var event = gSession.Classroom + "-" + gSession.parentID
            var data = {
                action: "spin",
                spinnerId: spinner.parent.data.spinnerId, delay: delay, targetRotated: targetRotated,
                spunBy: luid, spunByName: myName, isTeacher: Boolean(gTeacher), sessName: gSession.name
            }
            var cmd = {
                event: event, Classroom: gSession.Classroom, ttl: gSession.ttl,
                type: "Spinner", State: "Sent", Content: JSON.stringify(data)
            }
            cmd['For'] = gSession.id
            ib.createClassroomEvent(cmd).then((r) => {
                const pp = r.data.createClassroomEvent
            })
            return
        } else if (spinnerSelect.data.spinnerType === 'config') {
            var disc = spinner.parent.parent.getItem({ data: function (data) { return data.spinnerType === 'disc' } })
            var spnr = spinner.parent.parent
            setSpinnerParticipantsColorsStr(p => { return spnr.data.participantsDiscColors ? spnr.data.participantsDiscColors.join('\n') : "" })
            setSpinnerResultsValuesStr(p => { return spnr.data.discSectors.join('\n') })
            const pos = getPoint(e, true)
            setSpinnerConfigPanelPos(p => { return { top: pos[1], left: pos[0] } })
            setSpinnerSyncStudentSpins(p => { return spnr.data.syncStudentSpins })
            setSpinnerStudentsCanSpin(p => { return spnr.data.studentsCanSpin })
            setParticipantsSpinner(p => { return spnr.data.participantsSpinner })
            setSpinnerConfigPanelOpen(p => {
                gEditingSpinner = gEditingSpinner ? null : spnr
                return (!p)
            })
        }
    }

    function spinnerHit(e) {
        if (ctx.mode === 'edit') return false;
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]
            if (fooo && fooo.item && fooo.item.data && fooo.item.data.spinner) {
                var c = fooo.item.data
                if (c.spinnerType === "spinButton" || c.spinnerType === "handle" || c.spinnerType === "config") {
                    if (spinnerSaving) {
                        return true
                    }
                    spinnerSelect = fooo.item
                    return true
                }
            }
        }
        return false
    }

    function spinnerAdjust(x, y, e) {
        if (!spinnerSelect) return null;
        if (spinnerSaving) return null;
        var spnr = spinnerSelect.parent
        var pp = getPaperPoint(e)
        if (spnr.data.lock) {
            return spinnerSelect
        }
        if (spinnerSelect.data.spinnerType === "handle") {
            var pos = spnr.position
            var delta = new paper.Point(pp.x - spinnerSelect.position.x, pp.y - spinnerSelect.position.y)
            spnr.translate(delta)
            spnr.data.modified = true
            return spinnerSelect
        }
    }

    function getRandomFractionalValue(values) {
        var randomV = ['1/2', '1/3', '1/4', '1/6', '1/8', '1/12']
        if (values && values.length > 0) {
            randomV = values.split(',');
            randomV = randomV.map(part => part.replace(/(^\s+|\s+$)/g, ""))
        }
        var n = randomV.length
        var x = Math.floor((Math.random() * n));
        var v = randomV[x]
        v = v.replace("/", "\r\n")
        return v

    }

    function rollFDice(fdice, event) {
        if (fdice.onFrame) return;
        var data = event.Content
        var luid = mylocalStorage.getItem('mystoreID');
        if (data.rolledBy !== luid) {
            setMessage(data.rolledByName + " roll the dice.")
            onceMessage = true
        }
        var gqlObj = findSelectedGQL(fdice)
        if ((gUser && gqlObj.CreatedBy === gUser.id) ||
            (gUser && gqlObj.CreatedBy === gUser.UserProfile) ||
            (gqlObj.CreatedBy === luid) ||
            (gqlObj.Session && gqlObj.Session.CreatorLocalID === luid)
        ) {
            fdice.data.modified = true
        }
        var targetValues = data.targetValues;
        fdice.onFrame = rollFrame;
        fdice.data.rotated = 0;

        var startTime = Date.now();
        var pivot = []
        for (let i = 0; i < fdice.data.numberofdice; i++)
            pivot.push(fdice.children[0].children[i].bounds.center)
        function rollFrame(event) {
            if ((Date.now() - startTime) > 600) {
                this.children[0].children.forEach((d, n) => {
                    d.rotate(360 - (this.data.rotated % 360), pivot[n])
                })
                this.data.rotated = 0
                this.onFrame = null;
                if (targetValues.length > 0) {
                    this.children[0].children.map((c) => {
                        c.children.map((f, n, a) => {
                            if (f.data.fdiceType === 'diceTxt') {
                                var oldfraction = f.content.search("\r\n") !== -1
                                f.content = targetValues.shift()
                                var newfraction = f.content.search("\r\n") !== -1

                                if (oldfraction && !newfraction) {
                                    var offsetb = 16
                                    f.position = new paper.Point(f.position.x, f.position.y + offsetb)
                                }
                                if (!oldfraction && newfraction) {
                                    var offsetb = -16
                                    f.position = new paper.Point(f.position.x, f.position.y + offsetb)
                                }
                                if (newfraction) {
                                    f.content = f.content.substring(0, 8)
                                } else {
                                    f.content = f.content.substring(0, 4)
                                }
                                a[n + 1].visible = newfraction
                            }
                        })
                    })
                }
                fDiceSave(this);
                this.onFrame = null;
            } else {
                const angle = 30
                this.data.rotated += angle
                this.children[0].children.forEach((d, n) => {
                    d.rotate(angle, pivot[n])
                })
            }
        }
    }

    function handleFdicePointerUp(frndice, e) {
        if (fdiceSelect.data.fdiceType === 'handle') { fDiceSave(fdiceSelect); return; }
        if (fdiceSelect.data.fdiceType === 'fdiceButton') {
            var fdice = frndice.parent;
            var diceValue = [];
            //add random fractional values to the dice
            for (let i = 0; i < fdice.data.numberofdice; i++)
                diceValue.push(getRandomFractionalValue(fdice.data.values.split(";")[0]))
            // Add random delay before spinning slows down
            var delay = Math.floor(Math.random() * 0.2 * 60)
            var targetRotated = Math.floor((Math.random() * new Date().getMilliseconds()) % 360)
            var luid = mylocalStorage.getItem('mystoreID');
            var event = gSession.Classroom + "-" + gSession.parentID
            var data = {
                action: "roll",
                fdiceId: fdice.data.fdiceId, delay: delay, targetRotated: targetRotated,
                targetValues: diceValue,
                rolledBy: luid, rolledByName: myName, isTeacher: Boolean(gTeacher), sessName: gSession.name
            }
            var cmd = {
                event: event, Classroom: gSession.Classroom, ttl: gSession.ttl,
                type: "Fdice", State: "Sent", Content: JSON.stringify(data)
            }
            cmd['For'] = gSession.id
            ib.createClassroomEvent(cmd).then((r) => {
                const pp = r.data.createClassroomEvent
            })
            return
        } else if (fdiceSelect.data.fdiceType === 'config') {
            var fdice = frndice.parent.parent
            var FrcValue = fdice.data.values
            var defaultFrcValue = fdice.data.defaultValues
            setFdiceResultsValuesStr(p => { return FrcValue })
            setFdiceDefaultValuesStr(p => { return defaultFrcValue })
            const pos = getPoint(e, true)
            setFdiceConfigPanelPos(p => { return { top: pos[1], left: pos[0] } })
            setFdiceSyncStudentRolls(p => { return fdice.data.syncStudentFdice })
            setFdiceStudentsCanRoll(p => { return fdice.data.studentsCanRollDice })
            setFdiceNumberOfDice(p => { return fdice.data.numberofdice })
            setFdiceConfigPanelOpen(p => {
                gEditingFdice = gEditingFdice ? null : fdice
                return (!p)
            })
        }
    }

    function fdiceAdjust(x, y, e) {
        if (!fdiceSelect) return null;
        if (fdiceSaving) return null;
        var fd = fdiceSelect.parent
        var pp = getPaperPoint(e)
        if (fd.data.lock) {
            return fdiceSelect
        }
        if (fdiceSelect.data.fdiceType === "handle") {
            var pos = fd.position
            var delta = new paper.Point(pp.x - fdiceSelect.position.x, pp.y - fdiceSelect.position.y)
            fd.translate(delta)
            fd.data.modified = true
            return fdiceSelect
        }
    }


    function fDiceHit(e) {

        if (ctx.mode === 'edit') return false;
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]
            if (fooo && fooo.item && fooo.item.data && fooo.item.data.fdice) {
                var c = fooo.item.data
                if (c.fdiceType === "fdiceButton" || c.fdiceType === "handle" || c.fdiceType === "config") {
                    if (fdiceSaving) {
                        return true
                    }
                    fdiceSelect = fooo.item
                    return true
                }
            }
        }
        return false
    }

    function handleFdiceResultsChange(e) {
        var str = e.target.value
        setFdiceResultsValuesStr(p => str)
    }

    function closeFdiceConfigPanel() {
        gEditingFdice = null
        setFdiceConfigPanelOpen(p => false)
    }

    function updateFdice() {

        const fdice = paper.project.getItem({
            data: function (data) {
                return (data.fdiceType && data.fdiceType === "fdice" && data.fdiceId === gEditingFdice.data.fdiceId)
            }
        })
        if (fdice) {
            fdice.data.modified = true
            fdice.data.syncStudentFdice = fdiceSyncStudentRolls
            fdice.data.studentsCanRollDice = fdiceStudentsCanRolls
            fdice.data.values = fdiceResultsValuesStr
            //here 80 is the radius
            var c = new paper.Point(fdice.bounds.topLeft.x + 80, fdice.bounds.topLeft.y + 80)
            fdice.children = []
            var diceGroup = new paper.Group(drawFdiceGroup(c, fdiceNumberOfDice, fdiceResultsValuesStr))
            fdice.children.push(...diceGroup.children)
            checkWidgetDeleted(diceGroup.data)
            diceGroup.remove()
            fdice.data.numberofdice = fdiceNumberOfDice
            fdice.children[0].data.numberofdice = fdiceNumberOfDice
            fDiceSave(fdice)
        }
        closeFdiceConfigPanel()


    }

    function drawFdiceGroup(c, numberofdice, values) {
        var pointa = c.x;
        var pointb = c.y
        var diceWidth = 80
        var spacing = 20
        var diceArray = []
        var fdiceText, t
        var r = 80
        var btnX = c.x;
        var btnY = c.y;
        btnX += (diceWidth + spacing / 2) * (numberofdice - 1) + ((numberofdice > 1) ? spacing / 2 : 0)
        var defColors = ['crimson', 'forestgreen', 'cornflowerblue', 'gold']
        var fdiceColors = defColors;
        var fdiceValues = null
        if (values) {
            var vSplit = values.split(";")
            fdiceValues = vSplit[0].replace(/\s/g, '');
            var confColors = vSplit[1]
            var confTxtColors = vSplit[2]
            if (confColors) {
                confColors = confColors.replace(/\s/g, '')
                fdiceColors = confColors.split(",")
                while (fdiceColors.length < defColors.length) {
                    fdiceColors.push(defColors[fdiceColors.length])
                }

            }
            if (confTxtColors) {
                confTxtColors = confTxtColors.replace(/\s/g, '')
                confTxtColors = confTxtColors.split(",")
            }
        }

        for (var i = 0; i < numberofdice; i++) {
            pointa = c.x + (diceWidth + spacing) * i
            t = new Path.RegularPolygon(new Point(pointa, pointb), 4, diceWidth / 2);
            t.fillColor = fdiceColors[i];
            t.strokeWidth = 20;
            t.strokeCap = 'round';
            t.strokeJoin = 'round';
            t.strokeColor = fdiceColors[i];
            t.data.fdice = true
            t.data.fdiceType = 'triangle';
            t.data.rollSpeed = DEFAULT_ROLL_SPEED
            var content = getRandomFractionalValue(fdiceValues)
            var offsetb = content.search("\r\n") !== -1 ? -6 : 10
            content = content.search("\r\n") !== -1 ? content.substring(0, 8) : content.substring(0, 4)
            fdiceText = new paper.PointText({
                point: new Point(pointa, pointb + offsetb),
                onMouseEnter: function () {
                    // ...set canvas cursor to pointer
                    paper.view.element.style.setProperty('cursor', 'pointer');
                },
                // on mouse leave...
                onMouseLeave: function () {
                    // ...set canvas cursor to default
                    paper.view.element.style.setProperty('cursor', null);
                }

            })
            fdiceText.justification = 'center'
            fdiceText.fillColor = (confTxtColors && confTxtColors[i]) ? confTxtColors[i] : 'black'
            fdiceText.content = content
            fdiceText.data.fdice = true
            fdiceText.data.selected = false
            fdiceText.data.fdiceType = 'diceTxt'
            fdiceText.fontWeight = 'bold'
            fdiceText.fontSize = '24px'
            fdiceText.data.rollSpeed = DEFAULT_ROLL_SPEED

            var fdiceVinculum = new paper.Path.Line(new paper.Point(pointa - 10, pointb),
                new paper.Point(pointa + 10, pointb))
            fdiceVinculum.strokeColor = (confTxtColors && confTxtColors[i]) ? confTxtColors[i] : 'black'
            fdiceVinculum.strokeWidth = 2
            fdiceVinculum.visible = content.search("\r\n") !== -1
            diceArray.push(new paper.Group([t, fdiceText, fdiceVinculum]))


        }
        var triangleGrp = new paper.Group(diceArray)
        triangleGrp.data.rotated = 0
        triangleGrp.data.numberofdice = numberofdice
        triangleGrp.data.fdiceType = 'triangleGrp'


        var fdiceButton = new paper.Shape.Circle({
            center: new paper.Point(triangleGrp.position.x, btnY + r - 10),
            radius: 20,
            onMouseEnter: function () {
                // ...set canvas cursor to pointer
                paper.view.element.style.setProperty('cursor', 'pointer');
            },
            // on mouse leave...
            onMouseLeave: function () {
                // ...set canvas cursor to default
                paper.view.element.style.setProperty('cursor', null);
            }
        })
        fdiceButton.strokeWidth = 0
        fdiceButton.fillColor = 'red'
        fdiceButton.data.fdice = true
        fdiceButton.data.selected = false
        fdiceButton.data.fdiceType = 'fdiceButton'

        var fdiceBtnText = new paper.PointText({
            point: new Point(triangleGrp.position.x, btnY + r + 4 - 10),
            onMouseEnter: function () {
                // ...set canvas cursor to pointer
                paper.view.element.style.setProperty('cursor', 'pointer');
            },
            // on mouse leave...
            onMouseLeave: function () {
                // ...set canvas cursor to default
                paper.view.element.style.setProperty('cursor', null);
            }

        })
        fdiceBtnText.justification = 'center'
        fdiceBtnText.fillColor = 'white'
        fdiceBtnText.content = 'Roll'
        fdiceBtnText.data.fdice = true
        fdiceBtnText.data.selected = false
        fdiceBtnText.data.fdiceType = 'fdiceButton'
        fdiceBtnText.fontWeight = 'bold'

        var moveHandle = new paper.Path({ strokeColor: '#888', fillColor: '#888' })
        moveHandle.add(c)
        const len = r * 0.2
        moveHandle.add(new paper.Point(c.x + len, btnY), new paper.Point(c.x + len, btnY + len))
        moveHandle.closePath()
        moveHandle.position = new paper.Point(btnX + r - moveHandle.strokeBounds.width / 2, btnY - r + moveHandle.strokeBounds.height / 2)
        moveHandle.selected = false
        moveHandle.data.fdice = true
        moveHandle.data.fdiceType = "handle"

        var rect = new paper.Path.Rectangle(0, 0, 18, 18)
        rect.fillColor = '#FFFFFF11'
        rect.strokeWidth = 0
        rect.data.fdice = true
        rect.data.selected = false
        rect.data.fdiceType = "config"
        var fdiceConf = new paper.Group([rect])
        var hamb = fdiceConf.importSVG('<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M2 13.5h14V12H2v1.5zm0-4h14V8H2v1.5zM2 4v1.5h14V4H2z"/></svg>')

        fdiceConf.selected = false
        fdiceConf.data.fdice = true
        fdiceConf.data.fdiceType = "configPanel"
        fdiceConf.strokeColor = '#dddddd'
        fdiceConf.scale(1.5, 1.5)
        fdiceConf.position = new paper.Point(c.x - r + fdiceConf.strokeBounds.width / 2, c.y - r + fdiceConf.strokeBounds.height / 2)
        var fd = [triangleGrp, fdiceButton, fdiceBtnText, moveHandle, fdiceConf]
        return fd;
    }


    function drawFDice(c, syncStudentFdice = false, studentsCanRollDice = true) {
        var numberofdice = 1;
        var fdice = new paper.Group(drawFdiceGroup(c, numberofdice, null))
        fdice.data.fdice = true
        fdice.data.selected = false
        fdice.data.fdiceType = 'fdice'
        fdice.data.rotated = 0
        fdice.data.numberofdice = numberofdice
        fdice.data.defaultValues = "1/2,1/3,1/4,1/6,1/8,1/12"
        fdice.data.values = "1/2,1/3,1/4,1/6,1/8,1/12"
        fdice.data.fdiceId = uuid()
        fdice.data.syncStudentFdice = syncStudentFdice
        fdice.data.studentsCanRollDice = studentsCanRollDice
        return fdice
    }


    function fDiceSave(fdice) {
        if (!fdice) return
        if (fdiceSaving) return
        if (fdice.data.fdiceType !== 'fdice') {
            fdice = fdice.parent
            if (fdice.data.fdiceType !== 'fdice')
                fdice = fdice.parent
            if (fdice.data.fdiceType !== 'fdice') {
                console.error("Wasn't able to find the top level fractional dice paper obj");
            }
        }
        // const clk = clockSelect.parent
        if (fdice.data.lock) {
            return
        }
        if (!fdice.data.modified)
            return
        fdiceSaving = true
        delete fdice.data['modified']
        var gg = findSelectedGQL(fdice)
        if (gg) {
            var ggcopy = { ...gg }
            ggcopy.type = JSON.stringify(ggcopy.type)
            pushUndoStack("mod", ggcopy)
            delete gg['Session']
            updateDrawPaperGqlObj(gg, fdice, removeObj)
        } else {
            updateDrawPaperObj(fdice, removeObj)
        }
        function removeObj() {
            fdice.remove()
            fdiceSaving = false
        }

        return
    }

    function addFDice(e) {
        // var dC = discColors
        // var dS = discSectors
        var syncStudentFdice = true
        var studentsCanRollDice = true
        // const userSpinnerStr = mylocalStorage.getItem("userSpinner");
        // if (userSpinnerStr && userSpinnerStr !== "") {
        //     const userSpinner = JSON.parse(userSpinnerStr)
        //     if (userSpinner.discColors && userSpinner.discSectors) {
        //         dC = userSpinner.discColors
        //         dS = userSpinner.discSectors
        //         syncStudentSpins = userSpinner.syncStudentSpins
        //         studentsCanSpin = userSpinner.studentsCanSpin
        //     }
        // }
        var fdiceWidget = drawFDice(new paper.Point(300, 300), syncStudentFdice, studentsCanRollDice)
        sessFDiceWidgets++
        updateDrawPaperObj(fdiceWidget, doneAddingFDice)
        function doneAddingFDice(frdice) {
            checkWidgetDeleted(fdiceWidget.data)
            fdiceWidget.remove()
        }
        try {
            ReactGA.event({
                category: 'User',
                action: 'FDiceWidgetAdded'
            });
        } catch { }
    }

    function drawCompass(start, end) {

        var myLine = new paper.Path.Line(start, end)

        myLine.strokeColor = "grey";
        myLine.strokeWidth = 2
        myLine.dashArray = [2, 2]
        myLine.data.compass = true
        myLine.data.compassType = "line"

        var myCircle = new Path.Circle(start, 10);
        myCircle.strokeWidth = 2
        myCircle.strokeColor = 'black';
        myCircle.fillColor = mkColor('#ffffff', 0.3)

        myCircle.selected = false
        myCircle.data.compass = true
        myCircle.data.compassType = "center"
        var myCircle21 = new Path.Circle(start, 1);
        myCircle21.strokeWidth = 1
        myCircle21.strokeColor = 'black';
        myCircle21.selected = false
        myCircle21.data.compass = true
        myCircle21.data.compassType = "center"

        attachCompassTools(myCircle)


        var myCircleE = new Path.Circle(end, 3);
        myCircleE.strokeWidth = 1
        myCircleE.strokeColor = ctx.color
        myCircleE.fillColor = ctx.color;
        myCircleE.selected = false;
        myCircleE.data.compass = true
        myCircleE.data.compassType = "end"
        attachCompassTools(myCircleE)


        let vect = end.subtract(start)
        vect = vect.multiply(0.3)
        vect = vect.add(start)

        var myCircleM = new Path.Circle(vect, 3);
        myCircleM.strokeWidth = 1
        myCircleM.strokeColor = "red"
        myCircleM.fillColor = "red";
        myCircleM.selected = false;
        myCircleM.data.compass = true
        myCircleM.data.compassType = "mid"
        attachCompassTools(myCircleM)

        let vect2 = end.subtract(start)
        vect2 = vect2.multiply(0.8)
        vect2 = vect2.add(start)
        var myCircleD = new Path.Circle(vect2, 3);
        myCircleD.strokeWidth = 1
        myCircleD.strokeColor = "green"
        myCircleD.fillColor = "green";
        myCircleD.selected = false;
        myCircleD.data.compass = true
        myCircleD.data.compassType = "draw"
        attachCompassTools(myCircleD)

        var compassGroup = new paper.Group([myCircle, myCircle21, myCircleE, myLine, myCircleD, myCircleM])
        compassGroup.data.compass = true
        compassGroup.data.compassType = "group"
        mycompass = compassGroup;
    }
    function addCompass(e) {
        delCompass()
        drawCompass(new paper.Point(200, 200), new paper.Point(400, 400))
        ctx.mode = 'compass'
    }
    function attachCompassTools(pobj) {
        pobj.onMouseEnter = function (event) {
            // createCompassBound(this, false)
            this.selected = true
        }
        pobj.onMouseLeave = function (event) {
            this.selected = false
        }
    }
    function getHitCompass(e) {
        clearSelect()
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]
            if (fooo && fooo.item && fooo.item.data && fooo.item.data.compass) {
                var c = fooo.item.data
                if (c.compassType === "line") continue
                compassSelect = fooo.item
                return
            }
        }
    }

    function compassDown(e) {
        cicleSegments = []
        compassDots = []

        compassSelect = null
        getHitCompass(e)
        if (!compassSelect) return
        if (compassSelect.data.compassType === "mid" ||
            compassSelect.data.compassType === "draw") {
            var vv = compassgetitem("center")
            var rect = vv
            var pp = getPaperPoint(e)
            rect.data.lastPoint = pp;
        }
        if (compassSelect.data.compassType === "end") {
            var rect = mycompass
            var pp = getPaperPoint(e)

            rect.data.bounds = rect.bounds.clone();
            rect.data.scaleBase = pp.subtract(rect.bounds.center);
        }
    }
    function delCompass() {
        if (!mycompass) return null

        for (let i = 0; i < mycompass.children.length; i++) {
            var child = mycompass.children[i]
            child.remove()
        }
        mycompass.remove()
        mycompass = null;
    }

    function compassUp(e) {
        if (!compassSelect) return
        if (compassSelect.data.compassType === "draw") {
            if (cicleSegments.length > 0) {
                var path = new paper.Path({
                    segments: cicleSegments,
                    strokeColor: mkColor(ctx.color, ctx.opacity),
                    strokeWidth: ctx.brushRadius,
                });
                updateDrawPaperObj(path, done)
                function done() {
                    path.remove()
                    for (let i = 0; i < compassDots.length; i++) {
                        compassDots[i].remove()
                    }
                }
            }
        }
        compassSelect = null
        compassDots = []
        cicleSegments = []
    }
    function compassgetitem(ty) {
        if (!mycompass) return null

        for (let i = 0; i < mycompass.children.length; i++) {
            var child = mycompass.children[i]
            if (child && child.data) {
                if (child.data.compassType === ty) return child
            }
        }
        return null
    }
    var cicleSegments = []
    var compassDots = []

    function drawDot(p) {
        cicleSegments.push(p)
        var myCircle = new Path.Circle(p, ctx.brushRadius / 2);
        var col = mkColor(ctx.color, ctx.opacity)
        myCircle.fillColor = col;
        compassDots.push(myCircle)
        // updateDrawPaperObj(myCircle, done)
        // function done() {
        //     myCircle.remove()
        // }
    }
    function compassmove(x, y) {
        if (!compassSelect) return
        if (compassSelect.data.compassType === "center") {
            mycompass.position.x = x
            mycompass.position.y = y
        }
        if (compassSelect.data.compassType === "end") {
            let vv = compassgetitem("center")
            let start = vv.bounds.center;
            var pt = new paper.Point(x, y)
            delCompass()
            drawCompass(start, pt)
            compassSelect = compassgetitem("end")

            // var rect = mycompass
            // var pt = new paper.Point(x, y)

            // var bounds = rect.data.bounds;
            // console.log("Bounds", bounds, rect, pt) 
            // var scale = pt.subtract(bounds.center).length /
            //     rect.data.scaleBase.length;
            // //rect.scale(scale)
            // var tlVec = bounds.topLeft.subtract(bounds.center).multiply(scale);
            // var brVec = bounds.bottomRight.subtract(bounds.center).multiply(scale);
            // var newBounds = new paper.Rectangle(tlVec.add(bounds.center), brVec.add(bounds.center));
            // rect.bounds = newBounds;
        }
        if (compassSelect.data.compassType === "mid" ||
            compassSelect.data.compassType === "draw") {
            var pt = new paper.Point(x, y)
            var vv = compassgetitem("center")
            var rect = vv
            var center = rect.bounds.center;
            var lastPoint = rect.data.lastPoint
            var baseVec = center.subtract(lastPoint);
            var nowVec = center.subtract(pt);
            var angle = nowVec.angle - baseVec.angle;
            rect.data.lastPoint = pt
            var vv = compassgetitem("center")
            var f2 = vv.bounds.center;

            mycompass.rotate(angle, f2);
            var vv2 = compassgetitem("end")
            var f3 = vv2.bounds.center;
            if (compassSelect.data.compassType === "draw") drawDot(f3)
        }

    }

    function startMoveResize(e) {
        selectedObj = getHitObj(e)
        var addBound = true
        if (selectedObj) {
            var skip = false
            if (selectedObj && selectedObj.data && selectedObj.data.lock) {
                clearSelect()
                return
            }
            var gg = findSelectedGQL()
            if (!gg) skip = true
            if (gg && gg.SessionID !== gSessionID) {
                skip = true;
                if (selectedObj.data.studentClone) {
                    clearAllSelected()
                    selectedObj.data.notSession = true
                    let rect = selectedObj.clone()
                    rect.data.id = null
                    rect = adjustClonedWidget(rect)
                    rect.data.studentClone = false
                    doPixieMagic({
                        x: rect.bounds.center.x, y: rect.bounds.center.y,
                        num: 20, type: "pixiedust"
                    })
                    rect.data.fixSize = selectedObj.data.fixSize;
                    selectedObj = rect
                    rect.selected = true
                    skip = false
                    addBound = false
                }
                if (selectedObj.data.studentMove) {
                    clearAllSelected()
                    let rect = selectedObj.clone()
                    rect.data.id = null
                    selectedObj.remove() //remove the parent 
                    rect = adjustClonedWidget(rect)
                    rect.data.studentMove = false
                    rect.data.studentMoveParent = selectedObj.data.studentMoveParentID;
                    rect.data.cannotdelete = true
                    rect.data.fixSize = selectedObj.data.fixSize;
                    selectedObj = rect
                    rect.selected = true
                    skip = false
                    addBound = false
                }
                if (skip === true) {
                    clearSelect()
                    ctx.mode = "moveResize"
                    ctx.canvas.interface.style.cursor = ctx.subMode
                    return
                }
            } else {
                if (gg && gg.SessionID === gSessionID && ctx.subMode === "copy") {
                    clearAllSelected()
                    let rect = selectedObj.clone()
                    rect.data.id = null
                    if (selectedObj.data.studentMoveParentID) {
                        rect.data.studentMoveParentID = uuid() //new one for the clone 
                    }
                    processClone(selectedObj, rect)
                    selectedObj = rect
                    rect.selected = true
                    ctx.mode = "edit"
                    ctx.lastMode = "moveResize"
                    return
                }

            }
            if (selectedObj.data.boundingObj) {
                if (selectedObj.data.boundingResize) {
                    var rect = selectedObj.data.boundingObj
                    selectedObj = rect;
                    var pp = getPaperPoint(e)
                    if (rect && rect.data) {
                        ctx.mode = "resize"
                        ctx.lastMode = "moveResize"
                        rect.data.state = 'resizing';
                        rect.data.bounds = rect.bounds.clone();
                        rect.data.scaleBase = pp.subtract(rect.bounds.center);
                    }
                    return
                }
                if (selectedObj.data.boundingRotate) {
                    let rect = selectedObj.data.boundingObj
                    selectedObj = rect;
                    let pp = getPaperPoint(e)
                    if (rect && rect.data) {
                        rect.data.state = 'rotating';
                        rect.data.lastPoint = pp;
                        ctx.mode = "rotate"
                        ctx.lastMode = "moveResize"
                    }
                    return
                }
                if (selectedObj.data.c1) {
                    //only allowed if its not a student dragging a clone obj
                    selectedObj = selectedObj.data.boundingObj; // drag by line 
                } else {
                    selectedObj = null
                }
            }
        }
        if (selectedObj) {
            if (ctx.AutoMove && ctx.mode !== "moveResize" && addBound) {
                ctx.mode = "moveResize"
            } else {
                ctx.lastMode = "moveResize"
                ctx.mode = "edit"
            }
            if (addBound) createBound(selectedObj, true, true)
        } else {
            if (ctx.AutoMove) {
                ctx.mode = ctx.AutoMove
                delete ctx['AutoMove']
            }
            clearBound()
        }
    }
    async function handleAddImage(img) {
        var center = new Point(window.innerWidth / 2, window.innerHeight / 2)
        if (gridView.open) {
            dispatch(Actions.setClickMode({ mode: "palette", ctx: img }))
            return
        }
        handleEscape()
        if (img.indexOf("http") !== -1) {
            var ff = await iu.GetImage(img)
            let raster = new paper.Raster({ crossOrigin: 'anonymous', source: ff.img });
            raster.position = center
            raster.data.palette = true

            raster.onLoad = function () {
                selectedObj = raster
                ctx.mode = "edit"
                ctx.canvas.interface.style.cursor = "crosshair"
            }
            raster.onError = function (f) {
                console.log('The image not loaded.', f);
            };
        } else {
            let raster = new paper.Raster({ source: img, crossOrigin: 'anonymous' });
            raster.position = center
            raster.data.palette = true

            raster.onLoad = function () {
                selectedObj = raster
                ctx.mode = "edit"
                ctx.canvas.interface.style.cursor = "crosshair"
            }
            raster.onError = function (f) {
                console.log('The image not loaded.', f);
            };
        }

    }
    const [linkProps, setLinkDialog] = React.useState({ open: false, cb: null })
    var debouceBRect = null

    function clearBBound() {
        clearTimeout(debouceBRect);
        if (boundBRect) {
            boundBRect.remove()
            boundBRect = null
        }
    }

    function drawStar(x, y) {
        const side = 10
        var triangle = new Path.RegularPolygon(new Point(x, y), 3, side);
        triangle.fillColor = 'gold';
        var t2nd = triangle.clone()
        t2nd.position = new Point(x, y + 3)
        t2nd.scale(1, -1)
        var grp = new paper.Group([triangle, t2nd])
        grp.data.life = 50 + Math.floor(Math.random() * 100)
        grp.data.id = stars.length
        grp.data.quad = Math.floor(Math.random() * 4);
        grp.data.mx = Math.floor(Math.random() * 10);
        stars.push({ obj: grp, id: grp.data.id })
    }

    function drawPixie(x, y) {
        var grp = new Path.Circle(new Point(x, y), 3);
        grp.fillColor = 'gold';

        grp.data.life = 20 + Math.floor(Math.random() * 100)
        grp.data.id = stars.length
        grp.data.quad = Math.floor(Math.random() * 4);
        grp.data.mx = Math.floor(Math.random() * 10);
        stars.push({ obj: grp, id: grp.data.id })
    }
    function animateStars() {
        if (stars.length <= 0) return
        var exp = []
        for (let key in stars) {
            var st = stars[key]
            var mx = 1
            var my = 1
            if (st.obj.data.quad === 0) {
                mx = -1
                my = -1
            }
            if (st.obj.data.quad === 1) {
                mx = 1
                my = -1
            }
            if (st.obj.data.quad === 2) {
                mx = -1
                my = 1
            }
            if (st.obj.data.quad === 3) {
                mx = 1
                my = 1
            }
            st.obj.position.y += (3 * my)
            st.obj.position.x += st.obj.data.mx * mx
            st.obj.data.life--
            if (st.obj.data.life <= 1) {
                exp.push(st.obj.data.id)
                st.obj.remove()
            }
        }
        for (let i in exp) {
            var e = exp[i]
            var ff = stars.findIndex(x => x.id === e)
            if (ff !== -1) stars.splice(ff, 1)
        }
    }
    function doPixieMagic(args) {
        var cx = args.x
        var cy = args.y
        var num = args.num
        var typ = args.type

        if (stars.length > 0) return
        const numStar = num
        for (let i = 0; i < numStar; i++) {
            var rx = -1 + Math.round(Math.random()) * 2;
            var ry = -1 + Math.round(Math.random()) * 2;

            var x = cx + (Math.floor(Math.random() * 100) * rx)
            var y = cy + (Math.floor(Math.random() * 100) * ry)
            if (typ === "stars") {
                drawStar(x, y)
            }
            if (typ === "pixiedust") {
                drawPixie(x, y)
            }
        }
    }
    function handleBClicked(e) {
        if (boundBRect && boundBRect.data.linkData && boundBRect.data.linkData.link) {
            handleLinkData(boundBRect, e)
        }
        clearBBound()
    }
    function createButton(pobj) {
        clearBBound()
        // selectedObj = pobj
        debouceBRect = setTimeout(function () {
            clearBBound()
            return
        }, 2000)
        var ff = pobj.bounds
        var rr = {}
        rr.x = ff.x - 5
        rr.y = ff.y - 5
        rr.height = ff.height + 10
        rr.width = ff.width + 10
        let rectanglePath = new Path.Rectangle(rr);
        rectanglePath.strokeColor = ctx.SavedColor;
        rectanglePath.strokeWidth = 3
        rectanglePath.dashArray = dashArray
        rectanglePath.data.boundingObj = pobj
        boundBRect = rectanglePath
        boundBRect.data.linkData = pobj.data.linkData
        boundBRect.selected = false
    }
    var lastMedia = 200
    var medBox = null

    function closeMedia() {
        if (!medBox) return
        try {
            document.body.removeChild(medBox);
        } catch { }
        medBox = null
    }

    function processElemMeat(ob, arr) {
        if (ob instanceof paper.PointText) {
            arr.push(ob.content)
        }
        if (ob instanceof paper.Raster) {
            arr.push(ob.source)
        }
        if (ob.children) {
            ob.children.forEach(pc => {
                processElemMeat(pc, arr)
            })
        }
    }
    function answerDialogCall(obj, children) {
        var extChild = null
        if (children) {
            extChild = []
            children.forEach(c => {
                processElemMeat(c, extChild)
            })
        }
        setAnswerDialog({
            open: true, cb: done, linkstate: obj.data.answerKey,
            children: extChild
        })
        function done(e) {
            setAnswerDialog({ open: false, cb: null, children: null })
            if (!e) return
            obj.data.answerKey = e
            if (extChild) obj.data.DropZone = { answers: extChild, id: obj.data.linkData.id }
            var rr = obj.data.id
            if (rr && paperObj[rr] && paperObj[rr].gqlObj) {
                updateDrawPaperGqlObj(paperObj[rr].gqlObj, obj, null)
                if (children)
                    children.forEach(c => {
                        if (c.data.id) ib.delObject(c.data.id)
                    })
            }
        }
    }
    function codingDialogCall(obj) {
        setCodingDialog({
            open: true, cb: done, linkstate: obj.data.codingKey,
        })
        function done(e) {
            setCodingDialog({ open: false, cb: null, children: null })
            if (!e) return
            obj.data.codingKey = e
            var rr = obj.data.id
            if (rr && paperObj[rr] && paperObj[rr].gqlObj) {
                updateDrawPaperGqlObj(paperObj[rr].gqlObj, obj, null)
            }
        }
    }

    function TossSelect(obj) {
        obj.data.toss = true
        var link = { type: "DropZone", subtype: "toss", dzType: "toss" }

        var r = createClick(obj, link, "Toss")
        var group = new paper.Group([obj, r])
        group.data.linkData = { type: "DropZone", subtype: "tossGroup", dzType: "tossGroup" }
        group.data.linkDataButtonDraw = true
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            group.remove()
            ib.delObject(obj.data.id)
            r.remove()
        }
    }

    function findTopParent(e) {
        var p = e.parent
        var c = 7
        while (p.data.linkData.dzType !== "tossGroup") {
            p = p.parent
            c--
            if (c === 0) return null
        }
        return p
    }
    function tossClick(e) {
        var p = findTopParent(e)
        if (!p) return
        var box = null
        p.children.forEach((f) => {
            if (f.data.toss) {
                box = f
            }
        })
        if (!box) return
        var items = paper.project.getItems({ inside: box.bounds })
        var arr = []
        var coinsA = {}
        items.map((item) => {

            var vv = isInside(box, item)
            if (vv && item.data.palette) {
                processElemMeat(item, arr)
                arr.forEach(a => {
                    if (a.includes("http")) {
                        var filename = a.split(/[\\\/]/).pop();
                        if (filename.includes("head") || filename.includes("tail")) {
                            var flips = getRandomInt(10)
                            coinsA[item.data.id] = { obj: item, path: a, flips: flips }
                        }
                    }
                })
            }
        })
        var coins = []
        Object.keys(coinsA).forEach(k => {
            coins.push(coinsA[k])
        })
        doFlips(coins)

    }

    async function doFlips(coins) {

        for (let j = 0; j < 10; j++) {
            for (let i = 0; i < coins.length; i++) {
                let c = coins[i]
                if (c.flips <= 0) continue;
                c.flips--
                if (c.path.includes("head")) {
                    c.path = c.path.replace("head", "tail")
                } else if (c.path.includes("tail")) {
                    c.path = c.path.replace("tail", "head")
                }

                c.obj.source = c.path;
                var rr = c.obj.data.id
                c.obj.remove()
                updateDrawPaperGqlObj(paperObj[rr].gqlObj, c.obj, doneD)
                function doneD(no) {
                    // c.obj = no 
                }

            }
            await new Promise(r => setTimeout(r, 500));
        }
    }
    function textBoxClick(e, evt) {
        setShowTextTools(true)
        // document.addEventListener('contextmenu', handleContextMenu)
        // if ((evt.ctrlKey || evt.button === 2) && gTeacher) {
        //     evt.preventDefault()
        //     answerDialogCall(e)
        //     return
        // }
        var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
        var font = mylocalStorage.getItem("font")
        var ob = e.bounds.topLeft
        font = font ? font : "Roboto"
        var text = ""
        if (inpBox) {
            closeInput()
        }
        if (gTextBox[e.data.linkData.id]) {
            var old = gTextBox[e.data.linkData.id]
            text = old._content
            ib.delObject(old.data.id)
            delete gTextBox[e.data.linkData.id]
            old.remove()
        }
        inValue = { text: text, size: { h: e.bounds.height - (1 * e.data.linkData.stroke), w: e.bounds.width - (1 * e.data.linkData.stroke) } }

        inValue['font'] = font
        inValue['color'] = mkColor(ctx.SavedColor, ctx.opacity)
        inValue['brushSize'] = e.data.linkData.fontsz
        var sy = ctx.canvasContainer.scrollTop
        var sx = ctx.canvasContainer.scrollLeft
        var yoff = (e.data.linkData.fontsz - 6) * 6
        savePos = {
            x: ob.x + e.data.linkData.stroke,
            y: ob.y + e.data.linkData.stroke + 24 + yoff, tbdata: { obj: e, tm: new Date() }
        }
        var pageX = savePos.x - sx + 5
        var pageY = savePos.y - sy - 24 - yoff
        addInput(pageX + drawCanvasPos.x, pageY + drawCanvasPos.y);
        // addInput(pageX, pageY);
        // document.removeEventListener('contextmenu', handleContextMenu)
    }

    function getPage(inPage) {
        var pgNum = parseInt(inPage)

        if (gSession && gSession.boardConfig) {
            try {
                var j = JSON.parse(gSession.boardConfig)
                if (j.boardOrder) {
                    pgNum = j.boardOrder.order[pgNum]
                }
            } catch { }
        }
        return pgNum
    }
    function handleLinkData(e, evt) {
        if (e.data.linkData.type && e.data.linkData.type === "DropZone") {
            return DropZoneHit(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "ScreenShade") {
            return ScreenShadeHit(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "rbead") {
            return RbeadHit(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "form") {
            return formHit(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "coding") {
            return codingHit(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "playingCardPair") {
            return cardPairHit(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "playingcard") {
            return cardHit(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "runProgram") {
            return programClickRun(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "textBox") {
            return textBoxClick(e, evt)
        }
        if (e.data.linkData.type && e.data.linkData.type === "stoplight") {
            return stopLightClick(e)
        }
        if (e.data.linkData.type && e.data.linkData.type === "gameplay") {
            return handleGPButton(e.data.linkData)
        }
        if (e.data.linkData.type && e.data.linkData.type === "autoCorrect") {
            var luid = mylocalStorage.getItem('mystoreID');
            const pgSplit = gSession.parentBoardID.split("-pgNum-")
            ib.autoCorrect(gSession.Classroom, luid, pgSplit[1]).then((res) => { setAutoCorrect(true) })
            return
        }
        if (e.data.linkData.type && e.data.linkData.type === "runCodeBlock") {
            runCodeBlock();
            return
        }
        if (e.data.linkData.link) {
            if (e.data.linkData.type && e.data.linkData.type === "pageInBook") {
                const pgSplit = gSessionID.split("-pgNum-")
                var np = getPage(e.data.linkData.link)
                const newPage = pgSplit[0] + "-pgNum-" + np
                props.history.push("/board/" + newPage)
            } else {
                window.open(e.data.linkData.link)
            }
        }
        if (e.data.linkData.type && e.data.linkData.type === "media") {
            var ff = e.bounds.topLeft
            if (medBox) {
                document.body.removeChild(medBox);
                medBox = null
            }
            var input = document.createElement('video');
            input.src = e.data.linkData.file
            input.controls = true
            input.autoplay = true

            input.style.position = 'absolute';
            input.style.left = (ff.x - 4) + 'px';
            input.style.top = (ff.y - 4) + 'px';
            input.style.zIndex = 100
            input.style.color = "#ffffff"
            input.style.background = ctx.SavedColor;
            document.body.appendChild(input);
            input.focus();
            input.onblur = function () {
                closeMedia()
            };
            setTimeout(function () {
                medBox = input
            }, 100);
        }
    }

    function buttonHit(e) {
        if (gTeacher && boundRect) return false
        if ((e.ctrlKey || e.button === 2) && gTeacher) return false
        if (selectedObj && ctx.mode === "edit") return false
        var hitOptions = { segments: true, stroke: true, fill: true, tolerance: 5 };
        let point = paper.DomEvent.getOffset(e, ctx.canvas.drawing)
        point = paper.view.viewToProject(point)
        var foooA = paper.project.hitTestAll(point, hitOptions)
        for (let i = 0; i < foooA.length; i++) {
            let fooo = foooA[i]
            if (fooo && fooo.item && fooo.item.data && fooo.item.data.linkButton) {
                buttonClicked = fooo.item
                handleLinkData(fooo.item, e)
                return true
            }
        }
        closeMedia()
        return false
    }

    function createIconClickButton(gr, link, icon) {
        var ff = gr.bounds.bottomRight
        var rr = {}
        rr.x = ff.x - 45
        rr.y = ff.y - 45
        rr.height = 40
        rr.width = 40
        var b = createButtonCommon(gr, link, "", rr)
        b.fillColor = "#FFFFFF44"
        var clickIcon = paper.project.importSVG(icon)
        clickIcon.position = b.position
        clickIcon.scale(1.8)
        return new paper.Group([b, clickIcon])
    }

    function createClick(gr, link, textIn) {
        var ff = gr.bounds.bottomRight
        var rr = {}
        rr.x = ff.x - 85
        rr.y = ff.y - 35
        rr.height = 30
        rr.width = 80
        return createButtonCommon(gr, link, textIn, rr)
    }

    function createButtonCommon(gr, link, textIn, rr) {
        var cornerSize = new paper.Size(10, 10);
        var rrT = new paper.Rectangle(rr)
        let rectanglePath = new Path.Rectangle(rrT, cornerSize);
        if (textIn === "")
            rectanglePath.fillColor = ctx.SavedColor
        else
            rectanglePath.fillColor = ctx.SavedColor

        rectanglePath.data.linkButton = true
        rectanglePath.data.linkData = link
        var point = new paper.Point(rr.x + 10, rr.y + 20)
        if (textIn !== "") {
            var text = new paper.PointText(point);
            text.content = textIn
            text.style = {
                fontFamily: "Roboto",
                fontSize: 20,
                fillColor: "#ffffff",
                justification: 'left'
            };
            text.data.linkButton = true
            text.data.linkData = link
            rectanglePath.insertBelow(text);
        } else {
            return rectanglePath
        }

        var g1 = new paper.Group([rectanglePath, text])
        g1.data.linkData = link
        g1.data.linkButton = true
        return g1
    }
    function mediaInsert(e) {
        var passed = { ...e }
        handleEscape()
        if (!e) return
        const [x, y] = [500, lastMedia]
        lastMedia += 50

        var img = "/audio.png"
        var raster = new paper.Raster({ crossOrigin: 'anonymous', source: img, });
        raster.onLoad = function () {

            raster.position = new Point(x - 70, y)
            raster.fillColor = "#ffffff"
            var point = new paper.Point(x, y)
            var text = new paper.PointText(point);
            text.content = e.name;
            var font = mylocalStorage.getItem("font")
            font = font ? font : "Roboto"
            text.style = {
                fontFamily: font,
                fontSize: 35,
                fillColor: "#ffffff",
                justification: 'left'
            };
            var g1 = new paper.Group([text, raster])
            var ff = g1.bounds
            var rr = {}
            rr.x = ff.x - 10
            rr.y = ff.y - 10
            rr.height = ff.height + 20
            rr.width = ff.width + 20
            var cornerSize = new paper.Size(10, 10);
            var rrT = new paper.Rectangle(rr)
            let rectanglePath = new Path.Rectangle(rrT, cornerSize);
            rectanglePath.insertBelow(text);
            rectanglePath.fillColor = mkColor(ctx.SavedColor, ctx.opacity);
            rectanglePath.strokeWidth = 3
            passed.type = "media"

            var r = createClick(rectanglePath, passed, "PLAY")
            var group = new paper.Group([rectanglePath, text, raster, r])
            group.data.linkData = passed
            group.data.linkDataButtonDraw = true
            updateDrawPaperObj(group, donePaper)
            function donePaper(nObj) {
                group.remove()
                rectanglePath.remove()
                text.remove()
            }
        }
    }

    function addLink(foundKey) {
        if (!selectedObj) return
        setLinkDialog({ open: true, cb: done, noName: true })
        function done(e) {
            var icon = svgIcons.clickIcon;
            setLinkDialog({ open: false, cb: null })
            handleEscape()
            if (!e) return
            var r = createIconClickButton(selectedObj, e, icon)
            var group = new paper.Group([selectedObj, r])
            group.data.linkData = e
            group.data.linkDataButtonDraw = true

            updateDrawPaperObj(group, donePaper)
            if (foundKey) {
                ib.delObject(foundKey)
            }
            function donePaper(nObj) {
                group.remove()
                r.remove()
            }
        }

    }
    function linkInsertClick(e1) {
        setLinkDialog({ open: true, cb: done })
        function done(e) {
            setLinkDialog({ open: false, cb: null })
            handleEscape()
            if (!e) return
            var img = "/link.png"
            if (e.type === "autoCorrect") img = "/accept.png"
            var raster = new paper.Raster({ crossOrigin: 'anonymous', source: img, });
            raster.onLoad = function () {

                if (!e) return
                const [x, y] = getPoint(e1)
                raster.position = new Point(x, y)
                raster.fillColor = "#ffffff"

                var point = new paper.Point(x + 50, y)
                var text = new paper.PointText(point);
                text.content = e.name;
                var font = mylocalStorage.getItem("font")
                font = font ? font : "Roboto"
                text.style = {
                    fontFamily: font,
                    fontSize: 21,
                    fillColor: "#ffffff",
                    justification: 'left'
                };
                var g1 = new paper.Group([text, raster])

                var ff = g1.bounds
                var rr = {}
                rr.x = ff.x - 10
                rr.y = ff.y - 10
                rr.height = ff.height + 20
                rr.width = ff.width + 20
                var cornerSize = new paper.Size(10, 10);
                var rrT = new paper.Rectangle(rr)
                let rectanglePath = new Path.Rectangle(rrT, cornerSize);
                rectanglePath.insertBelow(text);
                rectanglePath.fillColor = mkColor(ctx.SavedColor, ctx.opacity);
                rectanglePath.strokeWidth = 3
                var r = createClick(rectanglePath, e, "CLICK")
                var group = new paper.Group([rectanglePath, text, raster, r])
                group.data.linkData = e
                group.data.linkDataButtonDraw = true
                group.position.x += 40
                group.position.y += 30
                updateDrawPaperObj(group, donePaper)
                function donePaper(nObj) {
                    group.remove()
                    rectanglePath.remove()
                    text.remove()
                }
            }
        }
    }
    function drawSnake() {
        handleEscape()
        ctx.mode = "snake"
        ctx.subMode = "snake"
        ctx.canvas.interface.style.cursor = "crosshair"
    }
    function drawSpotlight() {
        handleEscape()
        ctx.mode = "snake"
        ctx.subMode = "spotlight"
        ctx.canvas.interface.style.cursor = "crosshair"
    }
    function drawThumbs() {
        handleEscape()
        var obj = []

        var img = "/thumbs/black-thumbs.png"
        var raster = new paper.Raster({ source: img, crossOrigin: 'anonymous' });
        raster.data.createdAt = new Date().getTime() / 1000
        paper.project.activeLayer.insertChild(0, raster);
        raster.data.grid = true
        raster.onLoad = function () {
            const id = uuid()
            raster.position = new Point(200, 200)
            raster.data.linkData = { type: "stoplight", index: 0, id: id, subtype: "thumb" }
            raster.data.linkDataButtonDraw = true
            raster.data.linkButton = true
            raster.opacity = 0.05;

            var cornerSize = new paper.Size(10, 10);
            var ff = raster.bounds
            var rr = {}
            rr.x = ff.x - 10
            rr.y = ff.y - 10
            rr.height = ff.height + 20
            rr.width = ff.width + 20
            var rrT = new paper.Rectangle(rr)
            let rectanglePath = new Path.Rectangle(rrT, cornerSize);
            rectanglePath.strokeColor = "#CCCCCC"
            rectanglePath.strokeWidth = 5
            obj.push(rectanglePath)
            obj.push(raster)
            var group = new paper.Group(obj)
            group.data.stoplightGrp = id
            group.data.linkData = { type: "stoplight", group: true, subtype: "thumb" }
            group.data.linkDataButtonDraw = true

            updateDrawPaperObj(group, donePaper)
            function donePaper(nObj) {
                group.remove()
            }
        }
    }

    function processClone(oldObj, newObj) {
        var newuuid = uuid()
        function checkObj(o) {
            o.data.id = null
            if (o.data.stoplightGrp) {
                o.data.stoplightGrp = newuuid
            }
            if (o.data && o.data.linkData && o.data.linkData.id) {
                o.data.linkData = JSON.parse(JSON.stringify(o.data.linkData))
                o.data.linkData.id = newuuid
                delete o.data['answerKey']
            }
        }
        if (newObj.children) {
            for (let i = 0; i < newObj.children.length; i++) {
                var child = newObj.children[i]
                checkObj(child)
                if (child.children) {
                    child.children.forEach(pc => {
                        checkObj(pc)
                    })
                }
            }
        }

        checkObj(newObj)
    }

    function thumbsClickDb(e) {
        const COLORS_SL = ["/thumbs/redthumb.png", "/thumbs/yellowthumb.png", "/thumbs/greenthumb.png"]
        var ld = e.data.linkData
        var oldidx = 0
        if (ld.group) return
        if (gStopLight[ld.id]) {
            oldidx = gStopLight[ld.id].data.stoplight.index
            ib.delObject(gStopLight[ld.id].data.id)
            gStopLight[ld.id].remove()
            delete gStopLight[ld.id]
        }
        oldidx = (oldidx + 1) % COLORS_SL.length
        var col = COLORS_SL[oldidx]
        var raster = new paper.Raster({ source: col, crossOrigin: 'anonymous' });
        raster.data.createdAt = new Date().getTime() / 1000
        raster.onLoad = function () {
            var ff = e.bounds.center
            raster.position = ff
            raster.data.stoplight = { stoplightID: ld.id, index: oldidx, sess: gSessionID, subtype: "thumb" }

            updateDrawPaperObj(raster, donePaper)
            function donePaper(nObj) {
                raster.remove()
            }
        }
    }

    function drawStopLight() {
        handleEscape()
        var obj = []
        var rr = {}
        rr.x = 200
        rr.y = 200
        rr.height = 280
        rr.width = 100
        var rrT = new paper.Rectangle(rr)
        var cornerSize = new paper.Size(10, 10);
        let rectanglePath = new Path.Rectangle(rrT, cornerSize);
        rectanglePath.fillColor = "black"
        obj.push(rectanglePath)
        const id = uuid()
        const RADIUS = 40
        for (let i = 0; i < 3; i++) {
            var pt = new paper.Point(rr.x + rr.width / 2, rr.y + (RADIUS * 2) + (i * 90) - 30)
            var myCircle = new Path.Circle(pt, RADIUS);
            myCircle.fillColor = "grey"
            obj.push(myCircle)
            myCircle.data.linkData = { type: "stoplight", index: i, id: id }
            myCircle.data.linkDataButtonDraw = true
            myCircle.data.linkButton = true
        }
        var group = new paper.Group(obj)
        group.data.stoplightGrp = id
        group.data.linkData = { type: "stoplight", group: true }
        group.data.linkDataButtonDraw = true
        myCircle.data.linkButton = true
        updateDrawPaperObj(group, donePaper)
        function donePaper(nObj) {
            group.remove()
            rectanglePath.remove()
        }
    }

    var stopLightdebouce = null
    function stopLightClick(e) {
        var fn = stopLightClickDb;
        if (e.data.linkData && e.data.linkData.subtype === "thumb") fn = thumbsClickDb
        if (e.data.linkData && e.data.linkData.subtype === "calc") fn = calcClick

        ib.debounceClick({ timer: stopLightdebouce, function: fn, args: e })
    }

    async function calcClick(e) {
        var ld = e.data.linkData

        function updateGQL() {
            var rr = gStopLight[ld.id].data.id
            if (rr && paperObj[rr] && paperObj[rr].gqlObj) {
                updateDrawPaperGqlObj(paperObj[rr].gqlObj, gStopLight[ld.id], null)
            }
        }
        if (ld.group) return
        if (gStopLight[ld.id]) {
            // ihave a text object already 
            if (cc.calcClick(gStopLight[ld.id], e)) {
                updateGQL()
            }
            return
        }

        var screen = null
        e.parent.children.forEach((f) => {
            if (f.data.screen) {
                screen = f
            }
        })
        if (screen) {
            var b = screen.bounds.bottomRight
            var text = new paper.PointText(new paper.Point(b.x - 20, b.y - 20))
            text.data.stoplight = { stoplightID: ld.id, index: ld.index, sess: gSessionID }
            text.content = "0"
            text.style = {
                fontFamily: 'roboto',
                fontSize: 20,
                fillColor: ctx.SavedColor,
                justification: 'right'
            };
            updateDrawPaperObj(text, donePaper)
            function donePaper(nObj) {
                text.remove()
                if (cc.calcClick(gStopLight[ld.id], e)) {
                    updateGQL()
                }
            }
        }
    }
    function stopLightClickDb(e) {
        const COLORS_SL = ["red", "yellow", "green"]
        var ld = e.data.linkData
        if (ld.group) return
        if (gStopLight[ld.id]) {
            var oldidx = gStopLight[ld.id].data.stoplight.index
            ib.delObject(gStopLight[ld.id].data.id)
            gStopLight[ld.id].remove()
            delete gStopLight[ld.id]
            if (oldidx === ld.index) return //turn off
        }
        var col = COLORS_SL[ld.index]
        var ff = e.bounds.center
        var radius = e.bounds.width / 2
        var myCircle = new Path.Circle(ff, radius);
        myCircle.fillColor = col
        myCircle.data.stoplight = { stoplightID: ld.id, index: ld.index, sess: gSessionID }
        updateDrawPaperObj(myCircle, donePaper)
        function donePaper(nObj) {
            myCircle.remove()
        }
    }

    function handleLink() {
        handleEscape()
        ctx.mode = "insertLink"
        ctx.canvas.interface.style.cursor = "copy"
    }

    function handleCloner() {
        handleEscape()
        ctx.mode = "moveResize"
        ctx.subMode = "copy"
        ctx.canvas.interface.style.cursor = "copy"
    }

    function handleYouTube() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "copy"
        ctx.mode = "youtube"
    }
    function handleWebsite() {
        handleEscape()
        ctx.canvas.interface.style.cursor = "copy"
        ctx.mode = "website"
    }
    function handleMath(e) {
        handleEscape()
        ctx.canvas.interface.style.cursor = "text"
        ctx.mode = "math"
    }
    const cbs = {
        'loadImage': loadImage, 'clearCanvas': handleButtonClear, 'drawScreenShade': drawScreenShade,
        'Hide': handleButtonHide, 'Draw': handleButtonDraw, 'Erase': handleButtonDraw,
        'Text': handleText, 'Circle': handleCircle, 'PolyGon': handlePolygon, 'Play Animations': handleButtonPause, 'Line': handleLine, 'Lasso': handleLasso,
        'Pause Animations': handleButtonPause, 'Select': HandleAnimate, 'Download': HandleDownload, "Message": (m) => aniMess(m), "Notice": (m) => setMessage(m), 'downloadThisPage': downloadThisPage,
        'drawGrid': drawGrid, 'drawMusic': drawMusicGrid, 'drawHandwrite': drawHandwrite, 'Rect': handleRect, 'DownloadClass': HandleDownloadClass,
        'addImage': handleAddImage, 'Arrow': handleArrow, 'drawSyllable': drawSyllable, 'drawNote': drawNote, 'Move & Resize': HandleMoveResize, 'drawElkonin': drawElkonin,
        'clearGrid': cleanOldGrid, 'drawRichText': drawRichText, 'simpleColor': handleSimpleColor, 'backGround': handleBackGround,
        'handleCloner': handleCloner, 'handleLink': handleLink, drawHandwrite2: drawHandwrite2, 'BreakApart': BreakApart,
        'drawProgramGrid': drawProgramGrid, 'YouTube': handleYouTube, 'Website': handleWebsite, 'addCompass': addCompass, 'handleMath': handleMath, 'drawSpotlight': drawSpotlight,
        'addClock': addClock, 'addColoredClock': addColoredClock, 'addSpinner': addSpinner, 'addParticipantsSpinner': addParticipantsSpinner, 'addFDice': addFDice, 'drawSnake': drawSnake, 'drawStopLight': drawStopLight, 'textBox': textBox, 'drawThumbs': drawThumbs,
        'mediaInsert': mediaInsert, 'Export': HandleExport, 'drawCollegeLine': drawCollegeLine, 'drawCoordinatePlane': drawCoordinatePlane, 'graphCalc': graphCalc,
        'basicCalc': basicCalc, 'drawSelect': drawSelect, 'TileFactory': TileFactory, 'GridPainter': GridPainter, 'GridBox': GridBox, 'lockAllObj': lockAllObj, 'FormFactory': FormFactory,
        'TableFactory': TableFactory, 'DotPainter': DotPainter, 'Piano': DrawPiano, 'Xylophone': DrawXylophone, 'CallStudent': CallStudent, 'programmingBox': programmingBox,
        'addFromPalette': addFromPalette, 'DropZone': DropZone, 'DrawRekenrek': DrawRekenrek, 'MoleculeEditor': DrawMoleculeEditor, 'openEngagement': openEngagement, 'HandWrite': HandWrite,
        'addCoded': addCoded, 'drawRedWhite': drawRedWhite, 'magicDraw': magicDraw, 'ColorPicker': handleColorPicker, 'DrawBrush': handleButtonDraw, 'ZoomIn': ZoomInApiMode, 'Pan': PanMode
    }

    function magicDraw(e) {
        handleEscape()
        createMagicDraw()
        ctx.canvas.interface.style.cursor = "auto"
        ctx.mode = "magicDraw"
        setdrawMode({ name: 'draw' })
        ctx.color = ctx.SavedColor
        ctx.brushRadius = ctx.drawBrushRadius
        if (ctx.slider && ctx.slider.brush) {
            ctx.slider.brush.value = ctx.brushRadius
        }
        seteraseMode(false)
    }
    const BEADRAD = 15

    function DrawRLine(x, y, linenum) {
        var obj = []
        const LEN = 600

        var line = new Path.Line(new Point(x, y), new Point(x + LEN, y));
        line.strokeColor = "silver";
        line.strokeWidth = 4;
        line.data.linkData = {
            type: "rbead", line: linenum, typeR: "line"
        }
        obj.push(line)

        //beads
        for (let i = 0; i < 10; i++) {
            var bead = new Path.Circle(new Point(x + (i * 2 * BEADRAD), y), BEADRAD)
            var col = "red"
            if (i >= 5) col = "#FFFAFA"
            // bead.fillColor = {
            //     gradient: {
            //         stops: [[col, 0.90], ["black", 1]],
            //         radial: true
            //     },
            //     origin: bead.position,
            //     destination: bead.bounds.rightCenter
            // };
            bead.fillColor = col
            bead.strokeColor = "black"
            bead.strokeWidth = 1
            bead.data.linkData = {
                type: "rbead", id: i, line: linenum, typeR: "bead"
            }

            bead.data.linkButton = true
            bead.data.linkDataButtonDraw = true
            obj.push(bead)
        }
        var g = new paper.Group(obj)
        g.data.linkData = {
            type: "rbead", line: linenum, typeR: "groupLine"
        }
        return g
    }
    function DrawRekenrek(lines = 2) {
        var obj = []
        var b = {
            x: 65,
            y: 165,
            width: 650,
            height: (lines * 50) + 20
        }

        var cornerSize = new paper.Size(10, 10);
        var rrT = new paper.Rectangle(b)

        let rectanglePath = new Path.Rectangle(rrT, cornerSize)
        rectanglePath.fillColor = "#E18E2E"
        obj.push(rectanglePath)
        for (let i = 0; i < lines; i++) {
            let g = DrawRLine(100, 200 + (i * 50), i + 1)
            obj.push(g)
        }
        var g = new paper.Group(obj)

        g.data.linkData = {
            type: "rbead", typeR: "outer"
        }

        g.data.linkButton = true
        g.data.linkDataButtonDraw = true
        g.data.studentMoveParentID = uuid()
        g.data.studentMove = true

        let rr2 = {
            x: g.bounds.bottomLeft.x,
            y: g.bounds.bottomLeft.y + 10,
            height: 30,
            width: 80,
        }
        var rrLink = { type: "rbead", lines: lines, typeR: "reset" }

        var but = createButtonCommon(g, rrLink, "Reset", rr2)
        g.addChild(but)
        gRBeadObj = { parent: g, newOne: true, hit: null }

        resetRbead()
        // updateDrawPaperObj(g, done)
        // function done() {
        //     g.remove()

        // }
    }

    function getTopRbead(e) {
        if (e.data.linkData.typeR === "bead") return e.parent.parent
        if (e.data.linkData.typeR === "groupLine") return e.parent
        if (e.data.linkData.typeR === "outer") return e
        if (e.data.linkData.typeR === "reset") {
            var p = e.parent
            var c = 7
            while (p.data.linkData.typeR !== "outer") {
                p = p.parent
                c--
                if (c === 0) return null
            }
            return p
        }
    }

    function resetRbead() {
        if (!gRBeadObj) return
        let par = gRBeadObj.parent
        var arr = {}
        for (let i = 0; i < par.children.length; i++) {
            var gl = par.children[i]
            if (gl.data.linkData && gl.data.linkData.typeR === "groupLine") {
                // found this line
                gl.children.forEach(c => {
                    let cd = c.data.linkData
                    if (cd.typeR === "bead") arr[cd.id] = c
                })
                gRBeadObj.hit = arr[0]

                RbeadMove(gl.bounds.bottomRight.x, gl.bounds.bottomRight.y, null)
            }
        }
        RbeadDone()
    }
    function RbeadHit(e, evt) {
        var top = getTopRbead(e)
        if (!isMine(top)) {
            var ff = top.clone()
            top.remove()
            ff.data.id = null
            ff.data.studentMove = false
            ff.data.studentMoveParent = top.data.studentMoveParentID;
            ff.data.cannotdelete = true
            ff.data.fixSize = top.data.fixSize;
            gRBeadObj = { parent: ff, newOne: true, hit: e }

            if (e.data.linkData.typeR === "reset") return resetRbead()
            ctx.mode = "rbead"
            return
        }
        selectedObj = e;
        gRBeadObj = { parent: top, newOne: false, hit: e }
        if (e.data.linkData.typeR === "reset") return resetRbead()

        ctx.mode = "rbead"
    }
    function RbeadMove(x, y, evt) {
        if (!gRBeadObj) return
        let e = gRBeadObj.hit
        let par = gRBeadObj.parent

        if (!e || !e.data.linkData || e.data.linkData.type !== "rbead" || e.data.linkData.typeR !== "bead") return
        var bead = e.data.linkData
        //line and id 
        var thisBead = null
        var foundLine = 0
        var arr = {}
        par.children.forEach(gl => {
            if (gl.data.linkData && gl.data.linkData.typeR === "groupLine" && gl.data.linkData.line === bead.line) {
                // found this line
                gl.children.forEach(c => {
                    let cd = c.data.linkData
                    if (cd.typeR === "line") foundLine = c
                    if (cd.id === bead.id) thisBead = c
                    if (cd.typeR === "bead") arr[cd.id] = c
                })
            }
        })
        var rad = (thisBead.bounds.bottomRight.x - thisBead.bounds.bottomLeft.x) / 2

        function moveBead(bd, x) {
            var bb = bd.data.linkData
            var blID = bb.id > 0 ? bb.id - 1 : -1
            var brID = bb.id < 9 ? bb.id + 1 : -1
            var beadLeft = blID !== -1 ? arr[blID] : null
            var beadRight = brID !== -1 ? arr[brID] : null
            let maxX = foundLine.bounds.bottomRight.x
            let minX = foundLine.bounds.bottomLeft.x - rad

            if (beadRight) {
                maxX = beadRight.bounds.bottomLeft.x
            }
            if (beadLeft) {
                minX = beadLeft.bounds.bottomRight.x
            }

            var curx = bd.position.x
            let setx = x

            var movex = x - curx; // positive to the right, -ve to the left 
            if (movex > 0 && beadRight && (setx + rad) > maxX) {
                //gonna hit the next bead
                moveBead(beadRight, maxX + rad + movex)
                maxX = beadRight.bounds.bottomLeft.x
            }
            if (movex < 0 && beadLeft && (setx - rad) < minX) {
                //gonna hit the next bead
                moveBead(beadLeft, minX - rad + movex)
                minX = beadLeft.bounds.bottomRight.x
            }
            if (setx + rad > maxX) {
                setx = maxX - rad
            } else if (setx - rad < minX) {
                setx = minX + rad
            }
            bd.position.x = setx

        }
        if (thisBead && foundLine) {
            moveBead(thisBead, x)
        }
    }
    function RbeadDone() {
        if (!gRBeadObj) return

        if (gRBeadObj.newOne) {
            updateDrawPaperObj(gRBeadObj.parent)
            gRBeadObj.parent.remove()
            gRBeadObj = null
            return
        }
        let pid = gRBeadObj.parent.data.id
        let po = paperObj[pid] && paperObj[pid].gqlObj ? paperObj[pid].gqlObj : null
        if (po) {
            updateDrawPaperGqlObj(po, gRBeadObj.parent, done)
            function done() {
                if (gRBeadObj) gRBeadObj.parent.remove()
                gRBeadObj = null
            }
        } else {
            gRBeadObj = null
        }

        handleEscape()
    }
    const [peerDiag, setPeerDiag] = React.useState({ open: false, peer: null, cb: null })
    function answerCall(call) {
        setPeerDiag({ open: true, peer: null, gPeer: gPeer, cb: gotBall, call: call })
        function gotBall() {
            setPeerDiag({ open: false, peer: null, gPeer: null, cb: null, call: null })
        }
    }
    function CallStudent(e) {
        if (e.peerID && e.peerID in peers) {
            setPeerDiag({ open: true, peer: e.peerID, gPeer: gPeer, cb: gotBall, call: null })
        }
        function gotBall() {
            setPeerDiag({ open: false, peer: null, gPeer: null, cb: null, call: null })
        }
    }
    function lockAllObj() {
        var kobjs = Object.keys(paperObj)
        kobjs.forEach((key) => {
            var obj = paperObj[key]
            if (!obj || !obj.obj || obj.obj.data.lock) return
            obj.obj.data.lock = true
            updateDrawPaperObj(obj.obj, done)
            function done() {
                clearSelect()
                ib.delObject(key)
            }
        })
    }

    function setTilebox(obj) {
        var setBox = obj.data.rawForm ? formBox : tileBox
        if (obj.data.tile || obj.data.rawForm) {
            var f1 = obj.bounds.bottomRight
            var f2 = obj.bounds.topLeft
            var xcon = f1.x
            var ycon = f2.y
            if (setBox.x < xcon) setBox.x = xcon
            if (setBox.y < ycon) {
                setBox.x = xcon
                setBox.y = ycon
            }

            if (f1.x >= 1000) {
                setBox.x = 150
                setBox.y = f1.y + 25
            }
            if (f1.y >= maxHeight) {
                setBox.x = 150
                setBox.y = 200
                obj.position.y = 200
            }
        }
    }
    const [tableFactory, setTableFactory] = React.useState({ open: false, cb: null })

    function drawTable(e) {
        const tableDim = { x: 120, y: 100, totalw: 800, h: 70 }
        var r = parseInt(e.text.row)
        var c = parseInt(e.text.column)
        var obj = []
        var col = mkColor(ctx.color, ctx.opacity)
        var ycor = tableDim.y
        var colSize = tableDim.totalw / c
        if (e.control.makeSquareCells) {
            colSize = 80
            tableDim.totalw = colSize * c
        }
        for (let rr = 0; rr < r; rr++) {
            var leftPoint = new paper.Point(tableDim.x, ycor);
            var rightPoint = new paper.Point(tableDim.x + tableDim.totalw, ycor);
            let line = new paper.Path.Line(leftPoint, rightPoint);
            line.strokeColor = col;

            if (e.control.firstRowHeader && rr === 1) {
                line.strokeWidth = ctx.brushRadius * 2;
                let rectanglePath = new Path.Rectangle(new paper.Point(tableDim.x, tableDim.y), rightPoint);
                rectanglePath.fillColor = mkColor(ctx.color, 7)
                rectanglePath.data.tableRowLine = { rowH: tableDim.h, colH: colSize, columns: c }
                obj.push(rectanglePath)
            } else {
                if (rr !== 0) {
                    let rectanglePath = new Path.Rectangle(new paper.Point(tableDim.x, ycor - tableDim.h),
                        rightPoint);
                    rectanglePath.fillColor = mkColor(ctx.color, 2)
                    rectanglePath.data.tableRowLine = { rowH: tableDim.h, colH: colSize, columns: c }
                    obj.push(rectanglePath)
                }
                line.strokeWidth = 2;
            }
            obj.push(line)
            ycor += tableDim.h
        }
        //last row
        rightPoint = new paper.Point(tableDim.x + tableDim.totalw, ycor);
        let rectanglePath2 = new Path.Rectangle(new paper.Point(tableDim.x, ycor - tableDim.h),
            rightPoint);
        rectanglePath2.fillColor = mkColor(ctx.color, 2)
        rectanglePath2.data.tableRowLine = { rowH: tableDim.h, colH: colSize, columns: c }
        obj.push(rectanglePath2)

        var xcor = tableDim.x + colSize
        for (let rr = 0; rr < c; rr++) {

            var leftPoint = new paper.Point(xcor, tableDim.y);
            var rightPoint = new paper.Point(xcor, ycor);
            let line = new paper.Path.Line(leftPoint, rightPoint);
            line.strokeColor = col;
            line.strokeWidth = 2;
            xcor += colSize
            obj.push(line)
        }

        var rr = { x: tableDim.x, y: tableDim.y, width: tableDim.totalw, height: tableDim.h * r }
        var cornerSize = new paper.Size(5, 5);
        var rrT = new paper.Rectangle(rr)
        let rectanglePath = new Path.Rectangle(rrT, cornerSize);
        rectanglePath.strokeColor = col;
        rectanglePath.strokeWidth = ctx.brushRadius * 2;
        obj.push(rectanglePath)

        var g1 = new paper.Group(obj)
        g1.data.table = true
        g1.data.stackwithtime = true
        updateDrawPaperObj(g1, donePaper)
        function donePaper(nObj) {
            g1.remove()
            obj.forEach((o) => {
                o.remove()
            })
        }
    }
    function TableFactory() {
        setTableFactory({ open: true, cb: done })
        function done(e) {
            setTableFactory({ open: false, cb: null })
            if (!e) {
                return
            }
            drawTable(e)
        }
    }

    function applybreaks(strRawValue) {
        var nLastWrappingIndex = -1;
        var newText = ""
        var curLineLen = 0
        for (var i = 0; i < strRawValue.length; i++) {
            var curChar = strRawValue.charAt(i);
            if (curChar === ' ' || curChar === '-' || curChar === '+')
                nLastWrappingIndex = i;
            newText += curChar;
            curLineLen++
            if (curLineLen > 10) {
                var buffer = "";
                if (nLastWrappingIndex >= 0) {
                    for (var j = nLastWrappingIndex + 1; j < i; j++)
                        buffer += strRawValue.charAt(j);
                    nLastWrappingIndex = -1;
                    curLineLen = 0
                }
                buffer += curChar;
                newText = newText.substr(0, newText.length - buffer.length);
                newText += "\n" + buffer;
            }
        }
        return (newText)
    }
    const [tileFactory, setTileFactory] = React.useState({ open: false, cb: null })

    function drawTileText(point, name, lineFont, color, control, tSize) {
        var font = mylocalStorage.getItem("font")
        font = font ? font : "Roboto"
        var nameSet = ""
        if ((control.makeCards || control.makeMatchCards) && name.length > 7) {
            nameSet = applybreaks(name)
            tSize = 3
            if (name.length > 23) gText = 2
        } else {
            nameSet = name
            tSize = 5
        }
        var fontcolor = "#ffffff"
        if (control.fontColorBlack) {
            fontcolor = "#000000"
        }
        if (lineFont) {
            fontcolor = lineFont
        } else if (control.makeCards || control.makeMatchCards) {
            fontcolor = color
        }

        var multi = 9
        if (font === "Roboto") multi = 5
        var fsize = tSize * multi
        var text = new paper.PointText(point);
        text.content = nameSet
        while (fsize > 1) {
            text.style = {
                fontFamily: font,
                fontSize: fsize,
                fillColor: fontcolor,
                justification: 'center'
            };
            text.data.playingType = "text"
            let ff = text.bounds

            const cardwidth = 140
            const cardHeight = 190

            if (ff.height > cardHeight || ff.width > cardwidth) {
                fsize--
            } else {
                break
            }
        }

        return text
    }

    async function drawASLTile(name, color, lineFont, index, control, pairID = null) {
        var fname = name
        if (name.includes(",")) {
            fname = name.split(",")[0]
        }

        var g1 = await makeSignTile(fname, tileBox.x, tileBox.y, color)
        g1.data.tile = true
        g1.data.tileValue = name
        g1.data.pdfText = name
        for (let r in control) {
            g1.data[r] = control[r]
        }
        if (g1.data.studentMove) {
            g1.data.studentMoveParentID = uuid()
        }
        setTilebox(g1)
        updateDrawPaperObj(g1, donePaper)
        function donePaper(nObj) {
            g1.remove()
        }
    }
    function drawTile(name, color, lineFont, index, control, pairID = null) {
        var diff = 20
        const id = uuid()

        //var point = new Point(200 + (diff * col), 200 + (row * diff))
        var point = new Point(tileBox.x + diff, tileBox.y)
        var fname = name
        if (control.makeCards) {
            if (name.includes(";")) {
                fname = name.split(";")[0]
            }
        }
        var text = drawTileText(point, fname, lineFont, color, control, gText)
        var ff = text.bounds
        const EXTRA = 20
        var rr = {}
        rr.x = ff.x - EXTRA
        rr.y = ff.y - EXTRA

        rr.height = ff.height + (EXTRA * 2)
        rr.width = ff.width + (EXTRA * 2)
        var obj2 = []

        var rectanglePath2 = null
        var cornerSize = new paper.Size(10, 10);

        if (control.makeCards || control.makeMatchCards) {
            const cardwidth = 140
            const cardHeight = 190

            if (ff.height > cardHeight || ff.width > cardwidth) {
                text.remove()
                alert("This line is really long " + name)
                // return
            }
            var diffx = cardwidth - ff.width
            var diffy = cardHeight - ff.height
            rr.width = cardwidth;
            rr.height = cardHeight

            rr.x = ff.x - diffx / 2
            rr.y = ff.y - diffy / 2
            var oldht = rr.height
            rr.height = 20
            var rrT2 = new paper.Rectangle(rr)

            rr.height = oldht
            rectanglePath2 = new Path.Rectangle(rrT2, cornerSize);
            rectanglePath2.fillColor = color

            rectanglePath2.data.playingCard = { faceup: true }
            rectanglePath2.data.linkData = { type: "playingcard", id: id }
            rectanglePath2.data.linkDataButtonDraw = true
            rectanglePath2.data.linkButton = true
        } else {
            //make square
            if (name.length <= 2) {
                if (rr.height > rr.width) rr.width = rr.height
                if (rr.width > rr.height) rr.height = rr.width
            }
        }
        var rrT = new paper.Rectangle(rr)
        let rectanglePath = new Path.Rectangle(rrT, cornerSize);
        rectanglePath.insertBelow(text);
        rectanglePath.strokeWidth = 3
        obj2.push(rectanglePath)
        obj2.push(text)

        if (rectanglePath2 !== null) obj2.push(rectanglePath2)
        if (control.makeCards || control.makeMatchCards) {
            rectanglePath.strokeColor = color
            rectanglePath.fillColor = "#ffffff"

            if (control.makeMatchCards) {
                var f11 = rectanglePath.bounds
                var star = selectDrawStar(f11.bottomRight.x, f11.topLeft.y, color)
                star.data.selectStar = true
                star.visible = false
                obj2.push(star)
                dcard()
            } else {
                if (name.includes(";")) {
                    fname = name.split(";")[1]
                    let text2 = drawTileText(point, fname, lineFont, color, control, gText)
                    text2.data.playingType = "back"
                    text2.visible = false
                    obj2.push(text2)
                    dcard()
                } else {
                    var img = "/animals/004-bear.png"
                    var raster = new paper.Raster({ crossOrigin: 'anonymous', source: img, });
                    raster.onLoad = function () {
                        var b = raster.bounds
                        var diffx = rr.width - b.width
                        var diffy = rr.height - b.height

                        let rx = rr.x + diffx / 2 + b.width / 2
                        let ry = rr.y + diffy / 2 + b.height / 2
                        raster.position = new Point(rx, ry)
                        raster.visible = false
                        raster.data.playingType = "back"
                        obj2.push(raster)
                        dcard()
                    }
                }
            }
        } else {
            rectanglePath.fillColor = color
            rectanglePath.strokeColor = "#3174F5"
            dcard()
        }
        function dcard() {
            var g1 = new paper.Group(obj2)
            g1.data.tile = true
            g1.data.tileValue = name
            g1.data.pdfText = name
            if (control.makeCards || control.makeMatchCards) {
                g1.data.playingCard = { faceup: true }
                g1.data.linkDataButtonDraw = true
                g1.data.linkButton = true
                g1.data.linkData = { type: "playingcardOut", id: id }
                if (control.makeMatchCards) {
                    g1.data.playingCard = { faceup: true, pairID: pairID, id: id }
                    g1.data.linkData = { type: "playingCardPair", id: id }
                    rectanglePath2.data.linkData = { type: "playingCardPair", id: id }
                    // g1.children.forEach(c => {
                    //     c.data.inkDataButtonDraw = true
                    //     c.data.linkButton = true
                    //     c.data.playingCard = { faceup: true, pairID: pairID, id: id}
                    //     c.data.linkData = { type: "playingCardPair", id: id }
                    // })
                }
            }
            if (name.length === 0) {
                g1.data.sticky = true
            }
            var bounds = g1.bounds;
            var xdiff = bounds.center.x - bounds.topLeft.x
            var ydiff = bounds.center.y - bounds.topLeft.y

            g1.position = tileBox.x + diff + xdiff
            g1.position.y = tileBox.y + ydiff
            for (let r in control) {
                g1.data[r] = control[r]
            }
            if (g1.data.studentMove) {
                g1.data.studentMoveParentID = uuid()
            }
            setTilebox(g1)
            updateDrawPaperObj(g1, donePaper)
            function donePaper(nObj) {
                obj2.forEach(o => {
                    o.remove()
                })
            }
        }
    }

    function selectDrawStar(x, y, color) {
        var pt = new Point(x, y)
        var myCircle = new Path.Circle(pt, 30);
        myCircle.fillColor = color
        var triangle = new Path.RegularPolygon(pt, 3, 20);
        triangle.fillColor = 'gold';
        var t2nd = triangle.clone()
        t2nd.position = new Point(x, y + 3)
        t2nd.scale(1, -1)
        return (new paper.Group([myCircle, triangle, t2nd]))
    }
    function SetStarVisible(o, v) {
        o.children.forEach((c) => {
            if (c.data.selectStar)
                c.visible = v
        })
    }
    var OtherPair = null
    function cardPairHit(e, evt) {
        var par = e.parent
        if (!par || !par.data.playingCard || !par.data.id) return
        var pid = par.data.id
        if (!paperObj[pid] || !paperObj[pid].gqlObj) return
        var gql = paperObj[pid].gqlObj
        if (gql.SessionID !== gSessionID && !gTeacher && !par.data.studentMove) return
        var newOne = false
        if (gql.SessionID !== gSessionID && par.data.studentMove) {
            newOne = true
            var ff = par.clone()
            ff.data.id = null
            par.remove()

            ff.data.studentMove = false
            ff.data.studentMoveParent = par.data.studentMoveParentID;
            ff.data.cannotdelete = true
            ff.data.fixSize = par.data.fixSize;
            par = ff
        }
        var pairFound = false
        if (OtherPair) {
            if (OtherPair.data.playingCard.pairID === par.data.playingCard.pairID &&
                OtherPair.data.playingCard.id !== par.data.playingCard.id) {
                pairFound = true
            } else {
                SetStarVisible(OtherPair, false)
                updateDrawPaperGqlObj(paperObj[OtherPair.data.id].gqlObj, OtherPair, null)
            }
        }
        if (pairFound) {
            par.children.forEach((c) => {
                c.visible = false
            })
            par.visible = false
            OtherPair.children.forEach((c) => {
                c.visible = false
            })
            OtherPair.visible = false
            updateDrawPaperGqlObj(paperObj[OtherPair.data.id].gqlObj, OtherPair, null)
            OtherPair.remove()
        }
        if (newOne) {
            updateDrawPaperObj(par, done)
        } else {
            updateDrawPaperGqlObj(paperObj[pid].gqlObj, par, done)
        }
        function done(e) {
            par.remove()
            if (!pairFound) {
                OtherPair = e
                SetStarVisible(OtherPair, true)
                updateDrawPaperGqlObj(paperObj[OtherPair.data.id].gqlObj, OtherPair, null)
            }
        }
    }

    function cardHit(e, evt) {
        var par = e.parent
        var pid = par.data.id
        if (!par || !par.data.playingCard) return
        if (!paperObj[pid] || !paperObj[pid].gqlObj) return
        var gql = paperObj[pid].gqlObj
        if (gql.SessionID !== gSessionID && !gTeacher && !par.data.studentMove) return
        var newOne = false
        if (gql.SessionID !== gSessionID && par.data.studentMove) {
            newOne = true
            var ff = par.clone()
            ff.data.id = null
            par.remove()

            ff.data.studentMove = false
            ff.data.studentMoveParent = par.data.studentMoveParentID;
            ff.data.cannotdelete = true
            ff.data.fixSize = par.data.fixSize;
            par = ff
        }

        var faceup = !par.data.playingCard.faceup

        par.children.forEach((c) => {
            //c.selected = true
            if (c.data.playingType === "back") {
                if (faceup)
                    c.visible = false
                else
                    c.visible = true
            }
            if (c.data.playingType === "text") {
                if (faceup)
                    c.visible = true
                else
                    c.visible = false
            }
        })
        par.data.playingCard.faceup = faceup
        if (newOne) {
            updateDrawPaperObj(par, null)
        } else {
            updateDrawPaperGqlObj(paperObj[pid].gqlObj, par, null)
            par.remove()
        }
    }
    function TileFactory() {
        setTileFactory({ open: true, cb: done })
        async function done(e) {
            if (!e) {
                setTileFactory({ open: false, cb: null })
                return
            }
            var tiles = e.text.split('\n')
            for (var i in tiles) {
                var tile = tiles[i]
                let cc = tile.split(',')
                var name = cc[0]
                var color = cc[1] ? cc[1].trim() : ctx.color
                var lineFont = cc[2] ? cc[2].trim() : ((e.control && e.control.fontColorBlack) === true ? '#000' : null)
                if (e.control.makeMatchCards) {
                    var ffn = name.split(";")
                    if (ffn.length > 1) {
                        const id = uuid()
                        drawTile(ffn[0], color, lineFont, i, e.control, id)
                        drawTile(ffn[1], color, lineFont, i, e.control, id)
                    }
                } else if (e.control.makeASLTile) {
                    await drawASLTile(name, color, lineFont, i, e.control, null)
                } else {
                    drawTile(name, color, lineFont, i, e.control, null)
                }
            }
        }
    }

    function drawForm(e) {
        const TOPX = formBox.x + 50
        const TOPY = formBox.y
        var sx = TOPX
        var sy = TOPY
        const id = uuid()
        var os = e.text.split('\n')
        function drawText(nameSet, col, sz) {
            var font = mylocalStorage.getItem("font")
            font = font ? font : "Roboto"

            var text = new paper.PointText(new paper.Point(sx, sy + 10));
            text.content = nameSet
            text.style = {
                fontFamily: font,
                fontSize: sz,
                fillColor: col,
                justification: 'left'
            };
            return text
        }
        var obj = []
        var counter = 0
        for (var i in os) {
            var ln = os[i]
            counter++
            var col = ctx.SavedColor
            if (ln.includes(",")) {
                let l = ln.split(",")
                ln = l[0]
                col = l[1]
            }
            var f = ln.split(';')
            if (f.length < 2) continue
            if (f[0] === "t") {
                var rr = {
                    x: sx,
                    y: sy,
                    height: 150,
                    width: 300,
                }
                var rrT = new Path.Rectangle(rr)
                rrT.strokeColor = col
                rrT.width = 1
                rrT.dashArray = [2, 2]
                rrT.fillColor = mkColor(ctx.SavedColor, 2)
                var phText = f[1] ? f[1] : "enter text"
                rrT.data.linkData = {
                    type: "form", formType: "text", id: id, placeholder: phText,
                    value: phText + "-" + counter, display: phText
                }
                rrT.data.linkDataButtonDraw = true
                rrT.data.linkButton = true

                sy = sy + 20
                let t = drawText(phText, col, 21)
                t.data.linkData = {
                    type: "form", formType: "textPlace", placeholder: phText,
                    id: id, value: phText + "-" + counter, display: phText
                }
                sy = sy + 120

                t.data.linkDataButtonDraw = true
                t.data.linkButton = true
                obj.push(rrT)
                obj.push(t)
            }
            if (f[0] === "r") {
                const RADIUS = 20;
                var myCircle = new Path.Circle(new Point(sx, sy), RADIUS);
                sx = sx + 50
                myCircle.strokeColor = col
                myCircle.fillColor = mkColor(ctx.SavedColor, 5)
                myCircle.data.tcolor = myCircle.fillColor
                myCircle.data.linkData = { type: "form", formType: "radio", id: id, value: f[1] + "-" + counter, display: f[1] }
                myCircle.data.linkDataButtonDraw = true
                myCircle.data.linkButton = true

                let t = drawText(f[1], col, 30)
                t.data.linkData = { type: "form", formType: "radiotext", id: id, value: f[1] + "-" + counter, display: f[1] }
                t.data.linkDataButtonDraw = true
                t.data.linkButton = true
                obj.push(myCircle)
                obj.push(t)
            }
            if (f[0] === "c") {
                var cornerSize = new paper.Size(2, 2);
                var rr = {
                    x: sx - 15,
                    y: sy - 15,
                    height: 30,
                    width: 30,
                }
                var rrT = new paper.Rectangle(rr)

                let rectanglePath = new Path.Rectangle(rrT, cornerSize)
                sx = sx + 50
                rectanglePath.strokeColor = col
                rectanglePath.fillColor = mkColor(ctx.SavedColor, 5)
                rectanglePath.data.tcolor = rectanglePath.fillColor
                rectanglePath.data.linkData = { type: "form", formType: "checkbox", id: id, value: f[1] + "-" + counter, display: f[1] }
                rectanglePath.data.linkDataButtonDraw = true
                rectanglePath.data.linkButton = true
                obj.push(rectanglePath)

                let t = drawText(f[1], col, 30)
                t.data.linkData = { type: "form", formType: "checkboxtext", id: id, value: f[1] + "-" + counter, display: f[1] }
                t.data.linkDataButtonDraw = true
                t.data.linkButton = true
                obj.push(t)
            }
            if (f[0] === "l") {
                let t = drawText(f[1], col, 30)
                obj.push(t)
            }
            sy += 50
            sx = TOPX
        }
        var g1 = new paper.Group(obj)
        g1.data.formGroup = { type: "form", id: id }
        obj = [g1]
        var f2 = g1.bounds
        rr = {}
        rr.x = f2.x - 10
        rr.y = f2.y - 10
        rr.height = f2.height + 20
        rr.width = f2.width + 20
        let rectanglePath = new Path.Rectangle(rr);
        rectanglePath.strokeColor = ctx.SavedColor;
        rectanglePath.strokeWidth = 1
        obj.push(rectanglePath)

        var x1 = rectanglePath.bounds.bottomRight
        rr = {
            x: x1.x - 90,
            y: x1.y + 10,
            height: 30,
            width: 90,
        }
        let link = { type: "form", formType: "submit", id: id }
        let b = createButtonCommon(g1, link, "Submit", rr)
        obj.push(b)

        x1 = rectanglePath.bounds.bottomLeft
        rr = {
            x: x1.x,
            y: x1.y + 10,
            height: 30,
            width: 90,
        }
        link = { type: "form", formType: "results", id: id }
        b = createButtonCommon(g1, link, "Results", rr)
        obj.push(b)

        g1 = new paper.Group(obj)
        g1.data.linkData = { type: "form", formType: "GROUP", id: id }
        g1.data.linkDataButtonDraw = true
        g1.data.studentMove = true
        g1.data.studentMoveParentID = id
        g1.data.rawForm = e
        g1.data.pdfText = e.text
        setTilebox(g1)
        updateDrawPaperObj(g1, donePaper)
        function donePaper(nObj) {
            obj.forEach((o) => {
                o.remove()
            })
        }
    }
    const [formFactory, setFormFactory] = React.useState({ open: false, cb: null })
    function FormFactory() {
        setFormFactory({ open: true, cb: done })
        function done(e) {
            if (!e) {
                setFormFactory({ open: false, cb: null })
                return
            }
            drawForm(e)
        }
    }
    const [magicWriteDialog, setMagicWriteDialog] = React.useState({ open: false, cb: null, objs: [] })

    const [handWriteDialog, setHandWriteDialog] = React.useState({ open: false, cb: null })
    function HandWrite() {
        handleEscape()
        createMagicDraw()
        ctx.canvas.interface.style.cursor = "auto"
        ctx.mode = "magicHand"
        setdrawMode({ name: 'draw' })
        ctx.color = ctx.SavedColor
        ctx.brushRadius = ctx.drawBrushRadius
        if (ctx.slider && ctx.slider.brush && ctx.slider.brush.value)
            ctx.slider.brush.value = ctx.brushRadius
        seteraseMode(false)
    }

    const [formResults, setFormResults] = React.useState({ open: false, cb: null, obj: null })
    function OpenFormResults(o) {
        setFormResults({ open: true, cb: done, obj: o, shareCB: shareCB })
        function done(e) {
            if (!e) {
                setFormResults({ open: false, cb: null })
                return
            }
        }
        function shareCB(args) {
            let col = ctx.SavedColor;

            var f = args.obj.clone()
            var removals = []
            f.position.x = formBox.x + 50 + f.bounds.width / 2
            f.position.y = formBox.y + f.bounds.height / 2
            function drawTextName() {
                var font = "Roboto"
                let sx = f.bounds.topLeft
                let sy = f.bounds.topLeft - 30;
                var text = new paper.PointText(new paper.Point(sx, sy));
                text.content = args.result.name
                text.style = {
                    fontFamily: font,
                    fontSize: 18,
                    fillColor: col,
                    justification: 'left'
                };
                return text
            }
            // get color first
            for (let f1 in args.result.form) {
                let ppp = args.result.form[f1]
                if (ppp.type === 'color') {
                    col = ppp.value
                }
            }
            f.children.forEach(c => {
                if (c.data.formGroup) {
                    c.children.forEach(inc => {
                        for (let f1 in args.result.form) {
                            let ppp = args.result.form[f1]
                            if (ppp.set && inc.data.linkData && ppp.value === inc.data.linkData.value) {
                                if (inc.data.linkData.formType === "textPlace") {
                                    inc.content = ppp.set
                                    inc.fillColor = col
                                }
                                if (inc.data.linkData.formType === "checkbox") {
                                    inc.fillColor = col;
                                }
                                if (inc.data.linkData.formType === "radio") {
                                    inc.fillColor = col;
                                }
                            }
                        }
                    })
                } else {
                    if (c.data.linkData && c.data.linkData.formType) {
                        if (c.data.linkData.formType === "results" || c.data.linkData.formType === "submit") {
                            if (c.children)
                                c.children.forEach(inc => {
                                    removals.push(inc)
                                })
                            removals.push(c)
                        }
                    } else {
                        c.fillColor = mkColor(col, 4)
                    }
                }
            })
            for (let i = 0; i < removals.length; i++) {
                let r = removals[i]
                r.remove()
            }

            setTilebox(f)
            delete f.data['studentMove']
            delete f.data['linkData']
            delete f.data['studentMoveParentID']
            delete f.data['linkDataButtonDraw']

            let t = drawTextName()
            f.addChild(t)
            updateDrawPaperObj(f, done)
            function done() {
                f.remove()
            }

        }
    }
    function formHit(e, evt) {
        let f = e
        var p = e.parent
        while (!p.data.id) {
            p = p.parent
            if (!p) return
        }
        var pid = p.data.id
        var gql = paperObj[pid].gqlObj
        var newOne = false
        if (gql.SessionID !== gSessionID && p.data.studentMove) {
            newOne = true
            p.remove()
            var ff = p.clone()
            p = ff
            ff.data.id = null
            ff.data.studentMove = false
            ff.data.studentMoveParent = p.data.studentMoveParentID;
            ff.data.cannotdelete = true
            ff.data.fixSize = p.data.fixSize;
            var i2 = JSON.stringify(e.data.linkData)
            var found = false
            p.children.forEach(c => {
                if (c.data.linkData && ib.deepCompare(c.data.linkData, e.data.linkData)) {
                    //update the clicked one to the clone of it
                    found = true
                    e = c
                    f = e
                }
                if (!found && c.data.formGroup) {
                    c.children.forEach(inc => {
                        if (inc.data.linkData && ib.deepCompare(inc.data.linkData, e.data.linkData)) {
                            //update the clicked one to the clone of it
                            found = true
                            e = inc
                            f = e
                        }
                    })
                }
            })
        }
        if (f.data.linkData && f.data.linkData.formType === "results") {
            if (gTeacher) OpenFormResults(p)
            return
        }
        if (f.data.linkData && f.data.linkData.formType === "submit") {
            setTimeout(handleFormSubmit, 600, e, p);
            function handleFormSubmit(e, p) {
                //find the right form to submit 
                var sel = []
                e.fillColor = "#000000"
                p.children.forEach(pc => {
                    if (pc.data.formGroup) {
                        pc.children.forEach(c => {
                            if (c.data.linkData && c.data.linkData.cvalue) {
                                sel.push({
                                    type: c.data.linkData.formType,
                                    val: c.data.linkData.value, set: c.data.linkData.cvalue
                                })
                            }
                        })
                    }
                })
                sel.push({ type: 'color', val: ctx.SavedColor })
                ib.sendFormSubmit(f.data.linkData.id, gSession, myName, sel)
            }
            return
        }
        function editText() {
            setShowTextTools(true)
            function doneText(e) {
                if (!e) return
                newOne = false
                pid = savePos.tbdata.parent.data.id
                savePos.tbdata.obj.content = e
                savePos.tbdata.obj.fillColor = ctx.SavedColor
                savePos.tbdata.obj.data.linkData.cvalue = e
                done()
                return
            }
            var drawCanvasPos = getElPosition(document.getElementById("canvas_drawing"))
            p.children.forEach(pc => {
                if (pc.data.formGroup) {
                    pc.children.forEach(c => {
                        if (c.data.linkData && c.data.linkData.formType === "textPlace" &&
                            f.data.linkData.value === c.data.linkData.value) {
                            if (inpBox) closeInput()
                            var msg = ""
                            if (c.data.linkData.placeholder !== c.content) msg = c.content
                            savePos = {
                                x: c.bounds.topLeft.x,
                                y: c.bounds.topLeft.y,
                                sz: 4,
                                tbdata: { obj: c, tm: new Date(), cb: doneText }
                            }
                            if (msg !== "") inValue = { text: msg }
                            var sy = ctx.canvasContainer.scrollTop
                            var sx = ctx.canvasContainer.scrollLeft
                            // var yoff = (e.data.linkData.fontsz - 6) * 6

                            var pageX = savePos.x - sx + 5
                            var pageY = savePos.y - sy - 14
                            c.content = ""
                            if (newOne) {
                                updateDrawPaperObj(p, openinput)
                            } else {
                                openinput(p)
                            }
                            function openinput(o) {
                                if (newOne) {
                                    p.remove()
                                }
                                savePos.tbdata.parent = o
                                addInput(pageX + drawCanvasPos.x, pageY + drawCanvasPos.y);
                            }
                            return
                        }
                    })
                }
            })
        }

        if (f.data.linkData && f.data.linkData.formType === "text") {
            editText()
            return
        }
        if (f.data.linkData && f.data.linkData.formType === "textPlace") {
            editText()
            return
        }
        if (f.data.linkData && f.data.linkData.formType === "checkbox") {
            if (f.data.linkData.cvalue) {
                f.data.linkData.cvalue = false
                f.fillColor = f.data.tcolor
            } else {
                f.data.linkData.cvalue = true
                f.fillColor = ctx.SavedColor
            }
        }
        if (f.data.linkData && f.data.linkData.formType === "checkboxtext") {
            f.parent.children.forEach(c => {
                if (c.data.linkData && c.data.linkData.formType === "checkbox" &&
                    c.data.linkData.value === f.data.linkData.value) {
                    if (c.data.linkData.cvalue) {
                        c.data.linkData.cvalue = false
                        c.fillColor = c.data.tcolor
                    } else {
                        c.data.linkData.cvalue = true
                        c.fillColor = ctx.SavedColor
                    }
                }
            })
        }
        //radio
        if (f.data.linkData && f.data.linkData.formType === "radio") {
            if (f.data.linkData.cvalue) {
                f.data.linkData.cvalue = false
                f.fillColor = f.data.tcolor
            } else {
                f.parent.children.forEach(c => {
                    if (c.data.linkData && c.data.linkData.formType === "radio" && c.data.linkData.cvalue) {
                        c.data.linkData.cvalue = false
                        c.fillColor = c.data.tcolor
                    }
                })
                f.data.linkData.cvalue = true
                f.fillColor = ctx.SavedColor
                //unselect any other radio
            }
        }
        if (f.data.linkData && f.data.linkData.formType === "radiotext") {
            f.parent.children.forEach(c => {
                if (c.data.linkData && c.data.linkData.formType === "radio" &&
                    c.data.linkData.value === f.data.linkData.value) {
                    if (c.data.linkData && c.data.linkData.cvalue) {
                        c.data.linkData.cvalue = false
                        c.fillColor = c.data.tcolor
                    } else {
                        f.parent.children.forEach(c => {
                            if (c.data.linkData && c.data.linkData.formType === "radio" &&
                                c.data.linkData.cvalue) {
                                c.data.linkData.cvalue = false
                                c.fillColor = c.data.tcolor
                            }
                        })
                        c.data.linkData.cvalue = true
                        c.fillColor = ctx.SavedColor
                    }
                }
            })
        }
        function done() {
            if (newOne) {
                updateDrawPaperObj(p, null)
                p.remove()
            } else {
                if (paperObj[pid].gqlObj.SessionID === gSessionID) {
                    updateDrawPaperGqlObj(paperObj[pid].gqlObj, p, doneD)
                    function doneD() {
                        p.remove()
                    }
                } else {
                    console.log("NOT MINE CANNOT UPD")
                }
            }
        }
        done()
    }
    function basicCalc() {
        var calc = cc.drawCalc(inkColor)
        updateDrawPaperObj(calc, donePaper)
        function donePaper(nObj) {
            calc.remove()
        }
    }
    function trimGetBound(c) {
        var ctx = c.getContext('2d'),
            pixels = ctx.getImageData(0, 0, c.width, c.height),
            l = pixels.data.length,
            i,
            bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            },
            x, y;
        for (i = 0; i < l; i += 4) {
            if (pixels.data[i + 3] !== 0) {
                x = (i / 4) % c.width;
                y = ~~((i / 4) / c.width);
                if (bound.top === null) {
                    bound.top = y;
                }
                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }
                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }
                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            } else {
                // if (i % 10000 === 0) {
                //     console.log("I IS", i, pixels.data[i + 3])
                // }
            }
        }
        return (bound)
    }

    function trim(c) {
        var bound = trimGetBound(c)
        var ctx = c.getContext('2d');
        // var copy = document.createElement('canvas').getContext('2d');
        var trimHeight = bound.bottom - bound.top,
            trimWidth = bound.right - bound.left;
        // var left = new paper.Point(bound.left, bound.top)
        // var right = new paper.Point(bound.right, bound.bottom)
        // let rr = new paper.Path.Rectangle(left, right)
        // rr.fillColor = "#F0DC00";

        var trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
        return { img: trimmed, width: trimWidth, height: trimHeight }
        // var trimHeight = bound.bottom - bound.top,
        //     trimWidth = bound.right - bound.left,
        //     trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
        // // trimWidth = trimWidth > 1280 ? trimWidth : 1280;
        // // trimHeight = trimHeight > 800 ? trimHeight : 800
        // // copy.fillStyle = "#ffffff";
        // console.log(bound, trimWidth, trimHeight)

        // // copy.fillRect(0, 0, trimWidth, trimHeight);
        // copy.putImageData(trimmed, 0, 0);
        // // open new window with trimmed image:
        // return copy.canvas;
    }
    async function downloadThisPage() {
        handleEscape()

        aniMess("Creating PDF to download.")
        var mode = gResolution && gResolution === "a4" ? "p" : "l"
        var pdf = new jsPDF(mode, 'pt', 'a4');
        const srcWidth = ctx.canvas.drawing.width
        const srcHeight = ctx.canvas.drawing.height

        var destinationCanvas = document.createElement("canvas");
        destinationCanvas.width = srcWidth;
        destinationCanvas.height = srcHeight;
        var destCtx = destinationCanvas.getContext('2d');

        destCtx.fillStyle = "#FFFFFF";
        destCtx.fillRect(0, 0, srcWidth, srcHeight);
        destCtx.drawImage(ctx.canvas.drawing, 0, 0);

        var dt = destinationCanvas.toDataURL('image/jpeg', 0.7);
        const imgProps = pdf.getImageProperties(dt);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dt, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        var bdname = sess.name === "unsaved" ? "" : sess.name

        pdf.save("wb-c-" + bdname + ".pdf")

    }
    async function HandleDownload() {
        handleEscape()

        aniMess("Creating book to download.")
        const currPage = gSessionID
        const pgSplit = currPage.split("-pgNum-")
        const pages = await ib.getboardsbyparentSync(pgSplit[0])

        const srcWidth = ctx.canvas.drawing.width
        const srcHeight = ctx.canvas.drawing.height
        ctx.context.drawing.clearRect(0, 0, ctx.canvas.drawing.width, ctx.canvas.drawing.height)

        var destinationCanvas = document.createElement("canvas");
        destinationCanvas.width = srcWidth;
        destinationCanvas.height = srcHeight;
        var destCtx = destinationCanvas.getContext('2d');

        var mode = gResolution && gResolution === "a4" ? "p" : "l"
        var pdf = new jsPDF(mode, 'pt', 'a4');

        for (let i = 0; i < pages.length; i++) {
            clearGlobal()
            clearCanvas()
            destCtx.clearRect(0, 0, srcWidth, srcHeight)

            //create a rectangle with the desired color
            destCtx.fillStyle = "#FFFFFF";
            destCtx.fillRect(0, 0, srcWidth, srcHeight);

            const page = pages[i]
            gSessionID = page.id
            var objs = await ib.listObjectSync(page.id)
            if (objs.length > 0) {
                gotData(objs)
            }
            if (page.parentBoardID && page.parentBoardID !== page.id) {
                objs = await ib.listObjectSync(page.parentBoardID)
                if (objs.length > 0) {
                    gotData(objs)
                }
            }
            paper.project._needsUpdate = true;
            paper.project.view.update();
            await new Promise(r => setTimeout(r, 2000));

            //draw the original canvas onto the destination canvas
            destCtx.drawImage(ctx.canvas.drawing, 0, 0);

            var dt = destinationCanvas.toDataURL('image/jpeg', 0.7);

            // var dt = ctx.canvas.drawing.toDataURL('image/jpeg', 0.7);
            const imgProps = pdf.getImageProperties(dt);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(dt, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            if (i !== (pages.length - 1)) {
                pdf.addPage('a4', mode)
            }
        }
        var bdname = pages[0].name === "unsaved" ? "" : pages[0].name
        pdf.save("wb-c-" + bdname + ".pdf")
        window.location.reload();
    }


    async function HandleExport(local, url, instance) {
        var instanceObj = { local, url, instance }
        if (teacherR === 0) {
            return
        }
        aniMessage = false //this is to reset if snackbar was set true i.e. not to showup again

        handleEscape()
        var jsonObjt = []
        var boardName = null
        const currPage = gSessionID
        const pgSplit = currPage.split("-pgNum-")
        const pages = await ib.getboardsbyparentSync(pgSplit[0])
        if (pages && pages.length > 0) {
            if (pages[0] && pages[0].name === "unsaved") {
                aniMess("Please name the Board, Retry Export.")
                return
            } else {
                boardName = pages[0].name
            }
            var sessionObj = { objType: 'session', data: pages }
            jsonObjt.push(sessionObj)
        }
        var allObjects = []
        // aniMess("Exporting book.")
        for (let i = 0; i < pages.length; i++) {

            const page = pages[i]
            gSessionID = page.id
            var objs = await ib.listObjectSync(page.id)
            allObjects.push(...objs)
            // console.log('objects', objs)
            if (objs && objs.length > 0) {
                // addObjects(jsonObjt,objs)
            }
            // console.log('objects stringify', JSON.stringify(objs))
            if (page.parentBoardID && page.parentBoardID !== page.id) {
                var objs1 = await ib.listObjectSync(page.parentBoardID)
                if (objs1.length > 0) {
                    allObjects.push(...objs1)
                    // console.log('oparentboardid objects', objs)
                }
            }

        }
        if (allObjects && allObjects.length > 0) {
            addObjects(jsonObjt, allObjects, boardName, instanceObj)
        }
    }


    function addObjects(jsonObjt, objs, boardName, instanceObj) {
        var objectsObj = { objType: 'objects', data: objs }
        jsonObjt.push(objectsObj)
        var promiseArr = [];
        var filterobj1 = jsonObjt.filter(j => j.objType === 'objects')
        if (filterobj1 && filterobj1[0].data && filterobj1[0].data.length > 0) {
            filterobj1[0].data.forEach(element => {
                try {

                    if (element.objType === "image") {
                        const ct = JSON.parse(element.content)
                        var url = ct.url
                        var promise1 = new Promise((resolve, reject) => {
                            iu.GetImage(url).then((ff) => {
                                var raster = new paper.Raster({ source: ff.img, crossOrigin: 'anonymous' });
                                raster.onLoad = function () {
                                    var xx = raster._size.width / 2
                                    var yy = raster._size.height / 2
                                    raster.position = new Point(xx + 60, yy + 40)
                                    let imgB64 = raster.toDataURL('image/jpeg')
                                    let fileName = element.id + ' image'
                                    element.fileName = fileName
                                    zip.file(fileName, imgB64);
                                    resolve(element)
                                }
                                raster.onError = function () {
                                    console.log('onError', url)
                                    reject('Promise is rejected at getImage')
                                }
                            })
                        })
                        promiseArr.push(promise1)
                    } else if (element.objType === "drawPaper") {
                        var content = element.content
                        var eleType = JSON.parse(element.type)
                        if (eleType && eleType.compressed) {
                            content = pako.inflate(element.content, { to: 'string' });
                        }
                        const ct = JSON.parse(content)
                        var pobj = new paper[ct[0]]()
                        if (ct[0] === "Raster") {
                            try {
                                const ff = JSON.parse(content)
                                const rrt = ff[1].source.split("?X-Amz")[0]
                                ff[1].source = rrt
                                content = JSON.stringify(ff)
                            } catch (e) {
                                console.error("ERROR IS ", e)
                            }
                        }
                        pobj.data.id = element.id
                        pobj.importJSON(content)
                        var resolvedProm2 = null
                        var promise2 = null
                        if (ct[0] === "Raster") {
                            if (ct[1] && ct[1].data.palette) {
                                promise2 = new Promise((resolve, reject) => {
                                    pobj.onLoad = function (f) {
                                        //this is for drawPaper obj but inbuilt stickers/objects
                                        resolve()
                                    }
                                    resolvedProm2 = resolve
                                });
                                promiseArr.push(promise2)
                            } else {
                                pobj.onLoad = loadS3File
                            }
                            pobj.onError = loadS3File
                            function loadS3File(f) {
                                //this is for drawPaper objects i.e. added img is moved or resized
                                // cross domain failures for images?
                                //promise2 resolve it if it is added for the same object
                                if (ct[1] && ct[1].data.palette) {
                                    promiseArr = promiseArr.filter(p => p !== promise2)
                                    resolvedProm2()
                                }

                                // cropped/copied object
                                var isCroppedOrCopied = false
                                if (typeof (ct[1].source) === 'string') {
                                    isCroppedOrCopied = ct[1].source.includes('data:image/png;base64')
                                    if (isCroppedOrCopied) {
                                        var promiseCC = new Promise((resolve, reject) => {
                                            let bs64 = ct[1].source
                                            let fileName = element.id + ' ' + 'image'
                                            element.fileName = fileName
                                            zip.file(fileName, bs64);
                                            resolve()
                                        });
                                        promiseArr.push(promiseCC)
                                        return
                                    }
                                }
                                const ff = JSON.parse(content)
                                var promise3 = new Promise((resolve, reject) => {
                                    iu.GetS3File(ff[1].source).then((rr) => {
                                        ff[1].source = rr.img
                                        let mimeType = rr.img.ContentType
                                        let fileTypeName = ''
                                        let splitStr = mimeType.split('/')
                                        fileTypeName = splitStr[0]

                                        let fileName = element.id + ' ' + fileTypeName
                                        let blob = new Blob([rr.img.Body], { type: mimeType })
                                        if (mimeType === 'image/png' || mimeType === 'image/jpeg') {
                                            var reader = new FileReader();
                                            reader.readAsDataURL(blob);
                                            reader.onloadend = function () {
                                                var base64String = reader.result;
                                                let bs64 = base64String.substr(base64String.indexOf(', ') + 1)
                                                element.fileName = fileName
                                                zip.file(fileName, bs64);
                                                resolve()
                                            }
                                        } else {
                                            element.fileName = fileName
                                            zip.file(fileName, blob);
                                            resolve()
                                        }
                                    })
                                })
                                promiseArr.push(promise3)
                            }
                        } else {
                            if (ct[1] && ct[1].data) {
                                var mediaUrl = ct[1].data.linkData && ct[1].data.linkData.file
                                if (mediaUrl) {
                                    var promise4 = new Promise((resolve, reject) => {
                                        iu.GetS3File(mediaUrl).then((rr) => {
                                            let mimeType = rr.img.ContentType
                                            let fileTypeName = ''
                                            let splitStr = mimeType.split('/')
                                            fileTypeName = splitStr[0]
                                            let fileName = element.id + ' ' + fileTypeName
                                            let blob = new Blob([rr.img.Body], { type: mimeType })
                                            var reader = new FileReader();
                                            reader.readAsDataURL(blob);
                                            reader.onloadend = function () {
                                                var base64String = reader.result;
                                                let bs64 = base64String.substr(base64String.indexOf(', ') + 1)
                                                element.fileName = fileName
                                                zip.file(fileName, bs64);
                                                resolve()
                                            }

                                        })
                                    })
                                    promiseArr.push(promise4)
                                }

                            }
                        }
                    }
                } catch (error) {
                    console.log('Error while Export error msg:', error)
                }

            })

            Promise.all(promiseArr).then(values => {
                ExportBoards(jsonObjt, boardName, instanceObj)
            })
        }
    }


    function ExportBoards(jsonObjt, boardName, instanceObj) {

        function callAniMsg(msg) {
            setMessage(msg)
            try {
                ReactGA.event({
                    category: 'User',
                    action: 'Board exported to ' + instanceObj.instance
                });
            } catch { }
            setTimeout(() => window.location.reload(), 2000)
        }

        function copyErrorCB(msg) {
            setMessage(msg)
            try {
                ReactGA.event({
                    category: 'User',
                    action: 'Export to ' + instanceObj.instance + ' failed.'
                });
            } catch { }
            setTimeout(() => window.location.reload(), 2000)
        }

        if (!instanceObj.local) {
            setMessage("Exporting board, please wait ...")
            ib.copyBoard(jsonObjt, instanceObj, authUser.attributes.email, callAniMsg, copyErrorCB)
            // ib.copyBoard(jsonObjt, instanceObj, "nonexistent@fakeemail.com", callAniMsg, copyErrorCB)
            return
        }


        var data = JSON.stringify(jsonObjt)
        const filename = boardName + '.json';
        let zipFilename = 'Exported Board ' + boardName + '.wbc'
        var zipPromise = new Promise((resolve, reject) => {
            zip.file(filename, data);
            zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, zipFilename);
                resolve()
            });
        });
        try {
            ReactGA.event({
                category: 'User',
                action: 'BoardExported'
            });
        } catch { }
        zipPromise.then(values => {
            window.location.reload()
        })
    }

    async function HandleDownloadClass(separate, pageOnly) {
        var inPage = parseInt(pageOnly)
        handleEscape()
        var users = {}
        var localUsers = {}

        aniMess("Creating Class book to download.")
        const currPage = gSessionID
        const pgSplit = currPage.split("-pgNum-")
        var allPages = []
        const pages = await ib.getboardsbyparentSync(pgSplit[0])
        for (let i = 0; i < pages.length; i++) {
            checkUsers(pages[i])
            allPages.push({ page: pages[i] })
        }

        function checkUsers(p) {
            if (p.Users && p.Users.items && p.Users.items.length > 0) {
                p.Users.items.forEach((u) => {
                    if (u.content) {
                        try {
                            var pp = JSON.parse(u.content)
                            if (pp.localID) {
                                localUsers[pp.localID] = u.name
                            }
                        } catch {
                        }
                    }
                    if (p.parentID in users) {
                        if (users[p.parentID].indexOf(u.name) === -1) {
                            users[p.parentID] = users[p.parentID] + "," + u.name
                        }
                    } else {
                        users[p.parentID] = u.name
                    }
                })
            }
        }
        if (sess.Classroom) {
            let classPages = await ib.getSessionByClassroomSync(sess.Classroom)
            for (let i = 0; i < classPages.length; i++) {
                var cp = classPages[i]
                checkUsers(cp)
                if (cp.parentID === pgSplit[0]) continue
                allPages.push({ page: classPages[i] })
            }
        }
        allPages.sort(function (a, b) {
            if (a.page.parentID > b.page.parentID) {
                return 1
            } else if (a.page.parentID < b.page.parentID) {
                return -1
            }
            return 0
        })
        const srcWidth = ctx.canvas.drawing.width
        const srcHeight = ctx.canvas.drawing.height
        ctx.context.drawing.clearRect(0, 0, ctx.canvas.drawing.width, ctx.canvas.drawing.height)

        var destinationCanvas = document.createElement("canvas");
        var destinationCanvas2 = document.createElement("canvas");
        var destCtx2 = destinationCanvas2.getContext('2d');
        var destCtx = destinationCanvas.getContext('2d');

        var mode = gResolution && gResolution === "a4" ? "p" : "l"
        var pdf = new jsPDF(mode, 'pt', 'a4');

        function createHead(page) {
            var content = {
                "type": "text", "points": { "x": 20, "y": 20 }, "text": "Hello",
                "ended": true, "color": "#3174F5", "brushRadius": 4
            }
            var obj = {
                ended: true,
                objType: "text",
                animate: null,
                sess: page.id,
                SessionID: page.id,
                id: page.id + "temp",
                type: null
            }
            var nm = page.name.replace("unsaved:", "")
            var u = localUsers[page.CreatorLocalID] //users[page.parentID]
            var dt = new Date(page.createdAt)
            var text = "Created: " + dt.toLocaleString() + ","
            if (u) text = text + "Creator:" + u + ","
            if (page.name !== "unsaved") text = text + nm + " "
            if (page.pageNumber) text = text + "Page:" + page.pageNumber
            content.text = text
            obj.content = JSON.stringify(content)
            return obj
        }
        var savedparent = allPages[0].page.parentID
        var savedname = localUsers[allPages[0].page.CreatorLocalID] ? localUsers[allPages[0].page.CreatorLocalID] : "none"
        for (let i = 0; i < allPages.length; i++) {
            clearGlobal()
            clearCanvas()
            const page = allPages[i].page
            if (inPage !== 0 && page.pageNumber !== inPage) continue
            if (separate && page.parentID !== savedparent) {
                pdf.save("whiteboard-" + savedname + ".pdf")
                var mode = gResolution && gResolution === "a4" ? "p" : "l"
                var pdf = new jsPDF(mode, 'pt', 'a4');
                savedname = localUsers[page.CreatorLocalID] ? localUsers[page.CreatorLocalID] : "none"
                savedparent = page.parentID
            }
            gSessionID = page.id
            const hd = createHead(page)
            gotData([hd])
            var objs
            if (page.parentBoardID && page.parentBoardID !== page.id) {
                objs = await ib.listObjectSync(page.parentBoardID)
                if (objs.length > 0) {
                    gotData(objs)
                }
            }
            objs = await ib.listObjectSync(page.id)
            if (objs.length > 0) {
                gotData(objs)
            }
            paper.project._needsUpdate = true;
            paper.project.view.update();
            await new Promise(r => setTimeout(r, 2000));
            var img = trim(ctx.canvas.drawing)
            destCtx.clearRect(0, 0, srcWidth, srcHeight)
            destCtx2.clearRect(0, 0, srcWidth, srcHeight)
            destinationCanvas.width = img.width;
            destinationCanvas.height = img.height;
            destCtx.putImageData(img.img, 0, 0);

            destinationCanvas2.width = img.width;
            destinationCanvas2.height = img.height;
            //draw the original canvas onto the destination canvas
            destCtx2.fillStyle = "#ffffff";
            destCtx2.fillRect(0, 0, img.width, img.height);
            destCtx2.drawImage(destinationCanvas, 0, 0);

            var dt = destinationCanvas2.toDataURL('image/jpeg', 0.9);
            // document.write('<img src="'+dt+'"/>')
            // return
            // var dt = ctx.canvas.drawing.toDataURL('image/jpeg', 0.7);
            const imgProps = pdf.getImageProperties(dt);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            var hr = pdfWidth / imgProps.width
            var vr = pdfHeight / imgProps.height
            var reduceRatio = 1
            if (hr < 1 || vr < 1) {
                reduceRatio = Math.min(hr, vr)
            }
            pdf.addImage(dt, 'JPEG', 0, 0,
                imgProps.width * reduceRatio, imgProps.height * reduceRatio);
            if (i !== (pages.length - 1)) {
                pdf.addPage('a4', mode)
            }
        }
        if (separate) {
            pdf.save("whiteboard-" + savedname + ".pdf")
        } else {
            pdf.save("epic-board.pdf")
        }
        window.location.reload();
    }

    const pgNav = "page_nav"
    // const pgNav = mobile ? "page_nav_mobile" : "page_nav"
    const undoredo = mobile ? "undoredo_nav_mobile" : "undoredo_nav"
    var VideoID;

    if (sess && sess.Classroom) {
        if (sess.isGroup && teacherR !== 1) {
            VideoID = sess.parentID
        } else {
            VideoID = sess.Classroom
        }
    } else if (sess) {
        VideoID = sess.parentID
    } else {
        VideoID = null
    }

    function overlayHelpClosed(closeAction) {
        if (closeAction !== "shownexttime") {
            mylocalStorage.setItem('tourDone', true)
        } else {
            mylocalStorage.setItem('tourDone', false)
        }
        setOverlayHelpOpen(false);
    }
    function editRichText(foundKey) {
        if (richText.open) return
        var rect = selectedObj.bounds
        var loc = { x: rect.x, y: rect.y }
        if (foundKey) {
            ib.delObject(foundKey)
        }
        dispatch(Actions.setRichText({
            open: true,
            object: selectedObj.data.saved, cb:
                textEdit, location: loc
        }))
        clearSelect()
    }

    function startRichText(e) {
        if (richText.open) return
        if (mobile) {
            var [x, y] = getTouchPos(e)
        } else {
            const brush = getBrushfromLazy()
            x = brush.x
            y = brush.y
        }
        var loc = { x: x, y: y }
        dispatch(Actions.setRichText({ open: true, object: null, cb: textEdit, location: loc }))
    }
    async function textEdit(e, save) {
        if (!e) {
            dispatch(Actions.setRichText({ open: false, object: null, cb: null, loc: null }))
            return
        }
        var rr = await iu.writeFileReturn(e)

        var raster = new paper.Raster({ crossOrigin: 'anonymous', source: rr.img });
        raster.data.createdAt = new Date().getTime() / 1000
        let idx = getInsertIndex(raster)
        paper.project.activeLayer.insertChild(idx, raster);
        raster.data.richText = true
        raster.data.saved = save
        raster.onLoad = function () {
            var xx = raster._size.width / 2
            var yy = raster._size.height / 2

            raster.position = new Point(xx + richText.location.x, yy + richText.location.y)
            updateDrawPaperObj(raster, done)
            function done(no) {
                // clearSelect()
                raster.remove()
                dispatch(Actions.setRichText({ open: false, object: null, cb: null, loc: null }))
            }
            // raster.data.id = obj.id
            // paperObj[obj.id] = { obj: raster, type: "picture", gqlObj: obj }
        }
        raster.onError = function (f) {
            console.log('The image not loaded.', f);
        };

    }
    function userRequestedWelcomeTour() {
        setOverlayHelpOpen(true);
    }
    function drawerChanged(state) {
        setMenuDrawerOpen(p => state);
    }
    function collapseSlider() {
        setSliderCollapsed(true);
    }
    function openSlider() {
        setSliderCollapsed(false);
    }
    const [sidebar, setSidebar] = React.useState({ open: false })
    function pagefootercb(thumb = false) {
        var newState = !sidebar.open
        if (thumb) {
            if (sidebar.open) {
                newState = !sidebar.open
            } else {
                return
            }
        }
        if (newState) {
            aniMess("SIDEBAR Makes the board Read ONLY")
            mylocalStorage.setItem('sidebar', "open")
        } else {
            mylocalStorage.removeItem('sidebar');
        }
        setSidebarVal(false)
    }
    function setSidebarVal(duringinit) {
        var lu = mylocalStorage.getItem('sidebar')
        var open = false
        if (lu) open = true

        if (open) {
            ctx.canvas.drawing.style.left = "8px"
            gLocked = true
        } else {
            ctx.canvas.drawing.style.left = "0px"
            if (!duringinit) gLocked = false
        }
        setSidebar({
            open: open
        })
    }
    const [openGCalc, setOpenGCalc] = React.useState({ open: false })
    function graphCalc() {
        setOpenGCalc({ open: !openGCalc.open })
    }
    const [openPiano, setOpenPiano] = React.useState({ open: false })
    function DrawPiano() {
        const userid = gUser ? gUser.id : null;
        const id = uuid();

        if (openPiano.open) {
            done();
        } else if (teacherR === 1) {
            ib.createObject(id, "name", session.id, JSON.stringify({
                type: "extWindow",
                position: { x: 0, y: 300 },
                windowType: "piano"
            }), "extWindow", userid, null, session.ttl).then(res => {
                const copy = res.data.createObject
                delete copy["Session"];
                ib.updateObject({ ...copy });
                setOpenPiano({ open: !openPiano.open, cb: done, obj: { ...copy } })
            })
        } else {
            setOpenPiano({ open: !openPiano.open, cb: done })
        }
        function done() {
            setOpenPiano({ open: false, cb: null })
        }
    }
    const [openXylo, setOpenXylo] = React.useState({ open: false })
    function DrawXylophone() {
        const userid = gUser ? gUser.id : null;
        const id = uuid();

        if (openXylo.open) {
            done();
        } else if (teacherR === 1) {
            ib.createObject(id, "name", session.id, JSON.stringify({
                type: "extWindow",
                position: { x: 0, y: 300 },
                windowType: "xylophone"
            }), "extWindow", userid, null, session.ttl).then(res => {
                const copy = res.data.createObject
                delete copy["Session"];
                ib.updateObject({ ...copy });
                setOpenXylo({ open: !openXylo.open, cb: done, obj: { ...copy } })
            })
        } else {
            setOpenXylo({ open: !openXylo.open, cb: done })
        }
        function done() {
            setOpenXylo({ open: false, cb: null })
        }
    }
    const [openMoleculeEditor, setOpenMoleculeEditor] = React.useState({ open: false })
    function DrawMoleculeEditor() {
        const userid = gUser ? gUser.id : null;
        const id = uuid();

        if (openMoleculeEditor.open) {
            done();
        } else if (teacherR === 1) {
            ib.createObject(id, "name", session.id, JSON.stringify({
                type: "extWindow",
                position: { x: 100, y: 100 },
                windowType: "moleculeeditor"
            }), "extWindow", userid, null, session.ttl).then(res => {
                const copy = res.data.createObject
                delete copy["Session"];
                ib.updateObject({ ...copy });
                setOpenMoleculeEditor({ open: !openMoleculeEditor.open, cb: done, obj: { ...copy } })
            })
        } else {
            setOpenMoleculeEditor({ open: !openMoleculeEditor.open, cb: done })
        }
        function done() {
            setOpenMoleculeEditor({ open: false, cb: null })
        }
    }

    const [readalong, setReadAlong] = React.useState({ open: false, cb: null, text: null })
    function handleEditRead(obj) {
        setReadAlong({ open: true, cb: done, text: obj.data.pdfText })
        function done(e) {
            setReadAlong({ open: false, cb: null, text: null })
            if (e && e !== obj.data.pdfText) {
                obj.data.pdfText = e
                updateDrawPaperObj(obj, null)
            }
        }
    }
    //  const JOINURL = window.location.href.replace("/board", "/join")
    //const URL = "mailto:?subject=Please join this epic board&body=Please click on the link to join my board %0D%0A%0D%0A" + JOINURL

    const fontsList = ["Roboto", "Roboto", "Roboto", "Courier", "KgTeacherHelpers", "OpenDyslexic", "Lexend", "Sassoon", "Comic Sans"]
    function handleFontChange(c) {
        const selectTool = c.target.value
        dispatch(Actions.setPersonalConfig({
            ...personalConfig,
            font: selectTool,
        }))
        setAnchorFontAnchor(null)
    }
    const [fontEl, setFontEl] = React.useState(null);
    const [sliderType, setSliderType] = React.useState(null);
    const [openGo, setOpenGo] = React.useState(null);

    const handleClickFs = (event) => {
        setFontEl(event.currentTarget);
    };

    const handleCloseFs = () => {
        setFontEl(null);
    };

    const openFS = Boolean(fontEl);
    const id = openFS ? 'simple-popover' : undefined;

    const mobileScreen = (typeof window.orientation !== 'undefined' && (window.screen.width < 500))
    const renderHand = (!mobileScreen || !Boolean(teacherR))
    const [menuAnchorEl, setMenuAnchorEl] = React.useState({ el: null });

    const deleteTools = [
        { name: 'Clear Page', cb: clearpage },
        { name: 'Clear All Pages', cb: clearAllPages, userroles: ['teacher'], userroles: ['teacher'] },
        { name: 'Clear Student Pages', cb: clearStudentPages, userroles: ['teacher'] },
        { name: 'Clear Students & Pages', cb: clearStudents, userroles: ['teacher'] },
        { name: 'Delete Forever', cb: delForever, userroles: ['teacher'] },
        { name: 'Delete Page', cb: delPage, userroles: ['teacher'] },
    ]
    function clearpage() {
        handleCloseMenu()
        setyn({ open: true, message: "This will clear this board for everyone?", cb: clearBoard })
    }
    function clearBoard(val) {
        setyn({ open: false, message: "", cb: null })
        if (val === true) {
            handleButtonClear()
        }
    }
    const handleClickOpenMenu = (event) => {
        setMenuAnchorEl({ el: event.currentTarget });
    };

    const handleCloseMenu = () => {
        setMenuAnchorEl({ el: null });
    };

    function delForever() {
        handleCloseMenu()
        if (!teacherR) return
        setyn({ open: true, message: "This will delete the board permanently, are you sure?", cb: ynClick })
    }

    function delPage() {
        handleCloseMenu()
        if (!teacherR) return
        setyn({ open: true, message: "This will delete this page permanently, are you sure?", cb: delPageyn })
    }

    function ynClick(val) {
        setyn({ open: false, message: "", cb: null })
        if (val === true) {
            ib.delSession(props.match.params.boardid)
            props.history.push("/")
        }
    }

    function delPageyn(val) {
        setyn({ open: false, message: "", cb: null })
        if (val === true) {
            const pgSplit = props.match.params.boardid.split("-pgNum-")

            ib.deletePage(props.match.params.boardid, done)
            function done() {
                if (pgSplit[1] !== "1") {
                    var ff = parseInt(pgSplit[1]) - 1
                    var url = "/board/" + pgSplit[0] + "-pgNum-" + ff
                    props.history.push(url)
                    window.location.reload()
                    return
                } else {
                    props.history.push("/")
                }
            }
        }
    }
    function clearAllPages() {
        ReactGA.event({
            category: 'Tools',
            action: "Clear All Pages"
        });
        handleCloseMenu()
        if (!teacherR) return
        setyn({ open: true, message: "Clear all boards including students, are you sure?", cb: doneClick })
        function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (val === true) {
                ib.clearAllBoards(sess, "all")
            }
        }
    }

    function clearStudentPages() {
        ReactGA.event({
            category: 'Tools',
            action: "Clear Student Pages"
        });
        handleCloseMenu()
        if (!teacherR) return
        setyn({ open: true, message: "Clear all students boards, are you sure?", cb: doneClick })
        function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (val === true) {
                ib.clearAllBoards(sess, "student")
            }
        }
    }
    function clearStudents() {
        ReactGA.event({
            category: 'Tools',
            action: "Clear Students"
        });
        handleCloseMenu()
        if (!teacherR) return
        setyn({ open: true, message: "Clear all students (including boards), are you sure?", cb: doneClick })
        function doneClick(val) {
            setyn({ open: false, message: "", cb: null })
            if (val === true) {
                ib.clearAllBoards(sess, "studentAndPages")
            }
        }
    }

    function manageBoards(sendSession) {
        mylocalStorage.setItem('manageBoardsSession', props.match.params.boardid);


        if (sess && sess.Classroom) {
            mylocalStorage.setItem('manageBoardsClassroom', sess.Classroom);
        } else {
            mylocalStorage.removeItem('manageBoardsClassroom');
        }

        var urlPath = "/manage/";

        if (sendSession) {
            urlPath += props.match.params.boardid;
        }

        props.history.push(urlPath);
    }

    function handleConference(tvalue) {
        dispatch(Actions.setTab({
            ...tab,
            selected: tvalue
        }))
    }

    React.useEffect(() => {
        if (sidebar.open) {
            setTabMode('Thumbnail View')
        } else {
            setTabMode('Single View')
        }
    }, [sidebar])

    React.useEffect(() => {
        if (tabMode === 'Grid View')
            pagefootercb(true)
    }, [tabMode])


    function GridOptionsCard() {
        const classesCard = useStylesCard();
        const [nCols, setNCols] = React.useState('3');
        const defaultGridOptions = {
            nCols: '3',
            nRows: '3',
        }
        const [gridOptions, setGridOptions] = React.useState(defaultGridOptions);

        React.useEffect(() => {
            var savedGridOpts = mylocalStorage.getItem('gridOptions');
            if (savedGridOpts && savedGridOpts !== undefined) {
                var savedGridOptions = JSON.parse(savedGridOpts);
                setGridOptions(savedGridOptions);
            } else {
                mylocalStorage.setItem("gridOptions", JSON.stringify(gridOptions));
            }
        }, [])

        const handleColsChange = (event) => {
            var newVal;
            setGridOptions(p => { newVal = { ...p, nCols: event.target.value }; return newVal; });
            mylocalStorage.setItem("gridOptions", JSON.stringify(newVal));
            handleMultiBoardCB({ name: "Grid Options", gridOptions: newVal })
        }

        const handleRowsChange = (event) => {
            var newVal;
            setGridOptions(p => { newVal = { ...p, nRows: event.target.value }; return newVal; });
            mylocalStorage.setItem("gridOptions", JSON.stringify(newVal));
            handleMultiBoardCB({ name: "Grid Options", gridOptions: newVal })
        }
        function boardCheck(e) {
            dispatch(Actions.setGridBrowser({
                ...gridBrowser,
                open: e.target.checked
            }))
        }

        function gridOptionsClicked() {
            // setGridOptionsOpen(p => !p)
            setOpenGo(o => !o)
        }

        return (
            <>
                <Card className={classesCard.root} variant="outlined">
                    <CardContent className='p-0'>
                        <Typography className={classesCard.title} gutterBottom>
                            Grid Options
                        </Typography>
                        <Typography className={classesCard.content} component="div" color="textSecondary">
                            <Grid container alignItems="center">
                                <Grid item xs={12} className={classesCard.tlml}>
                                    <span className={classesCard.sublbl}>Boards Per Row:</span>
                                </Grid>
                                <Grid item xs={12} className={clsx(classesCard.tlml, 'customRadioBtns')}>
                                    {['1', '2', '3', '4', '5'].map((i) => {
                                        return (
                                            <React.Fragment key={"nColsRadio" + i}>
                                                <Radio
                                                    checked={gridOptions.nCols === i}
                                                    onChange={handleColsChange}
                                                    value={i}
                                                    name="nColsRadio"
                                                    inputProps={{ 'aria-label': i }}
                                                />
                                                <span style={{ marginRight: '5px' }}>{i}</span>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                                <Grid item xs={12} className={classesCard.tlml}>
                                    <span className={classesCard.sublbl}>Rows/Page:</span>
                                </Grid>
                                <Grid item xs={12} className={clsx(classesCard.tlml, 'customRadioBtns')}>
                                    {['1', '2', '3', '4', '5', '6'].map((i) => {
                                        return (
                                            <React.Fragment key={"nRowsRadio" + i}>
                                                <Radio
                                                    checked={gridOptions.nRows === i}
                                                    onChange={handleRowsChange}
                                                    value={i}
                                                    name="nRowsRadio"
                                                    inputProps={{ 'aria-label': i }}

                                                />
                                                <span style={{ marginRight: '5px' }}>{i}</span>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </Typography>
                    </CardContent>
                    <CardActions className='caofBoard'>
                        <Grid container alignItems="center">
                            <Grid item xs={12} className={clsx(classesCard.bb, 'customSwtBtns')}>
                                <span className={classesCard.sublbl}>Board Browser</span>
                                <FormControlLabel
                                    control={<Switch size="small" checked={gridBrowser.open} onChange={boardCheck} name="boardBrowser" />}
                                />
                            </Grid>
                            <GridBrowser {...gridBrowser} inkColor={props.inkColor} />
                        </Grid>
                        <Grid container alignItems="center" className='dividerBorder doneBtnBB'>
                            <Grid container justify="flex-end">
                                <Grid item><Button size="medium" onClick={gridOptionsClicked}>Done</Button></Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </>
        );
    }

    function handleDrag(e, ui) {
        if ((ui.y < 120 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    React.useEffect(() => {
        var pd = false
        var clearPage = false
        if (!Boolean(teacherR)) {
            if (boardTools && boardTools['Manage Boards'] && boardTools['Manage Boards'].checked) pd = true
            if (boardTools && boardTools['Clear'] && boardTools['Clear'].checked) clearPage = true
        }
        setIcoDisable({ manageboard: pd, clear: clearPage })
    }, [boardTools, teacherR])
    return (
        <>
            {autoCorrect && !teacherR && (<AutoCorrectModal open={autoCorrect} setOpen={setAutoCorrect} inkColor={inkColor} />)}
            {websiteAlertMessage && (<WebsiteAlertMessage open={websiteAlertMessage} setOpen={setWebsiteAlertMessage} inkColor={inkColor} history={props.history} />)}
            {readalong.open && (<ReadAloudDialog {...readalong} inkColor={inkColor} />)}
            {fontAnchor && (<FontPopper />)}
            {yn.open && <YesNoDialog {...yn} inkColor={inkColor} />}
            {engagementR.open &&
                <Suspense fallback={<div>Loading...</div>}>
                    <EngagementResults {...engagementR} />
                </Suspense>
            }
            {fillColor.pickerOpen && (<FillColorPicker {...fillColor} inkColor={inkColor} />)}
            {tileFactory.open && <TileFactoryDialog {...tileFactory} inkColor={inkColor} />}
            {formFactory.open && <FormFactoryDialog {...formFactory} inkColor={inkColor} />}
            {colorPicker.pickerOpen && (<BoardColorPicker {...colorPicker} inkColor={inkColor} setColorPicker={setColorPicker} ctx={ctx} isApiMode={isApiMode} />)}

            {magicWriteDialog.open && (
                <Suspense fallback={<div>Loading...</div>}>
                    <MagicWriteDialog {...magicWriteDialog} inkColor={inkColor} />
                </Suspense>
            )}

            {handWriteDialog.open && (
                <Suspense fallback={<div>Loading...</div>}>
                    <HandWriteDialog {...handWriteDialog} inkColor={inkColor} setShowTextTools={setShowTextTools} />
                </Suspense>
            )}
            {formResults.open && (
                <Suspense fallback={<div>Loading...</div>}>
                    <FormResults {...formResults} inkColor={inkColor} />
                </Suspense>
            )}
            {openMoleculeEditor.open && (
                <Suspense fallback={<div>Loading...</div>}>
                    <MoleculeEditor {...openMoleculeEditor} inkColor={inkColor} />
                </Suspense>
            )}
            {tableFactory.open && <TableFactoryDialog {...tableFactory} inkColor={inkColor} />}
            {openPiano.open && (
                <Suspense fallback={<div>Loading...</div>}>
                    <Piano inkColor={inkColor}  {...openPiano} />
                </Suspense>
            )}
            {openXylo.open && (
                <Suspense fallback={<div>Loading...</div>}>
                    <Xylophone inkColor={inkColor}  {...openXylo} />
                </Suspense>
            )}
            {chatSelector.open && <ChatSelector inkColor={inkColor} {...chatSelector} />}
            {peerDiag.open && <PeerConnection inkColor={inkColor} {...peerDiag} />}
            {diceShow && (diceShow.teacher.open || diceShow.board.open) && (
                <Suspense fallback={<div>Loading...</div>}>
                    <DiceRoll inkColor={inkColor} />
                </Suspense>
            )}
            {linkProps.open && (<LinkDialog {...linkProps} inkColor={inkColor} />)}
            {loopProps.open && (<LoopDialog {...loopProps} inkColor={inkColor} />)}

            {spText.open && <SpeechText inkColor={inkColor}  {...spText} />}
            <SpinnerConfigPanel inkColor={inkColor}
                spinnerConfigPanelOpen={spinnerConfigPanelOpen}
                spinnerConfigPanelPos={spinnerConfigPanelPos}
                spinnerResultsValuesStr={spinnerResultsValuesStr}
                spinnerParticipantsColorsStr={spinnerParticipantsColorsStr}
                spinnerSyncStudentSpins={spinnerSyncStudentSpins}
                spinnerStudentsCanSpin={spinnerStudentsCanSpin}
                participantsSpinner={participantsSpinner}
                spinnerDefaultsStr={discSectors.toString().replace(/,/g, '\n')}
                spinnerParticipantsColorsDefaultsStr={""}
                handleSpinnerResultsChange={handleSpinnerResultsChange}
                handleSpinnerParticipantsColorChange={handleSpinnerParticipantsColorChange}
                closeSpinnerConfigPanel={closeSpinnerConfigPanel}
                setSpinnerResultsValuesStr={setSpinnerResultsValuesStr}
                setSpinnerSyncStudentSpins={setSpinnerSyncStudentSpins}
                setSpinnerParticipantsColorsStr={setSpinnerParticipantsColorsStr}
                setSpinnerStudentsCanSpin={setSpinnerStudentsCanSpin}
                setParticipantsSpinner={setParticipantsSpinner}
                updateSpinner={updateSpinner}
            />
            <FdiceConfigPanel inkColor={inkColor}
                fdiceConfigPanelOpen={fdiceConfigPanelOpen}
                fdiceConfigPanelPos={fdiceConfigPanelPos}
                fdiceResultsValuesStr={fdiceResultsValuesStr}
                fdiceSyncStudentRolls={fdiceSyncStudentRolls}
                fdiceStudentsCanRolls={fdiceStudentsCanRolls}
                fdiceDefaultsStr={fdiceDefaultValuesStr}
                fdiceNumberOfDice={fdiceNumberOfDice}
                handleFdiceResultsChange={handleFdiceResultsChange}
                closeFdiceConfigPanel={closeFdiceConfigPanel}
                setFdiceResultsValuesStr={setFdiceResultsValuesStr}
                setFdiceSyncStudentRolls={setFdiceSyncStudentRolls}
                setFdiceStudentsCanRoll={setFdiceStudentsCanRoll}
                setFdiceNumberOfDice={setFdiceNumberOfDice}
                updateFdice={updateFdice}
            />
            <ClockConfigPanel inkColor={inkColor}
                clockConfigPanelOpen={clockConfigPanelOpen}
                clockConfigPanelPos={clockConfigPanelPos}
                clockDefaultType={clock_type}
                clockChanged={clockChanged}
                clockUpdatedType={clockUpdatedType}
                closeClockConfigPanel={closeClockConfigPanel}
                setClockUpdatedType={setClockUpdatedType}
                setClockChanged={setClockChanged}
                updateClock={updateClock}
            />
            {paletteDialog.open && <SavePaletteDialog {...paletteDialog} inkColor={inkColor} />}
            {answerDialog.open && <AnswerDialog {...answerDialog} inkColor={inkColor} />}
            {codingDialog.open && <CodingDialog {...codingDialog} inkColor={inkColor} />}

            {richText.open && <MyRichEditor cb={textEdit} inkColor={inkColor} />}
            {ctxMenu.open && <ContextMenu {...ctxMenu} />}
            {selectMenu.open && <SelectMenu {...selectMenu} />}

            {message !== "" && (

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={message !== ""}
                    onClose={() => setMessage("")}
                    key={"top center"}
                    autoHideDuration={3000}
                >
                    <SnackbarContent
                        style={{ fontSize: '0.58rem' }}
                        message={message}
                    >
                    </SnackbarContent>
                </Snackbar>
            )}
            {mobile && (
                <div className="scrollLock">
                    <button className="button-scroll" id="button_scroll" >
                        {scrollL ?
                            <PaneIcon style={{ height: '0.9rem' }} />
                            :
                            <LockIcon style={{ height: '0.9rem' }} />
                        }
                    </button>
                </div>
            )}

            {openGo ?
                <>
                    <Draggable
                        handle="strong"
                        onDrag={handleDrag}
                        position={windowPosition}
                        defaultClassName="draggable-grid"
                        defaultClassNameDragging="draggable2-grid"
                        defaultClassNameDragged="draggable3-grid"
                    >
                        <div className={clsx(classes.draggableWindow)}>
                            <div className={clsx(classes.draggableTop, 'dragDivmain')} style={{ width: "100%", backgroundColor: props.inkColor, cursor: "move" }}>
                                <strong className={clsx(classes.draggableTopHandle, 'justify-center')}>
                                    <svg width="30" height="10" viewBox="0 0 15 3" xmlns="http://www.w3.org/2000/svg" style={{ position: "absolute", top: "0px" }}>
                                        {svgIcons.dots}
                                    </svg>
                                </strong>
                            </div>

                            <GridOptionsCard />
                        </div>
                    </Draggable>
                </>
                : null
            }
            <div className='flex'>
                <MiniDrawer {...props} inkColor={inkColor} cb={cbs} drawMode={drawMode} eraseMode={eraseMode}
                    pauseMode={pauseMode} sessionId={props.match.params.boardid} sess={sess} user={user} gSession={gSession}
                    renameCB={handleBoardRename} tourRequestedCB={userRequestedWelcomeTour} drawerCB={drawerChanged}
                    gridView={gridView} userRole={userRole} menuDrawerOpen={menuDrawerOpen} pgNav={pgNav} inkColor={inkColor}
                    mutlibcfg={multBoardCfg} handleMultiBoardCB={handleMultiBoardCB}
                    openGo={openGo} setOpenGo={setOpenGo} setShowTextTools={setShowTextTools} setopenTip={setopenTip} openTip={openTip}
                    onChangeNameCB={unameChange} setParentBoardData={setParentBoardData} parentBoardData={parentBoardData} isBorderSet={isBorderSet} welcomeTourOpen={overlayHelpOpen} isApiMode={isApiMode} penBtnColor={penBtnColor} />
            </div>

            {!isApiMode ? (
                <>

                    <div className='footerBar' style={{ bottom: isBorderSet ? '6px' : '' }}>
                        <div className={clsx(pgNav, 'pgNavNew flex disp-fc-hf')} style={{ left: menuDrawerOpen ? '160px' : '0px' }} >
                            <div id="pageNav" className='pgNavThumb'>
                                <div className='footerBtn'>
                                    <PageFooter gv={gridView} sess={sess} session={props.match.params.boardid} history={props.history} inkColor={inkColor} cb={pagefootercb} />
                                </div>
                                {Boolean(teacherR) && tabMode !== 'Grid View' && !gridView.open && (
                                    <div className={clsx('footerBtn',
                                        { 'activeTabFooter': tabMode === 'Thumbnail View' })}>
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="Thumbnail View" arrow placement="top">
                                            <IconButton className="btnSqr p-0" id="pagesPanelButton" onClick={() => { pagefootercb(false) }}>
                                                <img src='/tools/thumbnail.svg' alt='Thumbnail View Icon' style={{ width: '16px' }} />
                                                <span className='footerNavlbl' style={{ color: tabMode === 'Thumbnail View' ? '#40537B' : '#7D8FB6' }}>Thumbnail View</span>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}

                            </div>
                            {multBoardCfg.multiBoardOption && teacherR === 1 && (
                                <div className='disp-fc-hf'>
                                    <MultiboardNav {...multBoardCfg} inkColor={inkColor} gv={gridView} session={sess} cb={handleMultiBoardCB} openGo={openGo} setOpenGo={setOpenGo} tabMode={tabMode} />
                                </div>
                            )}
                            {!icoDisable.manageboard && openSimpleTools &&
                                <div className='footerBtn' id="manageBoards">
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title={'Manage Boards'} arrow>
                                        <IconButton className='btnSqr p-0' onClick={() => { manageBoards(false) }}>
                                            <img src='/tools/manageBoard.svg' alt='Manage Baord Icon' style={{ width: '16px' }} />
                                            <span className='footerNavlbl'>Manage Boards</span>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }

                            {tab.allTabs.length > 0 && (
                                <>
                                    <div className='footerBtn'>
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="Conference" arrow placement="top">
                                            <IconButton className="btnSqr p-0" id="pagesPanelButton" onClick={() => { handleConference(0) }}>
                                                <PanoramaIcon />
                                                <span className='footerNavlbl'>WhiteBoard</span>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className='footerBtn'>
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="Conference" arrow placement="top">
                                            <IconButton className="btnSqr p-0" id="pagesPanelButton" onClick={() => { handleConference(1) }}>
                                                <CallIcon />
                                                <span className='footerNavlbl'>Conference</span>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <KiddieFrame inkColor={inkColor} />



                    {mathInput && mathInput.element && (
                        mathInput.element
                    )}
                    {showCase.dialog && (<ShowCaseDialog sess={sess} inkColor={inkColor} />)}

                    <>
                        <div
                            className={clsx(undoredo, 'undoPanel', 'undoNewCls', 'respMode-pl', {
                                'undoNewMove': menuDrawerOpen,
                            })}
                            style={{ display: tabValue.display0, marginLeft: menuDrawerOpen ? (mobile ? '170px' : '200px') : '0px' }}>
                            <div className={clsx({ 'moveHeader': menuDrawerOpen }, 'flex flex-align-center w-full overflowAuto')}>
                                <div className='flex'>
                                    <span id="undoRedoPanel">
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="Undo" arrow placement="top">
                                            <span> {/* These spans are needed in case disabled buttons are included within tooltip. Recommended by mui tooltip */}
                                                <IconButton className="btnSqr" onClick={undo} aria-label="undo" disabled={undoIconDisabled}>
                                                    {/* <UndoIcon fontSize="large" style={{ color: undoIconDisabled ? "#ddd" : inkColor }} /> */}
                                                    <img src='/tools/undo.svg' style={{ color: undoIconDisabled ? "#ddd" : inkColor }} alt='undo icon' />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="Redo" arrow placement="top">
                                            <span>
                                                <IconButton className="btnSqr" onClick={redo} aria-label="redo" disabled={redoIconDisabled}>
                                                    {/* <RedoIcon fontSize="large" style={{ color: redoIconDisabled ? "#ddd" : inkColor }} /> */}
                                                    <img src='/tools/redo.svg' style={{ color: undoIconDisabled ? "#ddd" : inkColor }} alt='redo icon' />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </span>
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title="Refresh" arrow placement="top">
                                        <IconButton className="btnSqr" onClick={(e) => { window.location.reload() }} aria-label="refresh">
                                            <img src='/tools/refresh.svg' alt='refresh icon' />
                                        </IconButton>
                                    </Tooltip>
                                    <IconButton className='trashMenu' onClick={(e) => { handleClickOpenMenu(e) }} aria-label="trash">
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="Delete Options" arrow placement="top">
                                            <img src='/tools/trash.svg' alt='trash icon' />
                                        </Tooltip>
                                        {/* <IconButton onClick={(e) => { handleButtonClear() }} aria-label="trash"> */}
                                        <ArrowDropDownIcon />
                                    </IconButton>
                                    {!icoDisable.clear && (
                                        <Popover
                                            id={menuAnchorEl.el ? 'simple-popover' : undefined}
                                            open={Boolean(menuAnchorEl.el)}
                                            anchorEl={menuAnchorEl.el}
                                            onClose={handleCloseMenu}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            className='menuPop'
                                        >
                                            {deleteTools.filter(a => (a.userroles === undefined || (a.userroles !== undefined && a.userroles.includes(userRole)))).map((e) => (
                                                <MenuItem key={e.name} className="customNewMenuItem" onClick={e.cb && e.cb}>
                                                    <Typography variant="h6">{e.name}</Typography>
                                                    {e.icon && <ListItemIcon>{e.icon}</ListItemIcon>}
                                                </MenuItem>
                                            ))}
                                        </Popover>)}

                                </div>


                                {zoomEnabled && (
                                    <>
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="ZoomIn" arrow placement="top">
                                            <span> {/* These spans are needed in case disabled buttons are included within tooltip. Recommended by mui tooltip */}
                                                <IconButton className="btnSqr" onClick={ZoomIn} aria-label="undo">
                                                    {/* <ZoomInIcon fontSize="large" style={{ color: inkColor }} /> */}
                                                    <img src='/tools/zoomin.svg' style={{ color: undoIconDisabled ? "#ddd" : inkColor }} alt='zoomin icon' />

                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="Reset Zoom" arrow placement="top">
                                            <span className='zoomPerc'>
                                                <Button size="medium" onClick={ResetZoom} aria-label="redo" style={{ color: inkColor, background: '#F3F8FE', borderRadius: '999px' }}>
                                                    {zoomSz}
                                                </Button>
                                            </span>
                                        </Tooltip>
                                        <Tooltip classes={{
                                            tooltip: classes.customTooltip,
                                            arrow: classes.customArrow
                                        }} title="ZoomOut" arrow placement="top">
                                            <span>
                                                <IconButton className="btnSqr" onClick={ZoomOut} aria-label="redo" >
                                                    <img src='/tools/zoomout.svg' style={{ color: undoIconDisabled ? "#ddd" : inkColor }} alt='zoomout icon' />
                                                    {/* <ZoomOutIcon fontSize="large" style={{ color: inkColor }} /> */}
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </>
                                )}


                                <div id="sidebar" className="newSliderHeader" style={{ display: (showTextTools === true || overlayHelpOpen === true) ? 'flex' : 'none' }}>
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }}
                                        aria-label="dashed pen" title="Dashed Pen"
                                    >
                                        <IconButton className={lineStyle === null ? '' : "dashed"} onClick={changeLineStyle} style={{ background: lineStyle === null ? 'transparent' : bUtils.hexToRGB(inkColor, 0.1) }}>
                                            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ border: lineStyle === null ? 'none' : '1px dashed !important' }}>
                                                <path d="M0.333984 1.00065C0.333984 0.632461 0.632461 0.333984 1.00065 0.333984H3.00065C3.36884 0.333984 3.66732 0.632461 3.66732 1.00065C3.66732 1.36884 3.36884 1.66732 3.00065 1.66732H1.00065C0.632461 1.66732 0.333984 1.36884 0.333984 1.00065Z" fill={lineStyle === null ? "#7D8FB6" : inkColor} />
                                                <path d="M0.333984 9.33398C0.333984 8.7817 0.7817 8.33398 1.33398 8.33398H12.6673C13.2196 8.33398 13.6673 8.7817 13.6673 9.33398C13.6673 9.88627 13.2196 10.334 12.6673 10.334H1.33398C0.781699 10.334 0.333984 9.88627 0.333984 9.33398Z" fill={lineStyle === null ? "#7D8FB6" : inkColor} />
                                                <path d="M6.16732 0.333984C5.79913 0.333984 5.50065 0.632461 5.50065 1.00065C5.50065 1.36884 5.79913 1.66732 6.16732 1.66732H7.83398C8.20217 1.66732 8.50065 1.36884 8.50065 1.00065C8.50065 0.632461 8.20217 0.333984 7.83398 0.333984H6.16732Z" fill={lineStyle === null ? "#7D8FB6" : inkColor} />
                                                <path d="M10.334 1.00065C10.334 0.632461 10.6325 0.333984 11.0007 0.333984H13.0007C13.3688 0.333984 13.6673 0.632461 13.6673 1.00065C13.6673 1.36884 13.3688 1.66732 13.0007 1.66732H11.0007C10.6325 1.66732 10.334 1.36884 10.334 1.00065Z" fill={lineStyle === null ? "#7D8FB6" : inkColor} />
                                                <path d="M1.00065 4.33398C0.632461 4.33398 0.333984 4.63246 0.333984 5.00065C0.333984 5.36884 0.632461 5.66732 1.00065 5.66732H13.0007C13.3688 5.66732 13.6673 5.36884 13.6673 5.00065C13.6673 4.63246 13.3688 4.33398 13.0007 4.33398H1.00065Z" fill={lineStyle === null ? "#7D8FB6" : inkColor} />
                                            </svg>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title="Toggle Highlighter" arrow placement="top">
                                        <IconButton aria-label="Pen Opacity" onClick={toggleHighlighter} style={{ background: toggleHL === false ? 'transparent' : bUtils.hexToRGB(inkColor, 0.1) }}>
                                            <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 0C0.447715 0 0 0.447715 0 1V3.49997C0 4.3284 0.671573 4.99997 1.5 4.99997C1.4828 4.99997 1.46678 5.00084 1.45003 5.00253H1.55192C1.53517 5.00084 1.5172 4.99997 1.5 4.99997H1.74633V5.00253H10.2911V4.99997H10.501C10.4838 4.99997 10.4668 5.00084 10.45 5.00253H10.5519C10.5352 5.00084 10.5182 4.99997 10.501 4.99997H10.502C11.3305 4.99997 12.002 4.3284 12.002 3.49997V1C12.002 0.447715 11.5543 0 11.002 0H1ZM1.00098 6.00253C1.00236 7.10592 1.89726 7.99997 3.00097 7.99997L8.99968 8L9.00097 7.99997C10.1047 7.99997 10.9996 7.10592 11.001 6.00253H1.00098ZM3.00269 13.5V9H9.00152L9.00288 9.73986C9.00382 10.2515 8.74393 10.7283 8.31343 11.0048L3.77287 13.9207C3.619 14.0195 3.42347 14.0265 3.26299 13.9388C3.10251 13.8511 3.00269 13.6829 3.00269 13.5Z" fill={toggleHL === false ? "#7D8FB6" : inkColor} />
                                            </svg>

                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title="Change Font Size" arrow placement="top">
                                        <IconButton aria-describedby={id} variant="contained" aria-label="Change Font Size" onClick={(e) => {
                                            handleClickFs(e)
                                            setSliderType('fs')
                                        }}>
                                            <TextFieldsIcon className="toolIcon" />
                                        </IconButton>
                                    </Tooltip>
                                    <Popover
                                        id={id}
                                        open={openFS}
                                        anchorEl={fontEl}
                                        onClose={handleCloseFs}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        className='fsPopper'
                                    >
                                        {sliderType === 'fs' &&
                                            <Tooltip classes={{
                                                tooltip: classes.customTooltip,
                                                arrow: classes.customArrow
                                            }} title="Text Size" arrow placement="right">
                                                <WBSlider id="slider_text" onChange={handleSliderText}
                                                    valueLabelDisplay="auto"
                                                    min={1}
                                                    max={20}
                                                    defaultValue={3}
                                                    orientation="vertical"
                                                    value={currentText} //dummy for react to react
                                                    aria-labelledby="continuous-slider" />
                                            </Tooltip>
                                        }
                                        {sliderType === 'pt' &&
                                            <Tooltip classes={{
                                                tooltip: classes.customTooltip,
                                                arrow: classes.customArrow
                                            }} title="Pen thickness" arrow placement="right">
                                                <WBSlider id="slider_brush" onChange={handleSliderBrush}
                                                    valueLabelDisplay="auto"
                                                    min={1}
                                                    max={20}
                                                    defaultValue={3}
                                                    orientation="vertical"
                                                    value={currentBrush}
                                                    aria-labelledby="continuous-slider" />
                                            </Tooltip>
                                        }
                                        {sliderType === 'po' &&
                                            <Tooltip classes={{
                                                tooltip: classes.customTooltip,
                                                arrow: classes.customArrow
                                            }} title="Pen opacity" arrow placement="right">
                                                <WBSlider id="slider_opacity" onChange={handleSliderOpacity}
                                                    valueLabelDisplay="auto"
                                                    min={0}
                                                    max={100}
                                                    orientation="vertical"
                                                    defaultValue={100}
                                                    value={currentOpacity} //dummy for react to react
                                                    aria-labelledby="continuous-slider" />
                                            </Tooltip>
                                        }

                                    </Popover>

                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title="Pen Thickness" arrow placement="top">
                                        <IconButton aria-label="Pen Thickness" aria-describedby={id} variant="contained" onClick={(e) => {
                                            handleClickFs(e)
                                            setSliderType('pt')
                                        }}>
                                            <img style={{ width: '16px' }} src='/tools/PenEdit.svg' alt='pen thickness icon' />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title="Pen Opacity" arrow placement="top">
                                        <IconButton aria-label="Pen Opacity" aria-describedby={id} variant="contained" onClick={(e) => {
                                            handleClickFs(e)
                                            setSliderType('po')
                                        }}>
                                            <img style={{ width: '16px' }} src='/tools/opacity.svg' alt='pen Opacity icon' />
                                        </IconButton>
                                    </Tooltip>
                                    <div className='selectFam'>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={personalConfig.font}
                                            onChange={handleFontChange}
                                            style={{ padding: "5px" }}
                                        >
                                            {fontsList.map((f) => (
                                                <MenuItem style={{ backgroundColor: inkColor, color: "#ffffff" }} value={f}>
                                                    <Typography variant="h6" className='font12-lato'>{f}</Typography>
                                                </MenuItem>

                                            ))}
                                        </Select>
                                    </div>
                                </div>
                                <div id="colorPicker" className="colorPickerCircle p-5">
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title="Pen Color" arrow placement="top">
                                        <IconButton style={{ background: penBtnColor }} aria-label="Pen Opacity" onClick={() => { handleColorPicker() }}>
                                            <span ></span>
                                        </IconButton>
                                    </Tooltip>
                                </div>

                                {renderHand && sess && sess.Classroom && (
                                    <HandRaise {...props} inkColor={inkColor} sess={sess} showTextTools={showTextTools} />
                                )}
                                {sess && sess.Classroom && (
                                    <ClassTimer {...props} sess={sess} inkColor={inkColor} showCase={showCase} />
                                )}
                                <div id="helpButton">
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title="Help" arrow placement="top">

                                        <IconButton onClick={() => setopenTip(true)}>
                                            <img src='/icons/helpIcon.svg' alt='Help icon' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        {gridView.open &&
                            <div className='goheader'>
                                <div className='sets'>
                                    <Tooltip classes={{
                                        tooltip: classes.customTooltip,
                                        arrow: classes.customArrow
                                    }} title="Grid Settings" arrow placement="top">
                                        <IconButton aria-label="Pen Thickness" aria-describedby={id} variant="contained" onClick={(e) => {
                                            setOpenGo(!openGo)
                                        }}>
                                            <img style={{ width: '16px' }} src='/gridSettings.svg' alt='Grid settings icon' />
                                        </IconButton>
                                    </Tooltip>
                                    <Typography className='lato10px'>Grid Settings</Typography>
                                </div>
                            </div>
                        }

                    </>
                    <div className="showdebug" style={{ display: 'none' }}>
                        {/* <div className="showdebug" style={{ display: tabValue.display0 }}> */}
                        <Tooltip classes={{
                            tooltip: classes.customTooltip,
                            arrow: classes.customArrow
                        }} title="Log debugs" arrow placement="top">
                            <IconButton onClick={showDebug} aria-label="debug">
                                <DeveloperModeIcon fontSize="large" style={{ color: inkColor }} />
                            </IconButton>
                        </Tooltip>
                    </div>

                </>
            ) : null}
            <div style={{ display: sliderCollapsed ? "block" : "none" }} className="collapsed_slider">
                <div className="slider_open_button">
                    <ChevronLeft onClick={openSlider} />
                </div>
            </div>
            <div style={{ overflow: 'hidden' }}>
                <div id="Canvas" style={{ display: tabValue.display0 }}>
                    {gridView.open && (
                        <div className={clsx('canvasGrid', 'transBg newCG')}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <NestedGrid {...multBoardCfg} sess={sess} history={props.history} inkColor={inkColor}
                                    gridOptions={gridOptions} />
                            </Suspense>
                        </div>
                    )}
                    {showCase.dialog && (
                        <div className="canvasGrid">

                        </div>
                    )}
                    <div id='exportImg'> </div>
                    {sidebar.open && (<SideBar inkColor={inkColor} sess={sess} history={props.history} menuDrawerOpen={menuDrawerOpen} />)}

                    <div className={clsx('canvas-container', 'canvContainer', {
                        'canvMove': menuDrawerOpen
                    })} id="canvas_container">

                        <div id="message">
                        </div>
                        {/* <canvas className={mobSize} tabIndex="0" id="canvas_interface"></canvas> */}
                        <div id='Cc'>
                            {isApiMode ? (
                                <canvas className={canvasClass}
                                    className={clsx(canvasClass)}
                                    id="canvas_drawing" tabIndex="0"
                                    style={{ width: "2000px", height: "1280px" }}>
                                </canvas>
                            ) : (
                                <div id="cs_container" className={clsx('cnvContainer', {
                                    'canvMove': menuDrawerOpen,
                                    'movePosBorder': isBorderSet
                                })} style={{ width: window.innerWidth - 40, right: '38px' }}>
                                    <canvas className={canvasClass}
                                        className={clsx(canvasClass,
                                            'sidbarClosedCls',
                                            {
                                                'sidbarOpenedCls': sidebar.open,
                                            })}
                                        id="canvas_drawing" tabIndex="0"></canvas>
                                    {openGCalc.open && (<GraphCalc inkColor={inkColor} canvasData={gCtx.canvas} cb={graphCalc} />)}
                                    {!gridView.open && !showCase.dialog && extWebpages && Object.keys(extWebpages).map((key, index) => {
                                        if (['xylophone', 'piano', 'moleculeeditor'].includes(extWebpages[key].windowType)) return (<></>);
                                        if (JSON.parse(extWebpages[key].content).windowType === 'media-gif') {
                                            return (
                                                <GifImage
                                                    key={extWebpages[key].id}
                                                    gifContent={extWebpages[key]}
                                                    canvasData={gCtx.canvas}
                                                />
                                            )
                                        }
                                        return (
                                            <ExternalWebpage
                                                key={extWebpages[key].id}
                                                type={extWebpages[key].windowType}
                                                index={index}
                                                inkColor={inkColor}
                                                extWebpage={extWebpages[key]}
                                                canvasData={gCtx.canvas}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                            {/* <canvas className={mobSize} id="canvas_others"></canvas> */}
                        </div>

                        {/* <canvas className={mobSize} id="canvas_temp"></canvas> */}
                        {/* {!mobile && (<canvas className="lazy-canvas" id="canvas_grid"></canvas>)} */}
                    </div>
                </div>
                {VideoID && tab.allTabs.length > 0 && (
                    <div id="Conference" className={confClass} style={{ display: tabValue.display1 }}>
                        <JitsiMeetComponent config={boardConfig} user={user} session={sess} isTeacher={teacherR} wbId={VideoID} wbIdTitle={sess.name} />
                    </div>
                )}
                <div className="loadFont">.</div>
                {boardChat.started && (
                    <div>
                        <Widget handleNewUserMessage={handleNewUserMessage}
                            title="Whiteboard.Chat"
                            showTimeStamp={false}
                            handleQuickButtonClicked={handleQuickButtonClicked}
                            subtitle="" />
                    </div>
                )}

            </div>
            {overlayHelpOpen ? <OverlayGuide helpCardDeck={tourType} open={overlayHelpOpen} onClose={overlayHelpClosed} inkColor={inkColor} setShowTextTools={setShowTextTools} /> : null}
            <PerfChecks />
            {IRContent !== null ?
                <WBCImmersiveReader title={IRContent.title} text={IRContent.text} locale={"en"}
                    tokenURL={IRContent.tokenURL} inkColor={inkColor}
                    options={{
                        onExit: () => { setIRContent(p => null) },
                        uiZIndex: 2000,
                    }} />
                : null}
        </>
    )
}

export default WhiteBoard