/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:9bbaacfe-ef42-432e-ba39-8cefb7b28854",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_9LW6Q0yZJ",
    "aws_user_pools_web_client_id": "679cm09uk698nsg0d19auevbmd",
    "oauth": {
        "domain": "whiteboard-wbcwhj.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/login/,https://eb.epiphani.ai/login/,https://www.shayr.us/login/,https://www.whiteboard.chat/login/,https://newpmdev.drtb0o61d9u0.amplifyapp.com/login/,https://www.asia.whiteboard.chat/login/,https://wb.whiteboard.chat/login/,https://uswest.whiteboard.chat/login/",
        "redirectSignOut": "http://localhost:3000/logout/,https://eb.epiphani.ai/logout/,https://www.shayr.us/logout/,https://www.whiteboard.chat/logout/,https://newpmdev.drtb0o61d9u0.amplifyapp.com/logout/,https://www.asia.whiteboard.chat/logout/,https://wb.whiteboard.chat/logout/,https://uswest.whiteboard.chat/logout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://h5fpwwnxevhlhh6oqggjwq76em.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "serviceApi",
            "endpoint": "https://k873yoawze.execute-api.ap-south-1.amazonaws.com/wbcwhj",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "collaborate637449bed9df4b3d944f19e6b9b6effe105211-wbcwhj",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
