import React from 'react';
import { SketchPicker } from 'react-color';
import { useSelector } from 'react-redux'
import Draggable from 'react-draggable';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button, Typography
} from '@material-ui/core';
import { useDispatch } from 'react-redux'
import * as Actions from "./store/actions"
import clsx from "clsx";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const pastels = ["#FDDFDF", "#FCF7DE", "#DEFDE0", "#DEF3FD", "#F0DEFD", "#FBE4FF",
                "#D5D6EA", "#F6F6EB", "#D7ECD9", "#F5D5CB", "#F6ECF5", "#F3DDF2",
                "#C4FAF8", "#FFFFD1", "#DBFFD6", "#F3FFE3"]
const useStyles = makeStyles((theme) => ({

    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        width: "250px !important",
        height: "400px",
        zIndex: 105,
        color: "#ffffff",

    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    close: {
        backgroundColor: "#E9EBF0",
        color: "rgb(47, 61, 91)",
        cursor: "pointer",
        borderRadius: "50%",
        position: "absolute",
        right: "20px",
        top: "10px",
    },
    formConfirm: {
        borderRadius: "999px",
        flex: "none",
        order: 0,
        flexGrow: 0,
        width: "200px",
        height: "32px",
        marginLeft: "24px",
        color: "#FFFFFF",
        fontSize: "14px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        textTransform: "capitalize",
        position: "absolute",
        bottom: "10px",
        left: "0px",
        boxShadow: "0px 1px 20px rgba(13, 20, 22, 0.1), 0px 0px 8px 2px rgba(13, 20, 22, 0.02)",
        "&:hover": {
            color: '#2F3D5B',
        },
    },
}))
export default function BackColorPicker(props) {
    const classes = useStyles();

    const background = useSelector((state) => state.background);
    const xpos = window.innerWidth / 2 - 115

    const [windowPosition, setWindowPosition] = React.useState({ x: xpos, y: 140 });
    const dispatch = useDispatch();

    React.useEffect(() => {
    }, [background])
    function colorChangeComplete(c) {
        ReactGA.event({
            category: 'User',
            action: "Color Change BackGround"
        });
        props.cb['backGround']({ color: c.hex })
    }

    function handleDrag(e, ui) {
        if ((ui.y < 120 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        dispatch(Actions.setBackGround({
            ...background,
            pickerOpen: false
        }))
    }
    function delBackground() {
        props.cb['backGround'](null)

    }
    return (
        <> {background.pickerOpen && (
            <Draggable
                handle="strong"

                onDrag={handleDrag}
                onStop={handleDragEnd}
                position={windowPosition}
                defaultClassName="draggable33-color"
                defaultClassNameDragging="draggable32-color"
                defaultClassNameDragged="draggable33-color"
            >
                <div id="smallpalette" className={clsx(classes.draggableWindow,'dragDiv')} style={{
                    border: '5px solid ' + props.inkColor,
                    // backgroundColor: '#ffffff44'
                }}> 
                    <div className={clsx(classes.draggableTop, 'dragDivmain')} style={{ width: "100%", backgroundColor: props.inkColor }}>
                        <div className={clsx(classes.draggableTopHandle, 'dragDivChild1')} >
                            <strong>
                            <MoreHorizIcon style={{ color: "#7D8FB6", position: "absolute", top: "-7", left: "112px", cursor: "move" }} />
                            </strong>
                        </div>
                    </div>
                    <div>
                        <CloseIcon className={classes.close}
                            onClick={handleClose} />
                    </div>
                    <div className='dragDivForms'>
                        <SketchPicker
                            disableAlpha
                            presetColors={pastels}
                            color={background.color ? background.color : "#ffffff"}
                            onChangeComplete={colorChangeComplete} />
                    </div>
                    <div className='formBtns backBtns'>
                        <Button
                            variant="contained"
                            size="small"
                            style={{ backgroundColor: props.inkColor }}
                            className={classes.formConfirm}
                            onClick={() => delBackground()}
                        >
                            Remove
                            </Button>
                    </div>
                </div>
            </Draggable>

        )}
        </>
    );
}