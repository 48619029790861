import React from 'react';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import PaneIcon from '@material-ui/icons/ControlCamera';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Typography,
    InputBase,
    Divider
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import clsx from "clsx";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    draggableWindow: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: " 0.5px solid rgba(0, 0, 0, 0.08)",
        position: "absolute",
        width: "400px !important",
        minHeight: "325px",
        zIndex: 105,
        color: "#ffffff",
        boxSizing: "border-box",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 96px rgba(0, 0, 0, 0.16)",
        borderRadius: "4px",
    },
    button: {
        margin: theme.spacing(1),
        color: "#ffffff",
        backgroundColor: "#3174F5",
        fontSize: "0.6rem",
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        textTransform: "none",
        display: 'inline-flex',
        borderRadius: 5,
        "&:hover": {
            backgroundColor: '#EBBF23',
            color: '#3174F5',
        },
    },
    draggableTop: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "20px",
        justifyContent: "flex-end",
        position: "fixed",
        paddingBottom: "5px",
        top: 0,
        left: 0,
    },
    rowsColumns: {
        padding: "4px 20px",
        margin: "8px 0px",
        background: "#FFFFFF",
        border: "1px solid #E0E5E7",
        boxSizing: "border-box",
        boxShadow: "0px 1px 2px rgba(0, 35, 61, 0.04)",
        borderRadius: "999px"
    },
    cp: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        paddingBottom: "5px",
        marginLeft: "20px !important",
        top: 20,
        left: 0,
    },
    draggableTopHandle: {
        display: "flex",
        flexGrow: "1"
    },
    draggableTopCloseButton: {
        display: "flex"
    },
    windowButton: {
        display: "flex",
        width: '30px',
        padding: '0',
        color: "#ffffff",

    },
    createButton: {
        color: "#FFFFFF",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "999px",
        fontFamily: 'Lato',
        fontWeight: 'normal',
        width: "100px",
        padding: "6px 12px",
        textTransform: "capitalize",
        letterSpacing: "-0.078px !important",
        "&:hover": {
            color: '#2F3D5B',
        }
    },
}))
export default function TableFactoryDialog(props) {
    const classes = useStyles();
    const [windowPosition, setWindowPosition] = React.useState({ x: 100, y: 200 });
    const [text, setText] = React.useState({ row: null, column: null })
    const [control, setControl] = React.useState({ firstRowHeader: false, makeSquareCells: false })
    const boardConfig = useSelector((state) => state.BoardConfig);

    const handleChange2 = (event) => {
        var old = { ...control, [event.target.name]: event.target.checked }
        if (event.target.name === "studentClone" && event.target.checked) {
            old.studentMove = false
        }
        if (event.target.name === "studentMove" && event.target.checked) {
            old.studentClone = false
        }
        setControl(old);
    };
    function handleDrag(e, ui) {
        if ((ui.y < 120 || ui.y > (window.innerHeight - 60)) || (ui.x < 50 || ui.x > window.innerWidth - 260)) {
            return
        }
        setWindowPosition(prev => {
            return { x: prev.x + ui.deltaX, y: prev.y + ui.deltaY }
        });
    }
    function handleDragEnd(e, ui) {
    }
    function handleClose(e) {
        e.preventDefault()
        props.cb(null)
    }
    function create(e) {
        props.cb({ text: text, control: control })
    }
    function pasteText(e) {
        e.preventDefault()
        setText(e.clipboardData.getData('text/plain'))
    }
        return (
            <Draggable
                handle="strong"
                onDrag={handleDrag}
                onStop={handleDragEnd}
                position={windowPosition}
                defaultClassName="draggable-color"
                defaultClassNameDragging="draggable2-color"
                defaultClassNameDragged="draggable3-color"
            >
            <div id="smallpalette" className={clsx(classes.draggableWindow, 'dragDiv')}>
                <div className={clsx(classes.draggableTop)}>
                    <strong className={clsx(classes.draggableTopHandle, 'dragDivChild1')} >
                        <MoreHorizIcon style={{ color: "#7D8FB6", position: "absolute", top: "-7", left: "196px", cursor: "move" }} />
                    </strong>
                    </div>
                <div className={clsx(classes.cp, 'dragDivForm')} style={{ width: "90%" }}>
                    <div style={{
                        margin: "0px 0px 10px 0px", fontSize: "16px", color: "#2F3D5B", fontWeight: "bolder",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        <span>Table Factory</span>
                        <CloseIcon style={{ backgroundColor: "#E9EBF0", color: "rgb(47, 61, 91)", cursor: "pointer", borderRadius: "50%" }} onClick={handleClose} />
                    </div>
                    <InputBase
                        className={classes.rowsColumns}
                            placeholder="Rows"
                            fullWidth
                            id="tileFact"
                            type='number'
                            value={text.row}
                            onChange={(e) => {
                                e.persist();
                                setText(p => { return { ...p, row: e.target.value } })
                            }}
                        />
                    <InputBase
                        className={classes.rowsColumns}
                            placeholder="Columns"
                            fullWidth
                            onPaste={pasteText}
                            type='number'
                            id="tileFact"
                            value={text.column}
                            onChange={(e) => {
                                e.persist();
                                setText(p => { return { ...p, column: e.target.value } })
                            }}
                        />
                    <div className="formcontrol">
                        <FormControlLabel
                            style={{
                                textTransform: "capitalize",
                                color: " #2F3D5B",
                                fontSize: "14px",
                                fontFamily: "Lato",
                                fontStyle: "normal",
                                fontWeight: "normal"
                            }}
                            control={<Checkbox checked={control.firstRowHeader} onChange={handleChange2} name="firstRowHeader" />}
                            label="First Row Header"
                        />
                         <FormControlLabel
                            style={{
                                textTransform: "capitalize",
                                color: " #2F3D5B",
                                fontSize: "14px",
                                fontFamily: "Lato",
                                fontStyle: "normal",
                                fontWeight: "normal"
                            }}
                        control={<Checkbox checked={control.makeSquareCells} onChange={handleChange2} name="makeSquareCells" />}
                        label="Make Square Cells"
                        />
                    </div>
                    <Divider
                        style={{ margin: "15px -20px", color: "#E0E5E7" }}
                    />
                    <div className='formBtns'>
                        <Button
                            variant="contained"
                            size="small"
                            className = {classes.createButton}
                            style={{ backgroundColor: props.inkColor }}
                            onClick={() => create()}
                            >
                            Create
                        </Button>
                            </div>
                    </div>
    
                </div>
    
            </Draggable>
    
        );

  

}